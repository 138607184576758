import { Observable } from 'rxjs/observable';
import { Component, Input, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/Services/employee.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-workhisory',
  templateUrl: './workhisory.component.html',
  styleUrls: ['./workhisory.component.css']
})
export class WorkhisoryComponent implements OnInit {
  public errorMsg;
  @Input() employeeId   :number=0;
  @Input() employeeName :string=null;
  @Input() Type:number=0;
  selectedFromDate:Date
  selectedToDate:Date

  constructor(public employeeService:EmployeeService,
              public commonService:CommonService
              ) { }

  ngOnInit(): void {
    this.selectedFromDate=new Date(this.employeeService.selectedDate);
    this.selectedToDate=new Date(this.employeeService.selectedDate);
    this. employeeWorkShedule();
  }

  closePopUp() {
    this.employeeService.employeepopup=0;
    this.employeeService.selectedEmployeeId=0;
  }

  timeList=[
    {time:'01'},{time:'02'},{time:'03'},
    {time:'04'},{time:'05'},{time:'06'},
    {time:'07'},{time:'08'},{time:'09'},
    {time:'10'},{time:'11'},{time:'12'},
    {time:'13'},{time:'14'},{time:'15'},
    {time:'16'},{time:'17'},{time:'18'},
    {time:'19'},{time:'20'},{time:'21'},
    {time:'22'},{time:'23'},{time:'Total'}
  ]

  employeeWorkShedule()
  {
    this.commonService.isLoading=true;
    if(this.employeeService.selectedEmployeeId > 0 && this.employeeService.employeepopup==1)
    {
      this.employeeService.employeeWorkShedule(this.commonService.convertWithTime(this.selectedFromDate),this.commonService.convertWithTime(this.selectedToDate)).subscribe(
        data=>{
          this.employeeService.employeeWorkshedule=data;
          this.commonService.isLoading=false;
        },
        error => {
          this.errorMsg = error;
          this.commonService.isLoading=false;
        });
    } 
    else if(this.employeeService.selectedEmployeeId > 0 && this.employeeService.employeepopup==2)
    {
      this.employeeService.employeeWorkHistory().subscribe(
        data=>{
          this.employeeService.employeeActivity=data;
          this.commonService.isLoading=false;
        },
        error => {
          this.errorMsg = error;
          this.commonService.isLoading=false;
        });
    }
  }
}
