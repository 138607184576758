import { Component, OnInit, Input } from '@angular/core';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CommonService } from 'src/app/Services/common.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { WorkSortingService } from 'src/app/Services/work-sorting.service';
import { BranchService } from 'src/app/Services/branch.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { WorkAllocationService } from 'src/app/Services/work-allocation.service';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { ProjectService } from 'src/app/Services/project.service';
import { ModuleService } from 'src/app/Services/module.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';

@Component({
  selector: 'app-custom-customer-requirement',
  templateUrl: './custom-customer-requirement.component.html',
  styleUrls: ['./custom-customer-requirement.component.css']
})
export class CustomCustomerRequirementComponent implements OnInit {


  errorMsg: any;
  PriorityList: any;

  jobOrderForm: boolean;
  serviceEngWF: boolean;
  WarningModel: boolean;

  StatusList: any;
  AssignToList: any;
  StatusFullList: any;
  TypeList: any;
  // SupportEngId: any


  AMCRenewalStatus: boolean
  CustomerRequirementForm: boolean

  StatusFullListCustom: any;
  ServiceReportEntryForm: boolean
  ModuleDateFullList: any = []


  SECustomDashBoardCustReqRefNo: any;
  SECustomDashBoardCustReqDealerId: any;
  SECustomDashBoardCustReqBranchId: any;

  @Input() refno: number
  @Input() DealerId: number
  @Input() BranchId: number

  constructor(
    private toastr: ToastrService,
    public _CustomerService: CustomerService,
    public _CommonService: CommonService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _serviceEngineerDBService: ServiceEngineerDBService,
    public _BranchService: BranchService,
    public _EmployeeService: EmployeeService,
    public _WorkSortingService: WorkSortingService,
    public _WorkAllocationService: WorkAllocationService,
    public _modulService: ModuleService,
    public _projectService: ProjectService,
    public _nodeRegService: NodeRegistrationService,
    public _ParticularmasterService: ParticularmasterService,
  ) { }

  ngOnInit() {
    // if (localStorage.getItem("Designation") == 'Support Engineer')
    //   this.SupportEngId = CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8);
    // else
    //   this.SupportEngId = '0'


    this.Reset();
    this.LoadCustomers();
    this.fillServiceEngineer();
    this.AssignToList = this._CommonService.GetAssignToList();
    this._serviceEngineerDBService.AllWorkSortingDet = []
    this.getCustomerReuirementListCommon();
    this.getCusReqOrServiceEntry();
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('ALL')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('ALL')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }



  getCustomerReuirementListCommon() {
    this.PriorityList = []
    this.StatusList = []
    this.TypeList = []
    this.PriorityList = this._CommonService.GetPriorityList()
    this.StatusList = this._CommonService.GetStatusList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusFullList = this._CommonService.GetStatusFullList();
    this.fillmodule();
    this.fillparticulars();
    this.GetAllModules();
    this.fillbranch();
  }

  fillmodule() {
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data

      },
        error => {
          this.errorMsg = error;
        })
  }

  fillparticulars() {
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data
      },
        error => {
          this.errorMsg = error;
        })
  }


  fillbranch() {
    if (this._CustomerrequirementService.selectedRecord.CustomerID != null) {
      this._BranchService.Fetch_BranchById(this._CustomerrequirementService.selectedRecord.CustomerID, 'CUSTOMERID')
        .subscribe(data => {
          this._BranchService.allBranchs = data
          this._CustomerrequirementService.selectedRecord.BranchID = null
          if (this._BranchService.allBranchs.length == 1) {
            this._CustomerrequirementService.selectedRecord.BranchID = this._BranchService.allBranchs[0].Id
            this.fillproject();
          }
        },
          error => {
            this.errorMsg = error;
          });
    }
    else {
      this._BranchService.Fetch_Branch('SERVENGID')
        .subscribe(data => {
          this._BranchService.allBranchs = data
          this._CustomerrequirementService.selectedRecord.BranchID = null
          if (this._BranchService.allBranchs.length == 1) {
            this._CustomerrequirementService.selectedRecord.BranchID = this._BranchService.allBranchs[0].Id
            this.fillproject();
          }
        },
          error => {
            this.errorMsg = error;
          });
    }

  }


  Reset() {
    this._BranchService.selectedBranch = {
      Addr1: null,
      Addr2: null,
      Addr3: null,
      BranchEmail: null,
      BranchFax: null,
      BranchName: null,
      Customer:null,
      BranchPhone: null,
      ContactEmail: null,
      ContactPerson: null,
      ContactPhone: null,
      CountryId: null,
      CustomerId: null,
      DealerId: null,
      Id: null,
      RouteId: null,

      // LicenseKeyValDays: null,
      // RemainderExp: null,
      // YearlyReminderExp: null,
      // YearlyReminderExpfreq: null,
      // BlockLicenseKeyIssue: null,
      // VersionChange: null,
      // CustomerProfileUpdation: null,
      // NextRenewalValidDays: null,
      UniqueBrachId: ''
    }

    this._WorkAllocationService.SelectedJobOrderHdr = {
      BranchAdr1: null,
      BranchAdr2: null,
      BranchAdr3: null,
      BranchId: null,
      CPBranch: null,
      CustAdr1: null,
      CustAdr2: null,
      CustAdr3: null,
      CustomerId: null,
      CPCutomer: null,
      IssueDate: null,
      JoNo: null,
      CPBranchPhone: null,
      CPCustomerPhone: null,
      From: null,
      To: null,
      FromTime: null,
      ServiceMode: 0,
      ToTime: null,
      WorkSortingAssigning: [],
      SEWorkDet: [],
      Notes: null,
      CurrentCharge:0,
      ServiceCharge: 0,
      EmployeeType: 0,
      AttachmentList: [],
      ProjectId:0,
      ContractDate:null,
      ContractVsCurrentDate:null,
      NumberOfVisit:0
    }

    //customerrequirement
    this._CustomerrequirementService.selectedRecord = {
      ID: 0,
      RefNo: 0,
      Time: null, ExpectedDate: null, Remark: '',
      ContractDate: null, PlannedDeliveryDate: null,
      SEID: null,
      ModeOfReg: null,
      Date: new Date(),
      BranchID: null,
      BranchAdr1: "",
      BranchAdr2: "",
      BranchAdr3: "",
      AMCUPTo: null,
      CustomerID: null,
      CustAdr1: "",
      CustAdr2: "",
      CustAdr3: "",
      ProjectID: null,
      Server: 0,
      Node: 0,
      NoOfVisits: 0,
      Address: "",
      Note: "",
      CusAttachementList: null,
      CusAttachementRemovedList: null,
      CusReqDtls: null,
      ContractVsCurrentDate: null,
      DealerId: 0
    }
    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._modulService.allModuleServiceReport = []
    this._CustomerrequirementService.allCustomerDtls = []
    this._CustomerrequirementService.newAttribute = {
      SNo: 1,
      Particular: null,
      ModuleId: null,
      Description: '',
      Type: null,
      Priority: null,
      Status: 2,
      Remarks: ''
    }
    this._CustomerrequirementService.RowCount = 0;
    this._CustomerrequirementService.allCustomerReqHistory = []

    // service report entry


    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._modulService.allModuleServiceReport = []
  }
  getCusReqOrServiceEntry() {

    this.SECustomDashBoardCustReqRefNo = this.refno;
    this.SECustomDashBoardCustReqDealerId = this.DealerId;
    this.SECustomDashBoardCustReqBranchId = this.BranchId;

    this.SearchCustReq(this.SECustomDashBoardCustReqRefNo, this.SECustomDashBoardCustReqDealerId)
    $('#myModalConfirm1').css("display", "block")
    this.jobOrderForm = false
    this.ServiceReportEntryForm = false
    this.WarningModel = false
    this.serviceEngWF = false
    this.CustomerRequirementForm = true

    this._BranchService.Fetch_BranchById(this.SECustomDashBoardCustReqBranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.allBranchs = obj
      this._CustomerrequirementService.selectedRecord.BranchID = null
      if (this._BranchService.allBranchs.length == 1) {
        this._CustomerrequirementService.selectedRecord.BranchID = this._BranchService.allBranchs[0].Id
        this.fillproject();
      }
    })
  }



  SearchCustReq(RefNo: number, DealerId: number) {
    this.AMCRenewalStatus = false
    if (RefNo != null)
      this._CustomerrequirementService.getCustomerRequirmentHomescreen(RefNo, DealerId).subscribe(obj => {
        console.log('getCustomerRequirmentHomescreen:', obj)
        if (obj.ID != 0) {
          this._CustomerrequirementService.FormUpdation = 'UPDATE';
          this._CustomerrequirementService.selectedRecord = Object.assign({}, obj)
          this._CustomerrequirementService.selectedRecord.RefNo = RefNo
          var custDtls = this._CustomerService.allCustomers.filter(e => e.Id == this._CustomerrequirementService.selectedRecord.CustomerID)[0];
          this._CustomerrequirementService.selectedRecord.CustAdr1 = custDtls.Addr1;
          this._CustomerrequirementService.selectedRecord.CustAdr2 = custDtls.Addr2;
          this._CustomerrequirementService.selectedRecord.CustAdr3 = custDtls.Addr3;
          this._CustomerrequirementService.selectedRecord.Date = new Date(obj.Date)
          this.fillproject();
          this._CustomerrequirementService.allCustomerDtls = obj.CusReqDtls

        }

      })
  }

  OnClickCancelcus() {
    $('#myModalConfirm1').css("display", "none")
    $('#myModal').addClass("show");
    this.WarningModel = false
    this.serviceEngWF = false
    this.jobOrderForm = false
    this.Reset();
    this._CommonService.servicereportentryPopUp = false
    this._CommonService.customerrequirementPopUp = false
  }



  fillproject() {
    this._projectService.fetchProject('BYBRANCHID', this._CustomerrequirementService.selectedRecord.BranchID == null ? 0 : this._CustomerrequirementService.selectedRecord.BranchID)
      .subscribe(data => {
        this._projectService.allProjects = data
        this._CustomerrequirementService.selectedRecord.ProjectID = null
        if (this._projectService.allProjects.length == 1)
          this._CustomerrequirementService.selectedRecord.ProjectID = this._projectService.allProjects[0].Id
        this.GetAllModules();
      },
        error => {
          this.errorMsg = error;
        });
  }

  GetAllModules() {

    this.AMCRenewalStatus = false
    this._CustomerrequirementService.allCustomerReqHistory = []
    if (this._CustomerrequirementService.selectedRecord.CustomerID != null
      && this._CustomerrequirementService.selectedRecord.BranchID != null
      && this._CustomerrequirementService.selectedRecord.ProjectID != null) {

      this._modulService.Fetch_ModuleServiceReport(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID)
        .subscribe(data => {
          this._modulService.allModuleServiceReport = data
        },
          error => {
            this.errorMsg = error;
          })
      this._CommonService.LoadSoftWareDtlsCommon(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID).
        subscribe(obj => {
          this._CustomerrequirementService.selectedRecord.Server = obj.NoOfServer
          this._CustomerrequirementService.selectedRecord.ContractVsCurrentDate = obj.ContractVsCurrentDate
          this._CustomerrequirementService.selectedRecord.Node = obj.Nodes
          if (obj.AMCUPTo == null)
            this._CustomerrequirementService.selectedRecord.AMCUPTo = null
          else {
            this._CustomerrequirementService.selectedRecord.AMCUPTo = new Date(obj.AMCUPTo)
            if (this._CustomerrequirementService.selectedRecord.AMCUPTo > new Date())
              this.AMCRenewalStatus = true
          }
          this._CustomerrequirementService.selectedRecord.ContractDate = obj.ContractDate == null ? null : new Date(obj.ContractDate)
        })

      this._CustomerrequirementService.GetCustomerRequirementsHistory(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID, this._CustomerrequirementService.selectedRecord.RefNo).subscribe(obj => {
        this._CustomerrequirementService.allCustomerReqHistory = obj.CusReqDtls

        console.log('this._CustomerrequirementService.allCustomerReqHistory', this._CustomerrequirementService.allCustomerReqHistory)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.NoOfVisits = obj.NoOfVisits
        this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate == null ? null : new Date(obj.LastVisitDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
        this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate == null ? null : new Date(obj.LastUpdExeDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate == null ? null : new Date(obj.LastUpdScriptDate)
      })

    }
  }
  // deleteFieldValue(index) {
  //   this._CustomerrequirementService.allCustomerDtls.splice(index, 1);
  //   this._CustomerrequirementService.RowCount = this._CustomerrequirementService.allCustomerDtls.length;
  // }

  // addFieldValue() {
  //   this._CustomerrequirementService.allCustomerDtls.push(this._CustomerrequirementService.newAttribute)
  //   this._CustomerrequirementService.RowCount = this._CustomerrequirementService.allCustomerDtls.length;
  //   this._CustomerrequirementService.newAttribute = {
  //     SNo: this._CustomerrequirementService.RowCount + 1,
  //     Particular: null,
  //     ModuleId: null,
  //     Description: '',
  //     Type: null,
  //     Priority: null,
  //     Status: 2,
  //     Remarks: ''
  //   }
  // }





}
