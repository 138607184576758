import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ServerProvider } from '../Models/Server-Provoder';

@Injectable({
  providedIn: 'root'
})
export class ServerProviderService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  
  selectedRecord:ServerProvider={
    ID:0,
    Code:'',
    Name:'',
    Address:'',
    ContactPerson:'',
    ContactNumber:'',
    Email:''
  };
  formHideShow = 0;
  allServerProvider:ServerProvider[]; 
  pageIndex: number = 0;
  isFiltering: boolean = false;
  CodeFilter: string = '';
  NameFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;

  clearValue(){
    this.selectedRecord={
      ID:0,
      Code:'',
      Name:'',
      Address:'',
      ContactPerson:'',
      ContactNumber:'',
      Email:''
    }
  }

  clearFilter() {
    this.isFiltering = false;
    this.CodeFilter = '';
    this.NameFilter = '';

  }
  get(TransMode: string): Observable<ServerProvider[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ServerProvider[]>(ROOT_URL + 'ServerProvider/Get', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getByID(TransMode: string,id:number): Observable<ServerProvider> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('ID', id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ServerProvider>(ROOT_URL + 'ServerProvider/GetByID', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  trans(values: ServerProvider): Observable<any> {
    var body = {
      Id: values.ID,
      Code: values.Code,
      Name: values.Name,
      Address: values.Address,
      ContactPerson: values.ContactPerson,
      ContactNumber: values.ContactNumber,
      Email: values.Email,
    }
    let params = new HttpParams();
    params = params.append('TransMode',"SAVE");
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "ServerProvider/Trans", body, {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  onDelete(Id:number){
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete<any>(ROOT_URL + 'ServerProvider/Delete', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
    }
}
