import { Injectable } from '@angular/core';
import { branch } from '../Models/branch.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  selectedBranch: branch;
  branchList: branch[];
  formHideShow = 0;
  formHidePassword = false;
  public allBranchs: branch[];
  imageUrl: string = "";
  pageIndex: number = 0;
  isFiltering: boolean = false;
  BranchNameFilter: string = '';
  CustomerNameFilter:string='';
  orderByDir: boolean = false;
  orderByColName: string = '';
  DealerId: string = ''
  giveEditPermission: boolean = false;
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }
  ClearFilter() {
    this.isFiltering = false;
    this.BranchNameFilter = '';
    this.CustomerNameFilter='';
  }
  postBranch(brnch: branch) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      CountryId: brnch.CountryId,
      DealerId: brnch.DealerId,
      CustomerId: brnch.CustomerId,
      BranchName: brnch.BranchName,
      Addr1: brnch.Addr1,
      Addr2: brnch.Addr2,
      Addr3: brnch.Addr3,
      BranchEmail: brnch.BranchEmail,
      BranchPhone: brnch.BranchPhone,
      BranchFax: brnch.BranchFax,
      ContactPerson: brnch.ContactPerson,
      ContactPhone: brnch.ContactPhone,
      ContactEmail: brnch.ContactEmail,
      RouteId: brnch.RouteId,
      Id: brnch.Id,
      DataBase:brnch.DataBase
    }
    return this.http.post<branch>(ROOT_URL + 'Branch/PostBranch', body)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  deleteBranch(DeleteID): Observable<any> {
    return this.http.delete<branch>(ROOT_URL + 'Branch/DeleteBranch/' + DeleteID)
  }

  Fetch_Branch(TransMode: string): Observable<branch[]> {
    return this.http.get<branch[]>(ROOT_URL + 'Branch/FetchAllBranch?TransMode=' + TransMode)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  Fetch_BranchById(Id: number, TransMode: string): Observable<branch[]> {
    return this.http.get<branch[]>(ROOT_URL + 'Branch/FetchAllBranchById?TransMode=' + TransMode + '&Id=' + Id)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  SentMail(brnch: branch): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params.append('UniqueId', brnch.UniqueBrachId).append('MailId', brnch.BranchEmail)
    return this.http.get<branch>(ROOT_URL + 'Branch/SentMail?UniqueId=' + brnch.UniqueBrachId + '&MailId=' + brnch.BranchEmail, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
