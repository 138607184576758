<div class="container-fluid" *ngIf="_nodeRegService.formHideShow==0">
    <!-- <div class="row flex-body">
        <div class="col-md-11 col-md-offset-1"> -->
            <!-- <div class="box">
                <h4 class="box-title">Node Registartion</h4>
            </div>

            <div class="col-md-4 offset-md-8 searchBox">
                <div class="form-group row no-gutters">
                    <div class="col-12 col-sm-6 col-md-2"><button type="button" class="btn-sm btn-info"
                            (click)="addList()"><i class="fas fa-plus" aria-hidden="true"></i></button></div>
                    <div class="col-12 col-sm-6 col-md-8"><input type="text" class="form-control-search" name="search"
                            [(ngModel)]="term"></div>
                    <div class="col-6 col-md-2"><button class="btn-info"><i class="fas fa-search"
                                aria-hidden="true"></i></button></div>
                </div>
            </div> -->
            <h2 class="heading_all">Node Registartion</h2>
            <br>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
                <div class="col-md-4 ">
        
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <button type="button" class="btn-sm btn-info"
                                    (click)="addList()"><i class="fas fa-plus" aria-hidden="true"></i></button>
                                </td>
                                <td style="width: 70%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                                <td><button class="btn-info"><i class="fas fa-search"
                                    aria-hidden="true"></i></button></td>
                            </tr>
                        </thead>
                    </table>
        
                </div>
            </div>
            <br>
            <table>
                <!-- <thead>
                    <tr>
                        <th>#</th>
                        <th (click)="sort('code')">Ref No</th>
                        <th (click)="sort('Date')">Date</th>
                        <th (click)="sort('Customer')">Customer</th>
                        <th (click)="sort('Branch')">Branch</th>
                        <th (click)="sort('SoftRegNo')">SoftRegNo</th>
                        <th>Action</th>
                    </tr>
                </thead> -->
                <thead>
                    <tr>
                        <th>#</th>
                        
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('RefNo')">
                                <span *ngIf="!_nodeRegService.isFiltering">RefNo</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_nodeRegService.orderByColName!='RefNo' "></i>
                                    <div *ngIf="_nodeRegService.orderByColName=='RefNo'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_nodeRegService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_nodeRegService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                          <div *ngIf="_nodeRegService.isFiltering">
                                <input type="text" #txtRefNoFilter="ngModel" id="RefNoFilter"
                                    name="RefNoFilter" [(ngModel)]="_nodeRegService.RefNoFilter"
                                    placeholder="RefNo">
                            </div>

                        </th>
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('CurDate')">
                                <span *ngIf="!_nodeRegService.isFiltering">Date</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_nodeRegService.orderByColName!='CurDate' "></i>
                                    <div *ngIf="_nodeRegService.orderByColName=='CurDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_nodeRegService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_nodeRegService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                          
                            <div *ngIf="_nodeRegService.isFiltering">
                                <input type="text" #txtDateFilter="ngModel" id="DateFilter"
                                    name="DateFilter" [(ngModel)]="_nodeRegService.DateFilter"
                                    placeholder="CurDate">
                            </div>
                        </th>
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('CustomerName')">
                                <span *ngIf="!_nodeRegService.isFiltering">Customer</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_nodeRegService.orderByColName!='CustomerName' "></i>
                                    <div *ngIf="_nodeRegService.orderByColName=='CustomerName'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_nodeRegService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_nodeRegService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                        
                            <div *ngIf="_nodeRegService.isFiltering">
                                <input type="text" #txtCustomerFilter="ngModel" id="CustomerFilter"
                                    name="CustomerFilter" [(ngModel)]="_nodeRegService.CustomerFilter"
                                    placeholder="Customer">
                            </div>
                        </th>
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('BranchName')">
                                <span *ngIf="!_nodeRegService.isFiltering">Branch</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_nodeRegService.orderByColName!='BranchName' "></i>
                                    <div *ngIf="_nodeRegService.orderByColName=='BranchName'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_nodeRegService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_nodeRegService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                           
                            <div *ngIf="_nodeRegService.isFiltering">
                                <input type="text" #txtBranchFilter="ngModel" id="BranchFilter"
                                    name="BranchFilter" [(ngModel)]="_nodeRegService.BranchFilter"
                                    placeholder="Branch">
                            </div>
                        </th>
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('SRNo')">
                                <span *ngIf="!_nodeRegService.isFiltering">SRNo</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_nodeRegService.orderByColName!='SRNo' "></i>
                                    <div *ngIf="_nodeRegService.orderByColName=='SRNo'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_nodeRegService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_nodeRegService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                          <div *ngIf="_nodeRegService.isFiltering">
                                <input type="text" #txtSRNoFilter="ngModel" id="SRNoFilter"
                                    name="SRNoFilter" [(ngModel)]="_nodeRegService.SRNoFilter"
                                    placeholder="SoftRegNo">
                            </div>
                        </th>
                        <th><em></em> Settings
                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                <i *ngIf="!_nodeRegService.isFiltering" class="fas fa-filter"></i>
                                <i *ngIf="_nodeRegService.isFiltering" class="fas fa-times"></i>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- ngRepeat: item in items -->
                    <!-- <tr *ngFor="let nodeReg of _nodeRegService.allNodeRegistrations  | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope"> -->
                        <tr *ngFor="let nodeReg of _nodeRegService.allNodeRegistrations | orderBy : 'RefNo' | gridOperations  :_nodeRegService.pageIndex:
                        {RefNo:_nodeRegService.RefNoFilter,CurDate:_nodeRegService.DateFilter,CustomerName:_nodeRegService.CustomerFilter,BranchName:_nodeRegService.BranchFilter,SRNo:_nodeRegService.SRNoFilter}:
                       {Column:_nodeRegService.orderByColName,Dir:_nodeRegService.orderByDir} ; let i = index"
                        class="ng-scope">
                        <td>{{i+1+((NoOfItems*_nodeRegService.pageIndex)-NoOfItems)}}</td>
                        <td>{{nodeReg.RefNo}}</td>
                        <td>{{nodeReg.CurDate |date:'dd-MM-yyyy'}}</td>
                        <td>{{nodeReg.CustomerName }}</td>
                        <td>{{nodeReg.BranchName}}</td>
                        <!-- <td>
                            <label class="form-control-label" *ngFor="let obj of  this._custService.allCustomers">
                                <p *ngIf="obj.Id==null">Select</p>
                                <p *ngIf="obj.Id==nodeReg.CustomerId">
                                    {{obj.Name}}
                                </p>
                            </label>
                        </td> -->
                        <!-- <td>
                            <label class="form-control-label" *ngFor="let obj of _brnchService.allBranchs">
                                <p *ngIf="obj.Id==null">Select</p>
                                <p *ngIf="obj.Id==nodeReg.BranchId">
                                    {{obj.BranchName}}
                                </p>
                            </label>
                        </td> -->
                        <td>{{nodeReg.SRNo}}</td>
                        <td>
                            <button type="button" class="btn-success" (click)="showForEdit(nodeReg)">
                                <i class="fas fa-pen-square" aria-hidden="true"></i></button>

                            <button type="button" class="btn-danger" (click)="onDelete(nodeReg)"><i class="fas fa-trash"
                                    aria-hidden="true"></i></button>
                        </td>
                    </tr><!-- end ngRepeat: item in items -->
                    <tr>
                        <td colspan="3"
                            *ngIf="(_nodeRegService.allNodeRegistrations?.length<=0 )  || _CommonService.filterdItemCount<1">
                            No Records To
                            Display.</td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                        <app-grid-pagination (pageIndexValue)="_nodeRegService.pageIndex=$event">
                        </app-grid-pagination>
                    </td>
                </tfoot>
            </table>
            <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
            <div class="margintop"></div>
        <!-- </div>
    </div> -->
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'NodeRegistration'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>