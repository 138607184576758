import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ServiceHistory, ServiceHistoryDtls, ServiceHistoryDtlsFooter } from '../Models/ServiceHistory.Model';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceHistoryService {

  SelectedServiceHistory: ServiceHistory
  allServiceHistoryDtls: ServiceHistoryDtls[]
  SelectedServiceHistoryDtlsFooter: ServiceHistoryDtlsFooter

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  clearValues() {
    this.SelectedServiceHistory = {
      AMCStatus: null,
      Address: '',
      BE: '',
      ContactPerson: '',
      CustomerId: null,
      DealerId: null,
      Duration: null,
      Email: '',
      InstallationDate: null,
      Phone: null,
      ServiceHistoryDtls: [],
    }
  }

  getServiceHistory(CustomerId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('CustomerId', CustomerId.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<any>(ROOT_URL + 'ServiceHistory/GetServiceHistory', {
      headers, params
    })
      .pipe(
      );
  }

}
