import { Injectable } from '@angular/core';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { CommonCombo, DocumentSetting } from '../Models/Settings';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  allDocumentSetting:DocumentSetting[]=[];
  selecteddocumentSetting:DocumentSetting={
    ID:0,Code:'',EmployeeID:undefined,Employee:'',ModuleID:undefined,Module:'',FileName:'',GDrivePath:'', IsClosed:false
  }
  employeeList:CommonCombo[]=[];
  moduleList:CommonCombo[]=[];
  formHideShow:number=0;
  isFiltering:boolean=false;
  CodeFilter:string='';
  EmployeeFilter:string='';
  ModuleFilter:string='';
  DocNameFilter:string='';
  pageIndex:number=0;
  orderByDir: boolean = false;
  orderByColName: string = '';

  constructor(private http: HttpClient,
              public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.CodeFilter = '';
    this.EmployeeFilter = '';
    this.ModuleFilter='';
    this.DocNameFilter='';

  }

  saveDocumentSetting(values:DocumentSetting)
  {
    var body={
      ID:values.ID,
      Code:values.Code,
      EmployeeID:values.EmployeeID,
      ModuleID:values.ModuleID,
      FileName:values.FileName,
      GDrivePath:values.GDrivePath,
      IsClosed:values.IsClosed
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<Response>(ROOT_URL+"Settings/Document_Trans",body,{
      headers
    })
    .pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  getalldoc(employeeID:number,moduleID:number)
  {
    const headers = new HttpHeaders().set('content-type','application/json');
    let params = new HttpParams();
    params = params.append('TransMode',"LoadDocument");
    params = params.append('EmployeeID',employeeID.toString());
    params = params.append('ModuleID',moduleID.toString());
    return this.http.get<any[]>(ROOT_URL+"Settings/GetAllDoc" , {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  onDelete(id:number)
  {
    const headers = new HttpHeaders().set('content-type','application/json');
    let params = new HttpParams();
    params = params.append('Transmode',"DeleteDocument");
    params = params.append('ID',id.toString());
    return this.http.delete<any>(ROOT_URL+"Settings/DeleteDocument" , {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
