<div class="container-fluid" *ngIf="_custService.formHideShow==0">
    <h2 class="heading_all">Customer</h2>
    <br>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4 ">

            <table>
                <thead>
                    <tr>
                        <td>
                            <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                    aria-hidden="true"></i></button>
                        </td>
                        <td style="width: 70%;"><input type="text" class="form-control-search" name="search"
                                [(ngModel)]="term"></td>
                        <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                    </tr>
                </thead>
            </table>

        </div>
    </div>
    <br>
    <table>
        <thead>
            <tr>
                <th>#</th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Name')">
                        <span *ngIf="!_custService.isFiltering">Name</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_custService.orderByColName!='Name' "></i>
                            <div *ngIf="_custService.orderByColName=='Name'">
                                <i class="fas fa-sort-amount-down" *ngIf="_custService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_custService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_custService.isFiltering">
                        <input type="text" #txtNameFilter="ngModel" [(ngModel)]="_custService.NameFilter"
                            placeholder="Name">
                    </div>

                </th>
                <th><em></em> Settings
                    <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                        <i *ngIf="!_custService.isFiltering" class="fas fa-filter"></i>
                        <i *ngIf="_custService.isFiltering" class="fas fa-times"></i>
                    </a>
                </th>
            </tr>
        </thead>
        <tbody>

            <tr *ngFor="let cust of _custService.allCustomers | gridOperations  :_custService.pageIndex: 
                     {Name:_custService.NameFilter}:
                    {Column:_custService.orderByColName,Dir:_custService.orderByDir} ; let i = index" class="ng-scope">
                <td>{{i+1+((NoOfItems*_custService.pageIndex)-NoOfItems)}}</td>

                <td>{{cust.Name}}</td>
                <td>
                    <button type="button" class="btn-success" (click)="showForEdit(cust)">
                        <i class="fas fa-pen-square" aria-hidden="true"></i></button>

                    <button type="button" class="btn-danger" (click)="onDelete(cust)"><i class="fas fa-trash"
                            aria-hidden="true"></i></button>
                </td>
            </tr>
            <tr>
                <td colspan="2" *ngIf="(_custService.allCustomers?.length<=0 )  || _CommonService.filterdItemCount<1">
                    No Records To
                    Display.</td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="3" *ngIf="_CommonService.totalPageCount>1">
                <app-grid-pagination (pageIndexValue)="_custService.pageIndex=$event">
                </app-grid-pagination>
            </td>
        </tfoot>
    </table>

    <div class="margintop"></div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'Customer'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>