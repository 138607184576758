<div class="container-fluid" *ngIf="_SettingsService.formHideShow==1">
    <h2 class="heading_all">DOCUMENT SETTING</h2>
    <br>
    <div id="form-wrap">
        <form #documentsettingForm="ngForm" (ngSubmit)="onSubmit(documentsettingForm)">
            <div class="col-md-4">
                <div class="form-group row">
                    <label>Code<span class="validation-error">*</span></label>
                    <input type="text" name="Code" #Code="ngModel" autocomplete="off"
                        [(ngModel)]="_SettingsService.selecteddocumentSetting.Code" placeholder="Code">
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group row">
                    <input type="checkbox" name="IsClosed" id="IsClosed" #IsClosed="ngModel" [(ngModel)]="_SettingsService.selecteddocumentSetting.IsClosed">
                    <label>IsClosed</label>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group row">
                    <label>Employee<span class="validation-error">*</span></label>
                    <ng-select name="Employee" id="Employee"
                        bindLabel="Name" bindValue="Id"
                        [items]="_SettingsService.employeeList" #Employee="ngModel"
                        [(ngModel)]="_SettingsService.selecteddocumentSetting.EmployeeID"
                        placeholder="Select">
                    </ng-select>
                </div>
                <div class="form-group row">
                    <label>Module<span class="validation-error">*</span></label>
                    <ng-select name="Module" id="Module"
                        bindLabel="Name" bindValue="Id"
                        [items]="_SettingsService.moduleList" #Module="ngModel"
                        [(ngModel)]="_SettingsService.selecteddocumentSetting.ModuleID"
                        placeholder="Select">
                    </ng-select>
                </div>
                <div class="form-group row">
                    <label>File Name<span class="validation-error">*</span></label>
                    <input type="text" name="FileName" #FileName="ngModel" autocomplete="off"
                        [(ngModel)]="_SettingsService.selecteddocumentSetting.FileName" placeholder="File Name">
                </div>
                <div class="form-group row">
                    <label>Google Drive Path<span class="validation-error">*</span></label>
                    <textarea name="GDrivePath" #GDrivePath="ngModel" autocomplete="off" style="width: 100%;color:blue;text-decoration:underline;"  rows="1" (dblclick)="_CommonService.openDocument(_SettingsService.selecteddocumentSetting.GDrivePath)"
                        [(ngModel)]="_SettingsService.selecteddocumentSetting.GDrivePath" required></textarea>
                    <div class="validation-error"
                        *ngIf="(GDrivePath.invalid && GDrivePath.touched)||(GDrivePath.invalid &&this.FormValidation)">This Field is Required.</div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(documentsettingForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>