import { Injectable } from '@angular/core';
import { project } from '../Models/project.model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs';
import { GlobalErrorHandlerService } from './global-error-handler.service'

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  selectedProject: project;
  projectList: project[];
  formHideShow = 0;
  allProjects: project[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  CodeFilter: string = '';
  NameFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.CodeFilter = '';
    this.NameFilter = '';

  }

  saveProject(values: project): Observable<any> {
    var body = {
      Id: values.Id,
      Code: values.Code,
      Name: values.Name,
      MonthlyRent: values.MonthlyRent,
      YearlyRent: values.YearlyRent,
      AddUserMonthlyRent: values.AddUserMonthlyRent,
      AddUserYearlyRent: values.AddUserYearlyRent,
      Cloud:values.Cloud
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + 'Project/InsUpdate', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  removeProject(DeleteID: number) {
    let params = new HttpParams();
    params = params.append('DeleteID', DeleteID.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete<any>(ROOT_URL + "Project/DeleteProject", {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  fetchProject(TransMode: string, Id: number): Observable<project[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode)
    params = params.append('Id', Id.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<project[]>(ROOT_URL + 'Project/FetchProject', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

}
