<div class="container-fluid" *ngIf="chargeTypeService.formHideShow==0">
    <h2 class="heading_all">{{this.commonService.commonNameText}}</h2>
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">
                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td style="width: 60%;"><input type="text" disabled class="form-control-search" name="search"></td>
                            <td><button disabled class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th style="width:205px;" class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Code')">
                            <span *ngIf="!chargeTypeService.isFiltering">Code</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="chargeTypeService.orderByColName!='Code' "></i>
                                <div *ngIf="chargeTypeService.orderByColName=='Code'">
                                    <i class="fas fa-sort-amount-down" *ngIf="chargeTypeService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!chargeTypeService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="chargeTypeService.isFiltering">
                            <input type="text" #txtBTypeCodeFilter="ngModel" [(ngModel)]="chargeTypeService.CodeFilter"
                                placeholder="Code">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('ChargeName')">
                            <span *ngIf="!chargeTypeService.isFiltering">{{this.commonService.commonNameText}}</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="chargeTypeService.orderByColName!='ChargeName' "></i>
                                <div *ngIf="chargeTypeService.orderByColName=='ChargeName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="chargeTypeService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!chargeTypeService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="chargeTypeService.isFiltering">
                            <input type="text" #txtBTypeFilter="ngModel" [(ngModel)]="chargeTypeService.NameFilter"
                                placeholder="{{this.commonService.commonNameText}}">
                        </div>
                    </th>
                    <th style="width:135px;"><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!chargeTypeService.isFiltering && chargeTypeService.allChargeType?.length > 0" class="fas fa-filter"></i>
                            <i *ngIf="chargeTypeService.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let type of this.chargeTypeService.allChargeType | orderBy : 'Code'| gridOperations  :chargeTypeService.pageIndex: 
                            {Code:chargeTypeService.CodeFilter,ChargeName:chargeTypeService.NameFilter}:
                            {Column:chargeTypeService.orderByColName,Dir:chargeTypeService.orderByDir} ; let i = index"
                    class="ng-scope">
                    <td>{{i+1+((NoOfItems*chargeTypeService.pageIndex)-NoOfItems)}}</td>
                    <td style="width:205px;">{{type.Code}}</td>
                    <td>{{type.Name}}</td>
                    <td style="width: 135px;">
                        <button type="button" class="btn-success" (click)="showForEdit(type)">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                        <button type="button" class="btn-danger" (click)="onDelete(type)"><i class="fas fa-trash"
                                aria-hidden="true"></i></button>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" *ngIf="(this.chargeTypeService.allChargeType?.length<=0 )  || commonService.filterdItemCount<1">
                        No Records To Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="commonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="chargeTypeService.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <div class="margintop"></div>
    </div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'ChargeType'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>