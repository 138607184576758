import { Injectable } from '@angular/core';
import { route } from '../Models/route.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  selectedRoute: route;
  routeList: route[];
  formHideShow = 0;
  allRoutes: route[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  RouteNameFilter: string = '';
  CountryIdFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  DealerId: number = 0
  giveEditPermission: boolean = false;

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.RouteNameFilter = '';
    this.CountryIdFilter = '';

  }

  postRoute(modul: route) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      ID: modul.Id == null ? 0 : modul.Id,
      RouteName: modul.RouteName,
      CountryId: modul.CountryId
    }
    return this.http.post<route>(ROOT_URL + 'Route/PostRoute', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getRoute(TransMode: string, Id: number): Observable<route[]> {
    return this.http.get<route[]>(ROOT_URL + 'Route/GetAllRoute?TransMode=' + TransMode + '&Id=' + Id)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  deleteRoute(DeleteID) {
    return this.http.delete<route>(ROOT_URL + 'Route/DeleteRoute/' + DeleteID)
  }
}
