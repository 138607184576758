import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModuleService } from 'src/app/Services/module.service';
import { ToastrService } from 'ngx-toastr';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { NgForm } from '@angular/forms';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { CommonService } from 'src/app/Services/common.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import * as $ from "jquery";
import { DealerService } from 'src/app/Services/dealer.service';
import { CustomerRequirementsDtls, CustomerRequirement_Attachments } from 'src/app/Models/CustomerRequirements';
import { IMG_ROOT_URL } from 'src/app/Models/Config';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-customer-requirements',
  templateUrl: './customer-requirements.component.html',
  styleUrls: ['./customer-requirements.component.css']
})
export class CustomerRequirementsComponent implements OnInit {

  public errorMsg;
  PriorityList: any;
  StatusList: any;
  TypeList: any;
  StatusFullList: any;
  AMCRenewalStatus: boolean
  SupportEngId: number;
  fileToUpload: any[] = [];
  fileInputLabel: string;

  SubmitButtonDisable: boolean = false //API call multiple 
  FormValidation: boolean  //Used form form validation
  openPopup: number = 0;//Added by Albert used to display send to work status and wrk allotment popup;
  //For description edit
  OpenDescriptionPopup: boolean
  customerReqIndex: number
  newAttributeorNot: boolean

  constructor(
    public _CommonService: CommonService,
    public _CustomerService: CustomerService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _ParticularmasterService: ParticularmasterService,
    public _BranchService: BranchService,
    public _projectService: ProjectService,
    public _nodeRegService: NodeRegistrationService,
    public _modulService: ModuleService,
    public _EmployeeService: EmployeeService,
    private toastr: ToastrService,
    public _dealrService: DealerService, public userService: UserService
  ) { }

  ngOnInit(): void {

    if (localStorage.getItem("Designation") == 'Support Engineer')
      this._CommonService.LoginUser = 'SupportEngineer';
    else
      this._CommonService.LoginUser = 'Provider';
    this._CommonService.FormID = 'CUSREQREFNO';

    this.openPopup = 0;
    this.resetForm();
    this.AMCRenewalStatus = false;
    this.getLoginedUser()
  }

  async getLoginedUser() {
    this.SupportEngId = parseInt(await this._CommonService.getLoginedUserId())
  }


  async resetForm(form?: NgForm) {
    this.SubmitButtonDisable = false
    this.FormValidation = false
    this._CustomerrequirementService.FormUpdation = 'SAVE'
    this.fileToUpload = []
    this.fileInputLabel = ''
    $("#customFile").val('');
    //Added to edit description
    this.OpenDescriptionPopup = false
    this._CustomerrequirementService.DescriptionEdit = ''
    this.customerReqIndex = 0
    this.newAttributeorNot = true


    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    //end
    this._CustomerrequirementService.selectedRecord = {
      ID: 0, Remark: '', ExpectedDate: null,
      RefNo: 0,
      Time: this.formatAMPM(new Date()),
      ContractDate: null,
      SEID: null,
      ModeOfReg: 0,
      Date: new Date(),
      PlannedDeliveryDate: null,
      BranchID: null,
      BranchAdr1: "",
      BranchAdr2: "",
      BranchAdr3: "",
      AMCUPTo: null,
      CustomerID: 0,
      CustAdr1: "",
      CustAdr2: "",
      CustAdr3: "",
      ProjectID: null,
      Server: 0,
      Node: 0,
      NoOfVisits: 0,
      Address: "",
      Note: "",
      CusAttachementList: [],
      CusAttachementRemovedList: [],
      CusReqDtls: null,
      ContractVsCurrentDate: null,
      DealerId: 0
    }
    this._modulService.allModuleServiceReport = []
    this._CustomerrequirementService.allCustomerDtls = []
    this._CustomerrequirementService.newAttribute = {
      SNo: 1,
      Particular: 0,
      RefNo: 0,
      ModuleId: 0,
      Description: '',
      Type: 0,
      Priority: 0,
      Status: 2,
      Remarks: ''
    }
    this._CustomerrequirementService.RowCount = 0;
    this._CustomerrequirementService.allCustomerReqHistory = []
    this.getListCommon();
  }

  getListCommon() {
    this.PriorityList = []
    this.StatusList = []
    this.TypeList = []
    this.GetAllDealers();
    this.PriorityList = this._CommonService.GetPriorityList()
    this.StatusList = this._CommonService.GetStatusList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusFullList = this._CommonService.GetStatusFullList();
    this.GetRefNo();
    this.fillAllEmployees();
    this.fillmodule();
    this.fillbranch();
    this.fillproject();
    this.fillparticulars();
  }

  async GetRefNo() {

    if (this._CommonService.LoginUser == 'SupportEngineer') {
      this._CustomerrequirementService.selectedRecord.DealerId = parseInt(await this._CommonService.getDealerId('CUSREQREFNO', this._CustomerrequirementService.selectedRecord.SEID, this._CommonService.LoginUser));

    }
    this._CustomerrequirementService.selectedRecord.SEID = parseInt(await this._CommonService.getLoginedUserId())
    this.LoadCustomers();
    if (localStorage.getItem("Designation") == 'Support Engineer')
      this._CustomerrequirementService.selectedRecord.RefNo = parseInt(await this._CommonService.getRefNo('CUSREQREFNO', 'SupportEngineer'));
    else
      this._CustomerrequirementService.selectedRecord.RefNo = parseInt(await this._CommonService.getRefNoById(this._CustomerrequirementService.selectedRecord.DealerId, 'CUSREQREFNO', this._CommonService.LoginUser));

  }

  fillAllEmployees() {
    this._EmployeeService.Fetch_Employee('ALL')
      .subscribe(data => {
        this._CustomerrequirementService.allEmployees = data;
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  GetAllDealers() {
    this._dealrService.FetchDealer('ALL')
      .subscribe(data => {
        this._dealrService.allDealers = data
      })
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomerById('DEALERID', this._CustomerrequirementService.selectedRecord.DealerId, 0)
      .subscribe(data => {
        this._CustomerService.allCustomers = data
        if (this._CustomerService.allCustomers.length != 1)
        {
          this._CustomerrequirementService.selectedRecord.CustomerID = 0
          this.fillbranch();
        }
        else {
          this._CustomerrequirementService.selectedRecord.CustomerID = this._CustomerService.allCustomers[0].Id
          this.onSelect(1)
        }
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillparticulars() {
    this._ParticularmasterService.particularList = []
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data
        this._ParticularmasterService.particularList = this._ParticularmasterService.allparticular.filter(obj => obj.ModuleID == this._CustomerrequirementService.newAttribute.ModuleId)

        this._CustomerrequirementService.newAttribute.Particular = 0
      },
        error => {
          this.errorMsg = error;
        })
  }


  onSelect(type: number) {
    if (type == 1 && this._CustomerrequirementService.selectedRecord.CustomerID != 0) {
      var custDtls = this._CustomerService.allCustomers.filter(e => e.Id == this._CustomerrequirementService.selectedRecord.CustomerID)[0];
      this._CustomerrequirementService.selectedRecord.CustAdr1 = custDtls.Addr1;
      this._CustomerrequirementService.selectedRecord.CustAdr2 = custDtls.Addr2;
      this._CustomerrequirementService.selectedRecord.CustAdr3 = custDtls.Addr3;
      this._CustomerrequirementService.selectedRecord.BranchID = null
      this._CustomerrequirementService.selectedRecord.ProjectID = null
      this._BranchService.allBranchs = []
      this._projectService.allProjects = []
      this._modulService.allModuleServiceReport = []
      this.fillbranch();
    }
    if (type == 2 && this._CustomerrequirementService.selectedRecord.BranchID != null) {
      var brnchDtls = this._BranchService.allBranchs.filter(e => e.Id == this._CustomerrequirementService.selectedRecord.BranchID)[0];
      this._CustomerrequirementService.selectedRecord.BranchAdr1 = brnchDtls.Addr1;
      this._CustomerrequirementService.selectedRecord.BranchAdr2 = brnchDtls.Addr2;
      this._CustomerrequirementService.selectedRecord.BranchAdr3 = brnchDtls.Addr3;
      this._projectService.allProjects = []
      this._modulService.allModuleServiceReport = []
      if(this._CustomerrequirementService.selectedRecord.CustomerID==null || this._CustomerrequirementService.selectedRecord.CustomerID==0)
      { 
        this._CustomerrequirementService.selectedRecord.CustomerID=  brnchDtls.CustomerId 
        var custDtls = this._CustomerService.allCustomers.filter(e => e.Id == this._CustomerrequirementService.selectedRecord.CustomerID)[0];
        this._CustomerrequirementService.selectedRecord.CustAdr1 = custDtls.Addr1;
        this._CustomerrequirementService.selectedRecord.CustAdr2 = custDtls.Addr2;
        this._CustomerrequirementService.selectedRecord.CustAdr3 = custDtls.Addr3;
      }
      this.fillproject()
    }
  }

  fillmodule() {
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillbranch(branchId?: number) {
    if (this._CustomerrequirementService.selectedRecord.CustomerID != 0) {
      this._BranchService.Fetch_BranchById(this._CustomerrequirementService.selectedRecord.CustomerID, 'CUSTOMERID')
        .subscribe(data => {
          this._BranchService.allBranchs = data
          if (this._BranchService.allBranchs.length == 1) {
            this._CustomerrequirementService.selectedRecord.BranchID = this._BranchService.allBranchs[0].Id
            this.onSelect(2)
          }
          else
            this._CustomerrequirementService.selectedRecord.BranchID = branchId
          this.fillproject();
        },
          error => {
            this.errorMsg = error;
          });
    }
    else {
      this._BranchService.Fetch_BranchById(this._CustomerrequirementService.selectedRecord.DealerId, 'CUSTREQDEALERID')
        .subscribe(data => {
          this._BranchService.allBranchs = data
          if (this._BranchService.allBranchs.length == 1) {
            this._CustomerrequirementService.selectedRecord.BranchID = this._BranchService.allBranchs[0].Id
            this.onSelect(2)
          }
          else
            this._CustomerrequirementService.selectedRecord.BranchID = branchId
          this.fillproject();
          // this.GetAllModules();
        },
          error => {
            this.errorMsg = error;
          });
    }

  }

  fillproject() {
    this._projectService.fetchProject('BYBRANCHID', this._CustomerrequirementService.selectedRecord.BranchID == null ? 0 : this._CustomerrequirementService.selectedRecord.BranchID)
      .subscribe(data => {
        this._projectService.allProjects = data
        if (this._projectService.allProjects.length == 1)
          this._CustomerrequirementService.selectedRecord.ProjectID = this._projectService.allProjects[0].Id
        else
          this._CustomerrequirementService.selectedRecord.ProjectID = null
        this.GetAllModules();
      },
        error => {
          this.errorMsg = error;
        });
  }


  GetAllModules() {
    this.AMCRenewalStatus = false
    this._CustomerrequirementService.allCustomerReqHistory = []

    if (this._CustomerrequirementService.selectedRecord.CustomerID != 0
      && this._CustomerrequirementService.selectedRecord.BranchID != null
      && this._CustomerrequirementService.selectedRecord.ProjectID != null) {

      this._modulService.Fetch_ModuleServiceReport(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID)
        .subscribe(data => {
          this._modulService.allModuleServiceReport = data
        },
          error => {
            this.errorMsg = error;
          })

      //------------------------------------------------------------------------------------------------------------------------------
      //-----------------------------------Other General Data Loading-----------------------------------------------------------------
      //------------------------------------------------------------------------------------------------------------------------------
      this._CommonService.LoadSoftWareDtlsCommon(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID).
        subscribe(obj => {

          this._CustomerrequirementService.selectedRecord.Server = obj.NoOfServer
          this._CustomerrequirementService.selectedRecord.ContractVsCurrentDate = obj.ContractVsCurrentDate
          this._CustomerrequirementService.selectedRecord.Node = obj.Nodes
          if (obj.AMCUPTo == null)
            this._CustomerrequirementService.selectedRecord.AMCUPTo = null
          else {
            this._CustomerrequirementService.selectedRecord.AMCUPTo = new Date(obj.AMCUPTo)
            if (this._CustomerrequirementService.selectedRecord.AMCUPTo > new Date())
              this.AMCRenewalStatus = true
          }
          this._CustomerrequirementService.selectedRecord.ContractDate = obj.ContractDate == null ? null : new Date(obj.ContractDate)
        })

      this._CustomerrequirementService.GetCustomerRequirementsHistory(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID, this._CustomerrequirementService.selectedRecord.RefNo).subscribe(obj => {
        // this._CustomerrequirementService.allCustomerReqHistory = obj.CusReqDtls
        this._CustomerrequirementService.selectedCustomerRequirement_Common.NoOfVisits = obj.NoOfVisits;
        this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate == null ? null : new Date(obj.LastVisitDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
        this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate == null ? null : new Date(obj.LastUpdExeDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate == null ? null : new Date(obj.LastUpdScriptDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.TotalRequirements = obj.TotalRequirements;
      })
    }
    else {
      this._CustomerrequirementService.selectedCustomerRequirement_Common = {
        ExeUpdatedDate: null,
        LastVisit: null,
        NoOfVisits: 0,
        SE: 0,
        ScriptUpdatedDate: null,
        TotalRequirements:0
      }
    }
  }

  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        this.fileToUpload.push(files[i]);
      }
    }
  }

  RemoveImage(image: File) {
    const index = this.fileToUpload.indexOf(image);
    if (index > -1) {
      this.fileToUpload.splice(index, 1);
    }
  }
  RemovePreviousImage(obj: CustomerRequirement_Attachments) {
    const index = this._CustomerrequirementService.selectedRecord.CusAttachementList.indexOf(obj);
    if (index > -1) {
      this._CustomerrequirementService.selectedRecord.CusAttachementRemovedList.push(obj)
      this._CustomerrequirementService.selectedRecord.CusAttachementList.splice(index, 1);
    }
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid && this._CustomerrequirementService.allCustomerDtls != null && this._CustomerrequirementService.allCustomerDtls.length > 0) {
      if (this._CustomerrequirementService.selectedRecord.SEID == this.SupportEngId) {
        this.SubmitButtonDisable = true
        this._CommonService.isLoading = true;
        this._CustomerrequirementService.SaveItem(this._CustomerrequirementService.selectedRecord, this.fileToUpload, this._CommonService.LoginUser)
          .subscribe(data => {
            this._CommonService.isLoading = false;
            this.SubmitButtonDisable = false
            if (data.Status == 0) {
              this.resetForm(form);
              this.openPopup = 0;
              this.toastr.success(data.Message, 'Customer Requirements');
            }
            else
              this.toastr.warning(data.Message, 'Customer Requirements');
          },
            error => {
              this._CommonService.isLoading = false;
              this.errorMsg = error;
              this.SubmitButtonDisable = false
              this.toastr.error('New Record Added Failure', 'Customer Requirements');
            })
      }
      else
        this.toastr.warning('Logined User Not Having the Permission', 'Customer Requirements');

    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Customer Requirements');
    }
  }

  deleteFieldValue(index) {
    this._CustomerrequirementService.allCustomerDtls.splice(index, 1);
    this._CustomerrequirementService.RowCount = this._CustomerrequirementService.allCustomerDtls.length;
  }

  OpenDescPopup(field: CustomerRequirementsDtls, index: number) {
    if (index == 0)
      this.newAttributeorNot = false
    else
      this.newAttributeorNot = true
    this._CustomerrequirementService.DescriptionEdit = field.Description
    this.customerReqIndex = field.SNo
    this.OpenDescriptionPopup = true
    setTimeout(() => {
      var cntrl = document.getElementById('DescriptionEdit');
      if (cntrl) {
        cntrl.focus();
      }
    }, 300);
  }
  SetDescription() {
    if (this.newAttributeorNot)
      this._CustomerrequirementService.newAttribute.Description = this._CustomerrequirementService.DescriptionEdit
    else {
      this._CustomerrequirementService.allCustomerDtls.forEach(obj => {
        if (obj.SNo.toString() == this.customerReqIndex.toString())
          obj.Description = this._CustomerrequirementService.DescriptionEdit
      })
    }
    this._CustomerrequirementService.DescriptionEdit = ''
    this.OpenDescriptionPopup = false
  }

  addFieldValue() {
    if (this._CustomerrequirementService.newAttribute.Particular != 0 && this._CustomerrequirementService.newAttribute.ModuleId != 0 &&
      this._CustomerrequirementService.newAttribute.Description != '' && this._CustomerrequirementService.newAttribute.Type != 0
      && this._CustomerrequirementService.newAttribute.Priority != 0 && this._CustomerrequirementService.newAttribute.Status != 0) {
      this._CustomerrequirementService.allCustomerDtls.push(this._CustomerrequirementService.newAttribute)
      this._CustomerrequirementService.RowCount = this._CustomerrequirementService.allCustomerDtls.length;
      this._CustomerrequirementService.newAttribute = {
        ID: 0,
        RefNo: this._CustomerrequirementService.selectedRecord.RefNo,
        SNo: this._CustomerrequirementService.RowCount + 1,
        Particular: 0,
        ModuleId: 0,
        Description: '',
        Type: 0,
        Priority: 0,
        Status: 2,
        Remarks: ''
      }
    }
    else
      this.toastr.warning('Please Enter Fields', 'Customer Requirements')
  }

  SearchCustReq() {
    this.AMCRenewalStatus = false
    this._CustomerrequirementService.selectedRecord.CusAttachementList = []
    this.fileToUpload = []
    if (this._CustomerrequirementService.selectedRecord.RefNo != null)
      this._CustomerrequirementService.getCustomerRequirment(this._CustomerrequirementService.selectedRecord.DealerId).subscribe(obj => {
        if (obj.ID != 0) {
          this._CustomerrequirementService.FormUpdation = 'SAVE'
          this._CustomerrequirementService.selectedRecord = Object.assign({}, obj)
          this._CustomerrequirementService.selectedRecord.CusAttachementRemovedList = []
          var custDtls = this._CustomerService.allCustomers.filter(e => e.Id == this._CustomerrequirementService.selectedRecord.CustomerID)[0];
          this._CustomerrequirementService.selectedRecord.CustAdr1 = custDtls.Addr1;
          this._CustomerrequirementService.selectedRecord.CustAdr2 = custDtls.Addr2;
          this._CustomerrequirementService.selectedRecord.CustAdr3 = custDtls.Addr3;
          this._CustomerrequirementService.selectedRecord.Date = new Date(obj.Date)
          this.GetAllModules();
          this._CustomerrequirementService.allCustomerDtls = obj.CusReqDtls
          this._CustomerrequirementService.RowCount = this._CustomerrequirementService.allCustomerDtls.length;
          this._CustomerrequirementService.newAttribute = {
            SNo: this._CustomerrequirementService.RowCount + 1,
            Particular: 0,
            ModuleId: 0,
            Description: '',
            Type: 0,
            Priority: 0,
            Status: 2,
            Remarks: ''
          }
          this.fillbranch(this._CustomerrequirementService.selectedRecord.BranchID);
          this.fillproject();
        }
        else {
          this.resetForm()
          this.toastr.warning('RefNo Does Not exist', 'Customer Requirements')
        }
      })
  }

  DelaerChange() {
    this.SubmitButtonDisable = false
    this._CustomerrequirementService.FormUpdation = 'SAVE'
    this.fileToUpload = []
    this.fileInputLabel = ''
    $("#customFile").val('');
    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._BranchService.allBranchs = []
    this._projectService.allProjects = []
    this._CustomerrequirementService.selectedRecord.ID = 0
    this._CustomerrequirementService.selectedRecord.Time = null
    this._CustomerrequirementService.selectedRecord.ContractDate = null
    this._CustomerrequirementService.selectedRecord.ModeOfReg = 0
    this._CustomerrequirementService.selectedRecord.Date = new Date()
    this._CustomerrequirementService.selectedRecord.BranchID = null
    this._CustomerrequirementService.selectedRecord.BranchAdr1 = ""
    this._CustomerrequirementService.selectedRecord.BranchAdr2 = ""
    this._CustomerrequirementService.selectedRecord.BranchAdr3 = ""
    this._CustomerrequirementService.selectedRecord.AMCUPTo = null
    this._CustomerrequirementService.selectedRecord.CustomerID = 0
    this._CustomerrequirementService.selectedRecord.CustAdr1 = ""
    this._CustomerrequirementService.selectedRecord.CustAdr2 = ""
    this._CustomerrequirementService.selectedRecord.CustAdr3 = ""
    this._CustomerrequirementService.selectedRecord.ProjectID = null
    this._CustomerrequirementService.selectedRecord.Server = 0
    this._CustomerrequirementService.selectedRecord.Node = 0
    this._CustomerrequirementService.selectedRecord.NoOfVisits = 0
    this._CustomerrequirementService.selectedRecord.Address = ""
    this._CustomerrequirementService.selectedRecord.Note = ""
    this._CustomerrequirementService.selectedRecord.CusAttachementList = []
    this._CustomerrequirementService.selectedRecord.CusReqDtls = null
    this._CustomerrequirementService.selectedRecord.ContractVsCurrentDate = null

    this._modulService.allModuleServiceReport = []
    this._CustomerrequirementService.allCustomerDtls = []
    this._CustomerrequirementService.RowCount = 0;
    this._CustomerrequirementService.allCustomerReqHistory = []

    this.LoadCustomers();
    this._modulService.moduleList = []
    this._CustomerrequirementService.allCustomerDtls = []
  }
  SendToWorkOpenPopUp(form: NgForm, index) {
    if (form.valid && this._CustomerrequirementService.allCustomerDtls != null && this._CustomerrequirementService.allCustomerDtls.length > 0) {
      this.openPopup = index;
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Customer Requirements');
    }
  }
  closePopUp() {
    this.openPopup = 0;
  }
  onSubmitToWorkDirect(form: NgForm,type:number) {
    // let result: boolean=this._CustomerrequirementService.allCustomerDtls.every(e=>e.Priority!=3);
    let result: boolean = this._CustomerrequirementService.allCustomerDtls.every((e: any) => {
      return e.Priority ==3
    });
    if(type==3 && this.openPopup==0 && !result)
    {
      this.toastr.info('Emergency assign is only for critical jobs', 'Customer Requirements');
      return;
    }
    if (form.valid && this._CustomerrequirementService.allCustomerDtls != null && this._CustomerrequirementService.allCustomerDtls.length > 0 &&
      ((this.openPopup == 1 && this._CustomerrequirementService.selectedRecord.ExpectedDate != null && this._CustomerrequirementService.selectedRecord.ExpectedDate.toString().length > 0 && this._CustomerrequirementService.selectedRecord.Remark.length > 0)
        ||
        (this.openPopup == 2 && this._CustomerrequirementService.selectedRecord.ExpectedDate != null && this._CustomerrequirementService.selectedRecord.PlannedDeliveryDate != null && this._CustomerrequirementService.selectedRecord.PlannedDeliveryDate.toString().length > 0 && this._CustomerrequirementService.selectedRecord.ExpectedDate.toString().length > 0 && this._CustomerrequirementService.selectedRecord.Remark.length > 0))
        || type==3 && this.openPopup==0) 
      {
          if (this.openPopup == 1) 
            this._CustomerrequirementService.FormUpdation = 'SAVEToWorkStatus'
          else if (this.openPopup == 2)
            this._CustomerrequirementService.FormUpdation = 'SAVEToWorkAllotment'
          else if(type==3)
          {
            this._CustomerrequirementService.FormUpdation = 'EMERGENCYJOB'
            this._CustomerrequirementService.selectedRecord.Remark='EMERGENCY JOB'
          }
          this.openPopup = 0;
          this.onSubmit(form);
      }
      else 
        this.toastr.info('Please fill all mandatory fields.', 'Customer Requirements');
  }

  download(URL) {
    window.open(IMG_ROOT_URL + 'Images/SoftwareRegistration/' + URL, '_blank');
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var strTime = ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2);
    return strTime.toString();
  }

}
