import { ToastrService } from 'ngx-toastr';
import { error } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { CustomerService } from './../../../../Services/customer.service';
import { DealerService } from './../../../../Services/dealer.service';
import { ReportsService } from 'src/app/Services/reports.service';

@Component({
  selector: 'app-sytemdetails',
  templateUrl: './sytemdetails.component.html',
  styleUrls: ['./sytemdetails.component.css']
})
export class SytemdetailsComponent implements OnInit {
  public errorMsg:string

  constructor(private toastrService:ToastrService,
    public reportsService:ReportsService,
    public dealerService: DealerService,
    public customerService:CustomerService) { }

  ngOnInit(): void {
    this.reset();
    this.reportsService.loadDealer();
    this.reportsService.loadCustomer();
  }

  reset()
  {
    this.reportsService.allBranchWiseModuleDtls={
      Status:0,
      Message:null,
      AllBranchName:[]
    }
    this.reportsService.customerFilter=null;
    this.reportsService.type=1;
  }

  loadDealerWiseCustomer()
  {
    this.customerService.allCustomers=[];
    this.reportsService.customerFilter=null;
    if(this.reportsService.dealerFilter.length > 0)
      this.customerService.allCustomers=this.reportsService.allCustomerList.filter(data=>data.DealerId==parseInt(this.reportsService.dealerFilter));
    else
      this.customerService.allCustomers=this.reportsService.allCustomerList;
  }

  sytemDetails(transType:string,moduleCode:string,nodeRegId:number)
  {
    if(this.reportsService.dealerFilter.length > 0 && this.reportsService.customerFilter!=undefined)
    {
      this.reportsService.systemDetailsTrans(transType,moduleCode,nodeRegId).subscribe(
        data=>{
          if(data.Status==2)
          {
            this.reportsService.allBranchWiseModuleDtls=data;
            return;
          }
          if(data.Status==3)
            this.toastrService.success(data.Message,'System Details')
          else 
            this.toastrService.error(data.Message,'System Details')
        },
        error=>{this.errorMsg=error;}
      );
    }
    else
    {
       this.toastrService.info('Please select the dealer and customer','System Details')
       return;
    }
  }
    

}
