import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-particular-master',
  templateUrl: './particular-master.component.html',
  styleUrls: ['./particular-master.component.css']
})
export class ParticularMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
