import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyRegistration } from '../Models/companyReg';
import { ROOT_URL } from '../Models/Config';

@Injectable({
  providedIn: 'root'
})
export class CompanyregistrationService {

  constructor(private http: HttpClient, ) { }
  companyData:CompanyRegistration;
clearData(){
  this.companyData={Address1:'',Address2:'',Address3:'',Email:'',Name:'',SupportSite:'',Tel:'',URL:''}
}
submitCompanyReg(cntry: CompanyRegistration) : Observable<any> {  
  const headers =new HttpHeaders().set('contentType', 'false');
  var body = {
    Address1:this.companyData.Address1,
    Address2:this.companyData.Address2,
    Address3:this.companyData.Address3,
    Email:this.companyData.Email,
    Name:this.companyData.Name,
    SupportSite:this.companyData.SupportSite,
    Tel:this.companyData.Tel,
    URL:this.companyData.URL
  } 
  return this.http.post<any>(ROOT_URL + 'CompanyRegistration/saveCompanyInfo/', body, {
    headers, 
  })   
}
getCompanyInfo(): Observable<CompanyRegistration> {
  return this.http.get<CompanyRegistration>(ROOT_URL + 'CompanyRegistration/getCompanyInfo')
    

}
}
