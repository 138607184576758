import { Injectable } from '@angular/core';
import { designation } from '../Models/designation.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs/observable';
import { GlobalErrorHandlerService } from './global-error-handler.service'

@Injectable({
  providedIn: 'root'
})
export class DesignationService {
  selectedDesignation: designation;
  designationList: designation[];
  formHideShow = 0;
  allDesignations: designation[];
  parentUser: any = ''
  pageIndex: number = 0;
  isFiltering: boolean = false;
  CodeFilter: string = '';
  NameFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;

  constructor(private http: HttpClient, private __GlobalErrorHandlerService: GlobalErrorHandlerService) { }
  ClearFilter() {
    this.isFiltering = false;
    this.CodeFilter = '';
    this.NameFilter = '';

  }
  postDesignation(desig: designation) : Observable<any> {

    if (localStorage.getItem('userRoles') == "[" + '"' + "Admin" + '"' + "]")
      this.parentUser = 'Admin'
    else
      this.parentUser = 'Dealer'

    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Code: desig.Code,
      Name: desig.Name,
      ParentUser: this.parentUser
    }

    return this.http.post<any>(ROOT_URL + 'Designation/PostDesignation', body, {
      headers
    })
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
      );
  }

  putDesignation(desig: designation) : Observable<any> {
    const params = new HttpParams().set('ID', desig.Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    if (localStorage.getItem('userRoles') == "[" + '"' + "Admin" + '"' + "]")
      this.parentUser = 'Admin'
    else
      this.parentUser = 'Dealer'


    var body = {
      Code: desig.Code,
      Name: desig.Name,
      ParentUser: this.parentUser,
      ID: desig.Id
    }

    return this.http.put<any>(ROOT_URL + 'Designation/PutDesignation/' + desig.Id, body, {
      headers,
      params
    })
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))

      );
  }
  getDesignation(): Observable<designation[]> {
    return this.http.get<designation[]>(ROOT_URL + 'Designation/GetAllDesignation')
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))

      );

    //.catch(this.__GlobalErrorHandlerService.handleError);
  }

  deleteDesignation(desig: designation) {
    const params = new HttpParams().set('ID', desig.Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Code: desig.Code,
      Name: desig.Name,

      ID: desig.Id
    }
    return this.http.delete<any>(ROOT_URL + 'Designation/DeleteDesignation/' + desig.Id)
  }
}
