import { Component, OnInit } from '@angular/core';
import { ModuleService } from 'src/app/Services/module.service';
import { ToastrService } from 'ngx-toastr';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-particular-master-form',
  templateUrl: './particular-master-form.component.html',
  styleUrls: ['./particular-master-form.component.css']
})
export class ParticularMasterFormComponent implements OnInit {
  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  constructor(
    public _ParticularmasterService: ParticularmasterService,
    private toastr: ToastrService,
    public _modulService: ModuleService
  ) { }

  ngOnInit(): void {
    this._ParticularmasterService.formHideShow = 0;
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data

      },
        error => {
          this.errorMsg = error;
        })
    this.resetForm();
  }
  formValidation(): boolean {
    if (this._ParticularmasterService.selectedRecord.ModuleID != 0 && this._ParticularmasterService.selectedRecord.Particulars != "")
      return true;
    else
      return false;
  }

  onSubmit(frm: NgForm) {
    this.FormValidation = true
    if (frm.valid && this.formValidation()) {
      this.isSubmit = true
      if (frm.valid) {
        this._ParticularmasterService.SaveItem(this._ParticularmasterService.selectedRecord).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 0) {

              this.toastr.success(data.Message, "Particular Master")
              this._ParticularmasterService.formHideShow = 0;
              this.resetForm();
              this._ParticularmasterService.getParticularMaster("ALL").subscribe(data => this._ParticularmasterService.allparticular = data,
                error => {
                  this.errorMsg = error;
                });
            }
            else {
              this.toastr.warning(data.Message, "Particular Master")

            }
          }
        );

      }
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Particular Master');
    }
  }



  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._ParticularmasterService.formHideShow = 0;

  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._ParticularmasterService.selectedRecord = {
      ID: null,
      ModuleID: 0,
      ModuleName: '',
      Particulars: ''
    }
  }



}
