<div class="container-fluid">
    <h2 class="heading_all">Income Details</h2><br>   
        <div class="row">
            <label style="padding-top:7px;margin-left:17px;">From:</label>
             <input class="form-control" placeholder="From Date" name="From" id="From" #From="ngModel" [(ngModel)]="reportsService.selectedDate" style="color: black;text-align:left;width:180px;" autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
             <label>To:</label>
             <input class="form-control" placeholder="To Date" name="To" id="To" #To="ngModel" [(ngModel)]="reportsService.selectedToDate" style="color: black;text-align:left;width:180px;" autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
             <button type="button" style="margin-left:10px;" class="btn btn-sm btn-success" (click)="this.incomeDetails()">Filter</button>
        </div>  
        <div class="fixTableHead">
            <div class="row">
                <div class="col-md-12">
                    <table class="tablepl table" id="income">
                    <thead>
                        <tr>
                            <th style="width:50px;font-size:15px;">Sno</th>
                            <th style="width:100px;font-size:15px;">
                                Vno   
                            </th>
                            <th style="width:90px;font-size:15px;">
                                Date  
                            </th>
                            <th style="width:210px;font-size:15px;">
                                <div *ngIf="!reportsService.isFiltering">Dealer</div>                  
                                <div *ngIf="reportsService.isFiltering">
                                    <select name="Type" #Type="ngModel" class="drp" [(ngModel)]="this.reportsService.dealerFilter" (ngModelChange)="this.incomeDetails()">
                                        <option [ngValue]="''">Select Dealer</option>
                                        <option *ngFor="let dealer of this.dealerService.allDealers" value="{{dealer.Id}}">{{dealer.DealerName}}</option>
                                    </select>
                                </div>      
                            </th>
                            <th style="width:210px;font-size:15px;">
                                <div *ngIf="!reportsService.isFiltering">Customer</div>                                   
                                <div *ngIf="reportsService.isFiltering">
                                    <ng-select name="CustomerId" id="CustomerId"  placeholder="Select Customer" bindLabel="Name" #CustomerId="ngModel" 
                                        [(ngModel)]="this.reportsService.customerFilter" bindValue="Id" (ngModelChange)="this.incomeDetails()"
                                        [items]="this.customerService.allCustomers  | orderBy : 'Name'" required>
                                    </ng-select>
                                </div>  
                            </th>
                            <th class="hidden-xs" style="width:150px;font-size:15px;">   
                                <div *ngIf="!reportsService.isFiltering">Type</div>                             
                                <div *ngIf="reportsService.isFiltering">
                                    <select name="Type" #Type="ngModel" class="drp" [(ngModel)]="this.reportsService.typeFiter" (ngModelChange)="this.incomeDetails()">
                                        <option [ngValue]="''">Select VoucherType</option>
                                        <option *ngFor="let type of this.commonService.allCommonResult" value="{{type.Id}}">{{type.Name}}</option>
                                    </select>
                                </div>         
                            </th>
                            <th style="width:175px;">Description</th>
                            <th class="hidden-xs" style="width:80px;font-size:15px;">Amount
                                <a class="AtjIsPoint AtjfloatRight" (click)="reportsService.toggleFilter(1);this.incomeDetails()">
                                    <i *ngIf="!reportsService.isFiltering" class="fas fa-filter"></i>
                                    <i *ngIf="reportsService.isFiltering" class="fas fa-times"></i>
                                </a>
                            </th>
                        </tr>                 
                    </thead>
                    <tbody>
                        <tr *ngFor="let income of this.reportsService.incomedetails;let i=index">
                            <td *ngIf="this.reportsService.incomedetails.length > 1">{{i+1}}</td>
                            <td *ngIf="this.reportsService.incomedetails.length > 1">{{income.Vno==0?'':income.Vno}}</td>
                            <td *ngIf="this.reportsService.incomedetails.length > 1">{{income.Date}}</td>
                            <td *ngIf="this.reportsService.incomedetails.length > 1">{{income.Dealer}}</td>
                            <td *ngIf="this.reportsService.incomedetails.length > 1" title="{{income.Customer}}">{{income.Customer}}</td>
                            <td *ngIf="this.reportsService.incomedetails.length > 1" [ngClass]="{'NetTotal':income.Type=='Total'}">{{income.Type}}</td>
                            <td *ngIf="this.reportsService.incomedetails.length > 1" title="{{income.Description}}">{{income.Description}}</td>
                            <td *ngIf="this.reportsService.incomedetails.length > 1" [ngClass]="{'NetTotal':income.Type=='Total'}">{{income.Amount}}</td> 
                        </tr> 
                        <tr>
                            <td colspan="3" *ngIf="this.commonService.isLoading == false && this.reportsService.incomedetails.length <= 1">
                             No Records To Display.</td>
                        </tr>           
                    </tbody>
                    </table>
                </div>            
            </div>
        </div>
</div>
