<div class="container-fluid ">
    <h2 class="heading_all">Settings</h2>
    <div class="p-5 bg-white rounded shadow mb-5">
        <!-- Lined tabs-->
        <ul id="myTab2" role="tablist"
            class="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center">
            <li class="nav-item flex-sm-fill">
                <a id="home2-tab" data-toggle="tab" href="#home2" role="tab" aria-controls="home2" aria-selected="true"
                    class="nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0 active">Company Off Days</a>
            </li>
            <li class="nav-item flex-sm-fill">
                <a id="profile2-tab" data-toggle="tab" href="#profile2" role="tab" aria-controls="profile2"
                    aria-selected="false"
                    class="nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0">Holidays</a>
            </li>
            <li class="nav-item flex-sm-fill">
                <a id="contact2-tab" data-toggle="tab" href="#contact2" role="tab" aria-controls="contact2"
                    aria-selected="false" class="nav-link text-uppercase font-weight-bold rounded-0">Leave Details</a>
            </li>
        </ul>
        <div id="myTab2Content" class="tab-content">
            <div id="home2" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade px-4 py-5 show active">
                <!-- <div id="form-wrap"> -->

                <form #offdaydetails="ngForm" (ngSubmit)="onOffdaySubmit(offdaydetails)">
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <!-- <input type="checkbox" name="checkbox"> -->
                            <input style="width: 18px;height: 25px;" type="checkbox" id="Sunday" name="Sunday"
                                #Sunday="ngModel" [(ngModel)]="_LeavedetailsService.selectedRecordOffday.Sunday">
                            <span class="checkbox__control">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                    focusable="false">
                                    <path fill='none' stroke='currentColor' stroke-width='3'
                                        d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                                </svg>
                            </span>
                        </span>
                        <span class="radio__label">Sunday</span>
                    </label>
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <input style="width: 18px;height: 25px;" type="checkbox" id="Monday" name="Monday"
                                #Monday="ngModel" [(ngModel)]="_LeavedetailsService.selectedRecordOffday.Monday">
                            <span class="checkbox__control">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                    focusable="false">
                                    <path fill='none' stroke='currentColor' stroke-width='3'
                                        d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                                </svg>
                            </span>
                        </span>
                        <span class="radio__label">Monday</span>
                    </label>
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <input style="width: 18px;height: 25px;" type="checkbox" id="Tuesday" name="Tuesday"
                                #Tuesday="ngModel" [(ngModel)]="_LeavedetailsService.selectedRecordOffday.Tuesday">
                            <span class="checkbox__control">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                    focusable="false">
                                    <path fill='none' stroke='currentColor' stroke-width='3'
                                        d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                                </svg>
                            </span>
                        </span>
                        <span class="radio__label">Tuesday</span>
                    </label>
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <input style="width: 18px;height: 25px;" type="checkbox" id="Wednesday" name="Wednesday"
                                #Wednesday="ngModel" [(ngModel)]="_LeavedetailsService.selectedRecordOffday.Wednesday">
                            <span class="checkbox__control">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                    focusable="false">
                                    <path fill='none' stroke='currentColor' stroke-width='3'
                                        d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                                </svg>
                            </span>
                        </span>
                        <span class="radio__label">Wednesday</span>
                    </label>
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <input style="width: 18px;height: 25px;" type="checkbox" id="Thursday" name="Thursday"
                                #Thursday="ngModel" [(ngModel)]="_LeavedetailsService.selectedRecordOffday.Thursday">
                            <span class="checkbox__control">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                    focusable="false">
                                    <path fill='none' stroke='currentColor' stroke-width='3'
                                        d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                                </svg>
                            </span>
                        </span>
                        <span class="radio__label">Thursday</span>
                    </label>
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <input style="width: 18px;height: 25px;" type="checkbox" id="Friday" name="Friday"
                                #Friday="ngModel" [(ngModel)]="_LeavedetailsService.selectedRecordOffday.Friday">
                            <span class="checkbox__control">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                    focusable="false">
                                    <path fill='none' stroke='currentColor' stroke-width='3'
                                        d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                                </svg>
                            </span>
                        </span>
                        <span class="radio__label">Friday</span>
                    </label>
                    <label class="checkbox">
                        <span class="checkbox__input">
                            <input style="width: 18px;height: 25px;" type="checkbox" id="Saturday" name="Saturday"
                                #Saturday="ngModel" [(ngModel)]="_LeavedetailsService.selectedRecordOffday.Saturday">
                            <span class="checkbox__control">
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                    focusable="false">
                                    <path fill='none' stroke='currentColor' stroke-width='3'
                                        d='M1.73 12.91l6.37 6.37L22.79 4.59' />
                                </svg>
                            </span>
                        </span>
                        <span class="radio__label">Saturday</span>
                    </label>

                    <label>Daily Man Hours<span class="validation-error">*</span></label> :
                    <!-- <input type="text" name="hours" style="width: 5%;"> -->
                    <input name="ManHours" #ManHours="ngModel" autocomplete="off"
                        [(ngModel)]="_LeavedetailsService.selectedRecordOffday.ManHours" placeholder=" ManHours"
                        required>
                    <div class="validation-error"
                        *ngIf="(ManHours.invalid && ManHours.touched)||(ManHours.invalid && this.FormValidationoff)">
                        This
                        Field is
                        Required.</div>
                    <button [disabled]="this.isSubmitoff" type="submit" class=" sim-button button1"
                        style="background-color: teal;width: 10%;margin-top: 24px;margin-left: 24px;">Submit</button>
                    <!-- Change color, as for an error state-->
                    <!-- <label class="checkbox" style="--color: #FE3C53;">
                    <span class="checkbox__input">
                        <input type="checkbox" name="checked" checked>
                        <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3'
                                    d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                        </span>
                    </span>
                    <span class="radio__label">Checkbox - Checked</span>
                </label> -->

                    <!-- <label class="checkbox checkbox--disabled">
                    <span class="checkbox__input">
                        <input type="checkbox" name="disabled" disabled>
                        <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3'
                                    d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                        </span>
                    </span>
                    <span class="radio__label">Checkbox - Disabled</span>
                </label> -->

                    <!-- <label class="checkbox checkbox--disabled">
                    <span class="checkbox__input">
                        <input type="checkbox" name="disabled-checked" disabled checked>
                        <span class="checkbox__control">
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true"
                                focusable="false">
                                <path fill='none' stroke='currentColor' stroke-width='3'
                                    d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
                        </span>
                    </span>
                    <span class="radio__label">Checkbox - Disabled Checked</span>
                </label> -->
                </form>
                <!-- </div> -->
            </div>
            <div id="profile2" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade  py-5">
                <!-- px-4 -->
                <div id="form-wrap">
                    <form #holidaydetails="ngForm" (ngSubmit)="onHolidaySubmit(holidaydetails)">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Reason<span class="validation-error">*</span></label>
                                    <input name="Reason" #Reason="ngModel" autocomplete="off"
                                        [(ngModel)]="_LeavedetailsService.selectedRecordHoliday.Reason"
                                        placeholder=" Reason" required>
                                    <div class="validation-error" *ngIf="Reason.invalid && this.FormValidationho">
                                        This Field is Required.
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                <label>Notes</label>
                                <textarea name="notes" autocomplete="off" placeholder=""></textarea>
                            </div> -->

                                <div class="ui-input-container">
                                    <label class="ui-form-input-container">
                                        <!-- <textarea class="ui-form-input"></textarea> -->
                                        <textarea class="ui-form-input" name="Note" #Note="ngModel" autocomplete="off"
                                            [(ngModel)]="_LeavedetailsService.selectedRecordHoliday.Note"
                                            required></textarea>
                                        <div class="validation-error" *ngIf="Note.invalid && this.FormValidationho">
                                            This Field is Required.
                                        </div>
                                        <span class="form-input-label">Notes<span class="validation-error">*</span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>From<span class="validation-error">*</span></label>
                                        <input class="form-control" placeholder="From" name="From" #From="ngModel"
                                            [(ngModel)]="_LeavedetailsService.selectedRecordHoliday.From"
                                            style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required>
                                        <div class="validation-error"
                                            *ngIf="(From.invalid && From.touched)||(From.invalid && this.FormValidationho)">
                                            This Field is
                                            Required.</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>To<span class="validation-error">*</span></label>
                                        <input class="form-control" placeholder="To" name="To" #To="ngModel"
                                            [(ngModel)]="_LeavedetailsService.selectedRecordHoliday.To"
                                            style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required>
                                        <div class="validation-error"
                                            *ngIf="(To.invalid && To.touched)||(To.invalid && this.FormValidationho)">
                                            This
                                            Field is
                                            Required.</div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="this.isSubmitho" class=" sim-button button1"
                                style="background-color: teal;width: 10%;margin-top: 24px;">Submit</button>
                            <button type="button" class=" sim-button button1"
                                style="background-color: teal;width: 10%;margin-top: 24px;"
                                (click)="resetHolidayText(holidaydetails)">Clear</button>
                            <button type="button" class=" sim-button button1"
                                style="background-color: teal;width: 10%;margin-top: 24px;"
                                (click)="resetHolidayForm(holidaydetails)">Cancel</button>
                        </div>
                        <br>
                        <div class="row">
                            <table style="color: black;">
                                <thead>
                                    <tr>
                                        <th>#</th>

                                        <th class="hidden-xs">
                                            <div class="AtjIsPoint" (click)="sort('Reason')">
                                                <span *ngIf="!_LeavedetailsService.isFiltering">Reason</span>
                                                <div class="AtjfloatRight">
                                                    <i class="fas fa-sort"
                                                        *ngIf="_LeavedetailsService.orderByColName!='Reason' "></i>
                                                    <div *ngIf="_LeavedetailsService.orderByColName=='Reason'">
                                                        <i class="fas fa-sort-amount-down"
                                                            *ngIf="_LeavedetailsService.orderByDir"></i>
                                                        <i class="fas fa-sort-amount-up-alt"
                                                            *ngIf="!_LeavedetailsService.orderByDir"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="_LeavedetailsService.isFiltering">
                                                <input type="text" #txtReasonFilter="ngModel"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="_LeavedetailsService.ReasonFilter"
                                                    placeholder="Reason">
                                            </div>
                                        </th>
                                        <th class="hidden-xs">
                                            <div class="AtjIsPoint" (click)="sort('Note')">
                                                <span *ngIf="!_LeavedetailsService.isFiltering">Note</span>
                                                <div class="AtjfloatRight">
                                                    <i class="fas fa-sort"
                                                        *ngIf="_LeavedetailsService.orderByColName!='Note' "></i>
                                                    <div *ngIf="_LeavedetailsService.orderByColName=='Note'">
                                                        <i class="fas fa-sort-amount-down"
                                                            *ngIf="_LeavedetailsService.orderByDir"></i>
                                                        <i class="fas fa-sort-amount-up-alt"
                                                            *ngIf="!_LeavedetailsService.orderByDir"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="_LeavedetailsService.isFiltering">
                                                <input type="text" #txtNoteFilter="ngModel"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="_LeavedetailsService.NoteFilter" placeholder="Note">
                                            </div>

                                        </th>
                                        <th><em></em> Settings
                                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleHolidayFilter()">
                                                <i *ngIf="!_LeavedetailsService.isFiltering" class="fas fa-filter"></i>
                                                <i *ngIf="_LeavedetailsService.isFiltering" class="fas fa-times"></i>
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let holiday of _LeavedetailsService.allholidaydetails | orderBy : 'Reason' | gridOperations  :_LeavedetailsService.pageIndexH:
                                            {Reason:_LeavedetailsService.ReasonFilter,Note:_LeavedetailsService.NoteFilter}:
                                            {Column:_LeavedetailsService.orderByColName,Dir:_LeavedetailsService.orderByDir} ; let i = index"
                                        class="ng-scope">
                                        <td>{{i+1+((NoOfItemsH*_LeavedetailsService.pageIndexH)-NoOfItemsH)}}</td>
                                        <td>{{holiday.Reason}}</td>
                                        <td>{{holiday.Note}}</td>
                                        <td>
                                            <button type="button" class="btn-success" (click)="OnEditHoliday(holiday)">
                                                <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                                            <button type="button" class="btn-danger"
                                                (click)="onDeleteHoliday(holiday.Id)"><i class="fas fa-trash"
                                                    aria-hidden="true"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" *ngIf="(_LeavedetailsService.allholidaydetails?.length<=0 )">
                                            No Records To Display.
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                                        <app-grid-pagination (pageIndexValue)="_LeavedetailsService.pageIndexH=$event">
                                        </app-grid-pagination>
                                    </td>
                                </tfoot>
                            </table>
                        </div>

                    </form>
                </div>
            </div>

            <div id="contact2" role="tabpanel" aria-labelledby="contact-tab" class="tab-pane fade px-4 py-5">
                <div id="form-wrap">
                    <form #leavedetails="ngForm" (ngSubmit)="onSubmit(leavedetails)">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>Name<span class="validation-error">*</span></label>
                                    <input name="Name" #Name="ngModel" autocomplete="off"
                                        [(ngModel)]="_LeavedetailsService.selectedRecord.Name" placeholder=" Name"
                                        required>
                                    <div class="validation-error" *ngIf="Name.invalid && this.FormValidationle">
                                        This Field is Required.
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row"> -->
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>No.Of Leave<span class="validation-error">*</span></label>
                                    <input name="NoOfDays" #NoOfDays="ngModel" autocomplete="off"
                                        [(ngModel)]="_LeavedetailsService.selectedRecord.NoOfDays"
                                        placeholder=" No Of Days" required>
                                    <div class="validation-error"
                                        *ngIf="(NoOfDays.invalid && NoOfDays.touched)||(NoOfDays.invalid && this.FormValidationle)">
                                        This Field is
                                        Required.</div>
                                </div>
                            </div>

                            <button type="submit" [disabled]="this.isSubmitle" class=" sim-button button1"
                                style="background-color: teal;width: 10%;margin-top: 24px;">Submit</button>
                            <button type="button" class=" sim-button button1"
                                style="background-color: teal;width: 10%;margin-top: 24px;"
                                (click)="resetText(leavedetails)">Clear</button>
                            <button type="button" class=" sim-button button1"
                                style="background-color: teal;width: 10%;margin-top: 24px;"
                                (click)="resetForm(leavedetails)">Cancel</button>
                            <!-- </div> -->
                        </div>
                        <br>
                        <div class="row">
                            <table style="color: black;">
                                <thead>
                                    <tr>
                                        <th>#</th>

                                        <th class="hidden-xs">
                                            <div class="AtjIsPoint" (click)="sort('Name')">
                                                <span *ngIf="!_LeavedetailsService.isFiltering">Name</span>
                                                <div class="AtjfloatRight">
                                                    <i class="fas fa-sort"
                                                        *ngIf="_LeavedetailsService.orderByColName!='Name' "></i>
                                                    <div *ngIf="_LeavedetailsService.orderByColName=='Name'">
                                                        <i class="fas fa-sort-amount-down"
                                                            *ngIf="_LeavedetailsService.orderByDir"></i>
                                                        <i class="fas fa-sort-amount-up-alt"
                                                            *ngIf="!_LeavedetailsService.orderByDir"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="_LeavedetailsService.isFiltering">
                                                <input type="text" #txtNameFilter="ngModel"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="_LeavedetailsService.NameFilter" placeholder="Name">
                                            </div>

                                        </th>
                                        <th class="hidden-xs">
                                            <div class="AtjIsPoint" (click)="sort('NoOfDays')">
                                                <span *ngIf="!_LeavedetailsService.isFiltering">No Of Days</span>
                                                <div class="AtjfloatRight">
                                                    <i class="fas fa-sort"
                                                        *ngIf="_LeavedetailsService.orderByColName!='NoOfDays' "></i>
                                                    <div *ngIf="_LeavedetailsService.orderByColName=='NoOfDays'">
                                                        <i class="fas fa-sort-amount-down"
                                                            *ngIf="_LeavedetailsService.orderByDir"></i>
                                                        <i class="fas fa-sort-amount-up-alt"
                                                            *ngIf="!_LeavedetailsService.orderByDir"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="_LeavedetailsService.isFiltering">
                                                <input type="text" #txtNoOfDaysFilter="ngModel"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="_LeavedetailsService.NoOfDaysFilter"
                                                    placeholder="No Of Days">
                                            </div>

                                        </th>
                                        <th><em></em> Settings
                                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                                <i *ngIf="!_LeavedetailsService.isFiltering" class="fas fa-filter"></i>
                                                <i *ngIf="_LeavedetailsService.isFiltering" class="fas fa-times"></i>
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let leave of _LeavedetailsService.allleavedetails | gridOperations  :_LeavedetailsService.pageIndexL:
                                            {Name:_LeavedetailsService.NameFilter,NoOfDays:_LeavedetailsService.NoOfDaysFilter}:
                                           {Column:_LeavedetailsService.orderByColName,Dir:_LeavedetailsService.orderByDir} ; let i = index"
                                        class="ng-scope">
                                        <tr>
                                            <td>{{i+1+((NoOfItemsL*_LeavedetailsService.pageIndexL)-NoOfItemsL)}}</td>
                                            <td>{{leave.Name}}</td>
                                            <td>{{leave.NoOfDays}}</td>
                                            <td>
                                                <button type="button" class="btn-success" (click)="OnEdit(leave)">
                                                    <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                                                <button type="button" class="btn-danger" (click)="onDelete(leave.Id)"><i
                                                        class="fas fa-trash" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td colspan="4" *ngIf="(_LeavedetailsService.allleavedetails?.length<=0 ) ">
                                            No Records To
                                            Display.</td>
                                    </tr>

                                </tbody>
                                <tfoot>
                                    <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                                        <app-grid-pagination (pageIndexValue)="_LeavedetailsService.pageIndexL=$event">
                                        </app-grid-pagination>
                                    </td>
                                </tfoot>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- End lined tabs -->
    </div>

</div>

<div class="margintop"></div>
<app-delete-confirm *ngIf="deletePopUp1" [title]="'Leave Details'" (ConfirmEvent)="dialogResult($event)">
</app-delete-confirm>
<app-delete-confirm *ngIf="deletePopUp2" [title]="'Holiday Details'" (ConfirmEvent)="dialogResultHoliday($event)">
</app-delete-confirm>