<div class="container-fluid" *ngIf="_CustomerfeedbackService.formHideShow!=0">

    <input type="hidden" name="Id">
    <h2 class="heading_all">Customer FeedBack</h2>
    <br>
    <div id="form-wrap">
        <!-- <div class="row" style=" margin-top:36px;">
    
        <div class="col-md-12">
            <div class="FormBox"> -->
        <form #cusfeedForm="ngForm" (ngSubmit)="onSubmit(cusfeedForm)">
            <!-- <div class="row FormHeader sim-button_head">
                        <h3>Customer FeedBack</h3>
                    </div> <br> -->

            <div class="tab-content" id="form-wrap">
                <div id="sec1" class="container tab-pane active">

                    <div class="tWrap">
                        <div class="tWrap__head">
                            <table>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" style="width: 450px;"></th>
                                        <th scope="col">Bad</th>
                                        <th scope="col">Good</th>
                                        <th scope="col">Very Good</th>
                                        <th scope="col">Excellent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let field of this._CustomerfeedbackService.allQuesDtls | orderBy : 'Question';let i = index "
                                        class="ng-scope">
                                        <th style="background:white;color:black" scope="row">{{i+1}}</th>
                                        <td style="text-align: left;margin-left: 20px;">{{field.Question}} ?</td>
                                        <td>

                                            <!-- <input type="checkbox" style="width: 25px;height: 25px;" [checked]="selected === field.Question" (change)="selected = field.Question" [(ngModel)]="field.BadOption"  name="{{field.BadOption}}_radio" [value]="field.BadOption">  -->
                                            <input type="radio" style="width: 25px;height: 25px;"
                                                [checked]="field.BadOption" name="{{i}}" value="{{field.BadOption}}"
                                                (change)="handleChange('b',field)" required>

                                        </td>
                                        <td>

                                            <!-- <input type="checkbox" style="width: 25px;height: 25px;" [checked]="selected === field.Question" (change)="selected =field.Question" [(ngModel)]="field.GoodOption" name="{{field.GoodOption}}_radio" [value]="field.GoodOption">  -->
                                            <input type="radio" style="width: 25px;height: 25px;"
                                                [checked]="field.GoodOption" name="{{i}}" value="{{field.GoodOption}}"
                                                (change)="handleChange('g',field)" required>
                                        </td>
                                        <td>

                                            <!-- <input type="checkbox" style="width: 25px;height: 25px;" [checked]="selected === field.Question" (change)="selected = field.Question" [(ngModel)]="field.VeryGoodOption" name="{{field.VeryGoodOption}}_radio" [value]="field.VeryGoodOption">  -->
                                            <input type="radio" style="width: 25px;height: 25px;"
                                                [checked]="field.VeryGoodOption" name="{{i}}"
                                                value="{{field.VeryGoodOption}}" (change)="handleChange('v',field)"
                                                required>

                                        </td>
                                        <td>

                                            <!-- <input type="checkbox" style="width: 25px;height: 25px;" [checked]="selected ===field.Question" (change)="selected = field.Question" [(ngModel)]="field.ExcellentOption" name="{{field.ExcellentOption}}_radio" [value]="field.ExcellentOption">  -->

                                            <input type="radio" style="width: 25px;height: 25px;"
                                                [checked]="field.ExcellentOption" name="{{i}}"
                                                value="{{field.ExcellentOption}}" (change)="handleChange('l',field)"
                                                required>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <br>

                    <div class="form-group">
                        <label for="select">
                            What do you like about the staff
                            ?<span style="color: red;">*</span>
                        </label>
                        <!-- <div class="col-md-9"> -->
                        <!-- <textarea type="text" class="form-control" name="Likestaff" placeholder=" "
                                        required></textarea> -->
                        <textarea class="form-control" name="Likestaff" #Likestaff="ngModel" autocomplete="off"
                            [(ngModel)]="_CustomerfeedbackService.selectedRecord.Likestaff" placeholder=" Likestaff"
                            required></textarea>
                        <div class="validation-error"
                            *ngIf="(Likestaff.invalid && Likestaff.touched)||(Likestaff.invalid && this.FormValidation)">
                            This Field is
                            Required.</div>
                        <!-- </div> -->
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="select1">
                            What do you dislike about the
                            staff
                            ?<span style="color: red;">*</span>
                        </label>
                        <!-- <div class="col-md-9"> -->
                        <textarea class="form-control" name="DislikeStaff" #DislikeStaff="ngModel" autocomplete="off"
                            [(ngModel)]="_CustomerfeedbackService.selectedRecord.DislikeStaff"
                            placeholder=" DislikeStaff" required></textarea>
                        <div class="validation-error"
                            *ngIf="(DislikeStaff.invalid && DislikeStaff.touched)||(DislikeStaff.invalid && this.FormValidation)">
                            This Field is
                            Required.</div>
                        <!-- </div> -->
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" [disabled]="this.isSubmit" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                            (click)="resetForm(cusfeedForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>

                    <br>
                </div>
            </div>

        </form>


        <!-- </div>
        </div>

    </div> -->
    </div>
</div>