import { Injectable } from '@angular/core';
import { AMCRenewal } from '../Models/AMCRenewal.model';
import { AppConstants } from '../Models/constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { ModuleService } from './module.service';

@Injectable({
  providedIn: 'root'
})
export class AmcRenewalService {
  selectedAMCRenewal: AMCRenewal;
  AMCRenewalList: AMCRenewal[];
  formHideShow = 0;
  formHidePassword = false;
  allAMCRenewals: AMCRenewal[];
  imageUrl: string = AppConstants.tockenURL + "Images/Logo/default.png";
  moduleList: number[] = [];

  pageIndex: number = 0;
  isFiltering: boolean = false;
  DateFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';


  public currentStringDate1ToBind = new Date();
  public currentStringDate2ToBind = new Date();
  public currentStringDate3ToBind = new Date();
  public currentStringDate1: any;
  public currentStringDate2: any;
  public currentStringDate3: any;

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService, public _ModuleService: ModuleService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.DateFilter = '';

  }

  postAMCRenewal(obj: AMCRenewal) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Id: obj.Id,
      AmcId: obj.AmcId,
      CustomerId: obj.CustomerId,
      BranchId: obj.BranchId,
      ProjectId: obj.ProjectId,
      AMCRenewalDate: obj.AMCRenewalDate,
      Supporters: obj.Supporters
    }
    console.log(body)

    return this.http.post<AMCRenewal>(ROOT_URL + 'AMCRenewal/PostAMCRenewal', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }


  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }

  getAMCRenewal(): Observable<AMCRenewal[]> {
    return this.http.get<AMCRenewal[]>(ROOT_URL + 'AMCRenewal/GetAllAMCRenewal')
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  deleteAMCRenewal(DeleteID) {
    console.log('DeleteID', DeleteID)
    return this.http.delete<AMCRenewal>(ROOT_URL + 'AMCRenewal/DeleteAMCRenewal/' + DeleteID)
  }

  UploadImage(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload, fileToUpload.name);
    return this.http.post<any>(ROOT_URL + 'AMCRenewal/UploadImage', formData)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
