<div class="container-fluid">
    <h2 class="heading_all">Time Sheet </h2>
    <div id="form-wrap" style="font-size: 12px;">
        <br>
        <div class="row" style="font-size: 16px;font-weight: bold;">
            <div class="col-md-4"></div>
            <div class="col-md-4" style="text-align: center;">
                <input type="month" id="start" name="start" min="2021-09" #start="ngModel" [(ngModel)]="this._AttandanceregisterService.selectedDate" (change)="getdata()">
            </div>
            <div class="col-md-4 ">
                <div class="row">
                    <div class="col-md-12 ">Total Working Hours:
                        {{_AttandanceregisterService.attandanceDetails.TotalWorkingHrs}}
                    </div>
                    <!-- <div class="col-md-6 ">Total Attanded Hours:
                       {{_AttandanceregisterService.attandanceDetails.TotalAttandedHrs}}
                    </div> -->
                </div>
            </div>
        </div>
        <br>       
        <div class="tHead" [ngStyle]="{'height': _AttandanceregisterService.attandanceDetails.tableDetails.length == 1 ? '200' : '600' }">
            <table>
                <thead>
                    <tr *ngFor="let data of _AttandanceregisterService.attandanceDetails.tableDetails;let i =index;">
                        <ng-container *ngIf="i==0">
                            <th> SNO </th>
                            <th> Employee </th>
                            <ng-container *ngFor="let mnth of data.monthDays">
                                <th> {{mnth.Date |date:'EEE'}}
                                    <br>
                                    <span style="width: 100%; text-align: center;"> {{mnth.Date |date:'dd'}}</span>
                                </th>
                            </ng-container>
                            <th><b>Avg. Hrs</b> </th>
                        </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of _AttandanceregisterService.attandanceDetails.tableDetails;let i =index;">
                        <ng-container *ngIf="i!=0 && (i+1)!=lastIndex">
                            <td> {{i}}</td>
                            <td> {{data.Employee}} </td>
                            <ng-container *ngFor="let mnth of data.monthDays">
                                <td [ngClass]="{'offDay':mnth.DayType==2  &&  mnth.Value=='0','holiday':mnth.DayType==3 &&  mnth.Value=='0'}">
                                    {{mnth.Value}} 
                                </td>
                            </ng-container>
                            <td><b>{{data.AvgHrs}}</b></td>
                        </ng-container>
                    </tr>
                    <tr style="position:sticky; bottom: 0; z-index:1;  background-color:#209e91;"
                        *ngFor="let data of _AttandanceregisterService.attandanceDetails.tableDetails;let i =index;">
                        <ng-container *ngIf="(i+1)==lastIndex">
                            <td> </td>
                            <td></td>
                            <ng-container *ngFor="let mnth of data.monthDays">
                                <td title="{{mnth.Value}}" style="color: black;"
                                    [ngClass]="{'offDay':mnth.DayType==2,'holiday':mnth.DayType==3}">
                                    <span>
                                        {{mnth.Value}}
                                    </span>
                                </td>
                            </ng-container>
                            <td> </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="margintop"></div>
</div>