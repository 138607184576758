import { error } from '@angular/compiler/src/util';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Injectable } from '@angular/core';
import { CommonCodeNameMaster } from '../Models/CommonCodeNameMaster';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs/observable';
import { Response } from '../Models/Response';
import { pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChargeTypeService {

  formHideShow:number=0;
  selectedChargeType:CommonCodeNameMaster
  allChargeType:CommonCodeNameMaster[]=[];
  isFiltering: boolean = false;
  orderByDir: boolean = false;
  orderByColName: string = '';
  pageIndex: number = 0;
  NameFilter: string = '';
  CodeFilter: string = '';

  constructor(private http: HttpClient, public globalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter()
  {
    this.isFiltering = false;
    this.NameFilter='';
    this.CodeFilter='';
  }

  commonCodeNameMaster_Trans(data:CommonCodeNameMaster,formId:string):Observable<Response>
  {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const body={
      Id:data.Id,
      Code:data.Code,
      Name:data.Name,
      FormId:formId
    }
      return this.http.post<Response>(ROOT_URL +'ChargeType/CommonCodeNameMaster_Trans',body,{
        headers
      }).pipe(catchError((error)=>this.globalErrorHandlerService.handleError(error))
      );
  }

  getAllCommonCodeNameMaster(formId)
  {
    let params= new HttpParams();
    params=params.append('FormId',formId)
    params=params.append('TransType','Fetch')
    return this.http.get<CommonCodeNameMaster[]>(ROOT_URL +'ChargeType/GetAllCommonCodeNameMaster',{
       params
    }).
        pipe(catchError((error)=>this.globalErrorHandlerService.handleError(error))
      );
  }

  deleteCommonCodeNameMaster(Id:number,formId:string):Observable<Response>
  {
    let params=new HttpParams().set('Id',Id.toString())
    params=params.append('FormId',formId)
    return this.http.delete<Response>(ROOT_URL +'ChargeType/DeleteCommonCodeNameMaster',{
      params
    }).
        pipe(catchError((error)=>this.globalErrorHandlerService.handleError(error))
      );
  }
}
