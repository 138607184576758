import { Component, OnInit } from '@angular/core';
import { error } from 'console';
import { ToastrService } from 'ngx-toastr';
import { DatePeriod } from 'src/app/Models/DatePeriod';
import { CommonService } from 'src/app/Services/common.service';
import { JobschedulingService } from 'src/app/Services/jobscheduling.service';

@Component({
  selector: 'app-batchwise-jobdetails',
  templateUrl: './batchwise-jobdetails.component.html',
  styleUrls: ['./batchwise-jobdetails.component.css']
})
export class BatchwiseJobdetailsComponent implements OnInit {

  errorMsg:string=''
  dDates:DatePeriod={FromDate:'',ToDate:''}
  enabledates:boolean=true;

  constructor(public _JobschedulingService:JobschedulingService,
              public _CommonService:CommonService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
   this.setDate(this._JobschedulingService.selectedPeriod??1);
   this.loadBatch();
   this.loadCustomer();
  }

  setDate(id: Number) {
    this._CommonService.GetDateByPeriod(id).subscribe(
      data=>{
        this.dDates=data;
        this._JobschedulingService.fromDate = new Date(this.dDates.FromDate);
        this._JobschedulingService.toDate = new Date(this.dDates.ToDate);
      }
    );
  }

  periodChange(e: Event) {
    if (this._JobschedulingService.selectedPeriod == 11)
      this.enabledates = false;
    else {
      this.enabledates = true;
      this.setDate(this._JobschedulingService.selectedPeriod??1);
    }
  }

  loadCustomer()
  {
    this._CommonService.getCommonList('ALLCUSTOMER').subscribe(
      data=>{this._JobschedulingService.allCustomer=data},
      error=>{this.errorMsg=error}
    )
  }

  loadBatch()
  {
    this._JobschedulingService.selectedJobScheduling.Id=undefined;
    this._CommonService.getCommonList('BATCHLIST',this._JobschedulingService.selectedCustomer??0).subscribe(
      data=>{this._JobschedulingService.allBatch=data
        this.searchClear();
      },
      error=>{this.errorMsg=error}
    )
  }

  searchClear()
  {
    this._JobschedulingService.batchWisejob={BatchWiseJobHdr:[],BatchWiseJobDetails:[]};
  }

  validate():boolean
  {
    let result=true;
    if(this._JobschedulingService.fromDate==null || this._JobschedulingService.toDate==null ||
       this._JobschedulingService.selectedPeriod==null || this._JobschedulingService.selectedBatch==undefined 
      )
      {
        this.toastr.info('Please fill the mandatory fields', 'Job Details') 
        result=false;
      }
    return result;
  }

  searchJobDetails()
  {
    if(this.validate())
    {
      this._CommonService.isLoading=true;
      this._JobschedulingService.getBatchWiseJobDetails("JOBDETAILS").subscribe(
        data=>{
          this._JobschedulingService.batchWisejob=data;
          this._CommonService.isLoading=false;
        },error=>{this.errorMsg=error
          this._CommonService.isLoading=false;}
      )
    }
  }  
}
