import { Component, OnInit } from '@angular/core';
import { AmChartsService, AmChart } from '@amcharts/amcharts3-angular';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { CommonService } from 'src/app/Services/common.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { SEDashBoardCustReq } from 'src/app/Models/Dashboard.Model';
import { NgForm } from '@angular/forms';
import { EmployeeService } from 'src/app/Services/employee.service';

@Component({
  selector: 'app-project-leader-home',
  templateUrl: './project-leader-home.component.html',
  styleUrls: ['./project-leader-home.component.css']
})
export class ProjectLeaderHomeComponent implements OnInit {

  private chart: AmChart;
  public errorMsg;
  PriorityList: any;
  TypeList: any;
  StatusList: any;
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;

  constructor(
    public _CustomerrequirementService: CustomerrequirementService,
    public _CommonService: CommonService,
    public _BranchService: BranchService,
    public _serviceEngineerDBService: ServiceEngineerDBService,
    public _EmployeeService: EmployeeService,
    public _CustomerService: CustomerService,
    public AmCharts: AmChartsService
  ) { }

  ngOnInit(): void {
    this._CommonService.isLoading = false;
    this.Reset();
    this.getProgrammersList();
    this.LoadCustomers();
    this.fillServiceEngineer();
    this.PriorityList = this._CommonService.GetPriorityList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusList = this._CommonService.GetStatusFullList();
    this.LoadDealerDashboardTodaysSchdule();
    this._CommonService.storeloginUserId();
    this.loadchart();
  }

  loadchart()
  {
    var chart = this.AmCharts.makeChart("chartdiv2", {
      "type": "serial",
      "theme": "none",
      "dataProvider": [{
        "country": "USA",
        "visits": 2025
      }, {
        "country": "China",
        "visits": 1882
      }, {
        "country": "Japan",
        "visits": 1809
      }, {
        "country": "Germany",
        "visits": 1322
      }, {
        "country": "Brazil",
        "visits": 395
      }],
      "valueAxes": [{
        "gridColor": "#FFFFFF",
        "gridAlpha": 0.2,
        "dashLength": 0
      }],
      "gridAboveGraphs": true,
      "startDuration": 1,
      "graphs": [{
        "balloonText": "[[category]]: <b>[[value]]</b>",
        "fillAlphas": 0.8,
        "lineAlpha": 0.2,
        "type": "column",
        "valueField": "visits"
      }],
      "chartCursor": {
        "categoryBalloonEnabled": false,
        "cursorAlpha": 0,
        "zoomable": false
      },
      "categoryField": "country",
      "categoryAxis": {
        "gridPosition": "start",
        "gridAlpha": 0,
        "tickPosition": "start",
        "tickLength": 20
      },
      "export": {
        "enabled": true
      }
    });

      //gauge
      var chart = this.AmCharts.makeChart("chartdiv", {
      "theme": "light",
      // "titles": [{
      // "text": "My Chart Title",
      // "bold":"true",
      // "size":12,
      // }],
      "type": "gauge",
      "axes": [{
        "topTextFontSize": 20,
        "topTextYOffset": 70,
        "axisColor": "#31d6ea",
        "axisThickness": 1,
        "endValue": 100,
        "gridInside": true,
        "inside": true,
        "radius": "50%",
        "valueInterval": 50,
        "tickColor": "#67b7dc",
        "startAngle": -90,
        "endAngle": 90,
        "unit": "%",
        "bandOutlineAlpha": 0,
        "bands": [{
          "color": "#0080ff",
          "endValue": 100,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0,
        },
        {
          "color": "#3cd3a3",
          "endValue": 0,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0
        }]
      }],
      "arrows": [{
        "alpha": 1,
        "innerRadius": "35%",
        "nailRadius": 0,
        "radius": "170%"
      }]
    });

    setInterval(randomValue, 2000);
  
    // set random value
    function randomValue() {
      var value = Math.round(Math.random() * 100);
      chart.arrows[0].setValue(value);
      chart.axes[0].setTopText(value + " %");
      // adjust darker band to new value
      chart.axes[0].bands[1].setEndValue(value);
    }
  }

  Reset(form?: NgForm) {
    this._BranchService.selectedBranch = {
      Addr1: null,
      Addr2: null,
      Addr3: null,
      BranchEmail: null,
      BranchFax: null,
      BranchName: null,
      Customer:null,
      BranchPhone: null,
      ContactEmail: null,
      ContactPerson: null,
      ContactPhone: null,
      CountryId: null,
      CustomerId: null,
      DealerId: null,
      Id: null,
      RouteId: null,
      UniqueBrachId: '',
    }
    this._serviceEngineerDBService.selectedDate = new Date()
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    this._CustomerService.allCustomers = []
    this._CustomerrequirementService.allSE = []
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('ALL')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('SERVICEENG')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  LoadDealerDashboardTodaysSchdule() {
    this._CustomerrequirementService.DealerDashBoardTodaysSchdule = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("SLTODAYS", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.DealerDashBoardTodaysSchdule = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  p: number = 1;
  LoadDealerDashboardCustReqData() {
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("SLID", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.SelectedDealerDashBoardCustReq = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  SelectedIndexRefNo: number = null;
  SelectedIndexVtype: number = null;
  showForEdit(Backlog: SEDashBoardCustReq) {
    this.SelectedIndexRefNo = Backlog.RefNo;
    this.SelectedIndexVtype = Backlog.Vtype;
    this._serviceEngineerDBService.AllWorkSortingDet = []
    this._serviceEngineerDBService.getPendingWorksDet(Backlog.RefNo, Backlog.Vtype, Backlog.DealerId, 'COMMON').subscribe(data => {
      this._serviceEngineerDBService.AllWorkSortingDet = data
    })
    this._BranchService.Fetch_BranchById(Backlog.BranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.selectedBranch = obj[0]
    })
  }

  getProgrammersList() {
    this._EmployeeService.selectedDate=this.convert(this._serviceEngineerDBService.selectedDate.toString());
    this._EmployeeService.Fetch_Employee('ADMINPRMS').subscribe(data => {
      this._EmployeeService.allEmployees = data;
    })
  }

  //activates the menu with the coordinates
  onrightClick(event,id,empName) {
    this.contextmenuX = 120
    this.contextmenuY = 120
    this.contextmenu = true;
    this._EmployeeService.selectedEmployeeData(id,empName,0)
  }

  disableContextMenu() {
    this.contextmenu = false;
  }

  checkDate(date): boolean {
    date = new Date(date)
    return !(moment(date.toLocaleDateString()).isSame(new Date().toLocaleDateString()))
  }
}
