import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReasonmasterService } from 'src/app/Services/reasonmaster.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-reason-master-form',
  templateUrl: './reason-master-form.component.html',
  styleUrls: ['./reason-master-form.component.css']
})
export class ReasonMasterFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  constructor(
    public _ReasonmasterService: ReasonmasterService,
    private toastr: ToastrService,
  ) { }


  ngOnInit(): void {
    this._ReasonmasterService.formHideShow = 0;
    this.resetForm();
  }

  formValidation(): boolean {
    if (this._ReasonmasterService.selectedRecord.Reason != "" && this._ReasonmasterService.selectedRecord.Code != "")
      return true;
    else
      return false;
  }

  onSubmit(frm: NgForm) {
    this.FormValidation = true
    if (frm.valid && this.formValidation()) {
      this.isSubmit = true
      if (frm.valid) {
        this._ReasonmasterService.SaveItem(this._ReasonmasterService.selectedRecord).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 0) {
              this._ReasonmasterService.formHideShow = 0;
              this.toastr.success(data.Message, "Reason Master")
              this.resetForm();
              this._ReasonmasterService.getReasonMaster("ALL").subscribe(data => this._ReasonmasterService.allReasonMaster = data,
                error => {
                  this.errorMsg = error;
                });
            }
            else {
              this.toastr.warning(data.Message, "Reason Master")

            }
          }
        );
      }
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Reason Master');
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._ReasonmasterService.formHideShow = 0;
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._ReasonmasterService.selectedRecord = {
      ID: null,
      Code: '',
      Reason: ''
    }
  }

}
