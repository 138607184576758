<div class="container-fluid">
    <h2 class="heading_all">Project Manager Dashboard
        <span [class.disabled] = "this._CommonService.KeyExpiryCount.LicenseKey == 0" data-title="License Key"  class="icon" (click)="this._CommonService.expiryDetails(1)">
            <img src="../../../../../assets/Images/Licensekey.ico">({{this._CommonService.KeyExpiryCount.LicenseKey}})
        </span>
        <span [class.disabled] = "this._CommonService.KeyExpiryCount.AMC == 0" data-title="Expiry AMC Details" class="icon" (click)="this._CommonService.expiryDetails(2)">
            <img src="../../../../../assets/Images/tools.jpg">({{this._CommonService.KeyExpiryCount.AMC}})
        </span>
        <span [class.disabled] = "this._CommonService.KeyExpiryCount.CloudServer == 0" data-title="Cloud Server Details" class="icon" (click)="this._CommonService.expiryDetails(3)">
            <img src="../../../../../assets/Images/cloudserver.ico">({{this._CommonService.KeyExpiryCount.CloudServer}})
        </span>
        <span [class.disabled] = "this._CommonService.KeyExpiryCount.CustomerOutstanding == 0" data-title="Customer Outstanding Details" class="icon" (click)="this._CommonService.expiryDetails(4)">
            <img src="../../../../../assets/Images/customeroutstanding.ico">({{this._CommonService.KeyExpiryCount.CustomerOutstanding}})
        </span>
        <span data-title="Job Forecasting">
            <img src="../../../../../assets/Images/jobforecasting.png" (click)="jobForeCasting()">
        </span>
        <app-scheduledmeeting></app-scheduledmeeting>
    </h2>
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-4 panel" style="overflow: auto;">
                <section class="card">
                    <div class="card-header user-header alt bg-dark">
                        <div class="media">
                            <a href="#">
                                <img class="align-self-center rounded-circle mr-3" style="width:45px; height:38px;"
                                    alt="" src="../../../assets/Images/programmer.jpg">
                            </a>
                            <div class="media-body">
                                <h4 class="text-light display-9">QBS MEMBERS</h4>
                            </div>
                        </div>
                    </div>
                    <div id="table-scroll" (click)="disableContextMenu()" oncontextmenu="return false;">
                        <table class="table tablepl">
                            <tbody>
                                <tr *ngFor="let data of this._EmployeeService.allEmployees">
                                    <td style="width:22px;" (contextmenu)="onrightClick($event,data.Id,data.EmpName)"><img class="align-self-center rounded-circle mr-3"
                                            style="width:14px; height:14px;" alt="" src="../../../assets/Images/admin.jpg">
                                        <div *ngIf="contextmenu==true">
                                            <app-contextmenu [x]="contextmenuX" [y]="contextmenuY"></app-contextmenu>
                                        </div>
                                    </td>
                                    <td title="{{data.EmpName}}">
                                        <span *ngIf="data.TotalEstimatedHours.length > 0" class="badge badge-success pull-right">{{data.EmpName}}({{data.TotalEstimatedHours}})</span>
                                        <span *ngIf="data.TotalEstimatedHours.length == 0 && !data.IsPeningWork" class="badge badge-danger">{{data.EmpName}}</span>
                                        <span *ngIf="data.TotalEstimatedHours.length == 0 && data.IsPeningWork" class="badge badge-warning">{{data.EmpName}}</span>
                                    </td>
                                    <td><span *ngIf="data.LoginDate.length>0" class="badge badge-success pull-right">
                                            {{data.LoginDate}} </span></td>
                                    <td><span title="{{data.ModuleWorkingOn}}" *ngIf="data.ModuleWorkingOn.length>0"
                                            class="badge badge-default pull-right"> {{data.ModuleWorkingOn}} </span>
                                    </td>
                                    <td><span *ngIf="data.LastUpdatedDate.length>0"
                                            [ngClass]="{redText:checkDate(data.LastUpdatedDate)}" class="badge ">
                                            {{data.LastUpdatedDate}} </span>
                                    </td>
                                    <td title="OverDueJobs:{{data.OverDueCount}}"><span *ngIf="data.OverDueCount > 0"  style="width:40px;color:red;">
                                        {{data.OverDueCount}}</span> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>                       
                    </div>
                    <!-- <div class="card-footer">
                        <strong> Programmers</strong>
                    </div> -->
                </section>
            </div>

            <div class="col-md-4 panel">
                <img src="../../../../../assets/Images/PMS.png" style="width:420px;height:280px;">
            </div>
            <div class="col-md-4">
                <div class="panel">
                    <app-pie></app-pie>
                </div>
            </div>
        </div>
        <br>

        <div class="row">
            <div class="col-md-12">
                <label style="color: black;">Select Date:</label>
                <input class="section3" placeholder="Choose Date" style="color: black; text-align: left;"
                    autocomplete="off" #dp="bsDatepicker" bsDatepicker
                    [(ngModel)]="this._serviceEngineerDBService.selectedDate" name="selectedDate"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                    (bsValueChange)="LoadDealerDashboardTodaysSchdule();LoadDealerDashboardCustReqData()" (ngModelChange)="getProgrammersList()">
            </div>
        </div>
        <br>
        <div class="row" style="margin-right: 0px;margin-left: 0px;">
            <div class="tWrap">
                <div class="tWrap__head">
                    <table>
                        <thead>
                            <tr>
                                <th>SNo</th>
                                <th>SE</th>
                                <th>RefNo</th>
                                <th>Customer</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>ExpDate</th>
                                <th>OverDue</th>
                                <th>Priority</th>
                                <th>AMCStatus</th>
                                <th>ServiceMode</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="tWrap__body">
                    <table>
                        <tbody>
                            <tr *ngFor="let Backlog of this._CustomerrequirementService.DealerDashBoardTodaysSchdule ; let i = index"
                                class="ng-scope">
                                <td>{{i+1}}</td>
                                <td> {{Backlog.ServiceEngs}} </td>
                                <td>{{Backlog.RefNo}}</td>
                                <td>
                                    <span class="form-control-label"
                                        *ngFor="let field of  _CustomerService.allCustomers">
                                        <p *ngIf="field.Id==Backlog.CustomerId">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td>{{Backlog.RDate|date}}</td>

                                <td>
                                    <span class="form-control-label" *ngFor="let field of this.TypeList">
                                        <p *ngIf="Backlog.Type==field.Id">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td>{{Backlog.DDate|date}}</td>
                                <td> {{Backlog.OverDue}} </td>
                                <td>
                                    <span class="form-control-label" *ngFor="let field of this.PriorityList">
                                        <p *ngIf="Backlog.PriorityId==field.Id">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td> {{Backlog.AmcStatus}} </td>
                                <td>
                                    <label class="form-control-label">
                                        <p *ngIf="Backlog.ServiceMode==1">By Phone</p>
                                        <p *ngIf="Backlog.ServiceMode==2">By Mail</p>
                                        <p *ngIf="Backlog.ServiceMode==3">By Chat</p>
                                        <p *ngIf="Backlog.ServiceMode==4">On Site</p>
                                        <p *ngIf="Backlog.ServiceMode==5">Remote Section</p>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <br>
        <div class="row" style="margin-right: 0px;margin-left: 0px;">
            <div class="tWrap">
                <div class="tWrap__head">
                    <table>
                        <thead>
                            <tr>
                                <th>SNo</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Type</th>
                                <th>ExpDate</th>
                                <th>OverDue</th>
                                <th>Priority</th>
                                <th>Status</th>
                                <th>SE</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="tWrap__body">
                    <table>
                        <tbody>
                            <tr *ngFor="let Backlog of this._CustomerrequirementService.SelectedDealerDashBoardCustReq ; let i = index"
                                class="ng-scope" (click)="showForEdit(Backlog)">
                                <td>
                                    <span *ngIf="SelectedIndexRefNo==Backlog.RefNo&&SelectedIndexVtype==Backlog.Vtype">
                                        <i class='fas fa-chevron-right' style='font-size:20px'></i>
                                    </span>
                                    {{i+1}}
                                </td>
                                <td>{{Backlog.RDate|date}}</td>
                                <td>
                                    <span class="form-control-label"
                                        *ngFor="let field of  _CustomerService.allCustomers">
                                        <p *ngIf="field.Id==Backlog.CustomerId">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td>
                                    <span class="form-control-label" *ngFor="let field of this.TypeList">
                                        <p *ngIf="Backlog.Type==field.Id">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td>{{Backlog.DDate|date}}</td>
                                <td> {{Backlog.OverDue}} </td>
                                <td>
                                    <span class="form-control-label" *ngFor="let field of this.PriorityList">
                                        <p *ngIf="Backlog.PriorityId==field.Id">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td>
                                    <span class="form-control-label" *ngFor="let field of this.StatusList">
                                        <p *ngIf="Backlog.Status==field.Id">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td> {{Backlog.ServiceEngs}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <br>
        <div class="row" style="color: black;" *ngIf="this._BranchService.selectedBranch.Id!=null">
            <div class="col-md-4" style="background-color: #fff;    padding: 25px;    border: solid #209e91;">
                <div style="text-align: center;">
                    <h6 style="font-weight: bold;">Branch Details </h6>
                    <p *ngIf="this._BranchService.selectedBranch.BranchName!=null">
                        Branch Name:{{this._BranchService.selectedBranch.BranchName}}
                    </p>
                    <p *ngIf="this._BranchService.selectedBranch.BranchEmail!=null">
                        {{this._BranchService.selectedBranch.BranchPhone}},
                        {{this._BranchService.selectedBranch.BranchEmail}}
                    </p>
                    <p *ngIf="this._BranchService.selectedBranch.Addr1!=null">
                        {{this._BranchService.selectedBranch.Addr1}},
                        {{this._BranchService.selectedBranch.Addr2}},
                        {{this._BranchService.selectedBranch.Addr3}}
                    </p>
                    <p *ngIf="this._BranchService.selectedBranch.ContactPerson!=null">
                        Contact Person:
                        {{this._BranchService.selectedBranch.ContactPerson}}
                        ,{{this._BranchService.selectedBranch.ContactPhone}}</p>
                </div>
            </div>
            <div class="col-md-8" style="background-color: #fff;    padding: 25px;    border: solid #209e91;">
                <div style="height: 200px;    overflow-y: auto;">
                    <ol>
                        <li style="font-weight: bold;"
                            *ngFor="let field of this._serviceEngineerDBService.AllWorkSortingDet">
                            {{field.Description}}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Key expiry details -->
<app-keyexpirydetails *ngIf="this._CommonService.expDetailsPopup > 0" [expDetailsPopup]="this._CommonService.expDetailsPopup"></app-keyexpirydetails>
<!-- Employee wise work history -->
<app-workhisory *ngIf="this._EmployeeService.selectedEmployeeId > 0 && this._EmployeeService.employeepopup > 0" [employeeId]="this._EmployeeService.selectedEmployeeId" [employeeName]="this._EmployeeService.selectedEmployeeName"></app-workhisory>