// import { AmcRenewalService } from './amc-renewal.service';
import { Injectable } from '@angular/core';
import { employee } from '../Models/employee.model';
import { JobCard, JobDetails, JobCardNotes, JobCardHoldingReason, KeyWords } from '../Models/job-card';
import { Observable } from 'rxjs/observable';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { EmpWorkStatus } from '../Models/PLWorkDetailsHdr.model';
import { WorkDetails, Worksheet } from '../Models/Backlogs';
import { from } from 'rxjs';
import { SelectedWorkDoc } from '../Models/Settings';
import { Common } from '../Models/Response';
@Injectable({
  providedIn: 'root'
})
export class JobCardService {

  allEmployee: employee[]
  selectedJobCard: JobCard
  allJobCardDtl: JobCard[] = []
  newJobDetails: JobDetails
  newNotes: JobCardNotes
  jobCardOptionDtls: JobCard;
  RemovedNotesFiles: string[] = [];
  statusType:number=1;
  fromDate:Date;
  toDate:Date;

  Worksheetlist: Worksheet[];
  WorkDetails: WorkDetails[]
  From: Date
  To: Date
  EmployeeId: number = 0
  EmpWiseJobBatch:Common[]=[];
  
  constructor(public http: HttpClient,
    public _GlobalErrorHandlerService: GlobalErrorHandlerService) {
    this.selectedJobCard = {
      QAConclusion: null, TesterConclusion: null,
      StatusText: '',
      Emp_ID: null,
      Designation: null,
      TesterSucceed: false,
      JobDetails: [],
      JobCardNotes: [],
      TestResults: [],
      JobCardTestResultAttachments: [],
      JobCardNotesAttachment: [],
      JONO: null,
      IssueDate: new Date(),
      Activity: null,
      EstHrs: '0',
      ExpStDate: new Date(),
      CompDate: new Date(),
      OverdueBy: '',
      ModuleId:0,
      Module: '',
      Particulars: null,
      Status: null,
      Description: '',
      Remark: '', 
      WorkStatusRemark: '', 
      WorkSortingRemark: '',
      WorkAllotmentRemark:'',
      DealerId: 0,
      Vtype: null,
      FileList: null,
      CustomerName: '',
      PriorityId: 0,
      Type: 0,
      AssignedBy: '',
      AttSE: '',
      TestResult: '',
      Attempt:0,
      TResultWOutJobcard:false,
      IsDue:false
    }
  
    this.newJobDetails = {
      ID: 0,
      JONO: null,
      Date: new Date(),
      Emp_ID: null,
      Activity: null,
      W_Date: new Date(),
      W_DateFrom: new Date(),
      W_DateTo: new Date(),
      W_From: '',
      W_To: '',
      Break: null,
      NextDay: false,
      Status: null,
      StatusText: '',
      Batch: 0,
      HoldingReason: { HoldingReason: null, Remark: '', Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: 0, ID: 0, JONO: null, MettingWith: 0, OtherEmergencyWork: 0, Reason: '' }

    }
    this.newNotes = {
      ID: 0,
      JONO: null,
      Date: new Date(),
      Emp_ID: null,
      Activity: null,
      Notes: '', 
      EmpName: '',
      NoteTime:'',    
    }
  }
  selectedWorkDoc:SelectedWorkDoc[]=[];
  
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  Fetch_AllJobs(TransMode:string,Id: number,FromDate:string,ToDate:string,SelectedBatchId:number=0): Observable<JobCard[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('Id', Id.toString());
    params = params.append('StatusType', this.statusType.toString());
    params = params.append('FromDate', FromDate);
    params = params.append('ToDate', ToDate);
    params = params.append('BatchId', SelectedBatchId.toString());
    return this.http.get<JobCard[]>(ROOT_URL + 'JobCard/Fetch_AllJobs', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  deleteTesterKeyword(keyID: number) {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params = new HttpParams();
    params = params.append('Mode', 'KEYWORDELETE');
    params = params.append('EmpID', "0");
    params = params.append('JONO', keyID.toString());
    params = params.append('ActivityID', "0");
    return this.http.post<any>(ROOT_URL + 'JobCard/InsUpdJobCardDetails', {}, {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  SaveJobCardDetails(value: JobCard, fileToUpload: any[], mode: string): Observable<any> {
    let headers = new HttpHeaders().set('contentType', 'false');
    if (value.TesterConclusion.Status > 0 && value.TesterConclusion.ID == 0) {
      value.TesterConclusion.Emp_ID = this.selectedJobCard.Emp_ID;
      value.TesterConclusion.Activity = this.selectedJobCard.Activity;
      value.TesterConclusion.JONO = this.selectedJobCard.JONO;
      // pass current date also
    }
    if (value.QAConclusion.Status > 0 && value.QAConclusion.ID == 0) {
      value.QAConclusion.Emp_ID = this.selectedJobCard.Emp_ID;
      value.QAConclusion.Activity = this.selectedJobCard.Activity;
      value.QAConclusion.JONO = this.selectedJobCard.JONO;
      // pass current date also
    }
    let jobDetailsdateFiltered: any[] = [];
    value.JobDetails.forEach(data => {
      let obj;
      obj = data;
      let holddate = data.HoldingReason != null ? this.convert(data.HoldingReason.Date) : '';
      if (obj.HoldingReason)
        obj.HoldingReason.Date = holddate;

      obj.Date = this.convert(obj.Date);
      obj.W_Date = this.convert(obj.W_Date);
      obj.W_DateFrom = this.convert(obj.W_DateFrom);
      obj.W_DateTo = this.convert(obj.W_DateTo);
      jobDetailsdateFiltered.push(obj);
    })
    let jobcardNoteFiltered: any[] = [];
    value.JobCardNotes.forEach(data=>{
      let obj;
      obj=data;
      obj.Date=this.convert(obj.Date);
      jobcardNoteFiltered.push(obj);
    })
    var body = {
      EmpId: value.Emp_ID,
      Designation: value.Designation,
      JONO: value.JONO,
      Date: value.IssueDate,
      Activity: value.Activity,
      JobDetails: jobDetailsdateFiltered,// value.JobDetails,
      // JobCardNotes: value.JobCardNotes
      JobCardNotes: jobcardNoteFiltered,
      TestResults: value.TestResults,
      TesterConclusion: value.TesterConclusion,
      QAConclusion: value.QAConclusion,
      JobCardNotesAttachment: value.JobCardNotesAttachment,
      JobCardTestResultAttachments: value.JobCardTestResultAttachments,
    }

    let params = new HttpParams();
    params = params.append('Mode', mode);
    params = params.append('EmpID', value.Emp_ID.toString());
    params = params.append('JONO', value.JONO.toString());
    params = params.append('ActivityID', value.Activity.toString());

    const formData: FormData = new FormData();
    if (fileToUpload) {
      for (var i = 0; i < fileToUpload.length; i++) {
        let file: File;
        file = fileToUpload[i].file;
        formData.append('Document', file);
      }
    }
    
    if (value.JobCardTestResultAttachments) {
      value.JobCardTestResultAttachments.forEach(data => {
        if (data.ID == 0) {
          formData.append('TestResultAttachment', data.File);
        }
      })
    }
    formData.append('obj', JSON.stringify(body));
    var removedNotes = {
      file: this.RemovedNotesFiles
    }
    
    formData.append('RemovedNotesFiles', JSON.stringify(removedNotes));

    return this.http.post<any>(ROOT_URL + 'JobCard/InsUpdJobCardDetails', formData, {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  GetJobCardOptionDtls(Mode: string, Emp_ID: number, JONO: number, activity: number): Observable<JobCard> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('Mode', Mode);
    params = params.append('Emp_ID', Emp_ID.toString());
    params = params.append('JONO', JONO.toString());
    params = params.append('activity', activity.toString());
    return this.http.get<JobCard>(ROOT_URL + 'JobCard/GetJobCardOptionDtls', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  getHoldReason(ID: number): Observable<JobCardHoldingReason> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('JobDetailsID', ID.toString());
    return this.http.get<JobCardHoldingReason>(ROOT_URL + 'JobCard/GetHoldReason', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  GetKeyWords(activity): Observable<KeyWords[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('ActivityId', activity);
    return this.http.get<KeyWords[]>(ROOT_URL + 'JobCard/GetKeyWords', {
      headers,params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  GetWorkStatus(Jono: number, Activity: number): Observable<EmpWorkStatus[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<EmpWorkStatus[]>(ROOT_URL + 'ProjectLeader/GetWorkStatus?JONO=' + Jono + '&Activity=' + Activity, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  JobCardTimeValidation(obj: JobDetails): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + 'JobCard/JobCardTimeValidation', obj, {
      headers
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }

  Fetch_Worksheet(Id: number, From: Date, To: Date): Observable<Worksheet[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('Id', Id.toString()).append('from', this.convert(From)).append('to', this.convert(To));
    return this.http.get<Worksheet[]>(ROOT_URL + 'JobCard/Fetch_Worksheet', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  Fetch_WorkDetails(Id: number, From: Date, To: Date): Observable<WorkDetails[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('Id', Id.toString()).append('From', this.convert(From)).append('To', this.convert(To));
    return this.http.get<WorkDetails[]>(ROOT_URL + 'JobCard/Fetch_WorkDetails', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  getSelectedWorkDetails(EmployeeID,ModuleID)
  {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('EmployeeID',EmployeeID)
    params = params.append('ModuleID',ModuleID)
    return this.http.get<any[]>(ROOT_URL + 'JobCard/GetSelectedWorkDetails', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
}
