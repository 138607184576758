import { Injectable } from '@angular/core';
import { ReceiptPaymentDataList, Receipts,ReceiptDtls } from '../Models/Receipts';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { Response } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})

export class RecieptService {
 selectedReciept: Receipts;
 allDues: ReceiptPaymentDataList[];
 allReceiptDtls: ReceiptDtls[];
 FormUpdation: boolean = false
 totalSelectedDue:number=null;
 totalSelectedPaid:number=null;
 totalSelectedBalance:number=null;

  constructor(
    private http: HttpClient,
     public _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getReceiptPaymentData(CustomerId: number,DealerId: number): Observable<ReceiptPaymentDataList[]> {
    let params = new HttpParams();
    params = params.append('Date',this.convert(this.selectedReciept.Date)).append('CustomerId', CustomerId.toString()).append('DealerId', DealerId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ReceiptPaymentDataList[]>(ROOT_URL + 'Receipt/GetReceiptPaymentData', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  SaveItem(Receipts: Receipts, allReceiptDtls: ReceiptDtls[]): Observable<any> {
    var body = {
        Id: Receipts.Id
      , VNo: Receipts.VNo
      , Date:this.convert(Receipts.Date)
      , DealerId: Receipts.DealerId
      , CustomerId: Receipts.CustomerId
      , TotalDue: Receipts.TotalDue
      , Received: Receipts.Received
      , Discount: Receipts.Discount
      , Balance: Receipts.Balance
      , Remark: Receipts.Remark
      , ReceiptDtls: allReceiptDtls
    }
    let params = new HttpParams();
    params = params.append('TransMode', this.FormUpdation == false ? 'SAVE' : 'UPDATE')

    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<Response>(ROOT_URL + 'Receipt/Receipt_Trans', body, {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getReceipt(VNo: number): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('VNo', VNo.toString());
    return this.http.get<Receipts>(ROOT_URL + 'Receipt/GetReceipt', {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  deleteReceipt()
  {
    let params = new HttpParams();
    params = params.append('Vno', this.selectedReciept.VNo.toString());
    params = params.append('CustomerId', this.selectedReciept.CustomerId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete<Response>(ROOT_URL + "Receipt/DeleteReceipt", {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
