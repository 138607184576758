import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';
import { ServerService } from 'src/app/Services/server.service';

@Component({
  selector: 'app-server-form',
  templateUrl: './server-form.component.html',
  styleUrls: ['./server-form.component.css']
})
export class ServerFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean; 
  formValidation: boolean;
  dropdownSettings = {};

    constructor(
      public _Service: ServerService,
      public _CommonService:CommonService,
      private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    this._Service.formHideShow = 0;
    this.fillServerProvider();
    this.fillProject();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 4,
      searchPlaceholderText: 'Select Field',
      noDataAvailablePlaceholderText: 'No Data Found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    }
    this._CommonService.loadTerms(1);
    this.resetForm();
  }

  fillServerProvider()
  {
    this._CommonService.getCommonList("ALLSERVERPROVIDER").subscribe(
      data=>{
        this._Service.allServerProvider=data;
      }
    )
  }
  fillProject()
  {
    this._CommonService.getCommonList("PROJECTWITHOUTALLOPTION").subscribe(
      data=>{
        this._Service.allProject=data;
      }
    )
  }
  validation(): boolean {
    if (this._Service.selectedRecord.Server != "" && this._Service.selectedRecord.ServerProviderID > 0 && this._Service.selectedRecord.TermID > 0
      && this._Service.selectedRecord.ProjectList.length > 0 && this._Service.selectedRecord.RegDate!='' && this._Service.selectedRecord.ServerExpiry !='')
      return true;
    else
      return false;
  }
  onSubmit(frm: NgForm) {
    this.formValidation = true;
    if (frm.valid && this.validation()) {
      this.isSubmit = true;
        this._Service.trans(this._Service.selectedRecord).subscribe(
          async data => {
            this.isSubmit = false;
            if (data.Status == 1) {
              this._Service.formHideShow = 0;
              this.toastr.success(data.Message, "Server Master")
              this.resetForm();
              this._Service.get("ALL").subscribe(data => this._Service.allServer = data,
                error => {
                  this.errorMsg = error;
                });
            }
            else if (data.Status == 2) {
              this.toastr.warning(data.Message, "Server Master")
              return;
            }
            else {
              this.toastr.warning(data.Message, "Server Master")
            }
          }
        );
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Server Master');
      this.isSubmit = false;
    }
  }
  backList() {
    this.resetForm();
    this.isSubmit = false;
    this.formValidation = false
    this._Service.formHideShow = 0;
  }
  resetForm(form?: NgForm) {
    this.isSubmit = false;
    this.formValidation = false;
    if (form != null)
      form.reset();
     this._Service.clearValue();
  }
}
