import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { Ng2SearchPipeModule } from 'ng2-search-filter'; //importing the module
import { Ng2OrderModule } from 'ng2-order-pipe'; //importing the module
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module

import { AuthInterceptor } from './auth/auth.interceptor';
import { ErrorLoggService } from './Services/error-logg.service'
import { GlobalErrorHandlerService } from './Services/global-error-handler.service'

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { AuthGuard } from './auth/auth.guard';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GridOperationsPipe } from './CustomPipe/grid-operations.pipe';
import { GridPaginationComponent } from './Layout/common/grid-pagination/grid-pagination.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DatepickerComponent } from './Datepicker/customedatepicker';
import { CustomCustomerRequirementComponent } from './Modules/Admin/Support-Engineer/custom-customer-requirement/custom-customer-requirement.component';
import { CustomServiceReportEntryComponent } from './Modules/Admin/Support-Engineer/custom-service-report-entry/custom-service-report-entry.component';

import { ConfirmationDialogComponent } from './Layout/common/confirmation-dialog/confirmation-dialog.component';
import { DeleteConfirmComponent } from './Layout/delete-confirm/delete-confirm.component';
import { ProTestResultComponent } from './Modules/Provider/Project Leader/pro-test-result/pro-test-result.component';
import { LoadingComponent } from './Layout/loading/loading.component';
import { DeveloperModule } from './Modules/Provider/developer.module';
import { TesterModule } from './Modules/Provider/tester.module';
import { AdminModule } from './Modules/Admin/admin.module';
import { DealerModule } from './Modules/Admin/Dealers/dealer.module';
import { SupportengModule } from './Modules/Admin/Support-Engineer/supporteng.module';
import { SupportleaderModule } from './Modules/Admin/Support-Leader/supportleader.module';
import { ProjectmanagerModule } from './Modules/Provider/Project-Manager/projectmanager.module';
import { ProjectleaderModule } from './Modules/Provider/Project Leader/projectleader.module';
import { WorkhisoryComponent } from './Modules/Common/workhisory/workhisory.component';
import { ErrorInterceptor } from './auth/error.interceptor';

import { DateformatPipe } from './CustomPipe/dateformat.pipe';
import { KeyexpirydetailsComponent } from './Modules/Common/keyexpirydetails/keyexpirydetails.component';
import { PieComponent } from './Diagrams/pie/pie.component';

import { TwoDigitfloatValueDirective } from './CustomDirective/two-digitfloat-value.directive';
import { BlockPasteDirective } from './CustomDirective/block-paste.directive';
import { GaugeComponent } from './Diagrams/gauge/gauge.component';
import { ContextmenuComponent } from './Layout/common/contextmenu/contextmenu.component';
import { PopupmessageComponent } from './Layout/common/popupmessage/popupmessage.component';
import { ScheduledmeetingComponent } from './Modules/Provider/scheduledmeeting/scheduledmeeting.component';
import { JobschedulingComponent } from './Modules/Provider/jobscheduling/jobscheduling.component';
import { BatchwiseJobdetailsComponent } from './Modules/Provider/MIS/batchwise-jobdetails/batchwise-jobdetails.component';
import { AssignedJobsForecastingComponent } from './Modules/Provider/Project Leader/Job-Management/assigned-jobs-forecasting/assigned-jobs-forecasting.component';
import { ProjectCostAnalysisComponent } from './Modules/Provider/MIS/project-cost-analysis/project-cost-analysis.component';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    GridOperationsPipe,
    DateformatPipe,
    TwoDigitfloatValueDirective,
    BlockPasteDirective,
    GridPaginationComponent,
    ConfirmationDialogComponent,
    DeleteConfirmComponent,
    ProTestResultComponent,
    DatepickerComponent,
    CustomCustomerRequirementComponent,
    CustomServiceReportEntryComponent,
    LoadingComponent,
    WorkhisoryComponent,
    KeyexpirydetailsComponent,
    PieComponent,
    GaugeComponent,
    ContextmenuComponent,
    PopupmessageComponent,
    ScheduledmeetingComponent,
    JobschedulingComponent,
    BatchwiseJobdetailsComponent,
    AssignedJobsForecastingComponent,
    ProjectCostAnalysisComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    Ng2SearchPipeModule, //including into imports
    Ng2OrderModule, //add here
    NgxPaginationModule,//add here
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AmChartsModule,
    NgxMaterialTimepickerModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    DeveloperModule,
    TesterModule,
    AdminModule,
    DealerModule,
    SupportengModule,
    SupportleaderModule,
    ProjectmanagerModule,
    ProjectleaderModule
    // JobschedulingModule
  ],
  providers: [
    ErrorLoggService, // register global error log service
    GlobalErrorHandlerService,// register global error handler
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
