<div class="container-fluid" *ngIf="_modulService.formHideShow!=0">
    <h2 class="heading_all">MODULE</h2><br>
    <div id="form-wrap">
        <form #moduleForm="ngForm" (ngSubmit)="onSubmit(moduleForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_modulService.selectedModule.Id">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Module Code<span class="validation-error">*</span></label>
                    <input name="ModuleCode" #ModuleCode="ngModel" autocomplete="off" 
                        [(ngModel)]="_modulService.selectedModule.ModuleCode" placeholder=" ModuleCode" required>
                    <div class="validation-error"
                        *ngIf="(ModuleCode.invalid && ModuleCode.touched)||(ModuleCode.invalid &&this.FormValidation)">
                        This Field is Required.</div>
                </div>
                <div class="form-group row">
                    <label>Module<span class="validation-error">*</span></label>
                    <input name="ModuleName" #ModuleName="ngModel" autocomplete="off"
                        [(ngModel)]="_modulService.selectedModule.ModuleName" placeholder=" ModuleName" required>
                    <div class="validation-error"
                        *ngIf="(ModuleName.invalid && ModuleName.touched)||(ModuleName.invalid &&this.FormValidation)">
                        This Field is Required.</div>
                </div>
                <div class="form-group">
                   <input type="checkbox" name="Type" #Type="ngModel" [(ngModel)]="this._modulService.selectedModule.Type"><label>Common</label>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(moduleForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>