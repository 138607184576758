<div class="container-fluid" *ngIf="chargeTypeService.formHideShow!=0">
    <h2 class="heading_all">{{this.commonService.commonNameText}}</h2>
    <br>
    <div id="form-wrap">
        <form #CommonCodeNameForm="ngForm" (ngSubmit)="onSubmit(CommonCodeNameForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="this.chargeTypeService.selectedChargeType.Id">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Code<span class="validation-error">*</span></label>
                    <input name="Code"  autocomplete="off" #Code="ngModel" [(ngModel)]="this.chargeTypeService.selectedChargeType.Code"
                         placeholder="Code" required>
                    <div class="validation-error" *ngIf="Code.invalid && this.FormValidation">This Field is Required.</div>
                </div>
                <div class="form-group row">
                    <label>{{this.commonService.commonNameText}}<span class="validation-error">*</span></label>
                    <input name="Name" autocomplete="off" #Name="ngModel" [(ngModel)]="this.chargeTypeService.selectedChargeType.Name"
                         placeholder="Name" required>
                    <div class="validation-error" *ngIf="Name.invalid && this.FormValidation">This Field is  Required.</div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2" (click)="resetForm(CommonCodeNameForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3" (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>