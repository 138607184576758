<div class="container-fluid">
    <h2 class="heading_all">Work Allocation</h2>
    <br>
    <div id="form-wrap">
        <form #WorkAllocationForm="ngForm">
            <div class="row">
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">SlNo</th>

                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('RDate')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">RDate</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='RDate' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='RDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <input type="text" #txtRDateFilter="ngModel"
                                                [(ngModel)]="_WorkAllocationService.RDateFilter" placeholder="RDate"
                                                id="RDateFilter" name="RDateFilter">
                                        </div>

                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('RefNo')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">Ref No</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='RefNo' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='RefNo'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <input type="text" #txtRefNoFilter="ngModel"
                                                [(ngModel)]="_WorkAllocationService.RefNoFilter" placeholder="Ref No"
                                                id="RefNoFilter" name="RefNoFilter">
                                        </div>
                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('CustomerName')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">Customer</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='CustomerName' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='CustomerName'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <input type="text" #txtCustomerFilter="ngModel"
                                                [(ngModel)]="_WorkAllocationService.CustomerFilter"
                                                placeholder="Customer" id="CustomerFilter" name="CustomerFilter">
                                        </div>
                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('ModeOfReg')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">MOReg</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='ModeOfReg' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='ModeOfReg'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <select name="MORegFilter" id="MORegFilter"
                                                [(ngModel)]="_WorkAllocationService.MORegFilter">
                                                <option [value]="''" selected>Select</option>
                                                <option [ngValue]="1">By Phone</option>
                                                <option [ngValue]="2">By Mail</option>
                                                <option [ngValue]="3">By Chat</option>
                                                <option [ngValue]="4">On Site</option>
                                                <option [ngValue]="5">Remote Section</option>
                                            </select>
                                        </div>

                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('PriorityId')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">Priority</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='PriorityId' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='PriorityId'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <select name="PriorityFilter" id="PriorityFilter"
                                                [(ngModel)]="_WorkAllocationService.PriorityFilter">
                                                <option [value]="''">Select</option>
                                                <option *ngFor="let filed of PriorityList" [ngValue]="filed.Id">
                                                    {{filed.Name}}
                                                </option>
                                            </select>
                                        </div>

                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('EmpName')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">Att SE</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='EmpName' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='EmpName'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <input type="text" #txtAttSEFilter="ngModel"
                                                [(ngModel)]="_WorkAllocationService.AttSEFilter" placeholder="Att SE"
                                                id="AttSEFilter" name="AttSEFilter">
                                        </div>

                                    </th>

                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('Remark')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">Remarks</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='Remark' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='Remark'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <input type="text" #txtRemarksFilter="ngModel"
                                                [(ngModel)]="_WorkAllocationService.RemarksFilter" placeholder="Remarks"
                                                id="RemarksFilter" name="RemarksFilter">
                                        </div>

                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('ExpDate')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">ExpDate</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='ExpDate' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='ExpDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <input type="text" #txtExpDateFilter="ngModel"
                                                [(ngModel)]="_WorkAllocationService.ExpDateFilter" placeholder="ExpDate"
                                                id="ExpDateFilter" name="ExpDateFilter">
                                        </div>

                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('VType')">
                                            <span *ngIf="!_WorkAllocationService.isFiltering">VType</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkAllocationService.orderByColName!='VType' "></i>
                                                <div *ngIf="_WorkAllocationService.orderByColName=='VType'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkAllocationService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkAllocationService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkAllocationService.isFiltering">
                                            <input type="text" #txtVTypeFilter="ngModel"
                                                [(ngModel)]="_WorkAllocationService.VTypeFilter" placeholder="VType"
                                                id="VTypeFilter" name="VTypeFilter">
                                        </div>

                                    </th>
                                    <th><em></em> Settings
                                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                            <i *ngIf="!_WorkAllocationService.isFiltering" class="fas fa-filter"></i>
                                            <i *ngIf="_WorkAllocationService.isFiltering" class="fas fa-times"></i>
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr *ngFor="let obj of this._WorkAllocationService.AllWorkAllcation | gridOperations  :null: 
                                {RDate:_WorkAllocationService.RDateFilter,RefNo:_WorkAllocationService.RefNoFilter,CustomerName:_WorkAllocationService.CustomerFilter,
                                ModeOfReg:_WorkAllocationService.MORegFilter,PriorityId:_WorkAllocationService.PriorityFilter,EmpName:_WorkAllocationService.AttSEFilter,
                                Remark:_WorkAllocationService.RemarksFilter,ExpDate:_WorkAllocationService.ExpDateFilter,
                                VType:_WorkAllocationService.VTypeFilter}:
                               {Column:_WorkAllocationService.orderByColName,Dir:_WorkAllocationService.orderByDir} ; let i = index"
                                    class="ng-scope"
                                    [ngClass]="{'greenBg':obj.AssignedBy=='Fully Assigned','yellowBg':obj.AssignedBy=='Partialy Assigned'}">
                                    <!-- <tr *ngFor="let obj of this._WorkAllocationService.AllWorkAllcation ;let i=index"> -->
                                    <th style="background:white;color:black" scope="row">
                                        <i *ngIf="obj.SNo==this._WorkAllocationService.selectedWorkAllocationHdr.SNo"
                                            class='fas fa-chevron-right' style='font-size:20px'></i>
                                        {{i+1}}
                                    </th>
                                    <td>{{obj.RDate}}</td>
                                    <td>{{obj.RefNo}}</td>
                                    <td [title]="obj.CustomerName"> {{obj.CustomerName}} </td>
                                    <td>
                                        <div class="col-md-8">
                                            <label class="form-control-label">
                                                <p *ngIf="obj.ModeOfReg==0"> Select </p>
                                                <p *ngIf="obj.ModeOfReg==1" title="By Phone"> By Phone </p>
                                                <p *ngIf="obj.ModeOfReg==2" title="By Mail"> By Mail </p>
                                                <p *ngIf="obj.ModeOfReg==3" title="By Chat"> By Chat </p>
                                                <p *ngIf="obj.ModeOfReg==4" title="On Site"> On Site </p>
                                                <p *ngIf="obj.ModeOfReg==5" title="Remote Section">Remote Section</p>
                                            </label>
                                        </div>
                                    </td>
                                    <td>

                                        <span class="form-control-label" *ngFor="let pri of  PriorityList">
                                            <p *ngIf="pri.Id==obj.PriorityId" [title]="obj.Name">
                                                {{pri.Name}}
                                            </p>
                                        </span>
                                    </td>

                                    <td [title]="obj.EmpName"> {{obj.EmpName}} </td>

                                    <td [title]="obj.Remark"> {{obj.Remark}} </td>
                                    <td>
                                        {{obj.ExpDate}}
                                    </td>
                                    <td>
                                        {{obj.VType==0?'CR':'SRE'}}
                                    </td>
                                    <td>
                                        <i class="fas fa-eye" (click)="getWorkAllocationDet(obj);"
                                            style="font-size:24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br>
            <div class="form-group">
                <label style="font-size: larger;">Branch Details</label>
                <label *ngIf="this._BranchService.selectedBranch.BranchName!=null"
                    style="color: black;width: 1096px;line-height:20px;">
                    {{this._BranchService.selectedBranch.BranchName}},{{this._BranchService.selectedBranch.BranchPhone}},
                    {{this._BranchService.selectedBranch.BranchEmail}},{{this._BranchService.selectedBranch.Addr1}},
                    {{this._BranchService.selectedBranch.Addr2}},
                    {{this._BranchService.selectedBranch.Addr3}}, Contact Person:
                    {{this._BranchService.selectedBranch.ContactPerson}}
                    ,Tel:{{this._BranchService.selectedBranch.ContactPhone}}
                </label>
            </div>
            <div class="row">
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">*</th>
                                    <th scope="col">SlNo</th>
                                    <th scope="col">ActivityId</th>
                                    <th scope="col">Particular</th>
                                    <th scope="col">Module</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Priority</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">JoNo</th>
                                    <th scope="col">RefNo</th>
                                    <th scope="col">View</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr
                                    *ngFor="let field of this._WorkAllocationService.AllWorkAllocationDet ; let i = index">
                                    <td>
                                        <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                            #CheckStatus="ngModel" [(ngModel)]="field.CheckStatus"
                                            name="{{field.CheckStatus}}" [checked]="field.CheckStatus"
                                            value="{{field.CheckStatus}}">
                                    </td>
                                    <th style="background:white;color:black" scope="row">{{i+1}}</th>
                                    <td [title]="field.ID"> {{field.ID}} </td>
                                    <td [title]="field.ParticularName"> {{field.ParticularName}} </td>
                                    <td [title]="field.ModuleName"> {{field.ModuleName}} </td>
                                    <!-- <td [title]="field.Description"> {{field.Description}} </td> -->
                                    <td (click)="onUpdate(field)" [title]="field.Description">
                                        <label class="form-control-label">
                                            <p style="cursor: pointer;text-decoration: underline;">
                                                {{field.Description}}
                                            </p>
                                        </label>
                                    </td>
                                    <td>
                                        <label class="form-control-label" *ngFor="let obj of PriorityList">
                                            <p *ngIf="field.PriorityId==obj.Id">
                                                {{obj.Name}}
                                            </p>
                                        </label>
                                    </td>
                                    <td>
                                        <label class="form-control-label" *ngFor="let obj of StatusFullList">
                                            <p *ngIf="field.StatusId==obj.Id">
                                                {{obj.Name}}
                                            </p>
                                        </label>
                                    </td>
                                    <td>
                                        {{field.JoNo}}
                                    </td>
                                    <td>
                                        {{field.RefNo}}
                                    </td>
                                    <td>
                                        <i class="fas fa-eye" (click)="ViewJobOrderDet(field.JoNo)"
                                            style="font-size:24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- </div> -->
            </div>
            <br>
            <div class="form-group row">
                <h6 style="color: #008080">Previous Remarks</h6>
            </div>
            <div class="form-group row" style="height: 100px;  overflow: auto;color: black;">
                <div class="col-md-8">
                    <ol>
                        <li *ngFor="let obj of this._WorkAllocationService.RemarkHistory ">{{obj.Remark}}
                        </li>
                    </ol>
                </div>
                <div class="col-md-4">
                    <div class="tWrap">
                        <div class="tWrap__head">
                            <table>
                                <tbody>
                                    <tr>
                                        <th style="border: 1px solid #164e86;background:#aaeff3;color: black;">
                                            <div style="text-align: center;">
                                                <h6 style="font-weight: bold;">Attachments </h6>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <p>File Name</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p>File Type</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ----------------------------MODELS------------------------------- -->

            <div class="container-fluid">

                <!-- The Modal -->
                <div class="modal fade show" id="myModalConfirm">
                    <div class="modal-dialog">

                        <div *ngIf="serviceEngWF">
                            <!--class="modal-dialog" -->
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <span class="modal-title">Service Engineers Work Forecasting</span>
                                    <button type="button" class="close" (click)="OnClickCancel()">×</button>
                                </div>

                                <!-- Modal body -->
                                <div class="modal-body" style="overflow: scroll;height: 600px;">
                                    <!-- seat booking -->
                                    <div class="row">

                                        <div class="col-md-12">
                                            <div>
                                                <table id="chartHeader">
                                                    <tr>
                                                        <td>
                                                            <div style="background-color: #3ec7c0ed ;height: 20px;
                                                        width: 20px;display: inline-block;
                                                        border-radius: 3px;"> </div> Half Day Leave
                                                        </td>
                                                        <td>
                                                            <div style="background-color: #d6708bcc  ; height: 20px;
                                                        width: 20px;display: inline-block;
                                                        border-radius: 3px;"> </div> Full Day Leaves
                                                        </td>
                                                        <td>
                                                            <div style="background-color: #56bfe8; height: 20px;
                                                            width: 20px;display: inline-block;
                                                            border-radius: 3px;"> </div>
                                                            Off Days
                                                        </td>
                                                        <td>
                                                            <div style="background-color:red;height: 20px;
                                                        width: 20px;display: inline-block;
                                                        border-radius: 3px;"> </div>
                                                            Fully Alloted
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div style="background-color: yellow;height: 20px;
                                                                width: 20px;display: inline-block;
                                                                border-radius: 3px;"> </div>
                                                            Partialy Alloted
                                                        </td>

                                                        <td style="padding-right:25px;">
                                                            <div style="background-color: greenyellow;height: 20px;
                                                            width: 20px;display: inline-block;border-radius: 3px;"> </div>
                                                            Not Alloted
                                                        </td>
                                                        <td>
                                                            <div style="background-color: #573ec7ed;height: 20px;
                                                        width: 20px;display: inline-block;
                                                        border-radius: 3px;"> </div>
                                                            Holiday
                                                        </td>
                                                        <td></td>
                                                    </tr>

                                                </table>
                                            </div>
                                        </div>
                                        <div class="col-md-6"></div>

                                        <div class="row" style="margin-top: 20px;">

                                            <div class="col-md-3">

                                                <!-- <input type="radio" [(ngModel)]="DevTestToggle" [value]="0" name="devTest" id="dev"
                                                        #dev="ngModel" (click)="ToggleDevTest()"> <label style="color: black;" for="dev">Developer</label>
                                                    <input type="radio" [(ngModel)]="DevTestToggle" [value]="1" id="tstr"
                                                        #tstr="ngModel" name="devTest" (click)="ToggleDevTest()"> <label
                                                        for="tstr" style="color: black;">Tester</label> -->

                                            </div>
                                            <!-- <div class="col-md-4">
                                                    <input class="section3" placeholder="Choose Date" name="Date"
                                                        #Date="ngModel"
                                                        [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.Date"
                                                        style="color: black; text-align: center;width: 100px;"
                                                        autocomplete="off" bsDatepicker
                                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                </div> -->
                                            <div class="col-md-6">
                                                <!-- Select month and year  -->
                                                <div class="row" style="margin-bottom: 10px;">
                                                    <div class="col col-4"></div>
                                                    <div class="col col-4" style="text-align: center;color: black;">
                                                        <a style="float: left;" (click)="preMonth()"
                                                            class="btn btn-primary">

                                                            <i class="fas fa-chevron-circle-left"></i></a>
                                                        <label style="padding-top: 26px">{{filterDate | date:'MMM yyyy'}}</label>
                                                        <a style="float: right;" (click)="nextMonth()"
                                                            class="btn btn-primary"> <i
                                                                class="fas fa-chevron-circle-right"></i> </a>
                                                    </div>
                                                    <div class="col col-4"></div>

                                                </div>


                                            </div>
                                            <div class="col-md-3">
                                                {{JobOrderOrManHour}}
                                                <input type="radio" [(ngModel)]="JobOrderOrManHour" [value]="0"
                                                    name="type" id="JobOrder" #JobOrder="ngModel"><label for="JobOrder"
                                                    style="color: black;">Job Order</label>

                                                <input type="radio" [(ngModel)]="JobOrderOrManHour" [value]="1"
                                                    id="ManHours" #ManHours="ngModel" name="type"> <label
                                                    style="color: black;" for="ManHours">Man Hour</label>
                                            </div>
                                            <!-- <div class="row"> -->
                                            <br>
                                            <!-- </div> -->
                                            <div class="row">
                                                <div class="tWrap">
                                                    <div class="tWrap__head">
                                                        <table>
                                                            <ng-container
                                                                *ngFor="let data of _ProjectLeaderService.EmployeeWorkForcastDetails let i=index">
                                                                <tr>
                                                                    <td *ngIf="i!=0">
                                                                        {{data.EmpName}}
                                                                    </td>
                                                                    <th *ngIf="i==0"> </th>
                                                                    <ng-container
                                                                        *ngFor="let details of data.EmployeeWorkForcastDetails; let j=index">
                                                                        <th *ngIf="i==0">
                                                                            {{details.Date | date:'dd EEE' }}
                                                                        </th>
                                                                        <!-- <td *ngIf="i!=0"
                                                                            [ngClass]="{'redBg':details.Status==1,'yellowBg':details.Status==2,'greenBg':details.Status==0}">
                                                                            {{JobOrderOrManHour==0?
                                                                            details.JobOrder:details.ManHour}}
                                                                        </td> -->
                                                                        <td *ngIf="i!=0"
                                                                            [title]="JobOrderOrManHour==0? details.JobOrder:details.ManHour"
                                                                            [ngClass]="{'redBg':details.Status==1,'yellowBg':details.Status==2,'greenBg':details.Status==0,'darkBlue':details.IsLeave==3,'lightOrange':details.IsLeave==1,'lightCyan':details.IsLeave==2,'lightblue':details.IsLeave==4}">
                                                                            {{JobOrderOrManHour==0?
                                                                            details.JobOrder:details.ManHour}}
                                                                        </td>
                                                                    </ng-container>
                                                                </tr>
                                                            </ng-container>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-4" style="text-align: center;">
                                            <div class="row">
                                                <label class="col-md-4" style="color: black;">From:</label>
                                                <input class="col-md-4 datepickerSt" #From="ngModel"
                                                    [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.From"
                                                    placeholder="From Date" name="From"
                                                    (ngModelChange)="valuechange($event,'From')" autocomplete="off"
                                                    bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                <input class="col-md-4 form-control" name="FromTime" autocomplete="off"
                                                    id="exampleInputName1" required type="time" #FromTime="ngModel"
                                                    [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.FromTime">
                                            </div>
                                        </div>
                                        <div class="col-md-4" style="text-align: center;">
                                            <div class="row">
                                                <label class="col-md-4" style="color: black;">To:</label>
                                                <input class="col-md-4 datepickerSt" #To="ngModel"
                                                    [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.To"
                                                    placeholder="To Date" name="To" autocomplete="off" bsDatepicker
                                                    (ngModelChange)="valuechange($event,'To')"
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">

                                                <input class="col-md-4 form-control" name="ToTime"
                                                    id="exampleInputName1" required type="time" autocomplete="off"
                                                    #ToTime="ngModel"
                                                    [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ToTime">
                                            </div>
                                        </div>
                                        <div class="col-md-4" style="text-align: center;">
                                            <div class="row">
                                                <label class="col-md-5" style="color: black;">Service Mode:</label>

                                                <div class="col-md-7">
                                                    <select class="form-control" name="ServiceMode"
                                                        #ServiceMode="ngModel"
                                                        [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ServiceMode"
                                                        required (change)="getServiceEngWorks()">
                                                        <option value='0' disabled>Select</option>
                                                        <option value='4'>On Site</option>
                                                        <option value='5'>Remote Section</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <input type="radio" (change)="getServiceEngWorks()"
                                                [(ngModel)]=" this._WorkAllocationService.SelectedJobOrderHdr.EmployeeType"
                                                [value]="0" name="Etype" id="Support" #Support="ngModel"><label
                                                for="Support" style="color: black;">Support</label>

                                            <input type="radio" (change)="getServiceEngWorks()"
                                                [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.EmployeeType"
                                                [value]="1" id="Provider" #Provider="ngModel" name="Etype">
                                            <label style="color: black;" for="Provider">Provider</label>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="tWrap" style="overflow: auto; margin-bottom: 15px;">
                                                <div class="tWrap__head">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">*</th>
                                                                <th scope="col">SlNo</th>
                                                                <th scope="col">Service Engineer</th>
                                                                <th scope="col">Job Order</th>
                                                                <th scope="col">AMHrs</th>

                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div class="tWrap__body">
                                                    <table>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let obj of this._WorkAllocationService.AllServiceEngWorksForecast | orderBy : 'SEName';let i=index">
                                                                <td>
                                                                    <input type="checkbox" #checkStatus="ngModel"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [(ngModel)]="obj.checkStatus"
                                                                        name="obj.checkStatus"
                                                                        [checked]="obj.checkStatus"
                                                                        value="{{obj.checkStatus}}">
                                                                </td>
                                                                <th style="background:white;color:black" scope="row">
                                                                    {{i+1}}</th>
                                                                <td> {{obj.SEName}} </td>
                                                                <td>{{obj.JobOrder}}</td>
                                                                <td>{{obj.AmHrs}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="tWrap">
                                                <div class="tWrap__head">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">SlNo</th>
                                                                <th scope="col">Customer</th>
                                                                <th scope="col">From</th>
                                                                <th scope="col">To</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div class="tWrap__body">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <th style="background:white;color:black" scope="row">1
                                                                </th>
                                                                <td>test</td>
                                                                <td>test</td>
                                                                <td>test</td>
                                                            </tr>
                                                            <tr>
                                                                <th style="background:white;color:black" scope="row">2
                                                                </th>
                                                                <td>test</td>
                                                                <td>test</td>
                                                                <td>test</td>
                                                            </tr>
                                                            <tr>
                                                                <th style="background:white;color:black" scope="row">3
                                                                </th>
                                                                <td>test</td>
                                                                <td>test</td>
                                                                <td>test</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br>
                                                <div style="margin-left: 210px;">
                                                    <label class="form-control-label" for="select" style="color: black;font-weight: bold;">Service Charge</label>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <label class="form-control-label" for="select" style="color: #008080;font-size:small">Old Balance:</label>
                                                        <input
                                                            style="height:30.5px;line-height:1;border-radius:0;border: 1px solid #ced4da;"
                                                            type="text" name="ServiceEngWorkForecastingHdrServiceCharge"
                                                            disabled #ServiceEngWorkForecastingHdrServiceCharge="ngModel"
                                                            [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ServiceCharge"
                                                            required>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label class="form-control-label" for="select" style="color: #008080;font-size:small">Current Charge:</label>
                                                        <input type="text"
                                                            style="height:30.5px;line-height:1;border-radius:0;border: 1px solid #ced4da;"
                                                            (change)="onChangeCalc(this._WorkAllocationService.SelectedJobOrderHdr.CurrentCharge)"
                                                            name="CurrentCharge" #CurrentCharge="ngModel"
                                                            [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.CurrentCharge"
                                                            required>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <label class="form-control-label" for="select" style="color: #008080;font-size:small">Total</label>
                                                        <input type="text" name="ServiceCharge"
                                                            style="height:30.5px;line-height:1;border-radius:0;border: 1px solid #ced4da;"
                                                            #ServiceCharge="ngModel"
                                                            [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.ServiceCharge"
                                                            required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <label style="color:black" class="col-md-1" for="select">Note:</label>
                                        <textarea class="form-control" name="Notes" #Notes="ngModel" autocomplete="off"
                                            [(ngModel)]="this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.Notes"
                                            placeholder=" Notes" required></textarea>
                                        <div class="validation-error" *ngIf="Notes.invalid && Notes.touched">
                                            This Field is Required.
                                        </div>
                                    </div>
                                    <br>
                                    <div class="text-center" style="margin-top: 10px;">
                                        <button type="button" (click)="WorkForeCastingSubmit()"
                                            class="sim-button button1">Allot</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="jobOrderForm">

                            <!--class="modal-dialog" -->
                            <div class="modal-content">

                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <span class="modal-title">Job Order</span>
                                    <button type="button" class="close" (click)="OnClickCancel()">×</button>
                                </div>

                                <div class="modal-body" style="overflow: scroll;height: 600px;">
                                    <div class="tab-content">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Job.No</label>

                                                    <input class="form-control" #JoNo="ngModel" name="JoNo"
                                                        type="number" disabled
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.JoNo"
                                                        autocomplete="off" placeholder="Job No" required>
                                                </div>
                                                <div class="form-group">
                                                    <label>Issue
                                                        Date</label>

                                                    <input class="form-control" placeholder="Issue Date"
                                                        #IssueDate="ngModel" name="IssueDate" disabled
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.IssueDate"
                                                        style="color: black; text-align: center;" autocomplete="off"
                                                        bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                </div>
                                                <div class="form-group">
                                                    <label>SEs</label>

                                                    <div class="row" style="margin-left: 0px;"
                                                        *ngFor="let obj of this._WorkAllocationService.SelectedJobOrderHdr.SEWorkDet">
                                                        <label>
                                                            <input type="checkbox" disabled
                                                                #{{obj.checkStatus+i}}="ngModel" autocomplete="off"
                                                                [(ngModel)]="obj.checkStatus" name="obj.checkStatus"
                                                                [checked]="obj.checkStatus" value="{{obj.checkStatus}}">
                                                        </label>
                                                        <label class="form-control-label"
                                                            *ngFor="let field of  this._CustomerrequirementService.allSE">
                                                            <p *ngIf="field.Id==obj.SEId">
                                                                {{field.EmpName}}
                                                            </p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group ">
                                                    <label class="col-md-4 form-control-label">Scheduled</label>

                                                    <div>
                                                        <input class="section3" placeholder="Issue Date" name="From"
                                                            disabled #From="ngModel"
                                                            [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.From"
                                                            style="color: black; text-align: center;width: 100px;"
                                                            autocomplete="off" bsDatepicker
                                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                        <label>{{this._WorkAllocationService.SelectedJobOrderHdr.FromTime}}</label>

                                                        <input class="section3" placeholder="Issue Date" name="To"
                                                            disabled #To="ngModel"
                                                            [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.To"
                                                            style="color: black; text-align: center;width: 100px;"
                                                            autocomplete="off" bsDatepicker
                                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                        <label>{{this._WorkAllocationService.SelectedJobOrderHdr.ToTime}}</label>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <label>Customer</label>
                                                    <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel"
                                                        bindLabel="Name" bindValue="Id" disabled
                                                        [items]="_CustomerService.allCustomers" required
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.CustomerId">
                                                    </ng-select>
                                                </div>

                                                <div style="color: #008080;line-height:50px;">
                                                    <label style="padding-right: 50px;">Contract Date</label>:
                                                    <label
                                                        style="color:black">{{this._WorkAllocationService.SelectedJobOrderHdr.ContractDate}}</label>
                                                </div>
                                                <div style="color: red">
                                                    <label>{{this._WorkAllocationService.SelectedJobOrderHdr.ContractVsCurrentDate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label>Branch</label>
                                                    <ng-select name="BranchId" id="BranchId" #BranchId="ngModel"
                                                        disabled [items]="this._BranchService.allBranchs" bindValue="Id"
                                                        bindLabel="BranchName"
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchId">
                                                    </ng-select>
                                                </div>
                                                <div class="form-group">
                                                    <label>Address</label>
                                                    <input class="form-control" name="BranchAdr1" autocomplete="off"
                                                        #BranchAdr1="ngModel" disabled
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr1"
                                                        placeholder=" Addr1" required>

                                                    <input class="form-control" name="BranchAdr2" autocomplete="off"
                                                        #BranchAdr2="ngModel" disabled
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr2"
                                                        placeholder=" Addr2" required>

                                                    <input class="form-control" name="BranchAdr3" autocomplete="off"
                                                        #BranchAdr3="ngModel" disabled
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr3"
                                                        placeholder=" Addr3" required>
                                                    <input class="form-control" name="CPBranchPhone"
                                                        #CPBranchPhone="ngModel" disabled
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.CPBranchPhone"
                                                        type="text" autocomplete="off" placeholder="ContactPerson"
                                                        required>
                                                </div>
                                                <div class="form-group ">
                                                    <label>Contact
                                                        Person Branch</label>

                                                    <input class="form-control" name="CPBranch" autocomplete="off"
                                                        #CPBranch="ngModel" disabled
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.CPBranch"
                                                        placeholder=" Phone" required>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="select">Project<span
                                                            style="color: red;">*</span></label>
                                                    <ng-select name="ProjectId" id="ProjectId" #ProjectId="ngModel"
                                                        bindLabel="Name" bindValue="Id" disabled
                                                        [items]="this._projectService.allProjects" required
                                                        [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.ProjectId">
                                                    </ng-select>
                                                </div>
                                                <div class="form-group ">
                                                    <div *ngIf=" _modulService.allModuleServiceReport.length>0"
                                                        class="form-group ">
                                                        <label>Modules</label>
                                                    </div>
                                                    <div *ngIf=" _modulService.allModuleServiceReport.length>0"
                                                        class="form-group ">
                                                        <div class="col-md-12">
                                                            <div class="tWrap">
                                                                <div class="tWrap__head">
                                                                    <table>
                                                                        <thead>
                                                                            <th scope="col">Modules</th>
                                                                            <th scope="col">Version</th>
                                                                            <th scope="col">Last Update</th>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                                <div class="tWrap__body">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr
                                                                                *ngFor="let obj of _modulService.allModuleServiceReport | orderBy : 'ModuleName'; ;let i=index">
                                                                                <th
                                                                                    style="background:white;color:black;">
                                                                                    {{obj.ModuleName}}</th>
                                                                                <th
                                                                                    style="background:white;color:black;">
                                                                                </th>
                                                                                <th
                                                                                    style="background:white;color:black;">

                                                                                </th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="color: #008080;line-height: 40px;">
                                                    <label style="padding-right:50px">No of Visits</label>:
                                                    <label
                                                        style="color:black">{{this._WorkAllocationService.SelectedJobOrderHdr.NumberOfVisit}}</label>
                                                </div>
                                                <div style="color: #008080;line-height: 40px;">
                                                    <label style="padding-right:50px">Last Visited</label>:
                                                    <label
                                                        style="color: black">{{this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit|date}}</label>
                                                </div>
                                                <div style="color: #008080;line-height: 40px;">
                                                    <label style="padding-right:30px">Last Visited SE</label>:
                                                    <label style="color: black"
                                                        *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE==0">No
                                                        visitors</label>
                                                    <label style="color: black"
                                                        *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE!=0">{{this._CustomerrequirementService.selectedCustomerRequirement_Common.SE}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="tWrap">
                                                <div class="tWrap__head">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style="width: 60px;">SlNo</th>
                                                                <th scope="col" style="width: 350px;">Module</th>
                                                                <th scope="col" style="width: 350px;">Particulars</th>
                                                                <th scope="col" style="width: 350px;">Description</th>
                                                                <!-- <th scope="col">Remark</th>
                                                                <th scope="col">Status</th> -->
                                                                <th scope="col" style="width: 100px;">Action</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                                <div class="tWrap__body">
                                                    <table>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let field of this._WorkAllocationService.SelectedJobOrderHdr.WorkSortingAssigning;let i=index">
                                                                <th style="background:white;color:black;width: 60px;"
                                                                    scope="row">
                                                                    {{i+1}}</th>
                                                                <td style="width: 350px;">
                                                                    <select style="width: auto;" disabled
                                                                        class="form-control"
                                                                        [(ngModel)]="field.ModuleId"
                                                                        [name]="field.ModuleId" required>
                                                                        <option [ngValue]="0" disabled>
                                                                            Select</option>
                                                                        <option
                                                                            *ngFor="let obj of _modulService.allModules"
                                                                            [ngValue]="obj.Id">
                                                                            {{obj.ModuleName}}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td style="width: 350px;">
                                                                    <select style="width: 350px;;" disabled
                                                                        class="form-control"
                                                                        [(ngModel)]="field.Particular"
                                                                        [name]="field.Particular"
                                                                        #newAttributeParticular="ngModel" required>
                                                                        <option [ngValue]="0" disabled>
                                                                            Select</option>
                                                                        <option
                                                                            *ngFor="let obj of  this._ParticularmasterService.allparticular | orderBy : 'Particulars'"
                                                                            [ngValue]="obj.ID">
                                                                            {{obj.Particulars}}
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td style="width: 350px;">

                                                                    <!-- {{field.Description}} -->
                                                                    <textarea type="text" name="Remark" id="Remark"
                                                                        required [ngModel]="field.Description"
                                                                        style="width: 100%;" disabled></textarea>
                                                                </td>
                                                                <!-- <td>{{field.Remark}}</td>
                                                                <td>
                                                                    <select class="form-control"
                                                                        [(ngModel)]="field.StatusId" disabled
                                                                        #newAttributeStatusId="ngModel"
                                                                        [name]="field.StatusId" required>
                                                                        <option [ngValue]="0" disabled>
                                                                            Select</option>
                                                                        <option *ngFor="let obj of this.StatusFullList"
                                                                            [ngValue]="obj.Id">
                                                                            {{obj.Name}}</option>
                                                                    </select>
                                                                </td> -->
                                                                <td style="width: 100px;">
                                                                    <button type="button"
                                                                        (click)="DeleteRow(field)">-</button>
                                                                </td>
                                                           </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <label class="col-md-2  form-control-label" style="color:#008080;"
                                                for="select">Service Charge</label>
                                            <div class="col-md-4">
                                                <input type="text" placeholder="Service Charge" class="form-control"
                                                    name="ServiceCharge" #ServiceCharge="ngModel"
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.ServiceCharge"
                                                    required>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <button type="button" (click)="JobOrderDetSubmit()"
                                                class="sim-button button1"
                                                style="margin-left: 560px;margin-top: 10px;">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="WarningModel">

                            <div class="modal-content" style="width: 62%;height: auto;">
                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <button type="button" class="close" (click)="OnClickCancel()">×</button>
                                </div>

                                <div class="modal-body text-center">
                                    <p style="color: black;">Do you want to modify existing JobOrder?</p>
                                    <button type="button" class=" btn-success" (click)="confirm()">Yes</button>
                                    <button type="button" class=" btn-info" (click)="decline()">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="text-center">
                <button type="button" (click)="JobForeCastingClick()" class=" sim-button
                                    button1">Submit</button>
            </div>
            <!-- ----------------------------------------------------------------- -->
            <br>
            <!-- <div class="row">
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <tbody>
                                <tr>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;width: 300px">
                                        <div style="text-align: center;">
                                            <h6 style="font-weight: bold;">Branch Details </h6>
                                            <p *ngIf="this._BranchService.selectedBranch.BranchName!=null">
                                                Branch Name:{{this._BranchService.selectedBranch.BranchName}}
                                            </p>
                                            <p *ngIf="this._BranchService.selectedBranch.BranchEmail!=null">
                                                {{this._BranchService.selectedBranch.BranchPhone}},
                                                {{this._BranchService.selectedBranch.BranchEmail}}
                                            </p>
                                            <p *ngIf="this._BranchService.selectedBranch.Addr1!=null">
                                                {{this._BranchService.selectedBranch.Addr1}},
                                                {{this._BranchService.selectedBranch.Addr2}},
                                                {{this._BranchService.selectedBranch.Addr3}}
                                            </p>
                                            <p *ngIf="this._BranchService.selectedBranch.ContactPerson!=null">
                                                Contact Person:
                                                {{this._BranchService.selectedBranch.ContactPerson}}
                                                ,{{this._BranchService.selectedBranch.ContactPhone}}</p>
                                        </div>
                                    </th>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;">
                                        <div style="text-align: center;">
                                            <h6 style="font-weight: bold;">Attachments </h6>
                                            <br>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <p>File Name</p>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>File Type</p>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;">
                                        <div style="text-align: center;">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h6>Support Engineer</h6>
                                                </div>
                                                <div class="col-md-6">
                                                    <h6>No Of Works</h6>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row"
                                                *ngFor="let obj of this._WorkAllocationService.AllServiceEngWorks">
                                                <div class="col-md-6">
                                                    <h6>
                                                        <select class="form-control" [name]="obj.SEId" disabled
                                                            [(ngModel)]="obj.SEId" required>
                                                            <option [ngValue]="null" disabled>Select
                                                            </option>
                                                            <option
                                                                *ngFor="let obj of this._CustomerrequirementService.allSE"
                                                                [ngValue]="obj.Id">{{obj.EmpName}}</option>
                                                        </select>
                                                    </h6>
                                                </div>
                                                <div class="col-md-6">
                                                    <h6>{{obj.NoOfWorks}}</h6>
                                                </div>


                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->
        </form>

        <form *ngIf="DscptnVisible==1">
            <div class="popup-container">

                <input type="checkbox" id="hold-cnfirm" checked>
                <div class="popup HoldingReason" (click)="closePopUp()">

                    <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                        <div class="title" style="color: black;">
                            <p><b>Description</b></p> <label for="test-results"> <i class="fas fa-times"
                                    style="color: black;" (click)="closePopUp()"></i>
                            </label>
                        </div>
                        <div>
                            <div class="row HoldingReason">
                                <div class="col-md-12">
                                    <div>

                                        <div class="row">

                                            <div class="col-12">
                                                <div class="form-group">


                                                    <textarea disabled
                                                        style="overflow-y: scroll; min-height: 290px;     resize: none;"
                                                        class="form-control" [value]="Description"></textarea>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>