import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { module, moduleCheckBox, ModuleServiceReport } from '../Models/module.model';
import { GlobalErrorHandlerService } from './global-error-handler.service'

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  selectedModule: module
  moduleList: module[]
  formHideShow = 0;
  allModules: module[];
  allModulesCheckBox: moduleCheckBox[];
  allModuleServiceReport: ModuleServiceReport[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  ModuleNameFilter: string = '';
  ModuleCodeFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;
  constructor(private http: HttpClient, private _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.ModuleNameFilter = '';
    this.ModuleCodeFilter = '';
  }

  postModule(modul: module): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Id: modul.Id,
      ModuleName: modul.ModuleName,
      ModuleCode: modul.ModuleCode,
      Type: modul.Type
    }

    return this.http.post<any>(ROOT_URL + 'Module/PostModule', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getModule(): Observable<module[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      ModuleName: '',
      ProjectId: '',
      ID: 0
    }
    return this.http.post<any>(ROOT_URL + 'Module/Fetch_Module', body, {
      headers
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }

  deleteModule(DeletedID:number) {
    return this.http.delete<any>(ROOT_URL + 'Module/DeleteModule/' + DeletedID)
  }

  getModuleCheckBox(): Observable<moduleCheckBox[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      ModuleName: '',
      ProjectId: '',
      ID: 0
    }
    return this.http.post<any>(ROOT_URL + 'Module/Fetch_ModuleCheckBox', body, {
      headers
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))

    );
  }

  Fetch_ModuleServiceReport(CustomerId: number, BranchId: number, ProjectId: number): Observable<ModuleServiceReport[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<any>(ROOT_URL + 'Module/Fetch_ModuleServiceReport?CustomerId=' + CustomerId + '&BranchId=' + BranchId + '&ProjectId=' + ProjectId, {
      headers
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
}
