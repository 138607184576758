<div class="container-fluid" *ngIf="cloudChargeService.formHideShow==0">
    <h2 class="heading_all">CS Cancellation</h2>
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">
                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width:60%;"><input type="text" class="form-control-search" name="search"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Vno')">
                            <span *ngIf="!cloudChargeService.isFiltering">VNo</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="cloudChargeService.orderByColName!='Vno' "></i>
                                <div *ngIf="cloudChargeService.orderByColName=='Vno'">
                                    <i class="fas fa-sort-amount-down" *ngIf="cloudChargeService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!cloudChargeService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cloudChargeService.isFiltering">
                            <input type="text" #txtRegNoFilter="ngModel" [(ngModel)]="cloudChargeService.RegNoFilter"
                                placeholder="VNo">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Customer')">
                            <span *ngIf="!cloudChargeService.isFiltering">Customer</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="cloudChargeService.orderByColName!='Customer' "></i>
                                <div *ngIf="cloudChargeService.orderByColName=='Customer'">
                                    <i class="fas fa-sort-amount-down" *ngIf="cloudChargeService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!cloudChargeService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cloudChargeService.isFiltering">
                            <input type="text" #txtCustomerFilter="ngModel" [(ngModel)]="cloudChargeService.CustomerFilter"
                                placeholder="Customer">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Date')">
                            <span *ngIf="!cloudChargeService.isFiltering">Date</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="cloudChargeService.orderByColName!='Date' "></i>
                                <div *ngIf="cloudChargeService.orderByColName=='Date'">
                                    <i class="fas fa-sort-amount-down" *ngIf="cloudChargeService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!cloudChargeService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cloudChargeService.isFiltering">
                            <input type="text" #txtDateFilter="ngModel" [(ngModel)]="cloudChargeService.DateFilter"
                                placeholder="Date">
                        </div>
                    </th>
                    <th style="width:170px;"><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!cloudChargeService.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="cloudChargeService.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let obj of this.cloudChargeService.allCSCancellation | gridOperations:cloudChargeService.pageIndex:
                        {VNo:cloudChargeService.RegNoFilter,Customer:cloudChargeService.CustomerFilter,Date:cloudChargeService.DateFilter}:
                        {Column:cloudChargeService.orderByColName,Dir:cloudChargeService.orderByDir};let i = index" class="ng-scope">
                    <td>{{i+1+((NoOfItems*cloudChargeService.pageIndex)-NoOfItems)}}</td>
                    <td>{{obj.VNo}}</td>
                    <td>{{obj.Customer}}</td>
                    <td>{{obj.Date}}</td>
                    <td style="width:170px;">
                        <button type="button" class="btn-success" (click)="showForEdit(obj)">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                        <button type="button" class="btn-danger" (click)="onDelete(obj.Id)"><i class="fas fa-trash" aria-hidden="true"></i></button>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" *ngIf="(cloudChargeService.allCSCancellation?.length<=0 )  || commonService.filterdItemCount <1">
                        No Records To Display.
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="commonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="cloudChargeService.pageIndex=$event"></app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <div class="margintop"></div>
    </div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'CS Cancellation'" (ConfirmEvent)="dialogResult($event)">
</app-delete-confirm>