import { Component, OnInit } from '@angular/core';
import { DesignationService } from 'src/app/Services/designation.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-designation-form',
  templateUrl: './designation-form.component.html',
  styleUrls: ['./designation-form.component.css']
})
export class DesignationFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  constructor(public _desigServive: DesignationService, public router: Router, public toastr: ToastrService) { }

  ngOnInit(): void {
    this._desigServive.formHideShow = 0;
    this.resetForm();
  }
  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._desigServive.selectedDesignation = {
      Id: null,
      Code: '',
      Name: ''

    }
  }
  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._desigServive.formHideShow = 0;

  }
  formValidation(): boolean {
    if (this._desigServive.selectedDesignation.Name != "" && this._desigServive.selectedDesignation.Code != "")
      return true;
    else
      return false;
  }
  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid && this.formValidation()) {
      this.isSubmit = true
      if (form.value.Id == null) {
        this._desigServive.postDesignation(form.value).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 0) {
              this.toastr.success(data.Message, "Designation")
              this._desigServive.formHideShow = 0;
              this.resetForm(form);
              this._desigServive.getDesignation().subscribe(data => this._desigServive.allDesignations = data,
                error => {
                  this.errorMsg = error;

                });
            }
            else {
              this.toastr.warning(data.Message, "Designation")

            }
          }
        );

      }
      else {
        this._desigServive.putDesignation(form.value).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 0) {
              this.toastr.success(data.Message, "Designation")
              this._desigServive.formHideShow = 0;
              this.resetForm(form);
              this._desigServive.getDesignation().subscribe(data => this._desigServive.allDesignations = data,
                error => {
                  this.errorMsg = error;

                });
            }
            else {
              this.toastr.warning(data.Message, "Designation")

            }
          });


      }

    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Designation');
    }
  }

}
