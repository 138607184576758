<div class="container-fluid">
    <div class="flex-body">
        <div class="row">
            <div class="col-md-12">
                <h3 class="heading_all">Dealer Dashboard
                    <span [class.disabled] = "this._CommonService.KeyExpiryCount.LicenseKey == 0" data-title="Expiry License Key Details"  style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(1)">
                        <img src="../../../../../assets/Images/Licensekey.ico">({{this._CommonService.KeyExpiryCount.LicenseKey}})
                    </span>
                    <span [class.disabled] = "this._CommonService.KeyExpiryCount.AMC == 0" data-title="Expiry AMC Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(2)">
                        <img src="../../../../../assets/Images/tools.jpg">({{this._CommonService.KeyExpiryCount.AMC}})
                    </span>
                    <span  [class.disabled] = "this._CommonService.KeyExpiryCount.CloudServer == 0" data-title="Cloud Server Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(3)">
                        <img src="../../../../../assets/Images/cloudserver.ico">({{this._CommonService.KeyExpiryCount.CloudServer}})
                    </span>
                    <span  [class.disabled] = "this._CommonService.KeyExpiryCount.CustomerOutstanding == 0" data-title="Customer Outstanding Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(4)">
                        <img src="../../../../../assets/Images/customeroutstanding.ico">({{this._CommonService.KeyExpiryCount.CustomerOutstanding}})
                    </span>
                </h3>
            </div><br>
            <div class="col-md-4 panel">
                <img src="../../../../../assets/Images/PMS.png" style="width:420px;height:280px;">
            </div>
            <div class="col-md-4">
                <div class="panel">
                    <div id="chartdiv"></div>
                </div>
            </div>
            <div class="col-md-4 panel">
                <img src="../../../../../assets/Images/PMS.png" style="width:420px;height:280px;">
            </div>
        </div>
        <br>
        <br>
        <br>
        <div class="row">
            <div class="col-md-2">
                <label style="margin-left: 100px;">Select Date:</label>
            </div>
            <div class="col-md-6">
                <input class="section3" placeholder="Choose Date" style="color: black; text-align: left;"
                    autocomplete="off" #dp="bsDatepicker" bsDatepicker
                    [(ngModel)]="this._serviceEngineerDBService.selectedDate" name="selectedDate"
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }"
                    (bsValueChange)="LoadDealerDashboardTodaysSchdule();LoadDealerDashboardCustReqData()">
            </div>
        </div>
    </div>
    <br>
    <div class="row" style="margin-right: 0px;margin-left: 0px;">
        <div class="tWrap">
            <div class="tWrap__head">
                <table>
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>SE</th>
                            <th>Customer</th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>ExpDate</th>
                            <th>OverDue</th>
                            <th>Priority</th>
                            <th>AMCStatus</th>
                            <th>ServiceMode</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="tWrap__body">
                <table>
                    <tbody>
                        <tr *ngFor="let Backlog of this._CustomerrequirementService.DealerDashBoardTodaysSchdule ; let i = index"
                            class="ng-scope">
                            <td>{{i+1}}</td>
                            <td>{{Backlog.ServiceEngs}} </td>
                            <td>
                                <span class="form-control-label" *ngFor="let field of  _CustomerService.allCustomers">
                                    <p *ngIf="field.Id==Backlog.CustomerId">
                                        {{field.Name}}
                                    </p>
                                </span>
                            </td>
                            <td>{{Backlog.RDate}}</td>

                            <td>
                                <span class="form-control-label" *ngFor="let field of this.TypeList">
                                    <p *ngIf="Backlog.Type==field.Id">
                                        {{field.Name}}
                                    </p>
                                </span>
                            </td>
                            <td>{{Backlog.DDate}}</td>
                            <td>
                                {{Backlog.OverDue}}
                            </td>
                            <td>
                                <span class="form-control-label" *ngFor="let field of this.PriorityList">
                                    <p *ngIf="Backlog.PriorityId==field.Id">
                                        {{field.Name}}
                                    </p>
                                </span>
                            </td>

                            <td>
                                {{Backlog.AmcStatus}}
                            </td>
                            <td>
                                <label class="form-control-label">
                                    <p *ngIf="Backlog.ServiceMode==1">By Phone</p>
                                    <p *ngIf="Backlog.ServiceMode==2">By Mail</p>
                                    <p *ngIf="Backlog.ServiceMode==3">By Chat</p>
                                    <p *ngIf="Backlog.ServiceMode==4">On Site</p>
                                    <p *ngIf="Backlog.ServiceMode==5">Remote Section</p>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br>
    <div class="row" style="    margin-right: 0px;    margin-left: 0px;">
        <div class="tWrap">
            <div class="tWrap__head">
                <table>
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Type</th>
                            <th>ExpDate</th>
                            <th>OverDue</th>
                            <th>Priority</th>
                            <th>Status</th>
                            <th>SE</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="tWrap__body">
                <table>
                    <tbody>
                        <tr *ngFor="let Backlog of this._CustomerrequirementService.SelectedDealerDashBoardCustReq ; let i = index"
                            class="ng-scope" (click)="showForEdit(Backlog)">
                            <td>{{i+1}}</td>
                            <td>{{Backlog.RDate}}</td>
                            <td>
                                <span class="form-control-label" *ngFor="let field of  _CustomerService.allCustomers">
                                    <p *ngIf="field.Id==Backlog.CustomerId">
                                        {{field.Name}}
                                    </p>
                                </span>
                            </td>
                            <td>
                                <span class="form-control-label" *ngFor="let field of this.TypeList">
                                    <p *ngIf="Backlog.Type==field.Id">
                                        {{field.Name}}
                                    </p>
                                </span>
                            </td>
                            <td>{{Backlog.DDate}}</td>
                            <td>
                                {{Backlog.OverDue}}
                            </td>
                            <td>
                                <span class="form-control-label" *ngFor="let field of this.PriorityList">
                                    <p *ngIf="Backlog.PriorityId==field.Id">
                                        {{field.Name}}
                                    </p>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="Backlog.IsHold" class="form-control-label" (click)="openHoldPopUp(Backlog)" style="text-decoration: underline;font-weight: bold;cursor: pointer;">
                                    <p>   Hold</p>
                                   </span>
                                   <span *ngIf="! Backlog.IsHold">
                                <span class="form-control-label" *ngFor="let field of this.StatusList">
                                    <p *ngIf="Backlog.Status==field.Id">
                                        {{field.Name}}
                                    </p>
                                </span>
                                </span>
                            </td>
                            <td>
                                {{Backlog.ServiceEngs}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br>
    <div class="row" style="width: 100%;margin-left: 0; margin-right: 0;"
        *ngIf="this._BranchService.selectedBranch.Id!=null">
        <div class="col-md-4" style="background-color: #fff;    padding: 25px;    border: solid #209e91;">
            <div style="text-align: center;">
                <h6 style="font-weight: bold;">Branch Details </h6>
                <p *ngIf="this._BranchService.selectedBranch.BranchName!=null">
                    Branch Name:{{this._BranchService.selectedBranch.BranchName}}
                </p>
                <p *ngIf="this._BranchService.selectedBranch.BranchEmail!=null">
                    {{this._BranchService.selectedBranch.BranchPhone}},
                    {{this._BranchService.selectedBranch.BranchEmail}}
                </p>
                <p *ngIf="this._BranchService.selectedBranch.Addr1!=null">
                    {{this._BranchService.selectedBranch.Addr1}},
                    {{this._BranchService.selectedBranch.Addr2}},
                    {{this._BranchService.selectedBranch.Addr3}}
                </p>
                <p *ngIf="this._BranchService.selectedBranch.ContactPerson!=null">
                    Contact Person:
                    {{this._BranchService.selectedBranch.ContactPerson}}
                    ,{{this._BranchService.selectedBranch.ContactPhone}}</p>
            </div>
        </div>
        <div class="col-md-8" style="background-color: #fff;    padding: 25px;    border: solid #209e91;">
            <div style="height: 200px;    overflow-y: auto;">
                <ol>
                    <li style="font-weight: bold;"
                        *ngFor="let field of this._serviceEngineerDBService.AllWorkSortingDet">
                        {{field.Description}}
                    </li>
                </ol>
            </div>
        </div>
    </div>

</div>
 <!-- Popup  -->
 <form #testREsult="ngForm" *ngIf="popUpIndex==1" >
    <div class="popup-container">

        <input type="checkbox" id="test-results">
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
             
                <div class="title">
                    <p>Holding Reason</p> <label for="test-results"> <i class="fas fa-times"
                            (click)="closePopUp()"></i> </label>
                </div>
                <div  >
                    <div class="row">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Reason <span style="color:red ;">*</span> </label>
                        <div class="col-md-5">
                            <ng-select name="HoldingReason" id="HoldingReason" #HoldingReason="ngModel"
                            bindLabel="Name" bindValue="Id" required [items]="holdingReasonList"
                           disabled [ngModel]="holdReason.HoldingReason"
                            placeholder="Select">
                        </ng-select>
                             
                        </div>
                    </div>
                    <div class="row" *ngIf="holdReason.HoldingReason==4">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Clarification From </label>
                        <div class="col-md-5">
                            <ng-select name="ClarificationFrm" id="ClarificationFrm" bindLabel="EmpName"
                                bindValue="Id" required #ClarificationFrm="ngModel"
                               disabled [ngModel]="holdReason.ExpectingResultFrom"
                                [items]="ClarificationFrom" placeholder="Select">
                            </ng-select>
                             
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Reason <span style="color:red ;">*</span></label>
                        <div class="col-md-5">
                            <textarea type="text" name="Reason" id="Reason" style="width: 100%;" disabled [ngModel]="holdReason.Reason" required  ></textarea>
                             
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Remark <span style="color:red ;">*</span></label>
                        <div class="col-md-5">
                            <textarea type="text" name="Remark" id="Remark"  required
                            [ngModel]="holdReason.Remark"    style="width: 100%;" disabled ></textarea>
                             
                        </div>
                    </div>
                     
                </div>
            </div>
        </div>
    </div>
</form>
<!--Key expiry details -->
<app-keyexpirydetails *ngIf="this._CommonService.expDetailsPopup > 0" [expDetailsPopup]="this._CommonService.expDetailsPopup"></app-keyexpirydetails>