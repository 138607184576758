<div class="container-fluid" *ngIf="_swRegService.formHideShow==0">
    <h2 class="heading_all">Software Registartion</h2>
    <br>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4 ">

            <table>
                <thead>
                    <tr>
                        <td>
                            <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                    aria-hidden="true"></i></button>
                        </td>
                        <td style="width:60%;"> <input type="text" class="form-control-search" name="search"
                                [(ngModel)]="term"></td>
                        <td> <button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                    </tr>
                </thead>
            </table>

        </div>
    </div>
    <br>
    <table>
        <thead>
            <tr>
                <th>#</th>
                <th class="hidden-xs" style="width:150px;">
                    <div class="AtjIsPoint" (click)="sort('RefNo')">
                        <span *ngIf="!_swRegService.isFiltering">RefNo</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_swRegService.orderByColName!='RefNo' "></i>
                            <div *ngIf="_swRegService.orderByColName=='RefNo'">
                                <i class="fas fa-sort-amount-down" *ngIf="_swRegService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_swRegService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_swRegService.isFiltering">
                        <input type="text" #txtRefNoFilter="ngModel" [(ngModel)]="_swRegService.RefNoFilter"
                            placeholder="RefNo">
                    </div>

                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('CustomerName')">
                        <span *ngIf="!_swRegService.isFiltering">Customer</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_swRegService.orderByColName!='CustomerName' "></i>
                            <div *ngIf="_swRegService.orderByColName=='CustomerName'">
                                <i class="fas fa-sort-amount-down" *ngIf="_swRegService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_swRegService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_swRegService.isFiltering">
                        <input type="text" #txtNameFilter="ngModel" [(ngModel)]="_swRegService.CustomerFilter"
                            placeholder="Name">
                    </div>

                </th>
                <th style="width:120px;"><em></em> Settings
                    <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                        <i *ngIf="!_swRegService.isFiltering" class="fas fa-filter"></i>
                        <i *ngIf="_swRegService.isFiltering" class="fas fa-times"></i>
                    </a>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let swReg of _swRegService.allSoftwareRegistrations |gridOperations  :_swRegService.pageIndex:
                        {RefNo:_swRegService.RefNoFilter,CustomerName:_swRegService.CustomerFilter}:
                       {Column:_swRegService.orderByColName,Dir:_swRegService.orderByDir} ; let i = index"
                class="ng-scope">
                <td>{{i+1+((NoOfItems*_swRegService.pageIndex)-NoOfItems)}}</td>
                <td>{{swReg.RefNo}}</td>
                <td>{{swReg.CustomerName}}</td>
                <td>
                    <button type="button" class="btn-success" (click)="showForEdit(swReg)">
                        <i class="fas fa-pen-square" aria-hidden="true"></i></button>

                    <button type="button" class="btn-danger" (click)="onDelete(swReg)"><i class="fas fa-trash"
                            aria-hidden="true"></i></button>
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    *ngIf="(_swRegService.allSoftwareRegistrations?.length<=0 )  || _CommonService.filterdItemCount<1">
                    No Records To Display.</td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                <app-grid-pagination (pageIndexValue)="_swRegService.pageIndex=$event">
                </app-grid-pagination>
            </td>
        </tfoot>
    </table>
    <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
    <div class="margintop"></div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'SoftwareRegistration'" (ConfirmEvent)="dialogResult($event)">
</app-delete-confirm>