import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { Country } from 'src/app/Models/Country.Model';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.css']
})
export class CountryListComponent implements OnInit {

  public errorMsg;
  public term: any = '';
  public deletePopUp: boolean = false;
  DeleteID: number = 0;
  constructor(public _cntryServive: CountryService, public _CommonService: CommonService, private toastr: ToastrService) { }

  ngOnInit() {

    this._cntryServive.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._cntryServive.getCountry()
      .subscribe(data => this._cntryServive.allCountries = data,
        error => {
          this.errorMsg = error;

        })
    this._cntryServive.formHideShow = 0;
  }

  toggleFilter() {
    this._cntryServive.isFiltering = this._cntryServive.isFiltering ? false : true;
    if (!this._cntryServive.isFiltering) {
      this._cntryServive.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._cntryServive.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._cntryServive.orderByColName) {
      this._cntryServive.orderByDir = !this._cntryServive.orderByDir;
    }
    this._cntryServive.orderByColName = colName;
  }
  NoOfItems: number = 10;

  addList() {

    this._cntryServive.formHideShow = 1;
  }

  showForEdit(cntry: Country) {
    this._cntryServive.selectedCountry = Object.assign({}, cntry);
    this._cntryServive.formHideShow = 1;
  }

  onDelete(cntry: Country) {
    this.DeleteID = cntry.Id;
    this.deletePopUp = true;

  }
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._cntryServive.deleteCountry(this.DeleteID)
        .subscribe(x => {
          this._cntryServive.getCountry().subscribe(data => this._cntryServive.allCountries = data,
            error => {
              this.errorMsg = error;
            });
          this.toastr.success("Deleted Successfully", "Country");
          this.DeleteID = 0;
        });
    }
  }
}
