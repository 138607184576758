import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Observable } from 'rxjs';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { WorkAllocationStatusService } from './work-allocation-status.service';
import { CustomerRequirement_Attachments } from '../Models/CustomerRequirements';
import { ProjectLeaderService } from './project-leader.service';
import { PLWorkDetailsHdr } from '../Models/PLWorkDetailsHdr.model';
// import * as CryptoJS from 'crypto-js';
import { Common, Response } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class WorkDeliveryService {

  AttachmentList: CustomerRequirement_Attachments[];
  AttachementRemovedList: CustomerRequirement_Attachments[];
  allBatch:Common[]=[];

  constructor(private http: HttpClient, 
    public _GlobalErrorHandlerService: GlobalErrorHandlerService,
    public _workAllocationStatusService: WorkAllocationStatusService,
    public _ProjectLeaderService: ProjectLeaderService,
  ) { }


  providerWorkDelivery(PLWorkDetailsHdr: PLWorkDetailsHdr[], TransMode: string, fileToUpload: any, CustomerId: number): Observable<any> {
    const headers = new HttpHeaders().set('contentType', 'false');
    var body = {
      ID: PLWorkDetailsHdr.filter(obj => obj.WorkDeliveryHdrId != 0).length > 0 ? PLWorkDetailsHdr.filter(obj => obj.WorkDeliveryHdrId != 0)[0].WorkDeliveryHdrId : 0,
      DelDate: this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate,
      CustomerId: CustomerId,
      Charge: this._workAllocationStatusService.SelectedWorkStatusHdr.Charge,
      workList: PLWorkDetailsHdr.length > 0 ? JSON.stringify(PLWorkDetailsHdr) : '',
      RemovedList: this._ProjectLeaderService.JobAssignAttachmentList.length > 0 ? JSON.stringify(this._ProjectLeaderService.JobAssignAttachmentList) : '',
      DeliveryAttachment: this._ProjectLeaderService.JobAssignAttachmentList.length > 0 ? JSON.stringify(this._ProjectLeaderService.JobAssignAttachmentList) : ''
    }

    const formData: FormData = new FormData();
    if (fileToUpload) {
      for (var i = 0; i < fileToUpload.length; i++) {
        formData.append('Document', fileToUpload[i]);
      }
    }

    formData.append('obj', JSON.stringify(body));
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    return this.http.post<any>(ROOT_URL + 'WorkSorting/WorkDeliveryProvider', formData, {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  providerWorkUnDelivered(activityId,workDeliveryId)
  {
    const headers = new HttpHeaders().set('contentType', 'false');
    let params = new HttpParams();
    const formData:FormData= new FormData();
    var body={
      ActivityId:activityId,
      ID:workDeliveryId
    }
    formData.append('obj', JSON.stringify(body));
    params = params.append('TransMode','UNDELIVERED');
    return this.http.post<Response>(ROOT_URL + 'WorkSorting/WorkDeliveryProvider', formData,{
      headers,params
    })
    .pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }

}
