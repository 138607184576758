<div class="container-fluid" *ngIf="_AmcRenewalService.formHideShow==0">
            <h2 class="heading_all">AMC Renewal</h2>
    <br>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4 ">
            <table>
                <thead>
                    <tr>
                        <td>
                            <button type="button" class="btn-sm btn-info"
                            (click)="addList()"><i class="fas fa-plus" aria-hidden="true"></i></button>
                        </td>
                        <td style="width:60%;"><input type="text" class="form-control-search" name="search"
                            [(ngModel)]="term"></td>
                        <td><button class="btn-info"><i class="fas fa-search"
                            aria-hidden="true"></i></button></td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <br>
            <table>
                <!-- <thead>
                    <tr>
                        <th>#</th>
                        <th (click)="sort('code')"> RenewalDate</th>
                        <th>Action</th>
                    </tr>
                </thead> -->
                <thead>
                    <tr>
                        <th>#</th>                      
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('AMCRenewalDate')">
                                <span *ngIf="!_AmcRenewalService.isFiltering">RenewalDate</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_AmcRenewalService.orderByColName!='AMCRenewalDate' "></i>
                                    <div *ngIf="_AmcRenewalService.orderByColName=='AMCRenewalDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_AmcRenewalService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_AmcRenewalService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="_AmcRenewalService.isFiltering">
                                <input type="text" #txtAMCRenewalDateFilter="ngModel" [(ngModel)]="_AmcRenewalService.DateFilter"
                                    placeholder="AMC Renewal Date">
                            </div>
                        </th>          
                        <th><em></em> Settings
                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                <i *ngIf="!_AmcRenewalService.isFiltering" class="fas fa-filter"></i>
                                <i *ngIf="_AmcRenewalService.isFiltering" class="fas fa-times"></i>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- ngRepeat: item in items -->
                    <!-- <tr *ngFor="let AmcRenewal of _AmcRenewalService.allAMCRenewals | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope"> -->
                        <tr *ngFor="let AmcRenewal of _AmcRenewalService.allAMCRenewals | orderBy : 'AMCRenewalDate' | gridOperations  :_AmcRenewalService.pageIndex:
                        {AMCRenewalDate:_AmcRenewalService.DateFilter}:
                       {Column:_AmcRenewalService.orderByColName,Dir:_AmcRenewalService.orderByDir} ; let i = index"
                        class="ng-scope">
                        <td>{{i+1+((NoOfItems*_AmcRenewalService.pageIndex)-NoOfItems)}}</td>
                        <td>{{AmcRenewal.AMCRenewalDate | date}}</td>
                        <!-- <td>{{AmcRenewal.BranchId}}</td> -->
                        <td>
                            <button type="button" class="btn-success" (click)="showForEdit(AmcRenewal)">
                                <i class="fas fa-pen-square" aria-hidden="true"></i></button>

                            <button type="button" class="btn-danger" (click)="onDelete(AmcRenewal)"><i
                                    class="fas fa-trash" aria-hidden="true"></i></button>
                        </td>
                    </tr><!-- end ngRepeat: item in items -->
                    <tr>
                        <td colspan="3"
                            *ngIf="(_AmcRenewalService.allAMCRenewals?.length<=0 )  || _CommonService.filterdItemCount<1">
                            No Records To  Display.
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                        <app-grid-pagination (pageIndexValue)="_AmcRenewalService.pageIndex=$event">
                        </app-grid-pagination>
                    </td>
                </tfoot>
            </table>
            <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
            <div class="margintop"></div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'AMC'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>