import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public userService: UserService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('userToken') != null) {
      let roles = next.data["roles"] as Array<string>;
      if (roles) {
        var match = this.userService.roleMatch(roles);
        if (match) return true;
        else {
          //this.router.navigate(['/forbidden']);
          return false;
        }
      }
      else
        return true;
    }
    console.clear()
    localStorage.clear();
    this.router.navigate(['/login']);
    return false;
  }
}