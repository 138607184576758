import { Component, OnInit } from '@angular/core';
import { CustomerfeedbackService } from 'src/app/Services/customerfeedback.service';
import { ToastrService } from 'ngx-toastr';
import { Customerfeedback } from 'src/app/Models/Customerfeedback';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-customer-feedback-list',
  templateUrl: './customer-feedback-list.component.html',
  styleUrls: ['./customer-feedback-list.component.css']
})
export class CustomerFeedbackListComponent implements OnInit {

  public errorMsg;
  public term         : any = '';
  public deletePopUp  : boolean = false;
  deleteID            : number = 0;

  constructor(
    public _CustomerfeedbackService: CustomerfeedbackService, 
    public _CommonService: CommonService,
    private toastr: ToastrService
  ) { }



  ngOnInit(): void {
    this._CustomerfeedbackService.pageIndex=1;
    this._CommonService.pageIndex=1;
    this._CustomerfeedbackService.getCustomerFeedBack("ALL")
    .subscribe(data => {
      this._CustomerfeedbackService.allCustomerFeedBack = data
     
    },
      error => {
        this.errorMsg = error;
      })
  this._CustomerfeedbackService.formHideShow = 0;
  }

  toggleFilter() {
    this._CustomerfeedbackService.isFiltering = this._CustomerfeedbackService.isFiltering ? false : true;
    if (!this._CustomerfeedbackService.isFiltering) {
      this._CustomerfeedbackService.ClearFilter();
     this._CommonService.pageIndex = 1;
      this._CustomerfeedbackService.pageIndex = 1;
    }
  }
 //Sorting
sort(colName: string) {
  if (colName == this._CustomerfeedbackService.orderByColName) {
    this._CustomerfeedbackService.orderByDir = !this._CustomerfeedbackService.orderByDir;
  }
  this._CustomerfeedbackService.orderByColName = colName;
}
  //sorting
  // key: string = 'name'; //set default
  // reverse: boolean = false;
  // sort(key) {
  //   this.key = key;
  //   this.reverse = !this.reverse;
  // }
  // //initializing p to one
  // p: number = 1;
  NoOfItems: number = 10;


  addNew() {
    this._CustomerfeedbackService.formHideShow = 1;
  }
  OnEdit( selectedRow:Customerfeedback){
    this._CustomerfeedbackService.selectedRecord=Object.assign({}, selectedRow);
    this._CustomerfeedbackService.getCustomerFeedBackById(selectedRow.ID)
    .subscribe(data => {
      this._CustomerfeedbackService.allQuesDtls = data['QuestionList']
      console.log('this._CustomerfeedbackService.allQuesDtls',this._CustomerfeedbackService.allQuesDtls)
     })
    this._CustomerfeedbackService.formHideShow = 1;
    }
  // onDelete(deleteID: number) {
  //   if (confirm('Are you sure to delete this record ?') == true) {
  //     this._CustomerfeedbackService.onDelete(deleteID)
  //       .subscribe(x => {
  //         this._CustomerfeedbackService.getCustomerFeedBack("ALL").subscribe(data => this._CustomerfeedbackService.allCustomerFeedBack = data,
  //           error => {
  //             this.errorMsg = error;
  //             console.error(error.name, error.message, error.stack);
  //           });
  //         this.toastr.success("Deleted Successfully", "Customerfeedback=");
  //       }, error => {
  //         this.errorMsg = error;
  //         console.error(error.name, error.message, error.stack);
  //         this.toastr.warning("Deletion Failure", "_Customerfeedback");
  //       })
  //   }
  // }


  onDelete(deleteID: number) {
    this.deletePopUp=true;
    this.deleteID=deleteID;
  }
  dialogResult(event) { 
    this.deletePopUp=false;
    if(event){
      this._CustomerfeedbackService.onDelete(this.deleteID)
        .subscribe(x => {
          this._CustomerfeedbackService.getCustomerFeedBack("ALL").subscribe(data => this._CustomerfeedbackService.allCustomerFeedBack = data,
            error => {
              this.errorMsg = error;
              console.error(error.name, error.message, error.stack);
            });
          this.toastr.success("Deleted Successfully", "Customer Feedback");
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.warning("Deletion Failure", "Customer Feedback");
        })
    }
    
  }





}
