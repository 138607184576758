<div *ngIf="_brnchService.formHideShow!=0" class="container-fluid">
    <h2 class="heading_all">BRANCH</h2>
    <br>
    <div id="form-wrap">
        <form action="#" #BranchForm="ngForm" (ngSubmit)="onSubmit(BranchForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_brnchService.selectedBranch.Id">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Country</label>
                        <select disabled class="form-control" name="CountryId" #CountryId="ngModel"
                            [(ngModel)]="_brnchService.selectedBranch.CountryId" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _countryService.allCountries" [ngValue]="prf.Id">
                                {{ prf.Name }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(CountryId.invalid && CountryId.touched)||(CountryId.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 13px;">Dealer</label>
                        <select disabled class="form-control" name="DealerId" #DealerId="ngModel"
                            (change)="LoadCustomers()" [(ngModel)]="_brnchService.selectedBranch.DealerId" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _dealrService.allDealers" [ngValue]="prf.Id">
                                {{ prf.DealerName }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(DealerId.invalid && DealerId.touched)||(DealerId.invalid && this.FormValidation)">
                            This Field is
                            Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 11px;">Customer<span class="validation-error">*</span></label>
                        <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel" bindLabel="Name"
                            bindValue="Id" [items]="_custService.allCustomers  | orderBy : 'Name'" required
                            [(ngModel)]="_brnchService.selectedBranch.CustomerId">
                        </ng-select>
                        <div class="validation-error"
                            *ngIf="(CustomerId.invalid && CustomerId.touched)||(CustomerId.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 15px;">Branch<span class="validation-error">*</span></label>

                        <input class="form-control" name="BranchName" #BranchName="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.BranchName" placeholder=" Branch Name" required>
                        <div class="validation-error"
                            *ngIf="(BranchName.invalid && BranchName.touched)||(BranchName.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 15px;">DataBase</label>
                        <input class="form-control" name="DataBase" #DataBase="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.DataBase" placeholder=" DataBase Name">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Address 1<span class="validation-error">*</span></label>
                        <input class="form-control" name="Addr1" #Addr1="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.Addr1" placeholder=" Addr1" required>
                        <div class="validation-error"
                            *ngIf="(Addr1.invalid && Addr1.touched)||(Addr1.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Address 2<span class="validation-error">*</span></label>
                        <input class="form-control" name="Addr2" #Addr2="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.Addr2" placeholder=" Addr2" required>
                        <div class="validation-error"
                            *ngIf="(Addr2.invalid && Addr2.touched)||(Addr2.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Address 3<span class="validation-error">*</span></label>

                        <input class="form-control" name="Addr3" #Addr3="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.Addr3" placeholder=" Addr3" required>
                        <div class="validation-error"
                            *ngIf="(Addr3.invalid && Addr3.touched)||(Addr3.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Email<span class="validation-error">*</span></label>
                        <input class="form-control" name="BranchEmail" #BranchEmail="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.BranchEmail"
                            [pattern]=this._CommonService.emailpattern placeholder=" Branch Email"
                            required>
                        <div class="validation-error"
                            *ngIf="(BranchEmail.invalid && BranchEmail.touched)||(BranchEmail.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Phone<span class="validation-error">*</span></label>
                        <input class="form-control" name="BranchPhone" #BranchPhone="ngModel" autocomplete="off"
                            ng-pattern="/^[0-9]*$/" (keypress)="keyPress($event)"
                            [(ngModel)]="_brnchService.selectedBranch.BranchPhone" placeholder=" Branch Phone" required>
                        <div class="validation-error"
                            *ngIf="(BranchPhone.invalid && BranchPhone.touched)||(BranchPhone.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Fax<span class="validation-error">*</span></label>
                        <input class="form-control" name="BranchFax" #BranchFax="ngModel" autocomplete="off"
                            ng-pattern="/^[a-zA-Z0-9]*$/" [(ngModel)]="_brnchService.selectedBranch.BranchFax"
                            placeholder=" BranchFax" required>
                        <div class="validation-error"
                            *ngIf="(BranchFax.invalid && BranchFax.touched)||(BranchFax.invalid && this.FormValidation)">
                            This Field isRequired.</div>
                    </div>
                    <div class="form-group">
                        <label>Contact Person<span class="validation-error">*</span></label>
                        <input class="form-control" name="ContactPerson" #ContactPerson="ngModel"
                            [(ngModel)]="_brnchService.selectedBranch.ContactPerson" autocomplete="off"
                            placeholder=" Contact Person" required>
                        <div class="validation-error"
                            *ngIf="(ContactPerson.invalid && ContactPerson.touched)||(ContactPerson.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Contact Mobile<span class="validation-error">*</span></label>
                        <input class="form-control" name="ContactPhone" #ContactPhone="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.ContactPhone" (keypress)="keyPress($event)"
                            ng-pattern="/^[0-9]*$/" placeholder=" Contact Phone" required>
                        <div class="validation-error"
                            *ngIf="(ContactPhone.invalid && ContactPhone.touched)||(ContactPhone.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Contact Email<span class="validation-error">*</span></label>
                        <input class="form-control" name="ContactEmail" #ContactEmail="ngModel" autocomplete="off"
                            [(ngModel)]="_brnchService.selectedBranch.ContactEmail"
                            [pattern]=this._CommonService.emailpattern placeholder=" Contact Email" required>
                        <div class="validation-error"
                            *ngIf="(ContactEmail.invalid && ContactEmail.touched)||(ContactEmail.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Route<span class="validation-error">*</span></label>

                        <select class="form-control" name="RouteId" #RouteId="ngModel"
                            [(ngModel)]="_brnchService.selectedBranch.RouteId" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _routeService.allRoutes | orderBy : 'RouteName'"
                                [ngValue]="prf.Id">
                                {{ prf.RouteName }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(RouteId.invalid && RouteId.touched)||(RouteId.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                        class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                        (click)="resetForm(BranchForm)">Reset</button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                        (click)="backList()">Exit</button>
                </div>
            </div>
        </form>
    </div>
</div>