<div class="container-fluid" *ngIf="_cntryServive.formHideShow==0">
    <div id="form-wrap">
        <h2 class="heading_all">Country</h2>
        <br>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">

                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width: 70%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>

            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <!-- <th (click)="sort('code')"> Code</th>
                        <th (click)="sort('name')">Name</th>
                        <th>Action</th> -->
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Code')">
                            <span *ngIf="!_cntryServive.isFiltering">Code</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_cntryServive.orderByColName!='Code' "></i>
                                <div *ngIf="_cntryServive.orderByColName=='Code'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_cntryServive.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_cntryServive.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_cntryServive.isFiltering">
                            <input type="text" #txtCodeFilter="ngModel" [(ngModel)]="_cntryServive.CodeFilter"
                                placeholder="Code">
                        </div>

                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Name')">
                            <span *ngIf="!_cntryServive.isFiltering">Name</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_cntryServive.orderByColName!='Name' "></i>
                                <div *ngIf="_cntryServive.orderByColName=='Name'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_cntryServive.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_cntryServive.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_cntryServive.isFiltering">
                            <input type="text" #txtNameFilter="ngModel" [(ngModel)]="_cntryServive.NameFilter"
                                placeholder="Name">
                        </div>

                    </th>
                    <th><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_cntryServive.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="_cntryServive.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- ngRepeat: item in items -->
                <!-- <tr *ngFor="let cntry of _cntryServive.allCountries | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope"> -->
                <tr *ngFor="let cntry of _cntryServive.allCountries | gridOperations  :_cntryServive.pageIndex: 
                        {Code:_cntryServive.CodeFilter,Name:_cntryServive.NameFilter}:
                       {Column:_cntryServive.orderByColName,Dir:_cntryServive.orderByDir} ; let i = index"
                    class="ng-scope">
                    <td>{{i+1+((NoOfItems*_cntryServive.pageIndex)-NoOfItems)}}</td>
                    <td>{{cntry.Code}}</td>
                    <td>{{cntry.Name}}</td>
                    <td>
                        <button type="button" class="btn-success" (click)="showForEdit(cntry)">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                        <button type="button" class="btn-danger" (click)="onDelete(cntry)"><i class="fas fa-trash"
                                aria-hidden="true"></i></button>
                    </td>
                </tr>
                <!--end ngRepeat: item in items -->
                <tr>
                    <td colspan="4"
                        *ngIf="(_cntryServive.allCountries?.length<=0 )  || _CommonService.filterdItemCount<1">
                        No Records To
                        Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="_cntryServive.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        <div class="margintop"></div>
    </div>
</div>

<app-delete-confirm *ngIf="deletePopUp" [title]="'COUNTRY'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>