<div class="container-fluid" *ngIf="this.cloudChargeService.formHideShow!=0">
    <h2 class="heading_all">CS Cancellation</h2><br>
    <div id="form-wrap">
        <form #csCancellationForm="ngForm" (ngSubmit)="onSubmit(csCancellationForm)">
            <input type="hidden" name="Id">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="cloudChargeService.selectedCSCancellation.Id">
                            <label>VNo<span class="validation-error">*</span></label>
                            <input type="text" [disabled]="true" name="VNo" autocomplete="off" placeholder="VNo" #VNo="ngModel" [(ngModel)]="this.cloudChargeService.selectedCSCancellation.VNo" required>                
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Date<span class="validation-error">*</span></label> 
                            <input class="form-control" placeholder="Date" name="Date" style="color: black;  text-align: left;"                 
                                #Date="ngModel"[(ngModel)]="this.cloudChargeService.selectedCSCancellation.Date" autocomplete="off" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        </div>
                    </div>
                </div>                      
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Dealer<span class="validation-error">*</span></label> 
                            <select class="form-control" name="DealerId" #DealerId="ngModel" [disabled]="cloudChargeService.selectedCSCancellation.Id > 0"
                                [(ngModel)]="cloudChargeService.selectedCSCancellation.DealerId" (change)="loadDealerWiseCustomers()"  required>
                                <option [ngValue]="null">Choose Dealer</option>
                                <option *ngFor="let dealer of this.dealerService.allDealers" [ngValue]="dealer.Id">{{dealer.DealerName}}</option>
                            </select>   
                            <div class="validation-error" *ngIf="csCancellationForm.submitted && cloudChargeService.selectedCSCancellation.DealerId==null">This filed is required</div>        
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Customer<span class="validation-error">*</span></label> 
                            <ng-select name="CustomerId" id="CustomerId" bindLabel="Name" #CustomerId="ngModel" [disabled]="(cloudChargeService.selectedCSCancellation.DealerId==null || cloudChargeService.selectedCSCancellation.Id > 0)"
                                [(ngModel)]="cloudChargeService.selectedCSCancellation.CustomerId" bindValue="Id"
                                [items]="customerService.allCustomers" required >
                            </ng-select>   
                            <div class="validation-error" *ngIf="csCancellationForm.submitted && cloudChargeService.selectedCSCancellation.CustomerId==null">This filed is required</div>              
                        </div>
                    </div>
                </div>      
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Affected From<span class="validation-error">*</span></label> 
                            <input class="form-control" placeholder="Affected From" name="AffectedFrom" style="color: black;  text-align: left;"                  
                                #AffectedFrom="ngModel"[(ngModel)]="this.cloudChargeService.selectedCSCancellation.AffectedFrom" autocomplete="off" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY'}" required>  
                                <div class="validation-error" *ngIf="csCancellationForm.submitted && cloudChargeService.selectedCSCancellation.AffectedFrom==null">This filed is required</div>         
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Reason<span class="validation-error">*</span></label> 
                            <select class="form-control" name="ReasonId" #ReasonId="ngModel" [(ngModel)]="cloudChargeService.selectedCSCancellation.ReasonId" required>
                                <option [ngValue]="null">Choose Reason</option>
                                <option *ngFor="let reason of this.reasonmasterService.allReasonMaster" [ngValue]="reason.ID">{{reason.Reason}}</option>
                            </select>   
                            <div class="validation-error" *ngIf="csCancellationForm.submitted && cloudChargeService.selectedCSCancellation.ReasonId==null">This filed is required</div>     
                        </div>
                    </div>
                </div>                                   
                <div class="form-group">
                    <label style="margin-left:12px;">Remark</label> 
                    <textarea class="form-control" name="Remark" autocomplete="off" #Remark="ngModel"[(ngModel)]="this.cloudChargeService.selectedCSCancellation.Remark" placeholder="Remark"></textarea>
                </div>             
                <div class="row">
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="submit" class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2" (click)="resetForm(csCancellationForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3" (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- </div> -->
</div>
