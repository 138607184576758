import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-node-reg',
  templateUrl: './node-reg.component.html',
  styleUrls: ['./node-reg.component.css']
})
export class NodeRegComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
