<div class="container-fluid">
    <div class="flex-body" style="margin-top: 0 !important;">   
        <h2 class="heading_all">Admin Dashboard
            <span [class.disabled] = "this._CommonService.KeyExpiryCount.LicenseKey == 0" data-title="License Key"  style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(1)">
                <img src="../../../../../assets/Images/Licensekey.ico">({{this._CommonService.KeyExpiryCount.LicenseKey}})
            </span>
            <span [class.disabled] = "this._CommonService.KeyExpiryCount.AMC == 0" data-title="Expiry AMC Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(2)">
                <img src="../../../../../assets/Images/tools.jpg">({{this._CommonService.KeyExpiryCount.AMC}})
            </span>
            <span [class.disabled] = "this._CommonService.KeyExpiryCount.CloudServer == 0" data-title="Cloud Server Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(3)">
                <img src="../../../../../assets/Images/cloudserver.ico">({{this._CommonService.KeyExpiryCount.CloudServer}})
            </span>
            <span [class.disabled] = "this._CommonService.KeyExpiryCount.CustomerOutstanding == 0" data-title="Customer Outstanding Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(4)">
                <img src="../../../../../assets/Images/customeroutstanding.ico">({{this._CommonService.KeyExpiryCount.CustomerOutstanding}})
            </span>
        </h2>   
        <div class="row">
            <div class="col-md-4">
                <section class="card">
                    <div class="card-header user-header alt bg-dark">
                        <div class="media">
                            <a href="#">
                                <img class="align-self-center rounded-circle mr-3" style="width:45px; height:45px;" alt="" src="../../../assets/Images/programmer.jpg">
                            </a>
                            <div class="media-body">
                                <h2 class="text-light display-6">QBS Professionals </h2>
                            </div>
                        </div>
                    </div>
                    <div id="table-scroll" style="height:350px;">
                        <table class="table tablepl">
                            <tbody>
                                <tr *ngFor="let data of this._employeeService.allEmployees">
                                    <td><img class="align-self-center rounded-circle mr-3"
                                            style="width:25px; height:25px;" alt="" src="../../../assets/Images/admin.jpg">
                                    </td>
                                    <td>
                                        {{data.EmpName}}
                                    </td>
                                    <td><span *ngIf="data.LoginDate.length>0" class="badge badge-success pull-right">
                                            {{data.LoginDate}} </span></td>
                                    <td><span *ngIf="data.ModuleWorkingOn.length>0"
                                            class="badge badge-default pull-right"> {{data.ModuleWorkingOn}} </span>
                                    </td>
                                    <td><span *ngIf="data.LastUpdatedDate.length>0"
                                            [ngClass]="{redText:checkDate(data.LastUpdatedDate)}" class="badge ">
                                            {{data.LastUpdatedDate}} </span></td>
                                    <td><i class="fas fa-circle" style="color:yellow"></i> </td>
                                </tr>                          
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="card-footer">
                        <strong> Programmers</strong>
                    </div> -->
                </section>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6 cardCntner card">
                        <div class="card-footer">
                            <a href="Dashboard#detail" (click)="workingDetail()"><strong
                                    class="card-title mb-3">BACKLOGS</strong></a>
                        </div>
                        <div class="card-body">
                            <div id="chartdiv"></div>
                            <div class="mx-auto d-block">                             
                            </div>
                        </div>

                        <div class="modal fade" id="myModalProgrmmr">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <!-- Modal Header -->
                                    <div class="modal-header">
                                        <h4 class="modal-title">Programmers</h4>
                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    <!-- Modal body -->
                                    <div class="modal-body">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Programmer 1</td>
                                                    <td>9.05 am</td>
                                                    <td>project</td>
                                                </tr>
                                                <tr>
                                                    <td>Programmer 2</td>
                                                    <td>9.05 am</td>
                                                    <td>project</td>
                                                </tr>
                                                <tr>
                                                    <td>Programmer 3</td>
                                                    <td>9.05 am</td>
                                                    <td>project</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- Modal footer -->
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 cardCntner card">                    
                        <div class="card-footer">
                            <strong class="card-title mb-3">Performance</strong>
                        </div>
                    </div>                 
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 card">
                <div id="detail">
                    <div class="wrkDetail" *ngIf="show1">
                        <div class="mx-auto d-block">
                            <h5>Working Details</h5>
                        </div>
                        <hr>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Sno</th>
                                    <th>Ref.no</th>
                                    <th>Project</th>
                                    <th>details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>customer1</td>
                                    <td>project1</td>
                                    <td>Module1</td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>customer1</td>
                                    <td>project1</td>
                                    <td>Module1</td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>customer1</td>
                                    <td>project1</td>
                                    <td>Module1</td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pendingDetails" *ngIf="show2">
                        <div class="mx-auto d-block">
                            <h5>Pending Details</h5>
                        </div>
                        <hr>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>Customer</th>
                                    <th>Project</th>
                                    <th>Module</th>
                                    <th>Date</th>
                                    <th>Priority</th>
                                    <th>Exp.ComDate</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>customer1</td>
                                    <td>project1</td>
                                    <td>Module1</td>
                                    <td>Date</td>
                                    <td>Priority</td>
                                    <td>Exp.ComDate</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>customer1</td>
                                    <td>project1</td>
                                    <td>Module1</td>
                                    <td>Date</td>
                                    <td>Priority</td>
                                    <td>Exp.ComDate</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>customer1</td>
                                    <td>project1</td>
                                    <td>Module1</td>
                                    <td>Date</td>
                                    <td>Priority</td>
                                    <td>Exp.ComDate</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="DeliveryDetails" *ngIf="show3">
                        <div class="mx-auto d-block">
                            <h5>Delivery Details</h5>
                        </div>
                        <hr>
                        <table class="table table-bordered" *ngIf="show3">
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>Customer</th>
                                    <th>Project</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Karak Mak</td>
                                    <td>pos3</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Land Mart</td>
                                    <td>pos4</td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Land Mart</td>
                                    <td>Inventory</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-4 card">
                <div class="card-body">
                    <div class="mx-auto d-block">
                        <h5>Over Dues</h5>
                    </div>
                    <hr>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>Project</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>project1</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>project1</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>project1</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Key expiry details -->
<app-keyexpirydetails *ngIf="this._CommonService.expDetailsPopup > 0" [expDetailsPopup]="this._CommonService.expDetailsPopup"></app-keyexpirydetails>