import { customer } from './../../../../Models/customer.model';
import { AddtionalModuleList } from './../../../../Models/OtherCharges';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { ChargeTypeService } from './../../../../Services/charge-type.service';
import { OtherChargesService } from 'src/app/Services/other-charges.service';
import { ToastrService } from 'ngx-toastr';
import { timeStamp } from 'console';

@Component({
  selector: 'app-other-charges',
  templateUrl: './other-charges.component.html',
  styleUrls: ['./other-charges.component.css']
})
export class OtherChargesComponent implements OnInit {
  public errorMsg;
  FormValidation:boolean
  textType:string="Number of Nodes";

  constructor(public commonService:CommonService,
              public custService: CustomerService,
              public dealrService: DealerService,
              public brnchService: BranchService,
              public otherChargesService:OtherChargesService,
              public chargeTypeService:ChargeTypeService,
              public toastrService:ToastrService) { }

  ngOnInit(): void {
    this.resetForm();
  }

  async GetVNo() {
    this.otherChargesService.selectedOtherCharges.VNo = parseInt(await this.commonService.getVNo('OTHERCHARGES'));
  }

  resetForm(form?:NgForm)
  {
    this.FormValidation = false
    if (form != null)
      form.reset();
    this.otherChargesService.selectedOtherCharges={
      Id: 0,
      VNo:0,
      Date: new Date(),
      DealerId:null,
      CustomerId:null,
      BranchId: null,
      ChargeTypeId: null,
      Charge:0,
      NoOfNodesOrCharacter: 0,
      Remark: '',
      AddtionalModuleList:[]
    }
    this.GetVNo();
    this.loadDealer();
    this.loadDealerWiseCustomers();
    this.loadCustomerWiseBranch()
    this.loadChargeType();
    this.otherChargesService.addtionalModuleList=[];
  }

  loadDealer() {
    this.dealrService.FetchDealer('ALL')
      .subscribe(data => {
        this.dealrService.allDealers = data
      })
  }

  loadDealerWiseCustomers() {
    this.otherChargesService.selectedOtherCharges.ChargeTypeId=null;
    this.otherChargesService.selectedOtherCharges.CustomerId=null;
    this.custService.FetchAllCustomer('ALL')
      .subscribe(data => { 
      if(this.otherChargesService.selectedOtherCharges.DealerId > 0)
      {
        this.custService.allCustomers  = data.filter(e=>
          e.DealerId==this.otherChargesService.selectedOtherCharges.DealerId
        )
      } 
      else
        this.custService.allCustomers=data   
      },
        error => {
          this.errorMsg = error;
        });
    }

  loadCustomerWiseBranch() {
    this.otherChargesService.selectedOtherCharges.BranchId=null;
    this.brnchService.Fetch_BranchById(this.otherChargesService.selectedOtherCharges.CustomerId??0, 'CUSTOMERID')
      .subscribe(data => {
        this.brnchService.allBranchs = data
        if(data.length==1)
          this.otherChargesService.selectedOtherCharges.BranchId=data[0].Id;
      },
        error => {
          this.errorMsg = error;
        });
      this.loadBatch();
  }

  loadBatch()
  {
    this.otherChargesService.selectedOtherCharges.BatchId=undefined;
    this.commonService.getCommonList('BATCHLIST',this.otherChargesService.selectedOtherCharges.CustomerId??0).subscribe(
      data=>{this.otherChargesService.allBatch=data
      },
      error=>{this.errorMsg=error}
    )
  }

  loadChargeType()
  {
    this.otherChargesService.addtionalModuleList=[];
     this.chargeTypeService.getAllCommonCodeNameMaster('3').subscribe(data=>
      {
        this.chargeTypeService.allChargeType=data
      },error=>{this.errorMsg=error})
  }

  loadBranchWiseModule(TransMode:string)
  {
     this.textType="Number of Nodes";
    if(this.otherChargesService.selectedOtherCharges.ChargeTypeId==2)
    {
      this.otherChargesService.getBranchWiseModule(TransMode,this.otherChargesService.selectedOtherCharges.DealerId > 0?this.otherChargesService.selectedOtherCharges.DealerId:0,this.otherChargesService.selectedOtherCharges.CustomerId > 0?this.otherChargesService.selectedOtherCharges.CustomerId:0).subscribe(data=>
        {
          this.otherChargesService.addtionalModuleList=data
        },error=>{this.errorMsg=error})
    }
    else if(this.otherChargesService.selectedOtherCharges.ChargeTypeId==4)
      this.textType="Number of Characters";
  }

  selectedModule(event,obj:AddtionalModuleList)
  {
    if (event.target.checked)
    {
      this.otherChargesService.selectedOtherCharges.AddtionalModuleList.push({
        ModuleId:obj.ModuleId,
        ModuleName:obj.ModuleName,
        CheckStatus:true,
        ModuleStatus:1
      });
    }
    else
    {
      if (this.otherChargesService.selectedOtherCharges.AddtionalModuleList.find(x => x.ModuleId == obj.ModuleId)) 
        this.otherChargesService.selectedOtherCharges.AddtionalModuleList.splice(this.otherChargesService.selectedOtherCharges.AddtionalModuleList.findIndex(x => x.ModuleId == obj.ModuleId), 1);
    }
  }

  formValidation(): boolean {  
     if (this.otherChargesService.selectedOtherCharges.VNo != 0 && this.otherChargesService.selectedOtherCharges.Date != null && this.otherChargesService.selectedOtherCharges.DealerId != 0
      && this.otherChargesService.selectedOtherCharges.CustomerId != 0 && this.otherChargesService.selectedOtherCharges.BranchId != 0
      && this.otherChargesService.selectedOtherCharges.Charge != null)
      return true;
    else
      return false;
  }

  onSubmit(form:NgForm)
  {
     this.FormValidation = true;
      if(form.valid && this.formValidation())
      {
        this.otherChargesService.saveOtherCharge(this.otherChargesService.selectedOtherCharges,this.otherChargesService.selectedOtherCharges.AddtionalModuleList).subscribe(data=>{
          if(data.Status==200)
            {
              this.resetForm();
              this.toastrService.success(data.Message,'Other Charges')
            }
            else if(data.Status==201)
              this.toastrService.info(data.Message,'Other Charges')
            else if(data.Status==500)
              this.toastrService.error(data.Message,'Other Charges')
        })
      }
      else
      {
        if(this.otherChargesService.selectedOtherCharges.ChargeTypeId==2 && this.otherChargesService.selectedOtherCharges.AddtionalModuleList.length==0)
        {
          this.toastrService.warning('Please Select One New Module','Other Charges')
          return false;
        }
        else
          this.toastrService.warning('Please Fill All Mandatory Fields.', 'Other Charges');
      }
  }

  deleteOtherCharge()
  {
    this.otherChargesService.deleteOtherCharge().subscribe(
      data=>
      {
        if (data.Status == 200) {
          this.toastrService.success(data.Message, 'Other Charges')
          this.resetForm();
        }
        else
          this.toastrService.warning(data.Message, 'Other Charges')
      },
      error=>{
        this.errorMsg=error;
        this.toastrService.error(this.errorMsg, 'Other Charges')
      })
  }

  searchOtherCharge()
  {
    if (this.otherChargesService.selectedOtherCharges.VNo != null)
    this.otherChargesService.getOtherCharge(this.otherChargesService.selectedOtherCharges.VNo).subscribe(obj => {
      this.otherChargesService.selectedOtherCharges = obj
      this.otherChargesService.selectedOtherCharges.AddtionalModuleList=obj.AddtionalModuleList;
      if(this.otherChargesService.selectedOtherCharges.Id == 0) {
        this.resetForm();
        this.toastrService.warning('Vno Does Not Exist', 'Other Charges')
      }
      else
        this.loadBranchWiseModule('ALL');   
    })
  }

}
