<div id="AtjDeletePopUp" class="Atj_overlay " (click)="$event.stopPropagation(); dismiss()">
    <div id="Atj_popup" class="Atj_popup" (click)="$event.stopPropagation();">
        <div class="headding"> {{ title }}</div>
        <a class="close" (click)="dismiss()">&times;</a>

        <div class="Atj_content">
            {{ message }}<br>
            <div style="float: right;">
                <button class="btn btn-sm btn-success" (click)="decline()">{{ btnCancelText }}</button> &nbsp;
                <button class="btn btn-sm btn-danger" (click)="accept()">{{ btnOkText }} </button>
            </div>
        </div>

    </div>

</div>