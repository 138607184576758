import { CommonService } from 'src/app/Services/common.service';
import { error } from '@angular/compiler/src/util';
import { from } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ChargeTypeService } from './../../../../../Services/charge-type.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-charge-type-form',
  templateUrl: './charge-type-form.component.html',
  styleUrls: ['./charge-type-form.component.css']
})
export class ChargeTypeFormComponent implements OnInit {

  FormValidation: boolean
  isSubmit:boolean;

  constructor(public chargeTypeService:ChargeTypeService,
              public toastrService:ToastrService,
              public commonService:CommonService) { }

  ngOnInit(): void {
    this.chargeTypeService.formHideShow=0;
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this.chargeTypeService.selectedChargeType = {
      Id:0,
      Code: '',
      Name: ''
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this.chargeTypeService.formHideShow = 0;
  }

  formValidation(): boolean {
    if (this.chargeTypeService.selectedChargeType.Code != "" && this.chargeTypeService.selectedChargeType.Name != "")
      return true;
    else
      return false;
  }

  onSubmit(form:NgForm)
  {
    this.FormValidation=true;
    if(form.valid && this.formValidation())
    {
      this.chargeTypeService.commonCodeNameMaster_Trans(form.value,this.commonService.formId).subscribe(data=>{
        this.isSubmit = false
        if(data.Status==200)
        {
          this.resetForm();
          this.toastrService.success(data.Message,this.commonService.commonNameText)
          this.chargeTypeService.getAllCommonCodeNameMaster(this.commonService.formId).subscribe(list=>{
            this.chargeTypeService.allChargeType=list},error=>{this.toastrService.error('Failed to Load Charge Type','Charge Type')})
          this.chargeTypeService.formHideShow=0;
        }
        else if(data.Status==201)
        {
          this.toastrService.warning(data.Message,this.commonService.commonNameText);
          return;
        }
        else
          {
            this.toastrService.error(data.Message,this.commonService.commonNameText)
            return;
          }
      },error=>{this.toastrService.error('Add/Update Record is Failure',this.commonService.commonNameText)}
      )
    }
    else 
      this.toastrService.warning('Please fill all mandatory fields.', this.commonService.commonNameText);
  }

}
