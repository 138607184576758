import { Settings } from './Models/TimeSettings.model';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './Layout/common/full/full.component';
import { NavbarComponent } from './Layout/common/navbar/navbar.component';
import { FooterComponent } from './Layout/common/footer/footer.component';
import { AuthGuard } from './auth/auth.guard';
import { DashboardComponent } from './DashBoard/Admin/dashboard.component';
import { Error404Component } from './General/error404/error404.component';
import { ChangepasswordComponent } from './General/changepassword/changepassword.component';
import { SidebarComponent } from './Layout/sidebar/sidebar.component';
import { DealerDashboardComponent } from './DashBoard/dealer-dashboard/dealer-dashboard.component';
import { CountryComponent } from './Modules/Admin/Masters/country/country.component';
import { CountryFormComponent } from './Modules/Admin/Masters/country/country-form/country-form.component';
import { CountryListComponent } from './Modules/Admin/Masters/country/country-list/country-list.component';
import { DealerComponent } from './Modules/Admin/Masters/dealer/dealer.component';
import { DealerFormComponent } from './Modules/Admin/Masters/dealer/dealer-form/dealer-form.component';
import { DealerListComponent } from './Modules/Admin/Masters/dealer/dealer-list/dealer-list.component';
import { ProjectComponent } from './Modules/Admin/Masters/project/project.component';
import { ProjectFormComponent } from './Modules/Admin/Masters/project/project-form/project-form.component';
import { ProjectListComponent } from './Modules/Admin/Masters/project/project-list/project-list.component';
import { ParticularMasterComponent } from './Modules/Admin/Masters/particular-master/particular-master.component';
import { ParticularMasterFormComponent } from './Modules/Admin/Masters/particular-master/particular-master-form/particular-master-form.component';
import { ParticularMasterListComponent } from './Modules/Admin/Masters/particular-master/particular-master-list/particular-master-list.component';
import { SparePartsComponent } from './Modules/Admin/Masters/spare-parts/spare-parts.component';
import { SparePartsListComponent } from './Modules/Admin/Masters/spare-parts/spare-parts-list/spare-parts-list.component';
import { SparePartsFormComponent } from './Modules/Admin/Masters/spare-parts/spare-parts-form/spare-parts-form.component';
import { ChargeTypeComponent } from './Modules/Admin/Masters/charge-type/charge-type.component';
import { ChargeTypeFormComponent } from './Modules/Admin/Masters/charge-type/charge-type-form/charge-type-form.component';
import { ChargeTypeListComponent } from './Modules/Admin/Masters/charge-type/charge-type-list/charge-type-list.component';
import { ServicesComponent } from './Modules/Admin/Masters/services/services.component';
import { ServicesListComponent } from './Modules/Admin/Masters/services/services-list/services-list.component';
import { ServicesFormComponent } from './Modules/Admin/Masters/services/services-form/services-form.component';
import { DesignationComponent } from './Modules/Admin/Masters/designation/designation.component';
import { DesignationListComponent } from './Modules/Admin/Masters/designation/designation-list/designation-list.component';
import { DesignationFormComponent } from './Modules/Admin/Masters/designation/designation-form/designation-form.component';
import { ModuleComponent } from './Modules/Admin/Masters/module/module.component';
import { ModuleListComponent } from './Modules/Admin/Masters/module/module-list/module-list.component';
import { ModuleFormComponent } from './Modules/Admin/Masters/module/module-form/module-form.component';
import { BussinessTypeComponent } from './Modules/Admin/Masters/bussiness-type/bussiness-type.component';
import { BussinessTypeListComponent } from './Modules/Admin/Masters/bussiness-type/bussiness-type-list/bussiness-type-list.component';
import { BussinessTypeFormComponent } from './Modules/Admin/Masters/bussiness-type/bussiness-type-form/bussiness-type-form.component';
import { EmployeeComponent } from './Modules/Admin/Masters/employee/employee.component';
import { EmployeeListComponent } from './Modules/Admin/Masters/employee/employee-list/employee-list.component';
import { EmployeeFormComponent } from './Modules/Admin/Masters/employee/employee-form/employee-form.component';
import { AmcRenewalComponent } from './Modules/Admin/Dealers/amc-renewal/amc-renewal.component';
import { AmcRenewalFormComponent } from './Modules/Admin/Dealers/amc-renewal/amc-renewal-form/amc-renewal-form.component';
import { AmcRenewalListComponent } from './Modules/Admin/Dealers/amc-renewal/amc-renewal-list/amc-renewal-list.component';
import { BranchComponent } from './Modules/Admin/Dealers/branch/branch.component';
import { BranchListComponent } from './Modules/Admin/Dealers/branch/branch-list/branch-list.component';
import { BranchFormComponent } from './Modules/Admin/Dealers/branch/branch-form/branch-form.component';
import { BusinessExecutiveComponent } from './Modules/Admin/Dealers/business-executive/business-executive.component';
import { BusinessExecutiveListComponent } from './Modules/Admin/Dealers/business-executive/business-executive-list/business-executive-list.component';
import { BusinessExecutiveFormComponent } from './Modules/Admin/Dealers/business-executive/business-executive-form/business-executive-form.component';
import { CustomerComponent } from './Modules/Admin/Dealers/customer/customer.component';
import { CustomerFormComponent } from './Modules/Admin/Dealers/customer/customer-form/customer-form.component';
import { CustomerListComponent } from './Modules/Admin/Dealers/customer/customer-list/customer-list.component';
import { RouteMasterComponent } from './Modules/Admin/Dealers/route-master/route-master.component';
import { RouteMasterFormComponent } from './Modules/Admin/Dealers/route-master/route-master-form/route-master-form.component';
import { RouteMasterListComponent } from './Modules/Admin/Dealers/route-master/route-master-list/route-master-list.component';
import { SoftwareRegistrationComponent } from './Modules/Admin/Dealers/software-registration/software-registration.component';
import { SoftwareRegistrationFormComponent } from './Modules/Admin/Dealers/software-registration/software-registration-form/software-registration-form.component';
import { SoftwareRegistrationListComponent } from './Modules/Admin/Dealers/software-registration/software-registration-list/software-registration-list.component';
import { NodeRegComponent } from './Modules/Admin/Dealers/node-reg/node-reg.component';
import { NodeRegFormComponent } from './Modules/Admin/Dealers/node-reg/node-reg-form/node-reg-form.component';
import { NodeRegListComponent } from './Modules/Admin/Dealers/node-reg/node-reg-list/node-reg-list.component';
import { ServiceReportEntryComponent } from './Modules/Admin/Support-Engineer/service-report-entry/service-report-entry.component';
import { ServiceReportForProductsComponent } from './Modules/Admin/Support-Engineer/service-report-for-products/service-report-for-products.component';
import { ServiceReportForProductsFormComponent } from './Modules/Admin/Support-Engineer/service-report-for-products/service-report-for-products-form/service-report-for-products-form.component';
import { ServiceReportForProductsListComponent } from './Modules/Admin/Support-Engineer/service-report-for-products/service-report-for-products-list/service-report-for-products-list.component';
import { CustomerRequirementsComponent } from './Modules/Admin/Support-Engineer/customer-requirements/customer-requirements.component';
import { CustomerFeedbackComponent } from './Modules/Admin/Support-Engineer/customer-feedback/customer-feedback.component';
import { CustomerFeedbackListComponent } from './Modules/Admin/Support-Engineer/customer-feedback/customer-feedback-list/customer-feedback-list.component';
import { CustomerFeedbackFormComponent } from './Modules/Admin/Support-Engineer/customer-feedback/customer-feedback-form/customer-feedback-form.component';
import { SupportEngineerHomeScreenComponent } from './Modules/Admin/Support-Engineer/support-engineer-home-screen/support-engineer-home-screen.component';
import { ProjectAllocationComponent } from './Modules/Admin/Support-Engineer/project-allocation/project-allocation.component';
import { ProjectForecastingComponent } from './Modules/Admin/Support-Engineer/project-forecasting/project-forecasting.component';
import { ProjectAllocationListComponent } from './Modules/Admin/Support-Engineer/project-allocation/project-allocation-list/project-allocation-list.component';
import { ProjectAllocationFormComponent } from './Modules/Admin/Support-Engineer/project-allocation/project-allocation-form/project-allocation-form.component';
import { WorkSortingComponent } from './Modules/Admin/Support-Leader/work-sorting/work-sorting.component'
import { WorkAllocationComponent } from './Modules/Admin/Support-Leader/work-allocation/work-allocation.component'
import { SupportLeaderHomeComponent } from './Modules/Admin/Support-Leader/support-leader-home/support-leader-home.component'
import { CustomerFeedBackMasterComponent } from './Modules/Admin/Support-Engineer/customer-feed-back-master/customer-feed-back-master.component';
import { CustomerFeedBackMasterFormComponent } from './Modules/Admin/Support-Engineer/customer-feed-back-master/customer-feed-back-master-form/customer-feed-back-master-form.component';
import { CustomerFeedBackMasterListComponent } from './Modules/Admin/Support-Engineer/customer-feed-back-master/customer-feed-back-master-list/customer-feed-back-master-list.component';
import { ProjectAllotmentComponent } from './Modules/Provider/Project-Manager/project-allotment/project-allotment.component';
import { ProjectManagerHomeComponent } from './Modules/Provider/Project-Manager/project-manager-home/project-manager-home.component';
import { TimeSettingsComponent } from './Modules/Admin/Masters/time-settings/time-settings.component';
import { WorkAllocationStatusComponent } from './Modules/Provider/Project-Manager/work-allocation-status/work-allocation-status.component';
import { ServiceHistoryComponent } from './Modules/Admin/Dealers/service-history/service-history.component';
import { NewLeadComponent } from './Modules/Admin/Masters/new-lead/new-lead.component';
import { ReasonMasterComponent } from './Modules/Admin/Masters/reason-master/reason-master.component';
import { ReasonMasterFormComponent } from './Modules/Admin/Masters/reason-master/reason-master-form/reason-master-form.component';
import { ReasonMasterListComponent } from './Modules/Admin/Masters/reason-master/reason-master-list/reason-master-list.component';

import { ProWorkAllotmentComponent } from './Modules/Provider/Project Leader/pro-work-allotment/pro-work-allotment.component';
import { JobCardComponent } from './Modules/Provider/Developer/job-card/job-card.component';
import { Siderbar2Component } from './Layout/siderbar2/siderbar2.component';
import { ProjectLeaderHomeComponent } from './Modules/Provider/Project Leader/project-leader-home/project-leader-home.component';
import { LeaveApplicationComponent } from './Modules/Common/leave-application/leave-application.component';
import { LeaveApplicationListComponent } from './Modules/Common/leave-application/leave-application-list/leave-application-list.component';
import { LeaveApplicationFormComponent } from './Modules/Common/leave-application/leave-application-form/leave-application-form.component';

import { DevelopersHomeScreenComponent } from './DashBoard/developers-home-screen/developers-home-screen.component';
import { TestersHomeScreenComponent } from './DashBoard/testers-home-screen/testers-home-screen.component';
import { HolidaySettingsComponent } from './Modules/Admin/Masters/holiday-settings/holiday-settings.component';

import { BacklogsComponent } from './Modules/Provider/Reports/backlogs/backlogs.component';
import { OutstandingComponent } from './Modules/Provider/Reports/Customer/outstanding/outstanding.component';
import { TodaysDeliveryComponent } from './Modules/Provider/Reports/todays-delivery/todays-delivery.component';
import { WorkDeliveryComponent } from './Modules/Provider/Tester/work-delivery/work-delivery.component';
import { IncomeComponent } from './Modules/Provider/Reports/income/income.component';
import { SytemdetailsComponent } from './Modules/Provider/Reports/sytemdetails/sytemdetails.component';
// import { TesterHomeComponent } from './Modules/Provider/Tester/tester-home/tester-home.component';

import { BranchSettingsComponent } from './Modules/Admin/Dealers/branch-settings/branch-settings.component';
import { BranchSettingsFormComponent } from './Modules/Admin/Dealers/branch-settings/branch-settings-form/branch-settings-form.component';
import { BranchSettingsListComponent } from './Modules/Admin/Dealers/branch-settings/branch-settings-list/branch-settings-list.component';
import { CompanyRegistrationComponent } from './Modules/Admin/Masters/company-registration/company-registration.component';
import { AttandenceregisterComponent } from './Modules/Admin/attandenceregister/attandenceregister.component';
import { TimeSheetComponent } from './Modules/Admin/time-sheet/time-sheet.component';
import { ServicechargeComponent } from './Modules/Provider/Project-Manager/servicecharge/servicecharge.component';
import { CloudchargeComponent } from './Modules/Provider/Project-Manager/cloudcharge/cloudcharge.component';
import { CscancellationListComponent } from './Modules/Provider/Project-Manager/cloudcharge/cscancellation/cscancellation-list/cscancellation-list.component';
import { CscancellationFormComponent } from './Modules/Provider/Project-Manager/cloudcharge/cscancellation/cscancellation-form/cscancellation-form.component';
import { CscancellationComponent } from './Modules/Provider/Project-Manager/cloudcharge/cscancellation/cscancellation.component';
import { OtherChargesComponent } from './Modules/Provider/Project-Manager/other-charges/other-charges.component';
import { ReceiptsComponent } from './Modules/Provider/Project-Manager/receipts/receipts.component';
import { PaymentPostphonedComponent } from './Modules/Provider/Project-Manager/payment-postphoned/payment-postphoned.component';
import { WorkSheetComponent } from './Modules/Provider/Project-Manager/work-sheet/work-sheet.component';
import { WorkDetailsComponent } from './Modules/Provider/Project-Manager/work-details/work-details.component';
import { DocumentsettingComponent } from './Modules/Settings/doc-setting/documentsetting-form/documentsetting.component';
import { DocumentsettingListComponent } from './Modules/Settings/doc-setting/documentsetting-list/documentsetting-list.component';
import { DocSettingComponent } from './Modules/Settings/doc-setting/doc-setting.component';
import { MeetingComponent } from './Modules/Provider/meeting/meeting.component';
import { MeetingFormComponent } from './Modules/Provider/meeting/meeting-form/meeting-form.component';
import { MeetingListComponent } from './Modules/Provider/meeting/meeting-list/meeting-list.component';
import { JobschedulingComponent } from './Modules/Provider/jobscheduling/jobscheduling.component';
import { BatchwiseJobdetailsComponent } from './Modules/Provider/MIS/batchwise-jobdetails/batchwise-jobdetails.component';
import { AssignedJobsForecastingComponent } from './Modules/Provider/Project Leader/Job-Management/assigned-jobs-forecasting/assigned-jobs-forecasting.component';
import { ProjectCostAnalysisComponent } from './Modules/Provider/MIS/project-cost-analysis/project-cost-analysis.component';
import { ServerProviderComponent } from './Modules/Admin/ServerMaster/server-provider/server-provider.component';
import { DomainComponent } from './Modules/Admin/ServerMaster/domain/domain.component';
import { ServerProviderFormComponent } from './Modules/Admin/ServerMaster/server-provider/server-provider-form/server-provider-form.component';
import { ServerProviderListComponent } from './Modules/Admin/ServerMaster/server-provider/server-provider-list/server-provider-list.component';
import { DomainFormComponent } from './Modules/Admin/ServerMaster/domain/domain-form/domain-form.component';
import { DomainListComponent } from './Modules/Admin/ServerMaster/domain/domain-list/domain-list.component';
import { ServerComponent } from './Modules/Admin/ServerMaster/server/server.component';
import { ServerFormComponent } from './Modules/Admin/ServerMaster/server/server-form/server-form.component';
import { ServerListComponent } from './Modules/Admin/ServerMaster/server/server-list/server-list.component';


const routes: Routes = [
  //Site routes goes here 
  {
    path: '',
    component: FullComponent,
    children: [
      //Dealer
      { path: 'dealerdashboard', component: DealerDashboardComponent, canActivate: [AuthGuard], data: { roles: ['Dealer', 'Admin'] } },
      { path: 'employee', component: EmployeeComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Admin', 'Dealer'] } },
      { path: 'branch', component: BranchComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Dealer'] } },
      { path: 'branchsettings', component: BranchSettingsComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Dealer'] } },
      { path: 'customer', component: CustomerComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Dealer'] } },
      { path: 'routemaster', component: RouteMasterComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Dealer'] } },
      { path: 'amcrenewal', component: AmcRenewalComponent, canActivate: [AuthGuard], data: { roles: ['Dealer'] } },
      { path: 'softwareregistration', component: SoftwareRegistrationComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Dealer'] } },
      { path: 'noderegistration', component: NodeRegComponent, canActivate: [AuthGuard], data: { roles: ['Dealer'] } },
      { path: 'designation', component: DesignationComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'servicehistory', component: ServiceHistoryComponent, canActivate: [AuthGuard], data: { roles: ['Admin', 'Dealer'] } },

      //Admin
      { path: 'admin', component: DashboardComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'country', component: CountryComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'dealer', component: DealerComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'project', component: ProjectComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'spareparts', component: SparePartsComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'chargeType/:formId', component: ChargeTypeComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager'] } },
      { path: 'services', component: ServicesComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'module', component: ModuleComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'bussinesstype', component: BussinessTypeComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Admin'] } },
      { path: 'businessexecutive', component: BusinessExecutiveComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'projectallocation', component: ProjectAllocationComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'workForecasting', component: ProjectForecastingComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'settings', component: TimeSettingsComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'newlead', component: NewLeadComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Support Leader', 'Admin', 'Dealer'] } },
      { path: 'companyRegistration', component: CompanyRegistrationComponent, canActivate: [AuthGuard], data: { roles: ['Admin',] } },
      { path: 'attandence', component: AttandenceregisterComponent, canActivate: [AuthGuard], data: { roles: ['Admin', 'Project Manager', 'Project Leader','Developers & Designers', 'Testers'] } },
      { path: 'timesheet', component: TimeSheetComponent, canActivate: [AuthGuard], data: { roles: ['Admin', 'Project Manager', 'Project Leader','Developers & Designers', 'Testers'] } },
      //Server Master
      { path: 'serverprovider', component: ServerProviderComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'domain', component: DomainComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'server', component: ServerComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },

      //Support Engineer  
      { path: 'supportEng', component: SupportEngineerHomeScreenComponent, canActivate: [AuthGuard], data: { roles: ['Support Engineer'] } },
      { path: 'particularmaster', component: ParticularMasterComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager', 'Admin','Project Leader'] } },
      { path: 'customerrequirement', component: CustomerRequirementsComponent, canActivate: [AuthGuard], data: { roles: ['Support Engineer', 'Project Manager', 'Support Leader', 'Project Leader', 'Developers & Designers', 'Testers'] } },
      { path: 'servicereportentry', component: ServiceReportEntryComponent, canActivate: [AuthGuard], data: { roles: ['Support Engineer', 'Project Manager', 'Support Leader', 'Project Leader', 'Developers & Designers', 'Testers'] } },
      { path: 'customerfeedback', component: CustomerFeedbackComponent, canActivate: [AuthGuard], data: { roles: ['Support Engineer'] } },
      { path: 'customerfeedbackmaster', component: CustomerFeedBackMasterComponent, canActivate: [AuthGuard], data: { roles: ['Support Engineer'] } },
      { path: 'reasonmaster', component: ReasonMasterComponent, canActivate: [AuthGuard], data: { roles: ['Admin','Project Manager'] } },


      //Support Leader
      { path: 'worksorting', component: WorkSortingComponent, canActivate: [AuthGuard], data: { roles: ['Support Leader'] } },
      { path: 'workallocation', component: WorkAllocationComponent, canActivate: [AuthGuard], data: { roles: ['Support Leader'] } },
      { path: 'supportleader', component: SupportLeaderHomeComponent, canActivate: [AuthGuard], data: { roles: ['Support Leader'] } },

      //Project Manager
      { path: 'projectmanager', component: ProjectManagerHomeComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager'] } },
      { path: 'projectallotment', component: ProjectAllotmentComponent },
      { path: 'workstatus', component: WorkAllocationStatusComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager'] } },
      { path: 'servicecharge', component: ServicechargeComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager'] } },
      { path: 'cloudcharge', component: CloudchargeComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager'] } },
      { path :'cscancellation', component:CscancellationComponent,canActivate:[AuthGuard],data:{ roles:['Project Manager'] } },
      { path: 'otherCharges',component: OtherChargesComponent,canActivate:[AuthGuard],data:{ roles:['Project Manager']}},
      { path: 'receipts', component: ReceiptsComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager'] } },
      { path: 'paymentpostphoned', component: PaymentPostphonedComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager'] } },
      { path: 'workdetails', component: WorkDetailsComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager','Project Leader','Developers & Designers', 'Testers'] } },
      { path: 'worksheet', component: WorkSheetComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager','Project Leader','Developers & Designers', 'Testers'] } },

      //Project leader
      { path: 'prowork', component: ProWorkAllotmentComponent, canActivate: [AuthGuard], data: { roles: ['Project Leader', 'Project Manager'] } },
      { path: 'projectleader', component: ProjectLeaderHomeComponent, canActivate: [AuthGuard], data: { roles: ['Project Leader'] } },

      { path: 'jobcard', component: JobCardComponent, canActivate: [AuthGuard], data: { roles: ['Project Leader', 'Developers & Designers', 'Testers', 'Project Manager'] } },
      { path: 'leave', component: LeaveApplicationComponent,canActivate: [AuthGuard] },//For all users.
      { path: 'meeting', component: MeetingComponent,canActivate: [AuthGuard]},
      // { path: 'jobscheduling',loadChildren:()=>import('./Modules/Provider/jobscheduling/jobschedulingmodule.module').then(m=>m.JobschedulingModule),canActivate: [AuthGuard], data: { roles: ['Project Leader','Project Manager'] } },
      { path: 'jobscheduling', component: JobschedulingComponent,canActivate: [AuthGuard],data: { roles: ['Project Leader','Project Manager'] }},

         //Jobs Management
      { path:'asjobsmangement', component: AssignedJobsForecastingComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager','Project Leader','Developers & Designers', 'Testers'] } },

      //Reports
      { path: 'holydaysetting', component: HolidaySettingsComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
      { path: 'Backlogs', component: BacklogsComponent, canActivate: [AuthGuard], data: { roles: ['Admin', 'Project Manager'] } },
      { path: 'outstanding', component: OutstandingComponent, canActivate: [AuthGuard], data: { roles: ['Admin', 'Project Manager','Support Leader'] } },
      { path: 'TodaysDelivery', component: TodaysDeliveryComponent, canActivate: [AuthGuard], data: { roles: ['Admin', 'Project Manager'] } },
      { path: 'workdelivery', component: WorkDeliveryComponent, canActivate: [AuthGuard], data: { roles: ['Testers'] } },
      { path :'income',component:IncomeComponent,canActivate:[AuthGuard],data: { roles: ['Project Manager']} },
      { path:'sytemdetails',component:SytemdetailsComponent,canActivate:[AuthGuard],data:{ roles:['Project Manager']} },

      //Mis
      { path:'jobdetails', component: BatchwiseJobdetailsComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager','Project Leader'] } },
      { path:'projectcostanalysis', component:ProjectCostAnalysisComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager','Project Leader'] } },

      //Settings
      { path: 'documentsettings', component: DocSettingComponent, canActivate: [AuthGuard], data: { roles: ['Project Manager','Project Leader','Developers & Designers', 'Testers'] } },

      {
        path: 'developer', canActivate: [AuthGuard], data: { roles: ['Developers & Designers'] },
        loadChildren: './Modules/Provider/developer.module#DeveloperModule'
      },

      // Tester
      { path: 'testerhmescreen', component: TestersHomeScreenComponent, canActivate: [AuthGuard], data: { roles: ['Testers'] } },
      {
        path: 'tester', canActivate: [AuthGuard], data: { roles: ['Testers'] },
        loadChildren: './Modules/Provider/tester.module#TesterModule'
      },
      // USe this path on Lazy Loading
      { path: 'admin', canActivate: [AuthGuard], data: { roles: ['Admin'] }, loadChildren: './Modules/Admin/admin.module#AdminModule' },
      { path: 'dealer', canActivate: [AuthGuard], data: { roles: ['Dealer', 'Admin'] }, loadChildren: './Modules/Admin/Dealers/dealer.module#DealerModule' },
      { path: 'supportEng', canActivate: [AuthGuard], data: { roles: ['Support Engineer'] }, loadChildren: './Modules/Admin/Support-Engineer/supporteng.module#SupportengModule' },
      { path: 'supportleader', canActivate: [AuthGuard], data: { roles: ['Support Leader'] }, loadChildren: './Modules/Admin/Support-Leader/supportleader.module#SupportleaderModule' },
      { path: 'projectmanager', canActivate: [AuthGuard], data: { roles: ['Project Manager'] }, loadChildren: './Modules/Provider/Project-Manager/projectmanager.module#ProjectmanagerModule' },
      { path: 'projectleader', canActivate: [AuthGuard], data: { roles: ['Project Leader'] }, loadChildren: './Modules/Provider/Project Leader/projectleader.module#ProjectleaderModule' },
      // { path: 'documentsettings', loadChildren: () => import('./Modules/Settings/documentsetting/documentsetting.module').then(m => m.DocumentSettingModule), canActivate: [AuthGuard],data: { roles: ['Admin', 'Project Manager','Project Leader'] }  },


      { path: '', redirectTo: '/login', pathMatch: 'full' },
    ]
  },

  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'login', loadChildren: () => import('./General/login/loginmodule').then(m => m.LoginModule) },
  { path: 'error404', component: Error404Component },
  { path: '**', component:Error404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  FullComponent,
  NavbarComponent,
  FooterComponent,
  ChangepasswordComponent,
  Error404Component,
  SidebarComponent,
  DashboardComponent,
  DealerDashboardComponent,
  CountryFormComponent, CountryListComponent, CountryComponent,
  DealerComponent, DealerFormComponent, DealerListComponent,
  ProjectComponent, ProjectFormComponent, ProjectListComponent,
  ParticularMasterComponent, ParticularMasterFormComponent, ParticularMasterListComponent,
  SparePartsComponent, SparePartsFormComponent, SparePartsListComponent,
  ChargeTypeComponent, ChargeTypeFormComponent, ChargeTypeListComponent,
  ServicesComponent, ServicesFormComponent, ServicesListComponent,
  DesignationComponent, DesignationListComponent, DesignationFormComponent,
  ModuleComponent, ModuleFormComponent, ModuleListComponent,
  BussinessTypeComponent, BussinessTypeFormComponent, BussinessTypeListComponent,
  EmployeeComponent, EmployeeListComponent, EmployeeFormComponent,
  AmcRenewalComponent, AmcRenewalFormComponent, AmcRenewalListComponent,
  BranchComponent, BranchListComponent, BranchFormComponent,
  BusinessExecutiveComponent, BusinessExecutiveListComponent, BusinessExecutiveFormComponent,
  CustomerComponent, CustomerFormComponent, CustomerListComponent,
  RouteMasterComponent, RouteMasterFormComponent, RouteMasterListComponent,
  SoftwareRegistrationComponent, SoftwareRegistrationFormComponent, SoftwareRegistrationListComponent,
  NodeRegComponent, NodeRegFormComponent, NodeRegListComponent,
  ServiceReportEntryComponent,
  ServiceReportForProductsComponent, ServiceReportForProductsFormComponent, ServiceReportForProductsListComponent,
  CustomerRequirementsComponent,
  CustomerFeedbackComponent, CustomerFeedbackListComponent, CustomerFeedbackFormComponent,
  SupportEngineerHomeScreenComponent, ProjectAllocationComponent, ProjectForecastingComponent,
  ProjectAllocationListComponent, ProjectAllocationFormComponent,
  WorkSortingComponent, WorkAllocationComponent, SupportLeaderHomeComponent,
  CustomerFeedBackMasterComponent, CustomerFeedBackMasterFormComponent, CustomerFeedBackMasterListComponent,
  ProjectManagerHomeComponent,
  ProjectAllotmentComponent,
  TimeSettingsComponent,
  WorkAllocationStatusComponent,
  ServiceHistoryComponent,
  NewLeadComponent,
  ReasonMasterComponent,
  ReasonMasterFormComponent, CompanyRegistrationComponent,
  ReasonMasterListComponent,
  ProjectLeaderHomeComponent,
  Siderbar2Component,
  ProWorkAllotmentComponent, JobCardComponent,
  DevelopersHomeScreenComponent, TestersHomeScreenComponent, HolidaySettingsComponent,
  LeaveApplicationComponent, LeaveApplicationListComponent, LeaveApplicationFormComponent,
  BacklogsComponent,OutstandingComponent, TodaysDeliveryComponent, WorkDeliveryComponent,IncomeComponent,SytemdetailsComponent,
  BranchSettingsComponent,BranchSettingsFormComponent,BranchSettingsListComponent,
  AttandenceregisterComponent, TimeSheetComponent,
  ServicechargeComponent,ReceiptsComponent,OtherChargesComponent, 
  CloudchargeComponent,CscancellationFormComponent,CscancellationListComponent,CscancellationComponent,
  PaymentPostphonedComponent,
  WorkSheetComponent, WorkDetailsComponent,
  DocumentsettingComponent,DocumentsettingListComponent,DocSettingComponent,
  MeetingComponent,MeetingFormComponent,MeetingListComponent,JobschedulingComponent,
  BatchwiseJobdetailsComponent,AssignedJobsForecastingComponent,ProjectCostAnalysisComponent,
  ServerProviderComponent,ServerProviderFormComponent,ServerProviderListComponent,
  DomainComponent,DomainFormComponent,DomainListComponent,
  ServerComponent,ServerFormComponent,ServerListComponent
]
