<div class="container-fluid">
    <form>
        <div class="row">
            <!-- flex-body -->
            <div class="col-md-12">
                <div class="FormBox">
                    <h2 class="heading_all">SERVICE HISTORY</h2>
                    <div id="form-wrap">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Dealer</label>
                                    <select class="form-control" name="DealerId" required (change)="LoadCustomers()"
                                        #DealerId="ngModel"
                                        [(ngModel)]="_serviceHistoryService.SelectedServiceHistory.DealerId">
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                            [ngValue]="prf.Id">
                                            {{ prf.DealerName }}
                                        </option>
                                    </select>
                                    <div class="form-group">
                                        <label>Customer</label>
                                        <select class="form-control" name="CustomerId" required #CustomerId="ngModel"
                                            (change)="onSelect()"
                                            [(ngModel)]="_serviceHistoryService.SelectedServiceHistory.CustomerId">
                                            <option [ngValue]="null" disabled>Select</option>
                                            <option
                                                *ngFor="let cust of  this._CustomerService.allCustomers  | orderBy : 'DealerName'"
                                                [ngValue]="cust.Id">
                                                {{ cust.Name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Address</label>
                                        <div class="blackcolor">
                                            {{_serviceHistoryService.SelectedServiceHistory.Address}}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Phone/Mobile:
                                        </label>
                                        <div class="blackcolor">
                                            {{_serviceHistoryService.SelectedServiceHistory.Phone}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Email</label>
                                    <div class="blackcolor">{{_serviceHistoryService.SelectedServiceHistory.Email}}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Contact
                                        Person:</label>
                                    <div class="blackcolor">
                                        {{_serviceHistoryService.SelectedServiceHistory.ContactPerson}}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Installation Date</label>
                                    <div class="blackcolor">
                                        {{_serviceHistoryService.SelectedServiceHistory.InstallationDate}}
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Duration:</label>
                                    <Div class="blackcolor">
                                        {{_serviceHistoryService.SelectedServiceHistory.Duration|date}}
                                    </Div>
                                </div>
                                <div class="form-group">
                                    <label>Be:</label>
                                    <div class="blackcolor">
                                        {{_serviceHistoryService.SelectedServiceHistory.BE}}
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>AMC</label>
                                    <div class="blackcolor">
                                        {{_serviceHistoryService.SelectedServiceHistory.AMCStatus}}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="table-responsive">
                                <table class="table tablepl">
                                    <thead>
                                        <tr>
                                            <th scope="col">SlNo</th>
                                            <th scope="col">RDate</th>
                                            <th scope="col">Ref No</th>
                                            <th scope="col">Mode Of Reg</th>
                                            <th scope="col">Reg SE</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Cleared Date</th>
                                            <th scope="col">SE</th>
                                            <th scope="col">Closed WithIn</th>
                                            <th scope="col">Expense</th>
                                            <th scope="col">Service Charge</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let obj of _serviceHistoryService.allServiceHistoryDtls;let i=index">
                                            <th scope="row">{{i+1}}</th>
                                            <td>{{obj.RDate}}</td>
                                            <td>{{obj.RefNo}}</td>
                                            <td>
                                                <label class="form-control-label">
                                                    <p *ngIf="obj.ModeOfReg==0"> Select </p>
                                                    <p *ngIf="obj.ModeOfReg==1"> By Phone </p>
                                                    <p *ngIf="obj.ModeOfReg==2"> By Mail </p>
                                                    <p *ngIf="obj.ModeOfReg==3"> By Chat </p>
                                                    <p *ngIf="obj.ModeOfReg==4"> On Site </p>
                                                    <p *ngIf="obj.ModeOfReg==5">Remote Section</p>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="form-control-label"
                                                    *ngFor="let field of this._CustomerrequirementService.allSE">
                                                    <p *ngIf="field.Id==obj.RegSE">
                                                        {{field.EmpName}}
                                                    </p>
                                                </label>
                                            </td>
                                            <td>
                                                <label class="form-control-label" *ngFor="let field of StatusList">
                                                    <p *ngIf="field.Id==obj.Status">
                                                        {{field.Name}}
                                                    </p>
                                                </label>
                                            </td>
                                            <td>{{obj.ClearedDate}}</td>
                                            <td>
                                                <label class="form-control-label"
                                                    *ngFor="let field of this._CustomerrequirementService.allSE">
                                                    <p *ngIf="field.Id==obj.SE">
                                                        {{field.EmpName}}
                                                    </p>
                                                </label>
                                            </td>
                                            <td>{{obj.ClosedWithin}}</td>
                                            <td>{{obj.Expense}}</td>
                                            <td>{{obj.ServiceCharge}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Total Work:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.TotalWorks}}</label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Error:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.Error}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">OnSite:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.OnSite}}</label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Modification:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.Modification}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Remote:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.Remote}}</label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Design:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.Design}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Expense:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.Design}}</label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Training:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.Training}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Service Charge:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.ServiceCharge}}</label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-8  form-control-label" for="select">Perfomance:</label>
                                    <div class="col-md-4">
                                        <label class="form-control-label"
                                            for="select">{{this._serviceHistoryService.SelectedServiceHistoryDtlsFooter.Perfomance}}
                                            days</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 
                        <div class="row">
                            <button type="submit" class=" sim-button button1">Submit</button>
                        </div> -->
                    </div>
                    <div class="tab-content">
                        <div id="sec1" class="container tab-pane active">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <!-- <label class="col-md-4  form-control-label" for="select">Dealer</label> -->
                                        <div class="col-md-8">
                                            <!-- <select class="form-control" name="DealerId" required
                                                (change)="LoadCustomers()" #DealerId="ngModel"
                                                [(ngModel)]="_serviceHistoryService.SelectedServiceHistory.DealerId">
                                                <option [ngValue]="null" disabled>Select</option>
                                                <option
                                                    *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                                    [ngValue]="prf.Id">
                                                    {{ prf.DealerName }}
                                                </option>
                                            </select> -->
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <!-- <label class="col-md-4  form-control-label" for="select">Customer</label> -->
                                        <div class="col-md-8">
                                            <!-- <select class="form-control" name="CustomerId" required
                                                #CustomerId="ngModel" (change)="onSelect()"
                                                [(ngModel)]="_serviceHistoryService.SelectedServiceHistory.CustomerId">
                                                <option [ngValue]="null" disabled>Select</option>
                                                <option
                                                    *ngFor="let cust of  this._CustomerService.allCustomers  | orderBy : 'DealerName'"
                                                    [ngValue]="cust.Id">
                                                    {{ cust.Name }}
                                                </option>
                                            </select> -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <!-- <label class="col-md-4  form-control-label" for="select">Address:</label> -->
                                        <div class="col-md-8">
                                            <!-- {{_serviceHistoryService.SelectedServiceHistory.Address}} -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <!-- <label class="col-md-4  form-control-label" for="select">Phone/Mobile:</label>
                                        <div class="col-md-8">
                                            {{_serviceHistoryService.SelectedServiceHistory.Phone}}
                                        </div> -->
                                    </div>
                                </div>

                                <div class="col-md-4">

                                    <div class="form-group row">
                                        <!-- <label class="col-md-3  form-control-label" for="select">Email:</label> -->
                                        <div class="col-md-9">
                                            <!-- {{_serviceHistoryService.SelectedServiceHistory.Email}} -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <!-- <label class="col-md-6  form-control-label" for="select">Contact
                                            Person:</label> -->
                                        <div class="col-md-6">
                                            <!-- {{_serviceHistoryService.SelectedServiceHistory.ContactPerson}} -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <!-- <label class="col-md-6  form-control-label" for="select">Installation
                                            Date:</label> -->
                                        <div class="col-md-6">
                                            <!-- {{_serviceHistoryService.SelectedServiceHistory.InstallationDate}} -->
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <!-- <label class="col-md-4  form-control-label" for="select">Duration:</label> -->
                                        <div class="col-md-8">
                                            <!-- {{_serviceHistoryService.SelectedServiceHistory.Duration|date}} -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <!-- <label class="col-md-4  form-control-label" for="select">BE:</label> -->
                                        <div class="col-md-8">
                                            <!-- {{_serviceHistoryService.SelectedServiceHistory.BE}} -->
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <!-- <label class="col-md-4  form-control-label" for="select">AMC:</label> -->
                                        <div class="col-md-8">
                                            <!-- {{_serviceHistoryService.SelectedServiceHistory.AMCStatus}} -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>