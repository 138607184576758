<!-- *ngIf="_nodeRegService.formHideShow!=0" -->
<div class="container-fluid">
    <h2 class="heading_all">Node Details</h2>
    <div id="form-wrap">
        <form #NodeRegistrationForm="ngForm" (ngSubmit)="onSubmit(NodeRegistrationForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_nodeRegService.selectedNodeRegistration.Id">

            <div class="tab-content">
                <div id="sec1" class="container tab-pane active">
                    <div class="row">
                        <div class="col-md-6">
                            <!-- <div class="form-group">
                                <label for="select">Ref.No<span style="color: red;">*</span></label>

                                <input class="form-control" name="RefNo" #RefNo="ngModel" autocomplete="off"
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.RefNo" placeholder=" RefNo"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(RefNo.invalid && RefNo.touched)||(RefNo.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div> -->


                            <!-- <div class="form-group">
                                <label>Country<span style="color: red;">*</span></label>

                                <select disabled class="form-control" name="CountryId" #CountryId="ngModel"
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.CountryId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let prf of _countryService.allCountries | orderBy : 'Name'"
                                        [ngValue]="prf.Id">
                                        {{ prf.Name }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(CountryId.invalid && CountryId.touched)||(CountryId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div> -->

                            <div class="form-group">
                                <label for="select">Dealer<span style="color: red;">*</span></label>

                                <select disabled class="form-control" name="DealerId" #DealerId="ngModel"
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.DealerId"
                                    (change)="LoadCustomers()" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.DealerName }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(DealerId.invalid && DealerId.touched)||(DealerId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>


                            </div>

                            <div class="form-group">
                                <label for="select">Customer<span style="color: red;">*</span></label>
                                <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel" bindLabel="Name"
                                    bindValue="Id" (ngModelChange)="onSelect($event);fillbranch();"
                                    [items]="_custService.allCustomers | orderBy : 'Name'" required
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.CustomerId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(CustomerId.invalid && CustomerId.touched)||(CustomerId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                {{this._nodeRegService.selectedNodeRegistration.CustomerId}}

                            </div>
                            <div class="form-group">
                                <label for="select">Branch<span style="color: red;">*</span></label>

                                <ng-select name="BranchId" id="BranchId" #BranchId="ngModel" bindLabel="BranchName"
                                    bindValue="Id" [items]="_brnchService.allBranchs | orderBy : 'BranchName'" required
                                    (ngModelChange)="LoadProject();"
                                    [(ngModel)]=" _nodeRegService.selectedNodeRegistration.BranchId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(BranchId.invalid && BranchId.touched)||(BranchId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                {{this._nodeRegService.selectedNodeRegistration.BranchId}}
                                <!-- (ngModelChange)="LoadSoftRegDtls();" -->
                            </div>

                            <div class="form-group">
                                <!-- disabled -->
                                <label for="select">Project<span style="color: red;">*</span></label>
                                <ng-select name="ProjectId" id="ProjectId" #ProjectId="ngModel" bindLabel="Name"
                                    bindValue="Id" [items]="_projectService.allProjects | orderBy : 'Name'" required
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.ProjectId"
                                    (ngModelChange)="LoadRegisteredSystemDetails(NodeRegistrationForm);">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(ProjectId.invalid && ProjectId.touched)||(ProjectId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                {{this._nodeRegService.selectedNodeRegistration.ProjectId}}
                            </div>


                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Date</label>


                                <input class="section3" placeholder="Contract Date"
                                    style="color: black; text-align: left;" autocomplete="off" #dp="bsDatepicker"
                                    bsDatepicker [ngModel]="this._nodeRegService.currentStringDateToBind"
                                    name="currentStringDate" (bsValueChange)="updateMyDate($event)"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">


                            </div>
                            <div class="form-group">
                                <label>Address<span style="color: red;">*</span></label>

                                <input class="form-control" name="Addr1" #Addr1="ngModel" autocomplete="off"
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.Addr1" placeholder=" Addr1"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr1.invalid && Addr1.touched)||(Addr1.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <label><span style="color: red;">*</span></label>
                                <input class="form-control" name="Addr2" #Addr2="ngModel" autocomplete="off"
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.Addr2" placeholder=" Addr2"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr2.invalid && Addr2.touched)||(Addr2.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <label><span style="color: red;">*</span></label>
                                <input class="form-control" name="Addr3" #Addr3="ngModel" autocomplete="off"
                                    [(ngModel)]="_nodeRegService.selectedNodeRegistration.Addr3" placeholder=" Addr3"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr3.invalid && Addr3.touched)||(Addr3.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row" *ngIf="this._nodeRegService.allNodeRegistrationDTLs.length>0">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>

                                    <thead>
                                        <tr>
                                            <th style="width: 35px;">SNo</th>
                                            <th>System</th>
                                            <th>RegDate</th>
                                            <th>Module</th>
                                            <th>Version</th>
                                            <th>Expiry</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        <tr
                                            *ngFor="let dtl of  this._nodeRegService.allNodeRegistrationDTLs; let i = index">
                                            <td style="width: 35px;"> {{i+1}} </td>
                                            <td> {{dtl.SystemName}} </td>
                                            <td> {{dtl.RegDate|date}} </td>
                                            <td> {{dtl.ModuleName}} </td>
                                            <td> {{dtl.ModuleVersion}} </td>
                                            <td> {{dtl.Expiry|date}} </td>
                                            <td> {{dtl.status}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" [disabled]="this.isSubmit" type="submit"
                        class=" sim-button button1">
                        Submit
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class=" sim-button button2"
                        (click)="resetForm(NodeRegistrationForm)">
                        Reset
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class=" sim-button button3"
                        (click)="backList()">Exit</button>
                </div>
            </div>
        </form>
    </div>
</div>