<div class="container-fluid" *ngIf="_dealrService.formHideShow!=0">
    <!-- <div class="row flex-body">
        <div class="col-md-11 col-md-offset-1"> -->

    <br>
    <div id="form-wrap">
        <h2 class="heading_all">DEALERS</h2>
        <form #dealerForm="ngForm" (ngSubmit)="onSubmit(dealerForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_dealrService.selectedDealer.Id">
            <div class="col-md-12 FormSec">
                <!-- <div class="row FormHeader sim-button_head">
                        <h3>DEALER </h3>
                    </div> -->

                <!-- <div class="FormBox"> -->
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#sec1">Dealer</a>
                    </li>
                    <li class="nav-item" *ngIf="!_dealrService.formHidePassword">
                        <a class="nav-link" data-toggle="tab" href="#sec2">Login Details</a>
                    </li>
                </ul><br>
                <div class="tab-content">
                    <div id="sec1" class="container tab-pane active">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group ">
                                    <label>Country<span style="color: red;">*</span></label>

                                    <select class="form-control" name="CountryId" #CountryId="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.CountryId" required>
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option *ngFor="let prf of _countryService.allCountries | orderBy : 'Name'"
                                            [ngValue]="prf.Id">
                                            {{ prf.Name }}
                                        </option>
                                    </select>
                                    <div class="validation-error"
                                        *ngIf="(CountryId.invalid && CountryId.touched)||(CountryId.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group ">
                                    <label>Delaer<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="DealerName"
                                        #DealerName="ngModel" [(ngModel)]="_dealrService.selectedDealer.DealerName"
                                        placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(DealerName.invalid && DealerName.touched)||(DealerName.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group">
                                    <label>Dealer Code<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="DealerCode"
                                        #DealerCode="ngModel" [(ngModel)]="_dealrService.selectedDealer.DealerCode"
                                        placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(DealerCode.invalid && DealerCode.touched)||(DealerCode.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>


                                </div>
                                <div class="form-group ">
                                    <label>Email<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="DealerEmail"
                                        #DealerEmail="ngModel" [(ngModel)]="_dealrService.selectedDealer.DealerEmail"
                                        placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(DealerEmail.invalid && DealerEmail.touched)||(DealerEmail.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>


                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group ">
                                    <label>Address<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="Addr1" #Addr1="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.Addr1" placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(Addr1.invalid && Addr1.touched)||(Addr1.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group ">
                                    <label><span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="Addr2" #Addr2="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.Addr2" placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(Addr2.invalid && Addr2.touched)||(Addr2.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group ">
                                    <label><span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="Addr3" #Addr3="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.Addr3" placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(Addr3.invalid && Addr3.touched)||(Addr3.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group ">
                                    <label>Phone<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="DealerPhone"
                                        #DealerPhone="ngModel" [(ngModel)]="_dealrService.selectedDealer.DealerPhone"
                                        placeholder="" required ng-pattern="/^[a-zA-Z0-9]*$/">
                                    <div class="validation-error"
                                        *ngIf="(DealerPhone.invalid && DealerPhone.touched)||(DealerPhone.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                            </div>
                            <div class="col-md-4">

                                <div class="form-group ">
                                    <label>Fax<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="DealerFax" #DealerFax="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.DealerFax" placeholder="" required ng-pattern="/^[a-zA-Z0-9]*$/">
                                    <div class="validation-error"
                                        *ngIf="(DealerFax.invalid && DealerFax.touched)||(DealerFax.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group ">
                                    <label>Contact Person<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="ContactPerson"
                                        #ContactPerson="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.ContactPerson" placeholder=""
                                        required>
                                    <div class="validation-error"
                                        *ngIf="(ContactPerson.invalid && ContactPerson.touched)||(ContactPerson.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group">
                                    <label>Mobile<span style="color: red;">*</span></label>
 
                                    <input class="form-control" autocomplete="off" name="ContactPhone" ng-pattern="/^[a-zA-Z0-9]*$/"
                                        #ContactPhone="ngModel" [(ngModel)]="_dealrService.selectedDealer.ContactPhone"
                                        placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(ContactPhone.invalid && ContactPhone.touched)||(ContactPhone.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group">
                                    <label>URL<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="URL" #URL="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.URL" placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(URL.invalid && URL.touched)||(URL.invalid && this.FormValidation)">This
                                        Field is
                                        Required.</div>


                                </div>
                                <div class="form-group ">
                                    <label>Contact Email<span style="color: red;">*</span></label>

                                    <input class="form-control" autocomplete="off" name="ContactEmail"
                                        #ContactEmail="ngModel" [(ngModel)]="_dealrService.selectedDealer.ContactEmail"
                                        placeholder="" required>
                                    <div class="validation-error"
                                        *ngIf="(ContactEmail.invalid && ContactEmail.touched)||(ContactEmail.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                            </div>


                        </div>
                    </div>
                    <div id="sec2" class="container tab-pane fade">
                        <br>
                        <div class="row">
                            <div class="col-md-6">

                                <div class="form-group" *ngIf="!_dealrService.formHidePassword">
                                    <label>Username<span style="color: red;">*</span></label>

                                    <input class="form-control" name="Username" #Username="ngModel" autocomplete="off"
                                        [(ngModel)]="_dealrService.selectedDealer.Username" placeholder=" Username"
                                        required>
                                    <div class="validation-error"
                                        *ngIf="(Username.invalid && Username.touched)||(Username.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group" *ngIf="!_dealrService.formHidePassword">
                                    <label>Password<span style="color: red;">*</span></label>

                                    <input type="password" class="form-control" name="Password" autocomplete="off"
                                        #Password="ngModel" [(ngModel)]="_dealrService.selectedDealer.Password"
                                        placeholder=" Password" required>
                                    <div class="validation-error"
                                        *ngIf="(Password.invalid && Password.touched)||(Password.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>
                                <div class="form-group" *ngIf="!_dealrService.formHidePassword">
                                    <label>Confirm Password<span style="color: red;">*</span></label>

                                    <input type="password" class="form-control" name="ConfirmPassword"
                                        autocomplete="off" #ConfirmPassword="ngModel"
                                        [(ngModel)]="_dealrService.selectedDealer.ConfirmPassword"
                                        placeholder=" ConfirmPassword" required>
                                    <div class="validation-error"
                                        *ngIf="(ConfirmPassword.invalid && ConfirmPassword.touched)||(ConfirmPassword.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>

                                </div>

                            </div>
                            <div class="col-md-6" *ngIf="!_dealrService.formHidePassword">
                                <div class="form-group">
                                    <label>Upload Logo</label>

                                    <input type="file" accept="image/*" name="LogoURL"
                                        (change)="handleFileInput($event.target.files)"> <br>


                                </div>
                                <div class="form-group">
                                    <label></label>

                                    <div class="uploadImg">
                                        <img [src]="_dealrService.imageUrl" height="150" width="190">
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="btnactns col-md-6 offset-3">
                    <button type="submit" [disabled]="this.isSubmit" class="sim-button button1">Submit</button>



                    <button type="button" class="sim-button button2" (click)="resetForm(dealerForm)">
                        Reset
                    </button>
                    <button type="button" class="sim-button button3" (click)="backList()">Exit</button>
                </div>
                <!-- </div> -->
            </div>
        </form>
    </div>
    <!-- </div> -->
</div>
<br>