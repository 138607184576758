import { Pipe, PipeTransform } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';

@Pipe({
  name: 'gridOperations'
})
export class GridOperationsPipe implements PipeTransform {
  constructor(private _CommonService: CommonService) {
  }

  transform(items: any[], pageIndex: any, itemFilter: any = null, sortParams: any): any[] {   
    if (items) {
      //Filter
      if (itemFilter) {
        let keyCount = Object.keys(itemFilter).length;
        let keyName, KeyValue;
        for (let i = 0; i < keyCount; i++) {
          keyName = Object.keys(itemFilter)[i];
          KeyValue = itemFilter[keyName];
          if (KeyValue) {
            items = items.filter(((data => (<string>data[keyName].toString()).toLowerCase().indexOf((KeyValue.toString()).toLowerCase()) > -1)));
          }
        }
      }
      //Sortting 
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9.]/g;
      if (sortParams.Column) {
        var array = items.sort(function (a, b) {

          a = (<string>a[sortParams.Column].toString()).toLowerCase();
          b = (<string>b[sortParams.Column].toString()).toLowerCase();
          var aA = a.replace(reA, "");
          var bA = b.replace(reA, "");
          if (aA === bA) {
            var aN = parseInt(a.replace(reN, ""), 10);
            var bN = parseInt(b.replace(reN, ""), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
          } else {
            return aA > bA ? 1 : -1;
          }
          // return a[sortParams.Column].toLowerCase() > b[sortParams.Column].toLowerCase() ? 1 : -1;
        });
        items = array;
        if (sortParams.Dir) {
          items = array.reverse();
        }
      }
      //Paging
      if (pageIndex != null) {
        this._CommonService.totalPageCount = Math.ceil(items.length / this._CommonService.itemCountPerPage);
        if (this._CommonService.totalPageCount < this._CommonService.pageIndex) {
          this._CommonService.pageIndex = 1;
        }
        this._CommonService.filterdItemCount = items.length; 
        return items.slice((this._CommonService.pageIndex * this._CommonService.itemCountPerPage) - (this._CommonService.itemCountPerPage), this._CommonService.pageIndex * this._CommonService.itemCountPerPage);
      }
      else {
        this._CommonService.filterdItemCount = items.length; 
        return items;
      }
    }
  }
}
