import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';
import { CompanyregistrationService } from 'src/app/Services/companyregistration.service';

@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.css']
})
export class CompanyRegistrationComponent implements OnInit {

  constructor(
    public _CompanyregistrationService:CompanyregistrationService,
    private toastr: ToastrService,
    private _common: CommonService,
  ) { }
  FormValidation:boolean;
  ngOnInit(): void {
    this.FormValidation=false;
    this._CompanyregistrationService.clearData();
    this._CompanyregistrationService.getCompanyInfo().subscribe(data=>{
      this._CompanyregistrationService.companyData=data;
    });
  }
  resetForm(form?: NgForm) {
   // this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
      this._CompanyregistrationService.clearData();
  }
  onSubmit(form: NgForm) {
    
    this.FormValidation = true;
    if(form.valid){
    this._common.isLoading=true;
    this._CompanyregistrationService.submitCompanyReg(this._CompanyregistrationService.companyData).subscribe(data=>{
      console.log(data);
      if (data.Status == 0) {
        this._common.isLoading=false;
        this.toastr.success(data.Message, "Company Registration");
        ///this.resetForm(form); 
      }
      else {
        this.toastr.warning(data.Message, "Company Registration")

      }
    })
  }
}
}
