<div class="container-fluid">
    <h2 class="heading_all">Work Status</h2>
    <br>
    <div id="form-wrap">
        <form #WorkAllocationStatusForm="ngForm" (ngSubmit)="onSubmit(WorkAllocationStatusForm)">
            <div class="form-group ">
                <div class="row">
                    <label class="col-md-2  form-control-label" for="select">Dealer</label>
                    <div class="col-md-4">
                        <select class="form-control" name="DealerId" #DealerId="ngModel"
                            [(ngModel)]="this._workAllocationStatusService.DealerId" (change)="getPendingWorks()"
                            required>
                            <option [ngValue]="null" disabled>Choose Dealer</option>
                            <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                [ngValue]="prf.Id">
                                {{ prf.DealerName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width:50px;">SlNo</th>
                                    <th class="hidden-xs" style="width: 88px;">
                                        <div class="AtjIsPoint" (click)="sort('RefNo')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Ref.No</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='RefNo'"></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='RefNo'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtRefNoFilter="ngModel" id="RefNoFilter"
                                                name="RefNoFilter"
                                                [(ngModel)]="_workAllocationStatusService.RefNoFilter"
                                                placeholder="RefNo">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 85px;">
                                        <div class="AtjIsPoint" (click)="sort('RegDate')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Reg.Date</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='RegDate'"></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='RegDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtRegDateFilter="ngModel" id="RegDateFilter"
                                                name="RegDateFilter"
                                                [(ngModel)]="_workAllocationStatusService.RegDateFilter"
                                                placeholder="RegDate">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 85px;">
                                        <div class="AtjIsPoint" (click)="sort('RepDate')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Rep.Date</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='RepDate' "></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='RepDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtRepDateFilter="ngModel" id="RepDateFilter"
                                                name="RepDateFilter"
                                                [(ngModel)]="_workAllocationStatusService.RepDateFilter"
                                                placeholder="RepDate">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 300px;">
                                        <div class="AtjIsPoint" (click)="sort('CustomerName')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Customer</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='CustomerName'"></i>
                                                <div
                                                    *ngIf="_workAllocationStatusService.orderByColName=='CustomerName'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtCustomerNameFilter="ngModel" id="CustomerFilter"
                                                name="CustomerFilter"
                                                [(ngModel)]="_workAllocationStatusService.CustomerFilter"
                                                placeholder="Customer Name">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width:85px;">
                                        <div class="AtjIsPoint" (click)="sort('ImpDate')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">IMP.Date</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='ImpDate'"></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='ImpDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtImpDateFilter="ngModel" id="IMPDateFilter"
                                                name="IMPDateFilter"
                                                [(ngModel)]="_workAllocationStatusService.IMPDateFilter"
                                                placeholder="Imp Date">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 90px;">
                                        <div class="AtjIsPoint" (click)="sort('AMC')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">AMC</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='AMC'"></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='AMC'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtAMCFilter="ngModel"
                                                [(ngModel)]="_workAllocationStatusService.AMCFilter" id="AMCFilter"
                                                name="AMCFilter" placeholder="AMC">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 88px;">
                                        <div class="AtjIsPoint" (click)="sort('PriorityId')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Priority</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='PriorityId'"></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='PriorityId'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtPriorityIdFilter="ngModel"
                                                [(ngModel)]="_workAllocationStatusService.PriorityFilter"
                                                id="PriorityFilter" name="PriorityFilter" placeholder="Priority">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 85px;">
                                        <div class="AtjIsPoint" (click)="sort('ExpDate')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Exp.Date</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='ExpDate'"></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='ExpDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtExpDateFilter="ngModel"
                                                [(ngModel)]="_workAllocationStatusService.EXPDateFilter"
                                                id="EXPDateFilter" name="EXPDateFilter" placeholder="ExpDate">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 155px;">
                                        <div class="AtjIsPoint" (click)="sort('PlannedDeliveryDate')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Planned Delivery
                                                Date</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='PlannedDeliveryDate'"></i>
                                                <div
                                                    *ngIf="_workAllocationStatusService.orderByColName=='PlannedDeliveryDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtPlannedDeliveryDateFilter="ngModel"
                                                [(ngModel)]="_workAllocationStatusService.PlanDateFilter"
                                                id="PlanDateFilter" name="PlanDateFilter"
                                                placeholder="Planned Delivery Date">
                                        </div>

                                    </th>
                                    <th class="hidden-xs" style="width: 88px;">
                                        <div class="AtjIsPoint" (click)="sort('AssignedBy')">
                                            <span *ngIf="!_workAllocationStatusService.isFiltering">Assigned</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_workAllocationStatusService.orderByColName!='AssignedBy'"></i>
                                                <div *ngIf="_workAllocationStatusService.orderByColName=='AssignedBy'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_workAllocationStatusService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_workAllocationStatusService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_workAllocationStatusService.isFiltering">
                                            <input type="text" #txtAssignedByFilter="ngModel"
                                                [(ngModel)]="_workAllocationStatusService.AssignedByFilter"
                                                id="AssignedByFilter" name="AssignedByFilter" placeholder="AssignedBy">
                                        </div>
                                    </th>
                                    <th style="width:60px;"><em></em> View
                                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                            <i *ngIf="!_workAllocationStatusService.isFiltering"
                                                class="fas fa-filter"></i>
                                            <i *ngIf="_workAllocationStatusService.isFiltering"
                                                class="fas fa-times"></i>
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr id="ex"
                                    *ngFor="let obj of this._workAllocationStatusService.WorkStatusList | gridOperations  :null: 
                                    {RefNo:_workAllocationStatusService.RefNoFilter,RegDate:_workAllocationStatusService.RegDateFilter,
                                    RepDate:_workAllocationStatusService.RepDateFilter,CustomerName:_workAllocationStatusService.CustomerFilter,
                                    ImpDate:_workAllocationStatusService.IMPDateFilter,AMC:_workAllocationStatusService.AMCFilter,
                                    PriorityId:_workAllocationStatusService.PriorityFilter,ExpDate:_workAllocationStatusService.EXPDateFilter,
                                    PlannedDeliveryDate:_workAllocationStatusService.PlanDateFilter,AssignedBy:_workAllocationStatusService.AssignedByFilter}:
                                    {Column:_workAllocationStatusService.orderByColName,Dir:_workAllocationStatusService.orderByDir};let i=index"
                                    [ngClass]="{'blackBg':obj.AssignedBy=='Not Assigned','redBg':obj.AvgStatus=='NOTSTARTED','yellowBg': obj.AvgStatus=='WIP'}">

                                    <td style="width: 50px;"><span *ngIf="selectedHeaderID== obj.WorkSortHdrId"> <i
                                                class='fas fa-chevron-right' style='font-size:20px'></i>
                                        </span> {{i+1}} </td>
                                    <td style="width: 88px;">{{obj.RefNo}}</td>
                                    <td style="width: 85px;">{{obj.RegDate }}</td>
                                    <td style="width: 85px;">{{obj.RepDate }}</td>
                                    <td style="width: 300px;"> {{obj.CustomerName}} </td>
                                    <td style="width: 85px;">{{obj.ImpDate }}</td>
                                    <td style="width: 90px;">{{obj.AMC}}</td>
                                    <td style="width: 88px;">
                                        <label class="form-control-label" *ngFor="let pri of  PriorityList">
                                            <p *ngIf="obj.PriorityId==0">Select</p>
                                            <p *ngIf="obj.PriorityId==pri.Id">
                                                {{pri.Name}}
                                            </p>
                                        </label>
                                    </td>
                                    <td style="width: 85px;">{{obj.ExpDate}}</td>
                                    <td style="width: 155px;">{{obj.PlannedDeliveryDate }}</td>
                                    <td style="width: 88px;">{{obj.AssignedBy}}</td>
                                    <td style="width:60px;">
                                        <i class="fas fa-eye" style="font-size:24px"
                                            (click)="getWorkAllocStatusDet(obj)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="this.selectedHeaderID>0">
                <div class="form-group">
                    <label>Remarks</label>

                    <textarea style="min-height: 85px;" class="form-control" name="Remark" #Remark="ngModel"
                        autocomplete="off" [(ngModel)]="_workAllocationStatusService.SelectedWorkStatusHdr.Remark"
                        placeholder=" Remarks" required></textarea>
                    <!-- <div class="validation-error" *ngIf="Remark.invalid && Remark.touched">
                        This
                        Field is Required.
                    </div> -->
                </div>
            </div>
            <br>
            <div class="row">
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <!-- <th scope="col">*</th> -->
                                    <th scope="col">SlNo</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Module</th>
                                    <th scope="col">Particulars</th>
                                    <th scope="col">Dsecription</th>
                                    <th scope="col">Exp.St.Date</th>
                                    <th scope="col">Exp.Comp.Date</th>
                                    <th scope="col">Stage</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Test Result</th>
                                    <th>VIEW</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr *ngFor="let obj of  this._workAllocationStatusService.SelectedWorkStatusDet ;let i=index"
                                    [ngClass]="{'redBg':obj.Status==0,'yellowBg':obj.Status==1 || obj.Status==5 ,'blackBg':obj.Status==0,'greenBg': obj.Status==6 && obj.TestResult=='Succeed'  && obj.Stage=='8' ,'DeliverdRow':obj.Stage=='9'}">
                                    <!-- <td>
                                        <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                            #CheckStatus="ngModel" [(ngModel)]="obj.CheckStatus"
                                            name="{{obj.CheckStatus}}" [checked]="obj.CheckStatus"
                                            value="{{obj.CheckStatus}}">
                                    </td> -->

                                    <td scope="row">{{i+1}} </td>
                                    <td>
                                        <select name="ActionType" (ngModelChange)="changeActionID(obj,$event)"
                                            [disabled]="obj.Stage!=5" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="obj.ActionID" #ActionType="ngModel"
                                            class="form-control combo_body">

                                            <option [value]="0" disabled>Select</option>
                                            <option *ngFor="let obj of  ActionList" [ngValue]="obj.Id"> {{obj.Name}}
                                            </option>
                                        </select>
                                        <!-- <select name="ActionType" (ngModelChange)="changeActionID(obj,$event)"
                                    [disabled]="obj.Stage!=5" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="obj.ActionID" #ActionType="ngModel" class="form-control combo_body">
                                     
                                    <option [value]="0" disabled>Select</option>
                                    <option *ngFor="let obj of  ActionList" [ngValue]="obj.Id"> {{obj.Name}}
                                    </option>
                                </select> -->
                                    </td>
                                    <td>
                                        <span class="form-control-label"
                                            *ngFor="let field of  this._modulService.allModules">
                                            <p *ngIf="obj.ModuleId==field.Id">
                                                {{field.ModuleName}}
                                            </p>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="form-control-label"
                                            *ngFor="let field of  this._ParticularmasterService.allparticular | orderBy : 'Particulars'">
                                            <p *ngIf="field.ID==obj.ParticularId">
                                                {{field.Particulars}}
                                            </p>
                                        </span>
                                    </td>
                                    <td (click)="onUpdate(obj)" style="cursor: pointer;text-decoration: underline;">
                                        {{obj.Description}}</td>
                                    <td>{{obj.ExpStDate|date}}</td>
                                    <td>{{obj.ExpCompDate|date}}</td>
                                    <td>
                                        {{obj.StageText}}

                                    </td>
                                    <td>
                                        {{obj.StatusText}}
                                    </td>
                                    <td>
                                        {{obj.TestResult}}
                                    </td>
                                    <td>
                                        <i class="fas fa-pause-circle" (click)="getHoldingDetails(obj)"
                                            style="font-size:24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="this.selectedHeaderID>0">

                <div class="form-group" style="width: 330px;margin-right: 10px;">
                    <label>Charge</label>
                    <input class="form-control" name="Charge" #Charge="ngModel" autocomplete="off"
                        [(ngModel)]="_workAllocationStatusService.SelectedWorkStatusHdr.Charge" placeholder="Charge"
                        required>
                </div>

                <div class="form-group" style="width: 330px;margin-right: 10px;">
                    <label>Planned Delivery Date<span style="color: red;">*</span></label>

                    <input class="form-control" placeholder="Planned Delivery Date" name="PlannedDeliveryDate"
                        #PlannedDeliveryDate="ngModel"
                        [(ngModel)]="_workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate"
                        style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required>
                    <div class="validation-error"
                        *ngIf="(PlannedDeliveryDate.invalid && PlannedDeliveryDate.touched)||(PlannedDeliveryDate.invalid && this.FormValidation)">
                        This Field is
                        Required.</div>

                </div>
                <button type="submit" class=" sim-button button1" [disabled]="this.isSubmit"
                    style="margin-top: 30px;">Submit</button>
            </div>

            <div *ngIf="this.selectedHeaderID>0">
                <div class="row">
                    <span style="width: 25%;" class="btn btn-default btn-file">Browse
                        <input type="file" name="customFile" #customFile id="customFile" multiple
                            (change)="handleFileInput($event)">
                    </span>
                </div>
                <br>
                <div class="row" style="width: 1500px;"
                    *ngIf="this.FileList.length>0 || this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentsList.length>0">
                    <div class="col-md-6">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">*</th>
                                            <th scope="col">FileName</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let obj of  this.FileList; let i=index">
                                            <th style="background:white;color:black;" scope="row">></th>
                                            <td (click)="downloadFileUrl(obj.name)"
                                                style="cursor: pointer; text-decoration: underline;"> {{obj.name}} </td>
                                            <td><button type="button" (click)="RemoveImage(obj)">-</button>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="let obj of this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentsList; let i=index">
                                            <th style="background:white;color:black;" scope="row">></th>
                                            <td (click)="downloadFileUrl(obj.FileName)"
                                                style="cursor: pointer; text-decoration: underline;"> {{obj.FileName}}
                                            </td>
                                            <td><button type="button" (click)="RemovePreviousImage(obj)">-</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </form>
    </div>
</div>

<!-- HOLD PopUp   -->
<form *ngIf="displayHoldpopUp==1">
    <div class="popup-container">

        <input type="checkbox" id="hold-cnfirm" checked>
        <div class="popup HoldingReason" (click)="closeHoldPopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p> <span style="text-decoration: underline;cursor: pointer;"
                            [ngClass]="{'activePopup':holdOrReject==0}" (click)="holdOrReject=0;"> Holding Reason
                        </span> &nbsp;/
                        <span style="text-decoration: underline;cursor: pointer;"
                            [ngClass]="{'activePopup':holdOrReject==1}" (click)="holdOrReject=1;">Reject Reason</span>
                    </p>
                    <!-- <i class="fas fa-times" (click)="closeHoldPopUp()"></i>
                          -->
                    <label for="test-results"> <i class="fas fa-times" (click)="closeHoldPopUp()"></i>
                    </label>
                </div>
                <div *ngIf="holdOrReject==0">
                    <div class="row HoldingReason">
                        <div class="col-md-12">
                            <div>

                                <div class="row">
                                    <div class="col-5">

                                        <div class="tWrap">
                                            <div class="tWrap__head">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>SNo</th>
                                                            <th>Reason</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>

                                            <div class="tWrap__body">
                                                <table>
                                                    <tbody>
                                                        <tr *ngFor="let obj of  HoldDetailsList;let i =index">
                                                            <td>
                                                                {{i+1}}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    *ngFor="let value of workallotmentHoldReasonsList">
                                                                    <span *ngIf="value.Id==obj.Reasons">
                                                                        {{value.Name}}</span>
                                                                </span>

                                                            </td>
                                                            <td>
                                                                <span (click)="selectHoldingReason(obj)"
                                                                    style="cursor: pointer; text-decoration: underline;">
                                                                    Select
                                                                </span>
                                                            </td>


                                                        </tr>
                                                        <tr *ngIf="HoldDetailsList?.length<=0">
                                                            <td colspan="3">
                                                                No Records To Display.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="row">

                                            <label class="col-3 form-control-label">Reason <span
                                                    style="color: red;">*</span></label>
                                            <div class="col-md-9">
                                                <ng-select name="HoldingReasonAction" id="HoldingReasonAction"
                                                    #HoldingReasonAction="ngModel" bindLabel="Name" bindValue="Id"
                                                    [items]="workallotmentHoldReasonsList"
                                                    [(ngModel)]="selectedHoldDetails.Reasons" placeholder="Select">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="selectedHoldDetails.Reasons==4">

                                            <label class="col-3 form-control-label">
                                                Clarification <span style="color: red;">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <ng-select name="HoldingMeetingWith" id="HoldingMeetingWith"
                                                    bindLabel="EmpName" bindValue="Id" [items]="HolePopUpEmpList"
                                                    #HoldingMeetingWith="ngModel"
                                                    [(ngModel)]="selectedHoldDetails.ClarificationFrom"
                                                    placeholder="Select">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="selectedHoldDetails.Reasons==6">

                                            <label class="col-3 form-control-label">
                                                Service Charge <span style="color: red;">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <input type="text" style="width: 100%;" name="scharge" id="scharge"
                                                    autocomplete="off" #scharge="ngModel"
                                                    [(ngModel)]="selectedHoldDetails.ServiceCharge">

                                            </div>
                                        </div>
                                        <div class="row">

                                            <label class="col-3 form-control-label">Reason <span
                                                    style="color: red;">*</span></label>
                                            <div class="col-md-9">
                                                <textarea type="text" name="HoldingReason" id="HoldingReason"
                                                    #HoldingReason="ngModel" [(ngModel)]="selectedHoldDetails.Reason"
                                                    required></textarea>

                                                <div class="validation-error"
                                                    *ngIf="HoldingReason.invalid && HoldingReason.touched">
                                                    This
                                                    Field is Required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <label class="col-3 form-control-label">Remark </label>
                                            <div class="col-md-9">
                                                <textarea type="text" name="HoldingRemark" id="HoldingRemark"
                                                    #HoldingRemark="ngModel"
                                                    [(ngModel)]="selectedHoldDetails.Remark"></textarea>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="!DisableHoldUpdateButton">
                                            <div class="col-md-3">
                                                <button style="width: 99%;" type="button" class="sim-button button2"
                                                    (click)="ClearHoldReasonPopUp()">Clear</button>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="button" (click)="updateHoldReason()"
                                                    [disabled]="SubmitButtonClickCheck" class="sim-button button1"
                                                    style="width: 100%;">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table></table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="holdOrReject==1" style="margin-bottom: 20px;">
                    <textarea name="reason" id="reason" cols="30" rows="5" placeholder="Reason." #reason="ngModel"
                        disabled [ngModel]="RejectReason"></textarea>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- Reject Reason  -->
<form *ngIf="displayHoldpopUp==2">
    <div class="popup-container">

        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closeHoldPopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Reject Reason </p> <label for="test-results"> <i class="fas fa-times"
                            (click)="closeHoldPopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12" style="text-align:center">

                            <textarea name="reason" id="reason" cols="30" rows="5" placeholder="Enter your reason."
                                #reason="ngModel" [(ngModel)]="RejectReason"></textarea>
                            <button class="sim-button button1" (click)="UpdateRejectReason()">Submit</button>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- *ngIf=" this._workAllocationStatusService.BtmVisible " -->
<!-- <form >
    <div class="popup-container">
        <div class="popup">
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Description</p> <label for="test-results"> </label>
                </div>
                <div style="margin-top: 25px;">
                    <div class="row">
                        <div class="col-md-12">
                            <textarea class="form-control" autofocus name="Description" #Description="ngModel"
                                style="height: 350px;" id="Description"
                                [(ngModel)]="this._CustomerrequirementService.DescriptionEdit" autocomplete="off"
                                placeholder=" Description" required>
                                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form> -->


<form *ngIf=" this._workAllocationStatusService.BtmVisible ">
    <div class="popup-container">

        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closeDescriptionPopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Description </p> <label for="test-results"> <i class="fas fa-times"
                            (click)="closeDescriptionPopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12" style="text-align:center">

                            <textarea class="form-control" autofocus name="Description" #Description="ngModel"
                                style="height: 350px;" id="Description"
                                [(ngModel)]="this._CustomerrequirementService.DescriptionEdit" autocomplete="off"
                                placeholder="Enter Your Description" required>
                                        </textarea>
                        </div>
                        <div class="col-md-12" style="text-align:center">

                            <button type="button" (click)="updateDescription();" class=" sim-button button1"
                                style="margin-top: 30px;">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>