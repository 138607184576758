import { Component, EventEmitter, HostListener, forwardRef, Input, OnInit, Output } from '@angular/core';
import { CustomerService } from 'src/app/Services/customer.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { ProjectAllotmentService } from 'src/app/Services/project-allotment.service';
import * as $ from 'jquery';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-custom',
  templateUrl: './project-allotment.component.html',
  styleUrls: ['./project-allotment.component.css'],

  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProjectAllotmentComponent),
    multi: true
  }]
})
export class ProjectAllotmentComponent implements ControlValueAccessor {


  @Input() dropdownList: [];
  @Input() DropDownName: 'Name';
  @Input() DropDownvalue: 'Id'

  selectedValue: string
  displayDropDown: boolean


  FullList: any[]
  FilteredList: any[]


  constructor(
    public _EmployeeService: EmployeeService,
    public _ProjectAllotmentService: ProjectAllotmentService,
    public _CustomerService: CustomerService,
  ) { }

datevalue:Date;
  ngOnInit(): void {
    this.datevalue=new Date();
    this.selectedValue = ''
    this.displayDropDown = false
    this.FullList = []
    // this.dropdownList.forEach(obj => {
    //   this.FullList.push({
    //     'Id': obj[this.DropDownvalue],
    //     'Name': obj[this.DropDownName]
    //   })
    // })
    this.FilteredList = this.FullList
  }

  filterFunction() {
    if (this.selectedValue == '')
      this.FilteredList = this.FullList
    else {
      this.FilteredList = []
      this.FilteredList = this.FullList.filter(((data => (<string>data[this.DropDownName].toString()).toLowerCase().indexOf((this.selectedValue.toString()).toLowerCase()) > -1)));
    }
  }

  DropdownShow() {
    this.displayDropDown = true
  }

  setValue(obj: any) {
    this.selectedValue = obj.Name
    this.value = obj.Id
    this.displayDropDown = false
  }

  onChange: Function;
  onTouched: Function;
  private _value: number = 0;

  get value() {
    return this._value;
  }

  set value(value: number) {
    if (value != null) {
      this._value = value;
      if (this.onChange) {
        this.onChange(value);
      }
    }
  }

  @HostListener('click') click() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  @HostListener('document:click', ['$event'])
  hide() {
    $(document).click((event) => {
      if (!$(event.target).closest('#myDiv').length) {
        this.displayDropDown = false;
      }
    });
  }

  writeValue(item: any): void {
    if (item != null) {
      this.value = item;
      if (this.FullList.filter(obj => obj.Id == item).length > 0)
        this.selectedValue = this.FullList.filter(obj => obj.Id == item)[0]['Name']
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
