import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { CloudCharge,CloudDueList,CSCancellation } from '../Models/CloudCharge';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { Response } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class CloudChargeService {
  selectedCloudCharge: CloudCharge;
  allDues: CloudDueList[];
 
  pageIndex:number = 0;
  isFiltering:boolean = false;
  DateFilter:string = '';
  DealerFilter:string = '';
  CustomerFilter:string = '';
  BranchFilter:string = '';
  RegNoFilter:string = '';
  TotalRentFilter:string = '';

  formHideShow = 0;
  selectedCSCancellation:CSCancellation;
  allCSCancellation:CSCancellation[]=[];

  orderByDir: boolean = false;
  orderByColName: string = '';

  constructor(
     private http: HttpClient,
     public _GlobalErrorHandlerService: GlobalErrorHandlerService
     ) { }

     ClearFilter() {
      this.isFiltering = false;
      this.DateFilter= '';
      this.DealerFilter= '';
      this.CustomerFilter= '';
      this.BranchFilter= '';
      this.RegNoFilter= '';
      this.TotalRentFilter= '';
    }

    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
  
    getPendingCloudCharge(WithIn: number,DealerId:number,CustomerId:number): Observable<CloudDueList[]> {
      let params = new HttpParams();
      params = params.append('WithIn', WithIn.toString());
      params = params.append('Date',this.convert(this.selectedCloudCharge.Date));
      params = params.append('DealerId',DealerId.toString());
      params = params.append('CustomerId',CustomerId.toString());
      const headers = new HttpHeaders().set('content-type', 'application/json');
      return this.http.get<CloudDueList[]>(ROOT_URL + 'CloudCharge/GetDueData', {
        headers, params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }

    getCloudCharge(VNo: number): Observable<any> {
      const headers = new HttpHeaders().set('content-type', 'application/json');
      let params = new HttpParams();
      params = params.append('VNo', VNo.toString());
      return this.http.get<CloudCharge>(ROOT_URL + 'CloudCharge/GetAllCloudCharges', {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
  
    SaveItem(Values: CloudCharge): Observable<any> {
      var body = {
          Id: Values.Id
        , SoftwareRegId: Values.SoftwareRegId
        , VNo: Values.VNo
        , Date:this.convert(Values.Date)
        , DealerId: Values.DealerId
        , CustomerId: Values.CustomerId
        , FreeFor:Values.FreeFor
        , MonthlyRent:Values.MonthlyRent
        , TotalRent: Values.TotalRent
        , AffectedFrom:this.convert(Values.AffectedFrom)
        , Duration: Values.Duration
        , Expiry:this.convert(Values.Expiry)
        , Remark: Values.Remark
      }
      const headers = new HttpHeaders().set('content-type', 'application/json');
      return this.http.post<Response>(ROOT_URL + "CloudCharge/InsUpdDelCloudCharge", body, {
        headers
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }

    deleteCloudCharge()
    {
      let params = new HttpParams();
      params = params.append('CloudId', this.selectedCloudCharge.Id.toString());
      params = params.append('SoftwareRegId', this.selectedCloudCharge.SoftwareRegId.toString());
      params = params.append('Vno', this.selectedCloudCharge.VNo.toString());
      const headers = new HttpHeaders().set('content-type', 'application/json');
      return this.http.delete<Response>(ROOT_URL + "CloudCharge/DeleteCloudCharge", {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
      /* CsCancellation section */
    CsCancellationTrans(data:CSCancellation,Vno:number)
    {
      var body={
        Id:data.Id==undefined?0:data.Id
        ,VNo:Vno
        ,Date:this.convert(data.Date)
        ,DealerId:data.DealerId
        ,CustomerId:data.CustomerId
        ,AffectedFrom:this.convert(data.AffectedFrom)
        ,ReasonId:data.ReasonId
        ,Remark:data.Remark
      }
      let params= new HttpParams();
      params=params.append('TransMode','SAVE')
      const headers = new HttpHeaders().set('content-type', 'application/json');
      return this.http.post<Response>(ROOT_URL + "CloudCharge/CsCancellation_Trans", body, {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }

    getCsCancellation()
    {
      const headers = new HttpHeaders().set('content-type', 'application/json');
      let params = new HttpParams();
      return this.http.get<CSCancellation[]>(ROOT_URL + 'CloudCharge/GetAllCsCancellation', {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }

    deleteCsCancellation(Id)
    {
      const headers = new HttpHeaders().set('content-type', 'application/json');
      let params = new HttpParams();
      params=params.append('Id',Id)
      params=params.append('TransMode','DELETE')
      return this.http.delete<Response>(ROOT_URL + 'CloudCharge/deleteCsCancellation', {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
}
