import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DevelopersHomeScreenComponent } from 'src/app/DashBoard/developers-home-screen/developers-home-screen.component'; 
import { CustomerRequirementsComponent } from '../Admin/Support-Engineer/customer-requirements/customer-requirements.component';
import { ServiceReportEntryComponent } from '../Admin/Support-Engineer/service-report-entry/service-report-entry.component';
import { JobCardComponent } from './Developer/job-card/job-card.component';

const routes: Routes = [
  { path: '', component: DevelopersHomeScreenComponent, },
  { path: 'customerrequirement', component: CustomerRequirementsComponent },
  { path: 'servicereportentry', component: ServiceReportEntryComponent },
  { path: 'jobcard', component: JobCardComponent }

]
@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes),
  ]
})
export class DeveloperModule { }
