import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/Services/customer.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { NgForm } from '@angular/forms';
import { ServiceHistoryService } from 'src/app/Services/service-history.service';
import * as CryptoJS from 'crypto-js';
import { CommonService } from 'src/app/Services/common.service';
import { BPEService } from 'src/app/Services/bpe.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-service-history',
  templateUrl: './service-history.component.html',
  styleUrls: ['./service-history.component.css']
})
export class ServiceHistoryComponent implements OnInit {

  public errorMsg;
  // LoginId: string = '0'
  LoginRole: string = ''
  TypeList: any;
  StatusList: any;

  constructor(public _CustomerService: CustomerService,
              public _CommonService: CommonService,
              private toastr: ToastrService,
              public _bpeService: BPEService,
              public _EmployeeService: EmployeeService,
              public _CustomerrequirementService: CustomerrequirementService,
              public _dealrService: DealerService,
              public _serviceHistoryService: ServiceHistoryService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem("Designation") == 'Dealer') {
      // this.LoginId = CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8);
      this.LoginRole = 'Dealer'
    }
    else if (localStorage.getItem("Designation") == 'Support Leader') {
      // this.LoginId = CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8);
      this.LoginRole = 'Support Leader'
    }
    else {
      // this.LoginId = '0';
      this.LoginRole = 'ALL'
    }
    this._serviceHistoryService.clearValues();
    this.GetCommonLost()
    this.Reset();
  }

  GetCommonLost() {
    this.TypeList = []
    this.StatusList = []
    this.LoadDealer();
    this.fillServiceEngineer();
    this.StatusList = this._CommonService.GetStatusFullList();
    this.TypeList = this._CommonService.GetTypeList();
    this.FillBPE();
  }


  Reset(form?: NgForm) {
    this._serviceHistoryService.SelectedServiceHistory = {
      AMCStatus: null,
      Address: null,
      BE: null,
      ContactPerson: null,
      CustomerId: null,
      DealerId: null,
      Duration: null,
      Email: null,
      InstallationDate: null,
      Phone: null,
      ServiceHistoryDtls: []
    }
    this._serviceHistoryService.SelectedServiceHistoryDtlsFooter = {
      Design: 0,
      Error: 0,
      Expense: 0,
      Modification: 0,
      OnSite: 0,
      Perfomance: 0,
      Remote: 0,
      ServiceCharge: 0,
      TotalWorks: 0,
      Training: 0
    }
  }

  onSelect() {
    this.GetServiceHistory();
    var custDtls = this._CustomerService.allCustomers.filter(e => e.Id == this._serviceHistoryService.SelectedServiceHistory.CustomerId)[0]
    this._serviceHistoryService.SelectedServiceHistory.Address = custDtls.Addr1;
    this._serviceHistoryService.SelectedServiceHistory.Phone = custDtls.CustPhone;
    this._serviceHistoryService.SelectedServiceHistory.Email = custDtls.CustEmail;
    this._serviceHistoryService.SelectedServiceHistory.ContactPerson = custDtls.ContactPerson;
  }


  GetServiceHistory() {
    this._serviceHistoryService.SelectedServiceHistoryDtlsFooter = {
      Design: 0,
      Error: 0,
      Expense: 0,
      Modification: 0,
      OnSite: 0,
      Perfomance: 0,
      Remote: 0,
      ServiceCharge: 0,
      TotalWorks: 0,
      Training: 0
    }
    this._serviceHistoryService.getServiceHistory(this._serviceHistoryService.SelectedServiceHistory.CustomerId)
      .subscribe(data => {
        this._serviceHistoryService.SelectedServiceHistory.Duration = data['Duration']
        this._serviceHistoryService.SelectedServiceHistory.InstallationDate = data['InstallationDate']
        this._serviceHistoryService.SelectedServiceHistory.AMCStatus = data['AMCStatus']
        this._serviceHistoryService.SelectedServiceHistory.BE = data['BE']
        this._serviceHistoryService.allServiceHistoryDtls = data['ServiceHistoryDtls']
        this._serviceHistoryService.allServiceHistoryDtls = data.ServiceHistoryDtls
        this._serviceHistoryService.SelectedServiceHistoryDtlsFooter = data.ServiceHistorySummmary
        console.log(this._serviceHistoryService.allServiceHistoryDtls)
      })
  }

  FillBPE() {
    this._bpeService.getBusinessPromotionExecutive('ALL')
      .subscribe(data => this._bpeService.allBusinessPromotionExecutives = data,
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee(this.LoginRole == 'ALL' ? 'ALL' : this.LoginRole == 'Dealer' ? 'DEALER' : 'SERVICEID')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  LoadDealer() {
    this._dealrService.FetchDealer(this.LoginRole == 'Dealer' ? 'ID' : this.LoginRole == 'Support Leader' ? 'SERVICEID' : 'ALL')
      .subscribe(
        data => {
          this._dealrService.allDealers = data
          if (this._dealrService.allDealers.length > 0) {
            this._serviceHistoryService.SelectedServiceHistory.DealerId = this._dealrService.allDealers[0]['Id']
            this.LoadCustomers();
          }
          else
            this.toastr.warning('Dealer Does not Exists', 'Service History')
        },
        error => {
          this.errorMsg = error;
        });
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomerById('DEALERID', this._serviceHistoryService.SelectedServiceHistory.DealerId)
      .subscribe(data => {
        this._CustomerService.allCustomers = data
        if (this._CustomerService.allCustomers.length > 0) {
          this._serviceHistoryService.SelectedServiceHistory.CustomerId = this._CustomerService.allCustomers[0]['Id']
          this.onSelect();
        }
        else
          this.toastr.warning('Customer Does not Exists', 'Service History')
      },
        error => {
          this.errorMsg = error;
        });
  }

}
