<!--project manager & project leader dashboard:-employee workshedule -->
<div *ngIf="this.employeeService.employeepopup==1 && !this.commonService.isLoading" class="Atj_overlay " (click)="$event.stopPropagation(); closePopUp()">
    <div id="Atj_popup" class="Atj_popup ATJ-slide-bottom" (click)="$event.stopPropagation();">      
            <div class="headding">
                <marquee direction = "right" width="70%" behavior="alternate">Work Schedule</marquee>    
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-8">
                    <input placeholder="FromDate" name="FromDate" #commonSelectedFromDate="ngModel"
                        [(ngModel)]="this.selectedFromDate" style="color: black;width:150px;text-align: left;" autocomplete="off" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY'}">
                    <input placeholder="ToDate" name="ToDate" #commonSelectedToDate="ngModel"
                        [(ngModel)]="this.selectedToDate" style="color: black;width:150px;text-align: left;margin-left:3px;" autocomplete="off" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY'}">
                    <button class="btn btn-sm btn-success" (click)="employeeWorkShedule()">Filter</button>
                </div>        
            </div>          
        <div class="headding" *ngIf="this.employeeService.employeeWorkshedule.length == 0"> 
            {{this.employeeName}} is free on {{this.selectedFromDate | date :'MM-d-yyyy'}} to {{this.selectedToDate | date :'MM-dd-yyyy'}}.....!
        </div>
        <div class="Atj_content" *ngIf="this.employeeService.employeeWorkshedule.length > 0">       
                <table class="tablepl">
                    <thead>
                        <tr>
                            <th scope="col" style="width:40px;">SNo</th>
                            <th scope="col" style="width:70px;">ActivityId</th>
                            <th scope="col" style="width:80px;">Issue Date</th>
                            <th scope="col" style="width:80px;">Est.St.Date</th>
                            <th scope="col" style="width:80px;">Est.End.Date</th>
                            <th scope="col" style="width:80px;">Est.Hrs</th>
                            <th scope="col" style="width:80px;">Status</th>
                            <th scope="col" style="width:80px;">Overdue By</th>
                            <th scope="col" style="width:80px;">Attempt</th>
                            <th scope="col" style="width:120px;">Customer</th>
                            <th scope="col" style="width:100px;">Module</th>
                            <th scope="col" style="width:210px;">Work Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let works of this.employeeService.employeeWorkshedule;let i=index">
                         <td>{{i+1}}</td>
                         <td>{{works.ActivityId}}</td>
                         <td>{{works.IssueDate}}</td>
                         <td>{{works.EstStDate}}</td>
                         <td>{{works.EstEndDate}}</td>
                         <td>{{works.EstHrs}}</td>
                         <td>{{works.Status}}</td>
                         <td>{{works.OverdueBy}}</td>
                         <td>{{works.Attempt}}</td>
                         <td title="{{works.Customer}}">{{works.Customer}}</td>
                         <td title="{{works.Module}}">{{works.Module}}</td>
                         <td title="{{works.WorkDetails}}">{{works.WorkDetails}}</td>
                        </tr>
                    </tbody>
                </table>           
            <br>
            <div style="float: right;">
                <button class="btn btn-sm btn-success" (click)="closePopUp()">Close</button>
            </div>
        </div>
    </div>
</div>
<!--project manager & project leader dashboard:-employee workhistory -->
<div *ngIf="this.employeeService.employeepopup==2" class="Atj_overlay " (click)="$event.stopPropagation(); closePopUp()">
    <div id="Atj_popup" class="Atj_popup ATJ-slide-bottom" (click)="$event.stopPropagation();">
        <div class="headding">
           <marquee direction = "right" behavior="alternate">Work History</marquee>  
        </div>
        <a class="close" (click)="closePopUp()"></a>
        <div class="headding"> 
            <span style="padding-left: 350px;">{{this.employeeName}} << {{this.employeeService.selectedDate |date: 'EEEE MMMM yyyy'}} >></span>
        </div>
        <div class="Atj_content">  
            <div class="fixTableHead">     
                <table class="tablepl">
                    <thead>
                        <tr>
                           <th style="width:130px;"></th>
                           <ng-container *ngFor="let time of timeList">
                             <th style="width:80px;">{{time.time}}</th>
                           </ng-container>
                        </tr>
                    </thead>
                    <tbody class="hover">
                        <tr *ngFor="let data of this.employeeService.employeeActivity.ActivitymonthDays;let i=index">
                            <td style="width:130px;" title="{{data.Value}}" [ngClass]="{'overdue':data.DayType==2,'leave':data.DayType==1,'publicholiday':data.DayType==3}">{{data.Date | date:'d'}}
                                <span style="padding-left:3px;">{{data.Date | date:'EEE'}}</span>
                                <span style="padding-left:2px;" *ngIf="data.DayType > 0">({{data.Value}})</span>
                            </td>
                            <ng-container>
                                <td></td>
                            </ng-container>
                            <!-- <td>0</td> -->
                        </tr>
                    </tbody>
                </table>           
            <br>            
            </div>
            <div style="float: right;padding-right:5px;">
                <button class="btn btn-sm btn-success" (click)="closePopUp()">Close</button>
            </div>
        </div>
    </div>
</div>