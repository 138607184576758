import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ServerInfo } from '../Models/Server';
import { Observable } from 'rxjs';
import { Common } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  
  selectedRecord:ServerInfo
  formHideShow = 0;
  allServer:ServerInfo[]; 
  allServerProvider:Common[]=[];
  allProject:Common[]=[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  RegDateFilter: string = '';
  ServerFilter: string = '';
  LocationFilter:string='';
  ServerProviderFilter:string='';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;

  clearValue(){
    this.selectedRecord={
      ID:0,
      Date:this.dateToStr(new Date()),
      RegDate:this.dateToStr(new Date()),
      Server:'',
      Specification:'',
      Location:'',
      ServerCost:0,
      TermID:1,
      ServerExpiry:this.dateToStr(new Date()),
      ServerProviderID:undefined,
      ServerProvider:'',
      Capacity:'',
      IsCanceled:false,
      Reason:'',
      ProjectList:[],
      EditedProjectList:''
    }
  }

  clearFilter() {
    this.isFiltering = false;
    this.RegDateFilter = '';
    this.ServerFilter = '';
    this.LocationFilter='';
    this.ServerProviderFilter='';
  }
  dateToStr(str: any,type:number=0) {
    var date = new Date(str);var mnth;var day;
    if(type=1)
    {
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [mnth, day,date.getFullYear(),].join("-");
    }
    else
    {
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }

  }
  get(TransMode: string): Observable<ServerInfo[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ServerInfo[]>(ROOT_URL + 'Server/Get', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getByID(TransMode: string,id:number): Observable<ServerInfo> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('ID', id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ServerInfo>(ROOT_URL + 'Server/GetByID', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  trans(values: ServerInfo): Observable<any> {
    var body = {
      ID:values.ID,
      Date:this.dateToStr(values.Date),
      RegDate:this.dateToStr(values.RegDate),
      Server:values.Server,
      Specification:values.Specification,
      Location:values.Location,
      ServerCost:values.ServerCost,
      TermID:values.TermID,
      ServerExpiry:this.dateToStr(values.ServerExpiry),
      ServerProviderID:values.ServerProviderID,
      Capacity:values.Capacity,
      IsCanceled:values.IsCanceled,
      Reason:values.Reason,
      ProjectList:JSON.stringify(values.ProjectList)
    }
    let params = new HttpParams();
    params = params.append('TransMode',"SAVE");
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "Server/Trans", body, {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  onDelete(Id:number){
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete<any>(ROOT_URL + 'Server/Delete', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
    }
}
