<div class="container-fluid" *ngIf="_custService.formHideShow!=0">
    <h2 class="heading_all">Customer </h2>
    <br>
    <div id="form-wrap">
        <form #customerForm="ngForm" (ngSubmit)="onSubmit(customerForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_custService.selectedCustomer.Id">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Country</label>
                        <select disabled class="form-control" name="CountryId" #CountryId="ngModel"
                            [(ngModel)]="_custService.selectedCustomer.CountryId" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _countryService.allCountries | orderBy : 'Name'"
                                [ngValue]="prf.Id">
                                {{ prf.Name }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(CountryId.invalid && CountryId.touched)||(CountryId.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Dealer</label>
                        <select disabled class="form-control" name="DealerId" #DealerId="ngModel"
                            [(ngModel)]="_custService.selectedCustomer.DealerId" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                [ngValue]="prf.Id">
                                {{ prf.DealerName }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(DealerId.invalid && DealerId.touched)||(DealerId.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Name<span class="validation-error">*</span></label>
                        <input class="form-control" name="Name" #Name="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.Name" placeholder=" Name" required>
                        <div class="validation-error"
                            *ngIf="(Name.invalid && Name.touched)||(Name.invalid && this.FormValidation)">This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Code<span class="validation-error">*</span></label>
                        <input class="form-control" name="Code" #Code="ngModel" autocomplete="off"
                           disabled [ngModel]="_custService.selectedCustomer.Code" placeholder=" Code" required>
                        <div class="validation-error"
                            *ngIf="(Code.invalid && Code.touched)||(Code.invalid && this.FormValidation)">This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Address<span class="validation-error">*</span></label>
                        <input class="form-control" name="Addr1" #Addr1="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.Addr1" placeholder=" Addr1" required>
                        <div class="validation-error"
                            *ngIf="(Addr1.invalid && Addr1.touched)||(Addr1.invalid && this.FormValidation)">This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label><span class="validation-error">*</span></label>
                        <input class="form-control" name="Addr2" #Addr2="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.Addr2" placeholder=" Addr2" required>
                        <div class="validation-error"
                            *ngIf="(Addr2.invalid && Addr2.touched)||(Addr2.invalid && this.FormValidation)">This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label><span class="validation-error">*</span></label>
                        <input class="form-control" name="Addr3" #Addr3="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.Addr3" placeholder=" Addr3" required>
                        <div class="validation-error"
                            *ngIf="(Addr3.invalid && Addr3.touched)||(Addr3.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Email<span class="validation-error">*</span></label>
                        <input class="form-control" name="CustEmail" #CustEmail="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.CustEmail"
                            [pattern]=this._CommonService.emailpattern placeholder=" Cust Email"
                            type="email" required>
                        <div class="validation-error"
                            *ngIf="(CustEmail.invalid && CustEmail.touched)||(CustEmail.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Phone<span class="validation-error">*</span></label>
                        <input class="form-control" name="CustPhone" #CustPhone="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.CustPhone" placeholder=" Cust Phone"
                            (keypress)="isNumber($event)" ng-pattern="/^[0-9]*$/" required>
                        <div class="validation-error"
                            *ngIf="(CustPhone.invalid && CustPhone.touched)||(CustPhone.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Fax<span class="validation-error">*</span></label>
                        <input class="form-control" name="CustFax" #CustFax="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.CustFax" ng-pattern="/^[a-zA-Z0-9]*$/"
                            placeholder=" CustFax" required>
                        <div class="validation-error"
                            *ngIf="(CustFax.invalid && CustFax.touched)||(CustFax.invalid && this.FormValidation)">This Field is Required.
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>URL<span class="validation-error">*</span></label>
                        <input class="form-control" name="URL" #URL="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.URL" placeholder=" URL" required>
                        <div class="validation-error"
                            *ngIf="(URL.invalid && URL.touched)||(URL.invalid && this.FormValidation)">This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Contact Person<span class="validation-error">*</span></label>
                        <input class="form-control" name="ContactPerson" #ContactPerson="ngModel"
                            [(ngModel)]="_custService.selectedCustomer.ContactPerson" autocomplete="off"
                            placeholder=" ContactPerson" required>
                        <div class="validation-error"
                            *ngIf="(ContactPerson.invalid && ContactPerson.touched)||(ContactPerson.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Mobile<span class="validation-error">*</span></label>
                        <input class="form-control" name="ContactPhone" #ContactPhone="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.ContactPhone" placeholder=" Contact Phone"
                            (keypress)="isNumber($event)" ng-pattern="[789][0-9]{9}" required>
                        <div class="validation-error"
                            *ngIf="(ContactPhone.invalid && ContactPhone.touched)||(ContactPhone.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Contact Email<span class="validation-error">*</span></label>
                        <input class="form-control" name="ContactEmail" #ContactEmail="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.ContactEmail"
                            [pattern]=this._CommonService.emailpattern placeholder=" Contact Email" required>
                        <div class="validation-error"
                            *ngIf="(ContactEmail.invalid && ContactEmail.touched)||(ContactEmail.invalid && this.FormValidation)">
                            This Field is Required.
                            </div>
                    </div>
                    <div class="form-group">
                        <label>No.of branches<span class="validation-error">*</span></label>

                        <input class="form-control" name="NoOfBranches" #NoOfBranches="ngModel" autocomplete="off"
                            (keyup)="UpdateBranchName()" [(ngModel)]="_custService.selectedCustomer.NoOfBranches"
                            placeholder=" NoOfBranches" (keypress)="isNumber($event)" required>
                        <div class="validation-error"
                            *ngIf="(NoOfBranches.invalid && NoOfBranches.touched)||(NoOfBranches.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.BranchNameVisibility ">
                        <label>Branch Name<span class="validation-error">*</span></label>
                        <input class="form-control" name="BranchName" #BranchName="ngModel" autocomplete="off"
                            [(ngModel)]="_custService.selectedCustomer.BranchName" placeholder=" Branch Name" required>
                        <div class="validation-error"
                            *ngIf="(BranchName.invalid && BranchName.touched)||(BranchName.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.BranchNameVisibility ">
                        <label for="select">Route<span class="validation-error">*</span></label>
                        <select class="form-control" name="RouteId" #RouteId="ngModel"
                            [(ngModel)]="_custService.selectedCustomer.RouteId" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _routeService.allRoutes | orderBy : 'RouteName'"
                                [ngValue]="prf.Id">
                                {{ prf.RouteName }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(RouteId.invalid && RouteId.touched)||(RouteId.invalid && this.FormValidation)">This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Company Logo <span class="validation-error">*</span></label>
                        <input type="file" accept="image/*" name="LogoURL" autocomplete="off"
                            (change)="handleFileInput($event.target.files)"> <br><br>
                    </div>
                    <div class="form-group">
                        <label></label>
                        <!-- <input type="text" id="" name="" class="form-control" placeholder=""> -->
                        <div class="uploadImg">
                            <img [src]="_custService.imageUrl" height="150" width="190">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="submit" [disabled]="this.isSubmit"
                        class="sim-button button1">
                        Submit
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                        (click)="resetForm(customerForm)">
                        Reset
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3"
                        (click)="backList()">Exit</button>
                </div>
            </div>
            <app-delete-confirm *ngIf="saveBranch" [title]="'Customer'"
                [message]="'Do you want to save Branch Details?'" [btnOkText]="'Yes'" [btnCancelText]="'No'"
                (ConfirmEvent)="saveBranchDetailsTo($event,customerForm)">
            </app-delete-confirm>
        </form>
    </div>
</div>