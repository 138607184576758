<div class="container-fluid">
    <h2 class="heading_all">Payment PostPoned</h2>
    <div id="padding-left:30px;padding-right:30px;">
        <form [formGroup]="paymentposphonedform" (ngSubmit)="paymentposphonedform.valid && onSubmit()">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>VNo:<span class="validation-error">*</span></label>
                            <input type="text" class="form-control" formControlName="VNo" name="VNo"  autocomplete="off" placeholder="VNo" required>
                            <div class="validation-error">
                                <ng-container *ngFor="let validation of validationMessages.VNo">
                                    <div class="error-message"
                                        *ngIf="paymentposphonedform.get('VNo').hasError(validation.type) && (paymentposphonedform.get('VNo').dirty || paymentposphonedform.get('VNo').touched)">
                                        <div name="information-circle-outline">
                                        </div> {{ validation.message }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Date:<span class="validation-error">*</span></label>
                            <input class="form-control" placeholder="Date" name="Date" formControlName="Date"
                                style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                            <div class="validation-error">
                                <ng-container *ngFor="let validation of validationMessages.Date">
                                    <div class="error-message"
                                        *ngIf="paymentposphonedform.get('Date').hasError(validation.type) && (paymentposphonedform.get('Date').dirty || paymentposphonedform.get('Date').touched)">
                                        <div name="information-circle-outline">
                                        </div> {{ validation.message }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button
                            style="color: white;background-color: gray;border: black;border-radius: 50%;width: auto;margin-top: 20px;"
                            type="button" class="sim-button button1" aria-hidden="true">Dues</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Customer<span class="validation-error">*</span></label>
                            <ng-select name="CustomerId" id="CustomerId" formControlName="Customer" bindLabel="Name"
                                bindValue="Id" [items]="_custService.allCustomers  | orderBy : 'Name'" required
                                (ngModelChange)="loadCustomerWiseBranch()">
                            </ng-select>
                            <div class="validation-error">
                                <ng-container *ngFor="let validation of validationMessages.Customer">
                                    <div class="error-message"
                                        *ngIf="paymentposphonedform.get('Customer').hasError(validation.type) && (paymentposphonedform.get('Customer').dirty || paymentposphonedform.get('Customer').touched)">
                                        <div name="information-circle-outline">
                                        </div> {{ validation.message }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Branch<span class="validation-error">*</span></label>
                            <ng-select name="Branch" id="Branch" formControlName="Branch" bindLabel="BranchName"
                                bindValue="Id" [items]="this._brnchService.allBranchs  | orderBy : 'BranchName'" required >
                            </ng-select>
                            <div class="validation-error">
                                <ng-container *ngFor="let validation of validationMessages.Branch">
                                    <div class="error-message"
                                        *ngIf="paymentposphonedform.get('Branch').hasError(validation.type) && (paymentposphonedform.get('Branch').dirty || paymentposphonedform.get('Branch').touched)">
                                        <div name="information-circle-outline">
                                        </div> {{ validation.message }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6" style="text-align: center;">
                        <label style="font-weight: bold;">Total Due:0</label>
                    </div>
                    <div class="col-md-6"></div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>PostPoned To:<span class="validation-error">*</span></label>
                            <input class="form-control" placeholder="PostPonedTo" name="PostPonedTo"
                                formControlName="PostPonedTo" style="color: black;  text-align: left;"
                                autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                            <div class="validation-error">
                                <ng-container *ngFor="let validation of validationMessages.PostPonedTo">
                                    <div class="error-message"
                                        *ngIf="paymentposphonedform.get('PostPonedTo').hasError(validation.type) && (paymentposphonedform.get('PostPonedTo').dirty || paymentposphonedform.get('PostPonedTo').touched)">
                                        <div name="information-circle-outline">
                                        </div> {{ validation.message }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Remark:</label>
                            <textarea class="form-control" name="Remark" formControlName="Remark" autocomplete="off"
                                placeholder=" Remark"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="submit"
                             class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                            (click)="Restform()">Reset</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>