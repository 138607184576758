<div class="container-fluid">
    <h2 class="heading_all">Cloud Charge</h2>
    <div id="form-wrap">
        <form action="#" #CloudChargeForm="ngForm">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>VNo:<span class="validation-error">*</span></label>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control" type="number" name="VNo" id="VNo" #VNo="ngModel" autocomplete="off"
                                    [(ngModel)]="_CloudChargeService.selectedCloudCharge.VNo" placeholder="VNo" required>
                            </div>
                            <div class="validation-error" *ngIf="VNo.invalid && VNo.touched && this.FormValidation">
                                This Field is Required.
                            </div>
                            <div class="col-md-6">
                                <i class="fas fa-search searchicon" (click)="searchCloudCharge()"  aria-hidden="true">
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" >
                        <label>Dealer</label>
                        <select class="form-control" name="DealerId" #DealerId="ngModel" [disabled]="_CloudChargeService.selectedCloudCharge.SoftwareRegId > 0"
                            [(ngModel)]="_CloudChargeService.selectedCloudCharge.DealerId" (change)="loadDealerWiseCustomers()"  required>
                            <option [ngValue]="null">Choose Dealer</option>
                            <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'" [ngValue]="prf.Id">
                                {{ prf.DealerName }}
                            </option>
                        </select>
                        <div class="validation-error" *ngIf="DealerId.invalid && this.FormValidation">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Customer<span class="validation-error">*</span></label>
                        <ng-select name="CustomerId" id="CustomerId" bindLabel="Name" #CustomerId="ngModel" [disabled]="(_CloudChargeService.selectedCloudCharge.DealerId==null || _CloudChargeService.selectedCloudCharge.SoftwareRegId > 0)"
                            [(ngModel)]="_CloudChargeService.selectedCloudCharge.CustomerId" bindValue="Id"
                            [items]="_custService.allCustomers  | orderBy : 'Name'" required >
                        </ng-select>
                        <div class="validation-error" *ngIf="CustomerId.invalid && this.FormValidation">
                            This Field is Required.
                        </div>
                    </div>          
                    <div class="form-group">
                        <label>Remark:</label>
                        <textarea class="form-control" name="Remark" autocomplete="off" #Remark="ngModel"
                            [(ngModel)]="_CloudChargeService.selectedCloudCharge.Remark" placeholder=" Remark"></textarea>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Date:<span class="validation-error">*</span></label>
                        <input class="form-control" placeholder="Date" name="Date" #Date="ngModel"
                            [(ngModel)]="_CloudChargeService.selectedCloudCharge.Date" 
                            style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        <div class="validation-error" *ngIf="Date.invalid && this.FormValidation">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Rent(One Month):<span class="validation-error">*</span></label>
                                <input class="form-control" name="MonthlyRent" autocomplete="off" #MonthlyRent="ngModel"
                                    [(ngModel)]="_CloudChargeService.selectedCloudCharge.MonthlyRent" (input)="this.totalRent()" placeholder="Monthly Rent" (keypress)="this._CommonService.decimalPointValidation($event)" required>
                                <div class="validation-error" *ngIf="MonthlyRent.invalid && this.FormValidation">
                                    This Field is Required.
                                </div>
                            </div>
                           <div class="col-md-6">
                            <label>Total Rent:<span class="validation-error">*</span></label>
                            <input class="form-control" name="TotalRent" autocomplete="off" #TotalRent="ngModel" disabled
                                [(ngModel)]="_CloudChargeService.selectedCloudCharge.TotalRent" placeholder="Total Rent" (keypress)="this._CommonService.decimalPointValidation($event)" required>
                            <div class="validation-error" *ngIf="TotalRent.invalid && this.FormValidation">
                                This Field is Required.
                            </div>
                           </div>                          
                        </div>                     
                    </div>
                    <div class="form-group">
                        <label>Affected From:<span class="validation-error">*</span></label>
                        <input class="form-control" placeholder="Affected From" name="AffectedFrom" id="AffectedFrom"
                            #AffectedFrom="ngModel" [(ngModel)]="_CloudChargeService.selectedCloudCharge.AffectedFrom" (ngModelChange)="this.expiryDateCalculate()"
                             style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        <div class="validation-error" *ngIf="AffectedFrom.invalid && this.FormValidation">
                            This Field is Required.</div>                     
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Duration(Months):</label>
                                <input class="form-control" name="Duration" autocomplete="off" placeholder=" Duration" #Duration="ngModel"
                                 minlength="1" (keypress)="this._CommonService.numberValidation($event)" 
                                [(ngModel)]="_CloudChargeService.selectedCloudCharge.Duration" [disabled]="!!_CloudChargeService.selectedCloudCharge.FreeFor" (input)="this.expiryDateCalculate()">                             
                            </div>
                            <div class="col-md-6">
                                <label>FreeFor(Months):</label>
                                <input class="form-control" name="FreeFor" autocomplete="off" #FreeFor="ngModel" placeholder=" FreeFor"
                                (input)="this.expiryDateCalculate()" minlength="1" (keypress)="this._CommonService.numberValidation($event)"
                                [(ngModel)]="_CloudChargeService.selectedCloudCharge.FreeFor" [disabled]="!!_CloudChargeService.selectedCloudCharge.Duration">                             
                            </div>
                        </div>
                        
                    </div>
                    <div class="form-group">
                        <label>Expiry:<span class="validation-error">*</span></label>
                        <input class="form-control" placeholder="Expiry" name="Expiry" id="Expiry" #Expiry="ngModel"
                            [(ngModel)]="_CloudChargeService.selectedCloudCharge.Expiry"  disabled 
                            style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        <div class="validation-error" *ngIf="Expiry.invalid && this.FormValidation">
                            This Field is Required.</div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="this._CommonService.dueEnabled">
                    <button style="color: white;background-color: gray;border: black;" type="button"
                        (click)="displayPendingCloudCharge()" class="sim-button button1" aria-hidden="true">Dues</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" [disabled]="this.isSubmit"
                       (click)="onSubmit(CloudChargeForm)" class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button1"
                       (click)="deleteCloudCharge()" >Delete</button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                        (click)="resetForm(CloudChargeForm)">Reset</button>
                </div>
            </div>
          
            <!-- ---------------------Dues modal  popup----------------------- -->     
            <div class="popup-container" *ngIf="this.popUpIndex">
                <input type="checkbox" id="test-results" checked>
                <div class="popup HoldingReason" (click)="closePopUp()">
                    <div class="inner ui-input-container" (click)="$event.stopPropagation();" [ngStyle]="{'height':_CloudChargeService.allDues.length > 2?'400px':'230px'}">
                        <div class="title">
                            <p>Cloud Dues</p>  
                            <label style="padding-left:730px">Expire Within(Days):</label>
                            <input class="form-control" name="remainderDays" autocomplete="off" [(ngModel)]="this._CommonService.remainderDays"placeholder=" With In Days" style="width: 100px;" required>
                            <input type="button" style="color: white;background-color: gray;width: 100px;height:35px; border: black;"
                                    (click)="displayPendingCloudCharge()" value="Search" aria-hidden="true">                           
                            <i class="fas fa-times" (click)="closePopUp()"></i>
                        </div>
                          <div class="fixTableHead">
                            <div class="row">
                            <div class="col-md-12">
                                <table class="tablepl table" id="duedetails">
                                    <thead>
                                        <tr>
                                            <th class="hidden-xs" style="width:160px;">
                                                <div class="AtjIsPoint" (click)="sort('Expiry')">
                                                    <span *ngIf="!_CloudChargeService.isFiltering">Expiry Date</span>
                                                    <div class="AtjfloatRight">
                                                        <i class="fas fa-sort"
                                                            *ngIf="_CloudChargeService.orderByColName!='Expiry' "></i>
                                                        <div
                                                            *ngIf="_CloudChargeService.orderByColName=='Expiry'">
                                                            <i class="fas fa-sort-amount-down"
                                                                *ngIf="_CloudChargeService.orderByDir"></i>
                                                            <i class="fas fa-sort-amount-up-alt"
                                                                *ngIf="!_CloudChargeService.orderByDir"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="_CloudChargeService.isFiltering">
                                                    <input type="text" #txtExpiryFilter="ngModel"
                                                        [(ngModel)]="_CloudChargeService.DateFilter"
                                                        id="DateFilter" name="DateFilter"
                                                        placeholder="Expiry Date">
                                                </div>
                                            </th>
                                            <th class="hidden-xs">
                                                <div class="AtjIsPoint"
                                                    (click)="sort('DealerName')">
                                                    <span *ngIf="!_CloudChargeService.isFiltering">Dealer</span>
                                                    <div class="AtjfloatRight">
                                                        <i class="fas fa-sort"
                                                            *ngIf="_CloudChargeService.orderByColName!='DealerName' "></i>
                                                        <div
                                                            *ngIf="_CloudChargeService.orderByColName=='DealerName'">
                                                            <i class="fas fa-sort-amount-down"
                                                                *ngIf="_CloudChargeService.orderByDir"></i>
                                                            <i class="fas fa-sort-amount-up-alt"
                                                                *ngIf="!_CloudChargeService.orderByDir"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="_CloudChargeService.isFiltering">
                                                    <input type="text"
                                                        #txtDealerNameFilter="ngModel"
                                                        [(ngModel)]="_CloudChargeService.DealerFilter"
                                                        id="DealerFilter" name="DealerFilter"
                                                        placeholder="Dealer">
                                                </div>
                                            </th>
                                            <th class="hidden-xs">
                                                <div class="AtjIsPoint"
                                                    (click)="sort('CustomerName')">
                                                    <span  *ngIf="!_CloudChargeService.isFiltering">Customer</span>
                                                    <div class="AtjfloatRight">
                                                        <i class="fas fa-sort"
                                                            *ngIf="_CloudChargeService.orderByColName!='CustomerName' "></i>
                                                        <div
                                                            *ngIf="_CloudChargeService.orderByColName=='CustomerName'">
                                                            <i class="fas fa-sort-amount-down"
                                                                *ngIf="_CloudChargeService.orderByDir"></i>
                                                            <i class="fas fa-sort-amount-up-alt"
                                                                *ngIf="!_CloudChargeService.orderByDir"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="_CloudChargeService.isFiltering">
                                                    <input type="text"
                                                        #txtCustomerNameFilter="ngModel"
                                                        [(ngModel)]="_CloudChargeService.CustomerFilter"
                                                        id="CustomerFilter" name="CustomerFilter"
                                                        placeholder="Customer">
                                                </div>
                                            </th>                                        
                                            <th class="hidden-xs" style="width:140px;">
                                                <div class="AtjIsPoint" (click)="sort('CloudRent')">
                                                    <span *ngIf="!_CloudChargeService.isFiltering">Total Rent</span>
                                                    <div class="AtjfloatRight">
                                                        <i class="fas fa-sort"
                                                            *ngIf="_CloudChargeService.orderByColName!='CloudRent'"></i>
                                                        <div
                                                            *ngIf="_CloudChargeService.orderByColName=='CloudRent'">
                                                            <i class="fas fa-sort-amount-down"
                                                                *ngIf="_CloudChargeService.orderByDir"></i>
                                                            <i class="fas fa-sort-amount-up-alt"
                                                                *ngIf="!_CloudChargeService.orderByDir"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="_CloudChargeService.isFiltering">
                                                    <input type="text" #txtTotalRentFilter="ngModel"
                                                        [(ngModel)]="_CloudChargeService.TotalRentFilter"
                                                        id="TotalRentFilter" name="TotalRentFilter"
                                                        placeholder="Total Rent">
                                                </div>                                      
                                            </th>
                                            <th style="width:140px;">Pending Month
                                                <a class="AtjIsPoint AtjfloatRight"
                                                (click)="toggleFilter()">
                                                <i *ngIf="!_CloudChargeService.isFiltering"
                                                    class="fas fa-filter"></i>
                                                <i *ngIf="_CloudChargeService.isFiltering"
                                                    class="fas fa-times"></i>
                                            </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr (click)="getSelectedDue(due,i)"
                                        *ngFor="let due of _CloudChargeService.allDues | gridOperations  :_CloudChargeService.pageIndex: 
                                            {Expiry:_CloudChargeService.DateFilter,DealerName:_CloudChargeService.DealerFilter,CustomerName:_CloudChargeService.CustomerFilter
                                            ,BranchName:_CloudChargeService.BranchFilter,CloudRent:_CloudChargeService.TotalRentFilter}:
                                            {Column:_CloudChargeService.orderByColName,Dir:_CloudChargeService.orderByDir} ; let i = index">
                                        <td style="width:130px;">{{due.Expiry}}</td>
                                        <td title="{{due.DealerName}}">{{due.DealerName}}</td>
                                        <td title="{{due.CustomerName}}">{{due.CustomerName}}</td>
                                        <td style="width:140px;">{{due.CloudRent}}</td>
                                        <td style="width:140px;">{{due.PendingMonth}}</td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                        </div>
                      </div>                     
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>