import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.css']
})
export class DeleteConfirmComponent implements OnInit {

  @Input() title: string = "Confirm";
  @Input() message: string = "Are you sure you want to delete ?";
  @Input() btnOkText: string = "Ok";
  @Input() btnCancelText: string = "Cancel";
  @Output() ConfirmEvent = new EventEmitter<boolean>();
  constructor() { }
  accept() {
    this.ConfirmEvent.emit(true);
  }
  decline() {
    this.ConfirmEvent.emit(false);
  }
  dismiss() {
    this.ConfirmEvent.emit(null);
  }
  ngOnInit() {
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    event.stopPropagation();
    if (event.code == "Escape") {
      this.dismiss();
    }
  }
}
