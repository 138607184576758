import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
// import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    // private authenticationService: AuthenticationService
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log('err', err);
            if ([401, 403].indexOf(err.status) !== -1) {
                console.clear()
                console.log(err)
                localStorage.clear();
                this.router.navigateByUrl('/login');
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}