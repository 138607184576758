import { CommonService } from 'src/app/Services/common.service';

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/Services/settings.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-documentsetting',
  templateUrl: './documentsetting.component.html',
  styleUrls: ['./documentsetting.component.css']
})
export class DocumentsettingComponent implements OnInit {

  isSubmit:boolean=false;
  errorMsg:string='';
  FormValidation: boolean  //Used form form validation

  constructor(private toastr: ToastrService,
              public _SettingsService:SettingsService,
              public _EmployeeService:EmployeeService,
              public _CommonService:CommonService) { }

  ngOnInit(): void {
    this.getEmployee();
    this.getModule();
    this.GetVNo();
  }

  async GetVNo() {
    this._SettingsService.selecteddocumentSetting.Code = parseInt(await this._CommonService.getVNo('DOCUMENTSETTING')).toString();
  }

  getEmployee() {
    this._CommonService.getCommonList('EMPLOYEE').subscribe(data => {
      this._SettingsService.employeeList = data;
    },
      error => {
        this.errorMsg = error;
      })
  }

  getModule()
  {
    this._CommonService.getCommonList('MODULE').subscribe(data => {
      this._SettingsService.moduleList = data;
    },
      error => {
        this.errorMsg = error;
      })
  }

  onSubmit(frm:NgForm)
  {
    this.FormValidation = true;
    if(frm.valid && this._SettingsService.selecteddocumentSetting.EmployeeID > 0 && this._SettingsService.selecteddocumentSetting.ModuleID > 0 && this._SettingsService.selecteddocumentSetting.FileName!='')
    {
      this.isSubmit = true;
      this._SettingsService.saveDocumentSetting(this._SettingsService.selecteddocumentSetting).subscribe(
        data=>{
          if(data.Status)
          {
            this._SettingsService.formHideShow=0;
            this.toastr.success(data.Message,'Document Setting');
            this.resetForm();
            this._SettingsService.getalldoc(0,0)
            .subscribe(data => {
              this._SettingsService.allDocumentSetting = data
            },
              error => {
                this.errorMsg = error;
              })
          }
          else
            this.toastr.error(data.Message,'Document Setting');
          this.isSubmit =false;
        },
        error => {
          this.errorMsg = error;
        })
    }
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._SettingsService.selecteddocumentSetting = {
      ID:0,
      Code:'',
      EmployeeID: undefined,
      Employee:'',
      ModuleID:undefined,
      Module:'',
      FileName:'',
      GDrivePath:'',
      IsClosed:false
    }
    this.GetVNo();
  }

  backList()
  {
    this.resetForm();
    this._SettingsService.formHideShow = 0;
  }
}
