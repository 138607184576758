<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <!-- <h2 class="heading_all">Work ForeCasting</h2> -->
            <div id="form-wrap">
                <div class="tab-content">
                    <div id="sec1" class=" tab-pane active">                     
                        <div class="row">
                            <div class="col-md-2">                             
                                <ng-select  name="EmpId" id="EmpId" bindLabel="Name" bindValue="Id" placeholder="Select For Employee" [(ngModel)]="this.reports.selectedEmployeeId"
                                     [items]="this.employeeService.allPrgrmsTester">
                                </ng-select>
                            </div>
                            <div class="col-md-2">
                                <input type="month" id="start" name="start" min="2021-12" #start="ngModel" [(ngModel)]="this.reports.selectedDate" (change)="displayWorkForecasting()">
                            </div>
                            <div class="col-md-8">
                                <input type="color" [ngStyle]="{'background-color':'green'}" disabled><label class="worktext">Work-1</label>
                                <input type="color" [ngStyle]="{'background-color':'blue'}" disabled><label class="worktext">Work-2</label>
                                <input type="color" [ngStyle]="{'background-color':'yellow'}" disabled><label class="worktext">Work-3</label>
                                <input type="color" [ngStyle]="{'background-color':'orange'}" disabled><label class="worktext">More</label>
                                <input type="color" [ngStyle]="{'background-color':'red'}" disabled><label class="worktext">Holiday</label>
                                <input type="color" disabled><label class="worktext">FreeDay</label>
                            </div>
                        </div>
                    </div>                 
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table id="outstanding" *ngIf="this.reports.isViweEnabled">
                <thead>
                   <tr *ngFor="let obj of this.reports.workForecastingDetails.MonthDays;let i=index">
                       <td *ngIf="obj.Type==0">
                            {{obj.Day1}}
                       </td>
                       <td *ngIf="obj.Type==0">
                            {{obj.Day2}}
                       </td>
                       <td *ngIf="obj.Type==0">
                        {{obj.Day3}}
                        </td>
                        <td *ngIf="obj.Type==0">
                            {{obj.Day4}}
                        </td>
                        <td *ngIf="obj.Type==0">
                                {{obj.Day5}}
                        </td>
                        <td *ngIf="obj.Type==0">
                            {{obj.Day6}}
                        </td>
                        <td *ngIf="obj.Type==0">
                            {{obj.Day7}}
                        </td>
                   </tr>
                </thead>
                <tbody>    
                    <ng-container *ngFor="let obj of this.reports.workForecastingDetails.MonthDays;let i=index">
                        <tr> 
                            <td *ngIf="obj.Type==1">
                                {{obj.Day1}}                        
                                <tr *ngFor="let workday of this.reports.workForecastingDetails.WorkAllocatedDays">
                                    <td class="works" *ngIf="obj.Day1==workday.SelectedFromDay && workday.ColorNo <5" [ngStyle]="{'background-color':getColor(workday.ColorNo)}"></td>
                                </tr>        
                            </td>
                            <td *ngIf="obj.Type==1">
                                {{obj.Day2}}
                                <tr *ngFor="let workday of this.reports.workForecastingDetails.WorkAllocatedDays">
                                    <td class="works" *ngIf="obj.Day2==workday.SelectedFromDay && workday.ColorNo <5" [ngStyle]="{'background-color':getColor(workday.ColorNo)}"></td>
                                </tr> 
                            </td>
                            <td *ngIf="obj.Type==1">
                                {{obj.Day3}}
                                <tr *ngFor="let workday of this.reports.workForecastingDetails.WorkAllocatedDays">
                                    <td class="works" *ngIf="obj.Day3==workday.SelectedFromDay && workday.ColorNo <5" [ngStyle]="{'background-color':getColor(workday.ColorNo)}"></td>
                                </tr> 
                            </td>
                                <td *ngIf="obj.Type==1">
                                {{obj.Day4}}
                                <tr *ngFor="let workday of this.reports.workForecastingDetails.WorkAllocatedDays">
                                    <td class="works" *ngIf="obj.Day4==workday.SelectedFromDay && workday.ColorNo <5" [ngStyle]="{'background-color':getColor(workday.ColorNo)}"></td>
                                </tr> 
                            </td>
                            <td *ngIf="obj.Type==1">
                                {{obj.Day5}}
                                <tr *ngFor="let workday of this.reports.workForecastingDetails.WorkAllocatedDays">
                                    <td class="works" *ngIf="obj.Day5==workday.SelectedFromDay && workday.ColorNo <5" [ngStyle]="{'background-color':getColor(workday.ColorNo)}"></td>
                                </tr> 
                            </td>
                            <td *ngIf="obj.Type==1">
                                {{obj.Day6}}
                                <tr *ngFor="let workday of this.reports.workForecastingDetails.WorkAllocatedDays">
                                    <td class="works" *ngIf="obj.Day6==workday.SelectedFromDay && workday.ColorNo <5" [ngStyle]="{'background-color':getColor(workday.ColorNo)}"></td>
                                </tr> 
                            </td>
                            <td *ngIf="obj.Type==1">
                                {{obj.Day7}}
                                <tr *ngFor="let workday of this.reports.workForecastingDetails.WorkAllocatedDays">
                                    <td class="works" *ngIf="obj.Day7==workday.SelectedFromDay && workday.ColorNo <5" [ngStyle]="{'background-color':getColor(workday.ColorNo)}"></td>
                                </tr> 
                            </td> 
                        </tr>
                    </ng-container>                        
                </tbody>
            </table>
        </div>                        
    </div>
</div>