import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/Services/country.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-country-form',
  templateUrl: './country-form.component.html',
  styleUrls: ['./country-form.component.css']
})
export class CountryFormComponent implements OnInit {

  constructor(public _cntryServive: CountryService, private router: Router, private toastr: ToastrService) { }

  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  ngOnInit() {

    this._cntryServive.formHideShow = 0;
    this.resetForm();

  }
  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._cntryServive.selectedCountry = {
      Id: null,
      Name: '',
      Code: ''

    }
  }
  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._cntryServive.formHideShow = 0;

  }
  formValidation(): boolean {
    if (this._cntryServive.selectedCountry.Name != "" && this._cntryServive.selectedCountry.Code != "")
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    console.log('form.valid :', form.valid)
    if (form.valid && this.formValidation()) {
      this.isSubmit = true
      if (form.value.Id == null) {
        this._cntryServive.postCountry(form.value).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 0) {
              this.toastr.success(data.Message, "Country")
              this._cntryServive.formHideShow = 0;
              this.resetForm(form);
              this.getcountry()
            }
            else {
              this.toastr.warning(data.Message, "Country")

            }
          });
      }
      else {
        this._cntryServive.putCountry(form.value).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 0) {
              this.toastr.success(data.Message, "Country")
              this._cntryServive.formHideShow = 0;
              this.resetForm(form);
              this.getcountry()
            }
            else {
              this.toastr.warning(data.Message, "Country")

            }
          });
      }

    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Country');
    }
  }

  getcountry() {
    this._cntryServive.getCountry().subscribe(data => this._cntryServive.allCountries = data,
      error => {
        this.errorMsg = error;
      });

  }


}
