<div class="container-fluid" *ngIf="_LeaveApplicationService.IsForm">
    <h2 class="heading_all">Leave Application</h2>
    <br>
    <div id="form-wrap">
        <form #leave="ngForm" (ngSubmit)="onSubmit(leave)">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Ref No:</label>
                        <input id="refNo" name="refNo" #refNo="ngModel"
                            [ngModel]="_LeaveApplicationService.SelectedData.RefNo" disabled autocomplete="off"
                            placeholder=" Ref No" required>
                        <div class="validation-error" *ngIf="refNo.invalid && refNo.touched">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Date:</label>
                        <input id="date" name="date" #date="ngModel"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.Date" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" disabled autocomplete="off"
                            placeholder="Date" required>
                        <div class="validation-error" *ngIf="date.invalid && date.touched">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Employee Code:</label>
                        <input id="empCode" name="empCode" #empCode="ngModel"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.EmpCode" disabled autocomplete="off"
                            placeholder="Employee Code" required>
                    </div>
                    <div class="form-group">
                        <label>Employee Name:</label>
                        <input id="empName" name="empName" #empName="ngModel"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.EmpName" disabled autocomplete="off"
                            placeholder="Employee Name" required>
                    </div>
                    <div class="form-group">
                        <label>Designation:</label>
                        <input id="designation" name="designation" #designation="ngModel"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.Designation" disabled autocomplete="off"
                            placeholder="Designation" required>
                    </div>
                    <div class="form-group">
                        <label>Leave Type <span class="validation-error">*</span>:</label>
                        <select id="leavet" name="leavet" #leavet="ngModel" style="width: 100%; padding: 5px;border-radius: 5px;"
                            [disabled]="LoginedUserId!=_LeaveApplicationService.SelectedData.EmpID || _LeaveApplicationService.SelectedData.Status!=0"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.LeaveTypeID">
                            <option [value]="0"> SELECT TYPE <span class="validation-error">*</span></option>
                            <option *ngFor="let field of _LeaveApplicationService.leaveTypeList" [value]="field.Id">
                                {{field.Name}} </option>
                        </select>
                        <div class="validation-error" *ngIf="leavet.invalid && leavet.touched">This Field is Required.</div>
                    </div>
                    <div class="row">
                        <div class="col-md-6" style="padding-left: 0;">
                            <div class="form-group">
                                <label>From <span class="validation-error">*</span>:</label>
                                <input id="beginDate" name="beginDate" #beginDate="ngModel"
                                    [(ngModel)]="_LeaveApplicationService.SelectedData.FromDate"
                                    [disabled]="LoginedUserId!=_LeaveApplicationService.SelectedData.EmpID || _LeaveApplicationService.SelectedData.Status!=0"
                                    bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" autocomplete="off"
                                    required (ngModelChange)="GetWorkSchedule('frm')">
                                <div class="validation-error" *ngIf="beginDate.invalid && beginDate.touched">This Field is Required.</div>
                            </div>
                        </div>
                        <div class="col-md-6" style="padding-right: 0;">
                            <div class="form-group">
                                <label>To <span class="validation-error">*</span>:</label>
                                <input id="toDate" name="toDate" #toDate="ngModel"
                                    [(ngModel)]="_LeaveApplicationService.SelectedData.ToDate"
                                    [disabled]="LoginedUserId!=_LeaveApplicationService.SelectedData.EmpID || _LeaveApplicationService.SelectedData.Status!=0"
                                    bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" autocomplete="off"
                                    required (ngModelChange)="GetWorkSchedule('To')">
                                <div class="validation-error" *ngIf="toDate.invalid && toDate.touched">This Field is Required.</div>
                            </div>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <input type="radio" [value]="1" name="full" id="full" #full="ngModel"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.Type"
                            [disabled]="LoginedUserId!=_LeaveApplicationService.SelectedData.EmpID || _LeaveApplicationService.SelectedData.Status!=0">
                        <label for="full">Full &nbsp;&nbsp;
                            &nbsp;&nbsp; &nbsp;&nbsp;</label>
                        <input type="radio" [value]="2" name="fn" id="fn" #fn="ngModel"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.Type"
                            [disabled]="LoginedUserId!=_LeaveApplicationService.SelectedData.EmpID || _LeaveApplicationService.SelectedData.Status!=0">
                        <label for="fn">FN &nbsp;&nbsp;
                            &nbsp;&nbsp; &nbsp;&nbsp;</label>
                        <input type="radio" [value]="3" name="AN" id="AN" #AN="ngModel"
                            [(ngModel)]="_LeaveApplicationService.SelectedData.Type"
                            [disabled]="LoginedUserId!=_LeaveApplicationService.SelectedData.EmpID || _LeaveApplicationService.SelectedData.Status!=0">
                        <label for="AN">AN &nbsp;&nbsp;
                            &nbsp;&nbsp; &nbsp;&nbsp;</label>
                    </div>
                </div>
                <div class="col-md-8">
                    <div style="text-align: center;">
                        <h4>Work Schedule</h4>
                    </div>
                    <div class="tWrap">
                        <div class="tWrap__head">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>JONO</th>
                                        <th>Start Date</th>
                                        <th>Comp Date</th>
                                        <th>Est.Hrs</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="tWrap__body">
                            <table>
                                <tbody>
                                    <tr
                                        *ngFor="let data of this._LeaveApplicationService.LeaveEmpWorkScheduleList;let i =index">
                                        <td> {{i+1}} </td>
                                        <td> {{data.JONO}} </td>
                                        <td> {{data.Statdate}} </td>
                                        <td>{{data.CompDate}}</td>
                                        <td>{{data.EstHr}}</td>
                                    </tr>
                                    <tr *ngIf="_LeaveApplicationService.LeaveEmpWorkScheduleList?.length<=0">
                                        <td colspan="3">
                                            No Record Found.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <h4>Consumed leave Details</h4>
                    </div>
                    <!-- <div class="tWrap"> -->
                        <!-- <div class="tWrap__head"> -->
                            <table class="tablepl">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Leave</th>
                                        <th>All.</th>
                                        <th>Con.</th>
                                        <th>Bal.</th>
                                    </tr>
                                </thead>
                            <!-- </table> -->
                        <!-- </div> -->
                        <!-- <div class="tWrap__body"> -->
                            <!-- <table class="tablepl"> -->
                                <tbody>
                                    <tr
                                        *ngFor="let data of _LeaveApplicationService.EmpLeaveBalanceDetails;let i=index">
                                        <td>{{i+1}}</td>
                                        <td>{{data.Leave}}</td>
                                        <td>{{data.All}}</td>
                                        <td>{{data.Consumed}}</td>
                                        <td>{{data.Balance}}</td>
                                    </tr>
                                    <tr *ngIf="_LeaveApplicationService?.EmpLeaveBalanceDetails.length<1">
                                        <td colspan="4">
                                            No Records To Display
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <textarea id="desc" name="desc" #desc="ngModel"
                        [(ngModel)]="_LeaveApplicationService.SelectedData.Description"
                        style="width: 100%; padding: 10px;" rows="2" placeholder="Description"
                        [disabled]="LoginedUserId!=_LeaveApplicationService.SelectedData.EmpID || _LeaveApplicationService.SelectedData.Status!=0"></textarea>
                </div>
            </div>
            <!-- FOR ADMINS  -->
            <div class="row" *ngIf="_LeaveApplicationService.SelectedData.ID>0">
                <div class="col-md-12">
                    <div class="tWrap">
                        <div class="tWrap__head">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Approver</th>
                                        <th> Status</th>
                                        <th> Reason</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="tWrap__body">
                            <table>
                                <tbody>
                                    <tr
                                        *ngFor=" let data of _LeaveApplicationService.SelectedData.LeaveApproverDetails; let i= index">
                                        <td>{{i+1}}</td>
                                        <td>{{data.ApproverName}}</td>
                                        <td [ngClass]="{'Approved':data.Status==1,'Rejected':data.Status==2}">
                                            <div *ngIf="LoginedUserId!=data.ApproverID">
                                                <!-- <button class="tblbtn tblbtn-green " *ngIf="data.Status==2"    > Approved</button> 
                                                <button class=" tblbtn tblbtn-red"   *ngIf="data.Status==1"    > Rejected</button> -->
                                                <div style="text-align: center;">
                                                    {{data.Status==2?'Rejected':data.Status==1?'Approved':'Pending'}}
                                                </div>
                                            </div>
                                            <div *ngIf="LoginedUserId==data.ApproverID">
                                                <button class="tblbtn tblbtn-green " type="button"
                                                    [ngClass]="{'fade': data.Status==2}" (click)="onApprover(data)">
                                                    {{data.Status==1?'Approved':'Approve'}}</button>
                                                <button class=" tblbtn tblbtn-red" type="button"
                                                    [ngClass]="{'fade': data.Status==1}"
                                                    (click)="onReject(data)">{{data.Status==2?'Rejected':'Reject'}}
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <div *ngIf="LoginedUserId!=data.ApproverID" style="text-align: center;">
                                                {{data.Reason}}
                                            </div>
                                            <textarea *ngIf="LoginedUserId==data.ApproverID"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="data.Reason"
                                                id="Reason" name="Reason" style="width: 100%; padding: 10px;" rows="2"
                                                placeholder="Reason"></textarea>
                                            <br>
                                            <span style="font-size: small;
                                                float: right;">
                                                {{data.ResponsDate}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="_LeaveApplicationService.SelectedData.LeaveApproverDetails?.length<=0">
                                        <td colspan="3"> No Records To Display.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="submit"
                            [disabled]="!_LeaveApplicationService.giveEditPermission" class="sim-button button1">Submit
                        </button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                            [disabled]="!_LeaveApplicationService.giveEditPermission"
                            (click)="resetForm(leave)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>