<div class="container-fluid">
    <!-- <h2 class="heading_all">Activity Completion Time Bound</h2> -->
    <h2 class="heading_all">Settings</h2>
    <div class="p-5 bg-white rounded shadow mb-5">
        <ul id="myTab2" role="tablist"
            class="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center">
            <li class="nav-item flex-sm-fill">
                <a id="home2-tab" data-toggle="tab" href="#home2" role="tab" aria-controls="home2" aria-selected="true"
                    class="nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0 active">Target</a>
            </li>
            <li class="nav-item flex-sm-fill">
                <a id="profile2-tab" data-toggle="tab" href="#profile2" role="tab" aria-controls="profile2"
                    aria-selected="false" class="nav-link text-uppercase font-weight-bold mr-sm-3 rounded-0">General</a>
            </li>
        </ul>
        <div id="myTab2Content" class="tab-content">
            <!-- py-5 px-4 -->
            <div id="home2" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade show active">
                <form #timeSettingsForm="ngForm" (ngSubmit)="onSubmit(timeSettingsForm)">
                    <div id="form-wrap">
                        <div class="row">
                            <div class="col-md-12">
                                <br>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th title="Activity Request Type" scope="col" style="text-align: center;" rowspan="2">Activity Request Type</th>
                                            <th scope="col" style="text-align: center;" colspan="2">Urgent</th>
                                            <th scope="col" style="text-align: center;" colspan="2">Very Urgent</th>
                                            <th scope="col" style="text-align: center;" colspan="2">Critical</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">Dealer</th>
                                            <th scope="col">Provider</th>
                                            <th scope="col">Dealer</th>
                                            <th scope="col">Provider</th>
                                            <th scope="col">Dealer</th>
                                            <th scope="col">Provider</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">Error</th>
                                            <td>
                                                <input name="edu" style="width: 70px;"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.EDU"
                                                    type="number"
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.EDU "
                                                    placeholder="Time">
                                            </td>
                                            <td>
                                                <input name="epu" style="width: 70px;"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.EPU "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.EPU"
                                                    placeholder="Time" type="number">
                                            </td>
                                            <td>
                                                <input name="edv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.EDV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.EDV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="epv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.EPV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.EPV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="edc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.EDC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.EDC"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="epc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.EPC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.EPC"
                                                    type="number">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Modification</th>
                                            <td>
                                                <input name="mdu" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.MDU "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.MDU"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="mpu" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.MPU "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.MPU"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="mdv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.MDV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.MDV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="mpv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.MPV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.MPV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="mdc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.MDC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.MDC"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="mpc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.MPC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.MPC"
                                                    type="number">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Design</th>
                                            <td>
                                                <input name="ddu" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.DDU "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.DDU"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="dpu" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.DPU "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.DPU"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="ddv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.DDV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.DDV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="dpv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.DPV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.DPV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="ddc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.DDC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.DDC"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="dpc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.DPC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.DPC"
                                                    type="number">
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Training </th>
                                            <td>
                                                <input name="tdu" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.TDU "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.TDU"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="tpu" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.TPU "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.TPU"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="tdv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.TDV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.TDV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="tpv" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.TPV "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.TPV"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="tdc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.TDC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.TDC"
                                                    type="number">
                                            </td>
                                            <td>
                                                <input name="tpc" style="width: 70px;" placeholder="Time"
                                                    [value]="this._timeSettingsService.selectedTimeSettings.TPC "
                                                    [(ngModel)]="_timeSettingsService.selectedTimeSettings.TPC"
                                                    type="number">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <button type="submit" class="sim-button button1">Submit</button>
                        </div>
                    </div>
                </form>
            </div>

            <div id="profile2" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade  py-5">
                <!-- px-4 -->
                <div id="form-wrap">
                    <!-- <h1>Hi All</h1> -->
                    <form #countryForm="ngForm" (ngSubmit)="onSubmitDate(countryForm)">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label>Date<span class="span">*</span></label>
                                        <input class="section3" placeholder="SettingsDate" name="SettingsDate"
                                            #SettingsDate="ngModel" [(ngModel)]="_timeSettingsService.selectedGeneralSettings.DefaultDate" autocomplete="off"
                                            bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">       
                                    </div>
                                    <div class="col-md-2">
                                        <label>Key Expiry Remainder Days<span class="span">*</span></label>
                                        <input type="number" placeholder="KeyExpiryRemainderDays" name="KeyExpiryRemainderDays" #KeyExpiryRemainderDays="ngModel" [(ngModel)]="_timeSettingsService.selectedGeneralSettings.KeyExpiryRemainderDays" autocomplete="off">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Decimal Places<span class="span">*</span></label>
                                        <input type="number" placeholder="DecimalPlaces" name="Decimal Places" #DecimalPlaces="ngModel" [(ngModel)]="_timeSettingsService.selectedGeneralSettings.DecimalPlaces" autocomplete="off">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Period Start Date<span class="span">*</span></label>
                                        <input class="section3" placeholder="Period Start Date" name="PeriodStartDate"
                                            #PeriodStartDate="ngModel" [(ngModel)]="_timeSettingsService.selectedGeneralSettings.PeriodStartDate" autocomplete="off"
                                            bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">    
                                    </div>
                                    <div class="col-md-2">
                                        <label>Administration %<span class="span">*</span></label>
                                        <input type="text" placeholder="Administration Percentage" name="AdministrationPercent" #AdministrationPercent="ngModel" 
                                           (keypress)="_CommonService.decimalPointValidation($event)" [(ngModel)]="_timeSettingsService.selectedGeneralSettings.AdministrationPercentage" autocomplete="off">
                                    </div>
                                    <div class="col-md-2">
                                        <button style="width:100%; margin-top: 25px;" type="submit"
                                            class="sim-button button1">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>