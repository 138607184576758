import { error } from '@angular/compiler/src/util';
import { CSCancellation } from './../../../../../../Models/CloudCharge';
import { CscancellationComponent } from './../cscancellation.component';
import { CloudChargeService } from 'src/app/Services/cloud-charge.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-cscancellation-list',
  templateUrl: './cscancellation-list.component.html',
  styleUrls: ['./cscancellation-list.component.css']
})
export class CscancellationListComponent implements OnInit {

  public errorMsg:string
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  NoOfItems: number = 10;

  constructor(public cloudChargeService:CloudChargeService,
              public toastrService:ToastrService,
              public commonService:CommonService) { }

  ngOnInit(): void {
    this.cloudChargeService.formHideShow = 0;
    this.getAllCsCancellation();
  }

  addList()
  {
    this.cloudChargeService.formHideShow=1;
  }

  toggleFilter()
  {
    this.cloudChargeService.isFiltering = this.cloudChargeService.isFiltering ? false : true;
    if (!this.cloudChargeService.isFiltering) {
      this.cloudChargeService.ClearFilter();
      this.commonService.pageIndex = 1;
      this.cloudChargeService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this.cloudChargeService.orderByColName) {
      this.cloudChargeService.orderByDir = !this.cloudChargeService.orderByDir;
    }
    this.cloudChargeService.orderByColName = colName;
  }

  getAllCsCancellation()
  {
    this.cloudChargeService.getCsCancellation().subscribe(
      data=>{
        this.cloudChargeService.allCSCancellation=data;
      },error=>{this.errorMsg}
    );
  }

  showForEdit(obj:CSCancellation)
  {
    this.cloudChargeService.selectedCSCancellation=Object.assign({},obj);
    this.cloudChargeService.formHideShow = 1;
  }

  onDelete(id:number)
  {
    this.deleteID = id;
    this.deletePopUp = true;
  }

  dialogResult(event)
  {
    this.deletePopUp = false;
    if(event)
    {
      this.cloudChargeService.deleteCsCancellation(this.deleteID)
      .subscribe(data => {
        if(data.Status==200)
          {
            this.toastrService.success(data.Message, "CsCancellation");
            this.cloudChargeService.getCsCancellation().subscribe(data => this.cloudChargeService.allCSCancellation = data,
              error => {
                this.errorMsg = error;
                this.toastrService.error(data.Message, "CsCancellation");
              });
          }           
      }, error => {
        this.errorMsg = error;
        this.toastrService.warning("Deletion Failure", "CsCancellation");
      })
    }
  }

}
