<div class="container-fluid" *ngIf="_desigServive.formHideShow!=0">

    <h2 class="heading_all">DESIGNATION</h2>
    <br>
    <div id="form-wrap">
        <form #designationForm="ngForm" (ngSubmit)="onSubmit(designationForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_desigServive.selectedDesignation.Id">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">

                        <label>Code<span style="color: red;">*</span></label>

                        <input name="Code" #Code="ngModel" autocomplete="off"
                            [(ngModel)]="_desigServive.selectedDesignation.Code" placeholder="" required>
                        <div class="validation-error"
                            *ngIf="(Code.invalid && Code.touched)||(Code.invalid &&this.FormValidation)">This Field is
                            Required.</div>

                    </div>
                    <div class="form-group">
                        <label>Name<span style="color: red;">*</span></label>

                        <input name="Name" #Name="ngModel" autocomplete="off"
                            [(ngModel)]="_desigServive.selectedDesignation.Name" placeholder=" Name" required>
                        <div class="validation-error"
                            *ngIf="(Name.invalid && Name.touched)||(Name.invalid &&this.FormValidation)">This Field is
                            Required.</div>

                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <button style="width: 99%; margin-top: 25px;" [disabled]="this.isSubmit" type="submit"
                                class="sim-button button1">Submit</button>
                        </div>
                        <div class="col-md-4">
                            <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                                (click)="resetForm(designationForm)">Reset</button>
                        </div>
                        <div class="col-md-4">
                            <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3"
                                (click)="backList()">Exit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- </div>
    </div> -->
</div>

<br>