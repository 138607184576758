import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DealerDashboardComponent } from 'src/app/DashBoard/dealer-dashboard/dealer-dashboard.component';
import { EmployeeComponent } from '../Masters/employee/employee.component';
import { BranchComponent } from './branch/branch.component';
import { CustomerComponent } from './customer/customer.component';
import { RouteMasterComponent } from './route-master/route-master.component';
import { AmcRenewalComponent } from './amc-renewal/amc-renewal.component';
import { SoftwareRegistrationComponent } from './software-registration/software-registration.component';
import { NodeRegComponent } from './node-reg/node-reg.component';
import { DesignationComponent } from '../Masters/designation/designation.component';
import { ServiceHistoryComponent } from './service-history/service-history.component';
import { BranchSettingsComponent } from '../Dealers/branch-settings/branch-settings.component';

const routes: Routes = [
  { path: '', component: DealerDashboardComponent },  
   { path: 'employee', component: EmployeeComponent },
  { path: 'branch', component: BranchComponent },
  { path: 'branchsettings', component: BranchSettingsComponent},
  { path: 'customer', component: CustomerComponent  },
  { path: 'routemaster', component: RouteMasterComponent  },
  { path: 'amcrenewal', component: AmcRenewalComponent },
  { path: 'softwareregistration', component: SoftwareRegistrationComponent },
  { path: 'noderegistration', component: NodeRegComponent },
  { path: 'designation', component: DesignationComponent  },
  { path: 'servicehistory', component: ServiceHistoryComponent  },


]
@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes),
  ]
})
export class DealerModule { }
