import { Injectable } from '@angular/core';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { softwareRegistration, SoftwareRegSystemDtls, SoftwareRegModules, SoftwareRegistrationAttachments,SoftwareRegPaymentTerms} from '../Models/softwareRegistration.model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { ModuleService } from './module.service';
import { AppConstants } from '../Models/constants';
import { Common, Response } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class SoftwareRegistrationService {

  selectedSoftwareRegistration: softwareRegistration;
  softwareRegistrationList: softwareRegistration[];
  formHideShow = 0;
  formHidePassword = false;
  allSoftwareRegistrations: softwareRegistration[];
  allSystemDetails: SoftwareRegSystemDtls[];
  allSoftwareRegPaymentTerms: SoftwareRegPaymentTerms[];
  allModuleDetails: SoftwareRegModules[];
  imageUrl: string = AppConstants.tockenURL + "Images/Logo/default.png";
  moduleList: number[] = [];
  tblVisible = 0;
  checkboxVisible = 0;
  allSRRefno: softwareRegistration[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  RefNoFilter: string = '';
  CustomerFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  FormUpdation: boolean = false
  RowCount: any=0;
  public newAttribute: any = {};
  ServerNameVisible = 0;
  allServer:Common[]=[];

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService, public _ModuleService: ModuleService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.RefNoFilter = '';
    this.CustomerFilter = '';
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getSoftwareRegistration(): Observable<softwareRegistration[]> {
    return this.http.get<softwareRegistration[]>(ROOT_URL + 'SoftwareRegistration/GetAllSoftwareRegistration')
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  SaveItem(swReg: softwareRegistration, allSystemDetails: SoftwareRegSystemDtls[], fileToUpload: File[],allSoftwareRegPaymentTerms: SoftwareRegPaymentTerms[]): Observable<any> {
    let headers = new HttpHeaders().set('contentType', 'false');
    console.log("date", swReg.StartDate,swReg.EndDate)
    var body = {
      Id: swReg.Id,
      CountryId: swReg.CountryId,
      DealerId: swReg.DealerId,
      RefNo: swReg.RefNo,
      CustomerId: swReg.CustomerId,
      ProjectId: swReg.ProjectId,
      BPEId: swReg.BPEId,
      ContractDate:this.convert(swReg.ContractDate),
      //FileName: fileToUpload,
      SoftwareRegistrationAttachments: swReg.SoftwareRegistrationAttachments,
      SoftwareRegistrationAttachmentRemovedList: swReg.SoftwareRegistrationAttachmentRemovedList,
      CentralizedServer: swReg.CentralizedServer,
      LocalServer: swReg.LocalServer,
      NetServer: swReg.NetServer,
      ServerDetails: swReg.ServerDetails,
      SoftwareRegSystemDtlsList: allSystemDetails,
      selectedModules: JSON.stringify(this.moduleList),

      ReportServer: swReg.ReportServer,
      ServerName:swReg.ServerName,
      Rent:swReg.Rent,
      FreeFor:swReg.FreeFor,
      Expiry:this.convert(swReg.Expiry),
      Notes:swReg.Notes,
      SoftwareCost:swReg.SoftwareCost,
      SoftwareRegPaymentTermsList:allSoftwareRegPaymentTerms,
      TermsConditions:swReg.TermsConditions,
      TermID:swReg.TermID??0,
      ServerID:swReg.ServerID??0,
      StartDate:swReg.StartDate?.toString()?this.convert(swReg.StartDate):null,
      EndDate:swReg.EndDate?.toString()?this.convert(swReg.EndDate):null
    }
    const formData: FormData = new FormData();
    if (fileToUpload) {
      for (var i = 0; i < fileToUpload.length; i++) {
        formData.append('Document', fileToUpload[i]);
      }
    }

    formData.append('obj', JSON.stringify(body));
    let params = new HttpParams();
    params = params.append('TransMode', this.FormUpdation == false ? 'SAVE' : 'UPDATE')
    return this.http.post<Response>(ROOT_URL + 'SoftwareRegistration/InsUpdDelSoftwareRegistration', formData, {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  deleteSoftwareRegistration(DeleteID) {
    return this.http.delete<softwareRegistration>(ROOT_URL + 'SoftwareRegistration/DeleteSoftwareRegistration/' + DeleteID)
  }

  getAllModuleByRegId(Id: number) {
    return this.http.get<any>(ROOT_URL + 'SoftwareRegistration/getAllModuleByRegId?Id=' + Id + '')
  }

  FetchSoftRegDtls(Id: number, TransMode: string, CustomerId: number): Observable<SoftwareRegSystemDtls[]> {
    let params = new HttpParams();
    params = params.append('Id', Id.toString()).append('TransMode', TransMode).append('CustomerId', CustomerId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<SoftwareRegSystemDtls[]>(ROOT_URL + 'SoftwareRegistration/FetchSoftRegDtls', {
      headers, params
    })
      .pipe(

      );
  }

  FetchSoftRegAttachments(Id: number): Observable<SoftwareRegistrationAttachments[]> {
    let params = new HttpParams();
    params = params.append('Id', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<SoftwareRegistrationAttachments[]>(ROOT_URL + 'SoftwareRegistration/FetchSoftRegAttachments', {
      headers, params
    })
      .pipe(

      );
  }

  UploadImage(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload, fileToUpload.name);
    return this.http.post<any>(ROOT_URL + 'SoftwareRegistration/UploadImage', formData)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getSoftwareregRefnoByCustomer(CustomerId: number): Observable<softwareRegistration[]> {
    return this.http.get<softwareRegistration[]>(ROOT_URL + 'SoftwareRegistration/GetAllReferenceNoByCusomerId?CustomerId=' + CustomerId)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  FetchSoftwareRegPaymentTermsList(Id: number): Observable<SoftwareRegPaymentTerms[]> {
    let params = new HttpParams();
    params = params.append('Id', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<SoftwareRegPaymentTerms[]>(ROOT_URL + 'SoftwareRegistration/FetchSoftwareRegPaymentTermsList', {
      headers, params
    })
      .pipe(

      );
  }
}
