import { CustomerService } from './customer.service';
import { DealerService } from './dealer.service';
import { Injectable } from '@angular/core';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { ROOT_URL } from '../Models/Config';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { CustomerOutstanding,Income,WorkForecasting,Month,WorkAllocatedDates,BranchWiseModuleDetails } from '../Models/reports';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  public errorMsg:string
  selectedDate:string
  selectedToDate:string
  selectedEmployeeId:number
  alloption={Id:0,Name:"All"}
  customerOutstanding:CustomerOutstanding[]=[];
  incomedetails:Income[]=[];
  isFiltering: boolean = false;
  dealerFilter: string = '';
  customerFilter: string = '';
  typeFiter:string='';
  allCustomerList:any[]=[];
  type:number;
  allBranchWiseModuleDtls:BranchWiseModuleDetails;

  workForecastingDetails:WorkForecasting;
  isViweEnabled:boolean=false;
  selectedDealerId:number=0;

  constructor(private http: HttpClient,
              public globalEventHandlers:GlobalErrorHandlerService,
              public dealerService:DealerService,
              public customerService:CustomerService) { }

  convertWithTime(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let result = [date.getFullYear(), mnth, day].join("-");
    return result + ' ' + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  ClearFilter(type)
  {
    if(type==1)
    {
      this.dealerFilter = '';
      this.customerFilter='';
      this.typeFiter='';
    }
    this.isFiltering=false;
  }

  toggleFilter(type) {
    if(type==1)
    {
      this.isFiltering = this.isFiltering ? false : true;
      if (!this.isFiltering) 
        this.ClearFilter(type);
    }
  }

  loadDealer() {
    this.dealerService.FetchDealer('DEALERIDWISE')
      .subscribe(
        data => {
          this.dealerService.allDealers = data; 
          if(this.dealerService.allDealers!=undefined && this.dealerService.allDealers.length==1)
            this.selectedDealerId= this.dealerService.allDealers[0].Id;
          else
            this.selectedDealerId=0;
        },error => {
          this.errorMsg = error;
        });
  }

  loadCustomer()
  {
    this.customerService.FetchAllCustomer('ALL').subscribe(
      data=>{
        this.customerService.allCustomers=data;
        this.allCustomerList=data;
      }
      ,error => {
        this.errorMsg = error;
      });
  }

  getCustomerOutstanding(age:number,voucherType:number)
  {
    let params=new HttpParams();
    params=params.append('Age',age.toString());
    params=params.append('DealerId',this.selectedDealerId.toString())
    params=params.append('VoucherType',voucherType.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<CustomerOutstanding[]>(ROOT_URL+'reports/GetCustomerOutstanding',{
      params,headers
    })
    .pipe(
      catchError((error) => this.globalEventHandlers.handleError(error))
    );
  }

  getIncomeDetails()
  {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params=new HttpParams();
    params=params.append('FromDate',this.selectedDate==undefined?'':this.convertWithTime(this.selectedDate));
    params=params.append('ToDate',this.selectedToDate==undefined?'':this.convertWithTime(this.selectedToDate));
    params=params.append('DealerId',this.dealerFilter==''?'0':this.dealerFilter);
    params=params.append('CustomerId',(this.customerFilter=='' || this.customerFilter==null)?'0':this.customerFilter);
    params=params.append('TypeId',this.typeFiter==''?'0':this.typeFiter);
    return this.http.get<Income[]>(ROOT_URL+'reports/GetIncomeDetails',{
      params,headers
    })
    .pipe(
      catchError((error) => this.globalEventHandlers.handleError(error))
    );
  }

  systemDetailsTrans(transType,moduleCode,nodeRegId)
  {
    let params=new HttpParams();
    params=params.append('TransType',transType)
    params=params.append('DealerId',this.dealerFilter==''?'0':this.dealerFilter);
    params=params.append('CustomerId',(this.customerFilter=='' || this.customerFilter==null)?'0':this.customerFilter);
    params=params.append('Type',this.type.toString());
    params=params.append('NodeRegId',nodeRegId);
    params=params.append('ModuleCode',moduleCode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<any>(ROOT_URL+'reports/SystemDetailsTrans',{
      params,headers
    })
    .pipe(
      catchError((error) => this.globalEventHandlers.handleError(error))
    );
  }

  getWorkForecasting(EmpId)
  {
    let params=new HttpParams();
    params=params.append('EmpId',EmpId);
    params=params.append('Date',this.convertWithTime(this.selectedDate.toString()));
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<CustomerOutstanding[]>(ROOT_URL+'reports/GetWorkForecasting',{
      params,headers
    })
    .pipe(
      catchError((error) => this.globalEventHandlers.handleError(error))
    );
  }
}

