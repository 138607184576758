import { Injectable } from '@angular/core';
import { nodeHDR, nodeDTL } from '../Models/node.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { softwareRegistration } from '../Models/softwareRegistration.model';
import { AMCRenewal } from '../Models/AMCRenewal.model';

@Injectable({
  providedIn: 'root'
})
export class NodeRegistrationService {

  selectedNodeRegistration: nodeHDR;
  // nodeRegistrationList: nodeHDR[];
  formHideShow = 0;
  tblVisible = 0;
  allNodeRegistrations: nodeHDR[];
  allNodeRegistrationDTLs: nodeDTL[]
  public currentStringDateToBind = new Date();
  public currentStringDate: any;

  imageUrl: string = "";
  public newAttribute: any = {};

  pageIndex: number = 0;
  isFiltering: boolean = false;
  RefNoFilter: string = '';
  DateFilter: string = '';
  CustomerFilter: string = '';
  BranchFilter: string = '';
  SRNoFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';



  constructor(private http: HttpClient, private _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.RefNoFilter = '';
    this.DateFilter = '';
    this.CustomerFilter = '';
    this.BranchFilter = '';
    this.SRNoFilter = '';
  }


  postNodeRegistration(swReg: nodeHDR) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Id: 0,
      CountryId: swReg.CountryId,
      DealerId: swReg.DealerId,
      // RefNo: swReg.RefNo,
      CurDate: this.currentStringDate,
      // SRNo: swReg.SRNo,
      CustomerId: swReg.CustomerId,
      BranchId: swReg.BranchId,
      ProjectId: swReg.ProjectId,
      NodeRegistrationDTLs: this.allNodeRegistrationDTLs
    }

    return this.http.post<nodeHDR>(ROOT_URL + 'NodeRegistration/PostNodeReg', body)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  putNodeRegistration(swReg: nodeHDR) {
    const params = new HttpParams().set('ID', swReg.Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      CountryId: swReg.CountryId,
      DealerId: swReg.DealerId,
      // RefNo: swReg.RefNo,
      CurDate: this.currentStringDate,
      // SRNo: swReg.SRNo,
      CustomerId: swReg.CustomerId,
      ProjectId: swReg.ProjectId,
      BranchId: swReg.BranchId,
      NodeRegistrationDTLs: this.allNodeRegistrationDTLs,
      ID: swReg.Id
    }
    return this.http.put<nodeHDR>(ROOT_URL + 'NodeRegistration/PutNodeReg/' + swReg.Id, body, {
      headers,
      params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }
  getNodeRegistration(): Observable<nodeHDR[]> {
    // console.log('',DealerId)
    return this.http.get<nodeHDR[]>(ROOT_URL + 'NodeRegistration/GetAllNodeReg')
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  deleteNodeRegistration(DeleteID) {
    return this.http.delete<nodeHDR>(ROOT_URL + 'NodeRegistration/DeleteNodeReg/' + DeleteID)
  }


  LoadSoftRegDtls(custId: number, BranchId: number, ProjectId: number): Observable<any> {
    return this.http.get<softwareRegistration>(ROOT_URL + 'NodeRegistration/LoadSoftRegDtls?CustId=' + custId + '&BranchId=' + BranchId + '&ProjectId=' + ProjectId)

  }


  LoadRegisteredSystemDetails(custId: number, BranchId: number, ProjectId: number): Observable<nodeDTL[]> {
    return this.http.get<nodeDTL[]>(ROOT_URL + 'NodeRegistration/LoadRegisteredSystemDetails?CustId=' + custId + '&BranchId=' + BranchId + '&ProjectId=' + ProjectId)

  }

}
