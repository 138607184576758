<div class="container-fluid" *ngIf="_empService.formHideShow!=0">
    <h2 class="heading_all">EMPLOYEE</h2>
    <br>
    <div id="form-wrap">
        <form #employeeForm="ngForm" (ngSubmit)="onSubmit(employeeForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_empService.selectedEmployee.Id">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Employee Id<span class="validation-error">*</span></label>
                        <input name="EmpId" #EmpId="ngModel" autocomplete="off"
                            [(ngModel)]="_empService.selectedEmployee.EmpId"  required>
                        <div class="validation-error"
                            *ngIf="(EmpId.invalid && EmpId.touched)||(EmpId.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Employee Name<span class="validation-error">*</span></label>
                        <input autocomplete="off" name="EmpName" #EmpName="ngModel"
                            [(ngModel)]="_empService.selectedEmployee.EmpName" required>
                        <div class="validation-error"
                            *ngIf="(EmpName.invalid && EmpName.touched)||(EmpName.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <!-- *ngIf="!_empService.editDesignation" -->
                    <div class="form-group">
                        <label>Designation<span class="validation-error">*</span></label>
                        <select class="form-control" name="Designation" #Designation="ngModel"
                            [(ngModel)]="_empService.selectedEmployee.Designation" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _empService.allDesignations | orderBy : 'Name'"
                                [ngValue]="prf.Id">
                                {{ prf.Name }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(Designation.invalid && Designation.touched)||(Designation.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Address<span class="validation-error">*</span></label>

                        <input autocomplete="off" name="Addr1" #Addr1="ngModel"
                            [(ngModel)]="_empService.selectedEmployee.Addr1" required>
                        <div class="validation-error"
                            *ngIf="(Addr1.invalid && Addr1.touched)||(Addr1.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Email<span class="validation-error">*</span></label>
                        <input autocomplete="off" name="Email" #Email="ngModel"  [(ngModel)]="_empService.selectedEmployee.Email" [pattern]="_CommonService.emailpattern" required>
                        <div class="validation-error" *ngIf="(Email.invalid && Email.touched)||(Email.invalid && this.FormValidation)">Email is Required.</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Phone No<span class="validation-error">*</span></label>
                        <input autocomplete="off" name="Phone" #Phone="ngModel" [(ngModel)]="_empService.selectedEmployee.Phone" (keypress)="_CommonService.mobNumberValidation($event)" minlength="8" maxlength="14" required>
                        <div class="validation-error"  *ngIf="(Phone.invalid && Phone.touched)||(Phone.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>Hourly Rate</label>
                        <input autocomplete="off" name="HourlyRate" #HourlyRate="ngModel" [(ngModel)]="_empService.selectedEmployee.HourlyRate" (keypress)="_CommonService.mobNumberValidation($event)" required>
                    </div>
                    <div *ngIf="!_empService.formHidePassword" class="form-group">
                        <label>Username<span class="validation-error">*</span></label>
                        <input name="Username" #Username="ngModel" autocomplete="off"
                            [(ngModel)]="_empService.selectedEmployee.Username"  required>
                        <div class="validation-error" *ngIf="(Username.invalid && Username.touched)||(Username.invalid && this.FormValidation)"> This Field is Required.</div>
                    </div>
                    <div *ngIf="!_empService.formHidePassword" class="form-group">
                        <label> Password<span class="validation-error">*</span></label>
                        <input type="password" name="Password" #Password="ngModel" autocomplete="off" BlockPaste
                            [(ngModel)]="_empService.selectedEmployee.Password" minlength="6" required>
                        <div class="validation-error"  *ngIf="(Password.invalid && Password.touched)||(Password.invalid && this.FormValidation)"> This Field is Required(Minimum length is Six)</div>
                    </div>
                    <div *ngIf="!_empService.formHidePassword" class="form-group">
                        <label>Confirm Password<span class="validation-error">*</span></label>
                        <input type="password" name="ConfirmPassword" autocomplete="off" #ConfirmPassword="ngModel"
                        BlockPaste  [(ngModel)]="_empService.selectedEmployee.ConfirmPassword" required>
                        <div class="validation-error" *ngIf="(ConfirmPassword.invalid && ConfirmPassword.touched)||(ConfirmPassword.invalid && this.FormValidation)">This Field is Required.</div>
                    </div>
                    <div class="validation-error" *ngIf="(_empService.selectedEmployee.Password!=_empService.selectedEmployee.ConfirmPassword)">Password Mismatching</div>
                    <div *ngIf="this._empService.formHidePassword" (click)="changepwd()" class="form-group">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button1">
                            Change Password
                        </button>
                    </div>
                    <br>
                    <div class="form-group">
                        <input type="checkbox" style="width: auto;" [(ngModel)]="_empService.selectedEmployee.InActive"
                            [checked]="_empService.selectedEmployee.InActive" name="InActive"
                            value="_empService.selectedEmployee.InActive">
                        <label>InActive</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label style="color: teal;padding-left:7px;padding-top: 10px;">Leave Approved By :</label><br>
                </div>
                <div class="col-md-10">
                    <select name="emp" id="emp" style="width: 100%; padding: 10px;" #emp="ngModel"
                        [(ngModel)]="SelectedApproverIndex">
                        <option [value]="null">SELECT APPROVER</option>
                        <ng-container *ngFor="let data of this._empService.allEmployees;let i=index">
                            <option [value]="i">{{data.EmpName}} / {{data.DesignationName}}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-md-2">
                    <a style="float:right;float:right;margin:0;height:45px; " class="btn btn-primary"
                        (click)="AddtoApproverList()"><i class="fas fa-plus"></i></a>
                </div>
            </div>
            <br>
            <!-- <table> -->
            <div class="tWrap">
                <div class="tWrap__head">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Settings</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <!--/.tWrap__head-->
                <div class="tWrap__body">
                    <table>
                        <tbody>
                            <tr *ngFor="let data of this._empService.selectedEmployee.EmployeeApprover;let i =index">
                                <td> {{i+1}} </td>
                                <td> {{data.ApproverName}} </td>
                                <td> {{data.Designation}} </td>
                                <td> <button type="button" class="btn-danger" (click)="removeFormApproverList(i)"><i
                                            class="fas fa-trash" aria-hidden="true"></i></button> </td>
                            </tr>
                            <tr *ngIf="this._empService.selectedEmployee.EmployeeApprover?.length<=0">
                                <td colspan="3">
                                    No Records found.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--/.tWrap__body-->
            </div>
            <!-- </table> -->
            <div class="row">
                <div class="col-md-4">
                    <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                        class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                        (click)="resetForm(employeeForm)">
                        Reset
                    </button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                        (click)="backList()">Exit</button>
                </div>
            </div>
        </form>
    </div>

</div>
<div class="container-fluid">
    <!-- The Modal -->
    <div class="modal fade show" id="myModalConfirm">
        <div class="modal-dialog">
            <div *ngIf="changePassword">
                <!--class="modal-dialog" -->
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <span class="modal-title">Change Password</span>
                        <button type="button" class="close" (click)="OnClickCancel()">×</button>
                    </div>
                    <div class="modal-body" style="overflow: auto;height: auto;">
                        <div class="tab-content">
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-6">
                                    <!-- <div class="form-group row"> -->
                                    <div class="form-group">
                                        <label>Username<span class="validation-error">*</span></label>
                                        <input name="Username" #Username="ngModel" autocomplete="off"
                                            [(ngModel)]="_empService.selectedEmployee.Username"  required>
                                        <div class="validation-error"
                                            *ngIf="(Username.invalid && Username.touched)||(Username.invalid && this.FormValidation)">
                                            This Field is Required.</div>
                                    </div>
                                    <div class="form-group">
                                        <label>Current Password<span class="validation-error">*</span></label>
                                        <input type="password" name="CurrentPassword" #CurrentPassword="ngModel"
                                            autocomplete="off" [(ngModel)]="_empService.CurrentPassword" required>
                                    </div>
                                    <div class="form-group">
                                        <label> Password<span class="validation-error">*</span></label>
                                        <input type="password" name="Password" #Password="ngModel" autocomplete="off"
                                            [(ngModel)]="_empService.selectedEmployee.Password"  required>
                                        <div class="validation-error"
                                            *ngIf="(Password.invalid && Password.touched)||(Password.invalid && this.FormValidation)">
                                            This Field is Required.</div>
                                    </div>
                                    <div class="form-group">
                                        <label>Confirm Password<span class="validation-error">*</span></label>
                                        <input type="password" name="ConfirmPassword" autocomplete="off"
                                            #ConfirmPassword="ngModel"
                                            [(ngModel)]="_empService.selectedEmployee.ConfirmPassword" required>
                                        <div class="validation-error"
                                            *ngIf="(ConfirmPassword.invalid && ConfirmPassword.touched)||(ConfirmPassword.invalid && this.FormValidation)">
                                            This Field isRequired.</div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <div class="col-md-3"></div>
                            </div>
                            <div class="row">
                                <div class="col-md-3"></div>
                                <div class="col-md-4">
                                    <button type="button" (click)="OnClickCancel()"
                                        style="width: 99%; margin-top: 25px;" class="sim-button button2">Save</button>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="WarningModel">
                <div class="modal-content" style="width: 62%;">
                    <div class="modal-header">
                        <button type="button" class="close" (click)="OnClickCancel()">×</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>