<div class="container-fluid" *ngIf="! _LeaveApplicationService.IsForm">
    <!-- *ngIf="_cntryServive.formHideShow==0" -->
    <h2 class="heading_all">Leave Application </h2>
    <div id="form-wrap">
        <br>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">
                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="AddNew()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width:60%;"><input type="text" class="form-control-search" name="search"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <table class="tablepl">
            <thead>
                <tr>
                    <th style="width:60px;">S.No</th>
                    <th style="width:80px;">
                        <div class="AtjIsPoint" (click)="sort('RefNo')" *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>Ref No</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_LeaveApplicationService.orderByColName!='RefNo' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='RefNo'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <input type="text" id="filterRefNo" name="filterRefNo" #filterEmpId="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.RefNo" placeholder="Ref No">
                        </div>
                    </th>
                    <th style="width:80px;">
                        <div class="AtjIsPoint" (click)="sort('EmpCode')" *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>Emp ID</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_LeaveApplicationService.orderByColName!='EmpCode' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='EmpCode'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <input type="text" id="filterEmpId" name="filterEmpId" #filterEmpId="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.EmpCode" placeholder="Emp ID">
                        </div>

                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('EmpName')" *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>Name</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_LeaveApplicationService.orderByColName!='EmpName' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='EmpName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <input type="text" id="filterEmpName" name="filterEmpName" #filterEmpId="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.EmpName" placeholder="Name">
                        </div>
                    </th>
                    <th>
                        <div class="AtjIsPoint" (click)="sort('Designation')"
                            *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>Designation</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_LeaveApplicationService.orderByColName!='Designation' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='Designation'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <input type="text" id="filterDesignation" name="filterDesignation" #filterEmpId="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.Designation"
                                placeholder="Designation">
                        </div>
                    </th>
                    <th>
                        <div class="AtjIsPoint" (click)="sort('LeaveTypeID')"
                            *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>Leave Type </span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_LeaveApplicationService.orderByColName!='LeaveTypeID' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='LeaveTypeID'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <select name="filterLeaveTypeID" id="filterLeaveTypeID" #filterEmpId="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.LeaveTypeID">
                                <option [value]="''">SELECT TYPE</option>
                                <option [value]="data.Id" *ngFor="let data of  _LeaveApplicationService.leaveTypeList">
                                    {{data.Name}}</option>
                            </select>
                        </div>
                    </th>
                    <th>
                        <div class="AtjIsPoint" (click)="sort('FromDate')"
                            *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>From Date </span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_LeaveApplicationService.orderByColName!='FromDate' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='FromDate'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <input type="text" id="filterFromDate" name="filterFromDate" #filterEmpId="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.FromDate">
                        </div>
                    </th>
                    <th>
                        <div class="AtjIsPoint" (click)="sort('ToDate')" *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>To Date </span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_LeaveApplicationService.orderByColName!='ToDate' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='ToDate'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <input type="text" id="filterToDate" name="filterToDate" #filterEmpId="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.ToDate">
                        </div>
                    </th>
                    <th style="width:90px;">
                        <div class="AtjIsPoint" (click)="sort('StatusText')"
                            *ngIf="!_LeaveApplicationService.isFiltering">
                            <span>Status </span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_LeaveApplicationService.orderByColName!='StatusText' "></i>
                                <div *ngIf="_LeaveApplicationService.orderByColName=='StatusText'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_LeaveApplicationService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_LeaveApplicationService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_LeaveApplicationService.isFiltering">
                            <select name="filterStatus" id="filterStatus" #filterStatus="ngModel"
                                [(ngModel)]="_LeaveApplicationService.DataListFilter.Status">
                                <option [value]="''">SELECT STATUS</option>
                                <option [value]="0">Pending</option>
                                <option [value]="1">Approved</option>
                                <option [value]="2">Rejected</option>
                            </select>
                        </div>
                    </th>
                    <th style="width:100px;"><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_LeaveApplicationService.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="_LeaveApplicationService.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let data of _LeaveApplicationService.DataList| gridOperations  :_LeaveApplicationService.pageIndex: 
                    {RefNo:_LeaveApplicationService.DataListFilter.RefNo,EmpCode:_LeaveApplicationService.DataListFilter.EmpCode,EmpName:_LeaveApplicationService.DataListFilter.EmpName,
                        Designation:_LeaveApplicationService.DataListFilter.Designation,LeaveTypeID:_LeaveApplicationService.DataListFilter.LeaveTypeID,FromDate:_LeaveApplicationService.DataListFilter.FromDate,
                        ToDate:_LeaveApplicationService.DataListFilter.ToDate,Status:_LeaveApplicationService.DataListFilter.Status}:
                   {Column:_LeaveApplicationService.orderByColName,Dir:_LeaveApplicationService.orderByDir} ;let i=index">
                    <td style="width:60px;">{{i+1}}</td>
                    <td style="width:80px;">{{data.RefNo}}</td>
                    <td style="width:80px;">{{data.EmpCode}}</td>
                    <td title="{{data.EmpName}}">{{data.EmpName}}</td>
                    <td title="{{data.Designation}}">{{data.Designation}}</td>
                    <td>{{data.LeaveTypeText}}</td>
                    <td>{{data.FromDate}}</td>
                    <td>{{data.ToDate}}</td>
                    <td [ngClass]="{'Approved':data.Status==1,'Rejected':data.Status==2}"> {{data.StatusText}} </td>
                    <td style="width:100px;">
                        <button type="button" class="btn-success" (click)="onEdit(data)">
                            <i class="fas fa-pen-square" aria-hidden="true"></i>
                        </button>
                        <button *ngIf="data.EmpID==this.LoginID" type="button" class="btn-danger" (click)="onDelete(data.EmpID,data.ID)">
                            <i class="fas fa-trash" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="(_LeaveApplicationService.DataList?.length<=0 )  || _CommonService.filterdItemCount<1">
                    <td colspan="7"> No Records To Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="7" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="_LeaveApplicationService.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
    </div>
    <div class="margintop"></div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'Leave Application'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>