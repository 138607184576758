import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeetingService } from 'src/app/Services/meeting.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-scheduledmeeting',
  templateUrl: './scheduledmeeting.component.html',
  styleUrls: ['./scheduledmeeting.component.css']
})
export class ScheduledmeetingComponent implements OnInit {

  scheduledCount:number=0;
  errorMsg:string='';
  meetingpopup:boolean=false;
  firstTimeload:boolean=false;
  timerSubscription: Subscription; 
  hr:any='0'+0;
  min:any='0'+0;
  sec:any='0'+0;
  ms:any='0'+0;
  startTimer:any;
  running:boolean=false;
  
  constructor(public _Meeting:MeetingService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
   if(!this.firstTimeload)
   {
    this._Meeting.getScheduledMeetingCount('MeetingCount').subscribe(
      data=>{
        this.scheduledCount=data[0].NotiCount
        this.firstTimeload=true;
      },
      error => {
        this.errorMsg = error;
      });
   }
   this.loadScheduledMeetingCount();
  }

  myinterval=interval(300000);
  loadScheduledMeetingCount()
  {
    this.timerSubscription=this.myinterval.subscribe((x)=>{
      this._Meeting.getScheduledMeetingCount('MeetingCount').subscribe(
        data=>{this.scheduledCount=data[0].NotiCount},
        error => {
          this.errorMsg = error;
        });
    })
  }

  timerStart():void
  {
    if(!this.running)
    {
      this.running=true;
      this.startTimer=setInterval((e)=>{
        this.ms++;
        this.ms=this.ms < 10?'0'+this.ms:this.ms;
        if(this.ms===100)
        {
          this.sec++;
          this.sec=this.sec < 10?'0'+this.sec:this.sec;
          this.ms='0'+ 0;
        }
        if(this.sec===60)
        {
          this.min++;
          this.min=this.min < 10?'0'+this.min:this.min;
          this.sec='0'+ 0;
        }
        if(this.min===60)
        {
          this.hr++;
          this.hr=this.hr < 10?'0'+this.hr:this.hr;
          this.min='0'+ 0;
        }
      },10)
    }  
    else  
      this.timerStop();  
  }
  timerStop()
  {
    clearInterval(this.startTimer);
    this.running=false;
  }
  timerReset()
  {
    clearInterval(this.startTimer);
    this.running=false;
    this.ms=0,this.sec=0,this.min=0,this.hr=0;
  }

  loadScheduledMeetingDetails()
  {
    this._Meeting.getScheduledMeetingDetails('MeetingList').subscribe(
      data=>{
        this._Meeting.allscheduledMeeting=data;
        this.meetingpopup=true;
      },
      error => {
        this.errorMsg = error;
      });
  }
  saveMeetingTime(type:number,obj:any)
  {
    if(obj.IsStarted && type==1)
    {
      this.toastr.info('Meeting is already started', 'Scheduled Meeting') 
      return;
    }
    if(!obj.IsStarted && type==2)
    {
      this.toastr.info('Meeting is not yet started', 'Scheduled Meeting') 
      return;
    }
    else
    {
      // this.meetingpopup=type==1?true:false;
      this._Meeting.SaveEmployeeMeetingTime(obj,type==1?'SaveStartTime':'SaveEndTime').subscribe(
        data=>{
          if(data.Status)
          {
            this.toastr.success(data.Message, 'Scheduled Meeting');
            this._Meeting.allscheduledMeeting.forEach(d=>{
              if(d.EmpMeetingID==obj.EmpMeetingID && d.MeetingID==obj.MeetingID)
                d.IsStarted=true;            
              if(type==1)
              {
                this.timerReset();
                this.timerStart();
              }
              else
                this.timerStop();
            })
          }
          else
            this.toastr.info(data.Message, 'Scheduled Meeting');
        },
       error => {
        this.errorMsg = error;
      });
    }
  }
  closePopUp()
  {
    this.meetingpopup=false;
  }
  ngOnDestroy(): void { 
    this.timerSubscription.unsubscribe(); 
  } 
}
