import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {


  @Input() title: string = "Confirm";
  @Input() message: string = "Are you sure you want to delete ?";
  @Input() btnOkText: string = "Ok";
  @Input() btnCancelText: string = "Cancel";
  @Output() ConfirmEvent = new EventEmitter<boolean>();
  constructor() { }
  accept() {
    this.ConfirmEvent.emit(true);
  }
  decline() {
    this.ConfirmEvent.emit(false);
  }
  dismiss() {
    this.ConfirmEvent.emit(null);
  }
  ngOnInit() {
  }
  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    event.stopPropagation();
    if (event.code == "Escape") {
      this.dismiss();
    }
  }
}
