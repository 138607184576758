import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amc-renewal',
  templateUrl: './amc-renewal.component.html',
  styleUrls: ['./amc-renewal.component.css']
})
export class AmcRenewalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
