<div class="container-fluid" *ngIf="_Meeting.formHideShow==1">
    <h2 class="heading_all">MEETING</h2>
    <br>
    <div id="form-wrap">
        <form #meetingForm="ngForm" (ngSubmit)="onSubmit(meetingForm)">
              <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label>Meeting No<span class="validation-error">*</span></label>
                        <input type="text" name="MeetingNo" #MeetingNo="ngModel" autocomplete="off"
                            [(ngModel)]="_Meeting.selectedMeeting.MeetingNo" placeholder="Meeting No">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label>Date<span class="validation-error">*</span></label>
                        <input class="form-control" name="VDate" #VDate="ngModel" 
                            autocomplete="off" [(ngModel)]="_Meeting.selectedMeeting.VDate" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                    </div>
                </div>
              </div>           
            <div class="col-md-12">
                <div class="form-group row">
                    <label>Topic<span class="validation-error">*</span></label>
                    <input type="text" name="Topic" #Topic="ngModel" autocomplete="off"
                            [(ngModel)]="_Meeting.selectedMeeting.Topic" placeholder="Topic">
                </div>
                <div class="form-group row">
                    <label>Agenda</label>
                    <input type="text" name="Agenda" #Agenda="ngModel" autocomplete="off"
                            [(ngModel)]="_Meeting.selectedMeeting.Agenda" placeholder="Agenda">
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label>Scheduled Date<span class="validation-error">*</span></label>
                            <input class="form-control" name="ScheduledDate" [disabled]="_Meeting.selectedMeeting.MeetingStatus" #ScheduledDate="ngModel" 
                                autocomplete="off" [(ngModel)]="_Meeting.selectedMeeting.ScheduledDate" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        </div>
                    </div>             
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label>Scheduled Time<span class="validation-error">*</span></label>
                            <input type="time" class="form-control" autocomplete="off" [disabled]="_Meeting.selectedMeeting.MeetingStatus"
                            [(ngModel)]="_Meeting.selectedMeeting.ScheduledTime" name="ScheduledTime"
                            #ScheduledTime="ngModel" placeholder="Time">
                        </div>
                    </div>
                </div>           
                <div class="form-group row">
                    <label>Regarding<span class="validation-error">*</span></label>
                    <ng-select name="RegardingType" id="RegardingType"
                        bindLabel="Name" bindValue="Id" [disabled]="_Meeting.selectedMeeting.MeetingStatus"
                        [items]="_Meeting.regardingTypeList" #RegardingType="ngModel"
                        [(ngModel)]="_Meeting.selectedMeeting.RegardingTypeId" (ngModelChange)="getRegardingList()"
                        placeholder="Select">
                    </ng-select>
                </div>
                <div class="form-group row" style="margin-top:5px;">
                    <ng-select name="Regarding" id="Regarding"
                        bindLabel="Name" bindValue="Id" [disabled]="_Meeting.selectedMeeting.MeetingStatus"
                        [items]="_Meeting.regardingList" #Regarding="ngModel"
                        [(ngModel)]="_Meeting.selectedMeeting.RegardingId"
                        placeholder="Select">
                    </ng-select>
                </div>           
                <div class="form-group row">
                    <label>Description</label>
                    <input type="text" name="Description" #Description="ngModel" autocomplete="off"
                        [(ngModel)]="_Meeting.selectedMeeting.Description" placeholder="Description">
                </div>
                <div class="form-group row">
                    <label>Attendees<span class="validation-error">*</span></label>
                    <ng-select name="Employee" id="Employee"
                        bindLabel="Name" bindValue="Id"
                        [items]="_Meeting.employeeList" #Employee="ngModel"
                        [(ngModel)]="this.selectedEmpId" (ngModelChange)="addEmployee(this.selectedEmpId)"
                        placeholder="Select">
                    </ng-select>
                    <table class="table">
                        <tr *ngFor="let obj of this._Meeting.selectedMeeting.Employees;let i=index">
                            <td>{{obj.Name}}
                                <img (click)="deleteEmployee(i)" style="height:20px;padding: 0px;float:right;" src="../../../../../assets/Images/delete.ico">
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(meetingForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>