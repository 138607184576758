<div class="container-fluid">
    <h2 class="heading_all">NEW LEAD </h2>
    <br>
    <div id="form-wrap">
        <form #NewLeadForm="ngForm" (ngSubmit)="onSubmit(NewLeadForm)">
            <div class="tab-content">
                <div id="sec1" class="container-fluid tab-pane active">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="select">Dealer<span style="color: red;">*</span></label>

                                <select class="form-control" name="DealerId" #DealerId="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.DealerId"
                                    (change)="LoadCustomers();LoadBPE();GetRefNo()" required>
                                    <option [ngValue]="0">Select</option>
                                    <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.DealerName }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(DealerId.invalid && DealerId.touched)||(DealerId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label for="select">Ref.No<span style="color: red;">*</span></label>





                                <!-- </div> -->
                                <div class="row">
                                    <div class="col-md-10" style="margin-left:-14px !important;">
                                        <input class="form-control" name="RefNo" #RefNo="ngModel" autocomplete="off"
                                            [(ngModel)]="_newleadService.selectedNewLeads.RefNo" placeholder=" RefNo"
                                            required>
                                        <div class="validation-error"
                                            *ngIf="(RefNo.invalid && RefNo.touched)||(RefNo.invalid && this.FormValidation)">
                                            This Field is
                                            Required.</div>
                                    </div>
                                    <div class="col-md-2">
                                        <!-- <div class="form-group col-md-2"> -->
                                        <i class="fas fa-search" style="font-size: 1.5rem;  margin-top: 5px; "
                                            (click)="SearchCustReq()" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="select">Date<span style="color: red;">*</span></label>

                                <input class="form-control" type="text" bsDatepicker
                                    [bsConfig]="{  dateInputFormat: 'DD-MM-YYYY' }" placeholder="Choose Date"
                                    style="color: black; text-align: center;" autocomplete="off" id="Date" name="Date"
                                    #Date="ngModel" [(ngModel)]="this._newleadService.selectedNewLeads.Date">
                                <div class="validation-error"
                                    *ngIf="(Date.invalid && Date.touched)||(Date.invalid && this.FormValidation)">This
                                    Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">BPE<span style="color: red;">*</span></label>

                                <select class="form-control" name="BpeId" #BpeId="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.BpeId" required>
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option
                                        *ngFor="let prf of _bpeService.allBusinessPromotionExecutives | orderBy : 'BPEName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.BPEName }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(BpeId.invalid && BpeId.touched)||(BpeId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label for="select">Contact Person<span style="color: red;">*</span></label>

                                <input class="form-control" name="ContactPerson" #ContactPerson="ngModel"
                                    autocomplete="off" [(ngModel)]="_newleadService.selectedNewLeads.ContactPerson"
                                    placeholder="Contact Person" required ng-pattern="/^[a-zA-Z0-9]*$/">
                                <div class="validation-error"
                                    *ngIf="(ContactPerson.invalid && ContactPerson.touched)||(ContactPerson.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Next Action<span style="color: red;">*</span></label>

                                <select class="form-control" name="NextAction" #NextAction="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.NextAction" required>
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option *ngFor="let obj of ActionList" [ngValue]="obj.Id">
                                        {{obj.Name}}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(NextAction.invalid && NextAction.touched)||(NextAction.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="select">Contact No<span style="color: red;">*</span></label>

                                <input class="form-control" name="ContactNo" #ContactNo="ngModel"
                                    (keypress)="isNumber($event)" ng-pattern="/^[0-9]*$/"
                                    [(ngModel)]="_newleadService.selectedNewLeads.ContactNo" autocomplete="off"
                                    placeholder=" Contact No" required>
                                <div class="validation-error"
                                    *ngIf="(ContactNo.invalid && ContactNo.touched)||(ContactNo.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>


                            </div>
                            <div class="form-group">
                                <label for="select">Customer<span style="color: red;">*</span></label>

                                <input class="form-control" name="CustomerId" #CustomerId="ngModel"
                                    [(ngModel)]=" _newleadService.selectedNewLeads.CustomerId" autocomplete="off"
                                    placeholder=" Customer" required>

                                <div class="validation-error"
                                    *ngIf="(CustomerId.invalid && CustomerId.touched)||(CustomerId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label>Address<span style="color: red;">*</span></label>

                                <input class="form-control" name="CustAddr1" #CustAddr1="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.CustAddr1" autocomplete="off"
                                    placeholder=" Addr1" required>
                                <div class="validation-error"
                                    *ngIf="(CustAddr1.invalid && CustAddr1.touched)||(CustAddr1.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                                <input class="form-control" name="CustAddr2" #CustAddr2="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.CustAddr2" autocomplete="off"
                                    placeholder=" Addr2" required>
                                <div class="validation-error"
                                    *ngIf="(CustAddr2.invalid && CustAddr2.touched)||(CustAddr2.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <input class="form-control" name="CustAddr3" #CustAddr3="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.CustAddr3" autocomplete="off"
                                    placeholder=" Addr3" required>
                                <div class="validation-error"
                                    *ngIf="(CustAddr3.invalid && CustAddr3.touched)||(CustAddr3.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Email<span style="color: red;">*</span></label>

                                <input class="form-control" name="Email" #Email="ngModel" autocomplete="off"
                                    pattern="[a-zA-Z0-9._%]{1,}@[a-zA-Z]{1,}[.]{1}[a-zA-Z]{2,3}"
                                    [(ngModel)]="_newleadService.selectedNewLeads.Email" placeholder="Email" required>
                                <div class="validation-error"
                                    *ngIf="(Email.invalid && Email.touched)||(Email.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Next Action Date<span style="color: red;">*</span></label>

                                <input type="text" bsDatepicker [bsConfig]="{  dateInputFormat: 'DD-MM-YYYY' }"
                                    placeholder="Choose Date" style="color: black; text-align: center;"
                                    autocomplete="off" id="NextActionDate" name="NextActionDate"
                                    #NextActionDate="ngModel"
                                    [(ngModel)]="this._newleadService.selectedNewLeads.NextActionDate">
                                <div class="validation-error"
                                    *ngIf="(NextActionDate.invalid && NextActionDate.touched)||(NextActionDate.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group ">
                                <label for="select">Refered By<span style="color: red;">*</span></label>

                                <select class="form-control" name="ReferedBy" #ReferedBy="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.ReferedBy" required
                                    (change)="onSelect(_newleadService.selectedNewLeads.ReferedBy)">
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option *ngFor="let obj of ReferedList" [ngValue]="obj.Id">
                                        {{obj.Name}}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(ReferedBy.invalid && ReferedBy.touched)||(ReferedBy.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="select">Project<span style="color: red;">*</span></label>

                                <select class="form-control" name="ProjectId" #ProjectId="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.ProjectId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let prf of _projectService.allProjects | orderBy : 'Name'"
                                        [ngValue]="prf.Id">
                                        {{ prf.Name }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(ProjectId.invalid && ProjectId.touched)||(ProjectId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label for="select">Modules<span style="color: red;">*</span></label>
                                <div class="col-md-12 moduleBox">
                                    <div *ngFor="let obj of _ModuleService.allModulesCheckBox | orderBy : 'ModuleName'">
                                        <input type="checkbox" (change)="onChangeModules($event, obj)"
                                            [checked]="obj.Selected" name="obj.ModuleName"
                                            value="{{obj.Id}}">&nbsp;{{obj.ModuleName}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Note<span style="color: red;">*</span></label>

                                <textarea class="form-control" name="Note" #Note="ngModel" autocomplete="off"
                                    [(ngModel)]="_newleadService.selectedNewLeads.Note" placeholder="Note"
                                    required> </textarea>
                                <div class="validation-error"
                                    *ngIf="(Note.invalid && Note.touched)||(Note.invalid && this.FormValidation)">This
                                    Field is
                                    Required.</div>
                            </div>


                            <div class="form-group" *ngIf="_newleadService.referedbycusVisible==1">
                                <label for="select">Customer</label>

                                <select class="form-control" name="ReferedEmployee" #ReferedEmployee="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.ReferedEmployee" required>
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option *ngFor="let prf of _custService.allCustomers | orderBy : 'Name'"
                                        [ngValue]="prf.Id">
                                        {{ prf.Name }}
                                    </option>
                                </select>


                            </div>
                            <div class="form-group" *ngIf="_newleadService.referedbyempVisible==1">
                                <label for="select">Employee</label>

                                <select class="form-control" name="ReferedEmployee" #ReferedEmployee="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.ReferedEmployee" required>
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option *ngFor="let prf of _EmployeeService.allEmployees | orderBy : 'Name'"
                                        [ngValue]="prf.Id">
                                        {{ prf.EmpName }}
                                    </option>
                                </select>


                            </div>
                        </div>

                    </div>
                </div>
                <br>
                <div class="container-fluid">
                    <!-- <br> -->
                    <br>
                    <div class="row" style="padding: 0 15px;">
                        <b style="color: #008080">Note</b>
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>

                                            <th> SlNo</th>
                                            <th> Requirement</th>
                                            <th> Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let field of this._newleadService.allNewLeadDet ; let i = index">
                                            <th style="background:white;color:black" scope="row">{{i+1}}</th>


                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="field.Requirement" name="{{field.Requirement}}"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Requirement"
                                                    required>
                                            </td>

                                            <td><button type="button" (click)="deleteFieldValue(i)">-</button></td>
                                        </tr>

                                        <tr>
                                            <th style="background:white;color:black" scope="row">
                                                {{this._newleadService.RowCount + 1}}</th>

                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="this._newleadService.newAttribute.Requirement"
                                                    name="newAttributeDescription" #newAttributeDescription="ngModel"
                                                    placeholder="Description">
                                            </td>

                                            <td><button type="button" (click)="addFieldValue()">+</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="select">Status<span style="color: red;">*</span></label>

                                <select class="form-control" name="Status" #Status="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.Status" required
                                    (change)="onStatusSelect(_newleadService.selectedNewLeads.Status)">
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option *ngFor="let obj of StatusList" [ngValue]="obj.Id">
                                        {{obj.Name}}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(Status.invalid && Status.touched)||(Status.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                        </div>
                        <div class="col-md-4" *ngIf="_newleadService.reasonVisible==1">
                            <div class="form-group">
                                <label for="select">Reason</label>

                                <select class="form-control" name="Reason" #Reason="ngModel"
                                    [(ngModel)]="_newleadService.selectedNewLeads.Reason">
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option
                                        *ngFor="let prf of _ReasonmasterService.allReasonMaster | orderBy : 'Reason'"
                                        [ngValue]="prf.ID">
                                        {{ prf.Reason }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-8" *ngIf="_newleadService.reasonVisible==1">
                            <div class="form-group">
                                <label>Remark</label>
                                <textarea class="form-control" name="Remark" #Remark="ngModel" autocomplete="off"
                                    [(ngModel)]="_newleadService.selectedNewLeads.Remark"
                                    placeholder="Remark"> </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button type="submit" style="width: 99%; margin-top: 25px;" class=" sim-button button1"
                        [disabled]="this.isSubmit">
                        Submit
                    </button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 25px;" class=" sim-button button2"
                        (click)="resetForm(NewLeadForm)">
                        Reset
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div style="margin-top: 50px;"></div>