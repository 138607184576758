<div class="container-fluid">

    <h2 class="heading_all">Work Sorting</h2>
    <div id="form-wrap">
        <form #WorkSortingForm="ngForm" (ngSubmit)="onSubmit(WorkSortingForm)">

            <div class="row" style="text-align: center;">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <button style="width: 100%;" type="button" class="sim-button button2"
                        [ngClass]="{'button2Change': this.clickReadyToinstall}" (click)="ReadytoInstall()">Ready To
                        Install</button>
                </div>
                <div class="col-md-4" *ngIf="this.clickReadyToinstall">
                    <select id="CustomerId" (change)="CustomerChange()" #CustomerId="ngModel"
                        [(ngModel)]=" this._WorkSortingService.selectedWorkSortHead.CustomerId" name="CustomerFilter"
                        style="padding: 8px;    border-radius: .5rem;">
                        <option [value]="0"> SELECT CUSTOMER </option>
                        <option *ngFor="let field of this._CustomerService.allCustomers| orderBy : 'Name'"
                            [value]="field.Id">
                            {{field.Name}} </option>
                    </select>
                    <!-- <button type="button" (click)="updateHoldReason()" class="sim-button button1" style="width: 100%;">Submit</button> -->
                </div>
                <div class="col-md-2"></div>

            </div>
            <br>
            <div class="row">
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col" style="width:30px;">SlNo
                                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                            <i *ngIf="!_WorkSortingService.isFiltering" class="fas fa-filter"></i>
                                            <i *ngIf="_WorkSortingService.isFiltering" class="fas fa-times"></i>
                                        </a>
                                    </th>
                                    <!-- <th scope="col">RDate</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('RDate')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">RDate</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='RDate'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='RDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtRDateFilter="ngModel" id="RDateFilter"
                                                name="RDateFilter" [(ngModel)]="_WorkSortingService.RDateFilter"
                                                placeholder="RDate">
                                        </div>

                                    </th>
                                    <!-- <th scope="col" style="width:50px;">Ref No</th> -->
                                    <th class="hidden-xs" style="width:70px;">
                                        <div class="AtjIsPoint" (click)="sort('RefNo')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">Ref No</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='RefNo'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='RefNo'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtRefNoFilter="ngModel" id="RefNoFilter"
                                                name="RefNoFilter" [(ngModel)]="_WorkSortingService.RefNoFilter"
                                                placeholder="RefNo">
                                        </div>
                                    </th>
                                    <!-- <th scope="col">Priority</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('PriorityId')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">Priority</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='PriorityId'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='PriorityId'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <select name="PriorityFilter" id="PriorityFilter"
                                                [(ngModel)]="_WorkSortingService.PriorityFilter">
                                                <option [value]="''">Select</option>
                                                <option *ngFor="let obj of PriorityList" [ngValue]="obj.Id">
                                                    {{obj.Name}}
                                                </option>
                                            </select>
                                        </div>

                                    </th>
                                    <!-- <th scope="col">Current Staus</th> -->
                                    <th class="hidden-xs" style="width:120px;">
                                        <div class="AtjIsPoint" (click)="sort('CurrentStatus')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">Current Status</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='CurrentStatus'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='CurrentStatus'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtCurrentStausFilter="ngModel" id="CurrentStausFilter"
                                                name="CurrentStausFilter"
                                                [(ngModel)]="_WorkSortingService.CurrentStausFilter"
                                                placeholder="Current Status">
                                        </div>
                                    </th>
                                    <!-- <th scope="col">ExpDate</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('ExpDate')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">ExpDate</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='ExpDate'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='ExpDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtExpDateFilter="ngModel" id="ExpDateFilter"
                                                name="ExpDateFilter" [(ngModel)]="_WorkSortingService.ExpDateFilter"
                                                placeholder="ExpDate">
                                        </div>
                                    </th>
                                    <!-- <th scope="col">OD_EDate</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('OD_EDate')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">OD_EDate</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='OD_EDate'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='OD_EDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtOD_EDateFilter="ngModel" id="OD_EDateFilter"
                                                name="OD_EDateFilter" [(ngModel)]="_WorkSortingService.OD_EDateFilter"
                                                placeholder="OD_EDate">
                                        </div>
                                    </th>
                                    <!-- <th scope="col">PD_Date</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('PD_Date')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">PD_Date</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='PD_Date'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='PD_Date'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtPD_DateFilter="ngModel" id="PD_DateFilter"
                                                name="PD_DateFilter" [(ngModel)]="_WorkSortingService.PD_DateFilter"
                                                placeholder="PD_Date">
                                        </div>

                                    </th>
                                    <!-- <th scope="col">OD_PDDate</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('OD_PDDate')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">OD_PDDate</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='OD_PDDate'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='OD_PDDate'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtOD_PDDateFilter="ngModel" id="OD_PDDateFilter"
                                                name="OD_PDDateFilter" [(ngModel)]="_WorkSortingService.OD_PDDateFilter"
                                                placeholder="OD_PDDate">
                                        </div>

                                    </th>
                                    <!-- <th scope="col">Customer</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('CustomerName')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">Customer</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='CustomerName'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='CustomerName'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtCustomerFilter="ngModel" id="CustomerFilter"
                                                name="CustomerFilter" [(ngModel)]="_WorkSortingService.CustomerFilter"
                                                placeholder="Customer">
                                        </div>

                                    </th>
                                    <!-- <th scope="col">Att SE</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('EmpName')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">Att SE</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='EmpName'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='EmpName'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtAttSEFilter="ngModel" id="AttSEFilter"
                                                name="AttSEFilter" [(ngModel)]="_WorkSortingService.AttSEFilter"
                                                placeholder="Att SE">
                                        </div>

                                    </th>
                                    <!-- <th scope="col">MOReg</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('ModeOfReg')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">MOReg</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='ModeOfReg'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='ModeOfReg'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <select name="MORegFilter" id="MORegFilter"
                                                [(ngModel)]="_WorkSortingService.MORegFilter">
                                                <option [value]="''" selected>Select</option>
                                                <option [ngValue]="1">By Phone</option>
                                                <option [ngValue]="2">By Mail</option>
                                                <option [ngValue]="3">By Chat</option>
                                                <option [ngValue]="4" hidden>On Site</option>
                                                <option [ngValue]="5" hidden>Remote Section</option>
                                            </select>
                                        </div>

                                    </th>
                                    <!-- <th scope="col">Req Type</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('TypeId')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">Req Type</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='TypeId'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='TypeId'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <select name="ReqTypeFilter" id="ReqTypeFilter"
                                                [(ngModel)]="_WorkSortingService.ReqTypeFilter">
                                                <option [value]="''">Select</option>
                                                <option *ngFor="let obj of TypeList" [ngValue]="obj.Id">
                                                    {{obj.Name}}
                                                </option>
                                            </select>
                                        </div>

                                    </th>
                                    <!-- <th scope="col">Project</th> -->
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('ProjectName')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">Project</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='ProjectName'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='ProjectName'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtProjectFilter="ngModel" id="ProjectFilter"
                                                name="ProjectFilter" [(ngModel)]="_WorkSortingService.ProjectFilter"
                                                placeholder="Project">
                                        </div>

                                    </th>
                                    <th class="hidden-xs">
                                        <div class="AtjIsPoint" (click)="sort('Vtype')">
                                            <span *ngIf="!_WorkSortingService.isFiltering">V Type</span>
                                            <div class="AtjfloatRight">
                                                <i class="fas fa-sort"
                                                    *ngIf="_WorkSortingService.orderByColName!='Vtype'"></i>
                                                <div *ngIf="_WorkSortingService.orderByColName=='Vtype'">
                                                    <i class="fas fa-sort-amount-down"
                                                        *ngIf="_WorkSortingService.orderByDir"></i>
                                                    <i class="fas fa-sort-amount-up-alt"
                                                        *ngIf="!_WorkSortingService.orderByDir"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="_WorkSortingService.isFiltering">
                                            <input type="text" #txtVtype="ngModel" id="txtVtype" name="txtVtype"
                                                [(ngModel)]="_WorkSortingService.Vtype" placeholder="0=CR, 1=SRE">
                                        </div>

                                    </th>
                                    <!-- <th scope="col">View</th> -->
                                    <th *ngIf="!this.clickReadyToinstall">View

                                    </th>

                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr
                                    *ngFor="let field of this._WorkSortingService.AllWorkSorting| gridOperations  :null: 
                                {RDate:_WorkSortingService.RDateFilter,Vtype:_WorkSortingService.Vtype,RefNo:_WorkSortingService.RefNoFilter,
                                PriorityId:_WorkSortingService.PriorityFilter,CurrentStatus:_WorkSortingService.CurrentStausFilter,
                                ExpDate:_WorkSortingService.ExpDateFilter,OD_EDate:_WorkSortingService.OD_EDateFilter,
                                PD_Date:_WorkSortingService.PD_DateFilter,OD_PDDate:_WorkSortingService.OD_PDDateFilter,
                                CustomerName:_WorkSortingService.CustomerFilter,EmpName:_WorkSortingService.AttSEFilter,
                                ModeOfReg:_WorkSortingService.MORegFilter,TypeId:_WorkSortingService.ReqTypeFilter,ProjectName:_WorkSortingService.ProjectFilter}:
                                {Column:_WorkSortingService.orderByColName,Dir:_WorkSortingService.orderByDir}; let i = index">
                                    <th style="background:white;color:rgb(88, 88, 88)" scope="row">
                                        <i *ngIf="field.ID==this._WorkSortingService.selectedWorkSortHead.ID"
                                            class='fas fa-chevron-right' style='font-size:20px'></i>
                                        {{i+1}}
                                    </th>
                                    <td [title]="field.RDate| date">{{field.RDate}}</td>
                                    <!-- | date -->
                                    <td [title]="field.RefNo" style="width:70px;">{{field.RefNo}}</td>
                                    <td>
                                        <span class="form-control-label" *ngFor="let obj of  PriorityList">
                                            <p *ngIf="obj.Id==field.PriorityId" [title]="obj.Name">
                                                {{obj.Name}}
                                            </p>
                                        </span>
                                    </td>
                                    <td [title]="field.CurrentStatus" style="width:120px;"> {{field.CurrentStatus}}</td>
                                    <td>
                                        <p *ngIf="field.ExpDate=='0001-01-01T00:00:00'"></p>
                                        <p *ngIf="field.ExpDate!='0001-01-01T00:00:00'">{{field.ExpDate}}</p>
                                        <!-- |date -->
                                    </td>
                                    <td>{{field.OD_EDate}} days</td>
                                    <td>
                                        <p *ngIf="field.PD_Date=='0001-01-01T00:00:00'"></p>
                                        <p *ngIf="field.PD_Date!='0001-01-01T00:00:00'">{{field.PD_Date}}</p>
                                        <!-- |date -->
                                    </td>
                                    <td [title]="field.OD_PDDate"> {{field.OD_PDDate}} days</td>
                                    <td [title]="field.CustomerName"> {{field.CustomerName}} </td>
                                    <td [title]="field.EmpName"> {{field.EmpName}} </td>
                                    <td>
                                        <div class="col-md-8">
                                            <label class="form-control-label">
                                                <p *ngIf="field.ModeOfReg==0"> Select </p>
                                                <p *ngIf="field.ModeOfReg==1" title="By Phone"> By Phone </p>
                                                <p *ngIf="field.ModeOfReg==2" title="By Mail"> By Mail </p>
                                                <p *ngIf="field.ModeOfReg==3" title="By Chat"> By Chat </p>
                                                <p *ngIf="field.ModeOfReg==4" title="On Site"> On Site </p>
                                                <p *ngIf="field.ModeOfReg==5" title="Remote Section">Remote Section</p>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="form-control-label" *ngFor="let obj of  TypeList">
                                            <p *ngIf="obj.Id==field.TypeId" [title]="obj.Name">
                                                {{obj.Name}}
                                            </p>
                                        </span>
                                    </td>

                                    <td [title]="field.ProjectName">
                                        {{field.ProjectName}}
                                    </td>
                                    <td [title]="field.Vtype">
                                        {{field.Vtype==0?'CR':'SRE'}}
                                    </td>
                                    <td *ngIf="!this.clickReadyToinstall">
                                        <i class="fas fa-eye" (click)="getPendingWorksDet(field)"
                                            style="font-size:24px"></i>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="this._WorkSortingService.AllWorkSortingDet.length>0" class="row">
                <div class="tWrap" id="newLines">
                    <div class="tWrap__head">
                        <table>
                            <tbody>
                                <tr>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;width: 300px">
                                        <div style="text-align: center;">
                                            <h6 style="font-weight: bold;">Branch Details </h6>
                                            <p *ngIf="this._BranchService.selectedBranch.BranchName!=null">
                                                Branch Name:{{this._BranchService.selectedBranch.BranchName}}
                                            </p>
                                            <p *ngIf="this._BranchService.selectedBranch.BranchEmail!=null">
                                                {{this._BranchService.selectedBranch.BranchPhone}},
                                                {{this._BranchService.selectedBranch.BranchEmail}}
                                            </p>
                                            <p *ngIf="this._BranchService.selectedBranch.Addr1!=null">
                                                {{this._BranchService.selectedBranch.Addr1}},
                                                {{this._BranchService.selectedBranch.Addr2}},
                                                {{this._BranchService.selectedBranch.Addr3}}
                                            </p>
                                            <p *ngIf="this._BranchService.selectedBranch.ContactPerson!=null">
                                                Contact Person:
                                                {{this._BranchService.selectedBranch.ContactPerson}}
                                                ,{{this._BranchService.selectedBranch.ContactPhone}}</p>
                                        </div>
                                    </th>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;">
                                        <div style="text-align: center;">
                                            <h6 style="font-weight: bold;">Customer Details </h6>
                                            <p *ngIf="this._CustomerService.selectedCustomer.Name!=null">
                                                Customer Name:{{this._CustomerService.selectedCustomer.Name}}
                                            </p>
                                            <p *ngIf="this._CustomerService.selectedCustomer.CustEmail!=null">
                                                {{this._CustomerService.selectedCustomer.CustPhone}},
                                                {{this._CustomerService.selectedCustomer.CustEmail}}
                                            </p>
                                            <p *ngIf="this._CustomerService.selectedCustomer.Addr1!=null">
                                                {{this._CustomerService.selectedCustomer.Addr1}},
                                                {{this._CustomerService.selectedCustomer.Addr2}},
                                                {{this._CustomerService.selectedCustomer.Addr3}}
                                            </p>
                                            <p *ngIf="this._CustomerService.selectedCustomer.ContactPerson!=null">
                                                Contact Person:
                                                {{this._CustomerService.selectedCustomer.ContactPerson}}
                                                ,{{this._CustomerService.selectedCustomer.ContactPhone}}</p>
                                        </div>
                                    </th>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;">
                                        <div style="text-align: center;">
                                            <h6 style="font-weight: bold;"> Installation Date </h6>
                                            <p>
                                                {{this._WorkSortingService.selectedWorkSortHead.InstallationDate |date}}
                                            </p>
                                            <h6 style="font-weight: bold;"> Project Module </h6>
                                            <p [title]="this._WorkSortingService.selectedWorkSortHead.Modules">
                                                {{this._WorkSortingService.selectedWorkSortHead.Modules }}
                                            </p>

                                        </div>
                                    </th>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;width: 200px;">
                                        <div style="text-align: center;">
                                            <h6 style="font-weight: bold;"> </h6>
                                            <label style="color:green" class="form-control-label"
                                                *ngIf="this.AmcORChargable==1">
                                                ON
                                                AMC
                                            </label>
                                            <label style="color:red" class="form-control-label"
                                                *ngIf="this.AmcORChargable==2">
                                                Chargable
                                            </label>

                                            <p>
                                                No. Of Services: <br>( <span title="On Site"
                                                    style="cursor: pointer;">{{this._WorkSortingService.selectedWorkSortHead.OnSiteCount}}
                                                </span> /
                                                <span title="Remote"
                                                    style="cursor: pointer;">{{this._WorkSortingService.selectedWorkSortHead.RemoteCount}}</span>
                                                )
                                            </p>
                                            <p>
                                                Last Service Att. Person:
                                                {{this._WorkSortingService.selectedWorkSortHead.LastServiceSE}} <br> &
                                                <br> Mode Of Service:
                                                {{this._WorkSortingService.selectedWorkSortHead.LastServiceMode}}
                                            </p>
                                            <p>
                                            </p>
                                        </div>
                                    </th>
                                    <th style="border: 1px solid #164e86;background:#aaeff3;color: black;width: 395px;">
                                        <!-- <div style="text-align: center;">
                                            <h6 style="font-weight: bold;">Attachments </h6>
                                            <br>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <p>File Name</p>
                                                </div>
                                                <div class="col-md-6">
                                                    <p>File Type</p>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="tWrap"
                                            style="height: 125px; margin-bottom: 20px; background:#aaeff3">
                                            <div class="tWrap__head">
                                                <table style="background:#aaeff3">
                                                    <thead>
                                                        <tr>
                                                            <th style="background: #5d6767;">#</th>
                                                            <th style="background: #5d6767;">ATTACHMENTS</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div class="tWrap__body" style="border-bottom: 1px solid #ccc;">
                                                <table style="background:#aaeff3">
                                                    <tbody>
                                                        <tr *ngFor="let data of FileList;let i = index">
                                                            <th style="background:#aaeff3;color:black; width:40px;"
                                                                scope="row">
                                                                {{i+1}}
                                                            </th>
                                                            <td (click)="downloadFileUrl(data)"
                                                                style="cursor: pointer; text-decoration: underline;">
                                                                {{data}}
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="FileList?.length<1">
                                                            <td>No File Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </th>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br>
            <!-- style="max-height:500px; overflow: auto;" -->
            <div class="row" *ngIf="this._WorkSortingService.AllWorkSortingDet.length>0">
                <div class="tWrap">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                            (change)="checkAll()" #selectedCheckAll="ngModel"
                                            [(ngModel)]="this._WorkSortingService.selectedCheckAll"
                                            name="selectedCheckAll"
                                            [checked]="this._WorkSortingService.selectedCheckAll"
                                            value="this._WorkSortingService.selectedCheckAll">&nbsp; Select All
                                    </th>
                                    <th scope="col" style="width:40px;">SlNo</th>
                                    <th scope="col" style="width:90px;">Action</th>
                                    <th scope="col" style="width:120px;">ActivityId</th>
                                    <th scope="col" style="width:120px;">Particular</th>
                                    <th scope="col" style="width:90px;">Module</th>
                                    <th scope="col" style="width:80px;">Req Type</th>
                                    <th scope="col" style="width:90px;">Priority</th>
                                    <th scope="col">Description</th>
                                    <th scope="col" style="width:120px;">Status</th>
                                    <th style="width:120px;">VType </th>
                                    <th style="width:80px;">RegNo </th>
                                    <th scope="col" style="width:90px;">Ass To</th>
                                    <th scope="col" style="width:110px;">Exp Date</th>
                                    <th scope="col" style="width:70px;">Action</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr *ngFor="let field of this._WorkSortingService.AllWorkSortingDet ; let i = index"
                                    [ngClass]="{'alloted-items' : field.StatusId==4,'alloted-itemsRemove':field.ActionType==4}">

                                    <td>
                                        <input type="checkbox" (change)="UpdateActionTypeCombo()"
                                            [ngModelOptions]="{standalone: true}" #CheckStatus="ngModel"
                                            [(ngModel)]="field.CheckStatus" name="{{field.CheckStatus}}"
                                            [checked]="field.CheckStatus" value="{{field.CheckStatus}}">
                                    </td>
                                    <th style="background:white;color:black; width:40px;" scope="row">
                                        {{i+1}}
                                    </th>
                                    <td style="width:90px;">
                                        <select #ActionType="ngModel" name="ActionType"
                                            [disabled]="field.StatusId==6||field.StatusId==7||field.StatusId==8||field.StatusId==4"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="field.ActionType"
                                            (change)="UpdateActionTypeDet(field)" class="form-control combo_body"
                                            required>
                                            <option [ngValue]="0" disabled>Select</option>
                                            <option *ngFor="let obj of  ActionList" [ngValue]="obj.Id"> {{obj.Name}}
                                            </option>
                                        </select>
                                    </td>
                                    <td style="width:120px;" [title]="field.ID"> {{field.ID}} </td>
                                    <td style="width:120px;" [title]="field.ParticularName"> {{field.ParticularName}}
                                    </td>
                                    <td style="width:90px;" [title]="field.ModuleName"> {{field.ModuleName}} </td>
                                    <td style="width:80px;">
                                        <span class="form-control-label" *ngFor="let obj of TypeList">
                                            <p *ngIf="obj.Id==field.TypeId" [title]="obj.Name">
                                                {{obj.Name}}
                                            </p>
                                        </span>
                                    </td>
                                    <td style="width:90px;">
                                        <span class="form-control-label" *ngFor="let obj of PriorityList">
                                            <p *ngIf="obj.Id==field.PriorityId" [title]="obj.Name">
                                                {{obj.Name}}
                                            </p>
                                        </span>
                                    </td>
                                    <td (click)="onUpdate(field)" [title]="field.Description">
                                        <label class="form-control-label">
                                            <p style="cursor: pointer;text-decoration: underline;">
                                                {{field.Description}}
                                            </p>
                                        </label>
                                    </td>
                                    <td style="width:120px;">
                                        <span class="form-control-label" *ngFor="let obj of StatusList">
                                            <p *ngIf="obj.Id==field.StatusId" [title]="obj.Name">
                                                {{obj.Name}}
                                            </p>
                                        </span>
                                    </td>
                                    <td style="width:120px;" [title]="field.Vtype">
                                        {{field.VType==0?'CR':'SRE'}}
                                    </td>
                                    <td style="width:80px;">
                                        {{field.RefNo}}
                                    </td>
                                    <!-- &&field.ActionType!=0 -->
                                    <td style="width:90px;">
                                        <select class="form-control combo_body" #newAttributeAssignTo="ngModel"
                                            [disabled]="(field.StatusId==6||field.StatusId==7||field.StatusId==8||field.StatusId==4) || (field.ActionType!=1)"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="field.AssTo"
                                            (change)="GetexpectedDate(field)" name="{{field.AssTo}}" required>
                                            <!-- <option [ngValue]="0" disabled>Select</option> -->
                                            <option *ngFor="let obj of this.AssignToList" [ngValue]="obj.Id">
                                                {{obj.Name}}</option>
                                        </select>
                                    </td>

                                    <td style="width:110px;">
                                        <input
                                            [disabled]="(field.StatusId==6||field.StatusId==7||field.StatusId==8||field.StatusId==4) || (field.ActionType!=1)"
                                            placeholder="Exp Date" #ExpDate="ngModel"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="field.ExpDate"
                                            name="{{field.ExpDate}}" style="color: black; text-align: center;width: 80%"
                                            autocomplete="off" #{{field.ExpDate}}="bsDatepicker" bsDatepicker
                                            [bsConfig]="{  dateInputFormat: 'MM-DD-YYYY' }">

                                    </td>
                                    <td style="width:70px;">
                                        <i *ngIf="field.ActionType==2" class="fas fa-trash"
                                            (click)="OnRemoveDet(field,i)" aria-hidden="true"
                                            style="font-size:24px"></i>
                                        <i class="fas fa-pause-circle" style="margin-left: 12px;"
                                            (click)="getHoldingDetails(field)" style="font-size:24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="this._WorkSortingService.AllWorkSortingDet.length>0&&this.ActionTypVisible" class="row">
                <div class="col-md-8">

                </div>
                <div class="col-md-2">
                    <div class="form-group ">
                        <label>Choose ActionType:</label>
                    </div>
                </div>
                <div class="col-md-2">
                    <select name="selectedActiontype" #selectedActiontype="ngModel" (change)="changeActionType()"
                        [(ngModel)]="_WorkSortingService.selectedActiontype" class="form-control" required
                        style="width: 160px;">
                        <option [ngValue]="0" disabled>Select</option>
                        <option [ngValue]="1">Accepted</option>
                        <option [ngValue]="2">Rejected</option>
                        <!-- <option [ngValue]="obj.Id" *ngFor="let obj of  ActionList"> {{obj.Name}} -->
                        <!-- </option> -->
                    </select>
                </div>
            </div>
            <!-- *ngIf="_workAllocationStatusService.BtmVisible==1" -->
            <!-- <div *ngIf="DscptnVisible==1">
                <div class="form-group">
                    <label>Description:</label>

                    <textarea style="overflow-y: scroll;    height: 100px;    resize: none;" class="form-control"
                        [value]="Description"></textarea>

                </div>
            </div> -->

            <div class="form-group ">
                <label>Remarks</label>
            </div>
            <div class="form-group ">

                <textarea class="form-control" name="Remark" #Remark="ngModel"
                    [(ngModel)]="_WorkSortingService.selectedWorkSortHead.Remark" placeholder=" Remarks"
                    required></textarea>
                <div class="validation-error" *ngIf="Remark.invalid && Remark.touched">
                    This
                    Field is Required.
                </div>

            </div>
            <br>
            <div class="form-group ">
                <h6 style="color: #008080">Previous Remarks</h6>
            </div>
            <div class="form-group" style="height: auto;margin-top: 10px;margin-bottom: 10px;  overflow: auto;">
                <ol>
                    <li *ngFor="let obj of this._WorkSortingService.RemarkHistory " style="color: black;">{{obj.Remark}}
                    </li>
                </ol>
            </div>

            <div class="row">
                <div class="col-md-4">

                </div>
                <div class="col-md-4">
                    <button type="submit" style="width: 100%;" [disabled]="!this.WorkHeaderAllotmentStatus "
                        class="sim-button button1">Submit</button>
                </div>

            </div>
            <br>
        </form>
    </div>
</div>

<form *ngIf="DisplayWorkStatus==1">
    <div class="popup-container">

        <input type="checkbox" id="hold-cnfirm" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Holding Reason</p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row HoldingReason">
                        <div class="col-md-12">
                            <div>

                                <div class="row">
                                    <div class="col-5">

                                        <div class="tWrap">
                                            <div class="tWrap__head">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>SNo</th>
                                                            <th>Reason</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <!--/.tWrap__head-->
                                            <div class="tWrap__body">
                                                <table>
                                                    <tbody>
                                                        <tr *ngFor="let obj of  HoldDetailsList;let i =index">
                                                            <td>
                                                                {{i+1}}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    *ngFor="let value of workallotmentHoldReasonsList">
                                                                    <span *ngIf="value.Id==obj.Reasons">
                                                                        {{value.Name}}</span>
                                                                </span>

                                                            </td>
                                                            <td>
                                                                <span (click)="selectHoldingReason(obj)"
                                                                    style="cursor: pointer; text-decoration: underline;">
                                                                    Select
                                                                </span>
                                                            </td>


                                                        </tr>
                                                        <tr *ngIf="HoldDetailsList?.length<=0">
                                                            <td colspan="3">
                                                                No Records To Display.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!--/.tWrap__body-->
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="row">

                                            <label class="col-3 form-control-label">Reason <span
                                                    style="color: red;">*</span></label>
                                            <div class="col-md-5">
                                                <ng-select name="HoldingReasonAction" id="HoldingReasonAction"
                                                    #HoldingReasonAction="ngModel" bindLabel="Name" bindValue="Id"
                                                    [items]="workallotmentHoldReasonsList"
                                                    [(ngModel)]="selectedHoldDetails.Reasons" placeholder="Select">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="selectedHoldDetails.Reasons==4">

                                            <label class="col-3 form-control-label">
                                                Clarification <span style="color: red;">*</span>
                                            </label>
                                            <div class="col-md-5">
                                                <ng-select name="HoldingMeetingWith" id="HoldingMeetingWith"
                                                    bindLabel="EmpName" bindValue="Id"
                                                    [items]="_JobCardService.allEmployee" #HoldingMeetingWith="ngModel"
                                                    [(ngModel)]="selectedHoldDetails.ClarificationFrom"
                                                    placeholder="Select">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <div class="row">

                                            <label class="col-3 form-control-label">Reason <span
                                                    style="color: red;">*</span></label>
                                            <div class="col-md-5">
                                                <textarea type="text" name="HoldingReason" id="HoldingReason"
                                                    #HoldingReason="ngModel" [(ngModel)]="selectedHoldDetails.Reason"
                                                    required></textarea>

                                                <div class="validation-error"
                                                    *ngIf="HoldingReason.invalid && HoldingReason.touched">
                                                    This
                                                    Field is Required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <label class="col-3 form-control-label">Remark </label>
                                            <div class="col-md-5">
                                                <textarea type="text" name="HoldingRemark" id="HoldingRemark"
                                                    #HoldingRemark="ngModel"
                                                    [(ngModel)]="selectedHoldDetails.Remark"></textarea>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="!DisableHoldUpdateButton">
                                            <div class="col-md-3">
                                                <button style="width: 99%;" type="button" class="sim-button button2"
                                                    (click)="ClearWorkSortHoldReason()">Clear</button>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="button" (click)="updateHoldReason()"
                                                    class="sim-button button1" style="width: 100%;">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<form *ngIf="DscptnVisible==1">
    <div class="popup-container">

        <input type="checkbox" id="hold-cnfirm" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Description</p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row HoldingReason">
                        <div class="col-md-12">
                            <div>

                                <div class="row">

                                    <div class="col-12">
                                        <div class="form-group">


                                            <textarea disabled
                                                style="overflow-y: scroll; min-height: 290px;     resize: none;"
                                                class="form-control" [value]="Description"></textarea>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>