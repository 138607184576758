<div class="container-fluid">
    <div class="row flex-body">
        <div class="col-md-9 offset-md-2">
            <div class="row FormHeader sim-button_head">
                <h3>Change Password</h3>
            </div>
            <div class="row FormBox">
                <div class="col-md-9 offset-md-2">
                    <div class="form-group row">
                        <label class="col-md-4 form-control-label">Old Password</label>
                        <div class="col-md-6">
                            <input type="text" id="" name="" class="form-control" placeholder="">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 form-control-label">New Password</label>
                        <div class="col-md-6">
                            <input type="text" id="" name="" class="form-control" placeholder="">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 form-control-label">Confirm Password</label>
                        <div class="col-md-6">
                            <input type="text" id="" name="" class="form-control" placeholder="">
                        </div>
                    </div>
                </div>
                <div class="btnactns col-md-6 offset-3">
                    <button type="button" class="sim-button button1">Submit</button>
                    <button type="button" class="sim-button button2">Reset</button>
                    <button type="button" class="sim-button button3">Exit</button>
                </div>
            </div>
        </div>
    </div>
</div>