import { Injectable } from '@angular/core';
import { bpe } from '../Models/bpe.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class BPEService {

  selectedBusinessPromotionExecutive: bpe;
  bpeList: bpe[];
  formHideShow = 0;
  allBusinessPromotionExecutives: bpe[];

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  postBusinessPromotionExecutive(type: bpe) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      BPEName: type.BPEName,
      DealerId: type.DealerId
    }

    return this.http.post<bpe>(ROOT_URL + 'BusinessPromotionExecutive/PostBusinessPromotionExecutive', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  putBusinessPromotionExecutive(type: bpe) {
    const params = new HttpParams().set('ID', type.Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      BPEName: type.BPEName,
      DealerId: type.DealerId,
      ID: type.Id
    }

    return this.http.put<bpe>(ROOT_URL + 'BusinessPromotionExecutive/PutBusinessPromotionExecutive/' + type.Id, body, {
      headers,
      params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getBusinessPromotionExecutive(TransMode: string): Observable<bpe[]> {
    const params = new HttpParams().set('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<bpe[]>(ROOT_URL + 'BusinessPromotionExecutive/GetAllBusinessPromotionExecutive',
      {
        headers,
        params
      })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getBusinessPromotionExecutiveById(TransMode: string, Id: string): Observable<bpe[]> {
    const params = new HttpParams().set('TransMode', TransMode).append('Id', Id);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<bpe[]>(ROOT_URL + 'BusinessPromotionExecutive/GetAllBusinessPromotionExecutiveById',
      {
        headers,
        params
      })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  deleteBusinessPromotionExecutive(type: bpe) {
    return this.http.delete<bpe>(ROOT_URL + 'BusinessPromotionExecutive/DeleteBusinessPromotionExecutive/' + type.Id)
  }
}
