import {Component, forwardRef, HostListener, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as $ from "jquery";   
@Component({
  selector: 'app-date-picker',
  styleUrls: ['./customedatepicker.css'],
  templateUrl: './customedatepicker.html', 
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatepickerComponent),
    multi: true
  }]
})
export class DatepickerComponent implements ControlValueAccessor {
  @Input() dateformat:string='mm/dd/yyyy hh:mm:ss';
  @Input() controlID:string='customdatepicker';
  calenderDates:string[];
  displayCalender:boolean;
  headerDate:Date; 
  HoursList:string[];
  MinitusList:string[];
  YearList:string[];
  selectedDate:string;
  selectedHour:string;
  selectedMin:string;
  selectedMeridian:string;
  selectedMonth:string;
  selectedYear:string;
  displayFlag:number; //- date calender  2- month 3- year
  ngOnInit(): void { 
    this.displayFlag=1;
    this.selectedMeridian=new Date().getHours()>12?'PM':'AM';
    this.headerDate=new Date();
    this.displayCalender=false;
    this.HoursList=[];
    this.MinitusList=[];
this.setHoursList();
this.setMinituesList();
   console.log('*** 18 ***')
     var cntrlid='#'+this.controlID;
      $(cntrlid).click(function(event){ 
          event.stopPropagation();
      });
      this.DesignCalender('');
  }
  
  @HostListener('document:click', ['$event']) 
  hide(){
    this.displayCalender=false;
    this.clearValues();
  }
  onChange: Function;
 
  onTouched: Function;
  
  private _value: string = '';

  get value() {
   console.log('*** 35 ***') 
    return this._value;
  }
 
  set value(value: string) {
    this._value = value;
   console.log('*** 42 ***')

    if (this.onChange) {
      this.onChange(value);
    }
  }
   
  @HostListener('click') click() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
    
 //call this function to set final value
  ChangeValue(dateValue) {
   console.log('*** 57 ***')
     if(this.selectedMonth.length>0){
      dateValue=new Date(new Date(dateValue).setMonth(Number(this.selectedMonth)));
     }
     this.selectedDate=this.convertWithTime(dateValue,true);
  //  final set
    //this.displayCalender=false;
  }
   PassValue(){ 
     console.log(this.selectedDate,this.selectedHour,this.selectedMin);
    let  date:Date= new Date(new Date(this.selectedDate).setHours(Number(this.selectedHour)));
    date=new Date(date.setMinutes(Number(this.selectedMin)));
       this.value =this.convertWithTime(date,true);
       this.displayCalender=false;
       this.clearValues();
   }
  writeValue(obj): void {
   console.log('*** 69 ***',obj) 
   if(this.checkIsDateValue(obj)){ 
   if(new Date(obj).getHours()>12){
    this.selectedMeridian='PM';
    this.selectedHour=String((new Date(obj).getHours()%12).toString()).padStart(2, '0');
    this.selectedMin=String((new Date(obj).getMinutes().toString()).padStart(2, '0'));
    
    console.log('set hour 92   = ',this.selectedHour);
   }
   else{
    this.selectedMeridian='AM';
   } 
   this.selectedMin=this.selectedMin==''?'00':this.selectedMin;
   this.selectedHour=this.selectedHour==''?'12':this.selectedHour;
   let date=new Date(new Date(obj).setHours(Number(this.selectedHour)));
   date=new Date(date.setMinutes(Number(this.selectedMin)));
    this.value =this.convertWithTime(date,true);
    this.selectedDate=this.value;
    this.DesignCalender(this.value);
   }
   else{
    this.value= 'Invalid Date';
    this.DesignCalender(new Date());
    if(new Date().getHours()>12){
      this.selectedMeridian='PM';
      this.selectedHour=String((new Date().getHours()%12).toString()).padStart(2, '0');
      this.selectedMin=String((new Date().getMinutes().toString()).padStart(2, '0')); 
     }
     else{
      this.selectedMeridian='AM';
      this.selectedHour=String((new Date().getHours()).toString()).padStart(2, '0');
      this.selectedMin=String((new Date().getMinutes().toString()).padStart(2, '0')); 
     }
   }
     
  }
 
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
   
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  checkIsDateValue(val){
    val=new Date(val);
    if(val && val!='Invalid Date'){
      if((val) instanceof Date){
        return true;
      }
    }
    return false;
  }
  convertWithTime(str,needTime) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2); 
      if(needTime){ 
        let result=[date.getFullYear(), mnth, day].join("-");
       let min= String((date.getMinutes().toString()).padStart(2, '0'));
       let hur=String(date.getHours().toString()).padStart(2, '0');
        return result+' '+hur+":"+min+" "+this.selectedMeridian;//+":"+date.getSeconds();
      }
      else{
        let result=[date.getFullYear(), mnth, day].join("-");
        return result;
      }
      
  }
   
  //Design Manage Begins
  onClicktxt(){ 
    this.displayFlag=1;
    if(this.checkIsDateValue(this.value)){ 
      if(new Date(this.value).getHours()>12){
       this.selectedMeridian='PM';
       this.selectedHour=String((new Date(this.value).getHours()%12).toString()).padStart(2, '0');
       this.selectedMin=String((new Date(this.value).getMinutes().toString()).padStart(2, '0'));
       
      }
      else{
       this.selectedMeridian='AM';
      } 
      let date=new Date(new Date(this.value).setHours(Number(this.selectedHour)));
      date=new Date(date.setMinutes(Number(this.selectedMin)));
       this.value =this.convertWithTime(date,true);
       this.selectedDate=this.value;
        this.DesignCalender(this.value);
      }
      else{
       this.value= 'Invalid Date';
       this.DesignCalender(new Date());
       if(new Date().getHours()>12){
         this.selectedMeridian='PM';
         this.selectedHour=String((new Date().getHours()%12).toString()).padStart(2, '0');
         this.selectedMin=String((new Date().getMinutes().toString()).padStart(2, '0')); 
        }
        else{
         this.selectedMeridian='AM';
         this.selectedHour=String((new Date().getHours()).toString()).padStart(2, '0');
         this.selectedMin=String((new Date().getMinutes().toString()).padStart(2, '0')); 
        }
      }
        
     
    this.displayCalender=true;
  
}
  preClick(){
    let dValue:Date;
    if(this.checkIsDateValue(this.headerDate)){
        dValue=new Date(this.headerDate);
    }
    else{
        dValue=new Date();
    }
    let d=this.convertWithTime(new Date(dValue.setMonth(dValue.getMonth()-1)),false);
    this.DesignCalender(d);
  }
  nextClick(){
    let dValue:Date;
    if(this.checkIsDateValue(this.headerDate)){
        dValue=new Date(this.headerDate);
    }
    else{
        dValue=new Date();
    }
    let d=this.convertWithTime(new Date(dValue.setMonth(dValue.getMonth()+1)),false);
    this.DesignCalender(d);
  }
  DesignCalender(dateValue){
    if(!this.checkIsDateValue(dateValue)){
      dateValue=new Date();
    }
    
    var t= new Date(dateValue); 
    this.headerDate=new Date(t);
    this.selectedMonth=t.getMonth().toString();
    console.log('date value ',t.toDateString())
    
    var lastdateOfMonth=(new Date(t.getFullYear(), t.getMonth() + 1, 0, 23, 59, 59).getUTCDate());
     
    var FirstWeekOfMonth=new Date(t.setDate(1)).getDay();
    console.log('First ',FirstWeekOfMonth)
    var i=0;  
    this.calenderDates=[];
    for (let i = 0; i < FirstWeekOfMonth; i++) {
       this.calenderDates.push(null);
    } 
     var i=0;   
    for (let i = 1; i <= lastdateOfMonth; i++) {
       this.calenderDates.push(this.convertWithTime(new Date(t.setDate(i)),false));
    } 
  }
  isSelectedDate(day){
    console.log('162--- ',day)
    return new Date(day).toDateString()==new Date(this.selectedDate).toDateString();
  }
  setHoursList(){
    for(let i=1;i<=12;i++){
      this.HoursList.push(String(i).padStart(2, '0') );
    }
  }
  setMinituesList(){
    for(let i=0;i<=59;i++){
      this.MinitusList.push(String(i).padStart(2, '0') );
    }
  }
  //Design Manage END
  clearValues()
  {
    this.selectedHour='';
    this.selectedDate='';
    this.selectedMeridian='';
    this.selectedMin='';
    this.selectedMonth=''; 
  }
  selectMonth(value){
    this.selectedMonth=value;
    let date=new Date(new Date(this.headerDate).setMonth(Number(this.selectedMonth)));
    this.DesignCalender(date);
    this.displayFlag=1;
  }
  selectYear(value){
    this.selectedYear=value;
    let date=new Date(new Date(this.headerDate).setFullYear(Number(this.selectedYear)));
    this.DesignCalender(date);
    this.displayFlag=1;
  }
  viewMonth(){
    this.displayFlag=2;
    
  }
  currentYear:number;
  viewYear(){ 
    this.YearList=[]; 
    let y:number=this.headerDate.getFullYear();
    this.selectedYear=y.toString();
    this.currentYear=Number(this.selectedYear);
    for(var i=y-10;i<y+10;i++){
    this.YearList.push(i.toString());
    }
    this.displayFlag=3;
  }
  
  loadPreYear(){ 
    this.YearList=[];
    this.currentYear=this.currentYear-10;
   let y= this.currentYear;
    for(var i=y-10;i<y+10;i++){
    this.YearList.push(i.toString());
    }
  }
  loadNextYear(){ 
    this.YearList=[];
    this.currentYear=this.currentYear+10;
   let y= this.currentYear;
     for(var i=y-10;i<y+10;i++){
     this.YearList.push(i.toString());
     }
   }
}