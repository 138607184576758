<!-- <div class="container-fluid" *ngIf="_CustomerfeedbackService.formHideShow==0">
    <div class="row flex-body">
        <div class="col-md-11 col-md-offset-1">
            <div class="box">
                <h4 class="box-title">Customer Feedbacks</h4>
            </div>
            <div class="col-md-4 offset-md-8 searchBox">
                <div class="form-group row no-gutters">
                    <div class="col-12 col-sm-6 col-md-2"><button type="button" class="btn-sm btn-info"
                            (click)="addNew()"><i class="fas fa-plus" aria-hidden="true"></i></button></div>
                    <div class="col-12 col-sm-6 col-md-8"><input type="text" class="form-control-search" name="search"
                            [(ngModel)]="term"></div>
                    <div class="col-6 col-md-2"><button class="btn-info"><i class="fas fa-search"
                                aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th (click)="sort('name')">Likes</th>
                        <th (click)="sort('code')"> Dislikes</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr *ngFor="let feedback of _CustomerfeedbackService.allCustomerFeedBack | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope">
                        <td>{{i+1+((NoOfItems*p)-NoOfItems)}}</td>
                        <td>{{feedback.Likestaff}}</td>
                        <td>{{feedback.DislikeStaff}}</td>
                        <td>
                            <button type="button" class="btn-success" (click)="OnEdit(feedback);">
                                <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                            <button type="button" class="btn-danger" (click)="onDelete(feedback.ID)"><i class="fas fa-trash"
                                    aria-hidden="true"></i></button>
                        </td>
                    </tr>
                </tbody>

            </table>
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
            <div class="margintop"></div>
        </div>
    </div>
</div> -->



<div class="container-fluid" *ngIf="_CustomerfeedbackService.formHideShow==0">
    
            <h2 class="heading_all">Customer Feedbacks</h2>
            <br>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4"></div>
                <div class="col-md-4 ">
        
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <button type="button" class="btn-sm btn-info"
                                    (click)="addNew()"><i class="fas fa-plus" aria-hidden="true"></i></button>
                                </td>
                                <td style="width: 70%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                                <td><button class="btn-info"><i class="fas fa-search"
                                    aria-hidden="true"></i></button></td>
                            </tr>
                        </thead>
                    </table>
        
                </div>
            </div>
            <br>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('Likestaff')">
                                <span *ngIf="!_CustomerfeedbackService.isFiltering">Likes</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_CustomerfeedbackService.orderByColName!='Likestaff' "></i>
                                    <div *ngIf="_CustomerfeedbackService.orderByColName=='Likestaff'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_CustomerfeedbackService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_CustomerfeedbackService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="_CustomerfeedbackService.isFiltering">
                                <input type="text" #txtLikesFilter="ngModel" [(ngModel)]="_CustomerfeedbackService.LikeFilter"
                                    placeholder="Likes">
                            </div>

                        </th>
                        <th class="hidden-xs">
                            <div class="AtjIsPoint" (click)="sort('DislikeStaff')">
                                <span *ngIf="!_CustomerfeedbackService.isFiltering">Dislikes</span>
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_CustomerfeedbackService.orderByColName!='DislikeStaff' "></i>
                                    <div *ngIf="_CustomerfeedbackService.orderByColName=='DislikeStaff'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_CustomerfeedbackService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!_CustomerfeedbackService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="_CustomerfeedbackService.isFiltering">
                                <input type="text" #txtDislikeStaffFilter="ngModel" [(ngModel)]="_CustomerfeedbackService.DisLikeFilter"
                                    placeholder="Dislikes">
                            </div>

                        </th>
                        <th><em></em> Settings
                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                <i *ngIf="!_CustomerfeedbackService.isFiltering" class="fas fa-filter"></i>
                                <i *ngIf="_CustomerfeedbackService.isFiltering" class="fas fa-times"></i>
                            </a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr *ngFor="let desig of _CustomerfeedbackService.allDesignations | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope"> -->
                    <tr *ngFor="let feedback of _CustomerfeedbackService.allCustomerFeedBack | orderBy : 'Likestaff' | gridOperations  :_CustomerfeedbackService.pageIndex:
                        {Likestaff:_CustomerfeedbackService.LikeFilter,DislikeStaff:_CustomerfeedbackService.DisLikeFilter}:
                       {Column:_CustomerfeedbackService.orderByColName,Dir:_CustomerfeedbackService.orderByDir} ; let i = index"
                        class="ng-scope">
                        <td>{{i+1+((NoOfItems*_CustomerfeedbackService.pageIndex)-NoOfItems)}}</td>
                        <td>{{feedback.Likestaff}}</td>
                        <td>{{feedback.DislikeStaff}}</td>
                        <td>
                            <button type="button" class="btn-success" (click)="OnEdit(feedback)">
                                <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                            <!-- <button type="button" class="btn-danger" (click)="onDelete(desig)"><i class="fas fa-trash"
                                    aria-hidden="true"></i></button> -->
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3"
                            *ngIf="(_CustomerfeedbackService.allCustomerFeedBack?.length<=0 )  || _CommonService.filterdItemCount<1">
                            No Records To
                            Display.</td>
                    </tr>
                </tbody>
                <tfoot>
                    <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                        <app-grid-pagination (pageIndexValue)="_CustomerfeedbackService.pageIndex=$event">
                        </app-grid-pagination>
                    </td>
                </tfoot>
            </table>
            <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->

            <div class="margintop"></div>
        <!-- </div>
    </div> -->
</div>