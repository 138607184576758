<div class="container-fluid" *ngIf="_ParticularmasterService.formHideShow==0">
    <!-- <div class="row flex-body">
        <div class="col-md-11 col-md-offset-1">
            <div class="box">
                <h4 class="box-title">ParticularMaster</h4>
            </div>
            <div class="col-md-4 offset-md-8 searchBox">
                <div class="form-group row no-gutters">
                    <div class="col-12 col-sm-6 col-md-2"><button type="button" class="btn-sm btn-info"
                            (click)="addNew()"><i class="fas fa-plus" aria-hidden="true"></i></button></div>
                    <div class="col-12 col-sm-6 col-md-8"><input type="text" class="form-control-search" name="search"
                            [(ngModel)]="term"></div>
                    <div class="col-6 col-md-2"><button class="btn-info"><i class="fas fa-search"
                                aria-hidden="true"></i></button>
                    </div>
                </div>
            </div> -->
    <h2 class="heading_all">PARTICULAR MASTER</h2>
    <br>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4 ">

            <table>
                <thead>
                    <tr>
                        <td>
                            <button type="button" class="btn-sm btn-info" (click)="addNew()"><i class="fas fa-plus"
                                    aria-hidden="true"></i></button>
                        </td>
                        <td style="width: 70%;"><input type="text" class="form-control-search" name="search"
                                [(ngModel)]="term"></td>
                        <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                    </tr>
                </thead>
            </table>

        </div>
    </div>

    <table>
        <thead>
            <tr>
                <th>#</th>
                <!-- <th (click)="sort('name')">Module</th>
                        <th (click)="sort('code')"> Particulars</th> -->
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('ModuleName')">
                        <span *ngIf="!_ParticularmasterService.isFiltering">Module</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_ParticularmasterService.orderByColName!='ModuleName' "></i>
                            <div *ngIf="_ParticularmasterService.orderByColName=='ModuleName'">
                                <i class="fas fa-sort-amount-down" *ngIf="_ParticularmasterService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_ParticularmasterService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_ParticularmasterService.isFiltering">
                        <input type="text" #txtModuleFilter="ngModel"
                            [(ngModel)]="_ParticularmasterService.ModuleNameFilter" placeholder="ModuleName">
                    </div>

                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Particulars')">
                        <span *ngIf="!_ParticularmasterService.isFiltering">Particulars</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_ParticularmasterService.orderByColName!='Particulars' "></i>
                            <div *ngIf="_ParticularmasterService.orderByColName=='Particulars'">
                                <i class="fas fa-sort-amount-down" *ngIf="_ParticularmasterService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_ParticularmasterService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_ParticularmasterService.isFiltering">
                        <input type="text" #txtParticularsFilter="ngModel"
                            [(ngModel)]="_ParticularmasterService.ParticularsFilter" placeholder="Particulars">
                    </div>

                </th>
                <th><em></em> Settings
                    <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                        <i *ngIf="!_ParticularmasterService.isFiltering" class="fas fa-filter"></i>
                        <i *ngIf="_ParticularmasterService.isFiltering" class="fas fa-times"></i>
                    </a>
                </th>
            </tr>
        </thead>
        <tbody>
            <!-- ngRepeat: item in items -->
            <tr *ngFor="let particular of _ParticularmasterService.allparticular | orderBy : 'Particulars' | gridOperations  :_ParticularmasterService.pageIndex: 
                     {ModuleName:_ParticularmasterService.ModuleNameFilter,Particulars:_ParticularmasterService.ParticularsFilter}:
                    {Column:_ParticularmasterService.orderByColName,Dir:_ParticularmasterService.orderByDir} ; let i = index"
                class="ng-scope">

                <td>{{i+1+((NoOfItems*_ParticularmasterService.pageIndex)-NoOfItems)}}</td>
                <td>{{particular.ModuleName}}</td>
                <td>{{particular.Particulars}}</td>
                <td>
                    <button type="button" class="btn-success" (click)="OnEdit(particular);">
                        <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                    <button type="button" class="btn-danger" (click)="onDelete(particular.ID)"><i class="fas fa-trash"
                            aria-hidden="true"></i></button>
                </td>
            </tr><!-- end ngRepeat: item in items -->
            <tr>
                <td colspan="3"
                    *ngIf="(_ParticularmasterService.allparticular?.length<=0 )  || _CommonService.filterdItemCount<1">
                    No Records To
                    Display.</td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                <app-grid-pagination (pageIndexValue)="_ParticularmasterService.pageIndex=$event">
                </app-grid-pagination>
            </td>
        </tfoot>


    </table>
    <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
    <div class="margintop"></div>
    <!-- </div>
    </div> -->
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'PARTICULAR MASTER'" (ConfirmEvent)="dialogResult($event)">
</app-delete-confirm>