import { Injectable } from '@angular/core';
import { Country } from '../Models/Country.Model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service'

import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  selectedCountry: Country;
  CountryList: Country[];
  formHideShow = 0;
  allCountries: Country[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  CodeFilter: string = '';
  NameFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  postCountry(cntry: Country): Observable<any>  {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Code: cntry.Code,
      Name: cntry.Name
    }

    return this.http.post<any>(ROOT_URL + 'Country/PostCountry', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  ClearFilter() {
    this.isFiltering = false;
    this.CodeFilter = '';
    this.NameFilter = '';

  }
  putCountry(cntry: Country) : Observable<any> {
    const params = new HttpParams().set('ID', cntry.Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Code: cntry.Code,
      Name: cntry.Name,

      ID: cntry.Id
    }

    return this.http.put<any>(ROOT_URL + 'Country/PutCountry/' + cntry.Id, body, {
      headers,
      params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getCountry(): Observable<Country[]> {
    return this.http.get<Country[]>(ROOT_URL + 'Country/GetAllCountry')
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );

  }

  deleteCountry(DeleteID:number) {

    return this.http.delete<any>(ROOT_URL + 'Country/DeleteCountry/' + DeleteID)
  }

}
