import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-spare-parts-form',
  templateUrl: './spare-parts-form.component.html',
  styleUrls: ['./spare-parts-form.component.css']
})
export class SparePartsFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  resetForm(form: NgForm) {

  }

  backList() {

  }
  onSubmit(form: NgForm) {

  }
}
