import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from 'src/app/Services/employee.service';

@Component({
  selector: 'app-popupmessage',
  templateUrl: './popupmessage.component.html',
  styleUrls: ['./popupmessage.component.css']
})
export class PopupmessageComponent implements OnInit {

  constructor(public employeeService:EmployeeService,
              public router:Router) { }     

  ngOnInit(): void {
    this.employeeService.employeeDashBoard={
      IsEnteredLastJobCard:true,
      LastEnteredJobCard:null,
    }
    this.employeeService.displayEmployeeDashBoard('EMPDASHBOARD');
  }

  closePopUp()
  {
    this.employeeService.employeeDashBoard.IsEnteredLastJobCard=true;
  }

  redirectJobCard()
  {
    this.router.navigate(['/jobcard'])
  }

}
