<div class="container-fluid">
    <div class="flex-body">
        <h2 class="heading_all">Support Engineer Dashboard</h2>
        <!-- <div class="tWrap"> -->
        <div class="row">
            <div class="col-md-4 panel">
                <!-- <canvas id="bar-chart-grouped" width="800" height="450"></canvas> -->
            </div>

            <div class="col-md-4">
                <div class="panel">
                    <div id="chartdiv"></div>
                </div>
            </div>
            <div class="col-md-4 panel">
                <!-- <canvas id="doughnut-chart" width="800" height="450"></canvas> -->
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="row" style="margin-right: 0px;margin-left: 0px;">
        <div class="tWrap">
            <div class="tWrap__head">
                <table>
                    <thead>
                        <tr>
                            <th scope="col">SNo</th>
                            <th scope="col">Customer</th>
                            <th scope="col">RDate</th>
                            <th scope="col">AttSE</th>
                            <th scope="col">DDate</th>
                            <th scope="col">OverDue</th>
                            <th scope="col">Priority</th>
                            <th scope="col">AMC Status</th>
                            <th scope="col">Service Mode</th>
                            <th scope="col">JoId</th>
                            <th scope="col">View</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="tWrap__body">
                <table>
                    <tbody>
                        <tr *ngFor="let obj of  this._serviceEngineerDBService.SelectedSEDashBoardCustReq;let i=index">
                            <td>{{i+1}}</td>
                            <td>
                                {{obj.CustomerName}}
                            </td>
                            <td>{{obj.RDate}}</td>
                            <td>
                                <span class="form-control-label"
                                    *ngFor="let field of _CustomerrequirementService.allSE">
                                    <p *ngIf="obj.SEID==field.Id">
                                        {{field.EmpName}}
                                    </p>
                                </span>
                            </td>
                            <td>{{obj.DDate}}</td>
                            <td>{{obj.OverDue}}</td>
                            <td>
                                <span class="form-control-label" *ngFor="let field of this.PriorityList">
                                    <p *ngIf="obj.PriorityId==field.Id">
                                        {{field.Name}}
                                    </p>
                                </span>
                            </td>
                            <td>{{obj.AmcStatus}}</td>
                            <td>
                                <label class="form-control-label">
                                    <p *ngIf="obj.ServiceMode==1">By Phone</p>
                                    <p *ngIf="obj.ServiceMode==2">By Mail</p>
                                    <p *ngIf="obj.ServiceMode==3">By Chat</p>
                                    <p *ngIf="obj.ServiceMode==4">On Site</p>
                                    <p *ngIf="obj.ServiceMode==5">Remote Section</p>
                                </label>
                            </td>
                            <td>{{obj.JoId}}</td>
                            <td>
                                <i class="fas fa-eye" (click)="OnClickAllot(obj)"
                                    style="font-size:24px;margin-left: 5px;" title="Job Order"></i>
                                <i class="fas fa-eye" (click)="getCusReqDet(obj)"
                                    routerLink="/supportEng/servicereportentry" style="font-size:24px;margin-left: 5px;"
                                    title="Service Report"></i>
                                <!-- <i class="fas fa-eye" (click)="getCusReqOrServiceEntry(obj)"
                                    style="font-size:24px;margin-left: 5px;" title="Customer Req Or Service Report"></i> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- </div> -->
        </div>
    </div>


    <!-- -----------------------------------------MODELS-------------------------------------------------- -->

    <!-- ------------------------------------------------------------------------------------------------- -->
    <!-- --------------------------------------Job Order-------------------------------------------------- -->
    <!-- ------------------------------------------------------------------------------------------------- -->
    <div class="container-fluid">

        <!-- The Modal -->
        <div class="modal fade show" id="myModalConfirm">
            <div class="modal-dialog">
                <div *ngIf="jobOrderForm">
                    <!--class="modal-dialog" -->
                    <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header">
                            <span class="modal-title">Job Order</span>
                            <button type="button" class="close" (click)="OnClickCancel()">×</button>
                        </div>
                        <div class="modal-body" style="overflow: scroll;height: 600px;">
                            <div class="tab-content">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label"
                                                style="color: #008080;">Job.No</label>
                                            <div class="form-group col-md-8">
                                                <input class="form-control" #JoNo="ngModel" name="JoNo" type="number"
                                                    disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.JoNo"
                                                    autocomplete="off" placeholder="Job No" required>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label" style="color: #008080;">Issue
                                                Date</label>
                                            <div class="form-group col-md-8">
                                                <input class="section3" placeholder="Issue Date" #IssueDate="ngModel"
                                                    name="IssueDate" disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.IssueDate"
                                                    style="color: black; text-align: center;width: 100px;"
                                                    autocomplete="off" bsDatepicker
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-5 form-control-label"
                                                style="color: #008080;">SEs</label>
                                            <div class="form-group col-md-6" style="border-style: solid;">
                                                <div class="row" style="margin-left: 0px;"
                                                    *ngFor="let obj of this._WorkAllocationService.SelectedJobOrderHdr.SEWorkDet">
                                                    <label>
                                                        <input type="checkbox" disabled #{{obj.checkStatus+i}}="ngModel"
                                                            [(ngModel)]="obj.checkStatus" name="obj.checkStatus"
                                                            [checked]="obj.checkStatus" value="{{obj.checkStatus}}">
                                                    </label>
                                                    <label class="form-control-label"
                                                        *ngFor="let field of  this._CustomerrequirementService.allSE">
                                                        <p *ngIf="field.Id==obj.SEId">
                                                            {{field.EmpName}}
                                                        </p>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group ">
                                            <label class="col-md-4 form-control-label"
                                                style="color: #008080;">Scheduled</label>
                                            <div>
                                                <input class="section3" placeholder="Issue Date" name="From" disabled
                                                    #From="ngModel"
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.From"
                                                    style="color: black; text-align: center;width: 100px;"
                                                    autocomplete="off" bsDatepicker
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                <label
                                                    style="color: #008080;">{{this._WorkAllocationService.SelectedJobOrderHdr.FromTime}}</label>

                                                <input class="section3" placeholder="Issue Date" name="To" disabled
                                                    #To="ngModel"
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.To"
                                                    style="color: black; text-align: center;width: 100px;"
                                                    autocomplete="off" bsDatepicker
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                <label
                                                    style="color: #008080;">{{this._WorkAllocationService.SelectedJobOrderHdr.ToTime}}</label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label style="color: #008080;">Customer</label>


                                            <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel"
                                                bindLabel="Name" bindValue="Id" disabled
                                                [items]="_CustomerService.allCustomers | orderBy : 'Name'" required
                                                [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.CustomerId">
                                            </ng-select>
                                        </div>

                                        <div style="color: #008080;line-height:50px;">
                                            <label style="padding-right: 50px;">Contract Date</label>:
                                            <label
                                                style="color:black">{{this._WorkAllocationService.SelectedJobOrderHdr.ContractDate}}</label>
                                        </div>
                                        <div style="color: red">
                                            <label>{{this._WorkAllocationService.SelectedJobOrderHdr.ContractVsCurrentDate}}</label>
                                        </div>
                                    </div>

                                    <div class="col-md-4">                                   
                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label"
                                                style="color: #008080;">Branch</label>
                                            <div class="form-group col-md-8">
                                                <select class="form-control" name="BranchId" #BranchId="ngModel"
                                                    disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchId"
                                                    required>
                                                    <option [ngValue]="null" disabled>Select
                                                    </option>
                                                    <option
                                                        *ngFor="let prf of this._BranchService.allBranchs | orderBy : 'BranchName'"
                                                        [ngValue]="prf.Id">
                                                        {{ prf.BranchName }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label"
                                                style="color: #008080;">Address</label>
                                            <div class="col-md-8">
                                                <input class="form-control" name="BranchAdr1" #BranchAdr1="ngModel"
                                                    autocomplete="off" disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr1"
                                                    placeholder=" Addr1" required>

                                                <input class="form-control" name="BranchAdr2" #BranchAdr2="ngModel"
                                                    autocomplete="off" disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr2"
                                                    placeholder=" Addr2" required>

                                                <input class="form-control" name="BranchAdr3" #BranchAdr3="ngModel"
                                                    autocomplete="off" disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr3"
                                                    placeholder=" Addr3" required>
                                                <input class="form-control" name="CPBranchPhone"
                                                    #CPBranchPhone="ngModel" disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.CPBranchPhone"
                                                    type="text" autocomplete="off" placeholder="ContactPerson" required>

                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label" style="color: #008080;">Contact
                                                Person Branch</label>
                                            <div class="form-group col-md-8">
                                                <input class="form-control" name="CPBranch" #CPBranch="ngModel"
                                                    autocomplete="off" disabled
                                                    [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.CPBranch"
                                                    placeholder=" Phone" required>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="select" style="color: #008080;">Project<span
                                                    style="color: red;">*</span></label>


                                            <ng-select name="ProjectId" id="ProjectId" #ProjectId="ngModel"
                                                bindLabel="Name" bindValue="Id" disabled
                                                [items]="this._projectService.allProjects" required
                                                [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.ProjectId">
                                            </ng-select>


                                        </div>
                                        <div class="form-group ">
                                            <div *ngIf=" _modulService.allModuleServiceReport.length>0"
                                                class="form-group ">
                                                <label style="color: #008080;">Modules</label>
                                            </div>
                                            <div *ngIf=" _modulService.allModuleServiceReport.length>0"
                                                class="form-group ">
                                                <div class="col-md-12">
                                                    <div class="tWrap">
                                                        <div class="tWrap__head">
                                                            <table>
                                                                <thead>
                                                                    <th scope="col">Modules</th>
                                                                    <th scope="col">Version</th>
                                                                    <th scope="col">Last Update</th>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <div class="tWrap__body">
                                                            <table>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let obj of _modulService.allModuleServiceReport | orderBy : 'ModuleName'; ;let i=index">
                                                                        <th style="background:white;color:black;">
                                                                            {{obj.ModuleName}}
                                                                        </th>
                                                                        <th style="background:white;color:black;">

                                                                        </th>
                                                                        <th style="background:white;color:black;">

                                                                        </th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="color: #008080;line-height: 40px;">
                                            <label style="padding-right:50px">No of Visits</label>:
                                            <label
                                                style="color:black">{{this._WorkAllocationService.SelectedJobOrderHdr.NumberOfVisit}}</label>
                                        </div>
                                        <div style="color: #008080;line-height: 40px;">
                                            <label style="padding-right:50px">Last Visited</label>:
                                            <label
                                                style="color: black">{{this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit}}</label>
                                        </div>
                                        <div style="color: #008080;line-height: 40px;">
                                            <label style="padding-right:30px">Last Visited SE</label>:
                                            <label style="color: black"
                                                *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE==0">No
                                                visitors</label>
                                            <label style="color: black"
                                                *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE!=0">{{this._CustomerrequirementService.selectedCustomerRequirement_Common.SE}}</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="tWrap">
                                        <div class="tWrap__head">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 60px;">SlNo</th>
                                                        <th scope="col" style="width: 350px;">Module</th>
                                                        <th scope="col" style="width: 350px;">Particulars</th>
                                                        <th scope="col" style="width: 350px;">Description</th>
                                                        <!-- <th scope="col">Remark</th>
                                                        <th scope="col">Status</th> -->
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div class="tWrap__body">
                                            <table>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let field of this._WorkAllocationService.SelectedJobOrderHdr.WorkSortingAssigning ;let i=index">
                                                        <td scope="row" style="width: 60px;">{{i+1}}</td>
                                                        <td style="width: 350px;">
                                                            <span class="form-control-label" disabled
                                                                *ngFor="let obj of   _modulService.allModules">
                                                                <p *ngIf="obj.Id==field.ModuleId">
                                                                    {{obj.ModuleName}}
                                                                </p>
                                                            </span>
                                                        </td>
                                                        <td style="width: 350px;">
                                                            <label class="form-control-label" disabled
                                                                *ngFor="let obj of  this._ParticularmasterService.allparticular | orderBy : 'Particulars'">
                                                                <p *ngIf="obj.ID==field.Particular">
                                                                    {{obj.Particulars}}
                                                                </p>
                                                            </label>
                                                        </td>
                                                        <td style="width: 350px;">
                                                            <!-- {{field.Description}} -->
                                                            <textarea type="text" name="Remark" id="Remark" required
                                                                [ngModel]="field.Description" style="width: 100%;"
                                                                disabled></textarea>

                                                        </td>
                                                        <!-- <td>{{field.Remark}}</td>
                                                        <td>
                                                            <label class="form-control-label" disabled
                                                                *ngFor="let obj of  StatusFullList">
                                                                <p *ngIf="obj.Id==field.StatusId">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </label>
                                                        </td> -->
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <label class="col-md-4  form-control-label" for="select"
                                        style="color: #008080;">Service Charge
                                        : {{this._WorkAllocationService.SelectedJobOrderHdr.ServiceCharge}}</label>
                                    <!-- <div class="col-md-4">
                                        <input type="text" placeholder="Service Charge" class="form-control"
                                            autocomplete="off" name="ServiceCharge" #ServiceCharge="ngModel"
                                            [(ngModel)]="this._WorkAllocationService.SelectedJobOrderHdr.ServiceCharge"
                                            required>
                                    </div>
                                    <div class="col-md-4">

                                    </div> -->
                                </div>


                                <br>
                                <div *ngIf="this._WorkAllocationService.SelectedJobOrderHdr.AttachmentList.length>0"
                                    class="row">
                                    <div class="col-md-6">
                                        <div class="tWrap">
                                            <div class="tWrap__head">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">*</th>
                                                            <th scope="col">FileName</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let obj of this._WorkAllocationService.SelectedJobOrderHdr.AttachmentList; let i=index">
                                                            <th style="background:white;color:black;" scope="row"><i
                                                                    class="fas fa-chevron-circle-right"></i></th>
                                                            <td (click)="downloadFileUrl(obj)"
                                                                style="cursor: pointer; text-decoration: underline;">
                                                                {{obj.FileName}} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div *ngIf="WarningModel">

                    <!--class="modal-dialog" -->
                    <div class="modal-content" style="width: 62%;">
                        <!-- Modal Header -->
                        <div class="modal-header">
                            <!-- <span class="modal-title">Job Order</span> -->
                            <button type="button" class="close" (click)="OnClickCancel()">×</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- ------------------------------------------------------------------------------------------------- -->
    <!-- --------------------------------Customer requirement---------------------------------------- -->
    <!-- ------------------------------------------------------------------------------------------------- -->



    <app-custom-customer-requirement *ngIf="this._CommonService.customerrequirementPopUp" [refno]="refno"
        [DealerId]="DealerId" [BranchId]="BranchId" [title]="'Customer Requirement'"></app-custom-customer-requirement>


    <!-- ------------------------------------------------------------------------------------------------- -->
    <!-- --------------------------------Service Report entry form---------------------------------------- -->
    <!-- ------------------------------------------------------------------------------------------------- -->
    <app-custom-service-report-entry *ngIf="this._CommonService.servicereportentryPopUp" [refno]="refno"
        [DealerId]="DealerId" [BranchId]="BranchId" [title]="'Service Report Entry'"></app-custom-service-report-entry>



</div>