import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SupportEngineerHomeScreenComponent } from './support-engineer-home-screen/support-engineer-home-screen.component';
import { CustomerRequirementsComponent } from './customer-requirements/customer-requirements.component';
import { ServiceReportEntryComponent } from './service-report-entry/service-report-entry.component';
import { CustomerFeedbackComponent } from './customer-feedback/customer-feedback.component';
import { CustomerFeedBackMasterComponent } from './customer-feed-back-master/customer-feed-back-master.component';


const routes: Routes = [
  { path: '', component: SupportEngineerHomeScreenComponent },  
      { path: 'customerrequirement', component: CustomerRequirementsComponent  },
      { path: 'servicereportentry', component: ServiceReportEntryComponent  },
      { path: 'customerfeedback', component: CustomerFeedbackComponent },
      { path: 'customerfeedbackmaster', component: CustomerFeedBackMasterComponent  }, 

]
@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes)
  ]
})
export class SupportengModule { }
