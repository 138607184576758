<nav class="navbar navbar-expand-lg navbar-light fixed-top bg-light">
    <a href="#" data-target="#sidebar" data-toggle="collapse"><i class="fa fa-navicon fa py-2 p-1"></i></a>
    <div class="navbar-brand" href="#"><img src="../../../../assets/Images/quickbis.png" class="logo_img" width="80px"
            height="40px"></div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item">
                <h2><b>PROJECT MANAGEMENT SYSTEM</b></h2>
            </li>
        </ul>
        <ul class="nav justify-content-end">
            <li class="nav-item">
                <h5>Resmi Jose</h5>
            </li>
            <li class="nav-item">
                <div class="dropdown">
                    <button data-toggle="dropdown" class="dropdown-toggle btn-success"><img
                            src="../../../../assets/Images/Nasta.png">&nbsp;&nbsp;</button>
                    <ul class="dropdown-menu">
                        <li (click)="Logout()">
                            <!-- routerLink="/login" -->
                            <p>
                                <i class="fas fa-sign-out-alt fas-3x" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;logout
                            </p>
                        </li>
                        <li>
                            <a *ngIf="userService.roleMatch(['Project Manager', 'Dealer','Support Leader','Support Engineer'])"
                                routerLink="/changepassword">
                                <i class="fas fa-key" aria-hidden="true"></i>Change password</a>
                        </li>
                    </ul>
                </div>
            </li>
            <form class="form-inline">
            </form>
        </ul>
        <ul class="nav justify-content-end">
            <li class="nav-item">
            </li>
        </ul>
    </div>
</nav>