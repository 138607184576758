import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { EmployeeWorkForcast, EmpWorkStatus, PLEmployeeWorksForecast, PLWorkDetailsDet, PLWorkDetailsHdr, PLWorkDetailsHdrFilter, WorkAllotmentActivitiesDetails,ActivityWiseEmployeeDeleteOption,ActivityWiseTestResultHistory,ActivityWiseTestResultFeedBackHistory,ActivityWiseWorkPostPoned } from '../Models/PLWorkDetailsHdr.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import * as CryptoJS from 'crypto-js';
import { CustomerRequirement_Attachments } from '../Models/CustomerRequirements';
import { ActivityRemarks } from '../Models/ActivityHold';
import { Response} from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class ProjectLeaderService {
  activityPopUpDetails: WorkAllotmentActivitiesDetails;
  PLWorkDetailsHdrList: PLWorkDetailsHdr[];
  PLWorkDetailsHdrListNotFiltered: PLWorkDetailsHdr[];
  PLWorkDetailsFilter: PLWorkDetailsHdrFilter;
  PLWorkDetailsDetList: PLWorkDetailsDet[]
  PLEmployeeWorksForecastList: PLEmployeeWorksForecast[]
  EmployeeWorkForcastDetails: EmployeeWorkForcast[];// To Display all emp work forcast
  orderByColName: string = '';
  orderByDir: boolean = false;
  JobAssignAttachmentList: CustomerRequirement_Attachments[]
  JobAssignAttachementRemovedList: CustomerRequirement_Attachments[]
  ListActivityWiseDeleteOption: ActivityWiseEmployeeDeleteOption[]=[];
  fliterEmpId:number=0;

  testResultHistoryPopUp:boolean=false;
  allActivityWiseTestResultHistory:ActivityWiseTestResultHistory;
  isFiltering: boolean = false;
  qaEngineerFilter:string
  testResultFliter:string
  qaResultFliter:string
  orderByTestResultDir: boolean = false;
  orderByTestResultColName: string = '';
  qaEngineerTestFeedBackFilter:string
  orderByTestFeedBacktDir: boolean = false;
  orderByTestFeedBackColName: string = '';
  allActivityWiseTestFeedBackHistory:ActivityWiseTestResultFeedBackHistory[]=[];
  activityWiseWorkPostPoned:ActivityWiseWorkPostPoned;

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  fetchProject(TransMode: string,employeeID,batchId:number=0): Observable<PLWorkDetailsHdr[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode),
    params = params.append('EmployeeID',employeeID==''?'0':employeeID.toString())
    params = params.append('BatchId',batchId.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<PLWorkDetailsHdr[]>(ROOT_URL + 'ProjectLeader/PLWorkList_Fetch', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  fetchProjectDelivery(transMode: string,isUnDelivered:number): Observable<PLWorkDetailsHdr[]> {
    let params = new HttpParams();
    params = params.append('TransMode', transMode)
    params = params.append('IsUnDelivered',isUnDelivered.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<PLWorkDetailsHdr[]>(ROOT_URL + 'ProjectLeader/PLWorkDelivery_Fetch', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  fetchProjectDet(obj: PLWorkDetailsHdr): Observable<PLWorkDetailsDet[]> {
    const body = {
      Vtype: obj.Vtype,
      RegNo: obj.RegNo,
      DealerId: obj.DealerId
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<PLWorkDetailsHdr[]>(ROOT_URL + 'ProjectLeader/PLWorkDetList_Fetch', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  GetWorkStatus(Jono: number, Activity: number): Observable<EmpWorkStatus[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<EmpWorkStatus[]>(ROOT_URL + 'ProjectLeader/GetWorkStatus?JONO=' + Jono + '&Activity=' + Activity, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  LoadEmployeesList(JoNo: number) {
    let params = new HttpParams();
    params = params.append('JoNo', JoNo.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<PLWorkDetailsHdr[]>(ROOT_URL + 'ProjectLeader/PLLoadEmployeesList', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getEmployeeWorksForecast(TransMode: string, Jono: number,ActivityID :number): Observable<PLEmployeeWorksForecast[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('Jono', Jono.toString());
    params = params.append('ActivityID', ActivityID.toString());
    console.log(TransMode, Jono,ActivityID)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<PLEmployeeWorksForecast[]>(ROOT_URL + 'ProjectLeader/getEmployeeWorksForecast', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  onAllottWork(TransMode: string, fileToUpload: File[], Department: number, Activity: PLWorkDetailsHdr[], Employee: PLEmployeeWorksForecast[], Remark: string, JoId: number): Observable<any> {
    let headers = new HttpHeaders().set('contentType', 'false');
    const frmData = new FormData();
    frmData.append('Activities', JSON.stringify(Activity));
    frmData.append('Employees', JSON.stringify(Employee));
    //Added by renjith
    if (fileToUpload) {
      for (var i = 0; i < fileToUpload.length; i++) {
        frmData.append('Document', fileToUpload[i]);
      }
    }
    frmData.append('RemovedList', this.JobAssignAttachementRemovedList.length > 0 ? JSON.stringify(this.JobAssignAttachementRemovedList) : '');
    frmData.append('JobAssignAttachment', this.JobAssignAttachmentList.length > 0 ? JSON.stringify(this.JobAssignAttachmentList) : '');
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('JoId', JoId.toString());
    params = params.append('Remark', Remark.length > 0 ? Remark : '  ');
    params = params.append('Department', Department.toString());
    return this.http.post<any>(ROOT_URL + "ProjectLeader/onAllottWork", frmData, {
      headers, params,
    });
  }
  GetEmployeeWorkForcastDetails(TransMode: string, Date: Date, SupportLeaderID: number): Observable<EmployeeWorkForcast[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('Date', Date.toLocaleDateString());
    params = params.append('SupportLeaderId', SupportLeaderID.toString());

    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<EmployeeWorkForcast[]>(ROOT_URL + 'ProjectLeader/GetEmployeeForecasting', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  ClearFilter() {
    this.PLWorkDetailsFilter = {
      CustomerName: '',ActivityID:'',
      DealerName: '', DealerId: '', CustomerID: '', EstHrs: '', JoNo: '', ModuleId: '', ModuleText: '', ParticularId: '',
      ParticularText: '', PriorityId: '', PriorityText: '', RegDate: '', RegNo: '', RepDate: '', Stage: '', StageText: '', Status: '',
      StatusText: '', TestResult: '', TestResultText: '', Type: '', TypeText: '', Vtype: '', VtypeText: '',EmployeeId:'',Batch:'',
      PDate:'',EstDate:''
    }
  }
  ClearTestResultFilter()
  {
    this.isFiltering=false;
    this.qaEngineerFilter="";
    this.testResultFliter="";
    this.qaResultFliter="";
  }
  
  DeleteActivity(Activity,EmployeeID)
  {
    let params = new HttpParams();
    params = params.append('Activity', Activity);
    params = params.append('EmployeeID', EmployeeID);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete<Response>(ROOT_URL + 'ProjectLeader/DeleteActivity',{
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  // GetAttachmentsByJoNo(JoNo: number): Observable<CustomerRequirement_Attachments[]> {
  //   let params = new HttpParams();
  //   params = params.append('JoNo', JoNo.toString())
  //   const headers = new HttpHeaders().set('content-type', 'application/json');
  //   return this.http.get<CustomerRequirement_Attachments[]>(ROOT_URL + 'ProjectLeader/GetAttachmentsByJoNo', {
  //     headers, params
  //   })
  //     .pipe(
  //       catchError((error) => this._GlobalErrorHandlerService.handleError(error))
  //     );
  // }

  getActivityWiseDeleteOption(Activity,Status,Stage) :Observable<ActivityWiseEmployeeDeleteOption[]>
  {
    let params = new HttpParams();
    params=params.append('Activity', Activity)
    params=params.append('Status', Status)
    params=params.append('Stage', Stage)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ActivityWiseEmployeeDeleteOption[]>(ROOT_URL + 'ProjectLeader/GetActivityWiseDeleteOption',{
      headers,params
    })
    .pipe(
      catchError((error)=> this._GlobalErrorHandlerService.handleError(error))
    );
  }

  getActivityWiseChanges(activityId,transType)
  {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params=new HttpParams();
    params=params.append('ActivityId',activityId)
    params=params.append('TransType',transType)
    return this.http.get<any>(ROOT_URL+ "ProjectLeader/GetActivityWiseChanges",{
      params,headers
    })
    .pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  
  workDateExtented(activityId,startDate,compDate,reasonId,totalEstMin)
  {
    let params = new HttpParams();
    params=params.append('ActivityId', activityId)
    params=params.append('StartDate',startDate)
    params=params.append('CompDate', compDate)
    params=params.append('TotalEstMin', totalEstMin)
    params=params.append('ReasonId', reasonId)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.put<Response>(ROOT_URL + 'ProjectLeader/WorkDateExtented',{},{
      headers,params
    })
    .pipe(
      catchError((error)=> this._GlobalErrorHandlerService.handleError(error))
    );
  }
  
  getActivityWiseTestResultHistory(ActivityId:number)
  {
    let params = new HttpParams();
    params=params.append('ActivityId', ActivityId.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ActivityWiseTestResultHistory>(ROOT_URL + 'ProjectLeader/GetActivityWiseTestResultHistory',{
      headers,params
    })
    .pipe(
      catchError((error)=> this._GlobalErrorHandlerService.handleError(error))
    );
  }

  getActivityWiseTestResultFeedBackHistory(ActivityId:number,EmployeeId:number)
  {
    let params = new HttpParams();
    params=params.append('ActivityId', ActivityId.toString())
    params=params.append('EmployeeId', EmployeeId.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ActivityWiseTestResultFeedBackHistory[]>(ROOT_URL + 'ProjectLeader/GetActivityWiseTestResultFeedBackHistory',{
      headers,params
    })
    .pipe(
      catchError((error)=> this._GlobalErrorHandlerService.handleError(error))
    );
  }

}
