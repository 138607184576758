<div class="container-fluid">
    <h2 class="heading_all">JOB SCHEDULING</h2>
    <br>
    <div id="form-wrap">
        <form #jobSchedulingForm="ngForm" (ngSubmit)="onSubmit(jobSchedulingForm)">
            <div class="row">
                    <div class="col-md-2">
                        <div class="form-group row">
                            <label><span class="validation-error">*</span>Date:</label>
                            <input class="form-control" placeholder="Date" name="Date" id="Date" autocomplete="off" bsDatepicker  
                                #VDate="ngModel" [(ngModel)]="_JobschedulingService.selectedJobScheduling.VDate" [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY'}" required>
                        </div>  
                    </div>
                    <div class="col-md-2">
                        <label><span class="validation-error">*</span>Batch Name</label>
                        <input class="form-control" type="text" name="BatchName" autocomplete="off" placeholder="Batch Name" maxlength="1000"
                          #BatchName="ngModel" [(ngModel)]="_JobschedulingService.selectedJobScheduling.BatchName"  style="display: flex;">
                    </div>
                    <div class="col-md-2">
                        <div class="form-group row">
                            <label><span class="validation-error">*</span>Customer</label>
                            <ng-select name="Customer" id="Customer"
                                bindLabel="Name" bindValue="Id" (change)="this.loadBatch()" 
                                [items]="_JobschedulingService.allCustomer" #Customer="ngModel"
                                [(ngModel)]="_JobschedulingService.selectedJobScheduling.CustomerId" placeholder="Select">
                            </ng-select>
                        </div>
                    </div> 
                    <div class="col-md-1">
                        <button type="button" name="Schedule" class="sim-button button1" style="width:75%; margin-top: 25px;" (click)="loadCustomerWiseUnScheduledJobs()">Search</button> 
                    </div>
                    <div class="col-md-2">
                        <div class="form-group row">
                            <label>Batch</label>
                            <ng-select name="Batch" id="Batch"
                                bindLabel="Name" bindValue="Id" 
                                [items]="_JobschedulingService.allBatch" #Batch="ngModel"
                                [(ngModel)]="_JobschedulingService.selectedJobScheduling.Id" placeholder="Select">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-2" style="margin-top:34px;">
                        <label>Finished</label>
                        <input type="checkbox" id="finished" name="finished"  [(ngModel)]="this.finished">
                        <label>UnFinished</label>
                        <input type="checkbox" id="unFinished" name="unFinished"  [(ngModel)]="this.unFinished">  
                    </div>
                    <div class="col-md-1">
                        <button type="button" name="Schedule" class="sim-button button1" style="width:75%; margin-top: 25px;" (click)="search()">Search</button> 
                    </div>
                </div>                    
         <div class="tWrap">
            <table>
                <thead>
                    <th>
                        <input type="checkbox" id="alljobs" name="alljobs" [(ngModel)]="selectAll" (change)="selectedJobs(1,'',-1)">
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_JobschedulingService.isFiltering"
                                class="fas fa-filter"></i>
                            <i *ngIf="_JobschedulingService.isFiltering"
                                class="fas fa-times"></i>
                        </a>
                    </th>
                    <th>Sno</th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('ActivityId')">
                            <span *ngIf="!_JobschedulingService.isFiltering">ID</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_JobschedulingService.orderByColName!='ActivityId'"></i>
                                <div *ngIf="_JobschedulingService.orderByColName=='ActivityId'">
                                    <i class="fas fa-sort-amount-down"
                                        *ngIf="_JobschedulingService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_JobschedulingService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_JobschedulingService.isFiltering">
                            <input type="text" #txtActivityIdFilter="ngModel" id="ActivityIdFilter" name="ActivityIdFilter"
                                [(ngModel)]="_JobschedulingService.ActivityIdFilter" placeholder="ID">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Particulars')">
                            <span *ngIf="!_JobschedulingService.isFiltering">Particulars</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_JobschedulingService.orderByColName!='Particulars'"></i>
                                <div *ngIf="_JobschedulingService.orderByColName=='Particulars'">
                                    <i class="fas fa-sort-amount-down"
                                        *ngIf="_JobschedulingService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_JobschedulingService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_JobschedulingService.isFiltering">
                            <input type="text" #txtParticularFilter="ngModel" id="ParticularFilter" name="ParticularFilter"
                                [(ngModel)]="_JobschedulingService.ParticularFilter" placeholder="Particular">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Description')">
                            <span *ngIf="!_JobschedulingService.isFiltering">Description</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_JobschedulingService.orderByColName!='Description'"></i>
                                <div *ngIf="_JobschedulingService.orderByColName=='Description'">
                                    <i class="fas fa-sort-amount-down"
                                        *ngIf="_JobschedulingService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_JobschedulingService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_JobschedulingService.isFiltering">
                            <input type="text" #txtDescriptionFilter="ngModel" id="DescriptionFilter" name="DescriptionFilter"
                                [(ngModel)]="_JobschedulingService.DescriptionFilter" placeholder="Description">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Type')">
                            <span *ngIf="!_JobschedulingService.isFiltering">Type</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_JobschedulingService.orderByColName!='Type'"></i>
                                <div *ngIf="_JobschedulingService.orderByColName=='Type'">
                                    <i class="fas fa-sort-amount-down"
                                        *ngIf="_JobschedulingService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_JobschedulingService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_JobschedulingService.isFiltering">
                            <input type="text" #txtTypeFilter="ngModel" id="TypeFilter" name="TypeFilter"
                                [(ngModel)]="_JobschedulingService.TypeFilter" placeholder="Type">
                        </div>
                    </th>
                    <th>EsHrs</th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Stage')">
                            <span *ngIf="!_JobschedulingService.isFiltering">Stage</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_JobschedulingService.orderByColName!='Stage'"></i>
                                <div *ngIf="_JobschedulingService.orderByColName=='Stage'">
                                    <i class="fas fa-sort-amount-down"
                                        *ngIf="_JobschedulingService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_JobschedulingService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_JobschedulingService.isFiltering">
                            <input type="text" #txtStageFilter="ngModel" id="StageFilter" name="StageFilter"
                                [(ngModel)]="_JobschedulingService.StageFilter" placeholder="Stage">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Status')">
                            <span *ngIf="!_JobschedulingService.isFiltering">Status</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_JobschedulingService.orderByColName!='Status'"></i>
                                <div *ngIf="_JobschedulingService.orderByColName=='Status'">
                                    <i class="fas fa-sort-amount-down"
                                        *ngIf="_JobschedulingService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_JobschedulingService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_JobschedulingService.isFiltering">
                            <input type="text" #txtStatusFilter="ngModel" id="StatusFilter" name="StatusFilter"
                                [(ngModel)]="_JobschedulingService.StatusFilter" placeholder="Status">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('ExpCDate')">
                            <span *ngIf="!_JobschedulingService.isFiltering">ExpCDate</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort"
                                    *ngIf="_JobschedulingService.orderByColName!='ExpCDate'"></i>
                                <div *ngIf="_JobschedulingService.orderByColName=='ExpCDate'">
                                    <i class="fas fa-sort-amount-down"
                                        *ngIf="_JobschedulingService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt"
                                        *ngIf="!_JobschedulingService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_JobschedulingService.isFiltering">
                            <input type="text" #txtExpCDateFilter="ngModel" id="ExpCDateFilter" name="ExpCDateFilter"
                                [(ngModel)]="_JobschedulingService.ExpCDateFilter" placeholder="ExpCDate">
                        </div>
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let jobs of _JobschedulingService.allUnScheduledJobs | gridOperations :null: 
                         { ActivityId:_JobschedulingService.ActivityIdFilter,Description:_JobschedulingService.DescriptionFilter,
                           Particulars:_JobschedulingService.ParticularFilter,Type:_JobschedulingService.TypeFilter,
                           Stage:_JobschedulingService.StageFilter,Status:_JobschedulingService.StatusFilter,
                           ExpCDate:_JobschedulingService.ExpCDateFilter}:
                           {Column:_JobschedulingService.orderByColName,Dir:_JobschedulingService.orderByDir};let i = index">
                        <td><input type="checkbox" id="{{i}}jobs" name="{{i}}jobs" (change)="selectedJobs(2,jobs,i)" #{{i}}jobs="ngModel" [(ngModel)]="jobs.Chk"></td>
                        <td>{{i+1}}</td>
                        <td>{{jobs.ActivityId}}</td>
                        <td title="{{jobs.Particulars}}">{{jobs.Particulars}}</td>
                        <td title="{{jobs.Description}}">{{jobs.Description}}</td>
                        <td>{{jobs.Type}}</td>
                        <td>{{jobs.EstHrs}}</td>
                        <td>{{jobs.Stage}}</td>
                        <td>{{jobs.Status}}</td>
                        <td>{{jobs.ExpCDate}}</td>
                    </tr>
                </tbody>
            </table> 
        </div>        
            <div class="row">
                <div class="col-md-2">
                    <label style="margin-top:34px;margin-left:99px;">Completion Date:</label>
                </div>
                <div class="col-md-2">
                    <label>Design</label>
                    <input class="form-control" name="DesignDate" #DesignDate="ngModel" 
                        autocomplete="off" [(ngModel)]="_JobschedulingService.selectedJobScheduling.DesignDate" bsDatepicker  
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' } ">
                </div>
                <div class="col-md-2">
                    <label>Development<span class="validation-error">*</span></label>
                    <input class="form-control" name="DevelopmentDate" #DevelopmentDate="ngModel" 
                        autocomplete="off" [(ngModel)]="_JobschedulingService.selectedJobScheduling.DevelopmentDate" bsDatepicker  
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                </div>
                <div class="col-md-2">
                    <label>Testing<span class="validation-error">*</span></label>
                    <input class="form-control" name="TestingDate" #TestingDate="ngModel" 
                        autocomplete="off" [(ngModel)]="_JobschedulingService.selectedJobScheduling.TestingDate" bsDatepicker  
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                </div>
                <div class="col-md-2">
                    <label>Delivery<span class="validation-error">*</span></label>
                    <input class="form-control" name="DeliveryDate" #DeliveryDate="ngModel" 
                        autocomplete="off" [(ngModel)]="_JobschedulingService.selectedJobScheduling.DeliveryDate" bsDatepicker  
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                </div>             
            </div>
            <div class="row">
                <div class="col-md-3">
                    <button type="submit" name="Schedule" class="sim-button button1" style="width:75%; margin-top: 25px;">Schedule</button>           
                </div>
                <div class="col-md-3">
                    <button type="button" name="Reset" class="sim-button button1" style="width:75%; margin-top: 25px;" (click)="delete()">Delete</button>
                </div>
                <div class="col-md-3">
                    <button type="button" name="Reset" class="sim-button button1" style="width:75%; margin-top: 25px;" (click)="reset()">Reset</button>
                </div>
            </div>
        </form>
    </div>
</div>