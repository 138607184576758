<div class="container-fluid" *ngIf="_routeService.formHideShow!=0">
    <h2 class="heading_all">Route Master</h2>
    <br>
    <div id="form-wrap">
        <form #routeForm="ngForm" (ngSubmit)="onSubmit(routeForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_routeService.selectedRoute.Id">
            <div class="col-md-12">

                <div class="form-group">
                    <label>Route<span style="color: red;">*</span></label>
                    <input name="RouteName" #RouteName="ngModel" autocomplete="off"
                        [(ngModel)]="_routeService.selectedRoute.RouteName" placeholder=" Route Name" required>
                    <div class="validation-error"
                        *ngIf="(RouteName.invalid && RouteName.touched)||(RouteName.invalid && this.FormValidation)">
                        This Field is
                        Required.</div>
                </div>
                <div class="form-group">
                    <label>Country</label>

                    <select disabled class="form-control" name="CountryId" #CountryId="ngModel"
                        [(ngModel)]="_routeService.selectedRoute.CountryId" required>
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let prf of _countryService.allCountries | orderBy : 'Name'" [ngValue]="prf.Id">
                            {{ prf.Name }}
                        </option>
                    </select>
                    <div class="validation-error"
                        *ngIf="(CountryId.invalid && CountryId.touched)||(CountryId.invalid && this.FormValidation)">
                        This Field is
                        Required.</div>

                </div>

                <div class="row">
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" [disabled]="this.isSubmit" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                            (click)="resetForm(routeForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- </div>
        </div> -->
</div>
<!-- </form> -->