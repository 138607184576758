import { Component, OnInit } from '@angular/core';
// import { Chart } from 'chart.js';
import * as moment from 'moment';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { CommonService } from 'src/app/Services/common.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { SEDashBoardCustReq } from 'src/app/Models/Dashboard.Model';
import { NgForm } from '@angular/forms';
import { EmployeeService } from 'src/app/Services/employee.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-manager-home',
  templateUrl: './project-manager-home.component.html',
  styleUrls: ['./project-manager-home.component.css']
})
export class ProjectManagerHomeComponent implements OnInit {

  public errorMsg;
  PriorityList: any;
  TypeList: any;
  StatusList: any;
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;

  constructor(public _CustomerrequirementService: CustomerrequirementService,
              public _CommonService: CommonService,
              public _BranchService: BranchService,
              public _serviceEngineerDBService: ServiceEngineerDBService,
              public _EmployeeService: EmployeeService,
              public _CustomerService: CustomerService,
              public router:Router) { }

  ngOnInit(): void {
    this._CommonService.isLoading = false;
    this.Reset();
    this.getProgrammersList();
    this.LoadCustomers();
    this._CommonService.displayKeyExpiryCount();
    this.PriorityList = this._CommonService.GetPriorityList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusList = this._CommonService.GetStatusFullList();
    this.LoadDealerDashboardTodaysSchdule();
    this._CommonService.storeloginUserId();
  }

  Reset(form?: NgForm) {
    this._BranchService.selectedBranch = {
      Addr1: null,
      Addr2: null,
      Addr3: null,
      BranchEmail: null,
      BranchFax: null,
      BranchName: null,
      Customer:null,
      BranchPhone: null,
      ContactEmail: null,
      ContactPerson: null,
      ContactPhone: null,
      CountryId: null,
      CustomerId: null,
      DealerId: null,
      Id: null,
      RouteId: null,
      UniqueBrachId: '',
    }
    this._CommonService.KeyExpiryCount={
      LicenseKey:0,AMC:0,CloudServer:0,CustomerOutstanding:0
    }
    this._serviceEngineerDBService.selectedDate = new Date()
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    this._CustomerService.allCustomers = []
    this._CustomerrequirementService.allSE = []
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('ALL')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  LoadDealerDashboardTodaysSchdule() {
    this._CustomerrequirementService.DealerDashBoardTodaysSchdule = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("SLTODAYS", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.DealerDashBoardTodaysSchdule = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  p: number = 1;
  LoadDealerDashboardCustReqData() {
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("SLID", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.SelectedDealerDashBoardCustReq = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  SelectedIndexRefNo: number = null;
  SelectedIndexVtype: number = null;
  showForEdit(Backlog: SEDashBoardCustReq) {
    this.SelectedIndexRefNo = Backlog.RefNo;
    this.SelectedIndexVtype = Backlog.Vtype;
    this._serviceEngineerDBService.AllWorkSortingDet = []
    this._serviceEngineerDBService.getPendingWorksDet(Backlog.RefNo, Backlog.Vtype, Backlog.DealerId, 'COMMON').subscribe(data => {
      this._serviceEngineerDBService.AllWorkSortingDet = data
    })

    this._BranchService.Fetch_BranchById(Backlog.BranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.selectedBranch = obj[0]
    })
  }

  getProgrammersList() {
    this._EmployeeService.selectedDate=this.convert(this._serviceEngineerDBService.selectedDate.toString());
      this._EmployeeService.Fetch_Employee('ADMINPRMS').subscribe(data => {
      this._EmployeeService.allEmployees = data;
    })
  }

  checkDate(date): boolean {
    date = new Date(date)
    return !(moment(date.toLocaleDateString()).isSame(new Date().toLocaleDateString()))
  }

  //activates the menu with the coordinates
  onrightClick(event,id,empName) {
    this.contextmenuX = 120
    this.contextmenuY = 120
    this.contextmenu = true;
    this._EmployeeService.selectedEmployeeData(id,empName,0)
  }
  disableContextMenu() {
    this.contextmenu = false;
  }
  jobForeCasting()
  {
    this.router.navigate(["/asjobsmangement"]);
  }
}
