import { Component, OnInit } from '@angular/core';
import { Worksheet } from 'src/app/Models/Backlogs';
import { CommonService } from 'src/app/Services/common.service';
import { JobCardService } from 'src/app/Services/job-card.service';
import { NgForm } from '@angular/forms';
import { EmployeeService } from 'src/app/Services/employee.service';
import { DateformatPipe } from '../../../../CustomPipe/dateformat.pipe';

@Component({
  selector: 'app-work-sheet',
  templateUrl: './work-sheet.component.html',
  styleUrls: ['./work-sheet.component.css']
})
export class WorkSheetComponent implements OnInit {

  constructor(public _EmployeeService: EmployeeService,
              public _CommonService: CommonService, 
              public _JobCardService: JobCardService) { }
  pageIndex: number;
  orderByColName: string = '';
  orderByDir: boolean = false;
  isFiltering: boolean = false;
  filterCol: Worksheet;
  StatusList: any;
  UserRole: string = '';
  public errorMsg;

  ngOnInit(): void {
    this._JobCardService.selectedJobCard.Emp_ID = 0;
    this.UserRole = JSON.parse(localStorage.getItem('userRoles'))[0].toString();
    if ((this.UserRole == 'Developers & Designers' || this.UserRole == 'Testers')) {
      this._CommonService.getLoginedUserId().then(value => {
        this._JobCardService.selectedJobCard.Emp_ID = Number(value);
      });
    }
    this.resetForm();
    this.StatusList = this._CommonService.GetTesterStatusList();
    this.fillServiceEngineer()
    this._JobCardService.Worksheetlist = [];
    this.pageIndex = 1;
    this.filterCol = { Date: '', Activity: null, W_AddedDate: '', CustomerName: '', ModuleName: '', W_From: '', W_To: '', NextDay: null, Status: '', Break: null,Description:'' }
    this._CommonService.pageIndex = 1;
  }

  resetForm(form?: NgForm) {
    this._JobCardService.EmployeeId = 0
    this._JobCardService.From = null
    this._JobCardService.To = null
    this._CommonService.isLoading = false;
  }

  sort(col) {
    if (col == this.orderByColName) {
      this.orderByDir = !this.orderByDir;
    }
    this.orderByColName = col;
  }

  getData() {
    this._CommonService.isLoading = true;
    this._JobCardService.Fetch_Worksheet(this._JobCardService.EmployeeId, this._JobCardService.From, this._JobCardService.To).subscribe(data => {
      this._CommonService.isLoading = false;
      this._JobCardService.Worksheetlist = data;
    },
      error => {
        this.errorMsg = error;
        this._CommonService.isLoading = false;
      })
  }

  toggleFilter() {
    this.isFiltering = !this.isFiltering;
    if (!this.isFiltering) {
      this.filterCol = { Date: '', Activity: null, W_AddedDate: '', CustomerName: '', ModuleName: '', W_From: '', W_To: '', NextDay: null, Status: '', Break: null,Description:''}
    }
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('TEAMMEMBERS')
      .subscribe(data => {
        this._EmployeeService.employeeList = data;
        this._JobCardService.EmployeeId=this._JobCardService.selectedJobCard.Emp_ID;
      },
        error => {
          this.errorMsg = error;
        })
  }
}
