import { Component, OnInit } from '@angular/core';
import { SoftwareRegistrationService } from 'src/app/Services/software-registration.service';
import { ToastrService } from 'ngx-toastr';
import { softwareRegistration, SoftwareRegPaymentTerms, SoftwareRegSystemDtls } from 'src/app/Models/softwareRegistration.model';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ModuleService } from 'src/app/Services/module.service';
import { AppConstants } from 'src/app/Models/constants';
import * as CryptoJS from 'crypto-js';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-software-registration-list',
  templateUrl: './software-registration-list.component.html',
  styleUrls: ['./software-registration-list.component.css']
})
export class SoftwareRegistrationListComponent implements OnInit {

  public errorMsg;
  public term: string = ''
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  SystemDetailsList: SoftwareRegSystemDtls;
  SoftwareRegPaymentTermsList: SoftwareRegPaymentTerms;

  constructor(public _swRegService: SoftwareRegistrationService, 
              public _ModuleService: ModuleService, 
              public _CommonService: CommonService,
              public _custService: CustomerService, 
              public _brnchService: BranchService, 
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.LoadCustomers();
    this._swRegService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.getSoftwareRegistration();
    this.getAllBranch();
    this._swRegService.formHideShow = 0;
  }

  toggleFilter() {
    this._swRegService.isFiltering = this._swRegService.isFiltering ? false : true;
    if (!this._swRegService.isFiltering) {
      this._swRegService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._swRegService.pageIndex = 1;
    }
  }

  getAllBranch() {
    this._brnchService.Fetch_Branch('DEALERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  getSoftwareRegistration() {
    this._swRegService.getSoftwareRegistration()
      .subscribe(data => {
        this._swRegService.allSoftwareRegistrations = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._swRegService.orderByColName) {
      this._swRegService.orderByDir = !this._swRegService.orderByDir;
    }
    this._swRegService.orderByColName = colName;
  }
 
  NoOfItems: number = 10;
  addList() {
    this._swRegService.imageUrl = AppConstants.tockenURL + "Images/Logo/default.jpg";
    this._ModuleService.getModuleCheckBox()
      .subscribe(data => {
        this._ModuleService.allModulesCheckBox = data
      },
        error => {
          this.errorMsg = error;
        })

    this._swRegService.formHideShow = 1;
    this._swRegService.formHidePassword = false;
  }

  LoadCustomers() {
    this._custService.FetchAllCustomer('DEALERID')
      .subscribe(data => this._custService.allCustomers = data,
        error => {
          this.errorMsg = error;
        })
  }

  showForEdit(swReg: softwareRegistration) {
    this._swRegService.selectedSoftwareRegistration = Object.assign({}, swReg);
    this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachmentRemovedList = []
    this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachments = []
    this._swRegService.selectedSoftwareRegistration.ContractDate = new Date(swReg.ContractDate)

    var custDtls = this._custService.allCustomers.filter(e => e.Id == this._swRegService.selectedSoftwareRegistration.CustomerId)[0];
    this._swRegService.selectedSoftwareRegistration.Addr1 = custDtls.Addr1;
    this._swRegService.selectedSoftwareRegistration.Addr2 = custDtls.Addr2;
    this._swRegService.selectedSoftwareRegistration.Addr3 = custDtls.Addr3;

    this._swRegService.getAllModuleByRegId(this._swRegService.selectedSoftwareRegistration.Id).subscribe(obj => {
      this._ModuleService.allModulesCheckBox = []
      this._ModuleService.allModulesCheckBox = obj;
      this._ModuleService.allModulesCheckBox.forEach(obj => {
        if (obj.Selected)
          this._swRegService.moduleList.push(obj.Id)
      })
    })
    this._swRegService.FetchSoftRegAttachments(this._swRegService.selectedSoftwareRegistration.Id)
      .subscribe(data => {
        this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachments = data

      },
        error => {
          this.errorMsg = error;
        });


    this._swRegService.FetchSoftRegDtls(this._swRegService.selectedSoftwareRegistration.Id, 'SOFTREGID', this._swRegService.selectedSoftwareRegistration.CustomerId)
      .subscribe(data => {
        this.SystemDetailsList = {
          Id: 0,
          SoftwareRegId: 0,
          BranchID: 0,
          BranchName: '',
          TypeOfBusinessId: 0,
          Date: null,
          FreeSupport: 0,
          ExpiredOn: null,
          Server: null,
          Nodes: null,
          Users: null,
          VanSales:0
        }
        data.forEach(obj => {
          this.SystemDetailsList.Id = obj.Id
          this.SystemDetailsList.SoftwareRegId = obj.SoftwareRegId
          this.SystemDetailsList.BranchID = obj.BranchID
          this.SystemDetailsList.BranchName = obj.BranchName
          this.SystemDetailsList.TypeOfBusinessId = obj.TypeOfBusinessId
          this.SystemDetailsList.Date = obj.Date.toString() == "" ? null : new Date(obj.Date);
          this.SystemDetailsList.FreeSupport = obj.FreeSupport
          this.SystemDetailsList.ExpiredOn = obj.ExpiredOn.toString() == "" ? null : new Date(obj.ExpiredOn);
          this.SystemDetailsList.Server = obj.Server
          this.SystemDetailsList.Nodes = obj.Nodes
          this.SystemDetailsList.Users = obj.Users
          this.SystemDetailsList.VanSales = obj.VanSales
          this._swRegService.allSystemDetails.push(this.SystemDetailsList)
          this.SystemDetailsList = {
            Id: 0,
            SoftwareRegId: 0,
            BranchID: 0,
            BranchName: '',
            TypeOfBusinessId: 0,
            Date: null,
            FreeSupport: 0,
            ExpiredOn: null,
            Server: null,
            Nodes: null,
            Users: null,
            VanSales:0
          }
        })
      },
        error => {
          this.errorMsg = error;
        });
    this._swRegService.imageUrl = AppConstants.tockenURL + "Images/SoftwareRegistration/" + this._swRegService.selectedSoftwareRegistration.FileName;
    this._swRegService.formHideShow = 1;
    this._swRegService.tblVisible = 1;
    this._swRegService.formHidePassword = true;
    if (this._swRegService.selectedSoftwareRegistration.CentralizedServer == true)
      this._swRegService.checkboxVisible = 1;
    else
      this._swRegService.checkboxVisible = 0;

      this._swRegService.FetchSoftwareRegPaymentTermsList(this._swRegService.selectedSoftwareRegistration.Id)
      .subscribe(data => {
        this.SoftwareRegPaymentTermsList = {
          Id: 0,
          SoftwareRegId: 0,
          Term: '',
          Date: null,
          Amount: '',
          Remark: null,
        }
        data.forEach(obj => {
          this.SoftwareRegPaymentTermsList.Id = obj.Id
          this.SoftwareRegPaymentTermsList.SoftwareRegId = obj.SoftwareRegId
          this.SoftwareRegPaymentTermsList.Term = obj.Term
          this.SoftwareRegPaymentTermsList.Date =  obj.Date.toString() == "" ? null : new Date(obj.Date);
          let amount = parseFloat(obj.Amount.toString()).toFixed(Number(localStorage.getItem('floatCount')));
        
          this.SoftwareRegPaymentTermsList.Amount =amount 
          this.SoftwareRegPaymentTermsList.Remark = obj.Remark
         
          this._swRegService.allSoftwareRegPaymentTerms.push(this.SoftwareRegPaymentTermsList)
          this.SoftwareRegPaymentTermsList = {
            Id: 0,
            SoftwareRegId: 0,
            Term: '',
            Date: null,
            Amount:'',
            Remark: null,
          }
        })
      },
        error => {
          this.errorMsg = error;
        });
       
        // let rent = parseFloat(this._swRegService.selectedSoftwareRegistration.Rent.toString()).toFixed(Number(localStorage.getItem('floatCount')));
        // this._swRegService.selectedSoftwareRegistration.Rent=parseInt(rent);
        // let softwarecost = parseFloat(this._swRegService.selectedSoftwareRegistration.SoftwareCost.toString()).toFixed(Number(localStorage.getItem('floatCount')));
        // this._swRegService.selectedSoftwareRegistration.SoftwareCost=parseInt(softwarecost);
        this._swRegService.selectedSoftwareRegistration.Expiry = new Date(swReg.Expiry)
        if (this._swRegService.selectedSoftwareRegistration.ReportServer == true)
        {
          this._swRegService.ServerNameVisible = 1;
          this._CommonService.getCommonList("ALLSERVER").subscribe(
            data=>{
              this._swRegService.allServer=data;
            }
          )
        }
      else
        this._swRegService.ServerNameVisible = 0; 
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
     return [date.getFullYear(), mnth, day].join("-");
  }

  onDelete(swReg: softwareRegistration) {
    this.deletePopUp = true;
    this.deleteID = swReg.Id;
  }

  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._swRegService.deleteSoftwareRegistration(this.deleteID)
        .subscribe(x => {
          this.getSoftwareRegistration();
          this.toastr.success("Deleted Successfully", "SoftwareRegistration");
          this.deleteID = 0;
        }, error => {
          this.errorMsg = error;
          this.toastr.error("Deletion Failure", "SoftwareRegistration");
        })
    }
  }
}
