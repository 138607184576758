import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/Services/employee.service';
import { ToastrService } from 'ngx-toastr';
import { employee } from 'src/app/Models/employee.model';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  public errorMsg;
  public term: string = ''

  constructor(public _empService: EmployeeService, public _CommonService: CommonService, private toastr: ToastrService) { }
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  ngOnInit(): void {
    this.deletePopUp = false;
    this._empService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.getAllEmployee()
    this._empService.formHideShow = 0;
  }

  getAllEmployee() {
    let role: string
    role = 'DEALERALL'
    if (localStorage.getItem('Designation') == "Dealer") {
      this._empService.Fetch_Employee(role)
        .subscribe(data => {
          console.log('Fetch_Employee:', data)
          this._empService.allEmployees = data
        },
          error => {
            this.errorMsg = error;
          })
    }
    else {
      role = 'ADMINALL'
      this._empService.Fetch_EmployeeById(role, 0)
        .subscribe(data => {
          console.log('Fetch_Employee:', data)
          this._empService.allEmployees = data
        },
          error => {
            this.errorMsg = error;
          })
    }
  }

  toggleFilter() {
    this._empService.isFiltering = this._empService.isFiltering ? false : true;
    if (!this._empService.isFiltering) {
      this._empService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._empService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._empService.orderByColName) {
      this._empService.orderByDir = !this._empService.orderByDir;
    }
    this._empService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  addList() {
    this._empService.editDesignation = false;
    this._empService.formHideShow = 1;
    this._empService.formHidePassword = false;
  }

  showForEdit(emp: employee) {
    this._empService.selectedEmployee = Object.assign({}, emp);;
    this._empService.editDesignation = true;
    this._empService.formHideShow = 1;
    this._empService.formHidePassword = true;
    this._empService.getLeaveApproverList('LEAVEAPPROVERS', emp.Id).subscribe(data => {
      this._empService.selectedEmployee.EmployeeApprover = data;
    })
  }

  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._empService.deleteEmployee(this.deleteID)
        .subscribe(x => {
          this.getAllEmployee();
          this.toastr.success("Deleted Successfully", "Employee");
          this.deleteID = 0;
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.error("Deletion Failure", "Employee");
        })
    }
  }

  onDelete(emp: employee) {
    this.deletePopUp = true;
    this.deleteID = emp.Id;
  }

}
