import { Component, OnInit } from '@angular/core';
import { branch } from 'src/app/Models/branch.model';
import { BranchService } from 'src/app/Services/branch.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-branch-list',
  templateUrl: './branch-list.component.html',
  styleUrls: ['./branch-list.component.css']
})
export class BranchListComponent implements OnInit {

  constructor(public _brnchService: BranchService,
              public _CommonService: CommonService,
              private toastr: ToastrService) { }

  public errorMsg;
  public term: string = ''
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  ngOnInit(): void {
    this._brnchService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.getAllBranch();
    this._brnchService.formHideShow = 0;
  }

  getAllBranch() {
    this._brnchService.Fetch_Branch('DEALERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  toggleFilter() {
    this._brnchService.isFiltering = this._brnchService.isFiltering ? false : true;
    if (!this._brnchService.isFiltering) {
      this._brnchService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._brnchService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._brnchService.orderByColName) {
      this._brnchService.orderByDir = !this._brnchService.orderByDir;
    }
    this._brnchService.orderByColName = colName;
  }

  NoOfItems: number = 10;

  addList() {
    this._brnchService.imageUrl = "";
    this._brnchService.formHideShow = 1;
    this._brnchService.formHidePassword = false;
  }

  showForEdit(brnch: branch) {
    this._brnchService.selectedBranch = Object.assign({}, brnch);;
    this._brnchService.formHideShow = 1;
    this._brnchService.formHidePassword = true;
  }

  onDelete(brnch: branch) {
    this.deletePopUp = true;
    this.deleteID = brnch.Id;
  }

  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._brnchService.deleteBranch(this.deleteID)
        .subscribe(x => {
          this.getAllBranch();
          this.deleteID = 0;
          console.log(x)
          if (x.Status == 0)
            this.toastr.success(x.Message, "Branch");
          else
            this.toastr.error(x.Message, "Branch");
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.error("Deletion Failure", "Branch");
        }
        )
    }

  }

  SentMailUniqueId(brnch: branch) {
    if (brnch.UniqueBrachId != "") {
      this._brnchService.SentMail(brnch).subscribe(obj => {
        if (obj.Status == 0)
          this.toastr.success(obj.Message, 'Branch')
        else
          this.toastr.error(obj.Message, 'Branch')
      })
    }
    else
      this.toastr.error('Unique Id Not Exist', 'Branch')
  }
}
