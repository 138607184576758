import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { strict } from 'assert';
import { TesterDashboard, TesterDashboardDet } from '../Models/TesterDashboard';

@Injectable({
  providedIn: 'root'
})
export class TesterdashboardService {
  AllPendingWork: TesterDashboard[]
  AllPendingWorkDet: TesterDashboardDet
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  getPendingWorks(): Observable<TesterDashboard[]> {

    const params = new HttpParams().append('TransMode', "Tester");

    return this.http.get<any>(ROOT_URL + 'TesterDashBoard/getAllAllotedWorkDetails', {
      params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }


  fetchProjectDet(obj: TesterDashboard): Observable<TesterDashboardDet> {

    const body = {
      Vtype: obj.VType,
      RefNo: obj.RefNo,
      DealerId: obj.DealerId
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<TesterDashboard>(ROOT_URL + 'TesterDashBoard/WorkDetList_Fetch', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

}
