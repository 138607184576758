<div class="container-fluid">
    <h2 class="heading_all">Work Details</h2>
    <br>
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="select">From</label>
                <input class="form-control" placeholder="From" name="From" #From="ngModel"
                    [(ngModel)]="_JobCardService.From" style="color: black;  text-align: left;" autocomplete="off"
                    bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="select">To</label>
                <input class="form-control" placeholder="To" name="To" #To="ngModel" [(ngModel)]="_JobCardService.To"
                    style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="select">Employee</label>
                <ng-select name="EmployeeId" id="EmployeeId" #EmployeeId="ngModel" bindLabel="EmpName" bindValue="Id" [disabled]="this._JobCardService.selectedJobCard.Emp_ID > 0"
                    required [items]="this._EmployeeService.employeeList " [(ngModel)]="_JobCardService.EmployeeId"
                    placeholder="Select">
                </ng-select>
            </div>
        </div>
        <div class="col-md-3">
            <button style="width: 100%;margin-top: 15px;" type="button" class="sim-button button2"
                (click)="getData()">Go</button>
        </div>
    </div>
    <br>
    <div class="tWrap">
        <table>
            <thead>
                <tr *ngIf="_JobCardService.WorkDetails?.length>0">
                    <th style="width: 50px;">#
                        <div style="display: inline-block;">
                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                <i *ngIf="!isFiltering" class="fas fa-filter"></i>
                                <i *ngIf="isFiltering" class="fas fa-times"></i>
                            </a>
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('CustomerName')" *ngIf="!isFiltering">
                            <span>Customer</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='CustomerName' "></i>
                                <div *ngIf="orderByColName=='CustomerName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="CustomerName" id="CustomerName"
                                #CustomerName="ngModel" [(ngModel)]="filterCol.CustomerName" placeholder="CustomerName">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('ProjectName')" *ngIf="!isFiltering">
                            <span>Project</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='ProjectName' "></i>
                                <div *ngIf="orderByColName=='ProjectName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="ProjectName" id="ProjectName"
                                #ProjectName="ngModel" [(ngModel)]="filterCol.ProjectName" placeholder="ProjectName">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('ModuleName')" *ngIf="!isFiltering">
                            <span>Module</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='ModuleName' "></i>
                                <div *ngIf="orderByColName=='ModuleName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="ModuleName" id="ModuleName"
                                #ModuleName="ngModel" [(ngModel)]="filterCol.ModuleName" placeholder="ModuleName">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:110px;">
                        <div class="AtjIsPoint" (click)="sort('Activity')" *ngIf="!isFiltering">
                            <span>Activity</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Activity' "></i>
                                <div *ngIf="orderByColName=='Activity'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="Activity" id="Activity"
                                #Activity="ngModel" [(ngModel)]="filterCol.Activity" placeholder="Activity">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:90px;">
                        <div class="AtjIsPoint" (click)="sort('EstHrs')" *ngIf="!isFiltering">
                            <span>EstHrs</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='EstHrs' "></i>
                                <div *ngIf="orderByColName=='EstHrs'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="EstHrs" id="EstHrs" #EstHrs="ngModel"
                                [(ngModel)]="filterCol.EstHrs" placeholder="EstHrs">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:125px;">
                        <div class="AtjIsPoint" (click)="sort('ActualHours')" *ngIf="!isFiltering">
                            <span>ActHours</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='ActualHours' "></i>
                                <div *ngIf="orderByColName=='ActualHours'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="ActualHours" id="ActualHours"
                                #ActualHours="ngModel" [(ngModel)]="filterCol.ActualHours" placeholder="ActualHours">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:100px;">
                        <div class="AtjIsPoint" (click)="sort('NoCases')" *ngIf="!isFiltering">
                            <span>NoCases</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='NoCases' "></i>
                                <div *ngIf="orderByColName=='NoCases'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="NoCases" id="NoCases"
                                #NoCases="ngModel" [(ngModel)]="filterCol.NoCases" placeholder="NoCases">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:95px;">
                        <div class="AtjIsPoint" (click)="sort('Attempts')" *ngIf="!isFiltering">
                            <span>Attempts</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Attempts' "></i>
                                <div *ngIf="orderByColName=='Attempts'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="Attempts" id="Attempts"
                                #Attempts="ngModel" [(ngModel)]="filterCol.Attempts" placeholder="Attempts">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:120px;">
                        <div class="AtjIsPoint" (click)="sort('Perfomance')" *ngIf="!isFiltering">
                            <span>Perfomance</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Perfomance' "></i>
                                <div *ngIf="orderByColName=='Perfomance'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="Perfomance" id="Perfomance"
                                #Perfomance="ngModel" [(ngModel)]="filterCol.Perfomance" placeholder="Perfomance">
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of _JobCardService.WorkDetails| gridOperations  :pageIndex:
                    {CustomerName:filterCol.CustomerName,ProjectName:filterCol.ProjectName,ModuleName:filterCol.ModuleName,Activity:filterCol.Activity
                    ,EstHrs:filterCol.EstHrs,ActualHours:filterCol.ActualHours,NoCases:filterCol.NoCases,Attempts:filterCol.Attempts,Perfomance:filterCol.Perfomance                   }:
                   {Column:orderByColName,Dir:orderByDir}; let i=index">
                    <td style="width: 50px;">{{i+1}}</td>
                    <td>{{data.CustomerName}}</td>
                    <td>{{data.ProjectName}}</td>
                    <td>{{data.ModuleName}}</td>
                    <td title="{{data.JobDetails}}">{{data.Activity}}</td>
                    <td>{{data.EstHrs}}</td>
                    <td>{{data.ActualHours}}</td>
                    <td>{{data.NoCases}}</td>
                    <td>{{data.Attempts}}</td>
                    <td>{{data.Perfomance}}</td>
                </tr><!-- end ngRepeat: item in items -->
                <tr *ngIf="_JobCardService.WorkDetails?.length<=0">
                    <td colspan="12">
                        No Records To Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
    </div>
    <div class="margintop"></div>
</div>