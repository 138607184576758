import { employee } from './../../../../Models/employee.model';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';
import { MeetingService } from 'src/app/Services/meeting.service';

@Component({
  selector: 'app-meeting-form',
  templateUrl: './meeting-form.component.html',
  styleUrls: ['./meeting-form.component.css']
})
export class MeetingFormComponent implements OnInit {

  isSubmit:boolean=false;
  errorMsg:string='';
  selectedEmpId?:number;

  constructor(public _CommonService:CommonService,
              public _Meeting:MeetingService,
              public toastr:ToastrService ) { }

  ngOnInit(): void {
    this.GetVNo();
    this.getEmployee();
    this.getRegardingTypeList();
  }
 
  async GetVNo() {
    this._Meeting.selectedMeeting.MeetingNo = parseInt(await this._CommonService.getVNo('MEETING')).toString();
  }
  getRegardingTypeList()
  {
    this._Meeting.regardingTypeList=[
      {Id:1,Name:'Batch'},
      {Id:2,Name:'Project'},
      {Id:3,Name:'Other Works'},
    ]
  }
  getRegardingList()
  {
    let transType='';
    this._Meeting.regardingList=[];
    this._Meeting.selectedMeeting.RegardingId=undefined;
    transType=this._Meeting.selectedMeeting.RegardingTypeId==1?'BATCHLIST':this._Meeting.selectedMeeting.RegardingTypeId==2?'PROJECT':'OTHERWORKS';
    this._CommonService.getCommonList(transType).subscribe(data=>{
      this._Meeting.regardingList=data;
      this._Meeting.selectedMeeting.RegardingId=data[0].Id;
    },
    error => {
      this.errorMsg = error;
    })
  }
  getEmployee() {
    this._CommonService.getCommonList('MEETINGEMPLOYEE').subscribe(data => {
      this._Meeting.employeeList = data;
    },
      error => {
        this.errorMsg = error;
      })
  }
  addEmployee(id:number)
  {
    const index=this._Meeting.selectedMeeting.Employees.findIndex(x=>x.Id==id);
    if(index ==-1)
    {
      this._Meeting.employeeList.forEach(data =>{
        if(data.Id==id)
         this._Meeting.selectedMeeting.Employees.push({Id:data.Id,Name:data.Name});
      })
    }
    this.selectedEmpId=undefined;
  }
  deleteEmployee(index:number)
  {
    this._Meeting.selectedMeeting.Employees.splice(index,1)
  }
  resetForm(form?: NgForm) {
    this.isSubmit = false;
    if (form != null)
      form.reset();
    this._Meeting.selectedMeeting = {
      ID:0,MeetingNo:'',VDate:new Date(),Topic:'',Agenda:'',ScheduledDate:new Date(),ScheduledTime:'',RegardingTypeId:undefined,RegardingId:undefined,
      Description:'',MeetingStatus:false,ScheduledOn:'',Regarding:'',Employees:[]
    }
    this.selectedEmpId=undefined;
    this.GetVNo();
  }

  validation():boolean
  {
    let result=true;
    if(this._Meeting.selectedMeeting.RegardingTypeId==undefined || this._Meeting.selectedMeeting.Regarding==undefined
        || this._Meeting.selectedMeeting.Topic=='' || this._Meeting.selectedMeeting.Employees.length==0
        || this._Meeting.selectedMeeting.ScheduledTime=='' )
        result =false;
    return result;
  }

  onSubmit(frm:NgForm)
  {
    if(frm.valid && this.validation())
    {
      this.isSubmit = true;
      this._Meeting.saveMeeting(this._Meeting.selectedMeeting).subscribe(
        data=>{
          if(data.Status)
          {
            this._Meeting.formHideShow=0;
            this.toastr.success(data.Message,'Meeting');
            this.resetForm();
            this._Meeting.getallmeeting("ALL").subscribe(data => {this._Meeting.allMeeting = data},
              error => {
                this.errorMsg = error;
              });
          }
          else
            this.toastr.error(data.Message,'Meeting');
          this.isSubmit =false;
        },
        error => {
          this.errorMsg = error;
        })
    }
  }
  backList()
  {
    this.resetForm();
    this._Meeting.formHideShow=0;
  }


}
