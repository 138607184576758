<form #assignedJobs="ngForm">
    <div class="modal-dialog">
        <div class="modal-content">
            <h2 class="heading_all">Assigned Jobs Forecasting
            </h2>
            <div class="modal-body" style="overflow-y:scroll; ">
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <table id="chartHeader">
                            <tr>
                                <td>
                                    <a style="float: left;" (click)="preMonth()" class="btn btn-primary">      
                                        <i class="fas fa-chevron-circle-left"></i></a>
                                        {{filterDate | date:'MMM yyyy'}}
                                    <a style="float: right;" (click)="nextMonth()" class="btn btn-primary">
                                        <i class="fas fa-chevron-circle-right"></i></a>
                                </td>
                                <td>
                                    <div style="background-color:yellow ;height:20px;width: 20px;display: inline-block;border-radius: 3px;"></div> 
                                    <label style="padding-left:2px;margin-top:5px;">Fully Assigned</label>
                                </td>
                                <td>
                                    <div style="background-color:lightgreen;height: 20px;width: 20px;display: inline-block;border-radius: 3px;"></div>
                                    Partialy Assigned
                                </td>
                                <td style="padding-right:22px;">
                                    <div style="background-color:green;height: 20px;width: 20px;display: inline-block;border-radius: 3px;"></div>
                                    Avaliable
                                </td>
                                <td>
                                    <div style="background-color:red;height:20px;width:20px;display:inline-block;border-radius: 3px;"></div> 
                                    Off Days
                                </td>
                                <td>
                                    <div style="background-color:orange; height:20px;width: 20px;display: inline-block;border-radius: 3px;"></div>
                                    Holidays
                                </td>
                                <td>
                                    <div style="background-color:blue;height: 20px;width: 20px;display: inline-block;border-radius: 3px;"></div>
                                    Leave
                                </td>
                                <td>
                                    <div style="background-color:lightblue;height: 20px;width: 20px;display: inline-block;border-radius: 3px;"></div>
                                    Half Day
                                </td>
                            </tr>
                        </table>
                    </div>                             
                </div>
                <div class="row">
                    <div class="tWrap__body">
                        <table class="table" id="Chartdetails">
                            <ng-container *ngFor="let data of _ProjectLeaderService.EmployeeWorkForcastDetails;let i=index;let islast=last">
                                <tr>
                                    <td *ngIf="i!=0 && i!=_ProjectLeaderService.EmployeeWorkForcastDetails.length-1" title="{{data.EmpName}}">
                                        {{data.EmpName}}
                                    </td>
                                    <th *ngIf="i==0"></th>
                                    <ng-container
                                        *ngFor="let details of data.EmployeeWorkForcastDetails; let j=index">
                                        <th *ngIf="i==0" class="Chartdetailsheader">
                                            {{details.Date | date:'dd' }}
                                            <Span style="display: block; font-size: 10px;">
                                                {{details.Date | date:'EEE' }}</Span>
                                        </th>
                                        <th *ngIf="i!=0 && i!=_ProjectLeaderService.EmployeeWorkForcastDetails.length-1" title="{{details.ManHour}}"
                                            [ngClass]="{'greenBg':details.Status==0,'yellowBg':details.Status==1,'lightgreenBg':details.Status==2,'normal':details.Status==6 || details.Status==8,'blue':details.IsLeave==1,'lightblue':details.IsLeave==2,'orange':details.IsLeave==4,'redBg':details.IsLeave==5}"
                                                (dblclick)="jobOrder(details.ID,details.Designation,details.Date)">
                                            {{details.ManHour}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </table>
                        <div class="row">
                            <div class="col-md-4" *ngIf="this._ProjectLeaderService.EmployeeWorkForcastDetails!=undefined">
                                Assigned Hours:{{this._ProjectLeaderService.EmployeeWorkForcastDetails[_ProjectLeaderService.EmployeeWorkForcastDetails.length- 1].EmployeeWorkForcastDetails[0].ManHour}}
                            </div>
                            <div class="col-md-4" *ngIf="this._ProjectLeaderService.EmployeeWorkForcastDetails!=undefined">
                                Avaliable Hours:{{this._ProjectLeaderService.EmployeeWorkForcastDetails[_ProjectLeaderService.EmployeeWorkForcastDetails.length- 1].EmployeeWorkForcastDetails[0].Designation}}
                            </div>
                            <div class="col-md-4" *ngIf="this._ProjectLeaderService.EmployeeWorkForcastDetails!=undefined">
                                Loading %:{{this.loadingPercentage}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
      </div>
   </div>
</form>
