import { Component, OnInit } from '@angular/core';
import { BussinessTypeService } from 'src/app/Services/bussiness-type.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-bussiness-type-form',
  templateUrl: './bussiness-type-form.component.html',
  styleUrls: ['./bussiness-type-form.component.css']
})
export class BussinessTypeFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  constructor(public _typeService: BussinessTypeService, 
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this._typeService.formHideShow = 0;
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._typeService.selectedBusinessType = {
      Id: null,
      BType: '',
      BTypeCode: ''
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._typeService.formHideShow = 0;
  }

  formValidation(): boolean {
    if (this._typeService.selectedBusinessType.BType != "" && this._typeService.selectedBusinessType.BTypeCode != "")
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid && this.formValidation()) {
      this.isSubmit = true
      if (form.value.Id == null) {
        this._typeService.postBusinessType(form.value)
          .subscribe(data => {
            this.isSubmit = false
            if (data == 0) {
              this.toastr.warning('Data Already Exists', 'BusinessType');
            }
            else {
              this._typeService.formHideShow = 0;
              this.toastr.success('New Record Added Successfully', 'BusinessType');
              this.resetForm(form);
              this._typeService.getBusinessType().subscribe(data => this._typeService.allBusinessTypes = data,
                error => {
                  this.errorMsg = error;
                });
            }
          },
            error => {
              this.errorMsg = error;
              this.toastr.error('New Record Added Failure', 'BusinessType');
            })
      }
      else {
        this._typeService.putBusinessType(form.value)
          .subscribe(data => {
            this.isSubmit = false
            if (data == 0) {
              this.toastr.warning('Data Already Exists!', 'BusinessType');
            }

            else {
              this._typeService.formHideShow = 0;
              this.toastr.success('Record Updated Successfully!', 'BusinessType');
              this.resetForm(form);
              this._typeService.getBusinessType().subscribe(data => this._typeService.allBusinessTypes = data,
                error => {
                  this.errorMsg = error;
                });
            }
          },
            error => {
              this.errorMsg = error;
              this.toastr.error('Record not Updated', 'BusinessType');
            });
        }
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'BusinessType');
    }
  }


}
