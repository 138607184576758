import { Injectable } from '@angular/core';
import { businessType } from '../Models/businessType.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs/observable';

@Injectable({
  providedIn: 'root'
})
export class BussinessTypeService {

  selectedBusinessType: businessType;
  businessTypeList: businessType[];
  formHideShow = 0;
  allBusinessTypes: businessType[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  BTypeFilter: string = '';
  BTypeCodeFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.BTypeFilter = '';
    this.BTypeCodeFilter = '';

  }

  postBusinessType(type: businessType) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      BType: type.BType,
      BTypeCode: type.BTypeCode
    }

    return this.http.post<any>(ROOT_URL + 'BusinessType/PostBusinessType', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  putBusinessType(type: businessType) {
    const params = new HttpParams().set('ID', type.Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      BType: type.BType,
      BTypeCode: type.BTypeCode,
      ID: type.Id
    }

    return this.http.put<any>(ROOT_URL + 'BusinessType/PutBusinessType/' + type.Id, body, {
      headers,
      params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getBusinessType(): Observable<businessType[]> {
    return this.http.get<businessType[]>(ROOT_URL + 'BusinessType/GetAllBusinessType')
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  deleteBusinessType(DeletedID) {
    return this.http.delete<any>(ROOT_URL + 'BusinessType/DeleteBusinessType/' + DeletedID)
  }

}
