<div id="customdatepicker" style="display:inline-block"  >
    <div (click)="onClicktxt()"> 
        <input id='date' style="pointer-events: none;" autocomplete="off" [placeholder]="dateformat" [ngModel]="value"  >
    </div>  
    <div id="calender" class="wrapper changeDisplayAnimation" *ngIf="displayCalender  && displayFlag==1">
        <div class="container-calendar">
            <span></span>                
            <div class="button-container-calendar" style="margin-top:1px;">            
                <button id="previous" (click)="preClick()">‹</button>
                <h3 style="cursor: pointer;
                text-align: center;
                z-index: 10057;
                display: inline-block;
                /* position: absolute; */              
                width: 78%;" >  
                <span (click)="viewMonth()"> {{headerDate|date:'MMM'}}</span>
                &nbsp;&nbsp;
                <span (click)="viewYear()">{{headerDate|date:'y'}}</span>    
            </h3>
                <button id="next" (click)="nextClick()">›</button>
            </div>       
            <table class="table-calendar" id="calendar" data-lang="en">        
                <tbody id="calendar-body" *ngIf="displayFlag==1">
                    <tr>
                        <td colspan="7" style="width: 100%;text-align: left;padding: 0;">
                            <span>
                                <span class="date-picker"> <span >SUN</span> </span>
                                <span class="date-picker"> <span >MON</span> </span>
                                <span class="date-picker"> <span >TUE</span> </span>
                                <span class="date-picker"> <span >WEN</span> </span>
                                <span class="date-picker"> <span >THU</span> </span>
                                <span class="date-picker"> <span >FRI</span> </span>
                                <span class="date-picker"> <span >SAT</span> </span>
                        </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="7" style="width: 100%;text-align: left;padding: 0;">
                            <span *ngFor="let day of calenderDates">
                                <span *ngIf="!day" class="date-picker"></span>
                                <span *ngIf="day" class="date-picker" [ngClass]="{'selected':isSelectedDate(day)}" (click)="ChangeValue(day)"> 
                                     <span  >{{day|date:'d'}} </span>
                                </span>
                        </span>
                        </td>
                    </tr>            
                </tbody>     
             </table> 
            <div class="footer-container-calendar">     
                 <select #hr="ngModel" id="hr" name="hr" [(ngModel)]="this.selectedHour"> 
                     <option *ngFor="let h of HoursList" [value]="h" >{{h}}</option>         
                 </select>
                 <select  [(ngModel)]="selectedMin" id="m" name="m" #min="ngModel">
                    <option *ngFor="let m of MinitusList"  [value]="m" >{{m}}</option>                   
                </select>   
                <select id="amPm"  name="amPm" #ampm="ngModel"  [(ngModel)]="selectedMeridian">
                    <option >AM</option>                   
                    <option >PM</option>                   
                </select>      
                <br> 
                <button class="date-picker selected" style="width: 57px;color: white;margin: 10px 0px 0px 0px;" (click)="PassValue()"> Set</button>
            </div>              
        </div>   
    </div>
    <!-- MONTH  -->
    <div id="month" class="wrapper changeDisplayAnimation" *ngIf="displayCalender && displayFlag==2">
        <div class="container-calendar">                    
            <table class="table-calendar" id="calendar" data-lang="en">          
                <!-- month -->
                <tbody id="calendar-body" *ngIf="displayFlag==2">
                    <tr>
                        <td colspan="7" style="width: 100%;text-align: center;padding: 0;">
                            <span>
                                <span class="month-picker" [ngClass]="{'selected':'0'==this.selectedMonth}" (click)="selectMonth('0')"> <span >Jan</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'1'==this.selectedMonth}" (click)="selectMonth('1')"> <span >Feb</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'2'==this.selectedMonth}" (click)="selectMonth('2')"> <span >Mar</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'3'==this.selectedMonth}" (click)="selectMonth('3')"> <span >Apr</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'4'==this.selectedMonth}" (click)="selectMonth('4')"> <span >May</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'5'==this.selectedMonth}" (click)="selectMonth('5')"> <span >Jun</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'6'==this.selectedMonth}" (click)="selectMonth('6')"> <span >Jul</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'7'==this.selectedMonth}" (click)="selectMonth('7')"> <span >Aug</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'8'==this.selectedMonth}" (click)="selectMonth('8')"> <span >Sep</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'9'==this.selectedMonth}" (click)="selectMonth('9')"> <span >Oct</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'10'==this.selectedMonth}" (click)="selectMonth('10')"> <span >Nov</span> </span>
                                <span class="month-picker" [ngClass]="{'selected':'11'==this.selectedMonth}" (click)="selectMonth('11')"> <span >Dec</span> </span>
                        </span>
                        </td>
                    </tr>                
                </tbody>
                <!-- month -->
             </table>             
        </div>   
    </div>
    <div id="year" class="wrapper changeDisplayAnimation" *ngIf="displayCalender && displayFlag==3">
        <div class="container-calendar">                   
            <table class="table-calendar" id="calendar" data-lang="en">          
                <!-- year -->
                <tbody id="calendar-body" *ngIf="displayFlag==3">
                    <tr>                      
                        <td colspan="7" style="width: 100%;text-align: center;padding: 0;">
                            <span >
                                <span style="position: absolute; margin-top: 30%;margin-left: -10px; cursor: pointer;" (click)="loadPreYear()"><</span>  
                                <span class="month-picker" [ngClass]="{'selected':y==this.selectedYear}" *ngFor="let y of YearList" (click)="selectYear(y)"> <span >{{y}}</span> </span>
                                <span style="position: absolute;margin-top: -25%;cursor: pointer;" (click)="loadNextYear()">></span> 
                        </span>
                        </td>
                    </tr>          
                </tbody>
                <!-- year -->
             </table>         
        </div>   
    </div>
</div>
 
