import { Component, OnInit } from '@angular/core';
import { Server } from 'http';
import { ToastrService } from 'ngx-toastr';
import { ServerInfo } from 'src/app/Models/Server';
import { CommonService } from 'src/app/Services/common.service';
import { ServerService } from 'src/app/Services/server.service';
import { server } from 'typescript';

@Component({
  selector: 'app-server-list',
  templateUrl: './server-list.component.html',
  styleUrls: ['./server-list.component.css']
})
export class ServerListComponent implements OnInit {

  public errorMsg;
  public term: any = '';
  public deletePopUp: boolean = false;
  deleteID: number = 0;

  constructor(
    public _Service: ServerService,
    public _CommonService: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this._Service.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.get();
    this._Service.formHideShow = 0;
  }

  get()
  {
    this._Service.get("ALL")
    .subscribe(data => {
      this._Service.allServer = data
    },
      error => {
        this.errorMsg = error;
      })
  }
  toggleFilter() {
    this._Service.isFiltering = this._Service.isFiltering ? false : true;
    if (!this._Service.isFiltering) {
      this._Service.clearFilter();
      this._CommonService.pageIndex = 1;
      this._Service.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._Service.orderByColName) {
      this._Service.orderByDir = !this._Service.orderByDir;
    }
    this._Service.orderByColName = colName;
  }
  NoOfItems: number = 10;
  addNew() {
    this._Service.formHideShow = 1;
  }
  onEdit(selectedRow:ServerInfo) {
    this._Service.getByID("BYID",selectedRow.ID)
    .subscribe(data => {
        this._Service.selectedRecord=data;
        let projectList: any[] = JSON.parse(data.EditedProjectList);
        this._Service.selectedRecord.ProjectList=projectList;
      },
      error => {
        this.errorMsg = error;
    })
    this._Service.formHideShow = 1;
  }
  onDelete(deleteID: number) {
    this.deleteID = deleteID;
    this.deletePopUp = true;
  }
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._Service.onDelete(this.deleteID)
        .subscribe(data => {
          if(data.Status==1)
          {
            this._Service.get("ALL").subscribe(data => this._Service.allServer = data,
              error => {
                this.errorMsg = error;
              });
            this.toastr.success(data.Message, "Server master");
          }
        }, error => {
          this.errorMsg = error;
          this.toastr.warning("Deletion Failure", "Server master");
        })
    }
  }
}
