import { CommonService } from './../../../../../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ServerProviderService } from 'src/app/Services/server-provider.service';

@Component({
  selector: 'app-server-provider-form',
  templateUrl: './server-provider-form.component.html',
  styleUrls: ['./server-provider-form.component.css']
})
export class ServerProviderFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean 
  formValidation: boolean

    constructor(
      public _Service: ServerProviderService,
      public _CommonService:CommonService,
      private toastr: ToastrService,
    ) { }
  ngOnInit(): void {
    this._Service.formHideShow = 0;
    this.resetForm();
  }

  validation(): boolean {
    if (this._Service.selectedRecord.Name != "" && this._Service.selectedRecord.Code != "" && this._Service.selectedRecord.Address != "")
      return true;
    else
      return false;
  }

  onSubmit(frm: NgForm) {
    this.formValidation = true;
    if (frm.valid && this.validation()) {
      this.isSubmit = true;
        this._Service.trans(this._Service.selectedRecord).subscribe(
          async data => {
            this.isSubmit = false;
            if (data.Status == 1) {
              this._Service.formHideShow = 0;
              this.toastr.success(data.Message, "Server Provider Master")
              this.resetForm();
              this._Service.get("ALL").subscribe(data => this._Service.allServerProvider = data,
                error => {
                  this.errorMsg = error;
                });
            }
            else if (data.Status == 2) {
              this.toastr.warning(data.Message, "Server Provider Master")
              return;
            }
            else {
              this.toastr.warning(data.Message, "Server Provider Master")
            }
          }
        );
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Server Provider Master');
      this.isSubmit = false;
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false;
    this.formValidation = false
    this._Service.formHideShow = 0;
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false;
    this.formValidation = false
    if (form != null)
      form.reset();
     this._Service.clearValue();
  }
}
