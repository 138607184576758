<div class="container-fluid" >
    <h2 class="heading_all">Company Registration </h2>
    <br>
    <div id="form-wrap">
        <!-- (ngSubmit)="onSubmit(countryForm)" -->
        <form  #companyReg="ngForm" (ngSubmit)="onSubmit(companyReg)"> 
       
            <div class="col-md-12">
               
                <div class="form-group">
                    <label>Name<span style="color: red;">*</span></label>

                    <input name="Name" autocomplete="off" placeholder="Name" required
                  #Name="ngModel"  [(ngModel)]="_CompanyregistrationService.companyData.Name">
                    <div class="validation-error"
                        *ngIf="(Name.invalid && Name.touched)||(Name.invalid && this.FormValidation)">This Field is
                        Required.</div>

                </div>
                <div class="form-group ">
                    <label>Address 1<span style="color: red;">*</span></label>
                    <textarea name="address1"  required id="address1" style="width: 100%;"  rows="1"  #address1="ngModel" [(ngModel)]="_CompanyregistrationService.companyData.Address1"></textarea>
                     <div class="validation-error"
                        *ngIf="(address1.invalid && address1.touched)||(address1.invalid && this.FormValidation)">This Field is
                        Required.</div>  

                </div>
                <div class="form-group ">
                    <label>Address 2<span style="color: red;">*</span></label>
                    <textarea name="address2" required id="address2" style="width: 100%;"  rows="1"  #address2="ngModel" [(ngModel)]="_CompanyregistrationService.companyData.Address2"></textarea>
                     <div class="validation-error"
                        *ngIf="(address2.invalid && address2.touched)||(address2.invalid && this.FormValidation)">This Field is
                        Required.</div>  

                </div>
                <div class="form-group ">
                    <label>Address 3<span style="color: red;">*</span></label>
                    <textarea name="address3" required id="address3" style="width: 100%;"  rows="1"  #address3="ngModel" [(ngModel)]="_CompanyregistrationService.companyData.Address3"></textarea>
                     <div class="validation-error"
                        *ngIf="(address3.invalid && address3.touched)||(address3.invalid && this.FormValidation)">This Field is
                        Required.</div>  

                </div>
                <div class="form-group">
                    <label>Tel<span style="color: red;">*</span></label>

                    <input name="tel" autocomplete="off" placeholder="Tel" required
                  #tel="ngModel"  [(ngModel)]="_CompanyregistrationService.companyData.Tel">
                    <div class="validation-error"
                        *ngIf="(tel.invalid && tel.touched)||(tel.invalid && this.FormValidation)">This Field is
                        Required.</div>

                </div>
                <div class="form-group">
                    <label>Email<span style="color: red;">*</span></label>

                    <input name="email" autocomplete="off" placeholder="Email" required id="email"
                  #email="ngModel"  [(ngModel)]="_CompanyregistrationService.companyData.Email">
                    <div class="validation-error"
                        *ngIf="(email.invalid && email.touched)||(email.invalid && this.FormValidation)">This Field is
                        Required.</div>

                </div>
                <div class="form-group">
                    <label>URL<span style="color: red;">*</span></label>

                    <input name="url" autocomplete="off" placeholder="url" required id="url"
                  #url="ngModel"  [(ngModel)]="_CompanyregistrationService.companyData.URL">
                    <div class="validation-error"
                        *ngIf="(url.invalid && url.touched)||(url.invalid && this.FormValidation)">This Field is
                        Required.</div>

                </div>
                <div class="form-group">
                    <label>Support Site<span style="color: red;">*</span></label>

                    <input name="supportsite" autocomplete="off" placeholder="Support Site" required id="supportsite"
                  #supportsite="ngModel"  [(ngModel)]="_CompanyregistrationService.companyData.SupportSite">
                    <div class="validation-error"
                        *ngIf="(supportsite.invalid && supportsite.touched)||(supportsite.invalid && this.FormValidation)">This Field is
                        Required.</div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <button style="width: 99%; margin-top: 25px;" type="submit"  
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-6">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                         (click)="resetForm(companyReg)"    >Reset</button>
                    </div>
                     
                </div>

            </div>
        </form>
    </div>
</div>