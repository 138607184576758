<form *ngIf="expDetailsPopup > 0 &&  expDetailsPopup < 3 && !this._CommonService.isLoading">
    <div class="popup-container">
        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                <div class="title">
                    <p>{{this._CommonService.expDetailsText}}</p>
                    <div *ngIf="this.rewdaysPermission==1 && expDetailsPopup==1">
                        <img src="../../../../assets/Images/Custom-Icon.ico" style="width:25px;height:20px;">
                        <select style="width:250px;padding:4px;" class="wrapper-dropdown-3" name="DealerFilter" #DealerFilter="ngModel"  [(ngModel)]="this._CommonService.selectedDealer" (change)="this.dealerWiseCustomer()">
                            <option *ngFor="let objdealer of this._CommonService.keyExpiredDealer" [value]="objdealer.Id">{{objdealer.Name}}</option>
                        </select>
                        <img src="../../../../assets/Images/Custom-Icon.ico" style="width:25px;height:20px;">
                        <select style="width:250px;padding:4px;"  class="wrapper-dropdown-3" name="CustomerFilter" #CustomerFilter="ngModel"  [(ngModel)]="this._CommonService.selectedCustomer">
                            <option *ngFor="let objcust of this._CommonService.keyExpiredCustomer" [value]="objcust.Id">{{objcust.Name}}</option>
                        </select>                                         
                    </div> 
                    <div *ngIf="this.expDetailsPopup==1" style="padding-top:8px;">
                        <label style="padding-right:4px;">With in Days</label> 
                        <input type="number" class="inputtextbox" placeholder="With in Days" name="remainderDays" minlength="1" autocomplete="off" #remainderDays="ngModel"  [(ngModel)]="this._CommonService.remainderDays">
                        <input type="button" class="Updatebutton" value="Filter" (click)="filterRemainderdaywiseKeyExpiry(0)">
                    </div>
                    <div *ngIf="this.rewdaysPermission==1 && this._CommonService.selectedCustomer > 0 && expDetailsPopup==1">  
                        <input type="number" class="inputtextbox" placeholder="Valid Days" name="validDays" minlength="1" autocomplete="off" #validDays="ngModel"  [(ngModel)]="this._CommonService.validDays">
                        <input type="button" class="Updatebutton" value="Update" (click)="updateValidDays()"> 
                    </div>
                    <i class="fas fa-times" (click)="closePopUp()"></i>
                </div>
                <div>
                  <div class="fixTableHead" [ngStyle]="{'height':expDetailsPopup==1 && (this._CommonService.KeyExpiryDetailsList==undefined || this._CommonService.KeyExpiryDetailsList.length) < 5?'200px':'370px'}">
                    <div class="row" *ngIf="expDetailsPopup==1">
                        <div class="col-md-12">
                            <!-- <div class="table-responsive"> -->
                                <table class="tablepl table" id="keyexpirydetails">
                                    <thead>
                                        <tr>
                                            <th style="width:45px;" *ngIf="this.rewdaysPermission==1" class="checkbox"><input type="checkbox" name="chkall" id="chkall" #chkall="ngModel" [(ngModel)]="this._CommonService.chkSelectAll" (change)="selectAllExpiredkeys()"></th>
                                            <th scope="col" style="width:60px;">SNO</th>
                                            <th title="Customer/Branch Name" scope="col">Customer/Branch</th>
                                            <th scope="col" style="width:240px;">Module</th>
                                            <th scope="col">System Name</th>
                                            <th style="width:85px;" scope="col">Instl.Date</th>
                                            <th scope="col">Last Renewal Date</th>
                                            <th scope="col">Expiry Date</th>
                                            <th scope="col" style="width:70px;">APP.FOR</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <ng-container *ngFor="let obj of this._CommonService.KeyExpiryDetailsList;let i=index;">
                                            <tr>  
                                                <td style="width:45px;" *ngIf="this.rewdaysPermission==1" class="checkbox"><input  type="checkbox" name="chkSystem" #chkSystem="ngModel" [(ngModel)]="obj.IsChecked"></td>                                        
                                                <td style="width:60px;">{{i+1}}</td>
                                                <td title="{{obj.BranchName}}" [ngClass]="{'hideBranchNameColumnLine':obj.BranchName.toString().length==0}">{{obj.BranchName}}</td>   
                                                <td title="{{obj.ModuleName}}" style="width:240px;">{{obj.ModuleName}}</td>                                         
                                                <td>{{obj.SystemName}}</td>
                                                <td style="width:85px;">{{obj.InstalledDate}}</td> 
                                                <td title="{{obj.LastRenewalDate}}">{{obj.LastRenewalDate}}</td>
                                                <td title={{obj.ExpiryDate}} [ngClass]="{'testFailedColorChange':obj.IsExpired==true}">{{obj.ExpiryDate}}</td> 
                                                <td style="width:70px;">{{obj.ValidDays}}</td> 
                                            </tr>
                                        </ng-container>
                                        <tr *ngIf="this._CommonService.KeyExpiryDetailsList==undefined">
                                            <td colspan="9">No Records To Display</td>
                                        </tr>
                                    </tbody>
                                </table>
                            <!-- </div> -->
                        </div>                      
                    </div>
                    <div class="row" *ngIf="expDetailsPopup==2 && this._CommonService.AMCExpiryDetails.length > 0">
                        <div class="col-md-12">
                                <table class="tablepl table" id="AMCdetails">
                                    <thead>
                                        <tr>
                                            <th scope="col">SNO</th>
                                            <th scope="col">Project</th>
                                            <th scope="col">Branch Name</th>
                                            <th scope="col">Dealer Name</th>
                                            <th scope="col">Customer</th>
                                            <th scope="col">AMC Renewal</th>
                                            <th scope="col">AMC Upto</th>
                                        </tr>
                                    </thead>
                                    <tbody> 
                                        <tr *ngFor="let obj of this._CommonService.AMCExpiryDetails;let i=index" [ngClass]="{'testFailedColorChange':obj.IsExpired==false}">  
                                            <td>{{i+1}}</td>
                                            <td title="{{obj.Project}}">{{obj.Project}}</td>   
                                            <td title="{{obj.BranchName}}">{{obj.BranchName}}</td>                                         
                                            <td>{{obj.DealerName}}</td>
                                            <td title="{{obj.Customer}}">{{obj.Customer}}</td> 
                                            <td>{{obj.AMCRenewal}}</td> 
                                            <td [ngClass]="{'testFailedColorChange':obj.IsExpired==true}">{{obj.AMCUpto}}</td> 
                                        </tr>
                                    </tbody>
                                </table>
                        </div>                      
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <button type="button"  *ngIf="expDetailsPopup==1" class="sim-button button1" style="width:100%;background-color: rgb(194, 11, 11);" (click)="filterRemainderdaywiseKeyExpiry(1)">Filter Hold Module</button>
                        </div>
                        <div class="col-md-4" style="height:35px;">
                            <span (click)="exportexcel()"><a></a><img src="../../../../assets/Images/Excel.ico" style="width:110px;height:40px;"></span>
                        </div>
                        <div class="col-md-4" style="height:35px;">
                            <button type="button" class="sim-button button1" style="width:100%;" (click)="closePopUp()">Close</button>
                        </div>                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="_CommonService.isLoading"></app-loading>