import { Component, Input, OnInit, HostListener, ErrorHandler } from '@angular/core';
import { ProjectLeaderService } from 'src/app/Services/project-leader.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pro-test-result',
  templateUrl: './pro-test-result.component.html',
  styleUrls: ['./pro-test-result.component.css']
})
export class ProTestResultComponent implements OnInit {

  @Input() activityId:number=0;
  @Input() stage:number=0;
  @Input() joNo:number=0;
  @Input() testResult:string='';
  @Input() qaResult:string='';
  testFeedBackPopUp:boolean=false;
  isTestFeedBackFiltering:boolean=false;
 
  constructor(public projectLeaderService:ProjectLeaderService,
              public toastrService:ToastrService) { }

  ngOnInit(): void {
    this.displayActivityWiseTestResultHistory();
  }
  
  displayActivityWiseTestResultHistory()
  {
    this.projectLeaderService.getActivityWiseTestResultHistory(this.activityId).subscribe(
      data=>{
        this.projectLeaderService.allActivityWiseTestResultHistory=data
      },error=>{
        this.toastrService.error('Something Went Wrong','Test Result History')
      }
    )
  }

  displayActivityWiseTestResultFeedBackHistory(EmployeeId:number)
  {
    this.projectLeaderService.getActivityWiseTestResultFeedBackHistory(this.activityId,EmployeeId).subscribe(
      data=>{
        this.testFeedBackPopUp=true;
        this.projectLeaderService.allActivityWiseTestFeedBackHistory=data
        console.log(this.projectLeaderService.allActivityWiseTestFeedBackHistory)
      },error=>{
        this.toastrService.error('Something Went Wrong','Test Result FeedBack')
      }
    )
  }

  closePopUp()
  {
    this.projectLeaderService.testResultHistoryPopUp=false;
    this.testFeedBackPopUp=false;
  }

  closeTestFeedBack()
  {
    this.testFeedBackPopUp=false;
  }

  toggleFilter(type:number) {
    if(type==1)
    {
      this.projectLeaderService.isFiltering = this.projectLeaderService.isFiltering ? false : true;
      if (!this.projectLeaderService.isFiltering) {
        this.projectLeaderService.ClearTestResultFilter();
      }
    }
    else
    {
      this.isTestFeedBackFiltering = this.isTestFeedBackFiltering ? false : true;
      if (!this.isTestFeedBackFiltering) {
        this.projectLeaderService.qaEngineerTestFeedBackFilter="";
      }
    }  
  }

  //Sorting
  sort(colName: string,type:number) {
    if(type==1)
    {
      if (colName == this.projectLeaderService.orderByTestResultColName) {
        this.projectLeaderService.orderByTestResultDir = !this.projectLeaderService.orderByTestResultDir;
      }
      this.projectLeaderService.orderByTestResultColName = colName;
    }
    else
    {
      if (colName == this.projectLeaderService.orderByTestFeedBackColName) {
        this.projectLeaderService.orderByTestFeedBacktDir = !this.projectLeaderService.orderByTestFeedBacktDir;
      }
      this.projectLeaderService.orderByTestFeedBackColName = colName;
    }

  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: any) {
    event.stopPropagation();
    if (event.code == "Escape") {
      this.closePopUp();
    }
  }
}
