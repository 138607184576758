<div class="container-fluid" *ngIf="!this.commonService.isLoading">
    <h2 class="heading_all">Outstanding</h2><br>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <select class="form-control Selectdropdown" name="DealerId" #DealerId="ngModel" [(ngModel)]="reports.selectedDealerId" (ngModelChange)="customerOutstanding()">
                        <option *ngIf="this.dealerService.allDealers!=undefined && this.dealerService.allDealers.length > 1" [ngValue]="0">Choose Dealer</option>
                        <option *ngFor="let dealer of dealerService.allDealers | orderBy : 'DealerName'" [ngValue]="dealer.Id">
                            {{dealer.DealerName}}
                        </option>
                    </select>                   
                </div>          
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <select class="form-control Selectdropdown" name="VoucherType" #Voucher="ngModel" [(ngModel)]="commonService.selectedVoucherType" (ngModelChange)="customerOutstanding()">
                        <option [ngValue]="0">Choose VoucherType</option>
                        <option *ngFor="let voucher of commonService.allCommonResult | orderBy : 'Name'" [ngValue]="voucher.Id">
                            {{voucher.Name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <label>Age></label>
                <input type="number" class="agewidth" placeholder="days" autocomplete="off" name="age" [(ngModel)]="age" (input)="customerOutstanding()">                    
            </div>
            <div class="col-md-3">
                <span (click)="this.exporttoexcelService.exportexcel(3)"><img src="../../../../../../assets/Images/Excel.ico" style="width:110px;height:40px;float:right;"></span>
            </div>
        </div>
        <div class="fixTableHead">
            <div class="row">
                <div class="col-md-12" *ngIf="this.reports.customerOutstanding?.length >1">
                    <table class="tablepl table" id="outstanding">
                    <thead>
                        <tr>
                            <th style="width:230px;font-size:18px;">Customer</th>
                            <th style="width:230px;font-size:18px;">Branch</th>
                            <th style="width:240px;font-size:18px;">Description</th>
                            <th style="width:110px;font-size:18px;">Date</th>
                            <th style="width:90px;font-size:18px;">Vno</th>
                            <th style="width:100px;font-size:18px;">Vtype</th>
                            <th style="width:110px;font-size:18px;">Amount</th>
                            <th style="width:110px;font-size:18px;">Paid</th>
                            <th style="width:110px;font-size:18px;">Balance</th>
                            <th style="width:100px;font-size:18px;">Age</th>
                        </tr>                 
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of this.reports.customerOutstanding;let i=index" [ngClass]="{'NetTotal':obj.Order==1 || obj.Order==2}">
                            <td title="{{obj.Customer}}">{{obj.Customer}}</td>
                            <td title="{{obj.Branch}}">{{obj.Branch}}</td>
                            <td title="{{obj.Description}}">{{obj.Description}}</td>
                            <td>{{obj.Date}}</td>
                            <td>{{obj.Vno}}</td>
                            <td [ngClass]="{'NetTotal':obj.Order==1 || obj.Order==2}">{{obj.VType}}</td>
                            <td [ngClass]="{'NetTotal':obj.Order==1 || obj.Order==2}">{{obj.Amount}}</td>
                            <td [ngClass]="{'NetTotal':obj.Order==1 || obj.Order==2}">{{obj.Paid}}</td>
                            <td [ngClass]="{'NetTotal':obj.Order==1 || obj.Order==2}">{{obj.Balance}}</td>
                            <td>{{obj.Age}}</td>
                        </tr>               
                    </tbody>
                    </table>
                </div>
                <div class="nodata" *ngIf="!this.commonService.isLoading && this.reports.customerOutstanding?.length<=1">
                <img src="../../../../../../assets/Images/empty1.png">
                </div>
            </div>
        </div>
</div>
