import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkStatus, WorkStatusHdr, WorkStatusAttachments, WorkStatusDet, WorkStatusDtlsHdr } from '../Models/WorkSorting.model';
import { employee } from '../Models/employee.model';
import $ from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class WorkAllocationStatusService {

  CountryId: number
  DealerId: number
  WorkStatusList: WorkStatus[]
  SelectedWorkStatusDet: WorkStatusDet[]
  SelectedWorkStatusHdr: WorkStatusHdr;
  SelectedWorkStatusDtlsHdr: WorkStatusDtlsHdr;
  BtmVisible: boolean;
  allEmployee: employee[]
  giveEditPermission: boolean = false;
  FormUpdation: boolean = false

  //filteration variables
  pageIndex: number = 0;
  isFiltering: boolean = false;

  RefNoFilter: string = '';
  RegDateFilter: string = '';
  RepDateFilter: string = '';
  CustomerFilter: string = '';
  IMPDateFilter: string = '';
  AMCFilter: string = '';
  PriorityFilter: string = '';
  EXPDateFilter: string = '';
  PlanDateFilter: string = '';
  AssignedByFilter: string = '';

  orderByDir: boolean = false;
  orderByColName: string = '';

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  getWorkStatusHeader(): Observable<WorkStatus[]> {
    return this.http.get<any>(ROOT_URL + 'WorkSorting/getWorkStatusHeader?DealerId=' + this.DealerId)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getWorkStatusDet(obj: WorkStatusHdr): Observable<WorkStatusDet[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      RefNo: obj.RefNo,
      VType: obj.VType,
      DealerId: obj.DealerId
    }
    console.log('service*****************', body)
    return this.http.post<any>(ROOT_URL + 'WorkSorting/getWorkStatusDet', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  FetchWorkStatusAttachments(Id: number): Observable<WorkStatusAttachments[]> {
    let params = new HttpParams();
    params = params.append('Id', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<WorkStatusAttachments[]>(ROOT_URL + 'WorkSorting/FetchWorkStatusAttachments', {
      headers, params
    })
      .pipe(

      );
  }

  WorkStatusIns(WorkStatusHdr: WorkStatusHdr, fileToUpload: File[]): Observable<any> {
    let headers = new HttpHeaders().set('contentType', 'false');
    var body = {
      ID: WorkStatusHdr.ID,
      WorkSortingHdrID: WorkStatusHdr.WorkSortingHdrID,
      Remark: WorkStatusHdr.Remark,
      Charge: WorkStatusHdr.Charge,
      // EmpId: WorkStatusHdr.EmpId,
      PlannedDeliveryDate: WorkStatusHdr.PlannedDeliveryDate,
      WorkStatusAttachmentsList: WorkStatusHdr.WorkStatusAttachmentsList,
      WorkStatusAttachmentRemovedList: WorkStatusHdr.WorkStatusAttachmentRemovedList,

    }
    const formData: FormData = new FormData();
    if (fileToUpload) {
      for (var i = 0; i < fileToUpload.length; i++) {
        formData.append('Document', fileToUpload[i]);
      }
    }
    formData.append('obj', JSON.stringify(body));
    return this.http.post<any>(ROOT_URL + 'WorkSorting/WorkStatusIns', formData, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  ClearFilter() {
    this.isFiltering = false;
    this.RefNoFilter = '';
    this.RegDateFilter = '';
    this.RepDateFilter = '';
    this.CustomerFilter = '';
    this.IMPDateFilter = '';
    this.AMCFilter = '';
    this.PriorityFilter = '';
    this.EXPDateFilter = '';
    this.PlanDateFilter = '';
    this.AssignedByFilter = '';
  }
}
