<!-- Test Result History -->
<div class="Atj_overlay " (click)="$event.stopPropagation(); closePopUp()">
    <div id="Atj_popup" class="Atj_popup ATJ-slide-bottom" (click)="$event.stopPropagation();">
        <div class="headding" *ngIf="this.projectLeaderService.allActivityWiseTestResultHistory.TestResult.length > 0">Test Result
            <span *ngIf="this.projectLeaderService.allActivityWiseTestResultHistory.Attempt > 0" style="padding-left:10px;background-color:green;">(Attempt:-{{this.projectLeaderService.allActivityWiseTestResultHistory.Attempt}})</span>
        </div>
        <a class="close" (click)="closePopUp()"></a>
        <div class="headding" *ngIf="this.projectLeaderService.allActivityWiseTestResultHistory.TestResult.length == 0"> 
            No Test Result Found.....!
        </div>
        <div class="Atj_content" *ngIf="this.projectLeaderService.allActivityWiseTestResultHistory.TestResult.length > 0">
            <div class="row">
                <div class="col-md-2">
                    JONo:-{{this.joNo}}
                </div>
                <div class="col-md-2">
                    Activity ID:-{{ this.activityId }}
                </div>
                <div class="col-md-4">
                    <!-- <span *ngIf="this.projectLeaderService.allActivityWiseTestResultHistory.Attempt > 0" style="padding-left:10px;background-color:green;">Attempt:-{{this.projectLeaderService.allActivityWiseTestResultHistory.Attempt}}</span> -->
                    Current Test Result:-<span [ngStyle]="testResult=='Succeed' && {'background-color': 'green'} || testResult=='Pending' && {'background-color': 'red'}">{{testResult}}</span>
                </div>
                <div class="col-md-4">
                    Current QA Result:-<span [ngStyle]="this.projectLeaderService.allActivityWiseTestResultHistory.QAResult=='Succeed' && {'background-color': 'green'} || this.projectLeaderService.allActivityWiseTestResultHistory.QAResult=='Failure' && {'background-color': 'red'} || this.projectLeaderService.allActivityWiseTestResultHistory.QAResult=='Pending' && {'background-color': 'red'}">{{this.projectLeaderService.allActivityWiseTestResultHistory.QAResult}}</span>
                </div>
            </div> 
            <div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>SNO</th>  
                            <th class="hidden-xs">
                                <div class="AtjIsPoint" (click)="sort('ExpCDate',1)">
                                    Exp.CDate
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestResultColName!='ExpCDate' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='ExpCDate'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestResultDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestResultDir"></i>
                                        </div>
                                    </div>
                                </div>
                            </th>  
                            <th class="hidden-xs">
                                <div class="AtjIsPoint" (click)="sort('ActCDate',1)">
                                    Act.CDate
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestResultColName!='ActCDate' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='ActCDate'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestResultDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestResultDir"></i>
                                        </div>
                                    </div>
                                </div>
                            </th>  
                            <th class="hidden-xs">
                                <div class="AtjIsPoint" (click)="sort('QAEngineer',1)">
                                    <span *ngIf="!projectLeaderService.isFiltering">QA Engineer</span>
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestResultColName!='QAEngineer' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='QAEngineer'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestResultDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestResultDir"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="projectLeaderService.isFiltering">
                                    <input type="text" #txtqaEngineerFilter="ngModel" [(ngModel)]="projectLeaderService.qaEngineerFilter"
                                    id="qaEngineerFilter" name="qaEngineerFilter" placeholder="QA Engineer">
                                </div>  
                            </th>  
                            <th class="hidden-xs">
                                <div class="AtjIsPoint" (click)="sort('TestResult',1)">
                                    <span *ngIf="!projectLeaderService.isFiltering">Test Result</span>
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestResultColName!='TestResult' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='TestResult'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestResultDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestResultDir"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="projectLeaderService.isFiltering">
                                    <input type="text" #txttestResultFliter="ngModel" [(ngModel)]="projectLeaderService.testResultFliter"
                                    id="testResultFliter" name="testResultFliter" placeholder="Test Result">
                                </div>  
                            </th> 
                            <th class="hidden-xs">
                                <div class="AtjIsPoint" (click)="sort('QAResult',1)">
                                    <span *ngIf="!projectLeaderService.isFiltering">QA Result</span>
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestResultColName!='QAResult' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='QAResult'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestResultDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestResultDir"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="projectLeaderService.isFiltering">
                                    <input type="text" #txtqaResultFliter="ngModel" [(ngModel)]="projectLeaderService.qaResultFliter"
                                    id="qaResultFliter" name="qaResultFliter" placeholder="QA Result">
                                </div>  
                            </th>
                            <th>Est.MHrs</th>
                            <th>Act.MHrs
                                <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter(1)">
                                    <i *ngIf="!projectLeaderService.isFiltering" class="fas fa-filter"></i>
                                    <i *ngIf="projectLeaderService.isFiltering" class="fas fa-times"></i>
                                </a>
                            </th> 
                        </tr>
                    </thead>
                    <tbody class="hover">
                        <tr *ngFor="let obj of this.projectLeaderService.allActivityWiseTestResultHistory.TestResult | gridOperations : 0 :
                            {QAEngineer:projectLeaderService.qaEngineerFilter,TestResult:projectLeaderService.testResultFliter,QAResult:projectLeaderService.qaResultFliter}
                            :{Column:projectLeaderService.orderByTestResultColName,Dir:projectLeaderService.orderByTestResultDir};let i=index" (click)="displayActivityWiseTestResultFeedBackHistory(obj.EmployeeId)">
                         <td>{{i+1}}</td>
                         <td>{{obj.ExpCDate}}</td>
                         <td>{{obj.ActCDate}}</td>
                         <td title="{{obj.QAEngineer}}">{{obj.QAEngineer}}</td>
                         <td>{{obj.TestResult}}</td>
                         <td>{{obj.QAResult}}</td>
                         <td>{{obj.EstMHrs}}</td>
                         <td>{{obj.ActMHrs}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
            <br>
            <div style="float: right;">
                <button class="btn btn-sm btn-success" (click)="closePopUp()">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- Test Result FeedBack -->
<div *ngIf="this.testFeedBackPopUp" class="Atj_overlay " (click)="$event.stopPropagation(); closeTestFeedBack()">
    <div id="Atj_popup" class="Atj_popup ATJ-slide-bottom" (click)="$event.stopPropagation();">
        <div class="headding" *ngIf="this.projectLeaderService.allActivityWiseTestFeedBackHistory.length > 0">Test FeedBack</div>
        <a class="close" (click)="closeTestFeedBack()"></a>
        <div class="headding" *ngIf="this.projectLeaderService.allActivityWiseTestFeedBackHistory.length == 0"> 
            No Test FeedBack.....!
        </div>
        <div class="Atj_content" *ngIf="this.projectLeaderService.allActivityWiseTestFeedBackHistory.length > 0">          
            <div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width:50px;">SNO</th>    
                            <th style="width:170px;" class="hidden-xs">
                                <div class="AtjIsPoint" (click)="sort('QAEngineer',2)">
                                    <span *ngIf="!this.isTestFeedBackFiltering">QA Engineer</span>
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestFeedBackColName!='QAEngineer' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='QAEngineer'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestFeedBacktDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestFeedBacktDir"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="this.isTestFeedBackFiltering">
                                    <input type="text" #txtqaEngineerFilter="ngModel" [(ngModel)]="projectLeaderService.qaEngineerTestFeedBackFilter"
                                    id="qaEngineerFilter" name="qaEngineerFilter" placeholder="QA Engineer">
                                </div>  
                            </th>                        
                            <th class="hidden-xs" style="width:150px;">
                                <div class="AtjIsPoint" (click)="sort('Date',2)">
                                    Date&Time
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestFeedBackColName!='Date' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='Date'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestFeedBacktDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestFeedBacktDir"></i>
                                        </div>
                                    </div>
                                </div>
                            </th>     
                            <th style="width:90px;" class="hidden-xs">
                                <div class="AtjIsPoint" (click)="sort('IsCleared',2)">
                                    Cleared
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestFeedBackColName!='IsCleared' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='IsCleared'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestFeedBacktDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestFeedBacktDir"></i>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th class="hidden-xs">Description
                                <!-- <div class="AtjIsPoint" (click)="sort('Description',2)">                                 
                                    <div class="AtjfloatRight">
                                        <i class="fas fa-sort" *ngIf="projectLeaderService.orderByTestFeedBackColName!='Description' "></i>
                                        <div *ngIf="projectLeaderService.orderByColName=='Description'">
                                            <i class="fas fa-sort-amount-down" *ngIf="projectLeaderService.orderByTestFeedBacktDir"></i>
                                            <i class="fas fa-sort-amount-up-alt" *ngIf="!projectLeaderService.orderByTestFeedBacktDir"></i>
                                        </div>
                                    </div>
                                </div>  -->
                                <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter(2)">
                                    <i *ngIf="!this.isTestFeedBackFiltering" class="fas fa-filter"></i>
                                    <i *ngIf="this.isTestFeedBackFiltering" class="fas fa-times"></i>
                                </a>                           
                            </th>                                               
                        </tr>
                    </thead>
                    <tbody class="hover">
                        <tr *ngFor="let obj of this.projectLeaderService.allActivityWiseTestFeedBackHistory | gridOperations : 0 :
                             {QAEngineer:projectLeaderService.qaEngineerTestFeedBackFilter}
                            :{Column:projectLeaderService.orderByTestFeedBackColName,Dir:projectLeaderService.orderByTestFeedBacktDir};let i=index">
                         <td style="width:50px;">{{i+1}}</td>
                         <td style="width:150px;" title="{{obj.QAEngineer}}">{{obj.QAEngineer}}</td>
                         <td style="width:130px;">{{obj.Date}}</td>
                         <td style="width:90px;">{{obj.IsCleared}}</td>
                         <td>{{obj.Description}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>             
            <br>
            <div style="float: right;">
                <button class="btn btn-sm btn-success" (click)="closeTestFeedBack()">Close</button>
            </div>
        </div>
    </div>
</div>