import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LeaveApplication, LeaveApplicationApprover, LeaveBalanceDetails, LeaveEmpWorkSchedule } from '../Models/LeaveApplication';
import { GlobalErrorHandlerService } from './global-error-handler.service'; 
import { Observable } from 'rxjs/observable';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators'; 
import { LeaveDetails } from '../Models/LeaveDetails';
import { Response } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class LeaveApplicationService {

  constructor(private http: HttpClient, private _globalErrorHandlerService: GlobalErrorHandlerService) { }
  SelectedData:LeaveApplication;
  DataListFilter:LeaveApplication;
  DataList:LeaveApplication[];
  pageIndex:number=1;
  isFiltering:boolean;
  orderByDir:boolean;
  orderByColName:string;
  IsForm:boolean;
  giveEditPermission:boolean=false;
  LeaveEmpWorkScheduleList:LeaveEmpWorkSchedule[];
  leaveTypeList:LeaveDetails[]=[];
  EmpLeaveBalanceDetails:LeaveBalanceDetails[]=[];
  UserRole:string
  leavereqcount:number=0;/* Any new leave request/leave approved/leave rejected */
  
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  ClearValue(){
    this.SelectedData={Status:0,Type:1,Date:null,StatusText:'',LeaveTypeText:'',Description:'',Designation:'',EmpCode:'',EmpID:0,EmpName:'',FromDate:null,ID:0,LeaveApproverDetails:[],LeaveTypeID:0,RefNo:'',ToDate:null}
  }

  ClearFilter(){
    this.DataListFilter={Status:null,Type:1,Date:null,StatusText:'',LeaveTypeText:'',Description:'',Designation:'',EmpCode:'',EmpID:0,EmpName:'',FromDate:null,ID:0,LeaveApproverDetails:[],LeaveTypeID:0,RefNo:'',ToDate:null}
  }

  GetData(){
      this.SelectedData=
        {Status:0,Type:0,Description:'',StatusText:'',Date:null,Designation:'',EmpCode:'',EmpID:0,EmpName:'',FromDate:null,ID:0,LeaveTypeID:0,RefNo:'',ToDate:null,LeaveTypeText:'',
        LeaveApproverDetails:[{ID:0,ApproverID:0,ApproverName:'test',ResponsDate:null,Status:0,Reason:'test reson',LeaveAppID:0}]}
  }

  GetAllLeaveRequest(employeeId:number): Observable<LeaveApplication[]> { 
    let params=new HttpParams().set('TransMode','GETALLREQ')
    params=params.append('EmpID',employeeId.toString())
    return this.http.get<LeaveApplication[]>(ROOT_URL + 'LeaveApplication/getAllLeaveRequest',{
      params
    })
      .pipe(
        catchError((error) => this._globalErrorHandlerService.handleError(error))
      ); 
  }
  
  GetApproverList(ID,EmpID): Observable<LeaveApplicationApprover[]> {
    return this.http.get<LeaveApplicationApprover[]>(ROOT_URL + 'LeaveApplication/getApproverDetails?ID='+ID+'&EmpID=' + EmpID+'&TransMode=GETAPPROVERSTATUS')
      .pipe(
        catchError((error) => this._globalErrorHandlerService.handleError(error))
      ); 
  }

  SubmitLeaveApplication(value: LeaveApplication): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      Status:value.Status,
      Type:value.Type,
      Date:this.convert(value.Date) ,
      Designation:value.Designation,
      EmpCode:value.EmpCode,
      EmpID:value.EmpID,
      EmpName:value.EmpName,
      FromDate:this.convert(value.FromDate) ,
      ID:value.ID,
      LeaveApproverDetails:value.LeaveApproverDetails,
      LeaveTypeID:value.LeaveTypeID,
      RefNo:value.RefNo,
      Description:value.Description,
      ToDate:this.convert(value.ToDate) 
    } 
    let params = new HttpParams();
    params = params.append('TransMode', 'SAVE');
    return this.http.post<any>(ROOT_URL + 'LeaveApplication/SaveLeaveApplication', body, {
      headers,params
    })
      .pipe(
        catchError((error) => this._globalErrorHandlerService.handleError(error))
      );
  }

  GetWorkSchedule(EmpID,StartDate:Date,CompDate:Date): Observable<LeaveEmpWorkSchedule[]> {
    let beginDate=this.convert(StartDate);
    let tillDate=this.convert(CompDate) ;
   return this.http.get<LeaveEmpWorkSchedule[]>(ROOT_URL + 'LeaveApplication/getWorkSchedule?EmpID=' + EmpID+'&beginDate='+beginDate+'&tillDate='+tillDate+'&TransMode=WORKSCHEDULE')
      .pipe(
        catchError((error) => this._globalErrorHandlerService.handleError(error))
      ); 
  }

  GetLeaveBalance(EmpID): Observable<LeaveBalanceDetails[]> { 
   return this.http.get<LeaveBalanceDetails[]>(ROOT_URL + 'LeaveApplication/LeaveBalanceDetails?EmpID=' + EmpID+'&TransMode=LEAVEDETAILS')
      .pipe(
        catchError((error) => this._globalErrorHandlerService.handleError(error))
      ); 
  }

  deleteLeaveApplication(empId,leaveId)
  {
    let params = new HttpParams();
    params = params.append('EmpId', empId);
    params = params.append('LeaveId', leaveId);
    return this.http.delete<Response>(ROOT_URL + 'LeaveApplication/DeleteLeaveApplication',{
      params
    })
    .pipe(
      catchError((error) => this._globalErrorHandlerService.handleError(error))
    ); 
  }
}
