import { LoadingComponent } from './../../../../Layout/loading/loading.component';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentSetting } from 'src/app/Models/Settings';
import { CommonService } from 'src/app/Services/common.service';
import { SettingsService } from 'src/app/Services/settings.service';

@Component({
  selector: 'app-documentsetting-list',
  templateUrl: './documentsetting-list.component.html',
  styleUrls: ['./documentsetting-list.component.css']
})
export class DocumentsettingListComponent implements OnInit {

  errorMsg:string='';
  NoOfItems: number = 10;
  deleteID:number=0;deletePopUp:boolean=false;
  term:string='';

  constructor(public _SettingsService:SettingsService,
              public _CommonService:CommonService,
              public toastr:ToastrService) { }

  ngOnInit(): void {
    this.deleteID = 0;
    this.deletePopUp = false;
    this._SettingsService.pageIndex = 1;
    this._SettingsService.getalldoc(0,0)
      .subscribe(data => {
        this._SettingsService.allDocumentSetting = data
      },
        error => {
          this.errorMsg = error;
        })
    this._SettingsService.formHideShow = 0;
  }

  toggleFilter() {
    this._SettingsService.isFiltering = this._SettingsService.isFiltering ? false : true;
    if (!this._SettingsService.isFiltering) {
      this._SettingsService.ClearFilter();
      this._SettingsService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._SettingsService.orderByColName) {
      this._SettingsService.orderByDir = !this._SettingsService.orderByDir;
    }
    this._SettingsService.orderByColName = colName;
  }

  addNew() {
    this._SettingsService.formHideShow = 1;
  }

  OnEdit(selectedRow: DocumentSetting) {
    this._SettingsService.selecteddocumentSetting = Object.assign({}, selectedRow);
    this._SettingsService.formHideShow = 1;
  }

  onDelete(deleteID: number) {
    this.deleteID = deleteID;
    this.deletePopUp = true;
  }
  
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._SettingsService.onDelete(this.deleteID)
        .subscribe(x => {
          if(x.Status)
          {
            this._SettingsService.getalldoc(0,0).subscribe(data => this._SettingsService.allDocumentSetting = data,
              error => {
                this.errorMsg = error;
              });
            this.toastr.success(x.Message, "Document Setting");
          }
        }, error => {
          this.errorMsg = error;
          this.toastr.warning("Deletion Failure", "Document Setting");
        })
    }
  }

}
