import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { customer } from 'src/app/Models/customer.model';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { SoftwareRegistrationService } from 'src/app/Services/software-registration.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { CommonService } from 'src/app/Services/common.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-node-reg-form',
  templateUrl: './node-reg-form.component.html',
  styleUrls: ['./node-reg-form.component.css']
})
export class NodeRegFormComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    public _nodeRegService: NodeRegistrationService,
    public _swRegService: SoftwareRegistrationService,
    public _countryService: CountryService,
    public _dealrService: DealerService,
    public _projectService: ProjectService,
    public _custService: CustomerService,
    public _brnchService: BranchService,
    public _CommonService: CommonService) { }

  Country_Id: any;
  public errorMsg;
  selectedCustomer: customer;
  indexs: number = 0
  // DealerId: string = '0'
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  ngOnInit(): void {
    this.resetForm();
    this._nodeRegService.tblVisible = 0
    this._nodeRegService.formHideShow = 0;
    this._countryService.getCountry()
      .subscribe(data => {
        this._countryService.allCountries = data
      },
        error => {
          this.errorMsg = error;
        });


    this.LoadDealer();
    this.LoadCustomers();
    // this.LoadBranch();
    // this.LoadProject();
    // this.GetRefNo();
  }

  LoadDealer() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        this._dealrService.allDealers = data
        this._nodeRegService.selectedNodeRegistration.CountryId = data[0].CountryId;
        this._nodeRegService.selectedNodeRegistration.DealerId = data[0].Id;
      })
  }

  LoadCustomers() {
    //Software Registered Customers only
    this._nodeRegService.selectedNodeRegistration.BranchId = null;
    this._nodeRegService.selectedNodeRegistration.CustomerId = null;
    this._nodeRegService.selectedNodeRegistration.CustomerName = null;
    this._nodeRegService.selectedNodeRegistration.Addr1 = null;
    this._nodeRegService.selectedNodeRegistration.Addr2 = null;
    this._nodeRegService.selectedNodeRegistration.Addr2 = null;
    this._nodeRegService.selectedNodeRegistration.ProjectId = null;

    this._custService.FetchAllCustomer('CUSTOMERBYSWREG')
      .subscribe(data => {
        this._custService.allCustomers = data
        this._nodeRegService.selectedNodeRegistration.CustomerId == null
      },
        error => {
          this.errorMsg = error;
        });
  }

  // LoadSRRefNoByCustID() {
  //   this._swRegService.getSoftwareregRefnoByCustomer(this._nodeRegService.selectedNodeRegistration.CustomerId == null ? 0 : this._nodeRegService.selectedNodeRegistration.CustomerId)
  //     .subscribe(data => {
  //       this._swRegService.allSRRefno = data
  //     },
  //       error => {
  //         this.errorMsg = error;
  //       });
  // }


  fillbranch() {
    this._nodeRegService.selectedNodeRegistration.ProjectId = null;
    this._nodeRegService.selectedNodeRegistration.BranchId = null;

    this._brnchService.Fetch_BranchById(this._nodeRegService.selectedNodeRegistration.CustomerId == null ? 0 : this._nodeRegService.selectedNodeRegistration.CustomerId, 'SWREGISTERED')
      .subscribe(data => {
        this._brnchService.allBranchs = data
        this._nodeRegService.selectedNodeRegistration.BranchId = null
        let branchList = data;
        if (branchList.length == 1)
          branchList.filter(e => {
            if (e.CustomerId == e.CustomerId) {
              this._nodeRegService.selectedNodeRegistration.BranchId = e.Id
              this.LoadProject();
            }
          })

      },
        error => {
          this.errorMsg = error;
        });
  }
  LoadBranch() {
    let customerID = this._nodeRegService.selectedNodeRegistration.CustomerId == null ? 0 : this._nodeRegService.selectedNodeRegistration.CustomerId
    if (this._nodeRegService.selectedNodeRegistration.CustomerId != null)
      this._brnchService.Fetch_BranchById(customerID, 'CUSTOMERID')
        .subscribe(data => {
          this._brnchService.allBranchs = data
          this._nodeRegService.selectedNodeRegistration.BranchId = null
        },
          error => {
            this.errorMsg = error;
          });
  }

  onSelect(Cust_ID) {
    this._nodeRegService.selectedNodeRegistration.Addr1 = '';
    this._nodeRegService.selectedNodeRegistration.Addr2 = '';
    this._nodeRegService.selectedNodeRegistration.Addr3 = '';
    this._nodeRegService.selectedNodeRegistration.CustomerId = Cust_ID;
    var custDtls = this._custService.allCustomers.filter(e => e.Id == this._nodeRegService.selectedNodeRegistration.CustomerId)[0];
    if (custDtls != null) {
      this._nodeRegService.selectedNodeRegistration.Addr1 = custDtls.Addr1;
      this._nodeRegService.selectedNodeRegistration.Addr2 = custDtls.Addr2;
      this._nodeRegService.selectedNodeRegistration.Addr3 = custDtls.Addr3;
    }
  }

  LoadProject() {
    if (this._nodeRegService.selectedNodeRegistration.BranchId != null)
      this._projectService.fetchProject('BYBRANCHID', this._nodeRegService.selectedNodeRegistration.BranchId)
        .subscribe(data => {
          console.log('Projects:', data)
          this._projectService.allProjects = data
        },
          error => {
            this.errorMsg = error;
          });

  }


  updateMyDate(newDate) {
    this._nodeRegService.currentStringDateToBind = newDate;
    this._nodeRegService.currentStringDate = this.convert(newDate);
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this._nodeRegService.allNodeRegistrationDTLs = []
    if (form != null)
      form.reset();
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._nodeRegService.selectedNodeRegistration = {
      Id: null,
      CountryId: null,
      DealerId: null,
      // RefNo: '',
      CurDate: new Date,
      // SRNo: null,
      CustomerId: null,
      CustomerName: '',
      Addr1: '',
      Addr2: '',
      Addr3: '',
      BranchId: null,
      BranchName: '',
      NodeRegistrationDTLs: null,
      ProjectId: null
    }
    this._nodeRegService.selectedNodeRegistration.NodeRegistrationDTLs = []
    this.LoadDealer();
    // this.GetRefNo();
  }
  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._nodeRegService.formHideShow = 0;

  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid) {
      this.isSubmit = true
      if (form.value.Id == null) {
        if (this._nodeRegService.allNodeRegistrationDTLs.length != 0) {
          this._nodeRegService.postNodeRegistration(this._nodeRegService.selectedNodeRegistration)
            .subscribe(data => {
              this.isSubmit = false
              this.resetForm(form);
              this.getAllNodeReg()
              this.toastr.success('New Record Added Succcessfully', 'NodeRegistration');
              this._nodeRegService.formHideShow = 0;
            },
              error => {
                this.errorMsg = error;
                this.resetForm(form);
                this.toastr.error('New Record Added Failure', 'NodeRegistration');
              })
        }
        else {
          this.toastr.warning('All Nodes are Already Registered', 'NodeRegistration');
          this.resetForm(form);
          this.getAllNodeReg()
          this._nodeRegService.formHideShow = 0;
        }
      }
      else {

        this._nodeRegService.putNodeRegistration(this._nodeRegService.selectedNodeRegistration)
          .subscribe(data => {
            this.isSubmit = false
            this.resetForm(form);
            this.getAllNodeReg()
            this.toastr.info('Record Updated Successfully!', 'NodeRegistration');
            this._nodeRegService.formHideShow = 0;
          },
            error => {
              this.errorMsg = error;
              this.toastr.error('Record not Updated', 'NodeRegistration');
            });

      }

    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'NodeRegistration');
    }
  }

  getAllNodeReg() {
    this._nodeRegService.getNodeRegistration()
      .subscribe(data => {
        this._nodeRegService.allNodeRegistrations = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  i: number = 0

  LoadRegisteredSystemDetails(NodeRegistrationForm: NgForm) {
    this._nodeRegService.allNodeRegistrationDTLs = []
    console.log(NodeRegistrationForm.value)
    if (NodeRegistrationForm.value.CustomerId != null && NodeRegistrationForm.value.BranchId != null && NodeRegistrationForm.value.ProjectId != null) {
      this._nodeRegService.LoadRegisteredSystemDetails(NodeRegistrationForm.value.CustomerId, NodeRegistrationForm.value.BranchId, NodeRegistrationForm.value.ProjectId).
        subscribe(obj => {
          console.log('LoadRegisteredSystemDetails', obj)
          if (obj.length > 0)
            this._nodeRegService.allNodeRegistrationDTLs = obj
          else
            this.toastr.warning('No Record Found', 'Node Registration')
          //   console.log('LoadSoftRegDtls', obj)
          //   if (obj.Nodes > 0) {
          //     this._nodeRegService.tblVisible = 1;
          //     while (this.i < obj.Nodes) {
          //       this._nodeRegService.newAttribute = {}
          //       this._nodeRegService.allNodeRegistrationDTLs.push(this._nodeRegService.newAttribute)
          //       this.i = this.i + 1
          //     }
          //     this.i = 0
          //   }
          //   else {
          //     this._nodeRegService.tblVisible = 0;

          //   }
        })
    }
  }

  addFieldValue() {
    this._nodeRegService.allNodeRegistrationDTLs.push(this._nodeRegService.newAttribute)
    this._nodeRegService.newAttribute = {}
    this._nodeRegService.selectedNodeRegistration.NodeRegistrationDTLs = [];
  }

  deleteFieldValue(index) {
    this._nodeRegService.allNodeRegistrationDTLs.splice(index, 1);
  }

  onSearchChange(name: string, i: number) {
    console.log(this._nodeRegService.allNodeRegistrationDTLs)
  }
}
