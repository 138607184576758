<!-- <div>
    <app-project-allocation-list></app-project-allocation-list>
</div>
<div>
    <app-project-allocation-form></app-project-allocation-form>
</div>

-->

<div class="container-fluid">
    <input type="hidden" name="Id">
    <div class="row" style="    margin-top: 36px;">
        <!-- flex-body -->
        <div class="col-md-12">
            <div class="FormBox">
                <div class="row FormHeader sim-button_head">
                    <h3>Project Allocation</h3>
                </div> <br>

                <div class="tab-content">
                    <div id="sec1" class="container tab-pane active">
                        <!-- 
                        <div style="text-align: center;">
                            <h6>
                                <ul style="font-weight: bold;">Customer Requirement</ul>
                            </h6>
                        </div>
                        <br> -->

                        <div class="row">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">SlNo</th>
                                            <th scope="col">RDate</th>
                                            <th scope="col">Ref No</th>

                                            <th scope="col">Customer</th>
                                            <th scope="col">MOReg</th>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Att SE</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">AssTo</th>
                                            <th scope="col">CDate</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>

                                            <td><select class="form-control" name="CustomerId" required>
                                                    <option value="1" disabled>Solved</option>
                                                    <option value="2">Pendning</option>
                                                    <option value="2">Party Solved</option>
                                                </select></td>
                                            <td>Cell</td>

                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>

                                            <td><select class="form-control" name="CustomerId" required>
                                                    <option value="1" disabled>Solved</option>
                                                    <option value="2">Pendning</option>
                                                    <option value="2">Party Solved</option>
                                                </select></td>
                                            <td>Cell</td>


                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>

                                            <td><select class="form-control" name="CustomerId" required>
                                                    <option value="1" disabled>Solved</option>
                                                    <option value="2">Pendning</option>
                                                    <option value="2">Party Solved</option>
                                                </select></td>
                                            <td>Cell</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">SlNo</th>
                                            <th scope="col">Particular</th>
                                            <th scope="col">Module</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>

                                            <td><select class="form-control" name="CustomerId" required>
                                                    <option value="1" disabled>Solved</option>
                                                    <option value="2">Pendning</option>
                                                    <option value="2">Party Solved</option>
                                                </select></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>

                                            <td><select class="form-control" name="CustomerId" required>
                                                    <option value="1" disabled>Solved</option>
                                                    <option value="2">Pendning</option>
                                                    <option value="2">Party Solved</option>
                                                </select></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td>Cell</td>
                                            <td><select class="form-control" name="CustomerId" required>
                                                    <option value="1" disabled>Solved</option>
                                                    <option value="2">Pendning</option>
                                                    <option value="2">Party Solved</option>
                                                </select></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br>
                        <div class="row">

                            <table class="table">
                                <tbody>
                                    <tr>
                                        <th style="border: 1px solid #164e86;">
                                            <div style="text-align: center;">
                                                <h6 style="font-weight: bold;">Pointex Tranding Company LLC </h6>
                                                <p>678/9883 Doha Quatar</p>
                                                <p>Ph :9846565465</p>
                                                <p>Branch:Doha, No of Server: 8 ,No of Nodes:9</p>
                                                <p>Contact Person: Renjith Sunny Contact Date :15/12/2020</p>
                                            </div>
                                        </th>
                                        <th style="border: 1px solid #164e86;">
                                            <div style="text-align: center;">
                                                <h6 style="font-weight: bold;">Attachments </h6>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <p>File Name</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p>File Type</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th style="border: 1px solid #164e86;">
                                            <div style="text-align: center;">
                                                <h6 style="font-weight: bold;">Support Engineer </h6>
                                                <br>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <p>No of Work</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <p>Date of Work</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <br>
                    <div class="row">
                        <button type="submit" class="sim-button button1">Submit</button> </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>