<div class="container-fluid" *ngIf="_prjctService.formHideShow!=0">
    <h2 class="heading_all">PROJECT</h2>
    <br>
    <div id="form-wrap">
        <form #projectForm="ngForm" (ngSubmit)="onSubmit(projectForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_prjctService.selectedProject.Id">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Code<span style="color: red;">*</span></label>
                    <input name="Code" #Code="ngModel" autocomplete="off"
                        [(ngModel)]="_prjctService.selectedProject.Code" placeholder=" Code" required>
                    <div class="validation-error"
                        *ngIf="(Code.invalid && Code.touched)||(Code.invalid &&this.FormValidation)">This Field is Required.</div>
                </div>
                <div class="form-group row">
                    <label>Name<span style="color: red;">*</span></label>
                    <input name="Name" #Name="ngModel" autocomplete="off"
                        [(ngModel)]="_prjctService.selectedProject.Name" placeholder=" Name" required>
                    <div class="validation-error"
                        *ngIf="(Name.invalid && Name.touched)||(Name.invalid &&this.FormValidation)">This Field is Required.</div>
                </div>

                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group row">
                            <label>Monthly Rent</label>
                            <input type="number" name="MonthlyRent" #MonthlyRent="ngModel" autocomplete="off"
                                [(ngModel)]="_prjctService.selectedProject.MonthlyRent" placeholder="Monthly Rent">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group row">
                            <label>Yearly Rent</label>
                            <input type="number" name="YearlyRent" #YearlyRent="ngModel" autocomplete="off"
                                [(ngModel)]="_prjctService.selectedProject.YearlyRent" placeholder="Yearly Rent">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group row">
                            <label>Additional User Monthly</label>
                            <input type="number" name="AddUserMonthlyRent" #AddMonthlyRent="ngModel" autocomplete="off"
                                [(ngModel)]="_prjctService.selectedProject.AddUserMonthlyRent" placeholder="Additional User Monthly">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group row">
                            <label>Additional User Yearly</label>
                            <input type="number" name="AddUserYearlyRent" #AddYearlyRent="ngModel" autocomplete="off"
                                [(ngModel)]="_prjctService.selectedProject.AddUserYearlyRent" placeholder="Additional User Yearly">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row" style="float:right;">
                            <input type="checkbox" name="Cloud" #Cloud="ngModel" autocomplete="off"
                                [(ngModel)]="_prjctService.selectedProject.Cloud">
                            <label>Cloud</label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(projectForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Back</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>