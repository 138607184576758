import { Component, OnInit } from '@angular/core';
import { BussinessTypeService } from 'src/app/Services/bussiness-type.service';
import { ToastrService } from 'ngx-toastr';
import { businessType } from 'src/app/Models/businessType.model';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-bussiness-type-list',
  templateUrl: './bussiness-type-list.component.html',
  styleUrls: ['./bussiness-type-list.component.css']
})
export class BussinessTypeListComponent implements OnInit {

  public errorMsg;
  public term: string = ''

  constructor(public _typeService: BussinessTypeService,
              public _CommonService: CommonService,
              private toastr: ToastrService) { }
              public deletePopUp: boolean = false;
  deleteID: number = 0;
  ngOnInit(): void {
    this.deletePopUp = false;
    this.deleteID = 0;
    this._typeService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._typeService.getBusinessType()
      .subscribe(data => this._typeService.allBusinessTypes = data,
        error => {
          this.errorMsg = error;
        })
    this._typeService.formHideShow = 0;
  }

  toggleFilter() {
    this._typeService.isFiltering = this._typeService.isFiltering ? false : true;
    if (!this._typeService.isFiltering) {
      this._typeService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._typeService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._typeService.orderByColName) {
      this._typeService.orderByDir = !this._typeService.orderByDir;
    }
    this._typeService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  addList() {
    this._typeService.formHideShow = 1;
  }
  showForEdit(type: businessType) {
    this._typeService.selectedBusinessType = Object.assign({}, type);;
    this._typeService.formHideShow = 1;
  }
  onDelete(type: businessType) {
    this.deleteID = type.Id;
    this.deletePopUp = true;
  }
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._typeService.deleteBusinessType(this.deleteID)
        .subscribe(x => {
          this._typeService.getBusinessType().subscribe(data => this._typeService.allBusinessTypes = data,
            error => {
              this.errorMsg = error;
            });
          this.toastr.success('Deletion Successfull', "BusinessType");
        }, error => {
          this.errorMsg = error;
          this.toastr.warning("Deletion Failure", "BusinessType");
        })
    }
  }
}
