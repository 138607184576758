import { Component, OnInit } from '@angular/core';
import { EmployeeService} from '../../../../Services/employee.service';
import { ReportsService } from 'src/app/Services/reports.service';

@Component({
  selector: 'app-project-forecasting',
  templateUrl: './project-forecasting.component.html',
  styleUrls: ['./project-forecasting.component.css']
})
export class ProjectForecastingComponent implements OnInit {
  public errorMsg;
  
  constructor(public employeeService: EmployeeService,
              public reports:ReportsService) { }

  ngOnInit(): void {
    this.reports.selectedDate = (new Date().getFullYear().toString()+'-'+ (new Date().getMonth() + 1));
    this.getProgrammersList();
    this.displayWorkForecasting();
  }

  public getColor(colorNo :number) : string {
    switch(colorNo) { 
      case 1 : return "green"
      case 2 : return "blue"
      case 3 : return "yellow"
      case 4 : return "orange"
    }
  }

  getProgrammersList() {
    this.employeeService.getAllProgrammersTesters().subscribe(
      data=>{
        data.push(this.reports.alloption);
        this.employeeService.allPrgrmsTester=data, 
        this.reports.selectedEmployeeId=0; 
      error=>{this.errorMsg=error;}}
    )
  }

  displayWorkForecasting()
  {
    this.reports.getWorkForecasting(0).subscribe(
      data=>{
        this.reports.workForecastingDetails=data;
        this.reports.isViweEnabled=true;
        console.log("works",this.reports.workForecastingDetails)
      },
      error => {
        this.errorMsg = error;
      });
  }
  
}
