import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { strict } from 'assert';
import { DevDashboard, DevDashboardDet } from '../Models/DevDashboard';
import { TesterDashboard } from '../Models/TesterDashboard';
import { FrequentlyVisitCustomer } from '../Models/FrequentlyVisitCustomer';

@Injectable({
  providedIn: 'root'
})
export class DevdashboardService {
  AllPendingWork: DevDashboard[]
  AllPendingWorkDet: DevDashboardDet
  AllFrequentlyVisitCustomer:FrequentlyVisitCustomer[]

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  getPendingWorks(): Observable<TesterDashboard[]> {
    const params = new HttpParams().append('TransMode', "Dev");
    return this.http.get<any>(ROOT_URL + 'TesterDashBoard/getAllAllotedWorkDetails', {
      params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  fetchProjectDet(obj: DevDashboard): Observable<DevDashboardDet> {
    const body = {
      Vtype: obj.VType,
      RefNo: obj.RefNo,
      DealerId: obj.DealerId
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<DevDashboard>(ROOT_URL + 'DevDashBoard/WorkDetList_Fetch', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  GetFrequentlyVisitCustomer(): Observable<FrequentlyVisitCustomer[]> {
    return this.http.get<FrequentlyVisitCustomer[]>(ROOT_URL + 'DashBoard/GetFrequentlyVisitCustomer')
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

}
