import { CommonService } from './../../../../Services/common.service';
import { Component, OnInit } from '@angular/core';
import { TimeSettingsService } from 'src/app/Services/time-settings.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-time-settings',
  templateUrl: './time-settings.component.html',
  styleUrls: ['./time-settings.component.css']
})
export class TimeSettingsComponent implements OnInit {

  constructor(public _timeSettingsService: TimeSettingsService,
              public _CommonService:CommonService, 
              private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.resetForm();
    this.getTimeSettings();
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this._timeSettingsService.selectedTimeSettings = {
      DDC: null,
      DDU: null,
      DDV: null,
      DPC: null,
      DPU: null,
      DPV: null,
      EDC: null,
      EDU: null,
      EDV: null,
      EPC: null,
      EPU: null,
      EPV: null,
      MDC: null,
      MDU: null,
      MDV: null,
      MPC: null,
      MPU: null,
      MPV: null,
      TDC: null,
      TDU: null,
      TDV: null,
      TPC: null,
      TPU: null,
      TPV: null
    }
    this._timeSettingsService.selectedGeneralSettings={
      DefaultDate:new Date(),
      KeyExpiryRemainderDays:0,
      DecimalPlaces:0,
      PeriodStartDate:new Date(),
      AdministrationPercentage:0
    }
  }
  
  onSubmit(form?: NgForm) {
    this._timeSettingsService.postTimeSettings().subscribe(obj => {
      if (obj.Status == 0) 
        this.toastr.success(obj.Message, 'Settings');
      else 
        this.toastr.warning(obj.Message, 'Settings')
      this.getTimeSettings();
    })
  }

  getTimeSettings() {
    this._timeSettingsService.getTimeSettings().subscribe(obj => {
      this._timeSettingsService.selectedTimeSettings = obj.timesettings
      this._timeSettingsService.selectedGeneralSettings.DefaultDate = obj.DefaultDate
      this._timeSettingsService.selectedGeneralSettings.KeyExpiryRemainderDays=obj.KeyExpiryRemainderDays;
      this._timeSettingsService.selectedGeneralSettings.DecimalPlaces = obj.DecimalPlaces
      this._timeSettingsService.selectedGeneralSettings.PeriodStartDate=obj.PeriodStartDate;
      this._timeSettingsService.selectedGeneralSettings.AdministrationPercentage=obj.AdministrationPercentage;
    })
  }

  onSubmitDate(form: NgForm) {
    this.onSubmit()
  }
}
