import { LeaveApplicationService } from './../../Services/leave-application.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from "jquery";
import { CommonService } from 'src/app/Services/common.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-siderbar2',
  templateUrl: './siderbar2.component.html',
  styleUrls: ['./siderbar2.component.css']
})
export class Siderbar2Component implements OnInit {
  constructor(public userService: UserService,
              private router: Router, 
              public _CommonService: CommonService) {
  }

  time:string
  arrayOfWeekdays = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]
  weekdayName :string

  ngOnInit(): void {
    this._CommonService.isLoading = false;
    const openMenu = document.querySelector(".open-menu");
    const closeMenu = document.querySelector(".close-menu");
    const menuWrapper = document.querySelector(".menu-wrapper");
    const hasCollapsible = document.querySelectorAll(".has-collapsible");

    // Sidenav Toggle
    openMenu.addEventListener("click", function () {
      menuWrapper.classList.add("offcanvas");
    });

    closeMenu.addEventListener("click", function () {
      menuWrapper.classList.remove("offcanvas");
    });


    // Collapsible Menu
    hasCollapsible.forEach(function (collapsible) {
      collapsible.addEventListener("click", function () {
        collapsible.classList.toggle("active");

        // Close Other Collapsible
        hasCollapsible.forEach(function (otherCollapsible) {
          if (otherCollapsible !== collapsible) {
            otherCollapsible.classList.remove("active");
          }
        });
      });
    });

    // user start
    $('.User-avtar').click(function () {
      if ($(".User-Dropdown").hasClass("U-open")) {
        $('.User-Dropdown').removeClass("U-open");
      }
      else {
        $('.User-Dropdown').addClass("U-open");
      }
    });
    // userend
    this.getCurrentDate();
  }

  getCurrentDate() {
    setInterval(() => {
    this.time = this.getDate();
    this.weekdayName=this.arrayOfWeekdays[new Date().getDay().toString()];
    }, 1000); 
  }

  getDate()
  {
    var date = new Date(),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    let hour=date.getHours().toString().length > 1 ? date.getHours() : '0'+ date.getHours();
    let minutes=date.getMinutes().toString().length > 1 ? date.getMinutes() : '0'+ date.getMinutes();
    let second=date.getSeconds().toString().length > 1 ? date.getSeconds() : '0'+date.getSeconds();
    let ampm = hour >= 12 ? 'PM' : 'AM';
    let time=hour + ':' + minutes + ':' + second + ' ' + ampm;
    return ([mnth, day,date.getFullYear()].join("-") +' '+ time);
  }

  Logout() {
    console.clear()
    this._CommonService.logOutUser().subscribe(data => console.log(data));
    // localStorage.clear();
    localStorage.removeItem('userToken');
    localStorage.removeItem('userRoles');
    localStorage.removeItem('Designation');
    this.router.navigate(['/login']);
  }

  closeSideBar() {
    // const closeMenu = document.querySelector(".close-menu");
    const menuWrapper = document.querySelector(".menu-wrapper");
    menuWrapper.classList.remove("offcanvas");
    // closeMenu.addEventListener("click", function () {
    //   menuWrapper.classList.remove("offcanvas");
    // });
  }
}
