import { Injectable } from '@angular/core';
import { Meeting, ScheduledMeeting } from '../Models/Metting';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { error } from 'console';
import { CommonCombo } from '../Models/Settings';
import { Common } from '../Models/Response';
import { Observable, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  formHideShow:number=0;
  allMeeting:Meeting[]=[]
  isFiltering:boolean=false;
  pageIndex:number=0;
  orderByDir: boolean = false;
  orderByColName: string = '';
  MeetingNoFilter:string='';
  TopicFilter:string='';
  ScheduledOnFilter:string='';
  RegardingFilter:string='';
  selectedMeeting:Meeting={
    ID:0,MeetingNo:'',VDate:new Date(),Topic:'',Agenda:'',ScheduledDate:new Date(),ScheduledTime:'',RegardingTypeId:undefined,RegardingId:undefined,
    Description:'',MeetingStatus:false,ScheduledOn:'',Regarding:'',Employees:[]
  }
  employeeList:CommonCombo[]=[];
  regardingTypeList:CommonCombo[]=[];
  regardingList:CommonCombo[]=[];
  allscheduledMeeting:ScheduledMeeting[]=[];

  constructor(private http: HttpClient,
    public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

    ClearFilter()
    {
      this.isFiltering = false;
      this.MeetingNoFilter='';
      this.TopicFilter='';
      this.ScheduledOnFilter='';
      this.RegardingFilter='';
    }

    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }

    
    saveMeeting(values:Meeting)
    {
      var body={
        ID:values.ID,
        MeetingNo:values.MeetingNo,
        VDate:this.convert(values.VDate),
        Topic:values.Topic,
        Agenda:values.Agenda,
        ScheduledDate:this.convert(values.ScheduledDate),
        ScheduledTime:values.ScheduledTime,
        RegardingTypeId:values.RegardingTypeId,
        RegardingId:values.RegardingId, 
        Description:values.Description,
        Employees:JSON.stringify(values.Employees)
      }
      let params = new HttpParams();
      params = params.append('TransMode','SaveMeeting');
      const headers = new HttpHeaders().set('content-type', 'application/json');
      return this.http.post<any>(ROOT_URL+"Meeting/SaveMeeting",body,{
        headers,params
      }).pipe(
        catchError((error)=>this._GlobalErrorHandlerService.handleError(error))
      )
    }

    getallmeeting(transmode:string)
    {
      const headers = new HttpHeaders().set('content-type','application/json');
      let params = new HttpParams();
      params = params.append('TransMode',transmode);
      return this.http.get<Meeting[]>(ROOT_URL+"Meeting/GetAllMeeting" , {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
    getMeetingDetails(transmode:string,Id:number)
    {
      const headers = new HttpHeaders().set('content-type','application/json');
      let params = new HttpParams();
      params = params.append('TransMode',transmode);
      params = params.append('ID',Id.toString());
      return this.http.get<Common[]>(ROOT_URL+"Meeting/GetMeetingDetails" , {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
    onDelete(id)
    {
      const headers = new HttpHeaders().set('content-type','false');
      let params = new HttpParams();
      params = params.append('TransMode',"DELETE");
      params = params.append('ID',id);
      return this.http.delete<any>(ROOT_URL+"Meeting/DeleteMeeting" , {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
    getScheduledMeetingCount(transmode)
    {
      const headers = new HttpHeaders().set('content-type','application/json');
      let params = new HttpParams();
      params = params.append('TransMode',transmode);
      return this.http.get<number>(ROOT_URL+"Meeting/GetScheduledMeetingDetails" , {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
    getScheduledMeetingDetails(transmode)
    {
      const headers = new HttpHeaders().set('content-type','application/json');
      let params = new HttpParams();
      params = params.append('TransMode',transmode);
      return this.http.get<ScheduledMeeting[]>(ROOT_URL+"Meeting/GetScheduledMeetingDetails" , {
        headers,params
      })
        .pipe(
          catchError((error) => this._GlobalErrorHandlerService.handleError(error))
        );
    }
    SaveEmployeeMeetingTime(values:ScheduledMeeting,transmode:string)
    {
      var body={
        MeetingID:values.MeetingID,
        EmpMeetingID:values.EmpMeetingID,
      }
      let params = new HttpParams();
      params = params.append('TransMode',transmode);
      const headers = new HttpHeaders().set('content-type', 'application/json');
      return this.http.post<any>(ROOT_URL+"Meeting/SaveEmployeeMeetingTime",body,{
        headers,params
      }).pipe(
        catchError((error)=>this._GlobalErrorHandlerService.handleError(error))
      )
    }
}
