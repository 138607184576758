import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-route-master',
  templateUrl: './route-master.component.html',
  styleUrls: ['./route-master.component.css']
})
export class RouteMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
