import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx'; 

@Injectable({
  providedIn: 'root'
})
export class ExporttoexcelService {

  fileName= 'Default.xlsx'; 

  constructor() { }

  exportexcel(type): void 
  {
      this.fileName="";
      var currentTimeInSeconds=Math.floor(Date.now());
      if(type==3)
      {
        this.fileName="Outstanding"+currentTimeInSeconds+'.xlsx';
        let outstanding = document.getElementById('outstanding');/* table id is passed over here */   
        const wsco: XLSX.WorkSheet =XLSX.utils.table_to_sheet(outstanding);/* generate workbook and add the worksheet */
        const wbco: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wbco, wsco, 'Sheet3');
        XLSX.writeFile(wbco, this.fileName); /* save to file */
      }
  }

}



  