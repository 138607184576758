import { ActivityWiseWorkPostPoned } from './../../../../Models/PLWorkDetailsHdr.model';
import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { ModuleService } from 'src/app/Services/module.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { ToastrService } from 'ngx-toastr';
import { WorkAllocationService } from 'src/app/Services/work-allocation.service';
import { NgForm } from '@angular/forms';
import $ from 'jquery';
import { EmployeeService } from 'src/app/Services/employee.service';
import { ProjectLeaderService } from 'src/app/Services/project-leader.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { EmpWorkStatus, fileList, PLEmployeeWorksForecast, PLWorkDetailsHdr } from 'src/app/Models/PLWorkDetailsHdr.model';
import { IMG_ROOT_URL, ROOT_URL } from 'src/app/Models/Config';
import { JobCardService } from 'src/app/Services/job-card.service';
import { CustomerRequirement_Attachments } from 'src/app/Models/CustomerRequirements';
import { ActivityRemarks, HoldActivityDetails } from 'src/app/Models/ActivityHold';
import { DateformatPipe } from '../../../../CustomPipe/dateformat.pipe';

@Component({
  selector: 'app-pro-work-allotment',
  templateUrl: './pro-work-allotment.component.html',
  styleUrls: ['./pro-work-allotment.component.css']
})
export class ProWorkAllotmentComponent implements OnInit {
  filterDate: Date;
  closeButtonVisible: boolean = false;
  isFiltering: boolean = false;
  deleteEmpId: number = 0;
  // deleteMessage: string = '';
  customerList: any[];
  fileToUpload: any[] = [];
  chkSelectAll: boolean = false;
  WorkAllotRemark: string;
  RejectReason: string;
  qaRemark: string;
  holdOrReject: number = 0;
  selectedActivityID: number = 0;

  workallotmentHoldReasonsList: any[];
  selectedHoldDetails: HoldActivityDetails;
  HoldDetailsList: HoldActivityDetails[];
  activityRemarksList: ActivityRemarks[];
  selectedActivityRemark: ActivityRemarks;

  PendingCount: number = 0;
  DevelopmentCount: number = 0;
  TestingCount: number = 0;
  SucceedCount: number = 0;
  AllCount: number = 0;

  activityId:number=0;
  stage:number=0;
  joNo:number=0;
  testResult:string="";
  qaResult:string="";
  activityType:number=0;/* type 0-activitydelete,type 1-work date extended */
  activityText:string="Delete Activity";
  selectedReasonId:number=0;
  selectedBatchId:number=0;
  currentDate:string=''

  constructor(
    public _CommonService: CommonService,
    public _WorkAllocationService: WorkAllocationService,
    public _ParticularmasterService: ParticularmasterService,
    public _modulService: ModuleService,
    public _CustomerService: CustomerService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _BranchService: BranchService,
    public _projectService: ProjectService,
    private toastr: ToastrService,
    public _EmployeeService: EmployeeService,
    public _dealrService: DealerService,
    public _ProjectLeaderService: ProjectLeaderService,
    public _JobCardService: JobCardService
  ) { }
  jobOrderExistsStatus: boolean;
  WarningModel: boolean;
  jobOrderForm: boolean;
  serviceEngWF: boolean;
  public errorMsg;
  SupportLeaderId: string

  StatusFullList: any;
  StageFullList: any;
  TypeList: any;
  PriorityList: any;
  Remark: string = '';
  RegNo:number;
  VtypeText:string='';
  RemarkWorkStatus: string = '';
  Description: string = '';
  DevTestToggle: number = 0;
  JobOrderOrManHour: number = 0;
  FileList: fileList[] = [];

  filterRegRepDate: number = 0;
  beginDate: Date = null;;
  ToDate: Date = null;

  selectedJoNo: number
  confirmedModification: boolean;

  // DeleteJono: number;
  deleteActivity:number=0;
  deleteActivityOption:number=-1;
  ActionList: any;
  DisableHoldUpdateButton: boolean;

  ngOnInit(): void {
    this._CommonService.isLoading = false;
    this.holdOrReject = 0;
    this.RejectReason = '';
    this.qaRemark = '';
    this.PendingCount = 0;
    this.DevelopmentCount = 0;
    this.TestingCount = 0;
    this.SucceedCount = 0;
    this.AllCount = 0;
    this.workallotmentHoldReasonsList = [
      { Id: 1, Name: 'Meeting' },
      { Id: 4, Name: 'Clarification' }
    ];

    window.scroll(0, 90);
    this.ActionList = [
      {
        Id: 1,
        Name: 'Accepted',
      },
      {
        Id: 2,
        Name: 'Rejected',
      },
      {
        Id: 3,
        Name: 'Hold',
      },
      {
        Id: 4,
        Name: 'Removed',
      }
    ];
    // this.DeleteJono = 0;
    this.deleteActivity = 0;
    this.WorkAllotRemark = '';
    this.RemarkWorkStatus = '';
    this.customerList = [];
    this.ToDate = new Date();
    this._CustomerService.allCustomers = [];
    this._ProjectLeaderService.PLWorkDetailsDetList = [];

    this.confirmedModification = true;
    this.SelectedJobNo = 0;
    this.SelectedStatus = 0;
    this.filterRegRepDate = 0;
    this.filterDate = new Date();
    this.LoadPendingWorks(0);
    this.getListCommon();
    this.Reset();
    this._ProjectLeaderService.EmployeeWorkForcastDetails = [];
    this._ProjectLeaderService.ClearFilter();
    this.ClearWorkAllotmentHoldReason();
    this.currentDate=this._CommonService.DateFormat(new Date(),1);
  }

  loadJobsBatch()
  {
    this._CommonService.getCommonList("BATCHLIST",this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID==''?0:parseInt(this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID)).subscribe(
      response=>{
        this._WorkAllocationService.jobBatch=response;
      }
    )
  }

  ClearWorkAllotmentHoldReason() {
    this.selectedHoldDetails = { ClarificationFrom: null, FormID: null, ID: 0, MeetingID: null, Reason: '', ServiceCharge: null, Reasons: null, Remark: '' }
  }
  //---------------------------------------------------------NEW CODE----------------------------------------------------------------

  Reset(form?: NgForm) {
    this.fileToUpload = []
    this._ProjectLeaderService.JobAssignAttachmentList = []
    this._ProjectLeaderService.JobAssignAttachementRemovedList = []
    this.selectedJoNo = 0
    this._ProjectLeaderService.activityWiseWorkPostPoned={
      IsWorkStarted:true,
      StartedDate:null,
      CompletedDate:null,
      EstHrs:0,
      EstMin:0
    }
  }
 
  LoadPendingWorks(employeeID) {
    this.PendingCount = 0;
    this.DevelopmentCount = 0;
    this.TestingCount = 0;
    this.SucceedCount = 0;
    this.AllCount = 0;
    this._CommonService.isLoading = true;
    this._ProjectLeaderService.fetchProject('Fetch',employeeID,this.selectedBatchId).subscribe(obj => {
      this._ProjectLeaderService.PLWorkDetailsHdrList = obj;
      this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered = obj;
      let mindate = new Date();
      this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered.forEach((data, i) => {
        this._ProjectLeaderService.PLWorkDetailsHdrList[i].IsDeletable = !this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered.some(ar => ar.ActivityID == data.ActivityID &&  ar.Stage ==8);
        if (!(this.customerList.some(arr => arr.Id == data.CustomerID))) {
          this.customerList.push({ Id: data.CustomerID, Name: data.CustomerName });
        }

        if (new Date(data.RegDate) < new Date(data.RepDate)) {
          if (new Date(mindate) > new Date(data.RegDate)) {
            mindate = new Date(data.RegDate);
          }
        }
        else {
          if (new Date(mindate) > new Date(data.RepDate)) {
            mindate = new Date(data.RepDate);
          }
        }
        //Count check
        this.AllCount += 1;
        if (data.Stage == 5) {
          this.PendingCount += 1;
        }
        else if (data.Stage == 6) {
          this.DevelopmentCount += 1;
        }
        else if (data.Stage == 7) {
          this.TestingCount += 1;
        }
        else if (data.Stage == 8) {
          this.SucceedCount += 1;
        }
      });
      this.beginDate = mindate;
      this.customerList = this.customerList.sort((a, b) => a.Name < b.Name ? -1 : 1);
      this._CommonService.isLoading = false;
    });
  }

  getListCommon() {
    this.PriorityList = []
    this.TypeList = []
    this.PriorityList = this._CommonService.GetPriorityList()
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusFullList = [
      {
        Id: 0,
        Name: 'Pending',
      },
      {
        Id: 1,
        Name: 'WIP',
      },
      {
        Id: 2,
        Name: 'Finished',
      },
      {
        Id: 3,
        Name: 'Hold',
      },
      {
        Id: 4,
        Name: 'Unable to Complete',
      },
      {
        Id: 5,
        Name: 'Testing Finished',
      },
      {
        Id: 6,
        Name: 'QA Finished',
      }
    ];

    this.StageFullList = this._CommonService.GetStageFullProviderList();
    this.fillmodule();
    this.fillproject();
    this.fillparticulars();
    this.LoadDealer();
    this.displayProgrammersTester();
    this.fillServiceEngineer();
  }

  fillparticulars() {
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillmodule() {
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillproject() {
    this._projectService.fetchProject('ALL', 0)
      .subscribe(data => {
        this._projectService.allProjects = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  LoadDealer() {
    this._dealrService.FetchDealer('ALL')
      .subscribe(
        data => {
          this._dealrService.allDealers = data;        
        }
        ,
        error => {
          this.errorMsg = error;
        });
  }

  displayProgrammersTester()
  {
    this._EmployeeService.getAllProgrammersTesters().subscribe(
      data=>{this._EmployeeService.allPrgrmsTester=data,
      error=>{this.errorMsg=error;}}
    )
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('SERVICEENG')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  SelectedIndex: number = null;
  getPendingWorksDet(obj: PLWorkDetailsHdr, i) {
    this.selectedActivityID = obj.ActivityID;
    this.SelectedIndex = obj.ActivityID;
    this.Description = obj.Description;
    this.Remark = obj.Remark;
    this.RemarkWorkStatus = obj.RemarkWorkStatus;
    this.RegNo=obj.RegNo;
    // this.VtypeText=obj.VtypeText;
    this._CommonService.isLoading = true;
    this._ProjectLeaderService.fetchProjectDet(obj).subscribe(obj => {
      this._ProjectLeaderService.PLWorkDetailsDetList = obj;
      this._CommonService.isLoading = false;
    });
    this.FileList = obj.FileList;
  }

  totalExpectedTime: string = '';
  OnClickAllot() {
    this.totalExpectedTime = '';
    var ExpTimeHr = 0;
    var ExpTimeMin = 0;
    let checkedItemCount = 0; this.WorkAllotRemark = '';
    let EstimateEnter = false;
    this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(data => {
      if (data.CheckStatus) {

        ExpTimeHr = Number(data.EstHrs) + ExpTimeHr;
        ExpTimeMin = Number(data.EstMin) + ExpTimeMin;
        checkedItemCount = checkedItemCount + 1;
        if (!(Number(data.EstHrs) > 0) && !(Number(data.EstMin) > 0)) {
          EstimateEnter = true;
        }
        if (data.JoNo > 0 && data.WorkAllocationRemark.length > 0) {
          this.WorkAllotRemark = data.WorkAllocationRemark;
        }
        if (data.JoNo > 0)
          this.selectedJoNo = data.JoNo
      }

    })
    if (checkedItemCount == 0) {
      this.toastr.warning('Please select any activity', 'Work Allocation');
      return;
    }
    if (EstimateEnter) {
      this.toastr.warning('Please enter estimate hours.', 'Work Allocation');
      return;
    }
    if (this._ProjectLeaderService.PLWorkDetailsHdrList.some(data => data.CheckStatus == true && data.JoNo == 0) && this._ProjectLeaderService.PLWorkDetailsHdrList.some(data => data.CheckStatus == true && data.JoNo != 0) && this.confirmedModification) {
      this.DisplayWorkStatus = 2;
      return
    }
    this.confirmedModification = true;
    $('#myModalConfirm').css("display", "block");
    // this.SelectedJobNo
    this.getEmployeeWorksForecast(0, 'ALL',0);
    this.WarningModel = false;
    this.serviceEngWF = true;
    this._CommonService.isLoading = true;
    this.getEmpForcastDetails();
    this._CommonService.isLoading = false;
    // (JOD.Est_Hrs / 60) Est_Hrs, 
    var totalMinutes = (Number(ExpTimeHr) * 60) + Number(ExpTimeMin);
    ExpTimeHr = Math.floor(Number(totalMinutes) / 60)
    ExpTimeMin = totalMinutes - (Math.floor(Number(totalMinutes) / 60) * 60)
    this.totalExpectedTime = ExpTimeHr + ' Hr ' + ExpTimeMin + ' Min';
  }
  popUpConfirm() {
    this.DisplayWorkStatus = 0;
    this.confirmedModification = false;
    this.OnClickAllot();
  }

  // GetAttachmentsByJoNo(JoNo: number) {
  //   this.fileToUpload = []
  //   this._ProjectLeaderService.JobAssignAttachmentList = []
  //   if (JoNo > 0) {
  //     this._ProjectLeaderService.GetAttachmentsByJoNo(JoNo).subscribe(obj => {
  //       if (obj.length > 0)
  //         this._ProjectLeaderService.JobAssignAttachmentList = obj
  //     })
  //   }
  // }

  LoadEmployeesList(JoNo: number) {
    this._ProjectLeaderService.LoadEmployeesList(JoNo).subscribe(obj => {
      this._ProjectLeaderService.PLWorkDetailsDetList = obj
    })
  }

  //---------------------------------------------------------OLD CODE.............................................................
  OnClickCancel() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");
    this.jobOrderExistsStatus = false
    this.WarningModel = false
    this.serviceEngWF = false
    this.jobOrderForm = false
    this.selectedJoNo = 0
    this.selectedReasonId=0;
    // this.Reset();
  }

  decline() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");

    this._WorkAllocationService.CheckedWorkAllocationDet = this._WorkAllocationService.AllWorkAllocationDet.filter(obj => {
      return obj.CheckStatus == true && obj.JoNo == 0
    })
    if (this._WorkAllocationService.CheckedWorkAllocationDet.length > 0) {
      $('#myModalConfirm').css("display", "block")
      this.WarningModel = false
      this.jobOrderForm = false
      this.serviceEngWF = true
    }
  }

  confirm() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");

    this._WorkAllocationService.CheckedWorkAllocationDet = this._WorkAllocationService.AllWorkAllocationDet.filter(obj => {
      return obj.CheckStatus == true
    })
    this._WorkAllocationService.getJobOrderByJobId(this._WorkAllocationService.SelectedJobOrderHdr.JoNo).subscribe(obj => {
      this._WorkAllocationService.SelectedJobOrderHdr = obj
      if (this._WorkAllocationService.CheckedWorkAllocationDet.length > 0) {
        $('#myModalConfirm').css("display", "block")
        this.WarningModel = false
        this.jobOrderForm = false
        this.serviceEngWF = true
      }
    })
  }

  WorkForeCastingSubmit() {
    this._WorkAllocationService.AllWorkAllocationDet = []
    if (this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.JoNo == 0) {
      this._WorkAllocationService.SaveJobOrder(0, 'SAVE').subscribe(obj => {
        this._WorkAllocationService.AllWorkAllocationDet = obj;
        this.toastr.success('Saved Successfully', 'Work Allocation')
        this.OnClickCancel();
      })
    }
    else {
      this._WorkAllocationService.SaveJobOrder(this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.JoNo, 'MODIFY')
        .subscribe(obj => {
          this._WorkAllocationService.AllWorkAllocationDet = obj;
          this.toastr.success('Modified Successfully', 'Work Allocation')
          this.OnClickCancel();
        })
    }
  }

  ToggleDevTest() {
    this._ProjectLeaderService.PLEmployeeWorksForecastList.forEach(data => {
      // data.checkStatus = false;
      if (data.ExpStartDate !=null && data.ExpStartDate!=undefined) {
        if (data.Designation == 10 && this.DevTestToggle == 1)
        {
          data.checkStatus = false;
          data.ExpStartDate=null;
          data.ExpCompDate=null;
        } 
        else if (data.Designation == 11 && this.DevTestToggle == 1)
        {
          data.checkStatus = false;
          data.ExpStartDate=null;
          data.ExpCompDate=null;
        } 
      }
    });
  }

  DisableAlloteButton: boolean = false;
  onAllottWork() {
    let act: boolean = true;
    let emp: boolean = true;
    let DateMissing = false;
    let selectedActivity: PLWorkDetailsHdr[] = [];
    let selectedEmployee: PLEmployeeWorksForecast[] = [];
    this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(data => {
      if (data.CheckStatus) {
        act = false;
        selectedActivity.push(data);
      }
    });

    this._ProjectLeaderService.PLEmployeeWorksForecastList.forEach(data => {
      if (data.checkStatus) {
        emp = false;
        selectedEmployee.push(data);
        if (data.ExpStartDate == null || data.ExpCompDate == null) {
          DateMissing = true;
        }
        else {
          if (!(data.ExpStartDate.toString().length > 0) || !(data.ExpCompDate.toString().length > 0)) {
            DateMissing = true;
          }
        }
      }
    });
    if (act) {
      this.toastr.warning('Please select any activity.', 'Work Allocation');
      return
    }
    if (emp) {
      this.toastr.warning('Please select any employee.', 'Work Allocation');
      return
    }
    if (DateMissing) {
      this.toastr.warning('Please check the date\'s.', 'Work Allocation');
      return
    }
    this.DisableAlloteButton = true;
    this._CommonService.isLoading = true;
    this._ProjectLeaderService.onAllottWork('SAVE', this.fileToUpload,this.DevTestToggle, selectedActivity, selectedEmployee, this.WorkAllotRemark,this.selectedJoNo).subscribe(data => {
        this._CommonService.isLoading = false;
        this._ProjectLeaderService.JobAssignAttachmentList = []
        this.fileToUpload = []
        if (data.Status == 0) {
          this.toastr.success(data.Message, 'Work Allocation');
          this.LoadPendingWorks(0);
          this.OnClickCancel()
          this.getEmployeeWorksForecast(this.SelectedJobNo, 'DEVTEST',0);
        }
        else {
          this.toastr.warning(data.Message, 'Work Allocation');
        }
        this.SelectedJobNo = 0;
        this.SelectedStatus = 0;
        this.DisableAlloteButton = false;
      });
  }

  downloadFileUrl(URL: fileList) {
    if (URL.Path == 'SoftwareReg') {
      window.open(IMG_ROOT_URL + 'Images/SoftwareRegistration/' + URL.File, '_blank');
    }
    else if (URL.Path == 'WorkStatus') {
      window.open(IMG_ROOT_URL + 'Images/WorkStatus/' + URL.File, '_blank');
    }
  }

  downloadWorkAllotmentFile(URL) {
    window.open(IMG_ROOT_URL + 'Images/ProviderWorkAllocation/' + URL, '_blank');
  }

  nextMonth() {
    this.filterDate = new Date(this.filterDate.setMonth(this.filterDate.getMonth() + 1));
    this.getEmpForcastDetails();
  }

  preMonth() {
    this.filterDate = new Date(this.filterDate.setMonth(this.filterDate.getMonth() - 1));
    this.getEmpForcastDetails();
  }

  getEmpForcastDetails() {
    this._CommonService.isLoading = true;
    this._ProjectLeaderService.GetEmployeeWorkForcastDetails('ALL', this.filterDate, 0).subscribe(
      data => {
        this._CommonService.isLoading = false;
        this._ProjectLeaderService.EmployeeWorkForcastDetails = data;
        //IsLeave :1- fullday leave
        //IsLeave :2- half leave
        //IsLeave :3- off day   
      }
    )
  }

  toggleFilter() {
    this.isFiltering = this.isFiltering ? false : true;
    if (!this.isFiltering) {
      this._ProjectLeaderService.PLWorkDetailsFilter = {
        CustomerName: '', ActivityID: '',
        DealerName: '', DealerId: '', CustomerID: '', EstHrs: '', JoNo: '', ModuleId: '', ModuleText: '', ParticularId: '',
        ParticularText: '', PriorityId: '', PriorityText: '', RegDate: '', RegNo: '', RepDate: '', Stage: '', StageText: '', Status: '',
        StatusText: '', TestResult: '', TestResultText: '', Type: '', TypeText: '', Vtype: '', VtypeText: '',EmployeeId:'',Batch:'',
        PDate:'',EstDate:''
      };
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._ProjectLeaderService.orderByColName) {
      this._ProjectLeaderService.orderByDir = !this._ProjectLeaderService.orderByDir;
    }
    else {
      this._ProjectLeaderService.orderByColName = colName;
    }

    var arr = [];
    arr = Object.assign([], this._ProjectLeaderService.PLWorkDetailsHdrList);
    var Temparr = [];
    if (this._ProjectLeaderService.orderByColName.length > 0)//Sorted
    {
      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9.]/g;
      Temparr = arr.sort((a, b) => {
        var Cola = (<string>a[this._ProjectLeaderService.orderByColName].toString()).toLowerCase();
        var Colb = (<string>b[this._ProjectLeaderService.orderByColName].toString()).toLowerCase();
        var aA = Cola.replace(reA, "");
        var bA = Colb.replace(reA, "");
        if (aA === bA) {
          var aN = parseInt(Cola.replace(reN, ""), 10);
          var bN = parseInt(Colb.replace(reN, ""), 10);
          return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          return aA > bA ? 1 : -1;
        }
      });
      if (this._ProjectLeaderService.orderByDir) {
        Temparr = Temparr.reverse();
      }
      this._ProjectLeaderService.PLWorkDetailsHdrList = Temparr;
    }
  }

  CheckDateFilter() {
    //this.SelectedIndex = null;
    this._ProjectLeaderService.PLWorkDetailsHdrList = [];
    this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered.forEach(data => {
      let date;

      if (this.filterRegRepDate == 1) {
        date = new Date(data.RepDate);
      }
      else {
        date = new Date(data.RegDate);
      }
      if (date != null) {
        if (this.beginDate != null && this.ToDate != null) {
          if (this.beginDate.toString().length > 0 && this.ToDate.toString().length > 0 && this.beginDate.toString() != 'Invalid Date' && this.ToDate.toString() != 'Invalid Date') {

            if (new Date(date.toLocaleDateString()) <= new Date(this.ToDate.toLocaleDateString()) && new Date(date.toLocaleDateString()) >= new Date(this.beginDate.toLocaleDateString())) {
              this._ProjectLeaderService.PLWorkDetailsHdrList.push(data);
            }
          }
          else {
            this._ProjectLeaderService.PLWorkDetailsHdrList = this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered;
          }
        }
        else {
          this._ProjectLeaderService.PLWorkDetailsHdrList = this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered;
        }
      }
    });
  }

  TableFilter() {
    var arr = [];
    // let Temparr=[];
    // Temparr=Object.assign([], this._ProjectLeaderService.PLWorkDetailsHdrList);

    arr = Object.assign({}, this._ProjectLeaderService.PLWorkDetailsHdrList);
    var result = [];
    //this._ProjectLeaderService.PLWorkDetailsHdrList
    arr.forEach(obj => {
      if (obj.DealerId.toString().toLocaleLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.DealerId.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.DealerId.length < 1) {
        if (obj.RegNo.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.RegNo.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.RegNo.length < 1) {
          if (obj.Vtype.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Vtype.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Vtype.length < 1) {
            if (obj.RepDate.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.RepDate.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.RepDate.length < 1) {
              // console.log(obj.Stage,'index =',obj.Stage.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Stage))
              if (obj.Stage.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Stage) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Stage.length < 1) {
                if (obj.Status.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Status) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Status.length < 1) {
                  if (obj.TestResultText.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.TestResultText.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.TestResultText.length < 1) {
                    if (obj.Type.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Type.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Type.length < 1) {
                      if (obj.PriorityId.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.PriorityId) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.PriorityId.length < 1) {
                        if (obj.ModuleId.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.ModuleId) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.ModuleId.length < 1) {
                          if (obj.ParticularId.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.ParticularId) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.ParticularId.length < 1) {
                            if (obj.JoNo.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.JoNo) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.JoNo.length < 1) {
                              result.push(obj);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    });
    this._ProjectLeaderService.PLWorkDetailsHdrList = result;
  }
  DisplayWorkStatus: number = 0;
  workStatus: EmpWorkStatus[] = [];
  selectedStageObj: PLWorkDetailsHdr;
  getWorkStatus(obj: PLWorkDetailsHdr) {
    this.workStatus = [];
    this.selectedStageObj = obj;
    // if (obj.JoNo > 0 && obj.ActivityID > 0) --Aju{ 
    if (obj.Stage > 5 && obj.ActivityID > 0) {
      this._ProjectLeaderService.GetWorkStatus(obj.JoNo, obj.ActivityID).subscribe(data => {
        this.workStatus = data;
        this.DisplayWorkStatus = 1;
      })
    }

  }

  closePopUp() {
    this.qaRemark = '';
    this.selectedStageObj = null;
    if (this.DisplayWorkStatus == 3) {
      this.DisplayWorkStatus = 1;
      return
    }
    else if (this.DisplayWorkStatus == 4) {
      this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(data => {
        if (data.ActivityID == this.preActivityiIDValue) {
          data.ActionID = this.preActionValue;
        }
      });
      this.ClearWorkAllotmentHoldReason();
    }
    else if (this.DisplayWorkStatus == 6) {
      this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(data => {
        if (data.ActivityID == this.preActivityiIDValue) {
          data.ActionID = this.preActionValue;
        }
      });
    }
    this.DisplayWorkStatus = 0;
    this.preActivityiIDValue = 0;
    this.preActionValue = 0;
    this.ActionIDValue = 0;
    this.deleteActivity=0;
    this.activityType=0;
    this.activityText="Delete";
    this._ProjectLeaderService.activityWiseWorkPostPoned={IsWorkStarted:true,StartedDate:null,CompletedDate:null,EstHrs:0,EstMin:0}
  }
  SelectedJobNo: number;
  SelectedStatus: number;
  closeActivityButtonVisible() {
    this.closeButtonVisible = false;
    let allReadytoClose = true;
    let trueIndex = 0;
    this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(data => {
      if (data.CheckStatus) {
        trueIndex = trueIndex + 1
        if (data.Status == 6 && allReadytoClose && data.TestResultText == 'Succeed' && data.Type == 16
        ) {
          allReadytoClose = true;
        }
        else {
          allReadytoClose = false;
        }
      }
    });
    if (trueIndex > 0)
      this.closeButtonVisible = allReadytoClose;
  }

  SelectCheckBox(obj: PLWorkDetailsHdr) {
    if (this._ProjectLeaderService.PLWorkDetailsHdrList.some(ele => ele.CheckStatus == true) && this._ProjectLeaderService.PLWorkDetailsHdrList.some(ele => ele.CheckStatus == true && ele.JoNo > 0)) {
      if (obj.JoNo > 0) {
        this.SelectedJobNo = obj.JoNo;
        this.SelectedStatus = obj.Status;
      }
    }
    else {
      this.SelectedJobNo = 0;
      this.SelectedStatus = 0;
    }
    this.closeActivityButtonVisible();
  }

  closeActivities() {
    let selectedActivity;
    selectedActivity = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(data => data.CheckStatus && data.Status == 6 && data.TestResultText == 'Succeed' && data.Type == 16);

    this._CommonService.isLoading = true;
    this._ProjectLeaderService.onAllottWork('CLOSEACT', this.fileToUpload,
      this.DevTestToggle, selectedActivity, null, this.WorkAllotRemark,
      this.selectedJoNo).subscribe(data => {
        this._CommonService.isLoading = false;

        if (data.Status == 0) {
          this.closeButtonVisible = false;
          this.toastr.success(data.Message, 'Work Allocation');
          this.LoadPendingWorks(0);
          this.SelectedJobNo = 0;
          this.SelectedStatus = 0;
        }
        else {
          this.toastr.warning(data.Message, 'Work Allocation');
        }
      });
  }

  holdingReasonList = [
    { Id: 1, Name: 'Meeting With' },
    { Id: 2, Name: 'Expecting Reasult From' },
    { Id: 3, Name: 'Other Emergency Work' },
    { Id: 4, Name: 'Clarification' }
  ];

  getEmployee() {
    this._EmployeeService.Fetch_Employee('ELSE').subscribe(data => {
      this._JobCardService.allEmployee = data;
    },
      error => {
        this.errorMsg = error;
      })
  }

  getholdReason(JobDetails: EmpWorkStatus) {
    this.DisplayWorkStatus = 3;
    this.getEmployee();
    this._JobCardService.newJobDetails = {
      ID: 0,
      JONO: null,
      Date: new Date(),
      Emp_ID: null,
      Activity: null,
      W_Date: new Date(),
      W_DateFrom: new Date(),
      W_DateTo: new Date(),
      W_From: '',
      W_To: '',
      Break: null,
      NextDay: false,
      Status: null, StatusText: '',
      Batch: 0,
      HoldingReason: { HoldingReason: null, Remark: '', Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: null, ID: 0, JONO: null, MettingWith: null, OtherEmergencyWork: null, Reason: '' }
    }
    if (JobDetails.JobCardID) {
      this._JobCardService.getHoldReason(JobDetails.JobCardID).subscribe(data => {
        this._JobCardService.newJobDetails.HoldingReason.MettingWith = data.MettingWith;
        this._JobCardService.newJobDetails.HoldingReason.HoldingReason = data.HoldingReason;
        this._JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom = data.ExpectingResultFrom;
        this._JobCardService.newJobDetails.HoldingReason.OtherEmergencyWork = data.OtherEmergencyWork;
        this._JobCardService.newJobDetails.HoldingReason.Remark = data.Remark;
        this._JobCardService.newJobDetails.HoldingReason.Reason = data.Reason;
      })
    }
  }

  validateDate(i, cntrl) {
    // if (this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpStartDate != null && this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpCompDate != null) {
    if (this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpStartDate.toString().length > 0 && (this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpCompDate !=null && this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpCompDate.toString().length > 0)) {
      if (this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpStartDate > this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpCompDate) {
        this.toastr.warning('Please Check the dates.', 'Work Allocation');
        if (cntrl == 'frm') {
          this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpStartDate = null;
        }
        else {
          this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpCompDate = null;
        }
        // }
      }
    }
  }

  SelectAllCheckBox() {
    if (this.chkSelectAll) {
      //this._ProjectLeaderService.PLWorkDetailsHdrList
      this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(obj => {
        if ((obj.Status == 0 || obj.Status == 2 || obj.Status == 4 || obj.Status == 6 || (obj.Status == 5 && (obj.TestResultText == 'Failure' || obj.TestResultText == 'Retest' || obj.TestResultText == 'Suggestion'))) && obj.ActionID == 1 && ((this.SelectedJobNo == obj.JoNo) || (this.SelectedJobNo == 0) || (obj.JoNo == 0))) {
          if (obj.DealerId.toString().toLocaleLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.DealerId.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.DealerId.length < 1) {
            if (obj.RegNo.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.RegNo.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.RegNo.length < 1) {
              if (obj.Vtype.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Vtype.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Vtype.length < 1) {
                if (obj.RepDate.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.RepDate.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.RepDate.length < 1) {
                  // console.log(obj.Stage,'index =',obj.Stage.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Stage))
                  if (obj.Stage.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Stage) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Stage.length < 1) {
                    if (obj.Status.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Status) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Status.length < 1) {
                      if (obj.TestResultText.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.TestResultText.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.TestResultText.length < 1) {
                        if (obj.Type.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.Type.toLocaleLowerCase()) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.Type.length < 1) {
                          if (obj.PriorityId.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.PriorityId) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.PriorityId.length < 1) {
                            if (obj.ModuleId.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.ModuleId) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.ModuleId.length < 1) {
                              if (obj.ParticularId.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.ParticularId) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.ParticularId.length < 1) {
                                if (obj.JoNo.toString().toLowerCase().indexOf(this._ProjectLeaderService.PLWorkDetailsFilter.JoNo) > -1 || this._ProjectLeaderService.PLWorkDetailsFilter.JoNo.length < 1) {
                                  obj.CheckStatus = true;
                                  this.SelectedJobNo = obj.JoNo != 0 ? obj.JoNo : this.SelectedJobNo;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

        }
      });

    }
    else {
      this.SelectedJobNo = 0;
      this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(obj => {
        obj.CheckStatus = false;
      })
    }
    this.closeActivityButtonVisible();
  }

  ClearChkSelect() {
    this.chkSelectAll = false;
    this.SelectedJobNo = 0;
    this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(obj => {
      obj.CheckStatus = false;
    })
  }

  isNumber(event) {
    if (event.charCode !== 0) {
      const pattern = /^[0-9]\d*$/;// /^\d{0,4}(\.\d{0,4})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode != 13) {
        event.preventDefault();
      }
    }
  }

  ToggleWorkAllocationChk(i) {
    this._ProjectLeaderService.PLEmployeeWorksForecastList[i].checkStatus = !this._ProjectLeaderService.PLEmployeeWorksForecastList[i].checkStatus;
    if (!this._ProjectLeaderService.PLEmployeeWorksForecastList[i].checkStatus) {
      this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpCompDate = null;
      this._ProjectLeaderService.PLEmployeeWorksForecastList[i].ExpStartDate = null;
    }
  }

  deleteJoNo(obj) {
    if(obj.Stage > 5)
    {
      this.deleteActivity=obj.ActivityID;
      this._ProjectLeaderService.getActivityWiseDeleteOption(this.deleteActivity,0,0).subscribe(data=>{
        this._ProjectLeaderService.ListActivityWiseDeleteOption=data;
        if(data.length==0)
        {
          this.deleteActivityOption=0;
          this.toastr.info("Assigned Employee are Work in Progress", 'Work Allocation');
        }
      }) 
    }  
  }

  displayActivitityExtentedReasons()
  {
    this._CommonService.getCommonList('ActivityExtentedReason').subscribe(
      data=>{
        this._CommonService.allCommonResult=data;
      },
      error => {
        this.errorMsg = error;
      });
  }

  isStartedActivity()
  {
    this.displayActivitityExtentedReasons();
    this.activityType=1;
    this.activityText='Work PostPoned'
    this._ProjectLeaderService.getActivityWiseChanges(this.deleteActivity,"WorkPostPoned").subscribe(
      data=>{
        this._ProjectLeaderService.activityWiseWorkPostPoned=data;
      })
  }

  activityWiseJobChanges() /* remove the employee OR remove fully job from activity OR extend the work start && enddate*/
  {
    if(this.activityType==0)
    {
      if(this.deleteActivityOption==-1)
      {
        this.toastr.info("Please Select One Option", 'Work Allocation');
        return
      }   
      this._ProjectLeaderService.DeleteActivity(this.deleteActivity,this.deleteActivityOption).subscribe(data => {
        if (data.Status == 0) {
          this.LoadPendingWorks(0);
          this.OnClickCancel()
          this.toastr.success(data.Message, 'Work Allocation');
        }
        else
          this.toastr.warning(data.Message, 'Work Allocation');
      })
    }
    else
    {
      if(this._ProjectLeaderService.activityWiseWorkPostPoned.StartedDate > this._ProjectLeaderService.activityWiseWorkPostPoned.CompletedDate)
      {
        this.toastr.info("Extented completed date must be greater than start date", 'Work Allocation');
        return
      }
      else if(this._ProjectLeaderService.activityWiseWorkPostPoned.EstHrs ==0 && this._ProjectLeaderService.activityWiseWorkPostPoned.EstMin==0)
      {
        this.toastr.warning('Please enter estimate hours.', 'Work Allocation');
        return
      }
        let totalEstMin=0;
        totalEstMin=(this._ProjectLeaderService.activityWiseWorkPostPoned.EstHrs * 60) + this._ProjectLeaderService.activityWiseWorkPostPoned.EstMin;
        this._ProjectLeaderService.workDateExtented(this.deleteActivity,this._CommonService.convertWithTime(this._ProjectLeaderService.activityWiseWorkPostPoned.StartedDate),this._CommonService.convertWithTime(this._ProjectLeaderService.activityWiseWorkPostPoned.CompletedDate),this.selectedReasonId,totalEstMin)
        .subscribe(
          data=>{
            if(data.Status)
            {
              this.OnClickCancel();
              this.activityType=0;
              this.toastr.success(data.Message, 'Work Allocation');
            }
            else
              this.toastr.error(data.Message, 'Work Allocation');
          })
    }
    this.deleteActivity=0;
    this.deleteActivityOption=-1;
  }

  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        this.fileToUpload.push(files[i]);
      }
    }
  }

  RemoveImage(image: File) {
    const index = this.fileToUpload.indexOf(image);
    if (index > -1) {
      this.fileToUpload.splice(index, 1);
    }
  }

  RemovePreviousImage(obj: CustomerRequirement_Attachments) {
    const index = this._ProjectLeaderService.JobAssignAttachmentList.indexOf(obj);
    if (index > -1) {
      this._ProjectLeaderService.JobAssignAttachementRemovedList.push(obj)
      this._ProjectLeaderService.JobAssignAttachmentList.splice(index, 1);
    }
  }
  preActionValue: number;
  preActivityiIDValue: number;
  ActionIDValue: number;
  changeActionID(preObj: PLWorkDetailsHdr, ActionID) {
    if (ActionID == 2) {
      this.holdOrReject = 1;
      this.RejectReason = '';
    }
    else {
      this.holdOrReject = 0;
    }

    this.DisableHoldUpdateButton = false;
    preObj.CheckStatus = false;
    this.ActionIDValue = ActionID;
    this.preActionValue = preObj.ActionID;
    this.preActivityiIDValue = preObj.ActivityID;

    if (ActionID == 3) {
      this.DisplayWorkStatus = 4;
      this._CommonService.isLoading = true;
      this._EmployeeService.Fetch_EmployeeById('JOBCARD', this.preActivityiIDValue).subscribe(data => {
        this._JobCardService.allEmployee = data;
        this._CommonService.GetHoldActivityDetails('WORKALLOCATION', this.preActivityiIDValue).subscribe(data2 => {
          this.HoldDetailsList = data2;
          this._CommonService.isLoading = false;
        });
      });
    }
    else if (ActionID == 2) {
      this.DisplayWorkStatus = 6;
    }
    else {
      this._CommonService.ChangeActionID('WORKALLOCATION', this.preActivityiIDValue, ActionID, '').subscribe(data => {
        if (data.Status == 0) {
          this.toastr.success(data.Message, 'Work Allocation');
        }
        else {
          this.toastr.warning(data.Message, 'Work Allocation');
        }
      })
    }
  }

  updateHoldReason() {
    if (this.selectedHoldDetails.Reasons > 0 && (this.selectedHoldDetails.ClarificationFrom > 0) && this.selectedHoldDetails.Reason.length > 0) {
      if (this.selectedHoldDetails.ID == 0) {
        this.ActionIDValue = 3;
      }
      this.ActionIDValue = 3;
      this._CommonService.HoldActivity('WORKALLOCATION', this.selectedHoldDetails.ID, this.preActivityiIDValue, this.ActionIDValue, this.selectedHoldDetails.Reasons, this.selectedHoldDetails.ClarificationFrom, 0, this.selectedHoldDetails.Reason, this.selectedHoldDetails.Remark, 0).subscribe(data => {

        if (data.Status == 0) {
          this.toastr.success(data.Message, 'Work Allocation');
          // if(this.selectedHoldDetails.ID==0){

          let i = this._ProjectLeaderService.PLWorkDetailsHdrList.findIndex(arr => arr.ActivityID == this.preActivityiIDValue);
          this._ProjectLeaderService.PLWorkDetailsHdrList[i].ActionID = 3;
          //}
          this.DisplayWorkStatus = 0;
          this.preActivityiIDValue = 0;
          this.preActionValue = 0;
          this.ActionIDValue = 0;
          this.ClearWorkAllotmentHoldReason();

        }
        else {
          this.toastr.warning(data.Message, 'Work Allocation');
        }
      });
    }
    else {
      this.toastr.warning('Please fill the required fields.', 'Work Allocation');
    }
  }

  getHoldingDetails(obj: PLWorkDetailsHdr) {
    if (obj.ActionID == 2) {
      this.holdOrReject = 1;
    }
    else {
      this.holdOrReject = 0;
    }
    this.RejectReason = obj.RejectReason;
    this.DisableHoldUpdateButton = obj.Stage != 5;
    this.DisplayWorkStatus = 4;
    this._CommonService.isLoading = true;
    this._CommonService.GetHoldActivityDetails('WORKALLOCATION', obj.ActivityID).subscribe(data => {

      this._EmployeeService.Fetch_EmployeeById('JOBCARD', obj.ActivityID).subscribe(data2 => {
        this._JobCardService.allEmployee = data2;
        this.preActivityiIDValue = obj.ActivityID;
        this.ActionIDValue = obj.ActionID;
        this.preActionValue = obj.ActionID;
        this.HoldDetailsList = data;
        this._CommonService.isLoading = false;
      },
        error => {
          this.errorMsg = error;
          this._CommonService.isLoading = false;
        })
    })
  }

  selectHoldingReason(obj: HoldActivityDetails) {
    this.selectedHoldDetails = Object.assign({}, obj);
  }

  getActivityRemarks(obj) {
    this.activityRemarksList = [];
    this._CommonService.isLoading = true;
    this.selectedActivityRemark = { ID: 0, Remark: '', ActivityID: obj.ActivityID, AddedByName: '', EditedByName: '', AddedBy: 0, AddedDate: '', EditedBy: 0, EditedDate: '', FormName: 'WorkAllotment' }
    this._WorkAllocationService.GetActivityRemarkList('WorkAllotment', obj.ActivityID).subscribe(remarks => {
      this.activityRemarksList = remarks;
      this.DisplayWorkStatus = 5;
      this._CommonService.isLoading = false;
    });
  }

  addActivityRemark(obj: ActivityRemarks) {
    obj = Object.assign({}, obj);
    if (obj.ID == 0) {
      obj.AddedByName = 'You';
      obj.AddedDate = this._WorkAllocationService.convert(new Date());
    }
    else {
      obj.EditedDate = this._WorkAllocationService.convert(new Date());
    }
    this._WorkAllocationService.SaveActivtyRemark(obj).subscribe(data => {
      if (data.Status == 0) {
        this.toastr.success(data.Message, 'Work Allocation');
        this._WorkAllocationService.GetActivityRemarkList('WorkAllotment', obj.ActivityID).subscribe(remarks => {
          this.activityRemarksList = remarks;
        });
      }
      else {
        this.toastr.warning(data.Message, 'Work Allocation');
      }
    });
    this.selectedActivityRemark = { ID: 0, Remark: '', ActivityID: obj.ActivityID, AddedByName: '', EditedByName: '', AddedBy: 0, AddedDate: '', EditedBy: 0, EditedDate: '', FormName: 'WorkAllotment' }

  }

  editActivityRemark(obj: ActivityRemarks) {
    this.selectedActivityRemark = Object.assign({}, obj);
  }

  UpdateRejectReason() {
    if (this.RejectReason.length > 0) {
      this.DisableAlloteButton = true;
      this._CommonService.ChangeActionID('REJECTREASON', this.preActivityiIDValue, 2, this.RejectReason).subscribe(data => {
        this.DisableAlloteButton = false;
        if (data.Status == 0) {
          let i = this._ProjectLeaderService.PLWorkDetailsHdrList.findIndex(arr => arr.ActivityID == this.preActivityiIDValue);
          this._ProjectLeaderService.PLWorkDetailsHdrList[i].RejectReason = this.RejectReason;
          this.RejectReason = ''
          let j = this._ProjectLeaderService.PLWorkDetailsHdrList.findIndex(arr => arr.ActivityID == this.preActivityiIDValue);
          this._ProjectLeaderService.PLWorkDetailsHdrList[i].ActionID = 2;
          this.toastr.success(data.Message, 'Work Allocation');
          this.RejectReason = '';
          this.DisplayWorkStatus = 0;
          this.preActivityiIDValue = 0;
          this.preActionValue = 0;
          this.ActionIDValue = 0;
        }
        else {
          this.toastr.warning(data.Message, 'Work Allocation');
        }
      });
    }
    else {
      this.toastr.warning('Please enter your reason.', 'Work Allocation');
    }
  }

  updateDescription() {
    this._CommonService.ChangeActionID('DESCRIPTIONUPDATE', this.selectedActivityID, 0, this.Description).subscribe(data => {
      if (data.Status == 0) {
        this.toastr.success(data.Message, 'Work Status');
        // var i =this._workAllocationStatusService.SelectedWorkStatusDet.findIndex(e=>e.ID==this.selectedActivityID);
        // this._workAllocationStatusService.SelectedWorkStatusDet[i].Description=this._CustomerrequirementService.DescriptionEdit;
        this.LoadPendingWorks(0);
        this.closePopUp();
      }
      else {
        this.toastr.warning(data.Message, 'Work Status');
      }
    });
  }

  openActivityPopUp(i: number, obj: PLWorkDetailsHdr) {
    this.DisplayWorkStatus = 8;
    this.WorkAllotRemark = obj.WorkAllocationRemark;
    this._ProjectLeaderService.activityPopUpDetails = { JONO: obj.JoNo, RegNo: obj.RegNo.toString(), Dealer: obj.DealerName, Module: obj.ModuleText, Particular: obj.ParticularText, Type: obj.TypeText, SNo: (i + 1).toString(), ExtHr: obj.EstHrs, Stage: obj.StageText };
    this.getEmployeeWorksForecast(obj.JoNo, 'JONOVIEW',obj.ActivityID);
    // this.GetAttachmentsByJoNo(obj.JoNo);
  }

  submitJobNoDetails() {
    this._ProjectLeaderService.onAllottWork('JOBNODETAILS', this.fileToUpload,this.DevTestToggle, null, null, this.WorkAllotRemark,Number(this._ProjectLeaderService.activityPopUpDetails.JONO)).subscribe(data => {
        this._ProjectLeaderService.JobAssignAttachmentList = []
        this.fileToUpload = []
        if (data.Status == 0) {
          this.toastr.success(data.Message, 'Work Allocation');
          this.LoadPendingWorks(0);
        }
        else {
          this.toastr.warning(data.Message, 'Work Allocation');
        }
        this.DisableAlloteButton = false;
      });
    this.closePopUp();
  }

  getEmployeeWorksForecast(jono: number, TransMode: string,ActivityID:number) {
    this._ProjectLeaderService.getEmployeeWorksForecast(TransMode, jono,ActivityID).subscribe(data => {
      this._ProjectLeaderService.PLEmployeeWorksForecastList = data;
    })
  }

  sendTestResultData(ActivityId:number,Stage:number,JONo:number,TestResult:string,QAResult:string)
  {
    this._ProjectLeaderService.testResultHistoryPopUp=false;
    if(Stage > 5)
    {
      this._ProjectLeaderService.testResultHistoryPopUp=true;
      this.activityId=ActivityId;
      this.stage=Stage;
      this.joNo=JONo;
      this.testResult=TestResult;
      this.qaResult=QAResult;
    }
  }
}
