import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Backloghs /*, WorkDetails, Worksheet*/ } from '../Models/Backlogs';
import { ROOT_URL } from '../Models/Config';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class BacklogsService {

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }
  DataList: Backloghs[];

  // Worksheetlist: Worksheet[];
  // WorkDetails: WorkDetails[]

  From: Date
  To: Date
  EmployeeId: number = 0

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }

  GetData(backlogDate): Observable<Backloghs[]> {
    return this.http.get<Backloghs[]>(ROOT_URL + 'Backlogs/getDataList?now=' + this.convert(new Date) + '&BacklogDate=' + this.convert(backlogDate))
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getTodayDelivery(nowDate): Observable<Backloghs[]> {
    return this.http.get<Backloghs[]>(ROOT_URL + 'Backlogs/getTodayDelivery?now=' + this.convert(new Date) + "&DeliverDate=" + this.convert(nowDate))
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

}
