<div class="container-fluid">
    <h2 class="heading_all">Customer Requirement</h2>
    <br>
    <div id="form-wrap">
        <form #CustomerRequirementForm="ngForm" (ngSubmit)="onSubmit(CustomerRequirementForm)">
            <div class="tab-content">
                <div id="sec1" class="container-fluid tab-pane active">
                    <div class="row">
                        <div class="col-md-4">
                            <div *ngIf=" this._CommonService.LoginUser == 'Provider'" class="form-group">
                                <label for="DealerId">Dealer</label>
                                <select class="form-control"
                                    [(ngModel)]="this._CustomerrequirementService.selectedRecord.DealerId"
                                    #DealerId="ngModel" name="DealerId" (change)="GetRefNo();DelaerChange()" required>
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.DealerName }}
                                    </option>
                                </select>

                            </div>
                            <br>
                            <div class="form-group row">
                                <label>Ref.No<span class="validation-error">*</span></label>
                                <input class="form-control" name="RefNo" #RefNo="ngModel" type="number"
                                    style="width: 30%;margin-left: 10px" autocomplete="off"
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.RefNo" placeholder=" Ref No"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(RefNo.invalid && RefNo.touched)||(RefNo.invalid && this.FormValidation)">
                                    This Field is Required.</div>

                                <i class="fas fa-search" style="font-size: 1.5rem;margin-top: 5px;margin-left: 5px;"
                                    (click)="SearchCustReq()" aria-hidden="true"></i>
                            </div>

                            <div class="form-group">
                                <label *ngIf="this._CommonService.LoginUser=='SupportEngineer'">SE<span class="validation-error">*</span></label>
                                <label *ngIf="this._CommonService.LoginUser=='Provider'">Employee</label>
                                <ng-select name="SEID" [disabled]="true" id="SEID" #SEID="ngModel" bindLabel="EmpName"
                                    bindValue="Id" required [items]="_CustomerrequirementService.allSE"
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.SEID">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(SEID.invalid && SEID.touched)||(SEID.invalid && this.FormValidation)">This Field is Required.</div>

                            </div>
                            <div class="form-group">
                                <label>Date<span class="validation-error">*</span></label>
                                <input class="form-control" placeholder="Date" name="Date" #Date="ngModel"
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.Date"
                                    style="color: black;text-align: left;" autocomplete="off" bsDatepicker
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                                <div class="validation-error"
                                    *ngIf="(Date.invalid && Date.touched)||(Date.invalid && this.FormValidation)">This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label>Customer<span class="validation-error">*</span></label>

                                <!-- <select class="form-control" name="CustomerID" #CustomerID="ngModel"
                                    (change)="onSelect(1);"
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.CustomerID" required>
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option *ngFor="let customer of _CustomerService.allCustomers | orderBy : 'Name'"
                                        [ngValue]="customer.Id">
                                        {{customer.Name }}
                                    </option>
                                </select> -->
                                <ng-select name="CustomerID" id="CustomerID" #CustomerID="ngModel" bindLabel="Name"
                                    bindValue="Id" (change)="onSelect(1);"
                                    [items]="_CustomerService.allCustomers | orderBy : 'Name'" required
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.CustomerID">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(CustomerID.invalid && CustomerID.touched)||(CustomerID.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label>Address</label>
                                <input class="form-control" name="CustAdr1" #CustAdr1="ngModel" autocomplete="off"
                                    [(ngModel)]=" this._CustomerrequirementService.selectedRecord.CustAdr1"
                                    placeholder=" Addr1" required>

                                <input class="form-control" name="CustAdr2" #CustAdr2="ngModel" autocomplete="off"
                                    [(ngModel)]=" this._CustomerrequirementService.selectedRecord.CustAdr2"
                                    placeholder=" Addr2" style="margin-top:3px;" required>

                                <input class="form-control" name="CustAdr3" #CustAdr3="ngModel" autocomplete="off"
                                    [(ngModel)]=" this._CustomerrequirementService.selectedRecord.CustAdr3"
                                    placeholder=" Addr3" style="margin-top:3px;"  required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="Time">Time</label>
                                <input class="form-control" name="Time" #Time="ngModel" autocomplete="off"
                                    [(ngModel)]="this._CustomerrequirementService.selectedRecord.Time" required
                                    type="time">
                            </div>
                            <div class="form-group">
                                <label for="ModeOfReg">Mode of Reg<span class="validation-error">*</span></label>

                                <select class="form-control" name="ModeOfReg" #ModeOfReg="ngModel"
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.ModeOfReg" required>
                                    <option [ngValue]="0" disabled>Select</option>
                                    <option [ngValue]="1">By Phone</option>
                                    <option [ngValue]="2">By Mail</option>
                                    <option [ngValue]="3">By Chat</option>
                                    <option [ngValue]="4" hidden>On Site</option>
                                    <option [ngValue]="5" hidden>Remote Section</option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(ModeOfReg.invalid && ModeOfReg.touched)||(ModeOfReg.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="BranchId">Branch<span class="validation-error">*</span></label>
                                <ng-select name="BranchId" id="BranchId" #BranchId="ngModel"
                                    [items]="this._BranchService.allBranchs" bindValue="Id" bindLabel="BranchName"
                                    (change)="onSelect(2);"
                                    [(ngModel)]="this._CustomerrequirementService.selectedRecord.BranchID">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(BranchId.invalid && BranchId.touched)||(BranchId.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="ProjectId">Project<span class="validation-error">*</span></label>
                                <ng-select name="ProjectId" id="ProjectId" #ProjectId="ngModel" bindLabel="Name"
                                    bindValue="Id" (change)="GetAllModules()" [items]="_projectService.allProjects"
                                    required [(ngModel)]="_CustomerrequirementService.selectedRecord.ProjectID">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(ProjectId.invalid && ProjectId.touched)||(ProjectId.invalid && this.FormValidation)">
                                    This Field is  Required.</div>
                            </div>
                            <div class="form-group">
                                <label>Modules</label>
                                <div class="col-md-8 moduleBox">
                                    <div
                                        *ngFor="let obj of _modulService.allModuleServiceReport | orderBy : 'ModuleName'">
                                        <input disabled type="checkbox" [checked]="true" name="obj.ModuleName"
                                            value="{{obj.Id}}">&nbsp;{{obj.ModuleName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Server</label>
                                <input class="form-control" name="Server" #Server="ngModel" autocomplete="off"
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.Server" disabled
                                    placeholder=" Server" required>

                                <label>Node</label>
                                <input class="form-control" name="Node" #Node="ngModel" autocomplete="off"
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.Node" disabled
                                    placeholder="Node" required>

                            </div>

                            <div class="form-group">
                                <label>Contract Date</label>

                                <input class="section3" placeholder="ContractDate" name="ContractDate"
                                    #ContractDate="ngModel" disabled
                                    [(ngModel)]="_CustomerrequirementService.selectedRecord.ContractDate"
                                    style="color: black; width: 110px; text-align: left;" autocomplete="off"
                                    bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">

                                <div class="col-md-4"
                                    *ngIf="_CustomerrequirementService.selectedRecord.ContractDate!=null">
                                    <label style="color:green" class="form-control-label"
                                        *ngIf="this.AMCRenewalStatus">ON
                                        AMC</label>
                                    <label style="color:red" class="form-control-label"
                                        *ngIf="!this.AMCRenewalStatus">AMC EXIPIRED</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label
                                    class="col-md-12 form-control-label">{{this._CustomerrequirementService.selectedRecord.ContractVsCurrentDate}}</label>
                            </div>
                            <div class="form-group">
                                <label>AMC UpTo</label>
                                <input class="form-control" name="AMCUPTo" #AMCUPTo="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="this._CustomerrequirementService.selectedRecord.AMCUPTo"
                                    placeholder="AMCUPTo" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                                    required>

                            </div>
                            <div class="form-group ">
                                <label>No Of Visits</label>
                                <input class="form-control" name="NoOfVisits" #NoOfVisits="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="this._CustomerrequirementService.selectedCustomerRequirement_Common.NoOfVisits"
                                    placeholder="No Of Visits" required>
                            </div>
                            <div class="form-group ">
                                <label>Total Requirements</label>
                                <input class="form-control" name="TotalRequirements" #TotalRequirements="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="this._CustomerrequirementService.selectedCustomerRequirement_Common.TotalRequirements"
                                    placeholder="Total Requirements">
                            </div><br>
                            <div class="form-group">
                                <label class="col-md-5 form-control-label"
                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit!=null">
                                    Last
                                    Visit:{{this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit|date}}
                                </label>

                                <label *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE!=0"
                                    class="col-md-2 form-control-label">
                                    SE:
                                </label>
                                <label *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE!=0"
                                    class="col-md-5 form-control-label">
                                    <select class="form-control" name="SE" #SE="ngModel" disabled
                                        [(ngModel)]="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE"
                                        required>
                                        <option [ngValue]="0" disabled>Select</option>
                                        <option
                                            *ngFor="let obj of this._CustomerrequirementService.allEmployees | orderBy : 'EmpName'"
                                            [ngValue]="obj.Id">
                                            {{obj.EmpName}}
                                        </option>
                                    </select>
                                </label>

                            </div>
                            <div class="form-group">
                                <label
                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate!=null"
                                    class="col-md-6 form-control-label">
                                    Script:{{this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate|date}}
                                </label>
                                <label class="col-md-6 form-control-label"
                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate!=null">
                                    EXC:
                                    {{this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate|date}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <hr> -->
                    <!-- <br> -->
                    <div style="text-align: center;" *ngIf="this._CustomerrequirementService.allCustomerReqHistory.length > 0">
                        <h6>
                            <ul>Work History</ul>
                        </h6>
                    </div>
                    <div class="row" *ngIf="this._CustomerrequirementService.allCustomerReqHistory.length > 0">
                        <div class="table-responsive">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">SlNo</th>
                                            <th scope="col">Ref No</th>
                                            <th scope="col">Module</th>
                                            <th scope="col">Particular</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Remarks</th>

                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        <tr
                                            *ngFor="let field of this._CustomerrequirementService.allCustomerReqHistory ; let i = index">
                                            <th style="background:white;color:black;" scope="row">{{i+1}}</th>
                                            <td>{{field.RefNo}}</td>
                                            <td>
                                                {{field.ModuleName}}
                                            </td>
                                            <td>
                                                {{field.ParticularName}}
                                            </td>

                                            <td>

                                                {{field.Description}}
                                            </td>
                                            <td>

                                                <span class="form-control-label" *ngFor="let obj of TypeList">
                                                    <p *ngIf="obj.Id==field.Type">
                                                        {{obj.Name}}
                                                    </p>
                                                </span>
                                            </td>
                                            <td>
                                                <span class="form-control-label" *ngFor="let obj of PriorityList">
                                                    <p *ngIf="obj.Id==field.Priority">
                                                        {{obj.Name}}
                                                    </p>
                                                </span>
                                            </td>
                                            <td>
                                                <span class="form-control-label" *ngFor="let obj of StatusFullList">
                                                    <p *ngIf="obj.Id==field.Status">
                                                        {{obj.Name}}
                                                    </p>
                                                </span>
                                            </td>
                                            <td>
                                                {{field.Remarks}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <hr><br> -->
                    <div style="text-align: center;">
                        <h6>
                            <ul>Customer Requirement</ul>
                        </h6>
                    </div>
                    <br>
                    <div class="row">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">SlNo</th>
                                            <th scope="col">Module</th>
                                            <th scope="col">Particular</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        <tr *ngFor="let field of this._CustomerrequirementService.allCustomerDtls;let i = index">
                                            <th style="background:white;color:black;" scope="row">{{i+1}}</th>
                                            <td [ngClass]="{'disabled':field.Status > 5}">
                                                <select style="width: 100%;" [(ngModel)]="field.ModuleId"
                                                    name="{{field.ModuleId}}" #{{field.ModuleId}}="ngModel"
                                                    class="form-control" required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of _modulService.allModuleServiceReport"
                                                        [ngValue]="obj.Id">
                                                        {{obj.ModuleName}}
                                                    </option>
                                                </select>

                                            </td>
                                            <td [ngClass]="{'disabled':field.Status > 5}">
                                                <select style="width: 60%;" class="form-control"
                                                    (change)="fillparticulars()" [(ngModel)]="field.Particular"
                                                    name="{{field.Particular}}" #{{field.Particular}}="ngModel"
                                                    required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <!-- <option
                                                            *ngFor="let obj of this._ParticularmasterService.allparticular | orderBy : 'Particulars'"
                                                            [ngValue]="obj.ID">
                                                            {{obj.Particulars}}
                                                        </option> -->
                                                    <ng-container
                                                        *ngFor="let obj of this._ParticularmasterService.allparticular | orderBy : 'Particulars'">
                                                        <option *ngIf="field.ModuleId==obj.ModuleID" [ngValue]="obj.ID">
                                                            {{obj.Particulars}}</option>
                                                    </ng-container>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="field.Description" name="{{field.Description}}"
                                                    #{{field.Description}}="ngModel" (click)="OpenDescPopup(field,0)"
                                                    (keydown)="OpenDescPopup(field,0);$event.stopPropagation();"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Description"
                                                    required>
                                            </td>
                                            <td [ngClass]="{'disabled':field.Status > 5}">
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="field.Type" name="{{field.Type}}"
                                                    #{{field.Type}}="ngModel" [ngModelOptions]="{standalone: true}"
                                                    required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of TypeList" [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td [ngClass]="{'disabled':field.Status > 5}">
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="field.Priority" name="{{field.Priority}}"
                                                    #{{field.Priority}}="ngModel" [ngModelOptions]="{standalone: true}"
                                                    required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of PriorityList" [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <div
                                                    *ngIf="field.Status==3||field.Status==4||field.Status==5||field.Status==6||field.Status==7||field.Status==8||field.Status==9">
                                                    <span class="form-control-label" *ngFor="let obj of StatusFullList">
                                                        <p *ngIf="obj.Id==field.Status">
                                                            {{obj.Name}}
                                                        </p>
                                                    </span>
                                                </div>
                                                <div *ngIf="field.Status==1||field.Status==2||field.Status==10">
                                                    <select style="width: 60%;" class="form-control"
                                                        [(ngModel)]="field.Status" name="{{field.Status}}"
                                                        #{{field.Status}}="ngModel"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [disabled]="field.Status==1||field.Status==2||field.Status==10"
                                                        required>
                                                        <option [ngValue]="0" disabled>Select</option>
                                                        <option *ngFor="let obj of StatusList" [ngValue]="obj.Id">
                                                            {{obj.Name}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="field.Remarks"
                                                    [disabled]="field.Status==3||field.Status==4||field.Status==5||field.Status==6||field.Status==7||field.Status==8||field.Status==9"
                                                    #{{field.Remarks}}="ngModel" name="{{field.Remarks}}"
                                                    [ngModelOptions]="{standalone: true}" autocomplete="off"
                                                    placeholder="Remarks" required>
                                            </td>
                                            <td><button type="button" [disabled]="field.Status > 5" (click)="deleteFieldValue(i)">-</button></td>
                                        </tr>

                                        <tr>
                                            <th style="background:white;color:black;" scope="row">
                                                {{this._CustomerrequirementService.RowCount + 1}}</th>
                                            <td>
                                                <select style="width: 100%;"
                                                    [(ngModel)]="this._CustomerrequirementService.newAttribute.ModuleId"
                                                    name="newAttributeModuleId" #newAttributeModuleId="ngModel"
                                                    (change)="fillparticulars()" class="form-control">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option
                                                        *ngFor="let obj of _modulService.allModuleServiceReport | orderBy : 'ModuleName'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.ModuleName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._CustomerrequirementService.newAttribute.Particular"
                                                    name="newAttributeParticular" #newAttributeParticular="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option
                                                        [hidden]="obj.ModuleID!=this._CustomerrequirementService.newAttribute.ModuleId"
                                                        *ngFor="let obj of  this._ParticularmasterService.particularList | orderBy : 'Particulars'"
                                                        [ngValue]="obj.ID">
                                                        {{obj.Particulars}}
                                                    </option>
                                                </select>
                                            </td>

                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="this._CustomerrequirementService.newAttribute.Description"
                                                    (click)="OpenDescPopup(this._CustomerrequirementService.newAttribute,1)"
                                                    (keydown)="OpenDescPopup(this._CustomerrequirementService.newAttribute,1);$event.stopPropagation();"
                                                    name="newAttributeDescription" #newAttributeDescription="ngModel"
                                                    placeholder="Description">
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._CustomerrequirementService.newAttribute.Type"
                                                    name="newAttributeType" #newAttributeType="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of TypeList | orderBy : 'Name' "
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._CustomerrequirementService.newAttribute.Priority"
                                                    name="newAttributePriority" #newAttributePriority="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of PriorityList  | orderBy : 'Name'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._CustomerrequirementService.newAttribute.Status"
                                                    name="newAttributeStatus" #newAttributeStatus="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of StatusList" [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="this._CustomerrequirementService.newAttribute.Remarks"
                                                    name="newAttributeRemarks" #newAttributeRemarks="ngModel"
                                                    placeholder="Remarks">
                                            </td>
                                            <td><button type="button" (click)="addFieldValue()">+</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <label style="color: #008080" for="select">Note<span class="validation-error">*</span></label>

                        <textarea class="form-control" name="Note" #Note="ngModel" autocomplete="off"
                            [(ngModel)]="_CustomerrequirementService.selectedRecord.Note" placeholder=" Notes"
                            required></textarea>
                        <div class="validation-error" *ngIf="Note.invalid && this.FormValidation">
                            This Field is Required.</div>
                    </div>
                    <br>
                    <div class="row">
                        <!-- <label style="color: #008080">Choose File</label>&nbsp; -->
                        <span style="width: 25%;" class="btn btn-default btn-file">Browse
                            <input type="file" name="customFile" #customFile id="customFile" multiple
                                (change)="handleFileInput($event)">
                        </span>
                    </div>
                    <br>
                    <div class="row"
                        *ngIf="this.fileToUpload.length>0||this._CustomerrequirementService.selectedRecord.CusAttachementList.length>0">
                        <div class="col-md-6">
                            <div class="tWrap">
                                <div class="tWrap__head">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">*</th>
                                                <th scope="col">FileName</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let obj of  this.fileToUpload; let i=index">
                                                <th style="background:white;color:black;font-weight: bold;" scope="row">
                                                    <i class="fas fa-chevron-circle-right"></i>
                                                </th>
                                                <td> {{obj.name}} </td>
                                                <td><button type="button" (click)="RemoveImage(obj)">-</button> </td>
                                            </tr>
                                            <tr
                                                *ngFor="let obj of this._CustomerrequirementService.selectedRecord.CusAttachementList; let i=index">
                                                <th style="background:white;color:black;" scope="row"><i
                                                        class="fas fa-chevron-circle-right"></i></th>
                                                <td style="text-decoration: underline; cursor: pointer;"
                                                    (click)="download(obj.FileName)"> {{obj.FileName}} </td>
                                                <td><button type="button" (click)="RemovePreviousImage(obj)">-</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">

                        </div>
                    </div>
                </div>
                <br>
                <div class="row">

                    <div class="col-md-2">
                        <!-- *ngIf="SupportEngId == _CustomerrequirementService.selectedRecord.SEID" -->
                        <button type="submit" class="sim-button button1" [disabled]="this.SubmitButtonDisable"
                            style="width: 99%; margin-top: 0px;">
                            <span>Submit</span>
                            <!-- <span>Update</span>
                            *ngIf="_CustomerrequirementService.FormUpdation" -->
                        </button>
                    </div>
                    <div class="col-md-3"
                        *ngIf="userService.roleMatch(['Project Leader','Developers & Designers','Testers','Project Manager'])">
                        <button type="button" class="sim-button button1"
                            (click)="SendToWorkOpenPopUp(CustomerRequirementForm,1)"
                            style="width: 99%; margin-top: 0px;">
                            Send to Work Status
                        </button>
                    </div>
                    <div class="col-md-3"
                        *ngIf="userService.roleMatch(['Project Leader','Developers & Designers','Testers','Project Manager'])">
                        <button type="button" class="sim-button button1"
                            (click)="SendToWorkOpenPopUp(CustomerRequirementForm,2)"
                            style="width: 99%; margin-top: 0px;">
                            Send to Work Allotment
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button type="button"  class=" sim-button button1"  (click)="onSubmitToWorkDirect(CustomerRequirementForm,3)"
                            style="width: 99%; margin-top: 0px;">
                            Emergency 
                        </button>
                    </div>
                    <div class="col-md-2">
                        <button type="button" (click)="resetForm(CustomerRequirementForm)" class=" sim-button button2"
                            style="width: 99%; margin-top: 0px;">
                            Reset
                        </button>
                    </div>
                </div>

                <!--  tEST result KEYWORD POP UP -->
                <form #Description="ngForm" *ngIf="OpenDescriptionPopup">
                    <div class="popup-container">
                        <div class="popup HoldingReason" (click)="OpenDescriptionPopup=false;">

                            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                                <div class="title">
                                    <p>Description</p> <label for="test-results"> <i class="fas fa-times"
                                            (click)="OpenDescriptionPopup=false;"></i> </label>
                                </div>
                                <div style="margin-top: 25px;">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <textarea class="form-control" autofocus name="DescriptionEdit"
                                                #DescriptionEdit="ngModel" style="height: 350px;" id="DescriptionEdit"
                                                [(ngModel)]="_CustomerrequirementService.DescriptionEdit"
                                                autocomplete="off" placeholder=" Description" required>
                                            </textarea>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-4">
                                        </div>
                                        <div class="col-md-4">
                                            <button type="button" class="sim-button button1" (click)="SetDescription()"
                                                style="width: 100%;">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </form>
    </div>

</div>
<app-loading *ngIf="SubmitButtonDisable"></app-loading>
<form #Description="ngForm">
    <div class="popup-container" *ngIf="openPopup>0">
        <div class="popup HoldingReason" (click)="closePopUp()">
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                <div class="title">
                    <p>Send to Work Status</p> <label for="test-results"> <i class="fas fa-times"
                            (click)="closePopUp()"></i> </label>
                </div>
                <div style="margin-top: 25px;">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Expected Date <span class="validation-error">*</span></label>
                                <input class="form-control" placeholder="Expected Date" id="expdate" name="expdate"
                                    #expdate="ngModel" required
                                    [(ngModel)]="this._CustomerrequirementService.selectedRecord.ExpectedDate"
                                    style="color: black;  width: 100%;  text-align: left;" autocomplete="off"
                                    bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="openPopup==2">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Planned Delivery Date <span class="validation-error">*</span></label>
                                <input class="form-control" placeholder="ContractDate" id="pdate" name="pdate"
                                    #pdate="ngModel" required
                                    [(ngModel)]="this._CustomerrequirementService.selectedRecord.PlannedDeliveryDate"
                                    style="color: black;  width: 100%;  text-align: left;" autocomplete="off"
                                    bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 5px;">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Remarks<span class="validation-error">*</span></label>
                                <textarea class="form-control" autofocus rows="5" autocomplete="off" id="Remarknote"
                                    name="Remarknote" #Remarknote="ngModel"
                                    [(ngModel)]="this._CustomerrequirementService.selectedRecord.Remark"
                                    placeholder=" Enter your remarks" required></textarea>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-4">
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="sim-button button1"
                                (click)="onSubmitToWorkDirect(CustomerRequirementForm,0)"
                                [disabled]="this.SubmitButtonDisable" style="width: 100%;">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>