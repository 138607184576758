import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AmcRenewalService } from 'src/app/Services/amc-renewal.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { RouteService } from 'src/app/Services/route.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { BussinessTypeService } from 'src/app/Services/bussiness-type.service';
import { BPEService } from 'src/app/Services/bpe.service';
import { ModuleService } from 'src/app/Services/module.service';
import { customer } from 'src/app/Models/customer.model';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { SoftwareRegistrationService } from 'src/app/Services/software-registration.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-amc-renewal-form',
  templateUrl: './amc-renewal-form.component.html',
  styleUrls: ['./amc-renewal-form.component.css']
})
export class AmcRenewalFormComponent implements OnInit {

  constructor(public _AmcRenewalService: AmcRenewalService,
    public _swRegService: SoftwareRegistrationService,
    private toastr: ToastrService,
    public _countryService: CountryService,
    public _dealrService: DealerService,
    public _custService: CustomerService,
    public _brnchService: BranchService,
    public _projectService: ProjectService,
    public _businessTypeService: BussinessTypeService,
    public _CommonService: CommonService,
    public _bpeService: BPEService,
    public _nodeRegService: NodeRegistrationService,
    public _ModuleService: ModuleService) { }

  Country_Id: any;
  public errorMsg;
  FileInvalidMessage = '';
  FileisValid = false;
  fileToUpload: File = null;
  selectedCustomer: customer;
  allCustomers1: customer[];
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  ngOnInit(): void {
    this._AmcRenewalService.moduleList = []
    this._brnchService.allBranchs = []
    this._AmcRenewalService.formHideShow = 0;
    this._AmcRenewalService.formHidePassword = false;
    this._countryService.getCountry()
      .subscribe(data => this._countryService.allCountries = data,
        error => {
          this.errorMsg = error;
        });

    this.LoadDealerByUserID();
    this.LoadCustomers();
    this._businessTypeService.getBusinessType()
      .subscribe(data => this._businessTypeService.allBusinessTypes = data,
        error => {
          this.errorMsg = error;
        });
    this._bpeService.getBusinessPromotionExecutive('DEALERID')
      .subscribe(data => this._bpeService.allBusinessPromotionExecutives = data,
        error => {
          this.errorMsg = error;
        });

    this.GetAllModules();
    this.resetForm();
  }

  onChangeModules(event, cat: any) {
    if (event.target.checked)
      this._AmcRenewalService.moduleList.push(cat.Id)
    else {
      const index = this._AmcRenewalService.moduleList.indexOf(cat.Id);
      if (index > -1) {
        this._AmcRenewalService.moduleList.splice(index, 1);
      }
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }

  LoadCustomers() {
    this._custService.FetchAllCustomer('CUSTOMERBYSWREG')
      .subscribe(data => {
        console.log('customerbbyswreg', data)
        this._custService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  GetAllModules() {
    this._ModuleService.getModuleCheckBox()
      .subscribe(data => {
        this._ModuleService.allModulesCheckBox = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  LoadBranch() {
    console.log(' this._AmcRenewalService.selectedAMCRenewal.CustomerId:', this._AmcRenewalService.selectedAMCRenewal.CustomerId)
    let CustomerId = this._AmcRenewalService.selectedAMCRenewal.CustomerId == null ? 0 : this._AmcRenewalService.selectedAMCRenewal.CustomerId
    this._brnchService.Fetch_BranchById(CustomerId, 'CUSTOMERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
        this._AmcRenewalService.selectedAMCRenewal.BranchId = null
        this._AmcRenewalService.selectedAMCRenewal.ProjectId = null
      },
        error => {
          this.errorMsg = error;
        });
  }

  onSelect(CustId) {
    this._AmcRenewalService.selectedAMCRenewal.CustomerId = CustId;
    console.log('this._custService.allCustomers', this._custService.allCustomers)
    var custDtls = this._custService.allCustomers.filter(e => e.Id == this._AmcRenewalService.selectedAMCRenewal.CustomerId)[0];
    console.log('custDtls', custDtls)
    this._AmcRenewalService.selectedAMCRenewal.Addr1 = custDtls.Addr1;
    this._AmcRenewalService.selectedAMCRenewal.Addr2 = custDtls.Addr2;
    this._AmcRenewalService.selectedAMCRenewal.Addr3 = custDtls.Addr3;
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._AmcRenewalService.selectedAMCRenewal = {
      AMCRenewalDate: null,
      Addr1: '',
      Addr2: '',
      Addr3: '',
      AmcId: null,
      BPEId: null,
      BranchId: null,
      BrchAddr1: '',
      BrchAddr2: '',
      BrchAddr3: '',
      ContractDate: null,
      CountryId: null,
      CustomerId: null,
      CustomerName: '',
      Date: null,
      DealerId: null,
      ExpiredOn: null,
      FileName: '',
      FreeSupport: 0,
      Id: 0,
      Nodes: null,
      ProjectId: null,
      RefNo: null,
      Server: null,
      Supporters: null,
      TypeOfBusinessId: null
    }
    this._AmcRenewalService.moduleList = []
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._AmcRenewalService.formHideShow = 0;
  }



  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid) {
      this.isSubmit = true
      this._AmcRenewalService.selectedAMCRenewal.Date = this.convert(this._AmcRenewalService.selectedAMCRenewal.Date)
      this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate = this.convert(this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate)
      this._AmcRenewalService.selectedAMCRenewal.ExpiredOn = this.convert(this._AmcRenewalService.selectedAMCRenewal.ExpiredOn)
      this._CommonService.isLoading = true
      this._AmcRenewalService.postAMCRenewal(form.value)
        .subscribe(data => {
          this._CommonService.isLoading = false

          this.isSubmit = false
          console.log(data)
          this.GetAllAMCRenewal();
          this.backList()
          if (data.Status == 0)
            this.toastr.success(data.Message, 'AmcRenewal');
          else
            this.toastr.warning(data.Message, 'AmcRenewal');
        },
          error => {
            this._CommonService.isLoading = false
            this.errorMsg = error;
            this.toastr.error('New Record Added Failure', 'AmcRenewal');
          })
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'AmcRenewal');
    }
  }

  GetAllAMCRenewal() {
    this._AmcRenewalService.getAMCRenewal()
      .subscribe(data => {
        this._AmcRenewalService.allAMCRenewals = data
      },
        error => {
          this.errorMsg = error;
        })
  }


  //File Validation
  CheckFileValid(file) {
    if (this.fileToUpload != null) {

      if ((file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/gif') && file.size <= (1600 * 1200)) {
        this.FileInvalidMessage = "";
        this.FileisValid = true;
      }
      else {
        this.FileisValid = false;
        this.FileInvalidMessage = "Only JPEG/PNG/Gif Image can be upload )";
      }
    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this._AmcRenewalService.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  LoadDealerByUserID() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        this._dealrService.allDealers = data
        this._AmcRenewalService.selectedAMCRenewal.CountryId = data[0].CountryId;
        this._AmcRenewalService.selectedAMCRenewal.DealerId = data[0].Id;

      })
  }

  LoadSoftRegDtls(form: NgForm) {

    if (form.value.CustomerId != null && form.value.BranchId != null && form.value.ProjectId != null)
      this._nodeRegService.LoadSoftRegDtls(form.value.CustomerId, form.value.BranchId, form.value.ProjectId).
        subscribe(obj => {
          console.log('LoadSoftRegDtl', obj)
          if (obj.Id > 0) {
            this._AmcRenewalService.selectedAMCRenewal = Object.assign({}, obj)
            this._AmcRenewalService.selectedAMCRenewal.Date = obj.Date == null ? null : new Date(obj.Date);
            this._AmcRenewalService.selectedAMCRenewal.ExpiredOn = obj.ExpiredOn == null ? null : new Date(obj.ExpiredOn);
            this.onSelect(this._AmcRenewalService.selectedAMCRenewal.CustomerId)
            this._swRegService.getAllModuleByRegId(this._AmcRenewalService.selectedAMCRenewal.Id).subscribe(obj => {
              this._ModuleService.allModulesCheckBox = []
              this._ModuleService.allModulesCheckBox = obj;
              this._ModuleService.allModulesCheckBox.forEach(obj => {
                if (obj.Selected)
                  this._swRegService.moduleList.push(obj.Id)
              })
            })
            this._AmcRenewalService.selectedAMCRenewal.AmcId = null
          }
          else {
            this._ModuleService.allModulesCheckBox.forEach(obj => {
              obj.Selected = false
            })
          }
        })

  }

  LoadProject(BranchId) {
    this._AmcRenewalService.selectedAMCRenewal.ProjectId = null
    this._AmcRenewalService.selectedAMCRenewal.BranchId = BranchId;
    this._projectService.fetchProject('BYBRANCHID', this._AmcRenewalService.selectedAMCRenewal.BranchId)
      .subscribe(data =>
        this._projectService.allProjects = data,
        error => {
          this.errorMsg = error;
        });
  }


  datecal() {
    if (this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate != null && this._AmcRenewalService.selectedAMCRenewal.Supporters != null) {
      let tempdate: Date = this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate
      this._AmcRenewalService.selectedAMCRenewal.ExpiredOn = this.addMonths(new Date(tempdate), this._AmcRenewalService.selectedAMCRenewal.Supporters);
    }
  }

  addMonths(date: Date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + parseInt(months));
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

}

