<div class="container-fluid">
    <!--provides a wrapper for testing the scroll-->
    <h2 class="heading_all">Work Allotment</h2>
    <div class="row">
        <div class="col-md-3">
            <input type="radio" [(ngModel)]="filterRegRepDate" [value]="0" name="RegRep" id="reg" #reg="ngModel"
                (change)="CheckDateFilter()"> <label for="reg">Reg. Date</label>

            <input type="radio" [(ngModel)]="filterRegRepDate" [value]="1" id="Rep" #Rep="ngModel" name="Rep"
                (change)="CheckDateFilter()"> <label for="Rep">Rep. Date</label>
        </div>
        <div class="col-md-2">
            From
            <input bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" style="width:125px;" autocomplete="off" id="fromDate"
                name="fromDate" #fromDate="ngModel" [(ngModel)]="beginDate" (ngModelChange)="CheckDateFilter()">
        </div>
        <div class="col-md-2">
            To <input bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" style="width:125px;" autocomplete="off" id="toDate"
                name="toDate" #toDate="ngModel" [(ngModel)]="ToDate" (ngModelChange)="CheckDateFilter()">
        </div>
        <div class="col-md-2">
            <select id="CustomerFilter" name="CustomerFilter" #CustomerFilter="ngModel"
                style="width: 250px;padding: 4px;"
                [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.CustomerID" (ngModelChange)="loadJobsBatch()">
                <option [value]="''"> SELECT Customer </option>
                <option *ngFor="let field of customerList" [value]="field.Id"> {{field.Name}} </option>
            </select>
        </div>
        <div class="col-md-2">
            <select style="width: 250px;padding: 4px;" name="SelectedBatch" id="SelectedBatch" #SelectedBatch="ngModel" [(ngModel)]="this.selectedBatchId" (ngModelChange)="this.LoadPendingWorks(0)">
                <option [value]="0">SELECT Batch</option>
                <option *ngFor="let batch of this._WorkAllocationService.jobBatch" [value]="batch.Id">{{batch.Name}}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" style="text-align:center;">
            <select name="developerTesterFilter" style="width: 250px;padding: 4px;float:left;" #developerTesterFilter="ngModel" 
            [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.EmployeeId" (ngModelChange)="LoadPendingWorks(_ProjectLeaderService.PLWorkDetailsFilter.EmployeeId)">
                <option [value]="''">SELECT Programmers & Tester</option>
                <option *ngFor="let obj of this._EmployeeService.allPrgrmsTester" [value]="obj.Id">{{obj.Name}}</option>
            </select>
            <span class="stageFilter">
                <input type="radio" id="pendingCount" (change)="ClearChkSelect()"
                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Stage" [value]="5" name="Stagestatus">
                <label class="pendingLable" for="pendingCount">Pending ({{PendingCount}})</label>
                <input type="radio" name="Stagestatus" (change)="ClearChkSelect()"
                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Stage" [value]="6" id="developent">
                <label class="development" for="developent">Development ({{DevelopmentCount}})</label>
                <input type="radio" name="Stagestatus" (change)="ClearChkSelect()"
                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Stage" [value]="7" id="testing">
                <label class="testing" for="testing">Testing ({{TestingCount}})</label>
                <input type="radio" name="Stagestatus" (change)="ClearChkSelect()"
                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Stage" [value]="8" id="succed">
                <label class="succed" for="succed">Succeed ({{SucceedCount}})</label>
                <input type="radio" name="Stagestatus" (change)="ClearChkSelect()"
                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Stage" [value]="''" id="all">
                <label class="All" for="all">All ({{AllCount}})</label>
            </span>
        </div>
    </div>
    <div class="tWrap">
        <div class="tWrap__head">
            <table>
                <thead>
                    <tr>
                        <th style="width: 50px;">
                            <span>
                                <input type="checkbox" title="Select ALL" name="chkall" id="chkall" #chkall="ngModel"
                                    [(ngModel)]="chkSelectAll" (change)="SelectAllCheckBox()">
                            </span>
                            <span>
                                <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                    <i *ngIf="!isFiltering" class="fas fa-filter"></i>
                                    <i *ngIf="isFiltering" class="fas fa-times"></i>
                                </a>
                            </span>
                        </th>
                        <th style="width: 52px;">SN.NO </th>
                        <th scope="col" style="width:70px;">Action</th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('ActivityID')">
                                ID
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='ActivityID' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='ActivityID'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="regNoFilter" name="regNoFilter" #regNoFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.ActivityID" placeholder="ID">
                            </div>

                        </th>
                        <th style="width:190px;">
                            <div *ngIf="!isFiltering" (click)="sort('DealerName')">
                                DEALER
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='DealerName' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='DealerName'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="dealerFilter" name="dealerFilter" #dealerFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.DealerId">
                                    <option [value]="''">SELECT DEALER</option>
                                    <option *ngFor="let field of this._dealrService.allDealers" [value]="field.Id">
                                        {{field.DealerName}} </option>
                                </select>
                            </div>

                        </th>

                        <!-- <th title="Reported Person">Rep.Person
                            <div *ngIf="!isFiltering" (click)="sort('ReportedPerson')">
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='ReportedPerson' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='ReportedPerson'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('RegNo')">
                                RegNo
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='RegNo' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='RegNo'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="regNoFilter" name="regNoFilter" #regNoFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.RegNo" placeholder="REG.NO">
                            </div>
                        </th> -->
                        <!-- <th>
                            <div *ngIf="!isFiltering" (click)="sort('VtypeText')">
                                VTYPE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='VtypeText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='VtypeText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="vtypefilter" name="vtypefilter" #vtypefilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Vtype">
                                    <option [value]="''">SELECT TYPE</option>
                                    <option [value]="0">Customer Requirement</option>
                                    <option [value]="1">Service Report</option>
                                </select>
                            </div>
                        </th> -->
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('PDate')">
                                P_D_Date
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='PDate' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='PDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="pDateFilter" name="pDateFilter" #pDateFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.PDate" placeholder="Planned Delivery Date">
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('EstDate')">
                                Est_C_Date
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='EstDate' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='RegNo'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="estDateFilter" name="estDateFilter" #estDateFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.EstDate" placeholder="Estimated Date">
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('RepDate')">
                                REP.DATE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='RepDate' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='RepDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="repDateFilter" name="repDateFilter" #repDateFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.RepDate"
                                    placeholder="REP.DATE">
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('StageText')">
                                STAGE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='StageText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='StageText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="stageFilter" name="stageFilter" #stageFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Stage">
                                    <option [value]="''">SELECT STAGE</option>
                                    <option *ngFor="let field of this.StageFullList" [value]="field.Id">
                                        {{field.Name}}</option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('StatusText')">
                                STATUS
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='StatusText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='StatusText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="statusFilter" name="statusFilter" #statusFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Status">
                                    <option [value]="''">SELECT STATUS</option>
                                    <option *ngFor="let field of this.StatusFullList " [value]="field.Id">
                                        {{field.Name}}</option>
                                </select>
                            </div>
                        </th>
                        <th title="TEST RESULT">
                            <div *ngIf="!isFiltering" (click)="sort('TestResultText')">
                                TEST RESULT
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='TestResultText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='TestResultText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="testresultFilter" name="testresultFilter" #testresultFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.TestResultText"
                                    placeholder="TEST RESULT">
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('TypeText')">
                                TYPE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='TypeText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='TypeText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="typeFilter" name="typeFilter" #typeFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Type">
                                    <option [value]="''">SELECT TYPE</option>
                                    <option *ngFor="let field of TypeList" [value]="field.Id"> {{field.Name}}</option>
                                </select>

                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('PriorityText')">
                                PRIORITY
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='PriorityText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='PriorityText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="priortyFilter" name="priortyFilter" #priortyFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.PriorityId">
                                    <option [value]="''">SELECT PRIORITY</option>
                                    <option *ngFor="let field of PriorityList" [value]="field.Id"> {{field.Name}}
                                    </option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('ModuleText')">
                                MODULE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='ModuleText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='ModuleText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="moduleFilter" name="moduleFilter" #moduleFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.ModuleId">
                                    <option [value]="''">SELECT MODULE</option>
                                    <option *ngFor="let field of this._modulService.allModules" [value]="field.Id">
                                        {{field.ModuleName}}
                                    </option>
                                </select>
                            </div>
                        </th>
                        <th title="PARTICULARS">
                            <div *ngIf="!isFiltering" (click)="sort('ParticularText')">
                                PARTICULARS
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='ParticularText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='ParticularText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="particularFilter" name="particularFilter" #particularFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.ParticularId">
                                    <option [value]="''">SELECT PARTICULAR</option>
                                    <option *ngFor="let field of this._ParticularmasterService.allparticular"
                                        [value]="field.ID">
                                        {{field.Particulars}}</option>
                                </select>
                            </div>
                        </th>
                        <th title="EST.HOURS">EST.HOURS</th>
                        <!-- <th>
                            <div *ngIf="!isFiltering" (click)="sort('JoNo')">
                                JOBNO
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='JoNo' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='JoNo'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="jobnoFilter" name="jobnoFilter" #jobnoFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.JoNo" placeholder="JOBNO">
                            </div>
                        </th> -->
                         <th>
                            <div *ngIf="!isFiltering" (click)="sort('Batch')">
                                Batch
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='Batch' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='Batch'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="batchFilter" name="batchFilter" #jobnoFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Batch" placeholder="Batch">
                            </div>
                        </th>
                        <th style="width:90px;">VIEW/DELETE</th>
                    </tr>
                </thead>
            </table>
        </div>
        <!--/.tWrap__head-->
        <div class="tWrap__body">
            <table>
                <tbody>
                    <ng-container *ngFor="let obj of  this._ProjectLeaderService.PLWorkDetailsHdrList| gridOperations  :null: 
                    {DealerId:_ProjectLeaderService.PLWorkDetailsFilter.DealerId,RegNo:_ProjectLeaderService.PLWorkDetailsFilter.RegNo,
                    Vtype:_ProjectLeaderService.PLWorkDetailsFilter.Vtype,RepDate:_ProjectLeaderService.PLWorkDetailsFilter.RepDate,
                    Stage:_ProjectLeaderService.PLWorkDetailsFilter.Stage,Status:_ProjectLeaderService.PLWorkDetailsFilter.Status,
                    TestResultText:_ProjectLeaderService.PLWorkDetailsFilter.TestResultText,Type:_ProjectLeaderService.PLWorkDetailsFilter.Type,
                    PriorityId:_ProjectLeaderService.PLWorkDetailsFilter.PriorityId,ModuleId:_ProjectLeaderService.PLWorkDetailsFilter.ModuleId,
                    ParticularId:_ProjectLeaderService.PLWorkDetailsFilter.ParticularId, JoNo:_ProjectLeaderService.PLWorkDetailsFilter.JoNo,
                    CustomerID:_ProjectLeaderService.PLWorkDetailsFilter.CustomerID,ActivityID:_ProjectLeaderService.PLWorkDetailsFilter.ActivityID,
                    EmployeeId:this._ProjectLeaderService.PLWorkDetailsFilter.EmployeeId,Batch:this._ProjectLeaderService.PLWorkDetailsFilter.Batch,
                    PDate:this._ProjectLeaderService.PLWorkDetailsFilter.PDate,EstDate:this._ProjectLeaderService.PLWorkDetailsFilter.EstDate}:
                    {Column:_ProjectLeaderService.orderByColName,Dir:_ProjectLeaderService.orderByDir};let i =index">

                        <!-- *ngIf="obj.ActionID!=4" -->
                        <tr
                            [ngClass]="{'finishedWork':obj.Status==2,'QaFinished':obj.Status==6 && obj.TestResultText=='Succeed','failed':obj.TestResultText=='Failure',
                                        'pendingBG':obj.Stage==5,'developmentBG':obj.Stage==6,'testingBG':obj.Stage==7,'succedBG':obj.Stage==8}">
                            <!-- style="pointer-events: none;  (click)="obj.CheckStatus=!obj.CheckStatus;"" -->
                            <td style="width: 50px;">
                                <!-- <span *ngIf="(obj.Status==0 || obj.Status==2 || obj.Status==4  || obj.Status==6)&& ((this.SelectedJobNo==obj.JoNo  ) || (this.SelectedJobNo==0  ) || (obj.JoNo==0  )) "> -->
                                <span
                                    *ngIf="((obj.Status==0  && obj.Stage!=6 && obj.Stage!=7 && obj.Stage !=8) || obj.Status==2 || obj.Status==4  || obj.Status==6 || obj.Status==7 || (obj.Status==5 && (obj.TestResultText=='Failure'||obj.TestResultText=='Retest'||obj.TestResultText=='Suggestion' ) ))  && ((obj.JoNo > 0  ) || (this.SelectedJobNo==0  ) || (obj.JoNo==0  ))">
                                    <input type="checkbox"  [(ngModel)]="obj.CheckStatus" name="chk"
                                        [disabled]="obj.ActionID !=1" (change)="SelectCheckBox(obj)">
                                </span>
                            </td>
                            <td style="width: 52px;"><span *ngIf="SelectedIndex==obj.ActivityID"> <i
                                        class='fas fa-chevron-right' style='font-size:20px'></i>
                                </span>{{i+1}}</td>
                            <td style="width:70px;">
                                <select name="ActionType" (ngModelChange)="changeActionID(obj,$event)"
                                    [disabled]="obj.Stage!=5" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="obj.ActionID" #ActionType="ngModel" class="form-control combo_body">
                                    <!-- (change)="changeActionID($event,obj)" -->
                                    <option [value]="0" disabled>Select Action</option>
                                    <option *ngFor="let obj of  ActionList"  [ngValue]="obj.Id"> {{obj.Name}}
                                    </option>
                                </select>
                            </td>
                            <td style="text-decoration: underline;cursor: pointer;" [title]="obj.ActivityID" (click)="openActivityPopUp(i,obj)"> {{obj.ActivityID}} </td>
                            <td style="width:190px;" [title]="obj.DealerName"> {{obj.DealerName}} </td>
                            <!-- <td [title]="obj.ReportedPerson">{{obj.ReportedPerson}}</td> -->
                            <!-- <td [title]="obj.RegNo">{{obj.RegNo}}</td> -->
                            <!-- <td [title]="obj.VtypeText"> {{obj.VtypeText}} </td> -->
                            <td [ngClass]="{'overdueBG':obj.Stage!=5 && this.currentDate>obj.PDate}">{{obj.PDate}}</td>
                            <td [ngClass]="{'overdueBG':obj.Stage!=5 && obj.Stage!=8 && obj.Status!=2 && obj.Status!=5 && this.currentDate>obj.EstDate}">{{obj.EstDate}}</td>
                            <td [title]="obj.RepDate">{{obj.RepDate }}</td>
                            <td [title]="obj.StageText" (click)="getWorkStatus(obj)"
                                style="cursor: pointer;text-decoration: underline;"> {{obj.StageText}} </td>
                            <td [title]="obj.StatusText"> {{obj.StatusText}} </td>
                            <td style="cursor: pointer;text-decoration: underline;" [title]="obj.TestResultText" (click)="sendTestResultData(obj.ActivityID,obj.Stage,obj.JoNo,obj.TestResultText,obj.StatusText)">{{obj.TestResultText}}</td>
                            <td [title]="obj.TypeText"> {{obj.TypeText}} </td>
                            <td [title]="obj.PriorityText"> {{obj.PriorityText}} </td>
                            <td [title]="obj.ModuleText"> {{obj.ModuleText}} </td>
                            <td [title]="obj.ParticularText"> {{obj.ParticularText}} </td>
                            <td [title]="obj.EstHrs" [title]="obj.EstHrs+' Hr '+obj.EstMin+' Min'">
                                <input name="esthr" style="width: 50%;" #esthr="ngModel" autocomplete="off"
                                    *ngIf="obj.CheckStatus" type="text" (keypress)="isNumber($event)"
                                    [(ngModel)]="obj.EstHrs" placeholder="Hrs">

                                <input name="estmin" style="width: 50%;" autocomplete="off" *ngIf="obj.CheckStatus"
                                    type="text" (keypress)="isNumber($event)" placeholder="Min" #EstMin="ngModel"
                                    [(ngModel)]="obj.EstMin">
                                <span *ngIf="!obj.CheckStatus">
                                    <span>
                                        {{obj.EstHrs}} Hr {{obj.EstMin}} Min
                                    </span>
                                </span>
                            </td>
                            <td title="{{obj.BatchDetails}}" style="cursor:pointer;">{{obj.Batch}}</td>
                            <!-- <td [title]="obj.JoNo" style="text-decoration: underline;cursor: pointer;"
                                (click)="openActivityPopUp(i,obj)">{{obj.JoNo}}
                            </td> -->
                            <td style="width:90px;">
                                <i class="fas fa-eye" title="Details" (click)="getPendingWorksDet(obj,i)" style="font-size:24px"></i>
                                <i class="fas fa-pause-circle" title="Hold" (click)="getHoldingDetails(obj)" style="font-size:24px"></i>
                                <i *ngIf="obj.IsDeletable" class="fas fa-trash-alt" title="Delete" (click)="deleteJoNo(obj)" style="font-size:24px;color: red;cursor: pointer;"></i>
                            </td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td colspan="4"
                            *ngIf="(_ProjectLeaderService.PLWorkDetailsHdrList?.length<=0 )  || _CommonService.filterdItemCount<1">
                            No Records To Display.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br>
    <div class="tWrap">
        <div class="tWrap__head">
            <table>
                <thead>
                    <tr>
                        <th style="width: 200px;">DEALER</th>
                        <th>CUSTOMER</th>
                        <th>PROJECT</th>
                        <th>Rep.Person</th>
                        <th>RegNo</th>
                        <th>VType</th>
                        <th>ATT.SE</th>
                        <th>EXP.DATE</th>
                        <th>Planned Delivery Date</th>
                        <th>Work Sorting Remark</th>
                    </tr>
                </thead>
            </table>
        </div>
        <!--/.tWrap__head-->
        <div class="tWrap__body">
            <table>
                <tbody>
                    <tr *ngFor="let obj of  this._ProjectLeaderService.PLWorkDetailsDetList ;let i =index">
                        <td style="width: 200px;">
                            <span class="form-control-label" *ngFor="let field of this._dealrService.allDealers">
                                <p *ngIf="field.Id==obj.DealerId">
                                    {{field.DealerName}}
                                </p>
                            </span>
                        </td>
                        <td>
                            <span class="form-control-label" *ngFor="let field of customerList">
                                <p *ngIf="field.Id==obj.CustomerId">
                                    {{field.Name}}
                                </p>
                            </span>
                        </td>
                        <td>
                            <span class="form-control-label" *ngFor="let field of this._projectService.allProjects">
                                <p *ngIf="field.Id==obj.ProjectId">
                                    {{field.Name}}
                                </p>
                            </span>
                        </td>
                        <td>{{obj.ReportedPerson}}</td>
                        <td>{{this.RegNo}}</td>
                        <td>{{obj.VtypeText}}</td>
                        <td>
                            <span class="form-control-label"
                                *ngFor="let field of this._CustomerrequirementService.allSE">
                                <p *ngIf="field.Id==obj.AttSE">
                                    {{field.EmpName}}
                                </p>
                            </span>
                        </td>
                        <td>{{obj.ExpDate}}</td>
                        <td>{{obj.PlannedDelDate}}</td>
                        <td style="white-space: normal;">{{obj.Remark }}</td>

                    </tr>
                    <tr *ngIf="this._ProjectLeaderService.PLWorkDetailsDetList?.length<=0">
                        <td colspan="5">
                            No Records To Display.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--/.tWrap__body-->
    </div>
</div>
<!--/.container-->

<div class="ui-input-container">
    <div class="conatiner-fluid">
        <div class="row">
            <div class="col-md-6">
                <label class="ui-form-input-container" (click)="this.DisplayWorkStatus = 7;">
                    <textarea class="ui-form-input" [value]="Description" disabled></textarea>
                    <span class="form-input-label">Description</span>
                </label>
                <label class="ui-form-input-container">
                    <textarea class="ui-form-input" [value]="Remark" disabled></textarea>
                    <span class="form-input-label">Activity Remark</span>
                </label>
            </div>
            <div class="col-md-6">
                <div class="tWrap" style="height: auto; margin-bottom: 20px;">
                    <div class="tWrap__head">
                        <table>
                            <thead>
                                <tr>
                                    <th>ATTACHMENTS</th>
                                    <!-- <th>FILE TYPES</th> -->
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <!--/.tWrap__head-->
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr *ngFor="let data of FileList">
                                    <td (click)="downloadFileUrl(data)"
                                        style="cursor: pointer; text-decoration: underline;">
                                        {{data.File}}
                                    </td>
                                </tr>
                                <tr *ngIf="FileList?.length<1">
                                    <td>No File Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--/.tWrap__body-->
                </div>
                <!-- Work Status Remark -->
                <label class="ui-form-input-container">
                    <textarea class="ui-form-input" [value]="RemarkWorkStatus" disabled></textarea>
                    <span class="form-input-label">Work Status Remark</span>
                </label>
            </div>
            <div class="col-md-3">
                <button type="button" (click)="OnClickAllot()" style="background-color: teal; width: 100%;"
                    class="sim-button button1">ASSIGN</button>
            </div>
            <div class="col-md-3" *ngIf="closeButtonVisible">
                <button type="button" (click)="closeActivities()" style="background-color: teal;width: 100%;"
                    class="sim-button button1">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <!-- The Modal -->
    <div class="modal fade show" id="myModalConfirm" style="background-color:rgb(0 0 0 / 75%) ;">
        <div class="modal-dialog">

            <div *ngIf="serviceEngWF">
                <!--class="modal-dialog" -->
                <div class="modal-content">
                    <div class="modal-header">
                        <span class="modal-title">Service Engineers Work Forecasting</span>
                        <button type="button" class="close" (click)="OnClickCancel()">×</button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body" style="overflow-y: scroll; ">
                        <!-- seat booking -->
                        <div class="row">
                            <div class="col-md-12">
                                <div>
                                    <table id="chartHeader">
                                        <tr>
                                            <td>
                                                <div style="background-color: #3ec7c0ed ;height: 20px;
                                            width: 20px;display: inline-block;
                                            border-radius: 3px;"> </div> Half Day Leave
                                            </td>
                                            <td>
                                                <div style="background-color: #d6708bcc  ; height: 20px;
                                            width: 20px;display: inline-block;
                                            border-radius: 3px;"> </div> Full Day Leaves
                                            </td>
                                            <td>
                                                <div style="background-color: #56bfe8; height: 20px;
                                                width: 20px;display: inline-block;
                                                border-radius: 3px;"> </div>
                                                Off Days
                                            </td>
                                            <td>
                                                <div style="background-color:red;height: 20px;
                                            width: 20px;display: inline-block;
                                            border-radius: 3px;"> </div>
                                                Fully Alloted
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div style="background-color: yellow;height: 20px;
                                        width: 20px;display: inline-block;
                                        border-radius: 3px;"> </div>
                                                Partialy Alloted
                                            </td>
                                            <td style="padding-right:22px;">
                                                <div style="background-color: greenyellow;height: 20px;
                                            width: 20px;display: inline-block;
                                            border-radius: 3px;"> </div>
                                                Not Alloted
                                            </td>
                                            <td style="padding-right:2px;">
                                                <div style="background-color: #573ec7ed;height: 20px;
                                            width: 20px;display: inline-block;
                                            border-radius: 3px;"> </div>
                                                Holiday
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </table>
                                </div>                             
                            </div>
                            <div class="row" style="margin-top: 20px;">
                                <div class="col-md-3">
                                    <input type="radio" [(ngModel)]="DevTestToggle" [value]="0" name="devTest" id="dev"
                                        #dev="ngModel" (click)="ToggleDevTest()"> <label for="dev">Developer</label>
                                    <input type="radio" [(ngModel)]="DevTestToggle" [value]="1" id="tstr"
                                        #tstr="ngModel" name="devTest" (click)="ToggleDevTest()"><label for="tstr">Tester</label>
                                </div>
                                <div class="col-md-6">
                                    <!-- Select month and year  -->
                                    <div class="row" style="margin-bottom: 10px;">
                                        <div class="col col-4"></div>
                                        <div class="col col-4" style="text-align: center;">
                                            <a style="float: left;" (click)="preMonth()" class="btn btn-primary">

                                                <i class="fas fa-chevron-circle-left"></i></a>
                                            {{filterDate | date:'MMM yyyy'}}
                                            <a style="float: right;" (click)="nextMonth()" class="btn btn-primary"> <i
                                                    class="fas fa-chevron-circle-right"></i> </a>
                                        </div>
                                        <div class="col col-4"></div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <input type="radio" [(ngModel)]="JobOrderOrManHour" [value]="0" name="type"
                                        id="JobOrder" #JobOrder="ngModel"><label for="JobOrder">Job Order</label>

                                    <input type="radio" [(ngModel)]="JobOrderOrManHour" [value]="1" id="ManHours"
                                        #ManHours="ngModel" name="type"> <label for="ManHours">Man Hour</label>


                                </div>
                                <div class="row">
                                    <br>
                                </div>
                                <div class="row">
                                    <div class="tWrap__body">
                                        <table class="table">
                                            <ng-container
                                                *ngFor="let data of _ProjectLeaderService.EmployeeWorkForcastDetails let i=index">
                                                <tr
                                                    *ngIf="DevTestToggle==0 ? data.Designation ==10 || data.Designation ==0: data.Designation ==11|| data.Designation ==0 ">

                                                    <td *ngIf="i!=0">
                                                        {{data.EmpName}}
                                                    </td>
                                                    <th *ngIf="i==0"> </th>
                                                    <ng-container
                                                        *ngFor="let details of data.EmployeeWorkForcastDetails; let j=index">
                                                        <th *ngIf="i==0" style="text-align: center;">
                                                            {{details.Date | date:'dd' }}
                                                            <Span style="display: block; font-size: 10px;">
                                                                {{details.Date | date:'EEE' }}</Span>
                                                        </th>
                                                        <td *ngIf="i!=0"
                                                            [title]="JobOrderOrManHour==0? details.JobOrder:details.ManHour"
                                                            [ngClass]="{'redBg':details.Status==1,'yellowBg':details.Status==2,'greenBg':details.Status==0,'darkBlue':details.IsLeave==3,'lightOrange':details.IsLeave==1,'lightCyan':details.IsLeave==2,'lightblue':details.IsLeave==4}">
                                                            {{JobOrderOrManHour==0?
                                                            details.JobOrder:details.ManHour}}
                                                        </td>
                                                    </ng-container>

                                                </tr>
                                            </ng-container>
                                        </table>
                                    </div>
                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-md-12">
                                        Expected Hours <b>{{totalExpectedTime}} </b>
                                        <div class="tWrap">
                                            <div class="tWrap__head">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">*</th>
                                                            <th scope="col">Employees</th>
                                                            <th scope="col">ExpStartDate</th>
                                                            <th scope="col">ExpCompDate</th>
                                                            <th scope="col">No.Works</th>
                                                            <th scope="col">ManHours</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div class="tWrap__body">
                                                <table>
                                                    <tbody>
                                                        <ng-container
                                                            *ngFor="let obj of this._ProjectLeaderService.PLEmployeeWorksForecastList;let i=index">
                                                            <tr
                                                                *ngIf="DevTestToggle==0 ? obj.Designation ==10 : obj.Designation ==11 ">
                                                                <!-- <td *ngIf="!obj.ChkEnable">
                                                                    <input type="checkbox" disabled
                                                                        style="pointer-events: none;"
                                                                        [ngModel]="obj.checkStatus"
                                                                        name="obj.checkStatus">
                                                                </td> -->
                                                                <!-- Aju --comment -->
                                                                <td *ngIf="obj.ChkEnable" (click)="ToggleWorkAllocationChk(i); "> 
                                                                    <input type="checkbox" #checkStatus="ngModel"
                                                                        style="pointer-events: none;"
                                                                        [ngModel]="obj.checkStatus"
                                                                        name="obj.checkStatus">
                                                                </td> 
                                                                <td> {{obj.SEName}}</td>
                                                                <td>
                                                                    <input *ngIf="obj.checkStatus" type="text"
                                                                        autocomplete="off" [id]="'expStartdate'+i"
                                                                        [name]="'expStartdate'+i" bsDatepicker
                                                                        [disabled]="!obj.ChkEnable"
                                                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [(ngModel)]="obj.ExpStartDate"
                                                                        (ngModelChange)="validateDate(i,'frm')">

                                                                    <span *ngIf="!obj.checkStatus">
                                                                        {{obj.ExpStartDate |date}}</span>
                                                                </td>
                                                                <td>
                                                                    <input *ngIf="obj.checkStatus" type="text"
                                                                        autocomplete="off" [id]="'ExpCompDate'+i"
                                                                        [name]="'ExpCompDate'+i" bsDatepicker
                                                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        [(ngModel)]="obj.ExpCompDate"
                                                                        (ngModelChange)="validateDate(i,'to')">
                                                                    <span *ngIf="!obj.checkStatus">
                                                                        {{obj.ExpCompDate |date}}
                                                                    </span>
                                                                </td>
                                                                <td>{{obj.NoWorks}}</td>
                                                                <td>{{obj.ManWorks}}</td>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12">
                                <textarea class="ui-form-input" id="rmrk" name="rmrk" #rmrk="ngModel"
                                    [(ngModel)]="WorkAllotRemark"></textarea>
                                <span class="form-input-label">Remark</span>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <span style="width: 25%;" class="btnBrowse btn-default btn-file">Browse
                                <input type="file" name="customFile" #customFile id="customFile" multiple
                                    (change)="handleFileInput($event)">
                            </span>
                        </div>
                        <br>

                        <div class="row"
                            *ngIf="this.fileToUpload.length>0 ||this._ProjectLeaderService.JobAssignAttachmentList.length>0">
                            <div class="col-md-6">
                                <div class="tWrap">
                                    <div class="tWrap__head">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col">*</th>
                                                    <th scope="col">FileName</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let obj of  this.fileToUpload; let i=index">
                                                    <th style="background:white;color:black;font-weight: bold;"
                                                        scope="row">
                                                        <i class="fas fa-chevron-circle-right"></i>
                                                    </th>
                                                    <td> {{obj.name}} </td>
                                                    <td><button type="button" (click)="RemoveImage(obj)">-</button>
                                                    </td>
                                                </tr>
                                                <tr
                                                    *ngFor="let obj of this._ProjectLeaderService.JobAssignAttachmentList; let i=index">
                                                    <th style="background:white;color:black;" scope="row">
                                                        <i class="fas fa-chevron-circle-right"></i>
                                                        </th>
                                                    <td (click)="downloadWorkAllotmentFile(obj.FileName)"
                                                        style="text-decoration: underline; cursor: pointer;">
                                                        {{obj.FileName}} </td>
                                                    <td><button type="button"
                                                            (click)="RemovePreviousImage(obj)">-</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <button type="button" [disabled]="DisableAlloteButton" class="sim-button button1"
                            (click)="onAllottWork()">Allot</button>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- WORK STATUS  -->
<form *ngIf="DisplayWorkStatus==1">
    <div class="popup-container">

        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Work Status</p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">SNO</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" title="Exp Start Date">Exp Start Date</th>
                                            <th scope="col">Exp Cmp Date</th>
                                            <th scope="col" title="Actual Start Date">Act Start Date</th>
                                            <th scope="col" title="Completed Date"> Act Comp Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let obj of this.workStatus;let i=index">
                                            <tr>
                                                <td> {{i+1}} </td>
                                                <td> {{obj.EmpName}} </td>
                                                <!-- <td> {{obj.StatusText}} </td>  -->
                                                <td *ngIf="obj.Status!=3">
                                                    {{obj.StatusText}}
                                                    <span style="display: block;"
                                                        *ngIf="obj.Status==6 || (obj.Status==5 && obj.QaStatus>0)">
                                                        {{obj.QaStatus==1?'Succeed':obj.QaStatus==2?'Failure':obj.QaStatus==3?'Retest':obj.QaStatus==4?'Suggestion':'Pending'}}
                                                        - {{obj.LastJobCardEntryDate}}
                                                        <br>
                                                        <span
                                                            style="text-decoration: underline; color: rgb(11, 85, 223);cursor: pointer;"
                                                            (click)="qaRemark=obj.QaRemark"> Remark </span>
                                                    </span>
                                                </td>
                                                <td *ngIf="obj.Status==3" (click)="getholdReason(obj)"
                                                    style="text-decoration: underline; cursor: pointer; color: royalblue;">
                                                    {{obj.StatusText}}
                                                </td>
                                                <td>
                                                    {{obj.ExpStartDate}}
                                                </td>
                                                <td>
                                                    {{obj.ExpCompDate}}
                                                </td>
                                                <td>{{obj.WorkStartDate}}</td>
                                                <td>{{obj.WorkCompleteDate}}</td>
                                                <!-- <td>  
                                                    <button type="button" *ngIf="obj.Status==0" (click)="removeEmployeeFromJobOrder(obj.EmpName,obj.Emp_ID)">-</button>
                                                </td>  -->
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td *ngIf="this.workStatus?.length<0">
                                                No Records To Display.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="col-md-12" *ngIf="qaRemark.length>0">
                            <textarea name="qaRe" disabled id="qaRe" cols="6" rows="3" #qaRe="ngModel"
                                [ngModel]="qaRemark"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Conirm pop up -->
<form *ngIf="DisplayWorkStatus==2">
    <div class="popup-container">

        <input type="checkbox" id="test-cnfirm" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Work Allocation</p> <label for="test-results"> <i class="fas fa-times"
                            (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="modal-body text-center">
                                <p style="color: black;">Do you want to Modify existing JobOrder?</p>
                                <button type="button" class=" btn-success" (click)="popUpConfirm()">Yes</button>
                                <button type="button" class=" btn-info" (click)="closePopUp()">No</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- HOLD REASON -->
<form *ngIf="DisplayWorkStatus==3">
    <div class="popup-container">
        <input type="checkbox" id="test-cnfirm" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                <div class="title">
                    <p>Holding Reason</p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <div style="pointer-events:none;">
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <label class="col-3 form-control-label">Reason</label>
                                    <div class="col-md-5">
                                        <ng-select name="HoldingReason" id="HoldingReason" #HoldingReason="ngModel"
                                            bindLabel="Name" bindValue="Id" [items]="holdingReasonList"
                                            [ngModel]="_JobCardService.newJobDetails.HoldingReason.HoldingReason"
                                            placeholder="Select">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==1">
                                    <div class="col-md-2"></div>
                                    <label class="col-3 form-control-label">Meeting With </label>
                                    <div class="col-md-5">
                                        <ng-select name="MeetingWith" id="MeetingWith" bindLabel="EmpName"
                                            bindValue="Id" [items]="_JobCardService.allEmployee" #MeetingWith="ngModel"
                                            [ngModel]="_JobCardService.newJobDetails.HoldingReason.MettingWith"
                                            placeholder="Select">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==2">
                                    <div class="col-md-2"></div>
                                    <label class="col-3 form-control-label">Expecting Reasult From</label>
                                    <div class="col-md-5">
                                        <ng-select name="ExpectResult" id="ExpectResult" bindLabel="EmpName"
                                            bindValue="Id" #ExpectResult="ngModel"
                                            [ngModel]="_JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom"
                                            [items]="_JobCardService.allEmployee" placeholder="Select">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==3">
                                    <div class="col-md-2"></div>
                                    <label class="col-3 form-control-label">Other Emergency Work</label>
                                    <div class="col-md-5">
                                        <ng-select name="otherwrk" id="otherwrk" bindLabel="JONO" bindValue="JONO"
                                            required #otherwrk="ngModel"
                                            [ngModel]="_JobCardService.newJobDetails.HoldingReason.OtherEmergencyWork"
                                            [items]="_JobCardService.allJobCardDtl" placeholder="Select">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==4">
                                    <div class="col-md-2"></div>
                                    <label class="col-3 form-control-label">Clarification From</label>
                                    <div class="col-md-5">
                                        <ng-select name="ClarificationFrm" id="ClarificationFrm" bindLabel="EmpName"
                                            bindValue="Id" required #ClarificationFrm="ngModel"
                                            [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom"
                                            [items]="_JobCardService.allEmployee" placeholder="Select">
                                        </ng-select>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <label class="col-3 form-control-label">Reason</label>
                                    <div class="col-md-5">
                                        <textarea type="text" name="Reason" id="Reason" #Reason="ngModel"
                                            [ngModel]="_JobCardService.newJobDetails.HoldingReason.Reason"></textarea>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <label class="col-3 form-control-label">Remark</label>
                                    <div class="col-md-5">
                                        <textarea type="text" name="Remark" id="Remark" #Remark="ngModel"
                                            [ngModel]="_JobCardService.newJobDetails.HoldingReason.Remark"></textarea>
                                    </div>
                                </div>
                            </div>
                            <!-- <table></table> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<form *ngIf="deleteActivity > 0">
    <div class="popup-container">
        <input type="checkbox" id="deletePopUp" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">
            <div class="inner ui-input-container" style="background-color:#404040;color:white;" (click)="$event.stopPropagation();">
                <div class="title">
                    <p class="headding">WORK ALLOTMENT</p>
                    <label for="deletePopUp"><i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <hr style="margin-bottom:1px;color: white;" >
                <div>
                    <div class="row">
                        <div class="col-md-12" style="text-align:center">
                            <p class="headding leftside" (click)="this.activityType=0;this.activityText='Delete Activity'">Delete Activity</p><br><br>
                            <table *ngIf="this.activityType==0 && this._ProjectLeaderService.ListActivityWiseDeleteOption.length > 0" class="deleteOption">
                                <tr style="border: none;" *ngFor="let obj of this._ProjectLeaderService.ListActivityWiseDeleteOption;let i=index">
                                    <td style="border: none;"><input type="radio" [(ngModel)]="obj.EmployeeID" #EmployeeID="ngModel" name="EmployeeID" [value]="i" (click)="this.deleteActivityOption=obj.EmployeeID">
                                        <span style="color: white;border: none;padding-left:5px;">{{obj.OptionMessage}}</span></td>
                                    <!-- <td style="color: white;border: none;"></td> -->
                                </tr>                       
                            </table>
                            <p class="headding leftside" (click)="isStartedActivity()">Work PostPoned</p><br><br>
                            <div *ngIf="this.activityType==1" class="leftside">    
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Start.Date</label>
                                        <input bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" style="width:100px;margin-left:5px;" autocomplete="off" id="startedDate" name="startedDate" [(ngModel)]="this._ProjectLeaderService.activityWiseWorkPostPoned.StartedDate" [disabled]="this._ProjectLeaderService.activityWiseWorkPostPoned.IsWorkStarted">
                                        <label>Comp.Date</label>
                                        <input bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" style="width:100px;" autocomplete="off" id="compDate" name="compDate" [(ngModel)]="this._ProjectLeaderService.activityWiseWorkPostPoned.CompletedDate">
                                        <input type="text" name="EstHrs" #EstHrs="ngModel" style="width: 40px;margin-left:3px;" placeholder="Hrs" (keypress)="_CommonService.numberValidation($event)" [(ngModel)]="this._ProjectLeaderService.activityWiseWorkPostPoned.EstHrs">
                                        <input type="text" name="EstMins" #EstMins="ngModel" style="width: 40px;margin-left:3px;" placeholder="Mins" (keypress)="_CommonService.numberValidation($event)" [(ngModel)]="this._ProjectLeaderService.activityWiseWorkPostPoned.EstMin">
                                        <select class="form-control" name="Reason" #Reason="ngModel" [(ngModel)]="this.selectedReasonId">
                                            <option [ngValue]="0">Select Extented Reason</option>
                                            <option *ngFor="let reason of _CommonService.allCommonResult | orderBy : 'Name'" [ngValue]="reason.Id">
                                                {{reason.Name}}
                                            </option>
                                        </select>
                                    </div>                                  
                                </div>                                                        
                            </div><br><br>
                            <div style="float:right;padding-top:3px;">
                                <button class="btn btn-sm btn-success" (click)="closePopUp()">Cancel</button> &nbsp;
                                <button class="btn btn-sm btn-danger" (click)="activityWiseJobChanges()">{{this.activityText}}</button>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- HOLD REASON Action Type-->
<form *ngIf="DisplayWorkStatus==4">
    <div class="popup-container">

        <input type="checkbox" id="hold-cnfirm" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p> <span style="text-decoration: underline;cursor: pointer;"
                            [ngClass]="{'activePopup':holdOrReject==0}" (click)="holdOrReject=0;"> Holding Reason
                        </span> &nbsp;/
                        <span style="text-decoration: underline;cursor: pointer;"
                            [ngClass]="{'activePopup':holdOrReject==1}" (click)="holdOrReject=1;">Reject Reason</span>
                    </p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div *ngIf="holdOrReject==0">
                    <div class="row HoldingReason">
                        <div class="col-md-12">
                            <div>

                                <div class="row">
                                    <div class="col-5">

                                        <div class="tWrap">
                                            <div class="tWrap__head">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>SNo</th>
                                                            <th>Reason</th>
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <!--/.tWrap__head-->
                                            <div class="tWrap__body">
                                                <table>
                                                    <tbody>
                                                        <tr *ngFor="let obj of  HoldDetailsList;let i =index">
                                                            <td>
                                                                {{i+1}}
                                                            </td>
                                                            <td>
                                                                <span
                                                                    *ngFor="let value of workallotmentHoldReasonsList">
                                                                    <span *ngIf="value.Id==obj.Reasons">
                                                                        {{value.Name}}</span>
                                                                </span>

                                                            </td>
                                                            <td>
                                                                <span (click)="selectHoldingReason(obj)"
                                                                    style="cursor: pointer; text-decoration: underline;">
                                                                    Select
                                                                </span>
                                                            </td>


                                                        </tr>
                                                        <tr *ngIf="HoldDetailsList?.length<=0">
                                                            <td colspan="3">
                                                                No Records To Display.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!--/.tWrap__body-->
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="row">

                                            <label class="col-3 form-control-label">Reason <span
                                                    style="color: red;">*</span></label>
                                            <div class="col-md-9">
                                                <ng-select name="HoldingReasonAction" id="HoldingReasonAction"
                                                    #HoldingReasonAction="ngModel" bindLabel="Name" bindValue="Id"
                                                    [items]="workallotmentHoldReasonsList"
                                                    [(ngModel)]="selectedHoldDetails.Reasons" placeholder="Select">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="selectedHoldDetails.Reasons==4">

                                            <label class="col-3 form-control-label">
                                                Clarification <span style="color: red;">*</span>
                                            </label>
                                            <div class="col-md-9">
                                                <ng-select name="HoldingMeetingWith" id="HoldingMeetingWith"
                                                    bindLabel="EmpName" bindValue="Id"
                                                    [items]="_JobCardService.allEmployee" #HoldingMeetingWith="ngModel"
                                                    [(ngModel)]="selectedHoldDetails.ClarificationFrom"
                                                    placeholder="Select">
                                                </ng-select>

                                            </div>
                                        </div>
                                        <div class="row">

                                            <label class="col-3 form-control-label">Reason <span
                                                    style="color: red;">*</span></label>
                                            <div class="col-md-9">
                                                <textarea type="text" name="HoldingReason" id="HoldingReason"
                                                    #HoldingReason="ngModel" [(ngModel)]="selectedHoldDetails.Reason"
                                                    required></textarea>

                                                <div class="validation-error"
                                                    *ngIf="HoldingReason.invalid && HoldingReason.touched">
                                                    This
                                                    Field is Required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <label class="col-3 form-control-label">Remark </label>
                                            <div class="col-md-9">
                                                <textarea type="text" name="HoldingRemark" id="HoldingRemark"
                                                    #HoldingRemark="ngModel"
                                                    [(ngModel)]="selectedHoldDetails.Remark"></textarea>

                                            </div>
                                        </div>
                                        <div class="row" *ngIf="!DisableHoldUpdateButton">
                                            <div class="col-md-3">
                                                <button style="width: 99%;" type="button" class="sim-button button2"
                                                    (click)="ClearWorkAllotmentHoldReason()">Clear</button>
                                            </div>
                                            <div class="col-md-4">
                                                <button type="button" (click)="updateHoldReason()"
                                                    class="sim-button button1" style="width: 100%;">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table></table>
                            </div>
                        </div>
                    </div>
                </div>



                <div *ngIf="holdOrReject==1">
                    <div class="row">
                        <div class="col-md-12" style="text-align:center">

                            <textarea name="reason" id="reason" cols="30" rows="5" placeholder=" reason."
                                #reason="ngModel" disabled [ngModel]="RejectReason"></textarea>
                            <!-- <button class="sim-button button1" (click)="UpdateRejectReason()" [disabled]="DisableAlloteButton" >Submit</button> -->

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Activity Remark  -->
<form *ngIf="DisplayWorkStatus==5">
    <div class="popup-container">

        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Activity Remarks </p> <label for="test-results"> <i class="fas fa-times"
                            (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12" style="text-align:center">

                            <textarea name="actremark" id="actremark" cols="6" rows="2" placeholder="Enter your remarks"
                                #actremark="ngModel" [(ngModel)]=" selectedActivityRemark.Remark"></textarea>
                            <button class="sim-button button1"
                                (click)="addActivityRemark(selectedActivityRemark)">{{selectedActivityRemark.ID==0?'Add':'Update'}}</button>
                        </div>
                        <div class="tWrap" style="padding-top: 20px;">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>SlNo</th>
                                            <th style="width: 80%;">Remark</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <!--/.tWrap__head-->
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        <tr *ngFor="let data of activityRemarksList;let i=index">
                                            <td>{{i+1}}</td>
                                            <td style="white-space: break-spaces;width: 80%;">
                                                {{data.Remark}}

                                                <div style="text-align:right;">
                                                    <span style="display: block;">Added By {{data.AddedByName}}
                                                        {{data.AddedDate |date:'MM-dd-yyyy'}}</span>
                                                    <span style="display: block;"
                                                        *ngIf="data.EditedByName.length>0">Modified By
                                                        {{data.EditedByName}} {{data.EditedDate}}</span>
                                                </div>


                                            </td>
                                            <td>
                                                <button type="button" class="btn-success"
                                                    (click)="editActivityRemark(data)">
                                                    <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                                                <!-- <button type="button" class="btn-danger" ><i class="fas fa-trash"
                                                        aria-hidden="true"></i></button> -->
                                            </td>
                                        </tr>
                                        <tr *ngIf="activityRemarksList.length<1">
                                            <td colspan="3"> No Records To Display.</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <!--/.tWrap__body-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Reject Reason  -->
<form *ngIf="DisplayWorkStatus==6">
    <div class="popup-container">

        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Reject Reason </p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12" style="text-align:center">

                            <textarea name="reason" id="reason" cols="30" rows="5" placeholder="Enter your reason."
                                #reason="ngModel" [(ngModel)]="RejectReason"></textarea>
                            <button class="sim-button button1" (click)="UpdateRejectReason()"
                                [disabled]="DisableAlloteButton">Submit</button>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- Description -->
<form *ngIf="DisplayWorkStatus==7">
    <div class="popup-container">

        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Description </p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12" style="text-align:center">

                            <textarea class="ui-form-input" style="height: 350px;" id="des" name="des" #des="ngModel"
                                [(ngModel)]="Description"></textarea>

                        </div>
                        <div class="col-md-12" style="text-align:center" *ngIf="selectedActivityID>0">

                            <button type="button" (click)="updateDescription();" class=" sim-button button1"
                                style="margin-top: 30px;">Submit</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<form *ngIf="DisplayWorkStatus==8">
    <div class="popup-container">
        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                <div class="title">
                    <p>Job No:{{this._ProjectLeaderService.activityPopUpDetails.JONO}} </p> <label for="test-results">
                        <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-3">SLNo :{{_ProjectLeaderService.activityPopUpDetails.SNo}}</div>
                        <div class="col-5">Dealer : {{_ProjectLeaderService.activityPopUpDetails.Dealer}}</div>
                        <div class="col-3">RegNo :{{_ProjectLeaderService.activityPopUpDetails.RegNo}}</div>
                        <div class="col-3">Type :{{_ProjectLeaderService.activityPopUpDetails.Type}}</div>
                        <div class="col-5">Particular : {{_ProjectLeaderService.activityPopUpDetails.Particular}}</div>
                        <div class="col-4">Module : {{_ProjectLeaderService.activityPopUpDetails.Module}}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <input type="radio" [(ngModel)]="DevTestToggle" [value]="0" name="devTest" id="dev"
                                #dev="ngModel" (click)="ToggleDevTest()"> <label for="dev">Developer</label>
                            <input type="radio" [(ngModel)]="DevTestToggle" [value]="1" id="tstr" #tstr="ngModel"
                                name="devTest" (click)="ToggleDevTest()"> <label for="tstr">Tester</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="tWrap">
                                <div class="tWrap__head">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Employees</th>
                                                <th scope="col">ExpStartDate</th>
                                                <th scope="col">ExpCompDate</th>
                                                <th scope="col">No.Works</th>
                                                <th scope="col">ManHours</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div class="tWrap__body">
                                    <table>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let obj of this._ProjectLeaderService.PLEmployeeWorksForecastList;let i=index">
                                                <!-- (DevTestToggle==0 ? obj.Designation ==10 : obj.Designation ==11 ) && -->
                                                <tr *ngIf="(obj.ExpStartDate !=null && obj.ExpStartDate.length>0) ">
                                                    <td> {{obj.SEName}}</td>
                                                    <td>
                                                        <span>{{obj.ExpStartDate |date}}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{obj.ExpCompDate |date}}</span>
                                                    </td>
                                                    <td>{{obj.NoWorks}}</td>
                                                    <td>{{obj.ManWorks}}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <textarea disabled class="ui-form-input" id="rmrk" name="rmrk" #rmrk="ngModel"
                                [(ngModel)]="WorkAllotRemark"></textarea>
                            <span class="form-input-label">Remark</span>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <span style="width: 25%; margin:0" class="btnBrowse btn-default btn-file">Browse
                            <input type="file" name="customFile" #customFile id="customFile" multiple
                                (change)="handleFileInput($event)">
                        </span>
                    </div> -->
                    <!-- <div class="row"
                        *ngIf="this.fileToUpload.length>0 ||this._ProjectLeaderService.JobAssignAttachmentList.length>0">
                        <div class="col-md-6">
                            <div class="tWrap">
                                <div class="tWrap__head">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col">*</th>
                                                <th scope="col">FileName</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let obj of  this.fileToUpload; let i=index">
                                                <th style="background:white;color:black;font-weight: bold;" scope="row">
                                                    <i class="fas fa-chevron-circle-right"></i>
                                                </th>
                                                <td> {{obj.name}} </td>
                                                <td><button type="button" (click)="RemoveImage(obj)">-</button>
                                                </td>
                                            </tr>
                                            <tr
                                                *ngFor="let obj of this._ProjectLeaderService.JobAssignAttachmentList; let i=index">
                                                <th style="background:white;color:black;" scope="row"><i
                                                        class="fas fa-chevron-circle-right"></i></th>
                                                <td (click)="downloadWorkAllotmentFile(obj.FileName)"
                                                    style="text-decoration: underline; cursor: pointer;">
                                                    {{obj.FileName}} </td>
                                                <td><button type="button" (click)="RemovePreviousImage(obj)">-</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                    <div class="row">
                        <button type="button" [disabled]="DisableAlloteButton" class="sim-button button1"
                            (click)="submitJobNoDetails()">submit</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</form>
<!-- testResult Details -->
<app-pro-test-result *ngIf="this._ProjectLeaderService.testResultHistoryPopUp" [activityId]="this.activityId" [stage]="this.stage" [joNo]="this.joNo" [testResult]="this.testResult" [qaResult]="this.qaResult"></app-pro-test-result>