<div class="container-fluid" *ngIf="_Service.formHideShow==0">
    <div id="form-wrap">
        <h2 class="heading_all">SERVER MASTER</h2>
        <br>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">
                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addNew()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width:60%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th class="hidden-xs" style="width:205px;">
                        <div class="AtjIsPoint" (click)="sort('RegDate')">
                            <span *ngIf="!_Service.isFiltering">Reg.Date</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_Service.orderByColName!='RegDate' "></i>
                                <div *ngIf="_Service.orderByColName=='RegDate'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_Service.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_Service.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_Service.isFiltering">
                            <input type="text" #txtRegDateFilter="ngModel" [(ngModel)]="_Service.RegDateFilter" placeholder="Reg.Date">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Server')">
                            <span *ngIf="!_Service.isFiltering">Server</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_Service.orderByColName!='Server' "></i>
                                <div *ngIf="_Service.orderByColName=='Server'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_Service.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_Service.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_Service.isFiltering">
                            <input type="text" #txtServerFilter="ngModel" [(ngModel)]="_Service.ServerFilter" placeholder="Server Name">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Location')">
                            <span *ngIf="!_Service.isFiltering">Location</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_Service.orderByColName!='Location' "></i>
                                <div *ngIf="_Service.orderByColName=='Location'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_Service.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_Service.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_Service.isFiltering">
                            <input type="text" #txtLocationFilter="ngModel" [(ngModel)]="_Service.LocationFilter" placeholder="Location ">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('ServerProvider')">
                            <span *ngIf="!_Service.isFiltering">Server Provider</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_Service.orderByColName!='ServerProvider' "></i>
                                <div *ngIf="_Service.orderByColName=='ServerProvider'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_Service.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_Service.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_Service.isFiltering">
                            <input type="text" #txtServerProviderFilter="ngModel" [(ngModel)]="_Service.ServerProviderFilter" placeholder="Server Provider ">
                        </div>
                    </th>
                    <th style="width: 135px;"><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_Service.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="_Service.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let obj of _Service.allServer | orderBy : 'Server' | gridOperations  :_Service.pageIndex: 
                        {RegDate:_Service.RegDateFilter,Server:_Service.ServerFilter,
                        Location:_Service.LocationFilter,ServerProvider:_Service.ServerProviderFilter}:
                        {Column:_Service.orderByColName,Dir:_Service.orderByDir} ; let i = index"
                    class="ng-scope">
                    <td>{{i+1+((NoOfItems*_Service.pageIndex)-NoOfItems)}}</td>
                    <td style="width:205px;">{{obj.RegDate}}</td>
                    <td>{{obj.Server}}</td>
                    <td>{{obj.Location}}</td>
                    <td>{{obj.ServerProvider}}</td>
                    <td style="width: 135px;">
                        <button type="button" class="btn-success" (click)="onEdit(obj);">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                        <button type="button" class="btn-danger" (click)="onDelete(obj.ID)"><i
                                class="fas fa-trash" aria-hidden="true"></i></button>
                    </td>
                </tr><!-- end ngRepeat: item in items -->
                <tr>
                    <td colspan="4"
                        *ngIf="(_Service.allServer?.length<=0 )  || _CommonService.filterdItemCount<1">
                        No Records To Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="_Service.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <div class="margintop"></div>
    </div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'SERVER MASTER'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>