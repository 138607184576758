import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { CustomerRequirements, CustomerRequirementsDtls, CustomerRequirement_Common } from '../Models/CustomerRequirements';
import { GlobalErrorHandlerService } from './global-error-handler.service'
import { employee } from '../Models/employee.model';
import * as CryptoJS from 'crypto-js';
import { SEDashBoardCustReq } from '../Models/Dashboard.Model';

@Injectable({
  providedIn: 'root'
})
export class CustomerrequirementService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  selectedRecord: CustomerRequirements;
  selectedCustomerRequirement_Common: CustomerRequirement_Common
  allCustomerDtls: CustomerRequirementsDtls[];
  allCustomerReqHistory: CustomerRequirementsDtls[];
  public newAttribute: any = {};
  RowCount: any;
  allSE: employee[]
  allEmployees: employee[]
  DescriptionEdit: string
  FormUpdation: string = 'SAVE';
  //Dealer Dashboard
  SelectedDealerDashBoardCustReq: SEDashBoardCustReq[]
  DealerDashBoardTodaysSchdule: SEDashBoardCustReq[]


  getCustomerRequirment(DealerId: number): Observable<CustomerRequirements> {
    let params = new HttpParams();
    params = params.append('RefNo', this.selectedRecord.RefNo.toString()).append('DealerId', DealerId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<CustomerRequirements[]>(ROOT_URL + 'CustomerRequirement/GetAllDataByRefNo', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getCustomerRequirmentHomescreen(RefNo: number, DealerId: number): Observable<CustomerRequirements> {
    let params = new HttpParams();
    params = params.append('RefNo', RefNo.toString()).append('DealerId', DealerId.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<CustomerRequirements[]>(ROOT_URL + 'CustomerRequirement/GetAllDataByRefNo', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  SaveItem(Values: CustomerRequirements, fileToUpload: File[], LoginUser: string): Observable<any> {
    let headers = new HttpHeaders().set('contentType', 'false');
    var body = {
      ID: Values.ID,
      PlannedDeliveryDate: Values.PlannedDeliveryDate,
      ExpectedDate: Values.ExpectedDate,
      Remark: Values.Remark,
      RefNo: Values.RefNo,
      Time: Values.Time,
      ContractDate: Values.ContractDate,
      SEID: Values.SEID,
      ModeOfReg: Values.ModeOfReg,
      Date: Values.Date,
      BranchID: Values.BranchID,
      AMCUPTo: Values.AMCUPTo,
      CustomerID: Values.CustomerID,
      ProjectID: Values.ProjectID,
      Server: Values.Server,
      Node: Values.Node,
      NoOfVisits: Values.NoOfVisits,
      Address: Values.CustAdr1,
      Note: Values.Note,
      CusAttachementList: Values.CusAttachementList,
      CusAttachementRemovedList: Values.CusAttachementRemovedList,
      CusReqDtls: this.allCustomerDtls,
      DealerId: Values.DealerId,
      UserType: LoginUser
    }

    const formData: FormData = new FormData();
    if (fileToUpload) {
      for (var i = 0; i < fileToUpload.length; i++) {
        formData.append('Document', fileToUpload[i]);
      }
    }
    formData.append('obj', JSON.stringify(body));

    let params = new HttpParams();
    params = params.append('Type', this.FormUpdation)

    return this.http.post<any>(ROOT_URL + 'CustomerRequirement/InsUpdDelCustomerRequirements', formData, {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  onDelete(Id: number) {
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.delete<any>(ROOT_URL + 'CustomerRequirement/DeleteCustomerRequirement', {
      headers, params

    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  GetCustomerRequirementsHistory(custId: number, BranchId: number, ProjectId: number, RefNo: number): Observable<any> {
    var body = {
      CustomerID: custId,
      RefNo: RefNo,
      BranchID: BranchId,
      ProjectID: ProjectId
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + 'CustomerRequirement/GetCustomerRequirementsHistory', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  GetDealerDashboardCustReqData(TransMode: string, selectedDate: string): Observable<SEDashBoardCustReq[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode)
      // .append('ID', CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8))
      .append('selectedDate', selectedDate);

    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<SEDashBoardCustReq[]>(ROOT_URL + 'DashBoard/GetDealerDashboardCustReqData', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

}
