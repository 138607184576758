import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import jQuery from "jquery";
import { EmployeeService } from 'src/app/Services/employee.service';
import { JobCardService } from 'src/app/Services/job-card.service';
import { CommonService } from 'src/app/Services/common.service';
import { IMG_ROOT_URL, ROOT_URL } from 'src/app/Models/Config';
import { NgForm } from '@angular/forms';
import { JobCard, JobCardTestResult, JobCardTestResultAttachments, KeyWords } from 'src/app/Models/job-card';
import { ToastrService } from 'ngx-toastr';
import { employee } from 'src/app/Models/employee.model';
import { fileList } from 'src/app/Models/PLWorkDetailsHdr.model';
import * as moment from 'moment';
import { DateformatPipe } from '../../../../CustomPipe/dateformat.pipe';
// import { exit } from 'process';
import { DatePeriod } from 'src/app/Models/DatePeriod';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.css']
})
export class JobCardComponent implements OnInit {
  public errorMsg;
  StatusList: any;
  KeyWordList: KeyWords[] = [];// To display All KeyWords
  // OpenkeyWordPopup: boolean = false;
  AddTestResultKeyWord: string = "";
  BugId:number=0;
  AddTestResultDesc: string = "";
  Remark: string = '';
  RemarkWorkSorting: string = '';
  RemarkWorkStatus: string = '';
  WorkAllotmentRemark:string='';
  AddNewKeyword: string = '';
  Description: string = '';
  FileList: fileList[] = [];
  fileToUpload: any[] = [];
  saveMode: string
  EmployeeList: employee[];
  SelectedGDrivePath:string='';
  selectedBatchId:number=0;

  holdingReasonList = [
    // { Id: 1, Name: 'Meeting With' },
    { Id: 2, Name: 'Expecting Reasult From' },
    { Id: 3, Name: 'Other Emergency Work' },
    { Id: 4, Name: 'Clarification' },
    { Id: 5, Name: 'Service Charge Confirmation' }
  ];

  TestResultStatus = [
    { ID: 1, Name: 'Succeed' },
    { ID: 2, Name: 'Failure' },
    { ID: 3, Name: 'Retest' }
    // { ID: 4, Name: 'Suggestions' }
  ]

  Bugs=[
    {ID:1,Name:'Bad'},
    {ID:2,Name:'Normal'},
    {ID:3,Name:'Suggestions'},
    {ID:4,Name:'Worse'}
  ]

  TypeList: any;
  PriorityList: any;
  filterFields: JobCard;
  dDates:DatePeriod={FromDate:'',ToDate:''}
  enabledates:boolean=true;
  transmode:string='';

  constructor(public _JobCardService: JobCardService,
              public _EmployeeService: EmployeeService,
              public _CommonService: CommonService, 
              private toastr: ToastrService,
              public employeeService:EmployeeService,
              private route: ActivatedRoute
              ) { }

  DevOrTest: number;
  OtherEmergencyWork: JobCard[];
  UserRole: string = '';
  isFiltering: boolean = false;
  orderByColName: string = '';
  orderByDir = false;
  employeesWorkingOn: string = '';
  isRedirect:boolean=false;

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.isRedirect=queryParams['param4'];
      if(!this.isRedirect)
      {
        this._CommonService.period=1;
        this.setDate((this._CommonService.period==null?1:this._CommonService.period));
      }
    });
    this.PriorityList = this._CommonService.GetPriorityList()
    this.TypeList = this._CommonService.GetTypeList();
    this.transmode="ALL";

    this._JobCardService.selectedJobCard.Emp_ID = 0;
    this.isFiltering = true;
    this._CommonService.isLoading = false;
    this.toggleFilter();
    this.UserRole = JSON.parse(localStorage.getItem('userRoles'))[0].toString();
    this.StatusList = []
    this.StatusList = this._CommonService.GetStatusFullProviderList();

    this.EmployeeList = [];
    if (this.UserRole == 'Developers & Designers') {
      this._JobCardService.selectedJobCard.Designation = 10;
    }
    if (this.UserRole == 'Project Leader' || this.UserRole == 'Project Manager' || this.UserRole == 'Developers & Designers') {
      this.DevOrTest = 1;
      this.StatusList = this._CommonService.GetStatusFullProviderList();
    }
    else if (this.UserRole == 'Testers') {
      this.DevOrTest = 2;
      this.StatusList = this._CommonService.GetTesterStatusList();
      this._JobCardService.selectedJobCard.Designation = 11;
    }
    if ((this.UserRole == 'Developers & Designers' || this.UserRole == 'Testers')) {
      this._JobCardService.statusType=0;
      this._CommonService.getLoginedUserId().then(value => {
        this._JobCardService.selectedJobCard.Emp_ID = Number(value); this.getAllJobs()
      });
    }
    if (this.isRedirect) {
      this.route.queryParams.subscribe((queryParams) => {
        this._JobCardService.selectedJobCard.Emp_ID =Number(queryParams['param1']);
        this._JobCardService.selectedJobCard.Designation = queryParams['param2'];
        this.enabledates = false;
        this._CommonService.period = 11;
        this._JobCardService.fromDate = new Date(queryParams['param3']);
        this._JobCardService.toDate = new Date(queryParams['param3']);
        this.DevOrTest = this._JobCardService.selectedJobCard.Designation == 10 ? 1 : 2;
      });
      if (this._JobCardService.selectedJobCard.Emp_ID > 0) this.getAllJobs()
    }

    this._JobCardService.allJobCardDtl = [];
    this.FileList = [];
    this.saveMode = '';
    this._JobCardService.newJobDetails = {
      ID: 0,
      JONO: null,
      Date: new Date(),
      Emp_ID: null,
      Activity: null,
      W_Date: new Date(),
      W_DateFrom: new Date(),
      W_DateTo: new Date(),
      W_From: '',
      W_To: '',
      Break: null,
      NextDay: false,
      Status: null, StatusText: '',
      Batch: 0,
      HoldingReason: { HoldingReason: null, Remark: '', Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: null, ID: 0, JONO: null, MettingWith: null, OtherEmergencyWork: null, Reason: '' }
    }
    // this.saveMode = '';
    window.scroll(0, 0);
    //jQuery plugin
    (function ($) {
      $.fn.uploader = function (options) {
        var settings = $.extend({
          MessageAreaText: "No files selected.",
          MessageAreaTextWithFiles: "File List:",
          DefaultErrorMessage: "Unable to open this file.",
          BadTypeErrorMessage: "We cannot accept this file type at this time.",
          acceptedFileTypes: ['pdf', 'jpg', 'gif', 'jpeg', 'bmp', 'tif', 'tiff', 'png', 'xps', 'doc', 'docx',
            'fax', 'wmp', 'ico', 'txt', 'cs', 'rtf', 'xls', 'xlsx']
        }, options);

        var uploadId = 1;
        //update the messaging 
        $('.file-uploader__message-area p').text(options.MessageAreaText || settings.MessageAreaText);

        //create and add the file list and the hidden input list
        var fileList = $('<ul class="file-list"></ul>');
        var hiddenInputs = $('<div class="hidden-inputs hidden"></div>');
        $('.file-uploader__message-area').after(fileList);
        $('.file-list').after(hiddenInputs);

        //when choosing a file, add the name to the list and copy the file input into the hidden inputs
        $('.file-chooser__input').on('change', function () {
          var file = $('.file-chooser__input').val();
          var fileName = (file.match(/([^\\\/]+)$/)[0]);

          //clear any error condition
          $('.file-chooser').removeClass('error');
          $('.error-message').remove();

          //validate the file
          var check = checkFile(fileName);
          if (check === "valid") {

            // move the 'real' one to hidden list 
            $('.hidden-inputs').append($('.file-chooser__input'));

            //insert a clone after the hiddens (copy the event handlers too)
            $('.file-chooser').append($('.file-chooser__input').clone({ withDataAndEvents: true }));

            //add the name and a remove button to the file-list
            $('.file-list').append('<li style="display: none;"><span class="file-list__name">' + fileName + '</span><button class="removal-button" data-uploadid="' + uploadId + '"></button></li>');
            $('.file-list').find("li:last").show(800);

            //removal button handler
            $('.removal-button').on('click', function (e) {
              e.preventDefault();

              //remove the corresponding hidden input
              $('.hidden-inputs input[data-uploadid="' + $(this).data('uploadid') + '"]').remove();

              //remove the name from file-list that corresponds to the button clicked
              $(this).parent().hide("puff").delay(10).queue(function () { $(this).remove(); });

              //if the list is now empty, change the text back 
              if ($('.file-list li').length === 0) {
                $('.file-uploader__message-area').text(options.MessageAreaText || settings.MessageAreaText);
              }
            });

            //so the event handler works on the new "real" one
            $('.hidden-inputs .file-chooser__input').removeClass('file-chooser__input').attr('data-uploadId', uploadId);
            //update the message area
            $('.file-uploader__message-area').text(options.MessageAreaTextWithFiles || settings.MessageAreaTextWithFiles);
            uploadId++;
          } else {
            //indicate that the file is not ok
            $('.file-chooser').addClass("error");
            var errorText = options.DefaultErrorMessage || settings.DefaultErrorMessage;

            if (check === "badFileName") {
              errorText = options.BadTypeErrorMessage || settings.BadTypeErrorMessage;
            }

            $('.file-chooser__input').after('<p class="error-message">' + errorText + '</p>');
          }
        });

        var checkFile = function (fileName) {
          var accepted = "invalid",
            acceptedFileTypes = this.acceptedFileTypes || settings.acceptedFileTypes,
            regex;

          for (var i = 0; i < acceptedFileTypes.length; i++) {
            regex = new RegExp("\\." + acceptedFileTypes[i] + "$", "i");

            if (regex.test(fileName)) {
              accepted = "valid";
              break;
            } else {
              accepted = "badFileName";
            }
          }
          return accepted;
        };
      };
    }(jQuery));

    //init 
    $(document).ready(function () {
      $('.fileUploader').uploader({
        MessageAreaText: "No files selected. Please select a file."
      });
    });
    this.employeeService.employeeDashBoard={
      IsEnteredLastJobCard:true,
      LastEnteredJobCard:null,
    }
    this.employeeService.displayEmployeeDashBoard('EMPDASHBOARD');
    this.getEmployee();
    this.loadEmpJobsBatch();
  }

  loadEmpJobsBatch()
  {
    this._CommonService.getCommonList("EMPJOBSBATCH").subscribe(
      response=>{
        this._JobCardService.EmpWiseJobBatch=response;
      }
    )
  }

  toggleFilter() {
    this.isFiltering = this.isFiltering ? false : true;
    if (!this.isFiltering) {
      this.filterFields = {
        QAConclusion: null, TesterConclusion: null,
        StatusText: '',
        Emp_ID: null,
        Designation: null,
        TesterSucceed: false,
        JobDetails: [],
        JobCardNotes: [],
        TestResults: [],
        JobCardTestResultAttachments: [],
        JobCardNotesAttachment: [],
        JONO: null,
        IssueDate: null,
        Activity: null,
        EstHrs: '',
        ExpStDate: null,
        CompDate: null,
        OverdueBy: '',
        ModuleId:0,
        Module: '',
        Particulars: null,
        Status: null,
        Description: '',
        Remark: '', WorkStatusRemark: '', WorkSortingRemark: '',WorkAllotmentRemark:'',
        DealerId: 0,
        Vtype: null,
        FileList: null,
        CustomerName: '',
        PriorityId: null,
        Type: null,
        AssignedBy: '',
        AttSE: '',
        TestResult: '',
        Attempt: 0,
        TResultWOutJobcard:false,
        IsDue:false
      };
    }
  }
  sort(colName: string) {
    if (colName == this.orderByColName) {
      this.orderByDir = !this.orderByDir;
    }
    else {
      this.orderByColName = colName;
    }
  }
  // get all employee
  getEmployee() {
    this._EmployeeService.Fetch_EmployeeById('ADMIN', 0).subscribe(data => {
      this._JobCardService.allEmployee = data;
      this.EmployeeList = this._JobCardService.allEmployee.filter(arr => arr.Designation == (this.DevOrTest == 1 ? 10 : 11));
    },
      error => {
        this.errorMsg = error;
      })
  }

  getAllJobs() {
    if (this._JobCardService.selectedJobCard.Emp_ID > 0) {
      this._JobCardService.allJobCardDtl = [];
      this._CommonService.isLoading = true;
      this._JobCardService.Fetch_AllJobs(this.transmode,this._JobCardService.selectedJobCard.Emp_ID,this._CommonService.DateFormat(this._JobCardService.fromDate,0),this._CommonService.DateFormat(this._JobCardService.toDate,0),this.selectedBatchId).subscribe(data => {
        this._JobCardService.allJobCardDtl = data
        this._CommonService.isLoading = false;
        this._JobCardService.selectedWorkDoc=[];
        this.transmode='DATEWISE';
      },
        error => {
          this.errorMsg = error;
          this._CommonService.isLoading = false;
        });
    }
  }
  SelectedIndex: number = null;
  getJobDet(obj: JobCard, i) {
    this._JobCardService.selectedWorkDoc=[];
    this.SelectedIndex = i,
    this.Description = obj.Description;
    this.Remark = obj.Remark;
    this.RemarkWorkSorting = obj.WorkSortingRemark;
    this.RemarkWorkStatus = obj.WorkStatusRemark;
    this.WorkAllotmentRemark=obj.WorkAllotmentRemark;
    this.FileList = obj.FileList;
    this._JobCardService.selectedJobCard.ModuleId=obj.ModuleId;
    this.getEmployeeList(obj.JONO, obj.Activity);
    this.loadSelectedWorkDetails();
  }

  downloadFileUrl(URL) {
    //window.open(IMG_ROOT_URL + 'Images/CustomerLogo/' + URL, '_blank');
    if (URL.Path == 'SoftwareReg') {
      window.open(IMG_ROOT_URL + 'Images/SoftwareRegistration/' + URL.File, '_blank');
    }
    else if (URL.Path == 'WorkStatus') {
      window.open(IMG_ROOT_URL + 'Images/WorkStatus/' + URL.File, '_blank');
    }
    else if (URL.Path == 'PLWORKALLOTMENT') {
      window.open(IMG_ROOT_URL + 'Images/ProviderWorkAllocation/' + URL.File, '_blank');
    }
  }
  deleteJobDetails(index) {
    this._JobCardService.selectedJobCard.JobDetails.splice(index, 1);
    // this._JobCardService.RowCount = this._JobCardService.allJobDetails.length;
  }

  addJobDetails() {
    if (this._JobCardService.newJobDetails.Date != null && this._JobCardService.newJobDetails.W_From.length > 0 && this._JobCardService.newJobDetails.W_To.length > 0
      && this._JobCardService.newJobDetails.W_To.length > 0 && this._JobCardService.newJobDetails.Status > 0) {
      this._JobCardService.newJobDetails.JONO = this._JobCardService.selectedJobCard.JONO
      this._JobCardService.newJobDetails.Emp_ID = this._JobCardService.selectedJobCard.Emp_ID
      this._JobCardService.newJobDetails.Activity = this._JobCardService.selectedJobCard.Activity
      // this._JobCardService.newJobDetails.Status = this._JobCardService.selectedJobCard.Status,
      this._JobCardService.newJobDetails.HoldingReason.Activity = this._JobCardService.selectedJobCard.Activity;
      this._JobCardService.newJobDetails.HoldingReason.Emp_ID = this._JobCardService.selectedJobCard.Emp_ID;
      this._JobCardService.newJobDetails.HoldingReason.JONO = this._JobCardService.selectedJobCard.JONO;
      this._JobCardService.newJobDetails.HoldingReason.Date = new Date();

      if(this._JobCardService.selectedJobCard.Designation==10)
      {
        const index=this._JobCardService.selectedJobCard.JobDetails.findIndex(e=>e.Status==2);
        if(index!=-1)
        {
          this.toastr.warning('Work is already finished.', 'Job Card');
          return;
        }
      }
      if(this._JobCardService.selectedJobCard.Designation==11)
      {
        const index=this._JobCardService.selectedJobCard.JobDetails.findIndex(e=>e.Status==6);
        if(index!=-1)
        {
          this.toastr.warning('Work is QA Finished', 'Job Card');
          return;
        }
        else
        {
          const index2=this._JobCardService.selectedJobCard.JobDetails.findIndex(e=>e.Status==5);
          if(index2!=-1 && this._JobCardService.newJobDetails.Status==3)
          {
            this.toastr.warning('Hold is not allowed after finishing the work', 'Job Card');
            return;
          }
        }
      }

      if (moment(this._JobCardService.newJobDetails.W_From, 'hh:mm:ss').isAfter(moment(this._JobCardService.newJobDetails.W_To, 'hh:mm:ss'))) {
        this.toastr.warning('Please check the time.', 'Job Card');
        return
      }
      this.StatusList.forEach(element => {
        if (element.Id == this._JobCardService.newJobDetails.Status) {
          this._JobCardService.newJobDetails.StatusText = element.Name;
        }
      });


      this._JobCardService.selectedJobCard.JobDetails = this._JobCardService.selectedJobCard.JobDetails == null ? [] : this._JobCardService.selectedJobCard.JobDetails
      this._JobCardService.newJobDetails.Date = new Date(this._JobCardService.convert(this._JobCardService.newJobDetails.Date));
      //Check Time Already exists
      var timeDuplicate = false;
      var time, beforeTime, afterTime;
      this._JobCardService.selectedJobCard.JobDetails.forEach(data => {
        if (this._JobCardService.convert(data.Date) == this._JobCardService.convert(this._JobCardService.newJobDetails.Date)) {
          time = moment(this._JobCardService.newJobDetails.W_From, 'hh:mm:ss'),
            beforeTime = moment(data.W_From, 'hh:mm:ss'),
            afterTime = moment(data.W_To, 'hh:mm:ss');

          if (time.isBetween(beforeTime, afterTime)) { timeDuplicate = true; }
          time = moment(this._JobCardService.newJobDetails.W_To, 'hh:mm:ss'),
            beforeTime = moment(data.W_From, 'hh:mm:ss'),
            afterTime = moment(data.W_To, 'hh:mm:ss');
          if (time.isBetween(beforeTime, afterTime)) { timeDuplicate = true; }
        }
      })

      //Time Validation Added By Renjith
      this._JobCardService.JobCardTimeValidation(this._JobCardService.newJobDetails)
        .subscribe(obj => {
          if (obj.Status == 1) {
            timeDuplicate = true
          }
          if (timeDuplicate) {
            this.toastr.warning('Duplicate Time.', 'Job Card');
            return
          }

          this._JobCardService.selectedJobCard.JobDetails.push(this._JobCardService.newJobDetails)
          this._JobCardService.newJobDetails = {
            ID: 0,
            JONO: null,
            Date: new Date(),
            Emp_ID: null,
            Activity: null,
            W_Date: new Date(),
            W_DateFrom: new Date(),
            W_DateTo: new Date(),
            W_From: '',
            W_To: '',
            Break: null,
            NextDay: false,
            Status: null, StatusText: '',
            Batch: 0,
            HoldingReason: { HoldingReason: null, Remark: '', Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: 0, ID: 0, JONO: null, MettingWith: 0, OtherEmergencyWork: null, Reason: '' }
          }

        })
    }
  }

  deleteNotes(index) {
    this._JobCardService.selectedJobCard.JobCardNotes.splice(index, 1);
  }

  addNotes() {
    if(this._JobCardService.newNotes.Date > new Date())
      {
        this.toastr.info('Date Mismatching '+ (this._CommonService.DateFormat(this._JobCardService.newNotes.Date,1)),'Job Card Notes')
        return;
      }
    if (this._JobCardService.newNotes.Notes.length > 0) {
      this._JobCardService.newNotes.JONO = this._JobCardService.selectedJobCard.JONO;
      this._JobCardService.newNotes.Emp_ID = this._JobCardService.selectedJobCard.Emp_ID;
      this._JobCardService.newNotes.Activity = this._JobCardService.selectedJobCard.Activity;
      this._JobCardService.selectedJobCard.JobCardNotes = this._JobCardService.selectedJobCard.JobCardNotes == null ? [] : this._JobCardService.selectedJobCard.JobCardNotes;
      this._JobCardService.newNotes.EmpName = 'You';
      this._JobCardService.selectedJobCard.JobCardNotes.unshift(this._JobCardService.newNotes);
      this._JobCardService.newNotes = {
        ID: 0,
        JONO: null,
        Date: new Date(),
        Emp_ID: null,
        Activity: null,
        Notes: '',
        EmpName: 'You',
        NoteTime: this._CommonService.getCurrentTime(new Date()),
      }
    }
  }

  onClickOptions(value: JobCard, mode) {
    this.fileToUpload = [];
    this._JobCardService.newJobDetails.Status = 0;
    this._JobCardService.newJobDetails.W_From = '';
    this._JobCardService.newJobDetails.W_To = '';
    this._JobCardService.newJobDetails.Break = null;
    this._JobCardService.newJobDetails.NextDay = false;

    this._JobCardService.selectedJobCard = {
      Emp_ID: this._JobCardService.selectedJobCard.Emp_ID,
      Designation: this._JobCardService.selectedJobCard.Designation,
      JobDetails: value.JobDetails,
      JobCardNotes: value.JobCardNotes,
      JobCardNotesAttachment: value.JobCardNotesAttachment,
      TestResults: value.TestResults,
      JONO: value.JONO,
      IssueDate: value.IssueDate,
      Activity: value.Activity,
      EstHrs: value.EstHrs,
      ExpStDate: value.ExpStDate,
      CompDate: value.CompDate,
      OverdueBy: value.OverdueBy,
      ModuleId:value.ModuleId,
      Module: value.Module,
      Particulars: value.Particulars,
      Status: value.Status,
      StatusText: value.StatusText,
      Description: value.Description,
      Remark: value.Remark,
      WorkSortingRemark: value.WorkSortingRemark,
      WorkStatusRemark: value.WorkStatusRemark,
      WorkAllotmentRemark:'',
      DealerId: value.DealerId,
      Vtype: value.Vtype,
      FileList: value.FileList,
      TesterConclusion: value.TesterConclusion,
      QAConclusion: value.QAConclusion,
      TesterSucceed: value.TesterSucceed,
      JobCardTestResultAttachments: value.JobCardTestResultAttachments,
      CustomerName: '',
      PriorityId: value.PriorityId,
      Type: value.Type,
      AssignedBy: value.AssignedBy,
      AttSE: value.AttSE,
      TestResult: value.TestResult,
      Attempt: value.Attempt,
      TResultWOutJobcard:value.TResultWOutJobcard,
      IsDue:false
    }
    let ModeValue = "";
    if (mode == 1) {
      ModeValue = 'TestResults'
      this.GetKeyWords();
    }
    else if (mode == 2)
      ModeValue = 'JobDetails'
    else if (mode == 3) {
      ModeValue = 'JobCardNotes'
      this._JobCardService.newNotes.NoteTime = this._CommonService.getCurrentTime(new Date());
    }

    this._JobCardService.GetJobCardOptionDtls(ModeValue, this._JobCardService.selectedJobCard.Emp_ID, this._JobCardService.selectedJobCard.JONO, this._JobCardService.selectedJobCard.Activity)
      .subscribe(data => {
        this._JobCardService.selectedJobCard.JobDetails = data.JobDetails;
        this._JobCardService.selectedJobCard.JobCardNotes = data.JobCardNotes;
        this._JobCardService.selectedJobCard.JobCardNotesAttachment = data.JobCardNotesAttachment;
        this._JobCardService.selectedJobCard.TestResults = data.TestResults;
        this._JobCardService.selectedJobCard.TesterConclusion = data.TesterConclusion;
        this._JobCardService.selectedJobCard.QAConclusion = data.QAConclusion;
        this._JobCardService.selectedJobCard.TesterSucceed = data.TesterSucceed;
        this._JobCardService.selectedJobCard.JobCardTestResultAttachments = data.JobCardTestResultAttachments;
        this.saveMode = ModeValue;
      })
  }

  //job card select(test result,job details,notes)
  GetJobCardOptionDtls() {
    this._CommonService.isLoading = true;
    this._JobCardService.GetJobCardOptionDtls(this.saveMode, this._JobCardService.selectedJobCard.Emp_ID, this._JobCardService.selectedJobCard.JONO, this._JobCardService.selectedJobCard.Activity)
      .subscribe(data => {
        this._CommonService.isLoading = false;
        this._JobCardService.selectedJobCard.JobDetails = data.JobDetails;
        this._JobCardService.selectedJobCard.JobCardNotes = data.JobCardNotes;
        this._JobCardService.selectedJobCard.JobCardNotesAttachment = data.JobCardNotesAttachment;
        this._JobCardService.selectedJobCard.TestResults = data.TestResults;
        this._JobCardService.selectedJobCard.TesterConclusion = data.TesterConclusion;
        this._JobCardService.selectedJobCard.QAConclusion = data.QAConclusion;
        this._JobCardService.selectedJobCard.TesterSucceed = data.TesterSucceed;
        this._JobCardService.selectedJobCard.JobCardTestResultAttachments = data.JobCardTestResultAttachments;
      },
        error => {
          this.errorMsg = error;
          this._CommonService.isLoading = false;
        });

  }
  checkDate() {
    if (new Date(this._JobCardService.newJobDetails.Date) > new Date()) {
      this.toastr.warning('Please check the date', 'Job Card');
      this._JobCardService.newJobDetails.Date = new Date();
    }
  }
  onChangeEmployee(event) {
    this.employeesWorkingOn = "";
    this._JobCardService.allJobCardDtl = []
    this.FileList = [];
    this.Description = ''; this.Remark = ''; this.RemarkWorkStatus = ''; this.RemarkWorkSorting = '';this.WorkAllotmentRemark='';
    this.SelectedIndex = null;
    this._JobCardService.selectedJobCard.Designation = event.Designation

    if (this._JobCardService.selectedJobCard.Designation == 10) {
      this.StatusList = this._CommonService.GetStatusFullProviderList();
    }
    else {
      this.StatusList = this._CommonService.GetTesterStatusList();
    }
    this.getAllJobs();
  }
  
  filterSelectedTestResultKeyword()
  {
    let id=0;
    this.KeyWordList.forEach(obj=>{
      if(obj.KeyWord==this.AddTestResultKeyWord)
        id=obj.ID
    })
    if(id==0)
      this.AddTestResultKeyWord=''
    else
      this.deleteTesterKeyWord(id);
  }

  deleteTesterKeyWord(id) {
    this._JobCardService.deleteTesterKeyword(id).subscribe(data => {
      if (data.Status == 0) {
        this.toastr.success(data.Message, 'Job Card');
        this.AddTestResultKeyWord=''
        this.GetKeyWords();
        this.getAllJobs();
      }
      else
        this.toastr.warning(data.Message, 'Job Card');
    })
  }

  // deleteTesterKeyWord(i, id) {
  //   if (id == 0) {
  //     this.KeyWordList.splice(i, 1);
  //   }
  //   else {
  //     this._JobCardService.deleteTesterKeyword(id).subscribe(data => {
  //       if (data.Status == 0) {
  //         this.toastr.success(data.Message, 'Job Card');
  //         this.getAllJobs();
  //       }
  //       else
  //         this.toastr.warning(data.Message, 'Job Card');
  //     })
  //   }
  // }

  onSubmitJobDetails(form: NgForm) {
    if (this.saveMode == "JobCardNotes") {
      if (this._JobCardService.newNotes.Notes.length > 0) {
        this.addNotes();
      }
    }
    if (this.saveMode == 'JobDetails') {
      if (this._JobCardService.selectedJobCard.JobDetails.length < 1)
        return;
    }
    this.onSubmit(this.saveMode)
  }

  DisableSubmit: boolean = false;
  onSubmit(mode) {
    // if (form.valid) {
    this.DisableSubmit = true;
    this._JobCardService.SaveJobCardDetails(this._JobCardService.selectedJobCard, this.fileToUpload, mode)
      .subscribe(data => {
        if (data.Status == 1) {
          this.toastr.success(data.Message, 'Job Card');
          this.getAllJobs();
        }
        else
          this.toastr.warning(data.Message, 'Job Card');
        // this.resetForm(form);
        this.GetJobCardOptionDtls();
        this.saveMode = '';
        this.DisableSubmit = false;
        this.AddTestResultKeyWord = "";
        this.AddTestResultKeyWord = "";
        this.fileToUpload = [];
        this._JobCardService.RemovedNotesFiles = [];
      },
        error => {
          this.errorMsg = error;
          this.DisableSubmit = false;
        })
  }
  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        //this._JobCardService.selectedJobCard.JobCardNotesAttachment.unshift({Activity:this._JobCardService.selectedJobCard.Activity,Attachment:files[i],Date})
        this.fileToUpload.push({ name: files[i].name, file: files[i] });
      }
    }
    if ($("#customFile")) {
      $("#customFile").val(null);
    }
  }

  closePopUp() {

    if (this.saveMode == 'HoldingReason' || this.saveMode == 'HoldingReasonView') {
      this.saveMode = 'JobDetails';
      this._JobCardService.newJobDetails.Status = this.PreStatusValue;
      this.PreStatusValue = null;
      this._JobCardService.newJobDetails.HoldingReason = { HoldingReason: null, Remark: '', Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: 0, ID: 0, JONO: null, MettingWith: 0, OtherEmergencyWork: null, Reason: '' }
    }
    else { this.saveMode = ''; }
    this.AddTestResultKeyWord = "";
    this.AddTestResultKeyWord = "";
    this._JobCardService.RemovedNotesFiles = [];
    this._JobCardService.newNotes.Notes = '';
  }

  onSubmitHoldReason() {
    if (this._JobCardService.newJobDetails.HoldingReason.Reason.length > 0 && this._JobCardService.newJobDetails.HoldingReason.Remark.length > 0 &&
      this._JobCardService.newJobDetails.HoldingReason.HoldingReason > 0 &&
      (this._JobCardService.newJobDetails.HoldingReason.HoldingReason == 5 || this._JobCardService.newJobDetails.HoldingReason.MettingWith > 0 || this._JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom > 0 || this._JobCardService.newJobDetails.HoldingReason.OtherEmergencyWork > 0)) {
      this.saveMode = 'JobDetails';
      this.PreStatusValue = null;
    }
  }

  PreStatusValue: number;
  ClarificationFrom: any;
  ChangeStatus(preStatus, CurrentStatus) {
    let checked = true
    if (CurrentStatus == 3) {
      this.OtherEmergencyWork = [];
      this.saveMode = 'HoldingReason';
      this.PreStatusValue = preStatus;

      this._JobCardService.allJobCardDtl.forEach(data => {
        // if (data.JONO != this._JobCardService.selectedJobCard.JONO &&
        //   !this.OtherEmergencyWork.some(ar => ar.JONO == data.JONO))
        //   this.OtherEmergencyWork.push(data);
        if (data.Activity != this._JobCardService.selectedJobCard.Activity &&
          !this.OtherEmergencyWork.some(ar => ar.Activity == data.Activity))
          this.OtherEmergencyWork.push(data);
      });
      this.ClarificationFrom = [];
      this._EmployeeService.Fetch_EmployeeById('JOBCARD', this._JobCardService.selectedJobCard.Activity).subscribe(data => {
        this.ClarificationFrom = data;
      },
        error => {
          this.errorMsg = error;
        });
    }
    if (CurrentStatus == 5 || CurrentStatus == 6) {
      if (this._JobCardService.selectedJobCard.TesterConclusion.Status == 0) {
        this.toastr.warning('Please Enter Test Result Status.', 'Job Card');
        this.closePopUp();
        checked = false;
      }
    }
    if (CurrentStatus == 6 && checked) {
      if (this._JobCardService.selectedJobCard.QAConclusion.Status == 0) {
        this.toastr.warning('Please Enter QA Conclusion Result.', 'Job Card');
        this.closePopUp();
      }
    }
  }

  getholdReason(JobDetails) {
    if (JobDetails.ID) {
      this.ClarificationFrom = [];
      this._EmployeeService.Fetch_EmployeeById('ELSE', this._JobCardService.selectedJobCard.Activity).subscribe(data => {
        this.ClarificationFrom = data;
      },
        error => {
          this.errorMsg = error;
        });

      this._JobCardService.getHoldReason(JobDetails.ID).subscribe(data => {
        this._JobCardService.newJobDetails.HoldingReason.MettingWith = data.MettingWith;
        this._JobCardService.newJobDetails.HoldingReason.HoldingReason = data.HoldingReason;
        this._JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom = data.ExpectingResultFrom;
        this._JobCardService.newJobDetails.HoldingReason.OtherEmergencyWork = data.OtherEmergencyWork;
        this._JobCardService.newJobDetails.HoldingReason.Remark = data.Remark;
        this._JobCardService.newJobDetails.HoldingReason.Reason = data.Reason;
        this.saveMode = 'HoldingReasonView';
      })
    }
  }
  // To add test results 
  AddTestResult() {
    if (this._JobCardService.selectedJobCard.TestResults == null) {
      this._JobCardService.selectedJobCard.TestResults = [];
    }
    if (this.AddTestResultKeyWord.length > 0 && this.AddTestResultDesc.length > 0 && this.BugId > 0) {
      let data: JobCardTestResult;
      data = {
        BatchNo: 0, isCleared:false,
        Activity: this._JobCardService.selectedJobCard.Activity,Description: this.AddTestResultDesc, ID: 0, JONO: this._JobCardService.selectedJobCard.JONO, Date: this._CommonService.DateFormat(new Date(),1),
        Emp_ID: this._JobCardService.selectedJobCard.Emp_ID, KeyWord: this.AddTestResultKeyWord,/* KeyWordID: 0,*/ EmpName: 'You', EditStatus: true,ClearStatus:0,TestResultId:0,BugId:this.BugId
      }
      this._JobCardService.selectedJobCard.TestResults.unshift(data);    
      this.AddTestResultKeyWord = this.AddTestResultDesc = "";
      this.BugId=0;
    }
  }

  RemoveTestResult(i) {
    this._JobCardService.selectedJobCard.TestResults.splice(i, 1);
  }
  
  // openkeyWordPopup() {
  //   this.AddNewKeyword = '';
  //   this.OpenkeyWordPopup = true;
  //   setTimeout(() => {
  //     var cntrl = document.getElementById('newKey');
  //     if (cntrl) {
  //       cntrl.focus();
  //     }
  //   }, 300);
  //}

  GetKeyWords() {
    this.AddNewKeyword = '';
    this._JobCardService.GetKeyWords(this._JobCardService.selectedJobCard.Activity)
      .subscribe(data => {
        this.KeyWordList = data;
      })
  }

  // SelectKeyWord(obj) {
  //   this.AddTestResultKeyWord = obj.KeyWord;
  //   this.OpenkeyWordPopup = false;
  // }

  AddNewkeyWord() {
    if (this.AddNewKeyword.length > 0) {
      if (!(this.KeyWordList.some(data => data.KeyWord == this.AddNewKeyword))) {
        this.KeyWordList.unshift({ ID: 0, KeyWord: this.AddNewKeyword })
        this.AddTestResultKeyWord = this.AddNewKeyword;
        // this.OpenkeyWordPopup = false;
      }
    }
  }

  ClearQaConclusion() {
    this._JobCardService.selectedJobCard.QAConclusion.Conclusion = "";
    this._JobCardService.selectedJobCard.QAConclusion.Status = 0;
  }

  checkTestResult()
  {
    if(this._JobCardService.selectedJobCard.TesterConclusion.Status==1)
    {  
      for (let index = 0; index < this._JobCardService.selectedJobCard.TestResults.length;index++) {
        if(!this._JobCardService.selectedJobCard.TestResults[index].isCleared && this._JobCardService.selectedJobCard.TestResults[index].BugId!=3)
        {
          this.toastr.warning('Some test results are not yet cleared','JobCard')
          this._JobCardService.selectedJobCard.TesterConclusion.Status=2;
          return;
        }
      }
    } 
  }

  TestResultFilesInput(event) {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        this._JobCardService.selectedJobCard.JobCardTestResultAttachments.unshift({
          Activity: this._JobCardService.selectedJobCard.Activity, Date: this._JobCardService.convert(new Date()), EmpName: 'You', ID: 0, JONO: this._JobCardService.selectedJobCard.JONO
          , Emp_ID: this._JobCardService.selectedJobCard.Emp_ID, File: files[i], FilePath: files[i].name,
          LocalFile: ''
        });
      }
      if ($("#testRFile")) {
        $("#testRFile").val(null);
      }
    }
  }

  RemoveTestResultFiles(i) {
    this._JobCardService.RemovedNotesFiles.push(this._JobCardService.selectedJobCard.JobCardTestResultAttachments[i].FilePath)
    this._JobCardService.selectedJobCard.JobCardTestResultAttachments.splice(i, 1);
  }

  DownloadTestResultFile(obj: JobCardTestResultAttachments) {
    if (obj.ID == 0) {
      //window.open(obj.File);
    }
    else 
      window.open(IMG_ROOT_URL + 'Images/JobCard/' + obj.FilePath);
  }

  DownloadNoteFile(obj) {
    window.open(IMG_ROOT_URL + 'Images/JobCard/' + obj.Attachment);
  }

  RemoveNoteFiles(i) {
    this._JobCardService.RemovedNotesFiles.push(this._JobCardService.selectedJobCard.JobCardNotesAttachment[i].Attachment);
    this._JobCardService.selectedJobCard.JobCardNotesAttachment.splice(i, 1);
  }

  RemoveNoteFilesBeforUpload(i) {
    this.fileToUpload.splice(i, 1);
  }

  getEmplist(e) {
    this.EmployeeList = [];
    this._JobCardService.allJobCardDtl = []
    this.FileList = [];
    this.Description = ''; this.Remark = ''; this.RemarkWorkStatus = ''; this.RemarkWorkSorting = '';this.WorkAllotmentRemark='';
    this.SelectedIndex = null;
    this._JobCardService.selectedJobCard.Emp_ID = null;
    if (e == 1)//DEV
    {
      this.EmployeeList = this._JobCardService.allEmployee.filter(arr => arr.Designation == 10);
    }
    else {//TEST
      this.EmployeeList = this._JobCardService.allEmployee.filter(arr => arr.Designation == 11);
    }
  }
  openDescriptionPopUp: boolean = false;
  getEmployeeList(JoNo, ActivityID) {
    this._JobCardService.GetWorkStatus(JoNo, ActivityID).subscribe(data => {
      this.employeesWorkingOn = '';
      let employee = [...new Set(data.map(item => item.EmpName))];
      employee.forEach(data => {
        this.employeesWorkingOn = this.employeesWorkingOn + data + " / "
      })
      this.employeesWorkingOn=this.employeesWorkingOn.slice(0,-3)
    })
  }
  
  periodChange(e: Event) {
    if (this._CommonService.period == 11)
      this.enabledates = false;
    else {
      this.enabledates = true;
      this.setDate(this._CommonService.period==null?1:this._CommonService.period);
    }
  }

   setDate(id: Number) {
    this._CommonService.GetDateByPeriod(id).subscribe(
      data=>{
        this.dDates=data;
        this._JobCardService.fromDate = new Date(this.dDates.FromDate);
        this._JobCardService.toDate = new Date(this.dDates.ToDate);
      }
    );
  }

  loadSelectedWorkDetails()
  {
    this._JobCardService.getSelectedWorkDetails(this._JobCardService.selectedJobCard.Emp_ID,this._JobCardService.selectedJobCard.ModuleId).subscribe(
      data=>{this._JobCardService.selectedWorkDoc=data},
      error => {
        this.errorMsg = error;
      })
  }

  viewDocument(Id:any)
  {
    if(Id > 0)
    {
      this._JobCardService.selectedWorkDoc.filter(e=>{
        if(e.Id==Id)
          this.SelectedGDrivePath=e.GDrivePath
      })
      window.open(this.SelectedGDrivePath,"_blank");
    }
  }

  ngOnDestroy()
  {
    this.setDate(1);
  }
}