import { Injectable } from '@angular/core';
import { dealer } from '../Models/dealer.Model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { GlobalErrorHandlerService } from './global-error-handler.service'

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  selectedDealer: dealer;
  dealerList: dealer[];
  formHideShow = 0;
  formHidePassword = false;
  allDealers: dealer[];
  DealerId: string
  public imageUrl: string = "";
  pageIndex: number = 0;
  isFiltering: boolean = false;
  DealerCodeFilter: string = '';
  DealerNameFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  UploadImage(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload, fileToUpload.name);
    return this.http.post<dealer>(ROOT_URL + 'Dealer/UploadImage', formData)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }
  ClearFilter() {
    this.isFiltering = false;
    this.DealerCodeFilter = '';
    this.DealerNameFilter = '';

  }
  postDealer(dealr: dealer) {
    console.log('post')
    var body = {
      CountryId: dealr.CountryId,
      DealerName: dealr.DealerName,
      DealerCode: dealr.DealerCode,
      Addr1: dealr.Addr1,
      Addr2: dealr.Addr2,
      Addr3: dealr.Addr3,
      DealerEmail: dealr.DealerEmail,
      DealerPhone: dealr.DealerPhone,
      DealerFax: dealr.DealerFax,
      ContactPerson: dealr.ContactPerson,
      ContactPhone: dealr.ContactPhone,
      ContactEmail: dealr.ContactEmail,
      URL: dealr.URL,
      LogoURL: dealr.LogoURL,
      Username: dealr.Username,
      Password: dealr.Password
    }
    return this.http.post<dealer>(ROOT_URL + 'Dealer/PostDealer', body)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }
  putDealer(dealr: dealer) {
    console.log('put')
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      CountryId: dealr.CountryId,
      DealerName: dealr.DealerName,
      DealerCode: dealr.DealerCode,
      Addr1: dealr.Addr1,
      Addr2: dealr.Addr2,
      Addr3: dealr.Addr3,
      DealerEmail: dealr.DealerEmail,
      DealerPhone: dealr.DealerPhone,
      DealerFax: dealr.DealerFax,
      ContactPerson: dealr.ContactPerson,
      ContactPhone: dealr.ContactPhone,
      ContactEmail: dealr.ContactEmail,
      URL: dealr.URL,
      LogoURL: dealr.LogoURL,
      Username: dealr.Username,
      Password: dealr.Password,

      ID: dealr.Id
    }

    return this.http.put<dealer>(ROOT_URL + 'Dealer/PutDealer/', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  FetchDealer(TransMode: string): Observable<dealer[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<dealer[]>(ROOT_URL + 'Dealer/FetchDealer', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  FetchDealerById(TransMode: string, Id: number): Observable<dealer[]> {
    let params = new HttpParams();
    params = params.append('Id', Id.toString()).append('TransMode', TransMode)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<dealer[]>(ROOT_URL + 'Dealer/FetchDealerById', {
      headers, params
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );;
  }

  deleteDealer(DeleteID: number) {
    return this.http.delete<any>(ROOT_URL + 'Dealer/DeleteDealer?UserId=' + DeleteID)
  }
}
