import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { LeaveApplicationService } from 'src/app/Services/leave-application.service';
import { ToastrService } from 'ngx-toastr';
import { LeaveApplicationApprover } from 'src/app/Models/LeaveApplication';
import { LeavedetailsService } from 'src/app/Services/leavedetails.service';
@Component({
  selector: 'app-leave-application-form',
  templateUrl: './leave-application-form.component.html',
  styleUrls: ['./leave-application-form.component.css']
})
export class LeaveApplicationFormComponent implements OnInit {

  constructor(public _CommonService: CommonService, 
              public _LeaveApplicationService: LeaveApplicationService,
              private toastr: ToastrService, 
              public _LeavedetailsService: LeavedetailsService) { }

  LoginedUserId: number;
  ngOnInit(): void {
    this._LeaveApplicationService.GetData();
    this.GetLeaveTypes();
    this.GetLoginId()
  }

  async GetLoginId() {
    this.LoginedUserId = parseInt(await this._CommonService.getLoginedUserId())
  }

  backList() {
    this._LeaveApplicationService.IsForm = false;
    this._LeaveApplicationService.ClearValue();
  }

  formValidation(): boolean {
    let result = true;
    if (this._LeaveApplicationService.SelectedData.Date != null && this._LeaveApplicationService.SelectedData.FromDate != null && this._LeaveApplicationService.SelectedData.ToDate != null) {
      if (this._LeaveApplicationService.SelectedData.EmpID > 0 && this._LeaveApplicationService.SelectedData.LeaveTypeID > 0 && this._LeaveApplicationService.SelectedData.Type > 0
        && this._LeaveApplicationService.SelectedData.ToDate.toString().length > 0 && this._LeaveApplicationService.SelectedData.FromDate.toString().length > 0
        && this._LeaveApplicationService.SelectedData.FromDate.toString() != 'Invalid date' && this._LeaveApplicationService.SelectedData.ToDate.toString() != 'Invalid date') {
        result = true;
      }
      else {
        result = false;
      }
    }
    else {
      result = false;
    }
    return result;
  }

  onSubmit(form: NgForm) { 
    if (this.formValidation()) {
      this._LeaveApplicationService.SubmitLeaveApplication(this._LeaveApplicationService.SelectedData).subscribe(data => {
        if (data.Status == 0) {
          this.toastr.success(data.Message, 'Leave Application');
          this._LeaveApplicationService.ClearValue();
          this.GetAllLeaveRequest();
          this._LeaveApplicationService.IsForm = false;
        }
        else {
          this.toastr.warning(data.Message, 'Leave Application');
        }
      });
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Leave Application');
    }
  }

  resetForm(form?: NgForm) {
    if (this._LeaveApplicationService.SelectedData.Status == 0) {
      this._LeaveApplicationService.SelectedData.Designation = '';
      this._LeaveApplicationService.SelectedData.LeaveTypeID = 0;
      this._LeaveApplicationService.SelectedData.Type = 1;
      this._LeaveApplicationService.SelectedData.FromDate = new Date();
      this._LeaveApplicationService.SelectedData.ToDate = new Date();
    }
  }

  GetAllLeaveRequest() {
    let employeeId=0
    if(this._LeaveApplicationService.UserRole == 'Developers & Designers' ||this._LeaveApplicationService.UserRole == 'Testers')
      employeeId=this.LoginedUserId;
    this._LeaveApplicationService.GetAllLeaveRequest(employeeId).subscribe(data => {
      this._LeaveApplicationService.DataList = data;
    });
  }

  onApprover(data: LeaveApplicationApprover) {
    data.Status = 1;
    data.ResponsDate = new Date().toLocaleDateString();
  }

  onReject(data) {
    data.Status = 2;
    data.ResponsDate = new Date().toLocaleDateString();
  }

  GetWorkSchedule(cntrl) {
    if (this.CheckDates(cntrl)) {
      if (this._LeaveApplicationService.SelectedData.FromDate != null && this._LeaveApplicationService.SelectedData.ToDate != null) {
        this._LeaveApplicationService.GetWorkSchedule(this.LoginedUserId, this._LeaveApplicationService.SelectedData.FromDate, this._LeaveApplicationService.SelectedData.ToDate).subscribe(
          data => {
            this._LeaveApplicationService.LeaveEmpWorkScheduleList = data;
          }
        );
      }
    }
  }

  GetLeaveTypes() {
    this._LeavedetailsService.getLeaveDetails("ALL")
      .subscribe(data => {
        this._LeaveApplicationService.leaveTypeList = data
      },
        error => {
        })
  }

  CheckDates(cntrl) {
    let result = true;
    if (this._LeaveApplicationService.SelectedData.FromDate.toString().length > 0 && this._LeaveApplicationService.SelectedData.ToDate.toString().length > 0) {
      if (this._LeaveApplicationService.SelectedData.FromDate > this._LeaveApplicationService.SelectedData.ToDate) {
        if (cntrl == 'frm') {
          this._LeaveApplicationService.SelectedData.FromDate = null;
        }
        else 
          this._LeaveApplicationService.SelectedData.ToDate = null;
        result = false;
        this.toastr.info('Please Check The Date.', 'Leave Application');
      }
    }
    return result;
  }
}
