import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DesignationService } from 'src/app/Services/designation.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { designation } from 'src/app/Models/designation.model';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-designation-list',
  templateUrl: './designation-list.component.html',
  styleUrls: ['./designation-list.component.css']
})
export class DesignationListComponent implements OnInit {

  public term: any = '';
  public errorMsg;
  constructor(public _desigServive: DesignationService, public _CommonService: CommonService, public router: Router, public toastr: ToastrService) { }

  ngOnInit() {
    this._desigServive.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._desigServive.getDesignation()
      .subscribe(data => this._desigServive.allDesignations = data,
        error => {
          this.errorMsg = error;
        })
    this._desigServive.formHideShow = 0;
  }
  toggleFilter() {
    this._desigServive.isFiltering = this._desigServive.isFiltering ? false : true;
    if (!this._desigServive.isFiltering) {
      this._desigServive.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._desigServive.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._desigServive.orderByColName) {
      this._desigServive.orderByDir = !this._desigServive.orderByDir;
    }
    this._desigServive.orderByColName = colName;
  }
  //sorting
  // key: string = 'name'; //set default
  // reverse: boolean = false;
  // sort(key) {
  //   this.key = key;
  //   this.reverse = !this.reverse;
  // }
  // //initializing p to one
  // p: number = 1;
  NoOfItems: number = 10;

  addList() {
    this._desigServive.formHideShow = 1;

  }
  showForEdit(cntry: designation) {
    this._desigServive.selectedDesignation = Object.assign({}, cntry);;
    this._desigServive.formHideShow = 1;

  }
  onDelete(cntry: designation) {
    if (confirm('Are you sure to delete this record ?') == true) {
      this._desigServive.deleteDesignation(cntry)
        .subscribe(x => {
          this._desigServive.getDesignation().subscribe(data => this._desigServive.allDesignations = data,
            error => {
              this.errorMsg = error;
            });
          this.toastr.success('Deleted Successfully', "Designation");
        })
    }
  }


}
