import { error } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, JsonpClientBackend } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { OtherCharges,AddtionalModuleList } from '../Models/OtherCharges';
import { Common, Response } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class OtherChargesService {

  selectedOtherCharges:OtherCharges;
  addtionalModuleList:AddtionalModuleList[];
  allBatch:Common[]=[];

  constructor(private http: HttpClient,private globalErrorHandlerService:GlobalErrorHandlerService) { }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  getBranchWiseModule(TransMode:string,DealerId:number,CustomerId:number)
  {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params = new HttpParams().append('TransMode',TransMode)
    params= params.append('DealerId',DealerId.toString())
    params = params.append('CustomerId',CustomerId.toString())
    return this.http.get<AddtionalModuleList[]>(ROOT_URL + 'OtherCharges/GetBranchWiseAllModule',{
      headers,params
    })
      .pipe(
        catchError((error) => this.globalErrorHandlerService.handleError(error))
      );
  }

  saveOtherCharge(charges:OtherCharges,AddtionalModuleList:AddtionalModuleList[]):Observable<Response>{
    const body={
      Id:charges.Id,
      VNo:charges.VNo,
      Date:this.convert(new Date(charges.Date)),
      DealerId:charges.DealerId,
      CustomerId:charges.CustomerId,
      BranchId:charges.BranchId,
      ChargeTypeId:charges.ChargeTypeId,
      Charge:charges.Charge,
      NoOfNodesOrCharacter:charges.NoOfNodesOrCharacter,
      Remark:charges.Remark,
      BatchId:charges.BatchId??0,
      AddtionalModuleList:AddtionalModuleList
    }
    return this.http.post<Response>(ROOT_URL + 'OtherCharges/OtherCharges_Trans',body)
      .pipe(
        catchError((error)=> this.globalErrorHandlerService.handleError(error))
      )
  }

  deleteOtherCharge()
  {
    const headers = new HttpHeaders().set('content-type','application/json');
    let params=new HttpParams();
    params=params.append('OtherChargeId',this.selectedOtherCharges.Id.toString())
    params=params.append('Vno',this.selectedOtherCharges.VNo.toString())
    params=params.append('NoOfNodesOrCharacter',this.selectedOtherCharges.NoOfNodesOrCharacter.toString())
    return this.http.delete<Response>(ROOT_URL +'OtherCharges/DeleteOtherCharge', {
      headers,params
    })
      .pipe(
        catchError((error) => this.globalErrorHandlerService.handleError(error))
      );
  }

  getOtherCharge(VNo: number): Observable<OtherCharges> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params= new HttpParams().set('Vno',VNo.toString())
    return this.http.get<OtherCharges>(ROOT_URL + 'OtherCharges/GetOtherCharge',{
      headers,params
    })
      .pipe(
        catchError((error) => this.globalErrorHandlerService.handleError(error))
      );
  }
}
