<div class="container-fluid" *ngIf="_ParticularmasterService.formHideShow!=0">
    <h2 class="heading_all">PARTICULAR MASTER</h2>
    <br>
    <div id="form-wrap">
        <form #particularmaster="ngForm" (ngSubmit)="onSubmit(particularmaster)">
            <!-- <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_prjctService.selectedProject.Id"> -->
            <!-- <div class="row FormHeader sim-button_head">
                    <h3>Particular Master</h3>
                </div> -->
            <div class="col-md-12">
                <div class="form-group">
                    <label>Module<span style="color: red;">*</span></label>

                    <select class="form-control" name="ModuleID" #ModuleID="ngModel"
                        [(ngModel)]="_ParticularmasterService.selectedRecord.ModuleID" required>
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let module of _modulService.allModules | orderBy : 'ModuleName'"
                            [ngValue]="module.Id">
                            {{ module.ModuleName }}
                        </option>
                    </select>
                    <div class="validation-error"
                        *ngIf="(ModuleID.invalid && ModuleID.touched)||(ModuleID.invalid && this.FormValidation)">This
                        Field is
                        Required.</div>
                </div>
                <div class="form-group">
                    <label>Particulars<span style="color: red;">*</span></label>

                    <input name="Particulars" #Particulars="ngModel" autocomplete="off"
                        [(ngModel)]="_ParticularmasterService.selectedRecord.Particulars" placeholder=" Particulars"
                        required>
                    <div class="validation-error"
                        *ngIf="(Particulars.invalid && Particulars.touched)||(Particulars.invalid && this.FormValidation)">
                        This Field is
                        Required.</div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" class="sim-button button2" style="width: 99%; margin-top: 25px;"
                            (click)="resetForm(particularmaster)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" class="sim-button button3" style="width: 99%; margin-top: 25px;"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- </div> -->
</div>