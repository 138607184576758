import { Component, OnInit } from '@angular/core';
import { ReportsService } from './../../../../../Services/reports.service';
import { CommonService } from 'src/app/Services/common.service';
import { ExporttoexcelService } from '../../../../../Services/exporttoexcel.service';
import { DealerService } from 'src/app/Services/dealer.service';
@Component({
  selector: 'app-outstanding',
  templateUrl: './outstanding.component.html',
  styleUrls: ['./outstanding.component.css']
})
export class OutstandingComponent implements OnInit {

  public age:number=0
  public errorMsg;
  constructor(public reports:ReportsService,
              public commonService:CommonService,
              public exporttoexcelService:ExporttoexcelService,
              public dealerService: DealerService) { }

  ngOnInit(): void {
    this.loadDealer();
    this.commonService.GetVoucherType();    
  }

  loadDealer() {
    this.dealerService.FetchDealer('DEALERIDWISE')
      .subscribe(
        data => {
          this.dealerService.allDealers = data; 
          if(this.dealerService.allDealers!=undefined && this.dealerService.allDealers.length==1)
            this.reports.selectedDealerId= this.dealerService.allDealers[0].Id;
          else
            this.reports.selectedDealerId=0;
            this.customerOutstanding();
        },error => {
          this.errorMsg = error;
        });
  }

  customerOutstanding()
  {
    this.commonService.isLoading = true;
    this.reports.getCustomerOutstanding(this.age,this.commonService.selectedVoucherType).subscribe(
      data=>{
        this.commonService.isLoading = false;
        this.reports.customerOutstanding=data;
      },
      error => {
        this.errorMsg = error;
        this.commonService.isLoading = false;
      });
  }
}
