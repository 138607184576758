<div class="container-fluid">
    <h2 class="heading_all">Service Charge Voucher</h2>
    <div id="form-wrap">
        <form action="#" #ServiceChargeForm="ngForm" (ngSubmit)="onSubmit(ServiceChargeForm)">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label style="padding-top: 15px;">VNo:</label>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control" style="width:180px;" name="VNo" id="VNo" #VNo="ngModel" autocomplete="off" [(ngModel)]="_ServicechargeService.selectedServiceCharge.VNo" placeholder="VNo" (keypress)="this._CommonService.numberValidation($event)" required>
                            </div>
                            <div class="validation-error" *ngIf="(VNo.invalid && VNo.touched)||(VNo.invalid && this.FormValidation)">
                                This Field is Required.
                            </div>
                            <div class="col-md-6">
                                <i class="fas fa-search searchicon" (click)="searchServiceCharge()" aria-hidden="true"></i>
                            </div>
                        </div>                 
                    </div>
                    <div class="form-group">
                        <label>Dealer</label>
                        <select class="form-control" name="DealerId" #DealerId="ngModel" (ngModelChange)="loadDealerWiseCustomers()" [disabled]="_ServicechargeService.selectedServiceCharge.DemandedServiceCharge > 0"
                            [(ngModel)]="_ServicechargeService.selectedServiceCharge.DealerId" required>
                            <option [ngValue]="null">Choose Dealer</option>
                            <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'" [ngValue]="prf.Id">
                                {{ prf.DealerName }}
                            </option>
                        </select>
                        <div class="validation-error" *ngIf="DealerId.invalid && this.FormValidation">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Customer<span class="validation-error">*</span></label>
                        <ng-select name="CustomerId" id="CustomerId" bindLabel="Name" #CustomerId="ngModel" [disabled]="_ServicechargeService.selectedServiceCharge.DealerId==null || _ServicechargeService.selectedServiceCharge.DemandedServiceCharge > 0"
                            [(ngModel)]="_ServicechargeService.selectedServiceCharge.CustomerId" bindValue="Id"
                            [items]="_custService.allCustomers  | orderBy : 'Name'" required
                            (ngModelChange)="loadCustomerWiseBranch()">
                        </ng-select>
                        <div class="validation-error" *ngIf="CustomerId.invalid && this.FormValidation">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="select">Branch<span class="validation-error">*</span></label>
                        <ng-select name="BranchId" id="BranchId" bindLabel="BranchName" #BranchId="ngModel"  [disabled]="_ServicechargeService.selectedServiceCharge.CustomerId==null || _ServicechargeService.selectedServiceCharge.DemandedServiceCharge > 0"
                            [(ngModel)]="_ServicechargeService.selectedServiceCharge.BranchId" bindValue="Id"
                            [items]="_brnchService.allBranchs | orderBy : 'BranchName'" required>
                        </ng-select>
                        <div class="validation-error" *ngIf="(BranchId.invalid && BranchId.touched)||(BranchId.invalid && this.FormValidation)">
                            This Field is Required.
                        </div>
                    </div>            
                    <div class="form-group">
                        <label>Remark:<span class="validation-error">*</span></label>
                        <textarea class="form-control" name="Remark" autocomplete="off" #Remark="ngModel" [(ngModel)]="_ServicechargeService.selectedServiceCharge.Remark" placeholder=" Remark" required></textarea>        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label style="padding-top: 15px;">Date:<span class="validation-error">*</span></label>
                        <input class="form-control" placeholder="Date" name="Date" id="Date" #Date="ngModel" [(ngModel)]="_ServicechargeService.selectedServiceCharge.Date" style="color: black;text-align:left;width:180px;" autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        <div style="padding-bottom:35px;" *ngIf="this._ServicechargeService.selectedServiceCharge.Id==0">
                            <button style="color:white;background-color:dimgray;" type="button" (click)="getServiceChargeData()" class="sim-button button1" aria-hidden="true">Search</button> 
                        </div>
                            <div class="validation-error" *ngIf="Date.invalid && this.FormValidation">
                                This Field is Required.
                            </div>                        
                    </div>
                    <div class="form-group">
                        <label>Demanded Service Charge:<span class="validation-error">*</span></label>
                        <input class="form-control inputwidth"  name="DemandedServiceCharge" autocomplete="off" disabled #DemandedServiceCharge="ngModel"  [(ngModel)]="_ServicechargeService.selectedServiceCharge.DemandedServiceCharge" placeholder=" Demanded Service Charge" required>
                            <div class="validation-error" *ngIf="(DemandedServiceCharge.invalid && DemandedServiceCharge.touched)||(DemandedServiceCharge.invalid && this.FormValidation)">
                                This Field is Required.
                            </div>
                    </div>
                    <div class="form-group">
                        <label>Against RefNo:<span class="validation-error">*</span></label>
                        <input class="form-control" name="AgainstRefno" autocomplete="off" #AgainstRefno="ngModel" disabled [(ngModel)]="_ServicechargeService.selectedServiceCharge.AgainstRefno" placeholder=" Against RefNo" required>
                            <div class="validation-error" *ngIf="AgainstRefno.invalid && this.FormValidation">
                                This Field is Required.
                            </div>
                    </div>
                    <div class="form-group">
                        <label>Approved Service Charge:<span class="validation-error">*</span></label>
                        <input class="form-control" name="ApprovedServiceCharge" autocomplete="off" #ApprovedServiceCharge="ngModel" [(ngModel)]="_ServicechargeService.selectedServiceCharge.ApprovedServiceCharge" (keypress)="this._CommonService.decimalPointValidation($event)" placeholder=" Approved Service Charge" required>
                            <div class="validation-error" *ngIf="ApprovedServiceCharge.invalid && this.FormValidation">
                                This Field is Required.
                            </div>
                    </div>                                
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width:100%;" type="submit" [disabled]="this.isSubmit" class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button [disabled]="this._ServicechargeService.selectedServiceCharge.Id==0" style="width:100%;" type="button" class="sim-button button1"
                        (click)="deleteServiceCharge()">Delete</button>
                </div>
                <div class="col-md-4">
                    <button style="width:100%;" type="button" class="sim-button button2" (click)="resetForm(ServiceChargeForm)">Reset</button>
                </div>
            </div>
            
            <div class="container-fluid">
                <!-- The Modal -->
                <div class="modal fade show" id="myModalConfirm" >
                    <div class="modal-dialog">
                        <div *ngIf="serviceChargeForm">
                            <!--class="modal-dialog" -->
                            <div class="modal-content">
                                <!-- Modal Header -->
                                <div class="modal-header">
                                    <span class="modal-title">Service Charges</span>
                                    <button type="button" class="close" (click)="closePopUp()">×</button>
                                </div>
                               <div class="modal-body" [ngStyle]="{'height':_ServicechargeService.allServiceCharge.length > 2?'400px':'230px'}">
                                    <div class="tab-content">
                                    <div class="row" *ngIf="_ServicechargeService.listVisible==1" id="listVisible">
                                        <div class="table-responsive">
                                        <div class="tWrap__head">
                                            <table>
                                                <thead>
                                                <tr> 
                                                    <th style="text-align:center;">
                                                        <input *ngIf="this._ServicechargeService.selectedServiceCharge.DealerId > 0 && this._ServicechargeService.selectedServiceCharge.CustomerId > 0" type="checkbox" name="chkall" id="chkall" #chkall="ngModel" [(ngModel)]="this._CommonService.chkSelectAll" (ngModelChange)="selectAllServiceCharge()">
                                                    </th>                                                
                                                 <th class="hidden-xs" >
                                                    <div class="AtjIsPoint" (click)="sort('Date')">
                                                        <span *ngIf="!_ServicechargeService.isFiltering">Date</span>
                                                        <div class="AtjfloatRight">
                                                            <i class="fas fa-sort" *ngIf="_ServicechargeService.orderByColName!='Date' "></i>
                                                            <div *ngIf="_ServicechargeService.orderByColName=='Date'">
                                                                <i class="fas fa-sort-amount-down" *ngIf="_ServicechargeService.orderByDir"></i>
                                                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_ServicechargeService.orderByDir"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="_ServicechargeService.isFiltering">
                                                        <input type="text" #txtDateFilter="ngModel" [(ngModel)]="_ServicechargeService.DateFilter"
                                                        id="DateFilter" name="DateFilter"   placeholder="Date">
                                                    </div>          
                                                </th>
                                                <th class="hidden-xs">
                                                    <div class="AtjIsPoint" (click)="sort('DealerName')">
                                                        <span *ngIf="!_ServicechargeService.isFiltering">Dealer</span>
                                                        <div class="AtjfloatRight">
                                                            <i class="fas fa-sort" *ngIf="_ServicechargeService.orderByColName!='DealerName' "></i>
                                                            <div *ngIf="_ServicechargeService.orderByColName=='DealerName'">
                                                                <i class="fas fa-sort-amount-down" *ngIf="_ServicechargeService.orderByDir"></i>
                                                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_ServicechargeService.orderByDir"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="_ServicechargeService.isFiltering">
                                                        <input type="text" #txtDealerNameFilter="ngModel" [(ngModel)]="_ServicechargeService.DealerFilter"
                                                        id="DealerFilter" name="DealerFilter"      placeholder="Dealer">
                                                    </div>
                            
                                                </th>
                                                <th class="hidden-xs">
                                                    <div class="AtjIsPoint" (click)="sort('CustomerName')">
                                                        <span *ngIf="!_ServicechargeService.isFiltering">Customer</span>
                                                        <div class="AtjfloatRight">
                                                            <i class="fas fa-sort" *ngIf="_ServicechargeService.orderByColName!='CustomerName' "></i>
                                                            <div *ngIf="_ServicechargeService.orderByColName=='CustomerName'">
                                                                <i class="fas fa-sort-amount-down" *ngIf="_ServicechargeService.orderByDir"></i>
                                                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_ServicechargeService.orderByDir"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="_ServicechargeService.isFiltering">
                                                        <input type="text" #txtCustomerNameFilter="ngModel" [(ngModel)]="_ServicechargeService.CustomerFilter"
                                                        id="CustomerFilter" name="CustomerFilter"       placeholder="Customer">
                                                    </div>
                            
                                                </th>
                                                <th class="hidden-xs">
                                                    <div class="AtjIsPoint" (click)="sort('BranchName')">
                                                        <span *ngIf="!_ServicechargeService.isFiltering">Branch</span>
                                                        <div class="AtjfloatRight">
                                                            <i class="fas fa-sort" *ngIf="_ServicechargeService.orderByColName!='BranchName' "></i>
                                                            <div *ngIf="_ServicechargeService.orderByColName=='BranchName'">
                                                                <i class="fas fa-sort-amount-down" *ngIf="_ServicechargeService.orderByDir"></i>
                                                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_ServicechargeService.orderByDir"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="_ServicechargeService.isFiltering">
                                                        <input type="text" #txtBranchNameFilter="ngModel" [(ngModel)]="_ServicechargeService.BranchFilter"
                                                        id="BranchFilter" name="BranchFilter"        placeholder="Branch">
                                                    </div>
                            
                                                </th>
                                                <th class="hidden-xs">
                                                    <div class="AtjIsPoint" (click)="sort('RefNoType')">
                                                        <span *ngIf="!_ServicechargeService.isFiltering">Reg.No</span>
                                                        <div class="AtjfloatRight">
                                                            <i class="fas fa-sort" *ngIf="_ServicechargeService.orderByColName!='RefNoType' "></i>
                                                            <div *ngIf="_ServicechargeService.orderByColName=='RefNoType'">
                                                                <i class="fas fa-sort-amount-down" *ngIf="_ServicechargeService.orderByDir"></i>
                                                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_ServicechargeService.orderByDir"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="_ServicechargeService.isFiltering">
                                                        <input type="text" #txtRefNoTypeFilter="ngModel" [(ngModel)]="_ServicechargeService.RegNoFilter"
                                                        id="RegNoFilter" name="RegNoFilter"     placeholder="Reg.No">
                                                    </div>
                            
                                                </th>
                                                <th class="hidden-xs">Amount                                           
                                                    <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                                        <i *ngIf="!_ServicechargeService.isFiltering" class="fas fa-filter"></i>
                                                        <i *ngIf="_ServicechargeService.isFiltering" class="fas fa-times"></i>
                                                    </a>
                                                </th>                                  
                                            </tr>
                                        </thead>
                                    </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>                             
                                            <tr *ngFor="let service of _ServicechargeService.allServiceCharge | gridOperations  :_ServicechargeService.pageIndex: 
                                                {Date:_ServicechargeService.DateFilter,DealerName:_ServicechargeService.DealerFilter,CustomerName:_ServicechargeService.CustomerFilter
                                                ,BranchName:_ServicechargeService.BranchFilter,RefNoType:_ServicechargeService.RegNoFilter,Amount:_ServicechargeService.AmountFilter}:
                                                {Column:_ServicechargeService.orderByColName,Dir:_ServicechargeService.orderByDir} ; let i = index"
                                                class="ng-scope">
                                            <td style="text-align:center;" class="checkbox"><input type="checkbox" name="CheckStatus" #CheckStatus="ngModel"  [(ngModel)]="service.CheckStatus"
                                               (ngModelChange)="selectedSerivceCharge(service)" [attr.disabled]="service.CheckboxPrivilege">
                                            </td>
                                            <td>{{service.Date}}</td>
                                            <td title="{{service.DealerName}}">{{service.DealerName}}</td>
                                            <td title="{{service.CustomerName}}">{{service.CustomerName}}</td>
                                            <td title="{{service.BranchName}}">{{service.BranchName}}</td>
                                            <td>{{service.RefNoType}}</td>
                                            <td>{{service.Amount}}</td>
                                       </tr>
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" style="text-align:center;"> 
                    <button type="submit"  class="sim-button button1" (click)="loadSelectedServiceCharge()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="WarningModel">
                <!--class="modal-dialog" -->
        <div class="modal-content" style="width: 62%;">
            <!-- Modal Header -->
            <div class="modal-header">
                <!-- <span class="modal-title">Job Order</span> -->
                <button type="button" class="close" (click)="closePopUp()">×</button>
            </div>
        </div>
    </div>
    </div>
    </div>
</div>
</form>
</div>

</div>