import { Component, OnInit } from '@angular/core';
import { AmcRenewalService } from 'src/app/Services/amc-renewal.service';
import { ModuleService } from 'src/app/Services/module.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/Models/constants';
import { AMCRenewal } from 'src/app/Models/AMCRenewal.model';
import { SoftwareRegistrationService } from 'src/app/Services/software-registration.service';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import * as CryptoJS from 'crypto-js';
import { ProjectService } from 'src/app/Services/project.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-amc-renewal-list',
  templateUrl: './amc-renewal-list.component.html',
  styleUrls: ['./amc-renewal-list.component.css']
})
export class AmcRenewalListComponent implements OnInit {


  public errorMsg;
  public term: string = ''
  // DealerId: string = '0'
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  constructor(public _AmcRenewalService: AmcRenewalService, 
              public _CommonService: CommonService, 
              public _ModuleService: ModuleService, 
              public _projectService: ProjectService,
              public _custService: CustomerService, 
              public _swRegService: SoftwareRegistrationService, 
              public _nodeRegService: NodeRegistrationService,
              public _brnchService: BranchService, 
              private toastr: ToastrService) { }


  ngOnInit(): void {
    this._AmcRenewalService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.GetAllAMCRenewal();
    this._AmcRenewalService.formHideShow = 0;
  }

  GetAllAMCRenewal() {
    this._AmcRenewalService.getAMCRenewal()
      .subscribe(data => {
        this._AmcRenewalService.allAMCRenewals = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  toggleFilter() {
    this._AmcRenewalService.isFiltering = this._AmcRenewalService.isFiltering ? false : true;
    if (!this._AmcRenewalService.isFiltering) {
      this._AmcRenewalService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._AmcRenewalService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._AmcRenewalService.orderByColName) {
      this._AmcRenewalService.orderByDir = !this._AmcRenewalService.orderByDir;
    }
    this._AmcRenewalService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  addList() {
    this._AmcRenewalService.imageUrl = AppConstants.tockenURL + "Images/Logo/default.jpg";
    this._ModuleService.getModuleCheckBox()
      .subscribe(data => {
        this._ModuleService.allModulesCheckBox = data
      },
        error => {
          this.errorMsg = error;
        })

    this._AmcRenewalService.formHideShow = 1;
    this._AmcRenewalService.formHidePassword = false;
  }

  showForEdit(swReg: AMCRenewal) {
    this._AmcRenewalService.selectedAMCRenewal = Object.assign({}, swReg);
    this._brnchService.Fetch_BranchById(this._AmcRenewalService.selectedAMCRenewal.CustomerId == null ? 0 : this._AmcRenewalService.selectedAMCRenewal.CustomerId, 'CUSTOMERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        });
    this.LoadProject()

    if (this._AmcRenewalService.selectedAMCRenewal.CustomerId != null && this._AmcRenewalService.selectedAMCRenewal.BranchId != null && this._AmcRenewalService.selectedAMCRenewal.ProjectId != null)
      this._nodeRegService.LoadSoftRegDtls(this._AmcRenewalService.selectedAMCRenewal.CustomerId, this._AmcRenewalService.selectedAMCRenewal.BranchId, this._AmcRenewalService.selectedAMCRenewal.ProjectId).
        subscribe(obj => {
          this._AmcRenewalService.selectedAMCRenewal.CountryId = obj.CountryId
          this._AmcRenewalService.selectedAMCRenewal.DealerId = obj.DealerId
          this._AmcRenewalService.selectedAMCRenewal.RefNo = obj.RefNo
          this._AmcRenewalService.selectedAMCRenewal.Date = new Date(obj.Date)
          this._AmcRenewalService.selectedAMCRenewal.TypeOfBusinessId = obj.TypeOfBusinessId
          this._AmcRenewalService.selectedAMCRenewal.BPEId = obj.BPEId
          this._AmcRenewalService.selectedAMCRenewal.Server = obj.Server
          this._AmcRenewalService.selectedAMCRenewal.Nodes = obj.Nodes
          this._AmcRenewalService.selectedAMCRenewal.Supporters = swReg.Supporters
          this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate = new Date(swReg.AMCRenewalDate)
          this.datecal()
          this._swRegService.getAllModuleByRegId(this._AmcRenewalService.selectedAMCRenewal.Id).subscribe(obj => {
            this._ModuleService.allModulesCheckBox = []
            this._ModuleService.allModulesCheckBox = obj;
            this._ModuleService.allModulesCheckBox.forEach(obj => {
              if (obj.Selected)
                this._swRegService.moduleList.push(obj.Id)
            })
          })
        })

    var custDtls = this._custService.allCustomers.filter(e => e.Id == this._AmcRenewalService.selectedAMCRenewal.CustomerId)[0];
    this._AmcRenewalService.selectedAMCRenewal.Addr1 = custDtls.Addr1;
    this._AmcRenewalService.selectedAMCRenewal.Addr2 = custDtls.Addr2;
    this._AmcRenewalService.selectedAMCRenewal.Addr3 = custDtls.Addr3;
    this._AmcRenewalService.imageUrl = AppConstants.tockenURL + "Images/SoftwareRegistration/" + this._AmcRenewalService.selectedAMCRenewal.FileName;
    this._AmcRenewalService.formHideShow = 1;
  }

  LoadProject() {
    this._projectService.fetchProject('BYBRANCHID', this._AmcRenewalService.selectedAMCRenewal.BranchId)
      .subscribe(data => this._projectService.allProjects = data,
        error => {
          this.errorMsg = error;
        });
  }

  LoadCustomers() {
    this._custService.FetchAllCustomer('DEALERID')
      .subscribe(data => {
        this._custService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  second_date: Date;
  datecal() {
    if (this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate != null && this._AmcRenewalService.selectedAMCRenewal.Supporters != null) {
      let tempdate: Date = this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate
      this._AmcRenewalService.selectedAMCRenewal.ExpiredOn = this.addMonths(new Date(tempdate), this._AmcRenewalService.selectedAMCRenewal.Supporters);
    }
  }

  addMonths(date: Date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + parseInt(months));
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }

  onDelete(swReg: AMCRenewal) {
    this.deletePopUp = true;
    this.deleteID = swReg.Id;
    // if (confirm('Are you sure to delete this record ?') == true) {
    //   this._AmcRenewalService.deleteAMCRenewal(swReg)
    //     .subscribe(x => {
    //       this.GetAllAMCRenewal()
    //       this.toastr.success("Deleted Successfully", "SoftwareRegistration");
    //     }, error => {
    //       this.errorMsg = error;
    //       console.error(error.name, error.message, error.stack);
    //       this.toastr.error("Deletion Failure", "SoftwareRegistration");
    //     })
    // }
  }

  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._AmcRenewalService.deleteAMCRenewal(this.deleteID)
        .subscribe(x => {
          this.GetAllAMCRenewal();
          this.toastr.success("Deleted Successfully", "AMC");
          this.deleteID = 0;
        }, error => {
          this.errorMsg = error;
          this.toastr.error("Deletion Failure", "AMC");
        }
        )
    }
  }

}
