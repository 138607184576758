import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-dealer-form',
  templateUrl: './dealer-form.component.html',
  styleUrls: ['./dealer-form.component.css']
})
export class DealerFormComponent implements OnInit {

  public errorMsg;
  FileInvalidMessage = '';
  FileisValid = false;
  fileToUpload: File = null;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  constructor(public _dealrService: DealerService, private toastr: ToastrService, public _countryService: CountryService) { }

  ngOnInit() {
    this._dealrService.formHideShow = 0;
    this._dealrService.formHidePassword = false;
    this._countryService.getCountry()
      .subscribe(data => this._countryService.allCountries = data,
        error => {
          this.errorMsg = error;
        });
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._dealrService.selectedDealer = {
      Id: null,
      CountryId: null,
      DealerName: '',
      Addr1: '',
      Addr2: '',
      Addr3: '',
      DealerEmail: '',
      DealerPhone: '',
      DealerFax: '',
      ContactPerson: '',
      ContactPhone: '',
      ContactEmail: '',
      URL: '',
      LogoURL: '',
      Username: '',
      Password: '',
      ConfirmPassword: '',
      DealerCode: ''
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._dealrService.formHideShow = 0;

  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);

    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this._dealrService.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  //Get All Dealers
  GetDealersList() {
    this._dealrService.FetchDealer('COUNTRYID')
      .subscribe(data => this._dealrService.allDealers = data,
        error => {
          this.errorMsg = error;
        });
  }

  onSubmit(form: NgForm) {
    console.log('sfs')
    this.FormValidation = true
    if (form.valid) {
      this.ChechFileValid(this.fileToUpload)
      if ((this.fileToUpload != null) && this.FileisValid) {
        this.FileisValid = false;
        this._dealrService.UploadImage(this.fileToUpload).subscribe(data => {
          this.GetDealersList();
        },
          error => {
            this.errorMsg = error;
          })
      }
      this.isSubmit = true
      if (form.value.Id == null) {
        if (this.fileToUpload != null)
          form.value.LogoURL = this.fileToUpload.name;
        else
          form.value.LogoURL = ''
        if (form.value.Username != null)
          console.log('form.value', form.value)
        this._dealrService.postDealer(form.value)
          .subscribe(data => {
            this.isSubmit = false
            console.log('datahi', data)
            this.resetForm(form);
            this.GetDealersList();
            this.toastr.success('New Record Added Succcessfully', 'Dealer');
            this._dealrService.formHideShow = 0;
          },
            error => {
              this.errorMsg = error;
              this.toastr.error('New Record Added Failure', 'Dealer');
            })
      }
      else {
        form.value.LogoURL = this._dealrService.selectedDealer.LogoURL;
        form.value.DealerCode = this._dealrService.selectedDealer.DealerCode;
        this._dealrService.putDealer(form.value).subscribe(data => {
          this.isSubmit = false
          this.resetForm(form);
          this.GetDealersList();
          this.toastr.info('Record Updated Successfully!', 'Dealer');
          this._dealrService.formHideShow = 0;
        },
          error => {
            this.errorMsg = error;
            this.toastr.error('Record not Updated', 'Dealer');
          });
      }
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Dealer');
    }

  }

  //File Validation
  ChechFileValid(file) {
    //var isValid = false;
    if (this.fileToUpload != null) {

      if ((file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/gif') && file.size <= (1600 * 1200)) {
        this.FileInvalidMessage = "";
        this.FileisValid = true;
      }
      else {
        this.FileInvalidMessage = "Only JPEG/PNG/Gif Image can be upload )";
        alert("Only JPEG/PNG/Gif Image can be upload )");
      }
    }

  }
}
