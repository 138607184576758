import { Component, OnInit } from '@angular/core';
import { BranchSettings } from 'src/app/Models/BranchSettings';
import { BranchsettingsService } from 'src/app/Services/branchsettings.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';

@Component({
  selector: 'app-branch-settings-list',
  templateUrl: './branch-settings-list.component.html',
  styleUrls: ['./branch-settings-list.component.css']
})
export class BranchSettingsListComponent implements OnInit {

  constructor(public _BranchsettingsService: BranchsettingsService,
    public _CommonService: CommonService,
    public _CustomerService: CustomerService,
    public _BranchService: BranchService,
    private toastr: ToastrService) { }

  public errorMsg;
  public term: string = ''
  public deletePopUp: boolean = false;
  deleteID: number = 0;

  ngOnInit(): void {
    this._BranchsettingsService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._BranchService.branchList = []
    this._CustomerService.allCustomers = []

    this.fillbranch();
    this.LoadCustomers()
    this.getAllBranchsettings();
    this._BranchsettingsService.formHideShow = 0;
  }
  getAllBranchsettings() {
    this._BranchsettingsService.Fetch_BranchSettings('DEALERID')
      .subscribe(data => {
        this._BranchsettingsService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  toggleFilter() {
    this._BranchsettingsService.isFiltering = this._BranchsettingsService.isFiltering ? false : true;
    if (!this._BranchsettingsService.isFiltering) {
      this._BranchsettingsService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._BranchsettingsService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._BranchsettingsService.orderByColName) {
      this._BranchsettingsService.orderByDir = !this._BranchsettingsService.orderByDir;
    }
    this._BranchsettingsService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  addList() {
    this._BranchsettingsService.formHideShow = 1;
  }

  showForEdit(brnch: BranchSettings) {
    this._BranchsettingsService.selectedBranchSettings = Object.assign({}, brnch);;
    this._BranchsettingsService.formHideShow = 1;
  }

  onDelete(brnch: BranchSettings) {
    this.deletePopUp = true;
    this.deleteID = brnch.Id;
  }

  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._BranchsettingsService.onDelete(this.deleteID)
        .subscribe(x => {
          this.getAllBranchsettings();
          this.toastr.success("Deleted Successfully", "Branch Settings");
          this.deleteID = 0;
        }, error => {
          this.errorMsg = error;
          this.toastr.error("Deletion Failure", "Branch Settings");
        })
    }
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('DEALERID')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillbranch() {
    this._BranchService.Fetch_Branch('DEALERID')
      .subscribe(data => {
        this._BranchService.branchList = data
      },
        error => {
          this.errorMsg = error;
        });
  }
}
