<div class="container-fluid">
    <h2 class="heading_all">Work Sheet</h2>
    <br>
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="select">From</label>
                <input class="form-control" placeholder="From" name="From" #From="ngModel"
                    [(ngModel)]="_JobCardService.From" style="color: black;  text-align: left;" autocomplete="off"
                    bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="select">To</label>
                <input class="form-control" placeholder="To" name="To" #To="ngModel" [(ngModel)]="_JobCardService.To"
                    style="color: black;  text-align: left;" autocomplete="off" bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="select">Employee</label>
                <ng-select name="EmployeeId" id="EmployeeId" #EmployeeId="ngModel" bindLabel="EmpName" bindValue="Id" [disabled]="this._JobCardService.selectedJobCard.Emp_ID > 0"
                    required [items]="this._EmployeeService.employeeList " [(ngModel)]="_JobCardService.EmployeeId"
                    placeholder="Select">
                </ng-select>
            </div>
        </div>
        <div class="col-md-3">
            <button style="width: 100%;margin-top: 15px;" type="button" class="sim-button button2"
                (click)="getData()">Go</button>
        </div>
    </div>
    <br>
    <div class="tWrap">
        <table>
            <thead>
                <tr *ngIf="_JobCardService.Worksheetlist?.length>0">
                    <th style="width: 50px;">#
                        <div style="display: inline-block;">
                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                <i *ngIf="!isFiltering" class="fas fa-filter"></i>
                                <i *ngIf="isFiltering" class="fas fa-times"></i>
                            </a>
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:120px;">
                        <div class="AtjIsPoint" (click)="sort('Date')" *ngIf="!isFiltering">
                            <span>Date</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Date' "></i>
                                <div *ngIf="orderByColName=='Date'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="Date" id="Date" #Date="ngModel"
                                [(ngModel)]="filterCol.Date" placeholder="Date">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:100px;">
                        <div class="AtjIsPoint" (click)="sort('Activity')" *ngIf="!isFiltering">
                            <span>Activity</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Activity' "></i>
                                <div *ngIf="orderByColName=='Activity'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="Activity" id="Activity"
                                #Activity="ngModel" [(ngModel)]="filterCol.Activity" placeholder="Activity">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('W_AddedDate')" *ngIf="!isFiltering">
                            <span>Entry DateTime</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='W_AddedDate' "></i>
                                <div *ngIf="orderByColName=='W_AddedDate'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="W_AddedDate" id="W_AddedDate"
                                #W_AddedDate="ngModel" [(ngModel)]="filterCol.W_AddedDate" placeholder="W_AddedDate">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('CustomerName')" *ngIf="!isFiltering">
                            <span>Customer Name</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='RefNo' "></i>
                                <div *ngIf="orderByColName=='RefNo'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="CustomerName" id="CustomerName"
                                #CustomerName="ngModel" [(ngModel)]="filterCol.CustomerName" placeholder="CustomerName">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('ModuleName')" *ngIf="!isFiltering">
                            <span>Module Name</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='ModuleName' "></i>
                                <div *ngIf="orderByColName=='ModuleName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="ModuleName" id="ModuleName"
                                #ModuleName="ngModel" [(ngModel)]="filterCol.ModuleName" placeholder="ModuleName">
                        </div>
                    </th>
                    <th class="hidden-xs"  style="width:100px;">
                        <div class="AtjIsPoint" (click)="sort('W_From')" *ngIf="!isFiltering">
                            <span>W_From</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='W_From' "></i>
                                <div *ngIf="orderByColName=='W_From'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="W_From" id="W_From" #W_From="ngModel"
                                [(ngModel)]="filterCol.W_From" placeholder="W_From">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:100px;">
                        <div class="AtjIsPoint" (click)="sort('W_To')" *ngIf="!isFiltering">
                            <span>W_To</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='W_To' "></i>
                                <div *ngIf="orderByColName=='W_To'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="W_To" id="W_To" #W_To="ngModel"
                                [(ngModel)]="filterCol.W_To" placeholder="W_To">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:80px;">
                        <div class="AtjIsPoint" (click)="sort('Break')" *ngIf="!isFiltering">
                            <span>Break</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Break' "></i>
                                <div *ngIf="orderByColName=='Break'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="Break" id="Break" #Break="ngModel"
                                [(ngModel)]="filterCol.Break" placeholder="Break">
                        </div>
                    </th>
                    <th class="hidden-xs" style="width:100px;">
                        <div class="AtjIsPoint" (click)="sort('NextDay')" *ngIf="!isFiltering">
                            <span>NextDay</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='NextDay' "></i>
                                <div *ngIf="orderByColName=='NextDay'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input type="text" class="form-control-search" name="NextDay" id="NextDay"
                                #NextDay="ngModel" [(ngModel)]="filterCol.NextDay" placeholder="NextDay">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Status')" *ngIf="!isFiltering">
                            <span>Status</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Stage' "></i>
                                <div *ngIf="orderByColName=='Stage'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering" style="width:100px;">
                            <!-- <input type="text" class="form-control-search" name="Status" id="Status" #Status="ngModel"
                            [(ngModel)]="filterCol.Status" placeholder="Status"> -->
                            <select style="width: 90%;" id="Status" name="Status" #Status="ngModel"
                                [(ngModel)]="filterCol.Status">
                                <option [value]="''">SELECT Status</option>
                                <option *ngFor="let field of this.StatusList" [value]="field.Id">
                                    {{field.Name}}</option>
                            </select>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of _JobCardService.Worksheetlist| gridOperations  :pageIndex:
                    {Date:filterCol.Date,Activity:filterCol.Activity,W_AddedDate:filterCol.W_AddedDate,CustomerName:filterCol.CustomerName
                    ,ModuleName:filterCol.ModuleName,W_From:filterCol.W_From,W_To:filterCol.W_To,NextDay:filterCol.NextDay,Status:filterCol.Status
                    ,Break:filterCol.Break}:
                   {Column:orderByColName,Dir:orderByDir}; let i=index">
                    <td style="width: 50px;">{{i+1}}</td>
                    <td>{{data.Date | dateformat:1}}</td>
                    <td title="{{data.Description}}">{{data.Activity}}</td>
                    <td>{{data.W_AddedDate | dateformat:3}}</td>
                    <td title="{{data.CustomerName}}">{{data.CustomerName}}</td>
                    <td>{{data.ModuleName}}</td>
                    <td>{{data.W_From}}</td>
                    <td>{{data.W_To}}</td>
                    <td>{{data.Break}}</td>
                    <td>{{data.NextDay}}</td>
                    <td>
                        <span class="form-control-label" *ngFor="let obj of StatusList">
                            <p *ngIf="obj.Id==data.Status" [title]="obj.Name">
                                {{obj.Name}}
                            </p>
                        </span>
                    </td>
                </tr><!-- end ngRepeat: item in items -->
                <tr *ngIf="_JobCardService.Worksheetlist?.length<=0">
                    <td colspan="12">
                        No Records To Display.
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="pageIndex=$event"></app-grid-pagination>
                </td>
            </tfoot>
        </table>
    </div>
    <div class="margintop"></div>
</div>