import { Component, OnInit } from '@angular/core';
import { CustomerfeedbackService } from 'src/app/Services/customerfeedback.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-customer-feedback-form',
  templateUrl: './customer-feedback-form.component.html',
  styleUrls: ['./customer-feedback-form.component.css']
})
export class CustomerFeedbackFormComponent implements OnInit {

  public errorMsg;
  selected = -1;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  constructor(
    public _CustomerfeedbackService: CustomerfeedbackService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this._CustomerfeedbackService.formHideShow = 0;
    this.fillQuestionList();
    this.resetForm();
  }

  fillQuestionList() {

    this._CustomerfeedbackService.getCustomerfeedbackDtls("Question")
      .subscribe(data => {
        this._CustomerfeedbackService.allQuesDtls = data;
      },
        error => {
        })
  }

  handleChange(e, f) {
    this._CustomerfeedbackService.allQuesDtls.forEach(element => {
      if (element == f) {
        if (e == 'b') {
          element.BadOption = true;
          element.ExcellentOption = false;
          element.GoodOption = false;
          element.VeryGoodOption = false;
        }
        if (e == 'g') {
          element.BadOption = false;
          element.ExcellentOption = false;
          element.GoodOption = true;
          element.VeryGoodOption = false;
        }
        if (e == 'v') {
          element.BadOption = false;
          element.ExcellentOption = false;
          element.GoodOption = false;
          element.VeryGoodOption = true;
        }
        if (e == 'l') {
          element.BadOption = false;
          element.ExcellentOption = true;
          element.GoodOption = false;
          element.VeryGoodOption = false;
        }
      }

    });
    console.log(this._CustomerfeedbackService.allQuesDtls)
  }


  onSubmit(frm: NgForm) {
    this.FormValidation = true
    if (frm.valid) {
      this.isSubmit = true
      this._CustomerfeedbackService.SaveItem(this._CustomerfeedbackService.selectedRecord, this._CustomerfeedbackService.allQuesDtls).subscribe(
        async data => {
          this.isSubmit = false
          if (data.Status == 0) {

            this.toastr.success(data.Message, "Customer feedback")
            this._CustomerfeedbackService.formHideShow = 0;
            this.resetForm();
            this._CustomerfeedbackService.getCustomerFeedBack("ALL").subscribe(data => this._CustomerfeedbackService.allCustomerFeedBack = data,
              error => {
                this.errorMsg = error;
              });
          }
          else {
            this.toastr.warning(data.Message, "Customer feedback")

          }
        }
      );

    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Customer feedback');
    }
  }




  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this.fillQuestionList();
    this._CustomerfeedbackService.formHideShow = 0;
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._CustomerfeedbackService.selectedRecord = {
      ID: null,
      Likestaff: '',
      DislikeStaff: '',
      QuestionList: null
    }
  }


}
