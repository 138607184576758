import { httpOptions } from './../../environments/environment';
import { Params } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Common } from '../Models/Response';
import { JobScheduling,JobsList,BatchWiseJobDetails, BatchWiseJob } from '../Models/JobScheduling';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators'; 

@Injectable({
  providedIn: 'root'
})
export class JobschedulingService {

  allCustomer:Common[]=[];
  allBatch:Common[]=[];
  allUnScheduledJobs:JobsList[]=[];
  selectedJobScheduling:JobScheduling={
    Id:undefined,VDate:new Date(),CustomerId:undefined,BatchName:'',DesignDate:'',DevelopmentDate:'',TestingDate:'',DeliveryDate:'',SelectedJobList:[]
  }
  isFiltering: boolean = false;
  orderByDir: boolean = false;
  orderByColName: string = '';

  ActivityIdFilter:string=''
  ParticularFilter:string=''
  DescriptionFilter:string=''
  TypeFilter:string=''
  StageFilter:string=''
  StatusFilter:string=''
  ExpCDateFilter:string=''

  batchStatus:any[]=[
    {Id:1,Name:'All'},{Id:2,Name:'Delivered'},{Id:3,Name:'UnDelivered'}
  ]
  fromDate:Date
  toDate:Date
  selectedPeriod:number=1;
  selectedStatus:number=1;
  selectedCustomer:number=undefined;
  selectedBatch:number=undefined;
  batchWisejob:BatchWiseJob={BatchWiseJobHdr:[],BatchWiseJobDetails:[]}

  constructor(private http:HttpClient,private _globalErrorHandlerService: GlobalErrorHandlerService) { }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  ClearValue(){
    this.allUnScheduledJobs=[];
    this. selectedJobScheduling={
      Id:undefined,VDate:new Date(),CustomerId:undefined,BatchName:'',DesignDate:'',DevelopmentDate:'',TestingDate:'',DeliveryDate:'',SelectedJobList:[]
    }
  }

  ClearFilter()
  {
    this.isFiltering = false;
  }

  getUnSheduledJobs(transmode:string) /*Unscheduled*/
  {
    let params= new HttpParams().append('TransMode',transmode);
    params=params.append('CustomerId',this.selectedJobScheduling.CustomerId==undefined?'0':this.selectedJobScheduling.CustomerId.toString())
    return this.http.get<JobsList[]>(ROOT_URL +'JobScheduling/GetUnSheduledJobs',{
      params
    }).pipe(
      catchError((error)=>this._globalErrorHandlerService.handleError(error))
    )
  }

  scheduledJobTrans(values:JobScheduling,transMode:string)
  {
    var body={
      Id:values.Id==undefined?0:values.Id,
      VDate:this.convert(values.VDate),
      CustomerId:values.CustomerId,
      BatchName:values.BatchName,
      DesignDate:values.DesignDate==''?null:this.convert(values.DesignDate),
      DevelopmentDate:this.convert(values.DevelopmentDate),
      TestingDate:this.convert(values.TestingDate),
      DeliveryDate:this.convert(values.DeliveryDate),
      SelectedJobList:JSON.stringify(values.SelectedJobList)
    }
    let params = new HttpParams();
    params = params.append('TransMode',transMode);
    return this.http.post<any>(ROOT_URL + 'JobScheduling/ScheduledJobTrans', body, {
      params
    })
      .pipe(
        catchError((error) => this._globalErrorHandlerService.handleError(error))
      );
  }

  getSheduledJobs(transmode:string,id) /*Finished Scheduled,Unfinished Scheduled */
  {
    let params= new HttpParams().append('TransMode',transmode);
    params=params.append('CustomerId',this.selectedJobScheduling.CustomerId==undefined?'0':this.selectedJobScheduling.CustomerId.toString())
    params=params.append('Id',id)
    return this.http.get<any>(ROOT_URL +'JobScheduling/GetScheduledJobs',{
      params
    }).pipe(
      catchError((error)=>this._globalErrorHandlerService.handleError(error))
    )
  }

  getBatchWiseJobDetails(transmode:string) /*Unscheduled*/
  {
    let params= new HttpParams().append('TransMode',transmode);
    params=params.append('From',this.convert(this.fromDate))
    params=params.append('To',this.convert(this.toDate))
    params=params.append('Status',this.selectedStatus.toString())
    params=params.append('CustomerId',this.selectedCustomer==undefined?'0':this.selectedCustomer.toString())
    params=params.append('BatchId',this.selectedBatch.toString())
    return this.http.get<any[]>(ROOT_URL +'JobScheduling/GetBatchWiseJobDetails',{
      params
    }).pipe(
      catchError((error)=>this._globalErrorHandlerService.handleError(error))
    )
  }

}
