<div class="container-fluid" *ngIf="_dealrService.formHideShow==0">
    <!-- <div class="row flex-body">
        <div class="col-md-11 col-md-offset-1">
            <div class="box">
                <h4 class="box-title">Dealer</h4>
            </div>
            <div class="col-md-4 offset-md-8 searchBox">
                <div class="form-group row no-gutters">
                    <div class="col-12 col-sm-6 col-md-2"><button type="button" class="btn-sm btn-info"
                            (click)="addList()"><i class="fas fa-plus" aria-hidden="true"></i></button></div>
                    <div class="col-12 col-sm-6 col-md-8"><input type="text" class="form-control-search" name="search"
                            [(ngModel)]="term"></div>
                    <div class="col-6 col-md-2"><button class="btn-info"><i class="fas fa-search"
                                aria-hidden="true"></i></button>
                    </div>
                </div>
            </div> -->
    <div id="form-wrap">
        <h2 class="heading_all">DEALER </h2>
        <br>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">

                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width:60%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>

            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <!-- <th (click)="sort('Code')">Code</th>
                        <th (click)="sort('name')">Name</th>
                        <th>Action</th> -->
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('DealerCode')">
                            <span *ngIf="!_dealrService.isFiltering">Code</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_dealrService.orderByColName!='DealerCode' "></i>
                                <div *ngIf="_dealrService.orderByColName=='DealerCode'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_dealrService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_dealrService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_dealrService.isFiltering">
                            <input type="text" #txtDealerCodeFilter="ngModel"
                                [(ngModel)]="_dealrService.DealerCodeFilter" placeholder="DealerCode">
                        </div>

                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('DealerName')">
                            <span *ngIf="!_dealrService.isFiltering">Name</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_dealrService.orderByColName!='DealerName' "></i>
                                <div *ngIf="_dealrService.orderByColName=='DealerName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_dealrService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_dealrService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_dealrService.isFiltering">
                            <input type="text" #txtDealerNameFilter="ngModel"
                                [(ngModel)]="_dealrService.DealerNameFilter" placeholder="DealerName">
                        </div>

                    </th>
                    <th><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_dealrService.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="_dealrService.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- ngRepeat: item in items -->
                <!-- <tr *ngFor="let dealr of _dealrService.allDealers | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope"> -->
                <tr *ngFor="let dealr of _dealrService.allDealers | orderBy : 'DealerName'| gridOperations  :_dealrService.pageIndex: 
                        {DealerCode:_dealrService.DealerCodeFilter,DealerName:_dealrService.DealerNameFilter}:
                       {Column:_dealrService.orderByColName,Dir:_dealrService.orderByDir} ; let i = index"
                    class="ng-scope">
                    <td>{{i+1+((NoOfItems*_dealrService.pageIndex)-NoOfItems)}}</td>
                    <td>{{dealr.DealerCode}}</td>
                    <td>{{dealr.DealerName}}</td>
                    <td>
                        <button type="button" class="btn-success" (click)="showForEdit(dealr)">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>

                        <button type="button" class="btn-danger" (click)="onDelete(dealr)"><i class="fas fa-trash"
                                aria-hidden="true"></i></button>
                    </td>
                </tr>
                <tr>
                    <td colspan="4"
                        *ngIf="(_dealrService.allDealers?.length<=0 )  || _CommonService.filterdItemCount<1">
                        No Records To
                        Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="_dealrService.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        <div class="margintop"></div>
        <!-- </div>
    </div> -->
    </div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'DEALER'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>