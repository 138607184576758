import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { RemarkHistory, WorkSorting, WorkSortingAssigning } from '../Models/WorkSorting.model';

@Injectable({
  providedIn: 'root'
})
export class WorkSortingService {

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  AllWorkSorting: WorkSorting[]
  AllWorkSortingDet: WorkSortingAssigning[]
  selectedWorkSortHead: WorkSorting
  RemarkHistory: RemarkHistory[]
  selectedActiontype: number
  selectedCheckAll: boolean


  //filteration variables
  pageIndex: number = 0;
  isFiltering: boolean = false;

  RDateFilter: string = '';
  RefNoFilter: string = '';
  PriorityFilter: string = '';
  CurrentStausFilter: string = '';
  ExpDateFilter: string = '';
  OD_EDateFilter: string = '';
  PD_DateFilter: string = '';
  OD_PDDateFilter: string = '';
  CustomerFilter: string = '';
  AttSEFilter: string = '';
  MORegFilter: string = '';
  ReqTypeFilter: string = '';
  ProjectFilter: string = '';
  Vtype: string = '';

  orderByDir: boolean = false;
  orderByColName: string = '';


  getPendingWorksHeader(SupportLeaderId: number, TransMode: string): Observable<WorkSorting[]> {
    return this.http.get<any>(ROOT_URL + 'WorkSorting/getPendingWorksHeader?Id=' + SupportLeaderId + '&TransMode=' + TransMode)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }


  getPendingWorksDet(DealerId: number, RefNo: number, Vtype: number): Observable<any> {
    return this.http.get<any>(ROOT_URL + 'WorkSorting/getPendingWorksDet?DealerId=' + DealerId + '&RefNo=' + RefNo + '&Vtype=' + Vtype)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  InsWorkSorting(): Observable<any> {

    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      RDate: this.selectedWorkSortHead.RDate,
      RefNo: this.selectedWorkSortHead.RefNo,
      Vtype: this.selectedWorkSortHead.Vtype,
      Remark: this.selectedWorkSortHead.Remark,
      DealerId: this.selectedWorkSortHead.DealerId,
      WorkSortingAssigning: JSON.stringify(this.AllWorkSortingDet.filter(obj => obj.AssTo != 0))
    }
    // console.log('InsWorkSorting:body', body)
    return this.http.post<any>(ROOT_URL + 'WorkSorting/InsWorkSortingDet', body, {
      headers,
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  GetexpectedDate(field: WorkSortingAssigning): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + 'WorkSorting/GetexpectedDate', field, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  ClearFilter() {
    this.isFiltering = false;
    this.RDateFilter = '';
    this.RefNoFilter = '';
    this.PriorityFilter = '';
    this.CurrentStausFilter = '';
    this.ExpDateFilter = '';
    this.OD_EDateFilter = '';
    this.PD_DateFilter = '';
    this.OD_PDDateFilter = '';
    this.CustomerFilter = '';
    this.AttSEFilter = '';
    this.MORegFilter = '';
    this.ReqTypeFilter = '';
    this.ProjectFilter = '';
    this.Vtype = '';
  }

  GetdeliveryItem(CustomerId: number, TransMode: string): Observable<any> {
    return this.http.get<any>(ROOT_URL + 'WorkSorting/GetdeliveryItem?CustomerId=' + CustomerId + '&TransMode=' + TransMode)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

}
