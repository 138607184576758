import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProjectLeaderHomeComponent } from './project-leader-home/project-leader-home.component';
import { CustomerRequirementsComponent } from '../../Admin/Support-Engineer/customer-requirements/customer-requirements.component';
import { ServiceReportEntryComponent } from '../../Admin/Support-Engineer/service-report-entry/service-report-entry.component';
import { ProWorkAllotmentComponent } from './pro-work-allotment/pro-work-allotment.component';
import { JobCardComponent } from '../Developer/job-card/job-card.component';

const routes: Routes = [
  { path: '', component: ProjectLeaderHomeComponent },
  { path: 'customerrequirement', component: CustomerRequirementsComponent },
  { path: 'servicereportentry', component: ServiceReportEntryComponent  },
  { path: 'prowork', component: ProWorkAllotmentComponent  }, 
  { path: 'jobcard', component: JobCardComponent },
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes),
  ]
})
export class ProjectleaderModule { }
