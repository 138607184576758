import { strict } from 'assert';
import { ParticularMaster } from 'src/app/Models/ParticularMaster';
import { Injectable } from '@angular/core';
import { employee, EmployeeLeaveApprover,EmployeeWorkShedule,EmployeeDashBoard,EmployeeActivity } from '../Models/employee.model';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { designation } from '../Models/designation.model';
import { Common } from '../Models/Response';
import { Observable } from 'rxjs/observable';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
// import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  selectedEmployee: employee;
  employeeList: employee[];
  formHideShow = 0;
  formHidePassword = false;
  allEmployees: employee[];
  allDesignations: designation[];
  editDesignation: boolean = false;
  pageIndex: number = 0;
  isFiltering: boolean = false;
  EmpIdFilter: string = '';
  EmpNameFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  CurrentPassword: string
  DealerId: number = 0
  allPrgrmsTester:Common[]=[];
  selectedDate:string=null;
  selectedEmployeeId:number=0;
  selectedEmployeeName:string=null;
  employeepopup:number=0;
  employeeWorkshedule:EmployeeWorkShedule[]=[];
  employeeDashBoard:EmployeeDashBoard
  employeeActivity:EmployeeActivity
  latestJobCardPendingDate:Date=new Date(new Date().setDate(new Date().getDate()-1));

  constructor(private http: HttpClient, public __GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  getDesignation(UserType: number): Observable<designation[]> {
    return this.http.get<designation[]>(ROOT_URL + 'Employee/GetAllDesignation?UserType=' + UserType)
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
      );
  }

  ClearFilter() {
    this.isFiltering = false;
    this.EmpIdFilter = '';
    this.EmpNameFilter = '';
  }

  postEmployee(emp: employee) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      EmpId: emp.EmpId,
      EmpName: emp.EmpName,
      Designation: emp.Designation,
      Addr1: emp.Addr1,
      Addr2: emp.Addr2,
      Addr3: emp.Addr3,
      Email: emp.Email,
      Phone: emp.Phone,
      HourlyRate: emp.HourlyRate,
      Username: emp.Username,
      Password: emp.Password,
      DesignationName: emp.DesignationName,
      InActive: emp.InActive,
      EmployeeApprover: emp.EmployeeApprover
    }
    return this.http.post<boolean>(ROOT_URL + 'Employee/PostEmployee', body, {
      headers
    })
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
      );
  }

  putEmployee(emp: employee) {
    const params = new HttpParams().set('ID', emp.Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    var body = {
      EmpId: emp.EmpId,
      EmpName: emp.EmpName,
      Designation: emp.Designation,
      Addr1: emp.Addr1,
      Addr2: emp.Addr2,
      Addr3: emp.Addr3,
      Email: emp.Email,
      Phone: emp.Phone,
      HourlyRate: emp.HourlyRate,
      Username: emp.Username,
      Password: emp.Password,
      DesignationName: emp.DesignationName,
      ID: emp.Id,
      OldPassword: this.CurrentPassword,
      LoginId: emp.LoginId,
      InActive: emp.InActive,
      LastUpdatedDate: emp.LastUpdatedDate,
      EmployeeApprover: emp.EmployeeApprover
    }

    return this.http.put<employee>(ROOT_URL + 'Employee/PutEmployee/' + emp.Id, body, {
      headers,
      params
    })
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))

      );
  }

  deleteEmployee(DeleteID) {
    return this.http.delete<employee>(ROOT_URL + 'Employee/DeleteEmployee/' + DeleteID)
  }

  Fetch_Employee(TransMode: string): Observable<employee[]> {
    let params= new HttpParams();
    params=params.append('TransType',TransMode);
    params=params.append('SelectedDate',this.selectedDate);
    return this.http.get<any>(ROOT_URL + 'Employee/Fetch_Employee',{
      params
    })
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))

      );
  }

  Fetch_EmployeeById(TransMode: string, Id: number): Observable<employee[]> {
    let params= new HttpParams();
    params=params.append('TransType',TransMode);
    params=params.append('Id',Id.toString());
    params=params.append('SelectedDate',this.selectedDate);
    return this.http.get<any>(ROOT_URL + 'Employee/Fetch_Employee',{
      params
    })
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
      );
  }

  GetLoginedEmployeeDetails(TransMode: string): Observable<employee> {
    return this.http.get<employee>(ROOT_URL + 'Employee/GetEmployeeById?TransMode=' + TransMode)
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
      );
  }

  getLeaveApproverList(TransMode: string, Id: number): Observable<EmployeeLeaveApprover[]> {
    return this.http.get<EmployeeLeaveApprover>(ROOT_URL + 'Employee/GetApproverList?TransMode=' + TransMode + '&Id=' + Id)
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
      );
  }

  getAllProgrammersTesters()
  {
    return this.http.get<Common[]>(ROOT_URL + 'Employee/GetPrommersTester')
      .pipe(
        catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
      );
  }

  /* Project leader && Project manager home screen employee work details */
  selectedEmployeeData(employeeId,employeeName,type)
  {
    this.selectedEmployeeId=employeeId;
    this.selectedEmployeeName=employeeName;
    this.employeepopup=type;
  }

  employeeWorkShedule(selectedFromDate,selectedToDate)
  {
    let params=new HttpParams();
    params=params.append('SelectedDate',selectedFromDate);
    params=params.append('SelectedToDate',selectedToDate);
    params=params.append('EmployeeId',this.selectedEmployeeId.toString());
    return this.http.get<EmployeeWorkShedule[]>(ROOT_URL + 'EmployeeDetails/GetEmployeeWorkShedule',{
      params
    }).pipe(
      catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
    );
  }

  employeeWorkHistory()
  {
    let params=new HttpParams();
    params=params.append('SelectedDate',this.selectedDate.toString());
    params=params.append('EmployeeId',this.selectedEmployeeId.toString());
    return this.http.get<any>(ROOT_URL + 'EmployeeDetails/GetEmployeeActivity',{
      params
    }).pipe(
      catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
    );
  }

  displayEmployeeDashBoard(TransType)
  {
    this.getEmployeeDashBoard(TransType).subscribe(
      data=>{
        this.employeeDashBoard=data;
      })
  }

  getEmployeeDashBoard(TransType)
  {
    let params=new HttpParams();
    params=params.append('TransType',TransType);
    return this.http.get<any>(ROOT_URL + 'Employee/GetEmployeeDashBoard',{
      params
    }).pipe(
      catchError((error) => this.__GlobalErrorHandlerService.handleError(error))
    );
  }
}
