import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/Services/route.service';
import { ToastrService } from 'ngx-toastr';
import { route } from 'src/app/Models/route.model';
import { CommonService } from 'src/app/Services/common.service';
import { DealerService } from 'src/app/Services/dealer.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-route-master-list',
  templateUrl: './route-master-list.component.html',
  styleUrls: ['./route-master-list.component.css']
})
export class RouteMasterListComponent implements OnInit {
  constructor(public _routeService: RouteService,
    public _CommonService: CommonService,
    public _dealrService: DealerService,
    private toastr: ToastrService) { }

  public errorMsg;
  public term: string = ''
  // DealerId: string = '0'
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  ngOnInit(): void {
    // this.DealerId = '0'
    this._routeService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.LoadDealerByDealerId();
    this._routeService.formHideShow = 0;
  }

  LoadDealerByDealerId() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        console.log(data)
        if (data.length > 0) {
          this._routeService.DealerId = data[0].Id;
          this._routeService.selectedRoute.CountryId = data[0].CountryId;
          this.GetAllRoutes();
        }
      })
  }

  toggleFilter() {
    this._routeService.isFiltering = this._routeService.isFiltering ? false : true;
    if (!this._routeService.isFiltering) {
      this._routeService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._routeService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._routeService.orderByColName) {
      this._routeService.orderByDir = !this._routeService.orderByDir;
    }
    this._routeService.orderByColName = colName;
  }
  NoOfItems: number = 10;

  addList() {
    this._routeService.formHideShow = 1;

  }
  showForEdit(rte: route) {
    this._routeService.selectedRoute = Object.assign({}, rte);;
    this._routeService.formHideShow = 1;
  }
  onDelete(rte: route) {
    this.deletePopUp = true;
    this.deleteID = rte.Id;
  }
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._routeService.deleteRoute(this.deleteID)
        .subscribe(x => {
          this.GetAllRoutes();
          this.toastr.success("Deleted Successfully", "Route");
          this.deleteID = 0;
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.error("Deletion Failure", "Route");
        }
        )
    }

  }
  GetAllRoutes() {
    this._routeService.getRoute('BYCOUNTRYID', this._routeService.selectedRoute.CountryId)
      .subscribe(data => this._routeService.allRoutes = data,
        error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
        });
  }
}
