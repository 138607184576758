import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Common } from '../Models/Response';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';

@Injectable({
  providedIn: 'root'
})
export class ProjectCostAnalysisService {
  fromDate:Date
  toDate:Date
  selectedPeriod:number=1;
  allCustomer:Common[]=[];
  allBatch:Common[]=[];
  allDealer:Common[]=[];
  allModule:Common[]=[];
  selectedCustomer:number=undefined;
  selectedModule:number=undefined;
  selectedBatch:number=undefined;
  selectedDealer:number=undefined;
  projectCostAnalysis:any[]=[];
  projectCostAnalysisDtls:any[]=[];

  constructor(private http:HttpClient,private _globalErrorHandlerService: GlobalErrorHandlerService) { }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  proejctCostAnalysis(transmode:string,dealerId:number=0,customerId:number=0,batchId:number=0,moduleId:number=0) 
  {
    let params= new HttpParams().append('TransMode',transmode);
    params=params.append('DealerId',dealerId.toString())
    params=params.append('CustomerId',customerId.toString())
    params=params.append('BatchId',batchId.toString())
    params=params.append('ModuleId',moduleId.toString())
    params=params.append('From',this.convert(this.fromDate))
    params=params.append('To',this.convert(this.toDate))
    return this.http.get<any[]>(ROOT_URL +'Mis/GetProjectCostAnalysis',{
      params
    }).pipe(
      catchError((error)=>this._globalErrorHandlerService.handleError(error))
    )
  }


}
