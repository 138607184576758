import { error } from 'protractor';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { SoftWareDtlsCommon } from '../Models/SoftWareDtlsCommon.model';
import { BehaviorSubject, Observable,Subject } from 'rxjs';
import { HoldActivityDetails } from '../Models/ActivityHold';
import { KeyExpiryCount, KeyExpiryDetails,AMCExpiryDetails,PendingBacklogs,KeyExpiryFilter} from '../Models/KeyExpiryDetails';
import { Response } from '../Models/Response';
import { Common } from '../Models/Response';
import { catchError } from 'rxjs/operators';
import { DatePeriod } from '../Models/DatePeriod';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  formId:string; /* 3-ChargeType,4-Reason */
  commonNameText:string='Name';
  emailpattern:any="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"; 
  isLoading: boolean = false;
  searchByColName: number = 2;
  pageIndex: number = 1;
  orderByDir: boolean = false;
  orderByColName: string = '';
  totalPageCount: number = 0;
  itemCountPerPage: number = 10;
  filterdItemCount: number = 0;
  FormID: string = '';
  LoginId: number = 0;
  LoginUser: string = '';
  refno: string = '';
  code: string = '';
  customerrequirementPopUp: boolean = false;
  servicereportentryPopUp: boolean = false;
  customerrequirementPopUpdiv: boolean = false;
  servicereportentryPopUpdiv: boolean = false;
/* Key expiry details */
  expDetailsPopup:number=0;
  expDetailsText:string='';
  KeyExpiryCount:KeyExpiryCount
  KeyExpiryDetailsList:KeyExpiryDetails[]=[];
  AMCExpiryDetails:AMCExpiryDetails[]=[];
  backlogs:PendingBacklogs[]=[];
  keyExpiredCustomer:KeyExpiryFilter[]=[];
  keyExpiredDealer:KeyExpiryFilter[]=[];
  validDays:number=0;
  selectedCustomer:number=0;
  selectedDealer:number=0;
  remainderDays:number=0;
  chkSelectAll: boolean = false;
  dueEnabled:boolean=true;
  dueCustomers:Common[]=[];
  public loginUserId = new BehaviorSubject<any>(37);
  nodificationcount:number;
  allCommonResult:Common[]=[];
  selectedVoucherType:number=0;
  period:number=1;
  allTerms:Common[]=[];

  constructor(private http: HttpClient, 
              public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  mobNumberValidation(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  numberValidation(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  decimalPointValidation(event: any) {
    const pattern = /^\d*\.?\d{0,2}$/g;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  convertWithTime(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let result = [date.getFullYear(), mnth, day].join("-");
    return result + ' ' + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  DateFormat(str,type) {
    /* type-0 : yyyy-MM-dd
       type-1 : MM-dd-yyyy
       type-2 : dd-MM-yyyy
       type-3 : MM-dd-yyyy HH:mm
    */
    var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    let hour=date.getHours().toString().length > 1 ? date.getHours() : '0'+ date.getHours();
    let minutes=date.getMinutes().toString().length > 1 ? date.getMinutes() : '0'+ date.getMinutes();
    let ampm = hour >= 12 ? 'pm' : 'am';
    let time=hour + ':' + minutes + ' ' + ampm;
    if(type==0)
      return [date.getFullYear(), mnth, day].join("-");
    if(type==1)
      return [mnth, day,date.getFullYear()].join("-");
    if(type==2)
      return [day,mnth,date.getFullYear()].join("-");
    if(type==3)
    return ([mnth, day,date.getFullYear()].join("-") +' '+ time);
  }

  periodList: any[] = [{ ID: 0, Name: 'All' }, { ID: 1, Name: 'Today' }, { ID: 2, Name: 'This Week' },
  { ID: 3, Name: 'This Month' }, { ID: 4, Name: 'This Fiscal Quarter' }, { ID: 5, Name: 'This Fiscal Year' },
  { ID: 6, Name: 'Yesterday' }, { ID: 7, Name: 'Last Week' }, { ID: 8, Name: 'Last Month' },
  { ID: 9, Name: 'Last Fiscal Quarter' }, { ID: 10, Name: 'Last Fiscal Year' }, { ID: 11, Name: 'Custom' }];
  
  async getLoginedUserId() {
    let asyncResult = await this.http.get(ROOT_URL + 'Common/getLoginedUserId').toPromise();
    let UserId = asyncResult.toString();
    return UserId;
  }

   getLoginUserId() {
    return this.http.get<number>(ROOT_URL + 'Common/getLoginedUserId');
  }

  storeloginUserId()
  {
    this.getLoginUserId().subscribe(
      data=>{
        this.loginUserId.next({loginedUserId:data});
      }
    )
  }

  loadTerms(type:number)
  {
    if(type==1)
      this.allTerms=[{Id:1,Name:'Monthly'}, {Id:2,Name:'Yearly'}, {Id:2,Name:'Bi Yearly'}, {Id:4,Name:'Tri Yearly'}]
    else if (type==2)
      this.allTerms=[{Id:1,Name:'Monthly'}, {Id:2,Name:'Yearly'}]
  }

  loginedUserId():Observable<any> {
    return this.loginUserId.asObservable();
  }

  async getRefNo(FormID: string, LoginUser: string) {
    let asyncResult = await this.http.get(ROOT_URL + 'Common/GetRefNo?FormID=' + FormID + '&LoginUser=' + LoginUser).toPromise();
    this.refno = asyncResult.toString();
    return this.refno;
  }


  async getRefNoById(DealerId: number, FormID: string, LoginUser: string) {
    let asyncResult = await this.http.get(ROOT_URL + 'Common/getRefNoById?FormID=' + FormID + '&LoginUser=' + LoginUser + '&DealerId=' + DealerId).toPromise();
    this.refno = asyncResult.toString();
    return this.refno;
  }

  async getCode(FormID: string) {
    // this.DealerId = CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8);
    let asyncResult = await this.http.get(ROOT_URL + 'Common/GetCode?FormID=' + FormID).toPromise();
    this.code = asyncResult.toString();
    return this.code;
  }

  LoadSoftWareDtlsCommon(custId: number, BranchId: number, ProjectId: number): Observable<SoftWareDtlsCommon> {
    return this.http.get<SoftWareDtlsCommon>(ROOT_URL + 'Common/LoadSoftWareDtlsCommon?CustId=' + custId + '&BranchId=' + BranchId + '&ProjectId=' + ProjectId)
  }

  async getDealerId(FormID: string, LoginId: number, LoginUser: string) {
    let asyncResult = await this.http.get(ROOT_URL + 'Common/GetDealerId?FormID=' + FormID + '&LoginId=' + LoginId + '&LoginUser=' + LoginUser).toPromise();
    this.refno = asyncResult.toString();
    return this.refno;
  }

  //Proirity Model
  GetPriorityList() {
    return [
      {
        Id: 1,
        Name: 'Urgent',
      },
      {
        Id: 2,
        Name: 'Very Urgent',
      },
      {
        Id: 3,
        Name: 'Critical',
      },
      {
        Id: 4,
        Name: 'Not Must',
      },
      {
        Id: 5,
        Name: 'Normal',
      }
    ]
  }

  GetStatusList() {
    return [
      {
        Id: 10,
        Name: 'Closed',
      },
      {
        Id: 2,
        Name: 'Pending',
      },
      {
        Id: 1,
        Name: 'Party Solved',
      }
    ]
  }

  GetAssignToList() {
    return [
      {
        Id: 0,
        Name: 'Pending'
      },
      {
        Id: 1,
        Name: 'Support',
      },
      {
        Id: 2,
        Name: 'Provider'
      }
    ]
  }

  GetReferedByList() {
    return [
      {
        Id: 1,
        Name: 'Customer',
      },
      {
        Id: 2,
        Name: 'Advertisement'
      },
      {
        Id: 3,
        Name: 'Contact',
      },
      {
        Id: 4,
        Name: 'Employees'
      }
    ]
  }

  GetActionList() {
    return [
      {
        Id: 1,
        Name: 'Proposal',
      },
      {
        Id: 2,
        Name: 'Contact'
      },
      {
        Id: 3,
        Name: 'Demo',
      },
      {
        Id: 4,
        Name: 'Meeting'
      }
    ]
  }

  GetNewLeadStatusList() {
    return [
      {
        Id: 1,
        Name: 'Open',
      },
      {
        Id: 2,
        Name: 'Followup'
      },
      {
        Id: 3,
        Name: 'Closed',
      },
      {
        Id: 4,
        Name: 'Lost'
      }
    ]
  }

  //Type Model
  GetTypeList() {  //Changed the order to Acs   by name - Albert
    return [
      {
        Id: 1,
        Name: 'Error',
      },
      {
        Id: 2,
        Name: 'Modification',
      },

      {
        Id: 3,
        Name: 'Traning',
      },
      {
        Id: 4,
        Name: 'Designing'
      },
      {
        Id: 5,
        Name: 'Mistakes'
      },
      {
        Id: 6,
        Name: 'Installation'
      },
      {
        Id: 7,
        Name: 'Updation'
      },
      {
        Id: 8,
        Name: 'System Change'
      },
      {
        Id: 9,
        Name: 'Corrections'
      },
      {
        Id: 10,
        Name: 'Support'
      },
      {
        Id: 11,
        Name: 'ReInstallation'
      },
      {
        Id: 12,
        Name: 'Data Recovery '
      },
      {
        Id: 13,
        Name: 'Centralization '
      },
      {
        Id: 14,
        Name: 'Testing'
      },
      {
        Id: 15,
        Name: 'New Development'
      },
      {
        Id: 16,
        Name: 'IH Training '
      },
      {
        Id: 17,
        Name: 'Demo'
      }
    ]
  }

  GetStatusFullList() {
    return [
      {
        Id: 1,
        Name: 'Party Solved',
      },
      {
        Id: 2,
        Name: 'Pending',
      },
      {
        Id: 3,
        Name: 'Support Department',
      },
      {
        Id: 4,
        Name: 'SupportEngineer',
      },
      {
        Id: 5,
        Name: 'Provider',
      },
      {
        Id: 6,
        Name: 'Developement',
      },
      {
        Id: 7,
        Name: 'Testing',
      },
      {
        Id: 8,
        Name: 'QA',
      },
      {
        Id: 9,
        Name: 'Deliverd',
      },
      {
        Id: 10,
        Name: 'Closed',
      }
    ]
  }

  GetStageFullProviderList() {
    return [
      {
        Id: 5,
        Name: 'Pending',
      },
      {
        Id: 6,
        Name: 'Developement',
      },
      {
        Id: 7,
        Name: 'Testing',
      },
      {
        Id: 8,
        Name: 'QA',
      },
      {
        Id: 9,
        Name: 'Deliverd',
      }
    ]
  }

  GetStatusFullProviderList() {
    return [
      {
        Id: 0,
        Name: 'Pending',
      },
      {
        Id: 1,
        Name: 'WIP',
      },
      {
        Id: 2,
        Name: 'Finished',
      },
      {
        Id: 3,
        Name: 'Hold',
      },
      {
        Id: 4,
        Name: 'Unable to Complete',
      },
      {
        Id:7,
        Name:'Handover'
      }
    ]
  }

  GetTesterStatusList() {
    return [
      {
        Id: 0,
        Name: 'Pending',
      },
      {
        Id: 1,
        Name: 'WIP',
      },
      // {
      //   Id: 2,
      //   Name: 'Finished', /* uncommented */
      // },
      {
        Id: 3,
        Name: 'Hold',
      },
      {
        Id: 4,
        Name: 'Unable to Complete',
      },
      {
        Id: 5,
        Name: 'Testing Finished',
      },
      {
        Id: 6,
        Name: 'QA Finished',
      },
      {
        Id:7,
        Name:'Handover'
      }
    ]
  }

  GetStatusFullListCustom() {
    return [
      {
        Id: 1,
        Name: 'Party Solved',
      },
      {
        Id: 10,
        Name: 'Closed',
      },
    ]
  }
 
  GetWorkSortActionList() {
    return [
      {
        Id: 1,
        Name: 'Accepted',
      },
      {
        Id: 2,
        Name: 'Rejected',
      },
      {
        Id: 3,
        Name: 'Hold',
      },
      // {
      //   Id: 4,
      //   Name: 'Removed',
      // },
    ]
  }

  GetVoucherType()
  {
    this.allCommonResult=[
      {Id:1,Name:'Cloud Charge'},
      {Id:2,Name:'Service Charge'},
      {Id:3,Name:'SoftwareCost'},
      {Id:4,Name:'Other Charge'},
    ]
  }

  openDocument(path:string)
  {
    window.open(path,"_blank");
  }

  ChangeActionID(TransMode, ActivityID, ActionID, Reason): Observable<any> {
    console.log(TransMode, ActivityID, ActionID)
    let headers = new HttpHeaders().set('contentType', 'false');
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('ActivityID', ActivityID);
    params = params.append('ActionID', ActionID);
    params = params.append('RejectReason', Reason);
    return this.http.post<any>(ROOT_URL + "Common/ChangeActionID", {}, {
      headers, params,
    })
  }

  HoldActivity(TransMode, ID, ActivityID, ActionID, Reasons, ClarificationID, Meeting, HoldReason, Remark, ServiceCharge: number): Observable<any> {
    console.log(TransMode, ActivityID, ActionID, Reasons, ClarificationID, Meeting, HoldReason, Remark, ServiceCharge)
    let headers = new HttpHeaders().set('contentType', 'false');
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('ActivityID', ActivityID);
    params = params.append('ActionID', ActionID);
    params = params.append('Reasons', Reasons);
    params = params.append('ClarificationID', ClarificationID != null ? ClarificationID : 0);
    params = params.append('Meeting', Meeting);
    params = params.append('HoldReason', HoldReason);
    params = params.append('Remark', Remark);
    params = params.append('Date', this.convertWithTime(new Date()));
    // params = params.append('UserID', CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8));
    params = params.append('ID', ID),

      params = params.append('ServiceCharge', ServiceCharge.toString());
    return this.http.post<any>(ROOT_URL + "Common/HoldActivity", {}, {
      headers, params,
    })
  }
  GetHoldActivityDetails(TransMode: string, ActivityID: number): Observable<HoldActivityDetails[]> {
    return this.http.get<HoldActivityDetails[]>(ROOT_URL + 'Common/GetHoldActivityDetails?TransMode=' + TransMode + '&ActivityID=' + ActivityID);

  }
  logOutUser(): Observable<any> {
    return this.http.get<any>(ROOT_URL + 'Common/logOutUser');
  }

  ImageDownloadTrack(ImageTrackRefId, Imagename): Observable<any> {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params = new HttpParams();
    params = params.append('ImageTrackRefId', ImageTrackRefId);
    params = params.append('Imagename', Imagename);
    // params = params.append('UserId', CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8));
    return this.http.get<any>(ROOT_URL + "Common/ImageDownloadTrack", {
      headers, params,
    })
  }

  async getVNo(FormID: string) {
    let asyncResult = await this.http.get(ROOT_URL + 'Common/GetVNo?FormID=' + FormID ).toPromise();
    this.refno = asyncResult.toString();
    return this.refno;
  }

  getCurrentTime(date) /* HH:mm Format */
  {
    let currentDate=date;
    let hour=currentDate.getHours().toString().length > 1 ? currentDate.getHours() : '0'+ currentDate.getHours();
    let minutes=currentDate.getMinutes().toString().length > 1 ? currentDate.getMinutes() : '0'+ currentDate.getMinutes();
    let time=hour + ':' + minutes
    return time;
  }

  getKeyExpiryCount()
  {
    let type=localStorage.getItem('userRoles');
    let headers = new HttpHeaders().set('contentType', 'false');
    let params = new HttpParams();
    params = params.append('type',type);
    return this.http.get<KeyExpiryCount>(ROOT_URL + "Common/KeyExpiryCount", {
      headers, params,
    })
  }
  getKeyExpiryDetails(isHoldModuleFilter)
  {
    let type=localStorage.getItem('userRoles');
    let headers = new HttpHeaders().set('contentType', 'false');
    let params = new HttpParams();
    params = params.append('type',type);
    params = params.append('CustomerId',this.selectedCustomer.toString());
    params = params.append('DealerId',this.selectedDealer.toString());
    params = params.append('RemainderDays',this.remainderDays.toString());
    params = params.append('IsHoldModuleFilter',isHoldModuleFilter);
    return this.http.get<any>(ROOT_URL + "Common/KeyExpiryDetails", {
      headers, params,
    }) 
    .pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  getBacklogs()
  {
    return this.http.get<PendingBacklogs[]>(ROOT_URL + "Common/Backlogs")
    .pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }
  displayKeyExpiryCount()
  {
    this.getKeyExpiryCount().subscribe(
      data=>
      {this.KeyExpiryCount=data;})
  }
  displayKeyExpiryDetails(ExpiryDetailsType:number,isHoldModuleFilter:number)
  {
    this.chkSelectAll=false;
    if(this.remainderDays==undefined || this.remainderDays.toString().length==0)
      this.remainderDays=0;
    this.isLoading=true;
    this.getKeyExpiryDetails(isHoldModuleFilter).subscribe(
      data=>{
        if(ExpiryDetailsType=1)
          this.KeyExpiryDetailsList=data.KeyExpiryDetails;
        if(ExpiryDetailsType=2)
          this.AMCExpiryDetails=data.AMCExpiryDetails;
          this.isLoading=false;
      },error=>{
         this.isLoading=false;
      })
  }
  expiryDetails(Type:number)
  {
    this.KeyExpiryDetailsList=[];
    this.expDetailsPopup=Type;
    if(this.expDetailsPopup==1)
      this.expDetailsText="License Key Expiry Details";
    if(this.expDetailsPopup==2)
      this.expDetailsText="AMC Expiry Details";  
    if(this.expDetailsPopup==3)
      this.expDetailsText="Cloud Server Pending List";
    if(this.expDetailsPopup==4)
      this.expDetailsText="Customer Outstanding Pending List";
      this.displayKeyExpiryDetails(this.expDetailsPopup,0);
  }
  getExpiryCustomerList()
  {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params=new HttpParams();
    params=params.append('Type',"1")
    params=params.append('KeyExpiryRemainderDays',this.remainderDays.toString())
    params=params.append('DealerId',this.selectedDealer.toString())
    return this.http.get<KeyExpiryFilter[]>(ROOT_URL+ "Common/GetExpiryCustDealerList",{
      headers,params
    })
  }
  getExpiryDealerList()
  {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params=new HttpParams();
    params=params.append('Type',"0")
    params=params.append('KeyExpiryRemainderDays',this.remainderDays.toString())
    params=params.append('DealerId',this.selectedDealer.toString())
    return this.http.get<KeyExpiryFilter[]>(ROOT_URL+ "Common/GetExpiryCustDealerList",{
      headers,params
    })
  }
  updateValidDays(selectedSystemName)
  {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params=new HttpParams();
    params=params.append('CustomerId',this.selectedCustomer.toString())
    params=params.append('DealerId',this.selectedDealer.toString())
    params=params.append('ValidDays',this.validDays.toString())
    params=params.append('selectedSystemName',JSON.stringify(selectedSystemName))
    return this.http.put<Response>(ROOT_URL+ "Common/UpdateValidDays",{},{
      headers,params
    })
  }
  getremainderdays()
  {
    this.remainderdays().subscribe(data=>
      {
        this.remainderDays=data;
      })
  }
  remainderdays()
  {
    return this.http.get<number>(ROOT_URL+ "Common/Remainderdays")
  }
  dueCustomerList(DealerId)
  {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params=new HttpParams();
    params=params.append('DealerId',DealerId)
    return this.http.get<Common[]>(ROOT_URL+ "Common/DueCustomerList",{
      params,headers
    })
  }
  async getnodificationCount(employeeId:number,TransMode:string)
  {
    let params=new HttpParams().set('EmpID',employeeId.toString())
    params=params.append('TransMode',TransMode)
    this.nodificationcount= await this.http.get<number>(ROOT_URL + 'LeaveApplication/GetLeaveReqCount',{
      params
    }).toPromise();   
      return this.nodificationcount;
  }

  getCommonList(TransType:string,OtherId:number=0)
  {
    let headers = new HttpHeaders().set('contentType', 'false');
    let params=new HttpParams();
    params=params.append('TransType',TransType)
    params=params.append('OtherId',OtherId.toString())
    return this.http.get<Common[]>(ROOT_URL+ "Common/GetCommonList",{
      params,headers
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }

   GetDateByPeriod(ID: Number) {
    let headers = new HttpHeaders().set('content-type','false');
    let params = new HttpParams();
    params = params.append('PeriodID', ID.toString());
    return this.http.get<DatePeriod>(ROOT_URL + 'Common/GetDateByPeriod', {
       params,headers
    }).pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );
  }

}
