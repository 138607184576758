import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/common.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router,public _CommonService:CommonService,
              public userService: UserService) { }

  ngOnInit(): void {
  }
  Logout() {
    // localStorage.removeItem('userToken')
    // localStorage.removeItem('Designation')
    // localStorage.removeItem('Id')
    // localStorage.removeItem('Phone')
    // localStorage.removeItem('Name')
    // localStorage.removeItem('Email')
    // localStorage.removeItem('userRoles')
    // localStorage.removeItem('userName')
    this._CommonService.logOutUser().subscribe(data=>console.log(data));
    console.clear()
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
