import { Injectable, ErrorHandler } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorLoggService } from './error-logg.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandler {

  constructor(public errorLogService: ErrorLoggService) {
    super();
  }

  handleError(error): Observable<any> {
    return Observable.throw(this.errorLogService.logError(error));
  }
}
