import { Component, OnInit } from '@angular/core';
import { ModuleService } from 'src/app/Services/module.service';
import { ToastrService } from 'ngx-toastr';
import { module } from 'src/app/Models/module.Model';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.css']
})
export class ModuleListComponent implements OnInit {

  constructor(public _modulService: ModuleService,
    public _CommonService: CommonService,
    private toastr: ToastrService) { }

  public errorMsg;
  public term: any = '';
  public deletePopUp: boolean = false;
  deleteID: number = 0;

  ngOnInit(): void {
    this.deletePopUp = false;
    this.deleteID = 0;
    this._modulService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.getAllModule();
    this._modulService.formHideShow = 0;
  }

  getAllModule() {
    this._modulService.getModule().subscribe(data => this._modulService.allModules = data,
      error => {
        this.errorMsg = error;
      });
  }

  toggleFilter() {
    this._modulService.isFiltering = this._modulService.isFiltering ? false : true;
    if (!this._modulService.isFiltering) {
      this._modulService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._modulService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._modulService.orderByColName) {
      this._modulService.orderByDir = !this._modulService.orderByDir;
    }
    this._modulService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  addList() {
    this._modulService.formHideShow = 1;
  }

  showForEdit(modul: module) {
    this._modulService.selectedModule = Object.assign({}, modul);
    this._modulService.formHideShow = 1;
  }

  onDelete(modul: module) {
    this.deleteID = modul.Id;
    this.deletePopUp = true;
  }
  
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._modulService.deleteModule(this.deleteID)
        .subscribe(x => {
          this.getAllModule();
          if (x == 0)
            this.toastr.success("Deleted Successfully", "Module");
          else
            this.toastr.warning("Record Reference Found", "Module");

        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.warning("Deletion Failure", "Module");
        })
    }
  }
}
