import { Component, OnInit } from '@angular/core';
import { CustomerfeedbackmasterService } from 'src/app/Services/customerfeedbackmaster.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerFeedBackMaster } from 'src/app/Models/CustomerFeedBackMaster';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-customer-feed-back-master-list',
  templateUrl: './customer-feed-back-master-list.component.html',
  styleUrls: ['./customer-feed-back-master-list.component.css']
})
export class CustomerFeedBackMasterListComponent implements OnInit {

  public errorMsg;
  public term: any = '';
  public deletePopUp: boolean = false;
  deleteID: number = 0;

  constructor(
    public _CustomerfeedbackmasterService: CustomerfeedbackmasterService,
    public _CommonService: CommonService,
    private toastr: ToastrService
  ) { }


  ngOnInit(): void {
    this._CustomerfeedbackmasterService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._CustomerfeedbackmasterService.getCustomerFeedBackMaster("ALL")
      .subscribe(data => {
        this._CustomerfeedbackmasterService.allCustomerFeedBackMaster = data
      },
        error => {
          this.errorMsg = error;
        })
    this._CustomerfeedbackmasterService.formHideShow = 0;
  }

  toggleFilter() {
    this._CustomerfeedbackmasterService.isFiltering = this._CustomerfeedbackmasterService.isFiltering ? false : true;
    if (!this._CustomerfeedbackmasterService.isFiltering) {
      this._CustomerfeedbackmasterService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._CustomerfeedbackmasterService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._CustomerfeedbackmasterService.orderByColName) {
      this._CustomerfeedbackmasterService.orderByDir = !this._CustomerfeedbackmasterService.orderByDir;
    }
    this._CustomerfeedbackmasterService.orderByColName = colName;
  }

  NoOfItems: number = 10;



  addNew() {
    this._CustomerfeedbackmasterService.formHideShow = 1;
  }
  OnEdit(selectedRow: CustomerFeedBackMaster) {
    console.log('selectedRow', selectedRow)
    this._CustomerfeedbackmasterService.selectedRecord = Object.assign({}, selectedRow);
    this._CustomerfeedbackmasterService.formHideShow = 1;
  }

  onDelete(deleteID: number) {
    this.deletePopUp = true;
    this.deleteID = deleteID;
  }
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._CustomerfeedbackmasterService.onDelete(this.deleteID)
        .subscribe(x => {
          this._CustomerfeedbackmasterService.getCustomerFeedBackMaster("ALL").subscribe(data => this._CustomerfeedbackmasterService.allCustomerFeedBackMaster = data,
            error => {
              this.errorMsg = error;
              console.error(error.name, error.message, error.stack);
            });
          this.toastr.success("Deleted Successfully", "Customer Feedback Master");
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.warning("Deletion Failure", "Customer Feedback Master");
        })
    }

  }


}
