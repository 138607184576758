<div class="container-fluid" *ngIf="_AmcRenewalService.formHideShow!=0">
    <h2 class="heading_all">AMC Renewal</h2>
    <br>
    <div id="form-wrap">
        <form #AMCRenewalForm="ngForm" (ngSubmit)="onSubmit(AMCRenewalForm)">
            <input type="hidden" name="AmcId" #AmcId="ngModel"
                [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.AmcId">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.Id">
            <div class="tab-content">
                <div id="sec1" class="container tab-pane active">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Country<span class="validation-error">*</span></label>
                                <select disabled class="form-control" name="CountryId" #CountryId="ngModel"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.CountryId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let prf of _countryService.allCountries | orderBy : 'Name'"
                                        [ngValue]="prf.Id">
                                        {{ prf.Name }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(CountryId.invalid && CountryId.touched)||(CountryId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Dealer<span class="validation-error">*</span></label>

                                <select disabled class="form-control" name="DealerId" #DealerId="ngModel"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.DealerId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.DealerName }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(DealerId.invalid && DealerId.touched)||(DealerId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label for="select">Ref.No<span class="validation-error">*</span></label>

                                <input disabled class="form-control" name="RefNo" #RefNo="ngModel" autocomplete="off"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.RefNo" placeholder=" RefNo"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(RefNo.invalid && RefNo.touched)||(RefNo.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Customer ID<span class="validation-error">*</span></label>

                                <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel" bindLabel="Name"
                                    bindValue="Id" (ngModelChange)="onSelect($event);LoadBranch()"
                                    [items]="_custService.allCustomers | orderBy : 'Name'" required
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.CustomerId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(CustomerId.invalid && CustomerId.touched)||(CustomerId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label>Address<span class="validation-error">*</span></label>

                                <input disabled class="form-control" name="Addr1" #Addr1="ngModel" autocomplete="off"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.Addr1" placeholder=" Addr1"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr1.invalid && Addr1.touched)||(Addr1.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <label><span class="validation-error">*</span></label>
                                <input disabled class="form-control" name="Addr2" #Addr2="ngModel" autocomplete="off"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.Addr2" placeholder=" Addr2"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr2.invalid && Addr2.touched)||(Addr2.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <label><span class="validation-error">*</span></label>
                                <input disabled class="form-control" name="Addr3" #Addr3="ngModel" autocomplete="off"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.Addr3" placeholder=" Addr3"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr3.invalid && Addr3.touched)||(Addr3.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Branch<span class="validation-error">*</span></label>
                                <ng-select name="BranchId" id="BranchId" #BranchId="ngModel" bindLabel="BranchName"
                                    bindValue="Id" (ngModelChange)="LoadProject($event);"
                                    [items]="_brnchService.allBranchs | orderBy : 'BranchName'" required
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.BranchId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(BranchId.invalid && BranchId.touched)||(BranchId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label for="select">Project<span class="validation-error">*</span></label>
                                <ng-select name="ProjectId" id="ProjectId" #ProjectId="ngModel" bindLabel="Name"
                                    bindValue="Id" (ngModelChange)="LoadSoftRegDtls(AMCRenewalForm)"
                                    [items]="_projectService.allProjects | orderBy : 'Name'" required
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.ProjectId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(ProjectId.invalid && ProjectId.touched)||(ProjectId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label for="select">Modules</label>
                                <div class="col-md-8 moduleBox">
                                    <div *ngFor="let obj of _ModuleService.allModulesCheckBox | orderBy : 'ModuleName'">
                                        <input disabled type="checkbox" (change)="onChangeModules($event, obj)"
                                            [checked]="obj.Selected" name="obj.ModuleName"
                                            value="{{obj.Id}}">&nbsp;{{obj.ModuleName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="select">Date</label>

                                <input disabled class="section3" placeholder="Choose Date"
                                    style="color: black; text-align: left;" autocomplete="off" #Date="bsDatepicker"
                                    bsDatepicker [(ngModel)]="this._AmcRenewalService.selectedAMCRenewal.Date"
                                    name="Date" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">

                            </div>
                            <div class="form-group">
                                <label for="select">
                                    Type Of Business<span class="validation-error">*</span>
                                </label>
                                <select disabled class="form-control" name="TypeOfBusinessId"
                                    #TypeOfBusinessId="ngModel"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.TypeOfBusinessId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option
                                        *ngFor="let prf of _businessTypeService.allBusinessTypes | orderBy : 'BType'"
                                        [ngValue]="prf.Id">
                                        {{ prf.BType }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(TypeOfBusinessId.invalid && TypeOfBusinessId.touched)||(TypeOfBusinessId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group">
                                <label for="select">BPE<span class="validation-error">*</span></label>

                                <select disabled class="form-control" name="BPEId" #BPEId="ngModel"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.BPEId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option
                                        *ngFor="let prf of _bpeService.allBusinessPromotionExecutives | orderBy : 'BPEName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.BPEName }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(BPEId.invalid && BPEId.touched)||(BPEId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Server<span class="validation-error">*</span></label>

                                <input disabled class="form-control" name="Server" #Server="ngModel" autocomplete="off"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.Server" placeholder=" Server"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Server.invalid && Server.touched)||(Server.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="select">Nodes<span class="validation-error">*</span></label>

                                <input disabled class="form-control" name="Nodes" #Nodes="ngModel" autocomplete="off"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.Nodes" placeholder=" Nodes"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Nodes.invalid && Nodes.touched)||(Nodes.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label>AMC Renewal Date</label>
                                <input class="section3" placeholder="AMCRenewalDate"
                                    style="color: black; text-align: left;" autocomplete="off" #AMCRenewalDate="ngModel"
                                    name="AMCRenewalDate" bsDatepicker
                                    [(ngModel)]="this._AmcRenewalService.selectedAMCRenewal.AMCRenewalDate"
                                    (ngModelChange)="this.datecal()" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                            </div>
                            <div class="form-group">
                                <label>Support For (in Month)<span class="validation-error">*</span></label>
                                <input class="form-control" name="Supporters" #Supporters="ngModel" autocomplete="off"
                                    [(ngModel)]="_AmcRenewalService.selectedAMCRenewal.Supporters" (keypress)="this._CommonService.numberValidation($event)"
                                    (ngModelChange)="this.datecal()" placeholder=" Supporters" required>
                                <div class="validation-error"
                                    *ngIf="(Supporters.invalid && Supporters.touched)||(Supporters.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label>Expired On</label>
                                <input type="text" disabled class="section3" placeholder="Expired Date"
                                    style="color: black; text-align: left;" autocomplete="off" id="ExpiredOn"
                                    name="ExpiredOn" #ExpiredOn="ngModel" bsDatepicker
                                    [(ngModel)]="this._AmcRenewalService.selectedAMCRenewal.ExpiredOn"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" [disabled]="this.isSubmit" type="submit"
                        class=" sim-button button1">
                        Submit
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class=" sim-button button2"
                        (click)="resetForm(AMCRenewalForm)">
                        Reset
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class=" sim-button button3"
                        (click)="backList()">Exit</button>
                </div>
            </div>
        </form>
    </div>
    <!-- </div>
        </div> -->
</div>