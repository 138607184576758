<div class="container-fluid" *ngIf="_brnchService.formHideShow==0">
    <h2 class="heading_all">BRANCH</h2>
    <br>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4 ">
            <table>
                <thead>
                    <tr>
                        <td>
                            <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                    aria-hidden="true"></i></button>
                        </td>
                        <td style="width:60%;"><input type="text" class="form-control-search" name="search"
                                [(ngModel)]="term"></td>
                        <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <br>
    <table>
        <thead>
            <tr>
                <th>#</th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('BranchName')">
                        <span *ngIf="!_brnchService.isFiltering">Name</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_brnchService.orderByColName!='BranchName' "></i>
                            <div *ngIf="_brnchService.orderByColName=='BranchName'">
                                <i class="fas fa-sort-amount-down" *ngIf="_brnchService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_brnchService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_brnchService.isFiltering">
                        <input type="text" #txtBranchNameFilter="ngModel" [(ngModel)]="_brnchService.BranchNameFilter"
                            placeholder="Branch Name">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Customer')">
                        <span *ngIf="!_brnchService.isFiltering">Customer</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_brnchService.orderByColName!='Customer' "></i>
                            <div *ngIf="_brnchService.orderByColName=='Customer'">
                                <i class="fas fa-sort-amount-down" *ngIf="_brnchService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_brnchService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_brnchService.isFiltering">
                        <input type="text" #txtCustomerFilter="ngModel" [(ngModel)]="_brnchService.CustomerNameFilter"
                            placeholder="Customer Name">
                    </div>
                </th>
                <th style="width:170px;">UniqueId</th>
                <th style="width:150px;"><em></em>Settings
                    <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                        <i *ngIf="!_brnchService.isFiltering" class="fas fa-filter"></i>
                        <i *ngIf="_brnchService.isFiltering" class="fas fa-times"></i>
                    </a>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let brnch of _brnchService.allBranchs | orderBy : 'BranchName' | gridOperations  :_brnchService.pageIndex: 
                        {BranchName:_brnchService.BranchNameFilter,Customer:_brnchService.CustomerNameFilter}:
                        {Column:_brnchService.orderByColName,Dir:_brnchService.orderByDir} ; let i = index"
                class="ng-scope">
                <td>{{i+1+((NoOfItems*_brnchService.pageIndex)-NoOfItems)}}</td>
                <td>{{brnch.BranchName}}</td>
                <td>{{brnch.Customer}}</td>
                <td>{{brnch.UniqueBrachId}}</td>
                <td>
                    <button type="button" class="btn-success" (click)="showForEdit(brnch)">
                        <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                    <button type="button" class="btn-danger" (click)="onDelete(brnch)"><i class="fas fa-trash"
                            aria-hidden="true"></i></button>
                    <button type="button" class="btn-info" (click)="SentMailUniqueId(brnch)"><i
                            class="fas fa-paper-plane" aria-hidden="true"></i></button>
                </td>
            </tr><!-- end ngRepeat: item in items -->
            <tr>
                <td colspan="2" *ngIf="(_brnchService.allBranchs?.length<=0 )  || _CommonService.filterdItemCount<1">
                    No Records To Display.
                </td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="3" *ngIf="_CommonService.totalPageCount>1">
                <app-grid-pagination (pageIndexValue)="_brnchService.pageIndex=$event">
                </app-grid-pagination>
            </td>
        </tfoot>
    </table>
    <div class="margintop"></div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'Branch'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>