import { Injectable } from '@angular/core';
import { ServiceCharge, ServiceChargeList } from '../Models/ServiceCharge';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';

@Injectable({
  providedIn: 'root'
})
export class ServicechargeService {

  selectedServiceCharge: ServiceCharge;
  allServiceCharge: ServiceChargeList[];
  listVisible = 0;
  pageIndex: number = 0;
  isFiltering: boolean = false;
  DateFilter: string = '';
  DealerFilter: string = '';
  CustomerFilter: string = '';
  BranchFilter: string = '';
  RegNoFilter: string = '';
  AmountFilter: string = '';

  orderByDir: boolean = false;
  orderByColName: string = '';

  selectedWorkStatus:string[]=[];

  constructor(private http: HttpClient,
              public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  ClearFilter() {
    this.isFiltering = false;
    this.DateFilter= '';
    this.DealerFilter= '';
    this.CustomerFilter= '';
    this.BranchFilter= '';
    this.RegNoFilter= '';
    this.AmountFilter= '';
  }

  getServiceChargeData(DealerId:number,CustomerId:number): Observable<ServiceChargeList[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    let params=new HttpParams();
    params=params.append('DealerId',DealerId.toString())
    params=params.append('CustomerId',CustomerId.toString())
    return this.http.get<ServiceChargeList[]>(ROOT_URL + 'ServiceCharge/GetServiceChargeData',{
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getServiceCharge(VNo: number): Observable<ServiceCharge> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ServiceCharge>(ROOT_URL + 'ServiceCharge/GetAllServiceCharges?VNo=' + VNo , {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  SaveItem(Values: ServiceCharge): Observable<any> {
    var body = {
        Id: Values.Id
      , VNo: Values.VNo
      , Date:this.convert(Values.Date)
      , DealerId: Values.DealerId
      , CustomerId: Values.CustomerId
      , BranchId: Values.BranchId
      , DemandedServiceCharge: Values.DemandedServiceCharge
      , AgainstRefno: Values.AgainstRefno
      , VType: Values.VType
      , ApprovedServiceCharge: Values.ApprovedServiceCharge
      , Remark: Values.Remark
    }
    let params=new HttpParams().set('selectedWorkStatus',JSON.stringify(this.selectedWorkStatus))
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<Response>(ROOT_URL + "ServiceCharge/InsUpdDelServiceCharge", body, {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  
  deleteServiceCharge()
  {
    const headers = new HttpHeaders().set('content-type','application/json');
    let params=new HttpParams();
    params=params.append('ServiceChargeId',this.selectedServiceCharge.Id.toString())
    return this.http.delete<Response>(ROOT_URL +"ServiceCharge/deleteServiceCharge", {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
