<div class="container-fluid">
    <h2 class="heading_all">Receipts</h2><br>
    <div id="form-wrap">
        <form action="#" #ReceiptsForm="ngForm">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label style="padding-top: 15px;">VNo:<span class="validation-error">*</span></label>
                        <div class="row">
                            <div class="col-md-6">
                                <input class="form-control" name="VNo" id="VNo" #VNo="ngModel" autocomplete="off"
                                    [(ngModel)]="_RecieptService.selectedReciept.VNo" placeholder="VNo" required>
                            </div>
                            <div class="validation-error" *ngIf="VNo.invalid && this.FormValidation">
                                This Field is Required.</div>
                            <div class="col-md-6">
                                <i class="fas fa-search searchicon" (click)="searchReceipt()" aria-hidden="true">
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Dealer</label>
                        <select class="form-control" name="DealerId" #DealerId="ngModel" [disabled]="this._RecieptService.selectedReciept.Id > 0"
                            [(ngModel)]="_RecieptService.selectedReciept.DealerId" (change)="loadDealerWiseCustomers()" required>
                            <option [ngValue]="null">Choose Dealer</option>
                            <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'" [ngValue]="prf.Id">
                                {{ prf.DealerName }}
                            </option>
                        </select>
                        <div class="validation-error" *ngIf="DealerId.invalid && this.FormValidation">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group" *ngIf="this._RecieptService.selectedReciept.Id==null">
                        <label>Customer<span class="validation-error">*</span></label>
                        <ng-select name="CustomerId" id="CustomerId" bindLabel="Name" #CustomerId="ngModel" [disabled]="(this._RecieptService.selectedReciept.DealerId ==null || this._RecieptService.selectedReciept.Id > 0)"
                            [(ngModel)]="_RecieptService.selectedReciept.CustomerId" bindValue="Id"
                            [items]="this._CommonService.dueCustomers  | orderBy : 'Name'" required>
                        </ng-select>
                        <div class="validation-error"  *ngIf="CustomerId.invalid && this.FormValidation">
                            This Field is Required.</div>
                    </div> 
                    <div class="form-group" *ngIf="this._RecieptService.selectedReciept.Id > 0">
                        <label>Customer<span class="validation-error">*</span></label>
                        <ng-select name="CustomerId" id="CustomerId" bindLabel="Name" #CustomerId="ngModel" [disabled]="(this._RecieptService.selectedReciept.DealerId ==null || this._RecieptService.selectedReciept.Id > 0)"
                            [(ngModel)]="_RecieptService.selectedReciept.CustomerId" bindValue="Id"
                            [items]="this._custService.allCustomers  | orderBy : 'Name'" required>
                        </ng-select>
                        <div class="validation-error"  *ngIf="CustomerId.invalid && this.FormValidation">
                            This Field is Required.</div>
                    </div>                   
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label style="padding-top: 15px;width:50px;">Date:<span class="validation-error">*</span></label>
                        <input class="form-control" placeholder="Date" name="Date" id="Date" #Date="ngModel"
                            [(ngModel)]="_RecieptService.selectedReciept.Date" style="color: black;  text-align: left;"
                            autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        <div class="validation-error" *ngIf="Date.invalid && this.FormValidation">
                            This Field is Required.</div>   
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Received:<span class="validation-error">*</span></label>
                                <input class="form-control" name="Received" autocomplete="off" #Received="ngModel" placeholder="Received" [disabled]="this._RecieptService.selectedReciept.Id > 0"
                                    [(ngModel)]="_RecieptService.selectedReciept.Received" (keypress)="this._CommonService.decimalPointValidation($event)"  (input)="loadBalance()" required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Discount:</label>
                                <input class="form-control" name="Discount" autocomplete="off" #Discount="ngModel" placeholder="Discount" [disabled]="this._RecieptService.selectedReciept.Id > 0"
                                    [(ngModel)]="_RecieptService.selectedReciept.Discount" (keypress)="this._CommonService.numberValidation($event)"  (change)="loadBalance()" required>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Balance:<span class="validation-error">*</span></label>
                                <input disabled class="form-control" name="Balance" autocomplete="off" #Balance="ngModel" placeholder="Balance" 
                                    [(ngModel)]="_RecieptService.selectedReciept.Balance" (keypress)="this._CommonService.decimalPointValidation($event)" required>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Remark:</label>
                        <textarea class="form-control" name="Remark" autocomplete="off" #Remark="ngModel"
                            [(ngModel)]="_RecieptService.selectedReciept.Remark" placeholder=" Remark"></textarea>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="this._CommonService.dueEnabled">
                    <button style="color: white;background-color: dimgray;width: 120px;" type="button"
                        (click)="getDueData()" class="sim-button button1" aria-hidden="true">Search</button><br>
                    <label for="select" style="color: red;font-weight: bold;">Total Due:</label>
                    <label style="color: red;font-weight: bold;">{{this._RecieptService.selectedReciept.TotalDue}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width: 99%; margin-top:25px;" type="button" [disabled]="this.isSubmit"
                       (click)="onSubmit(ReceiptsForm)" class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%;margin-top:25px;" type="button" (click)="deleteReceipt()" class="sim-button button1">Delete</button> 
                </div>
                <div class="col-md-4">
                    <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                        (click)="resetForm(ReceiptsForm)">Reset</button>
                </div>
            </div>
            <!-- Receipt due pop up -->
            <div class="popup-container" *ngIf="this.popUpIndex">
                <input type="checkbox" id="test-results" checked>
                <div class="popup HoldingReason" (click)="closePopUp()">
                    <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                        <div class="title">
                            <p>Receipt</p>                              
                            <i class="fas fa-times" (click)="closePopUp()"></i>
                        </div>
                         <div class="fixTableHead" [ngStyle]="{'height':_RecieptService.allDues.length > 3 ? '290px':'190px'}" style="height:180px;">
                            <div class="row">
                                <div class="col-md-12">
                                    <table class="tablepl table" id="receiptDetails">
                                        <thead>
                                            <tr>
                                                <!-- <th><input type="checkbox" name="chkall" id="chkall" #chkall="ngModel" [(ngModel)]="this._CommonService.chkSelectAll" (change)="selectAllReceipts()"></th> -->
                                                <th><input type="checkbox" name="chkall" id="chkall" disabled></th>
                                                <th>SNo</th>
                                                <th class="hidden-xs" style="width:125px;">
                                                     VType
                                                </th>
                                                <th class="hidden-xs" style="width:60px;">
                                                    VNo
                                                </th>
                                                <th class="hidden-xs" style="width:90px;">
                                                    Date
                                                </th>
                                                <th class="hidden-xs" style="width:200px;">
                                                    Customer
                                                </th>  
                                                <th class="hidden-xs">
                                                    Total Due
                                                </th>                                          
                                                <th class="hidden-xs">
                                                    Total Paid
                                                </th>
                                                <th class="hidden-xs">
                                                    Due
                                                </th>
                                                <!-- <th class="hidden-xs">
                                                    Balance
                                                </th>                                             -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let receipt of _RecieptService.allDues; let i=index" class="ng-scope">
                                                <td>
                                                    <input type="checkbox" name="{{i}}CheckStatus" #CheckStatus="ngModel" [(ngModel)]="receipt.CheckStatus"
                                                         (ngModelChange)="calculateDue(receipt)">
                                                    </td>
                                                    <td>{{i+1}}</td>
                                                    <td>{{receipt.VName}}</td>
                                                    <td>{{receipt.VNo}}</td>
                                                    <td>{{receipt.ContractDate}}</td>
                                                    <td title="{{receipt.CustomerName}}">{{receipt.CustomerName}}</td>
                                                    <td>{{receipt.TotalDue}}</td>
                                                    <td>{{receipt.Paid}}</td>
                                                    <td>{{receipt.Due}}</td>
                                                    <!-- <td>{{receipt.Balance}}</td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> 
                              </div>
                            </div>        
                            <br><div class="form-group">
                            <div class="row disabled">
                                <div class="col-md-3" style="pointer-events:visible">
                                    <button style="width: 100%;" type="button" (click)="loadSelectedReceipt()" class="sim-button button1">Submit</button>
                                </div>
                                <div class="col-md-3">
                                    <input name="TotalDue" placeholder="Total Due" #TotalDue="ngModel" [(ngModel)]="this._RecieptService.totalSelectedDue" autocomplete="off" (keypress)="this._CommonService.decimalPointValidation($event)">
                                </div>  
                                <div class="col-md-3">
                                    <input name="TotalPaid" placeholder="Total Paid" autocomplete="off" #TotalPaid="ngModel" [(ngModel)]="this._RecieptService.totalSelectedPaid" (keypress)="this._CommonService.decimalPointValidation($event)">
                                </div>  
                                <div class="col-md-3">
                                    <input name="TotalBalance" placeholder="Total Balance" autocomplete="off" #TotalBalance="ngModel" [(ngModel)]="this._RecieptService.totalSelectedBalance" (keypress)="this._CommonService.decimalPointValidation($event)">
                                </div>                       
                            </div>                             
                        </div>                                                    
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>