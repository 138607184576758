import { Component, OnInit } from '@angular/core';
import { CountryService } from 'src/app/Services/country.service';
import { RouteService } from 'src/app/Services/route.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { DealerService } from 'src/app/Services/dealer.service';
import * as CryptoJS from 'crypto-js';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-route-master-form',
  templateUrl: './route-master-form.component.html',
  styleUrls: ['./route-master-form.component.css']
})
export class RouteMasterFormComponent implements OnInit {

  constructor(public _routeService: RouteService,
    public _CommonService: CommonService,
    private toastr: ToastrService,
    public _dealrService: DealerService,
    public _countryService: CountryService) { }

  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  ngOnInit(): void {
    this._routeService.formHideShow = 0;
    this._countryService.getCountry()
      .subscribe(data => this._countryService.allCountries = data,
        error => {
          this.errorMsg = error;
        })
    this.resetForm();
  }
  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._routeService.selectedRoute = {
      Id: null,
      RouteName: '',
      CountryId: null,
      CountryName: ''
    }
    this.LoadDealerByDealerId();
  }
  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._routeService.formHideShow = 0;

  }
  LoadDealerByDealerId() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        this._routeService.DealerId = data[0].Id;
        this._routeService.selectedRoute.CountryId = data[0].CountryId;
        this._routeService.getRoute('BYCOUNTRYID', this._routeService.selectedRoute.CountryId)
          .subscribe(data => this._routeService.allRoutes = data,
            error => {
              this.errorMsg = error;
            })
      })
  }

  formValidation(form: NgForm): boolean {
    if (form.value.RouteName != "")
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid && this.formValidation(form)) {
      this._CommonService.isLoading = true
      this.isSubmit = true
      form.value.CountryId = this._routeService.selectedRoute.CountryId
      this._routeService.postRoute(form.value).subscribe(
        async data => {
          this._CommonService.isLoading = false
          this.isSubmit = false
          if (data.Status == 0) {
            this.toastr.success(data.Message, "Route Master")
            this.GetAllRoutes();
            this.backList();
          }
          else
            this.toastr.warning(data.Message, "Route Master")
        },
        error => {
          this._CommonService.isLoading = false
          this.errorMsg = error;
          this.toastr.error('New Record Added Failure', 'Route Master');
        })
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Route Master');
    }
  }

  GetAllRoutes() {
    this._routeService.getRoute('BYCOUNTRYID', this._routeService.selectedRoute.CountryId)
      .subscribe(data => this._routeService.allRoutes = data,
        error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
        });
  }
}
