import { Injectable } from '@angular/core';
import { BranchSettings } from '../Models/BranchSettings';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';

@Injectable({
  providedIn: 'root'
})
export class BranchsettingsService {

  selectedBranchSettings: BranchSettings;
  DealerId: string = ''
  BranchSettingsList: BranchSettings[];
  formHideShow = 0;
  pageIndex: number = 0;
  isFiltering: boolean = false;
  LicenseKeyValDaysFilter: string = '';
  CustomerFilterId:number=0;
  BranchFilterId:number=0;
  orderByDir: boolean = false;
  orderByColName: string = '';
  public allBranchs: BranchSettings[];
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  ClearFilter() {
    this.isFiltering = false;
    this.LicenseKeyValDaysFilter = '';
    this.CustomerFilterId=0;
    this.BranchFilterId=0;
  }

  InsUpdDelBranchSettings(BranchSettings: BranchSettings) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      BranchId: BranchSettings.BranchId,
      DealerId: BranchSettings.DealerId,
      CustomerId: BranchSettings.CustomerId,
      Id: BranchSettings.Id,
      LicenseKeyValDays: BranchSettings.LicenseKeyValDays,
      RemainderExp: BranchSettings.RemainderExp,
      YearlyReminderExp: BranchSettings.YearlyReminderExp,
      YearlyReminderExpfreq: BranchSettings.YearlyReminderExpfreq,
      BlockLicenseKeyIssue: BranchSettings.BlockLicenseKeyIssue,
      VersionChange: BranchSettings.VersionChange,
      CustomerProfileUpdation: BranchSettings.CustomerProfileUpdation,
      SystemReplacement: BranchSettings.SystemReplacement,
    }
    // return this.http.post<BranchSettings>(ROOT_URL + 'BranchSettings/InsUpdDelBranchSettings', body)
    //   .pipe(
    //     catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    //   );
    return this.http.post<any>(ROOT_URL + "BranchSettings/InsUpdDelBranchSettings", body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  onDelete(Id: number) {
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.delete<any>(ROOT_URL + 'BranchSettings/DeleteBranchSettings', {
      headers, params

    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  Fetch_BranchSettings(TransMode: string): Observable<BranchSettings[]> {
    return this.http.get<BranchSettings[]>(ROOT_URL + 'BranchSettings/FetchAllBranchSettings?TransMode=' + TransMode)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
