import { Component, OnInit } from '@angular/core';
import { customer } from 'src/app/Models/customer.model';
import { BranchService } from 'src/app/Services/branch.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { RouteService } from 'src/app/Services/route.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-branch-form',
  templateUrl: './branch-form.component.html',
  styleUrls: ['./branch-form.component.css']
})
export class BranchFormComponent implements OnInit {


  Country_Id: any;
  public errorMsg;
  FileInvalidMessage = '';
  FileisValid = false;
  fileToUpload: File = null;
  selectedCustomer: customer;
  allCustomers1: customer[];
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  constructor(public _brnchService: BranchService,
    private toastr: ToastrService,
    public _countryService: CountryService,
    public _dealrService: DealerService,
    public _CommonService: CommonService,
    public _custService: CustomerService,
    public _routeService: RouteService) { }

  ngOnInit(): void {
    this._brnchService.formHideShow = 0;
    this._brnchService.formHidePassword = false;
    this.resetForm();
    this._countryService.getCountry()
      .subscribe(data => this._countryService.allCountries = data,
        error => {
          this.errorMsg = error;
        });
    this.LoadCustomers();
    this.LoadDealerByDealerId();
  }

  LoadDealerByDealerId() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        this._dealrService.allDealers = data
        this._brnchService.selectedBranch.CountryId = data[0].CountryId;
        this._brnchService.selectedBranch.DealerId = data[0].Id;
        this._routeService.getRoute('BYCOUNTRYID', this._brnchService.selectedBranch.CountryId)
          .subscribe(data => this._routeService.allRoutes = data,
            error => {
              this.errorMsg = error;
            });
      })
  }
  LoadCustomers() {
    this._custService.FetchAllCustomer('DEALERID')
      .subscribe(data => this._custService.allCustomers = data,
        error => {
          this.errorMsg = error;
        });
  }

  onSelect(Cust_ID, index) {
    var custDtls = this._custService.allCustomers.filter(e => e.Id == this._brnchService.selectedBranch.CustomerId)[0];
    this._brnchService.selectedBranch.Addr1 = custDtls.Addr1;
    this._brnchService.selectedBranch.Addr2 = custDtls.Addr2;
    this._brnchService.selectedBranch.Addr3 = custDtls.Addr3;
    this._brnchService.selectedBranch.BranchEmail = custDtls.CustEmail;
    this._brnchService.selectedBranch.BranchPhone = custDtls.CustPhone;
    this._brnchService.selectedBranch.BranchFax = custDtls.CustFax;
    this._brnchService.selectedBranch.ContactPerson = custDtls.ContactPerson;
    this._brnchService.selectedBranch.ContactPhone = custDtls.ContactPhone;
    this._brnchService.selectedBranch.ContactEmail = custDtls.ContactEmail;
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._brnchService.selectedBranch = {
      Id: null,
      CountryId: null,
      DealerId: null,
      CustomerId: null,
      Customer:'',
      BranchName: '',
      Addr1: '',
      Addr2: '',
      Addr3: '',
      BranchEmail: '',
      BranchPhone: '',
      BranchFax: '',
      ContactPerson: '',
      ContactPhone: '',
      ContactEmail: '',
      RouteId: null,
      UniqueBrachId: '',
      DataBase:''
    }
    this.LoadDealerByDealerId();
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._brnchService.formHideShow = 0;
  }

  formValidation(): boolean {
    if (this._brnchService.selectedBranch.CustomerId > 0 && this._brnchService.selectedBranch.BranchName != "" && this._brnchService.selectedBranch.Addr1 != ""
      && this._brnchService.selectedBranch.Addr2 != "" && this._brnchService.selectedBranch.Addr3 != "" && this._brnchService.selectedBranch.BranchEmail != ""
      && this._brnchService.selectedBranch.BranchPhone != "" && this._brnchService.selectedBranch.BranchFax != "" && this._brnchService.selectedBranch.ContactPerson != ""
      && this._brnchService.selectedBranch.ContactPhone != "" && this._brnchService.selectedBranch.ContactEmail != "" && this._brnchService.selectedBranch.RouteId > 0)
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    form.value.CountryId = this._brnchService.selectedBranch.CountryId
    form.value.DealerId = this._brnchService.selectedBranch.DealerId
    if (form.valid && this.formValidation()) {
      this.isSubmit = true
      this._CommonService.isLoading = true
      this._brnchService.postBranch(form.value)
        .subscribe(data => {
          this._CommonService.isLoading = false
          this.isSubmit = false
          if (data.Status == 0)
            this.toastr.success(data.Message, 'Branch');
          else
            this.toastr.warning(data.Message, 'Branch');
          this.getAllBranch();
          this.backList()
        },
          error => {
            this._CommonService.isLoading = false
            this.errorMsg = error;
            this.toastr.error('New Record Added Failure', 'Branch');
          })
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Branch');
    }
  }

  getAllBranch() {
    this._brnchService.Fetch_Branch('DEALERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        })
  }
  
  keyPress(event: any) {
    if (event.charCode !== 0) {
      const pattern = /[0-9\+]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode != 13) {
        event.preventDefault();
      }
    }
  }
}
