import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttandanceRegister, MonthDays } from '../Models/AttandanceRegister';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AttandanceregisterService {

  constructor(private http: HttpClient,
              public _GlobalErrorHandlerService: GlobalErrorHandlerService ) { }

  selectedDate:string;
  attandanceDetails:AttandanceRegister;

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  
  getData(){
    this.attandanceDetails={TotalAttandance:"",TotalWorkingDays:'',tableDetails:[],TotalAttandedHrs:'',TotalWorkingHrs:''}; 
    let params=new HttpParams().set('DateValue',this.convert(this.selectedDate))  
    return this.http.get<AttandanceRegister>(ROOT_URL + 'EmployeeDetails/getAttandenceRegister',{
      params
    })
    .pipe(
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))
    );  
  }

  getTimeSheetData(){
    this.attandanceDetails={TotalAttandance:"",TotalWorkingDays:'',tableDetails:[],TotalAttandedHrs:'',TotalWorkingHrs:''}; 
    let params=new HttpParams().set('DateValue',this.convert(this.selectedDate)) 
    return this.http.get<AttandanceRegister>(ROOT_URL + 'EmployeeDetails/getTimesheet',{
      params
    })
    .pipe(  
      catchError((error) => this._GlobalErrorHandlerService.handleError(error))  
    ); 
  }
}
