import { error } from '@angular/compiler/src/util';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { employee } from 'src/app/Models/employee.model';
import { LeaveApplicationApprover } from 'src/app/Models/LeaveApplication';
import { CommonService } from 'src/app/Services/common.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { LeaveApplicationService } from 'src/app/Services/leave-application.service';

@Component({
  selector: 'app-leave-application-list',
  templateUrl: './leave-application-list.component.html',
  styleUrls: ['./leave-application-list.component.css']
})
export class LeaveApplicationListComponent implements OnInit {
  LoginedEmp: employee;
  LoginedEmpApproverList: LeaveApplicationApprover[];
  LoginID: number
  public deletePopUp: boolean = false;
  deleteEmpId:number=0;
  deleteID: number = 0;
  public errorMsg:string;

  constructor(public _CommonService: CommonService, 
              public _LeaveApplicationService: LeaveApplicationService, 
              private _EmployeeService: EmployeeService,
              private toastr:ToastrService) { }

  ngOnInit(): void {
    this._LeaveApplicationService.UserRole = JSON.parse(localStorage.getItem('userRoles'))[0].toString();
    this.GetLoginId()
    this._LeaveApplicationService.IsForm = false;
    this._LeaveApplicationService.isFiltering = false;
    this._LeaveApplicationService.ClearValue();
    this._LeaveApplicationService.ClearFilter();
    this.getLoginedEmp()
  }

  async GetLoginId() {
    this.LoginID = parseInt(await this._CommonService.getLoginedUserId())
  }

  getLoginedEmp() {
    this._EmployeeService.GetLoginedEmployeeDetails('EMPLOYEE').subscribe(
      data => {
        if (data) {
          this.LoginedEmp = data;
          this._LeaveApplicationService.GetApproverList(0, this.LoginID).subscribe(data2 => {
            this.LoginedEmpApproverList = data2;
          });
          this.GetAllLeaveRequest();
        }
      }
    )
  }

  async AddNew() {
    this._LeaveApplicationService.SelectedData.Date = new Date();
    this._LeaveApplicationService.giveEditPermission = true;
    this._LeaveApplicationService.SelectedData.FromDate = new Date();
    this._LeaveApplicationService.SelectedData.ToDate = new Date();
    this._LeaveApplicationService.SelectedData.RefNo = await this._CommonService.getRefNo('LEAVE', '');
    this._LeaveApplicationService.GetWorkSchedule(this.LoginedEmp.Id, new Date(), new Date()).subscribe(
      data => {
        this._LeaveApplicationService.LeaveEmpWorkScheduleList = data;
      }
    )
    this.getBalaceLeaveDetails(this.LoginedEmp.Id);
    if (this.LoginedEmp) {
      this._LeaveApplicationService.SelectedData.EmpCode = this.LoginedEmp.EmpId;
      this._LeaveApplicationService.SelectedData.EmpID = this.LoginedEmp.Id;
      this._LeaveApplicationService.SelectedData.EmpName = this.LoginedEmp.EmpName;
      this._LeaveApplicationService.SelectedData.Designation = this.LoginedEmp.DesignationName;
      this._LeaveApplicationService.IsForm = true;
      this._LeaveApplicationService.SelectedData.LeaveApproverDetails = this.LoginedEmpApproverList;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._LeaveApplicationService.orderByColName) {
      this._LeaveApplicationService.orderByDir = !this._LeaveApplicationService.orderByDir;
    }
    this._LeaveApplicationService.orderByColName = colName;
  }

  toggleFilter() {
    this._LeaveApplicationService.isFiltering = this._LeaveApplicationService.isFiltering ? false : true;
    if (!this._LeaveApplicationService.isFiltering) {
      this._LeaveApplicationService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._LeaveApplicationService.pageIndex = 1;
    }
  }

  onDelete(empId,leaveAppID) {
    this.deleteEmpId=empId;
    this.deleteID=leaveAppID;
    this.deletePopUp=true;
  }

  dialogResult(event)
  {
    this.deletePopUp=false;
    console.log(event)
    if (event) {
      this._LeaveApplicationService.deleteLeaveApplication(this.deleteEmpId,this.deleteID)
        .subscribe(data => {
          if(data.Status)
          {
            this.GetAllLeaveRequest();
            this.toastr.success(data.Message, "Leave Application");
          }
          else
            this.toastr.warning(data.Message, "Leave Application");
        }, error => {
          this.errorMsg = error;
          this.toastr.error("Something went wrong", "Leave Application");
        })
      }
  }

  GetAllLeaveRequest() {
    let employeeId=0
    if(this._LeaveApplicationService.UserRole == 'Developers & Designers' ||this._LeaveApplicationService.UserRole == 'Testers')
      employeeId=this.LoginID;
    this._LeaveApplicationService.GetAllLeaveRequest(employeeId).subscribe(data => {
      this._LeaveApplicationService.DataList = data;
    })
  }

  onEdit(data) {
    this._LeaveApplicationService.SelectedData = Object.assign({}, data);
    this._LeaveApplicationService.SelectedData.Date = new Date(this._LeaveApplicationService.SelectedData.Date);
    this._LeaveApplicationService.SelectedData.FromDate = new Date(this._LeaveApplicationService.SelectedData.FromDate);
    this._LeaveApplicationService.SelectedData.ToDate = new Date(this._LeaveApplicationService.SelectedData.ToDate);
    this._LeaveApplicationService.GetApproverList(data.ID, data.EmpID).subscribe(data => {
      this._LeaveApplicationService.SelectedData.LeaveApproverDetails = data;
      this._LeaveApplicationService.IsForm = true;
      if (this._LeaveApplicationService.SelectedData.EmpID == this.LoginedEmp.Id || this._LeaveApplicationService.SelectedData.LeaveApproverDetails.some(data => data.ApproverID == this.LoginedEmp.Id)) {
        this._LeaveApplicationService.giveEditPermission = true
      }
    });

    this._LeaveApplicationService.GetWorkSchedule(data.EmpID, this._LeaveApplicationService.SelectedData.FromDate, this._LeaveApplicationService.SelectedData.ToDate).subscribe(
      data => {
        this._LeaveApplicationService.LeaveEmpWorkScheduleList = data;
      }
    );
    this.getBalaceLeaveDetails(data.EmpID);
  }

  getBalaceLeaveDetails(EmpID) {
    this._LeaveApplicationService.GetLeaveBalance(EmpID).subscribe(
      data => {
        this._LeaveApplicationService.EmpLeaveBalanceDetails = data;
      }
    );
  }

}
