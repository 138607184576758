<div class="container-fluid">
    <h2 class="heading_all">Support Leader Dashboard
        <span [class.disabled] = "this._CommonService.KeyExpiryCount.LicenseKey == 0" data-title="License Key"  style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(1)">
            <img src="../../../../../assets/Images/Licensekey.ico">({{this._CommonService.KeyExpiryCount.LicenseKey}})
        </span>
        <span [class.disabled] = "this._CommonService.KeyExpiryCount.AMC == 0" data-title="Expiry AMC Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(2)">
            <img src="../../../../../assets/Images/tools.jpg">({{this._CommonService.KeyExpiryCount.AMC}})
        </span>
        <span  [class.disabled] = "this._CommonService.KeyExpiryCount.CloudServer == 0" data-title="Cloud Server Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(3)">
            <img src="../../../../../assets/Images/cloudserver.ico">({{this._CommonService.KeyExpiryCount.CloudServer}})
        </span>
        <span  [class.disabled] = "this._CommonService.KeyExpiryCount.CustomerOutstanding == 0" data-title="Customer Outstanding Details" style="float:right;font-size:medium;cursor: pointer;padding-left:20px;" (click)="this._CommonService.expiryDetails(4)">
            <img src="../../../../../assets/Images/customeroutstanding.ico">({{this._CommonService.KeyExpiryCount.CustomerOutstanding}})
        </span>
    </h2>
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-3 panel">
                <img src="../../../../../assets/Images/PMS.png" style="width:300px;height:280px;">
            </div>
            <div class="col-md-3">
                <div class="panel">
                    <app-pie></app-pie>
                </div>
            </div>
            <div class="col-md-6 panel"> 
                <label style="text-decoration: underline; color: black">Frequently Visited Customers</label>
                <div class="tWrap fixTableHead" style="height:250px;">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width:auto;">Customer</th>
                                    <th>Branch</th>
                                    <th style="width:80px;">No.Visits</th>
                                    <th style="width:90px;">Frequency</th>
                                    <th style="width:80px;">Last Visit</th>
                                </tr>
                            </thead>
                                <tbody>
                                    <tr *ngFor="let obj of this._DevdashboardService.AllFrequentlyVisitCustomer">
                                        <td title="{{obj.Customer}}">{{obj.Customer}}</td>
                                        <td title="{{obj.Branch}}">{{obj.Branch}}</td>
                                        <td style="width:80px;">{{obj.NoVisit}}</td>
                                        <td style="width:90px;">{{obj.Frequency}}</td>
                                        <td style="width:80px;">{{obj.LastVisit}}</td>
                                    </tr>                                                                                     
                                </tbody>
                        </table> 
                    </div> 
                </div>               
            </div>
        </div><br>
        <div class="row">
            <div class="col-md-2">
                <label style="margin-left: 100px; color: black; ">Select Date:</label>
            </div>
            <div class="col-md-6">
                <input class="section3" placeholder="Choose Date" style="color: black; text-align: left;"
                    autocomplete="off" #dp="bsDatepicker" bsDatepicker
                    [(ngModel)]="this._serviceEngineerDBService.selectedDate" name="selectedDate"
                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                    (bsValueChange)="LoadDealerDashboardTodaysSchdule();LoadDealerDashboardCustReqData()">
            </div>
        </div>
        <br>
        <div class="row" style="margin-right: 0px;margin-left: 0px;">
            <div class="tWrap">
                <div class="tWrap__head">
                    <table>
                        <thead>
                            <tr>
                                <th>SNo</th>
                                <th>SE</th>
                                <th>RefNo</th>
                                <th>Customer</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>ExpDate</th>
                                <th>OverDue</th>
                                <th>Priority</th>
                                <th>AMCStatus</th>
                                <th>ServiceMode</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="tWrap__body">
                    <table>
                        <tbody>
                            <tr *ngFor="let Backlog of this._CustomerrequirementService.DealerDashBoardTodaysSchdule ; let i = index"
                                class="ng-scope">
                                <td>{{i+1}}</td>
                                <td> {{Backlog.ServiceEngs}} </td>
                                <td>{{Backlog.RefNo}}</td>
                                <td>
                                    <span class="form-control-label"
                                        *ngFor="let field of  _CustomerService.allCustomers">
                                        <p *ngIf="field.Id==Backlog.CustomerId">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td>{{Backlog.RDate|date}}</td>

                                <td>
                                    <span class="form-control-label" *ngFor="let field of this.TypeList">
                                        <p *ngIf="Backlog.Type==field.Id">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td>{{Backlog.DDate|date}}</td>
                                <td> {{Backlog.OverDue}} </td>
                                <td>
                                    <span class="form-control-label" *ngFor="let field of this.PriorityList">
                                        <p *ngIf="Backlog.PriorityId==field.Id">
                                            {{field.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td> {{Backlog.AmcStatus}} </td>
                                <td>
                                    <label class="form-control-label">
                                        <p *ngIf="Backlog.ServiceMode==1">By Phone</p>
                                        <p *ngIf="Backlog.ServiceMode==2">By Mail</p>
                                        <p *ngIf="Backlog.ServiceMode==3">By Chat</p>
                                        <p *ngIf="Backlog.ServiceMode==4">On Site</p>
                                        <p *ngIf="Backlog.ServiceMode==5">Remote Section</p>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <br>     
        <div class="row">
            <div class="tWrap">
                <div class="tWrap__head">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" style="width:30px;">SlNo</th>
                                <!-- <th scope="col">RDate</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('RDate')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">RDate</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='RDate'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='RDate'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtRDateFilter="ngModel" id="RDateFilter"
                                            name="RDateFilter" [(ngModel)]="_WorkSortingService.RDateFilter"
                                            placeholder="RDate">
                                    </div>

                                </th>
                                <!-- <th scope="col" style="width:50px;">Ref No</th> -->
                                <th class="hidden-xs" style="width:70px;">
                                    <div class="AtjIsPoint" (click)="sort('RefNo')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">Ref No</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='RefNo'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='RefNo'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtRefNoFilter="ngModel" id="RefNoFilter"
                                            name="RefNoFilter" [(ngModel)]="_WorkSortingService.RefNoFilter"
                                            placeholder="RefNo">
                                    </div>
                                </th>
                                <!-- <th scope="col">Priority</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('PriorityId')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">Priority</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='PriorityId'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='PriorityId'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <select name="PriorityFilter" id="PriorityFilter"
                                            [(ngModel)]="_WorkSortingService.PriorityFilter">
                                            <option [value]="''">Select</option>
                                            <option *ngFor="let obj of PriorityList" [ngValue]="obj.Id">
                                                {{obj.Name}}
                                            </option>
                                        </select>
                                    </div>

                                </th>
                                <!-- <th scope="col">Current Staus</th> -->
                                <th class="hidden-xs" style="width:120px;">
                                    <div class="AtjIsPoint" (click)="sort('CurrentStatus')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">Current Status</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='CurrentStatus'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='CurrentStatus'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtCurrentStausFilter="ngModel" id="CurrentStausFilter"
                                            name="CurrentStausFilter"
                                            [(ngModel)]="_WorkSortingService.CurrentStausFilter"
                                            placeholder="Current Status">
                                    </div>
                                </th>
                                <!-- <th scope="col">ExpDate</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('ExpDate')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">ExpDate</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='ExpDate'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='ExpDate'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtExpDateFilter="ngModel" id="ExpDateFilter"
                                            name="ExpDateFilter" [(ngModel)]="_WorkSortingService.ExpDateFilter"
                                            placeholder="ExpDate">
                                    </div>
                                </th>
                                <!-- <th scope="col">OD_EDate</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('OD_EDate')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">OD_EDate</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='OD_EDate'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='OD_EDate'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtOD_EDateFilter="ngModel" id="OD_EDateFilter"
                                            name="OD_EDateFilter" [(ngModel)]="_WorkSortingService.OD_EDateFilter"
                                            placeholder="OD_EDate">
                                    </div>
                                </th>
                                <!-- <th scope="col">PD_Date</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('PD_Date')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">PD_Date</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='PD_Date'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='PD_Date'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtPD_DateFilter="ngModel" id="PD_DateFilter"
                                            name="PD_DateFilter" [(ngModel)]="_WorkSortingService.PD_DateFilter"
                                            placeholder="PD_Date">
                                    </div>

                                </th>
                                <!-- <th scope="col">OD_PDDate</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('OD_PDDate')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">OD_PDDate</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='OD_PDDate'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='OD_PDDate'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtOD_PDDateFilter="ngModel" id="OD_PDDateFilter"
                                            name="OD_PDDateFilter" [(ngModel)]="_WorkSortingService.OD_PDDateFilter"
                                            placeholder="OD_PDDate">
                                    </div>

                                </th>
                                <!-- <th scope="col">Customer</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('CustomerName')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">Customer</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='CustomerName'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='CustomerName'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtCustomerFilter="ngModel" id="CustomerFilter"
                                            name="CustomerFilter" [(ngModel)]="_WorkSortingService.CustomerFilter"
                                            placeholder="Customer">
                                    </div>

                                </th>
                                <!-- <th scope="col">Att SE</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('EmpName')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">Att SE</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='EmpName'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='EmpName'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtAttSEFilter="ngModel" id="AttSEFilter"
                                            name="AttSEFilter" [(ngModel)]="_WorkSortingService.AttSEFilter"
                                            placeholder="Att SE">
                                    </div>

                                </th>
                                <!-- <th scope="col">MOReg</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('ModeOfReg')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">MOReg</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='ModeOfReg'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='ModeOfReg'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <select name="MORegFilter" id="MORegFilter"
                                            [(ngModel)]="_WorkSortingService.MORegFilter">
                                            <option [value]="''" selected>Select</option>
                                            <option [ngValue]="1">By Phone</option>
                                            <option [ngValue]="2">By Mail</option>
                                            <option [ngValue]="3">By Chat</option>
                                            <option [ngValue]="4" hidden>On Site</option>
                                            <option [ngValue]="5" hidden>Remote Section</option>
                                        </select>
                                    </div>

                                </th>
                                <!-- <th scope="col">Req Type</th> -->
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('TypeId')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">Req Type</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='TypeId'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='TypeId'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <select name="ReqTypeFilter" id="ReqTypeFilter"
                                            [(ngModel)]="_WorkSortingService.ReqTypeFilter">
                                            <option [value]="''">Select</option>
                                            <option *ngFor="let obj of TypeList" [ngValue]="obj.Id">
                                                {{obj.Name}}
                                            </option>
                                        </select>
                                    </div>

                                </th>
                                <th class="hidden-xs">
                                    <div class="AtjIsPoint" (click)="sort('ProjectName')">
                                        <span *ngIf="!_WorkSortingService.isFiltering">Project</span>
                                        <div class="AtjfloatRight">
                                            <i class="fas fa-sort"
                                                *ngIf="_WorkSortingService.orderByColName!='ProjectName'"></i>
                                            <div *ngIf="_WorkSortingService.orderByColName=='ProjectName'">
                                                <i class="fas fa-sort-amount-down"
                                                    *ngIf="_WorkSortingService.orderByDir"></i>
                                                <i class="fas fa-sort-amount-up-alt"
                                                    *ngIf="!_WorkSortingService.orderByDir"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="_WorkSortingService.isFiltering">
                                        <input type="text" #txtProjectFilter="ngModel" id="ProjectFilter"
                                            name="ProjectFilter" [(ngModel)]="_WorkSortingService.ProjectFilter"
                                            placeholder="Project">
                                    </div>

                                </th>
                                <th><em></em> View
                                    <a class="AtjIsPoint AtjfloatRight">
                                        <i *ngIf="!_WorkSortingService.isFiltering" class="fas fa-filter"></i>
                                        <i *ngIf="_WorkSortingService.isFiltering" class="fas fa-times"></i>
                                    </a>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="tWrap__body">
                    <table>
                        <tbody>
                            <tr
                                *ngFor="let field of this._WorkSortingService.AllWorkSorting| gridOperations  :null: 
                            {RDate:_WorkSortingService.RDateFilter,RefNo:_WorkSortingService.RefNoFilter,
                            PriorityId:_WorkSortingService.PriorityFilter,CurrentStatus:_WorkSortingService.CurrentStausFilter,
                            ExpDate:_WorkSortingService.ExpDateFilter,OD_EDate:_WorkSortingService.OD_EDateFilter,
                            PD_Date:_WorkSortingService.PD_DateFilter,OD_PDDate:_WorkSortingService.OD_PDDateFilter,
                            CustomerName:_WorkSortingService.CustomerFilter,EmpName:_WorkSortingService.AttSEFilter,
                            ModeOfReg:_WorkSortingService.MORegFilter,TypeId:_WorkSortingService.ReqTypeFilter,ProjectName:_WorkSortingService.ProjectFilter}:
                            {Column:_WorkSortingService.orderByColName,Dir:_WorkSortingService.orderByDir}; let i = index">
                                <th style="background:white;color:rgb(88, 88, 88);width: 30px;" scope="row">
                                    <i *ngIf="field.ID==this._WorkSortingService.selectedWorkSortHead.ID"
                                        class='fas fa-chevron-right' style='font-size:20px'></i>
                                    {{i+1}}
                                </th>
                                <td [title]="field.RDate| date">{{field.RDate}}</td>
                                <!-- | date -->
                                <td [title]="field.RefNo" style="width:70px;">{{field.RefNo}}</td>
                                <td>
                                    <span class="form-control-label" *ngFor="let obj of  PriorityList">
                                        <p *ngIf="obj.Id==field.PriorityId" [title]="obj.Name">
                                            {{obj.Name}}
                                        </p>
                                    </span>
                                </td>
                                <td [title]="field.CurrentStatus" style="width:120px;"> {{field.CurrentStatus}}</td>
                                <td>
                                    <p *ngIf="field.ExpDate=='0001-01-01T00:00:00'"></p>
                                    <p *ngIf="field.ExpDate!='0001-01-01T00:00:00'">{{field.ExpDate}}</p>
                                    <!-- |date -->
                                </td>
                                <td>{{field.OD_EDate}} days</td>
                                <td>
                                    <p *ngIf="field.PD_Date=='0001-01-01T00:00:00'"></p>
                                    <p *ngIf="field.PD_Date!='0001-01-01T00:00:00'">{{field.PD_Date}}</p>
                                    <!-- |date -->
                                </td>
                                <td [title]="field.OD_PDDate"> {{field.OD_PDDate}} days</td>
                                <td [title]="field.CustomerName"> {{field.CustomerName}} </td>
                                <td [title]="field.EmpName"> {{field.EmpName}} </td>
                                <td>
                                    <div class="col-md-8">
                                        <label class="form-control-label">
                                            <p *ngIf="field.ModeOfReg==0"> Select </p>
                                            <p *ngIf="field.ModeOfReg==1" title="By Phone"> By Phone </p>
                                            <p *ngIf="field.ModeOfReg==2" title="By Mail"> By Mail </p>
                                            <p *ngIf="field.ModeOfReg==3" title="By Chat"> By Chat </p>
                                            <p *ngIf="field.ModeOfReg==4" title="On Site"> On Site </p>
                                            <p *ngIf="field.ModeOfReg==5" title="Remote Section">Remote Section</p>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <span class="form-control-label" *ngFor="let obj of  TypeList">
                                        <p *ngIf="obj.Id==field.TypeId" [title]="obj.Name">
                                            {{obj.Name}}
                                        </p>
                                    </span>
                                </td>

                                <td [title]="field.ProjectName">
                                    {{field.ProjectName}}
                                </td>
                                <td>
                                    <i class="fas fa-eye" (click)="getPendingWorksDet(field)"
                                        style="font-size:24px"></i>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
<br>
        <div *ngIf="this._WorkSortingService.AllWorkSortingDet ?.length>0" class="row">
            <div class="tWrap" id="newLines">
                <div class="tWrap__head">
                    <table>
                        <tbody>
                            <tr>
                                <th style="border: 1px solid #164e86;background:#aaeff3;color: black;width: 300px">
                                    <div style="text-align: center;">
                                        <h6 style="font-weight: bold;">Branch Details </h6>
                                        <p *ngIf="this._BranchService.selectedBranch.BranchName!=null">
                                            Branch Name:{{this._BranchService.selectedBranch.BranchName}}
                                        </p>
                                        <p *ngIf="this._BranchService.selectedBranch.BranchEmail!=null">
                                            {{this._BranchService.selectedBranch.BranchPhone}},
                                            {{this._BranchService.selectedBranch.BranchEmail}}
                                        </p>
                                        <p *ngIf="this._BranchService.selectedBranch.Addr1!=null">
                                            {{this._BranchService.selectedBranch.Addr1}},
                                            {{this._BranchService.selectedBranch.Addr2}},
                                            {{this._BranchService.selectedBranch.Addr3}}
                                        </p>
                                        <p *ngIf="this._BranchService.selectedBranch.ContactPerson!=null">
                                            Contact Person:
                                            {{this._BranchService.selectedBranch.ContactPerson}}
                                            ,{{this._BranchService.selectedBranch.ContactPhone}}</p>
                                    </div>
                                </th>
                                <th style="border: 1px solid #164e86;background:#aaeff3;color: black;">
                                    <div style="text-align: center;">
                                        <h6 style="font-weight: bold;">Customer Details </h6>
                                        <p *ngIf="this._CustomerService.selectedCustomer.Name!=null">
                                            Customer Name:{{this._CustomerService.selectedCustomer.Name}}
                                        </p>
                                        <p *ngIf="this._CustomerService.selectedCustomer.CustEmail!=null">
                                            {{this._CustomerService.selectedCustomer.CustPhone}},
                                            {{this._CustomerService.selectedCustomer.CustEmail}}
                                        </p>
                                        <p *ngIf="this._CustomerService.selectedCustomer.Addr1!=null">
                                            {{this._CustomerService.selectedCustomer.Addr1}},
                                            {{this._CustomerService.selectedCustomer.Addr2}},
                                            {{this._CustomerService.selectedCustomer.Addr3}}
                                        </p>
                                        <p *ngIf="this._CustomerService.selectedCustomer.ContactPerson!=null">
                                            Contact Person:
                                            {{this._CustomerService.selectedCustomer.ContactPerson}}
                                            ,{{this._CustomerService.selectedCustomer.ContactPhone}}</p>
                                    </div>
                                </th>
                                <th style="border: 1px solid #164e86;background:#aaeff3;color: black;">
                                    <div style="text-align: center;">
                                        <h6 style="font-weight: bold;"> Installation Date </h6>
                                        <p>
                                            {{this._WorkSortingService.selectedWorkSortHead.InstallationDate |date}}
                                        </p>
                                        <h6 style="font-weight: bold;"> Project Module </h6>
                                        <p [title]="this._WorkSortingService.selectedWorkSortHead.Modules">
                                            {{this._WorkSortingService.selectedWorkSortHead.Modules }}
                                        </p>

                                    </div>
                                </th>
                                <th style="border: 1px solid #164e86;background:#aaeff3;color: black;width: 200px;">
                                    <div style="text-align: center;">
                                        <h6 style="font-weight: bold;"> </h6>
                                        <label style="color:green" class="form-control-label"
                                            *ngIf="this.AmcORChargable==1">
                                            ON
                                            AMC
                                        </label>
                                        <label style="color:red" class="form-control-label"
                                            *ngIf="this.AmcORChargable==2">
                                            Chargable
                                        </label>

                                        <p>
                                            No. Of Services: <br>( <span title="On Site"
                                                style="cursor: pointer;">{{this._WorkSortingService.selectedWorkSortHead.OnSiteCount}}
                                            </span> /
                                            <span title="Remote"
                                                style="cursor: pointer;">{{this._WorkSortingService.selectedWorkSortHead.RemoteCount}}</span>
                                            )
                                        </p>
                                        <p>
                                            Last Service Att. Person:
                                            {{this._WorkSortingService.selectedWorkSortHead.LastServiceSE}} <br> &
                                            <br> Mode Of Service:
                                            {{this._WorkSortingService.selectedWorkSortHead.LastServiceMode}}
                                        </p>
                                        <p>
                                        </p>
                                    </div>
                                </th>
                                <th style="border: 1px solid #164e86;background:#aaeff3;color: black;width: 395px;">                                 
                                    <div class="tWrap"
                                        style="height: 125px; margin-bottom: 20px; background:#aaeff3">
                                        <div class="tWrap__head">
                                            <table style="background:#aaeff3">
                                                <thead>
                                                    <tr>
                                                        <th style="background: #5d6767;">#</th>
                                                        <th style="background: #5d6767;">ATTACHMENTS</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div class="tWrap__body" style="border-bottom: 1px solid #ccc;">
                                            <table style="background:#aaeff3">
                                                <tbody>
                                                    <tr *ngFor="let data of FileList;let i = index">
                                                        <th style="background:#aaeff3;color:black; width:40px;"
                                                            scope="row">
                                                            {{i+1}}
                                                        </th>
                                                        <td (click)="downloadFileUrl(data)"
                                                            style="cursor: pointer; text-decoration: underline;">
                                                            {{data}}
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="FileList?.length<1">
                                                        <td>No File Found</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </th>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
 <!-- Popup  -->
<form #testREsult="ngForm" *ngIf="popUpIndex==1" >
    <div class="popup-container">
        <input type="checkbox" id="test-results">
        <div class="popup HoldingReason" (click)="closePopUp()">
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">            
                <div class="title">
                    <p>Holding Reason</p> 
                    <label for="test-results"> 
                        <i class="fas fa-times" (click)="closePopUp()"></i> 
                    </label>
                </div>
                <div  >
                    <div class="row">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Reason <span style="color:red ;">*</span> </label>
                        <div class="col-md-5">
                            <ng-select name="HoldingReason" id="HoldingReason" #HoldingReason="ngModel"
                            bindLabel="Name" bindValue="Id" required [items]="holdingReasonList"
                           disabled [ngModel]="holdReason.HoldingReason"
                            placeholder="Select">
                        </ng-select>                            
                        </div>
                    </div>
                    <div class="row" *ngIf="holdReason.HoldingReason==4">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Clarification From </label>
                        <div class="col-md-5">
                            <ng-select name="ClarificationFrm" id="ClarificationFrm" bindLabel="EmpName"
                                bindValue="Id" required #ClarificationFrm="ngModel"
                               disabled [ngModel]="holdReason.ExpectingResultFrom"
                                [items]="ClarificationFrom" placeholder="Select">
                            </ng-select>                        
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Reason <span style="color:red ;">*</span></label>
                        <div class="col-md-5">
                            <textarea type="text" name="Reason" id="Reason" style="width: 100%;" disabled [ngModel]="holdReason.Reason" required  ></textarea>                           
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <label class="col-3 form-control-label">Remark <span style="color:red ;">*</span></label>
                        <div class="col-md-5">
                            <textarea type="text" name="Remark" id="Remark"  required
                            [ngModel]="holdReason.Remark"    style="width: 100%;" disabled ></textarea>                            
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</form>

<!--Key expiry details -->
<app-keyexpirydetails *ngIf="this._CommonService.expDetailsPopup > 0" [expDetailsPopup]="this._CommonService.expDetailsPopup"></app-keyexpirydetails>

