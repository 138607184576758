<div class="container-fluid">
    <h2 class="heading_all">Other Charge Voucher</h2>
    <div id="form-wrap">
        <form #OtherChargeForm="ngForm">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label style="padding-top: 15px;">VNo:</label>
                        <div class="row">
                            <div class="col-md-6">
                                <input type="number" class="form-control" style="width:180px;" name="VNo" autocomplete="off"  placeholder="VNo" #VNo="ngModel" [(ngModel)]="otherChargesService.selectedOtherCharges.VNo" required>
                            </div>
                            <div class="validation-error" *ngIf="VNo.invalid && this.FormValidation">
                                This Field is Required.
                            </div>
                            <div class="col-md-6">
                                <i class="fas fa-search searchicon" aria-hidden="true" (click)="searchOtherCharge()"></i>
                            </div>
                        </div>                 
                    </div>
                    <div class="form-group">
                        <label>Dealer<span class="validation-error">*</span></label>
                        <select class="form-control" name="DealerId" #DealerId="ngModel" (ngModelChange)="loadDealerWiseCustomers()" [(ngModel)]="otherChargesService.selectedOtherCharges.DealerId" required>
                            <option [ngValue]="null">Choose Dealer</option>
                            <option *ngFor="let dealer of dealrService.allDealers | orderBy : 'DealerName'" [ngValue]="dealer.Id">
                                {{dealer.DealerName}}
                            </option>
                        </select>
                        <div class="validation-error" *ngIf="DealerId.invalid && this.FormValidation">
                            This Field is Required.
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Customer<span class="validation-error">*</span></label>
                        <ng-select name="CustomerId" id="CustomerId" bindLabel="Name" #CustomerId="ngModel" [disabled]="otherChargesService.selectedOtherCharges.DealerId==null"
                            [(ngModel)]="otherChargesService.selectedOtherCharges.CustomerId" bindValue="Id"
                            [items]="custService.allCustomers  | orderBy : 'Name'" required
                            (ngModelChange)="loadCustomerWiseBranch()">
                        </ng-select>
                        <div class="validation-error" *ngIf="CustomerId.invalid && this.FormValidation">
                            This Field is Required.
                        </div> 
                    </div>
                    <div class="form-group">
                        <label for="select">Branch<span class="validation-error">*</span></label>
                        <ng-select name="BranchId" id="BranchId" bindLabel="BranchName" #BranchId="ngModel" [disabled]="otherChargesService.selectedOtherCharges.CustomerId==null"
                            [(ngModel)]="otherChargesService.selectedOtherCharges.BranchId" bindValue="Id"
                            [items]="brnchService.allBranchs | orderBy : 'BranchName'" required>
                        </ng-select>
                        <div class="validation-error" *ngIf="BranchId.invalid && this.FormValidation">
                            This Field is Required.
                        </div>
                    </div>            
                    <div class="form-group">
                        <label>Remark:</label>
                        <textarea class="form-control" name="Remark" autocomplete="off" placeholder=" Remark" #Remark="ngModel" [(ngModel)]="otherChargesService.selectedOtherCharges.Remark"></textarea>        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label style="padding-top: 15px;">Date:<span class="validation-error">*</span></label>
                        <input class="form-control" placeholder="Date" name="Date" id="Date" style="color: black;text-align:left;width:180px;" autocomplete="off" #Date="ngModel" [(ngModel)]="otherChargesService.selectedOtherCharges.Date" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY'}" required>
                            <div class="validation-error" *ngIf="OtherChargeForm.submitted && Date.errors?.required">
                                This Field is Required.
                            </div>                        
                    </div>
                    <div class="form-group">
                        <label>Charge Type:<span class="validation-error">*</span></label>
                        <select class="form-control" name="ChargeTypeId" #ChargeTypeId="ngModel" [(ngModel)]="otherChargesService.selectedOtherCharges.ChargeTypeId" (ngModelChange)="loadBranchWiseModule('ALL')" [disabled]="(otherChargesService.selectedOtherCharges.BranchId==null || otherChargesService.selectedOtherCharges.Id > 0)" required>
                            <option [ngValue]="null">Choose Charge Type</option>
                            <option *ngFor="let chargeType of this.chargeTypeService.allChargeType" [ngValue]="chargeType.Id">{{chargeType.Name}}</option>
                        </select>
                            <div class="validation-error" *ngIf="ChargeTypeId.invalid && this.FormValidation">
                                This Field is Required.
                            </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Charge:<span class="validation-error">*</span></label>
                            <input class="form-control" name="Charge" autocomplete="off"  placeholder="Charge" (keypress)="commonService.decimalPointValidation($event)" #Charge="ngModel" [(ngModel)]="otherChargesService.selectedOtherCharges.Charge" required>
                            <div class="validation-error" *ngIf="OtherChargeForm.submitted && Charge.errors?.required && (Charge.value==0 || Charge.value==null)">
                                This Field Must be Greater than Zero.
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="this.otherChargesService.selectedOtherCharges.ChargeTypeId==6 && otherChargesService.selectedOtherCharges.CustomerId > 0">
                            <label>Batch:</label>
                            <ng-select name="BatchId" id="BatchId" bindLabel="Name" #BatchId="ngModel" [disabled]="otherChargesService.selectedOtherCharges.CustomerId==null"
                                [(ngModel)]="otherChargesService.selectedOtherCharges.BatchId" bindValue="Id"
                                [items]="this.otherChargesService.allBatch">
                            </ng-select>
                        </div>
                        <div class="col-md-6" *ngIf="(this.otherChargesService.selectedOtherCharges.ChargeTypeId==1 || this.otherChargesService.selectedOtherCharges.ChargeTypeId==4)">
                            <label>{{textType}}:</label>
                            <input class="form-control" name="Nodes" autocomplete="off" (keypress)="commonService.numberValidation($event)" minlength="1" maxlength="9" placeholder="Number of Nodes" #NumberOfNodes="ngModel" [(ngModel)]="otherChargesService.selectedOtherCharges.NoOfNodesOrCharacter" [disabled]="this.otherChargesService.selectedOtherCharges.Id > 0">
                        </div>                              
                    </div>
                    <div class="form-group" *ngIf="this.otherChargesService.selectedOtherCharges.ChargeTypeId==2">
                        <label style="padding-top: 15px;" for="ModuleName">Module:</label>
                        <div class="col-md-8 moduleBox">
                            <div *ngFor="let module of this.otherChargesService.addtionalModuleList">
                                <input type="checkbox"  name="module.ModuleName" [checked]="module.CheckStatus" [attr.disabled]="module.ModuleStatus > 1?true:null" value="{{module.ModuleId}}" (change)="selectedModule($event,module)">{{module.ModuleName}}
                            </div>
                        </div>
                    </div>                                
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width:100%;" type="button" *ngIf="this.otherChargesService.selectedOtherCharges.Id==0" (click)="onSubmit(OtherChargeForm)" class="sim-button button1">Submit</button>
                    <button *ngIf="this.otherChargesService.selectedOtherCharges.Id > 0" style="width:100%;pointer-events: none;opacity: 0.6;" type="button" disabled class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button style="width:100%;" [disabled]="this.otherChargesService.selectedOtherCharges.Id==0" type="button" class="sim-button button1" (click)="deleteOtherCharge()">Delete</button>
                </div>
                <div class="col-md-4">
                    <button style="width:100%;" type="button" class="sim-button button2" (click)="resetForm(OtherChargeForm)">Reset</button>
                </div>
            </div>                   
        </form>
    </div>
</div>
