import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SupportLeaderHomeComponent } from './support-leader-home/support-leader-home.component';
import { WorkSortingComponent } from './work-sorting/work-sorting.component';
import { WorkAllocationComponent } from './work-allocation/work-allocation.component';
import { ServiceHistoryComponent } from '../Dealers/service-history/service-history.component';


const routes: Routes = [
  { path: '', component: SupportLeaderHomeComponent },
  { path: 'worksorting', component: WorkSortingComponent },
  { path: 'workallocation', component: WorkAllocationComponent },  
  // { path: 'servicehistory', component: ServiceHistoryComponent}
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes),
  ]
})
export class SupportleaderModule { }
