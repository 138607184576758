import { Component, OnInit } from '@angular/core';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { ToastrService } from 'ngx-toastr';
import { ParticularMaster } from 'src/app/Models/ParticularMaster';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-particular-master-list',
  templateUrl: './particular-master-list.component.html',
  styleUrls: ['./particular-master-list.component.css']
})
export class ParticularMasterListComponent implements OnInit {

  constructor(
    public _ParticularmasterService: ParticularmasterService,
    public _CommonService: CommonService,
    private toastr: ToastrService
  ) { }
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  public errorMsg;
  public term: any = '';

  ngOnInit(): void {
    this.deleteID = 0;
    this.deletePopUp = false;
    this._ParticularmasterService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._ParticularmasterService.getParticularMaster("ALL")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data

      },
        error => {
          this.errorMsg = error;
        })
    this._ParticularmasterService.formHideShow = 0;
  }
  toggleFilter() {
    this._ParticularmasterService.isFiltering = this._ParticularmasterService.isFiltering ? false : true;
    if (!this._ParticularmasterService.isFiltering) {
      this._ParticularmasterService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._ParticularmasterService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._ParticularmasterService.orderByColName) {
      this._ParticularmasterService.orderByDir = !this._ParticularmasterService.orderByDir;
    }
    this._ParticularmasterService.orderByColName = colName;
  }
  NoOfItems: number = 10;


  addNew() {
    this._ParticularmasterService.formHideShow = 1;
  }
  OnEdit(selectedRow: ParticularMaster) {
    this._ParticularmasterService.selectedRecord = Object.assign({}, selectedRow);
    this._ParticularmasterService.formHideShow = 1;
  }
  onDelete(deleteID: number) {
    this.deletePopUp = true;
    this.deleteID = deleteID;

  }


  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._ParticularmasterService.onDelete(this.deleteID)
        .subscribe(x => {
          this._ParticularmasterService.getParticularMaster("ALL").subscribe(data => this._ParticularmasterService.allparticular = data,
            error => {
              this.errorMsg = error;
              console.error(error.name, error.message, error.stack);
            });
          this.toastr.success("Deleted Successfully", "Particular");
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.warning("Deletion Failure", "Particular");
        })
    }
  }
}
