import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
import { WorkSortingService } from 'src/app/Services/work-sorting.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { ModuleService } from 'src/app/Services/module.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WorkSorting, WorkSortingAssigning } from 'src/app/Models/WorkSorting.model';
import { EmployeeService } from 'src/app/Services/employee.service';
import { HoldActivityDetails } from 'src/app/Models/ActivityHold';
import { JobCardService } from 'src/app/Services/job-card.service';
import { IMG_ROOT_URL } from 'src/app/Models/Config';

@Component({
    selector: 'app-work-sorting',
    templateUrl: './work-sorting.component.html',
    styleUrls: ['./work-sorting.component.css']
})
export class WorkSortingComponent implements OnInit {
    AmcORChargable: number;
    public errorMsg;
    PriorityList: any;
    StatusList: any;
    AssignToList: any;
    ActionList: any;
    TypeList: any;
    DealerId: number;
    SupportLeaderId: number
    WorkHeaderAllotmentStatus: boolean = true
    Description: string = ''
    DscptnVisible: number = 0
    ActionTypVisible: boolean = false
    CurrentWorkStatus: boolean = false
    preActivityiIDValue: number;
    ActionIDValue: number;

    workallotmentHoldReasonsList: any[];
    selectedHoldDetails: HoldActivityDetails;
    HoldDetailsList: HoldActivityDetails[];
    DisplayWorkStatus: number = 0;
    DisableHoldUpdateButton: boolean;
    FileList: string[] = [];

    //Ready to install Section
    clickReadyToinstall: boolean

    constructor(
        public _CommonService: CommonService,
        public _WorkSortingService: WorkSortingService,
        public _ParticularmasterService: ParticularmasterService,
        public _modulService: ModuleService,
        public _CustomerService: CustomerService,
        public _CustomerrequirementService: CustomerrequirementService,
        public _BranchService: BranchService,
        public _projectService: ProjectService,
        private toastr: ToastrService,
        public _EmployeeService: EmployeeService,
        public _JobCardService: JobCardService
    ) { }

    ngOnInit(): void {
        this.ActionIDValue = 0;
        this.AmcORChargable = 0;
        this.DisplayWorkStatus = 0
        this.DisableHoldUpdateButton = false;
        this._WorkSortingService.selectedActiontype = 0
        this._WorkSortingService.selectedCheckAll = false
        this.clickReadyToinstall = false

        this.resetForm();
        this.getData()
    }

    async resetForm() {
        this.WorkHeaderAllotmentStatus = true
        this.CurrentWorkStatus = false
        this.DscptnVisible = 0
        this._WorkSortingService.RemarkHistory = []
        this._WorkSortingService.AllWorkSortingDet = []
        this._WorkSortingService.selectedWorkSortHead =
        {
            ID: 0,
            SEID: 0,
            RDate: null,
            RefNo: 0,
            BranchId: 0,
            LastServiceMode: '',
            LastServiceSE: '',
            OnSiteCount: '',
            RemoteCount: '',
            ProjectId: 0,
            ModeOfReg: 0,
            CustomerId: 0,
            PriorityId: 0,
            TypeId: 0,
            Vtype: 0,
            Remark: null,
            InstallationDate: null,
            Modules: '',
            DealerId: null,
            CustomerName: '',
            BranchName: '',
            EmpName: '',
            ProjectName: '',
            AMCDate: null,
            ExpDate: null,
            OD_EDate: 0,
            OD_PDDate: 0,
            PD_Date: null,
            CurrentStatus: ''
        }
        this._CustomerService.selectedCustomer = {
            Addr1: null,
            Addr2: null,
            Addr3: null,
            BranchName: null,
            Code: '',
            ContactEmail: '',
            ContactPerson: null,
            ContactPhone: '',
            CountryId: null,
            CustEmail: null,
            CustFax: '',
            CustPhone: null,
            DealerId: null,
            Id: 0,
            LogoURL: '',
            Name: null,
            NoOfBranches: 0,
            RouteId: 0,
            URL: '',
        }
        this._BranchService.selectedBranch = {
            Addr1: null,
            Addr2: null,
            Addr3: null,
            BranchEmail: null,
            BranchFax: null,
            BranchName: null,
            Customer:null,
            BranchPhone: null,
            ContactEmail: null,
            ContactPerson: null,
            ContactPhone: null,
            CountryId: null,
            CustomerId: null,
            DealerId: null,
            Id: null,
            RouteId: null,
            UniqueBrachId: '',
        }
        this._CommonService.LoginUser = 'SupportLeader';
        this._CommonService.FormID = 'WorkSorting';
    }
    async getData() {
        if (localStorage.getItem("Designation") == 'Support Leader')
            this.SupportLeaderId = parseInt(await this._CommonService.getLoginedUserId())
        else
            this.SupportLeaderId = 0
        this._CommonService.LoginId = this.SupportLeaderId;


        this.DealerId = parseInt(await this._CommonService.getDealerId(this._CommonService.FormID, this._CommonService.LoginId, this._CommonService.LoginUser));
        this.getListCommon();
        this.getPendingWorksHeader();
        this.ClearWorkAllotmentHoldReason()
    }


    getListCommon() {
        this.TypeList = []
        this.PriorityList = []
        this.StatusList = []
        this.TypeList = this._CommonService.GetTypeList();
        this.PriorityList = this._CommonService.GetPriorityList()
        this.StatusList = this._CommonService.GetStatusFullList();
        this.AssignToList = this._CommonService.GetAssignToList();
        this.ActionList = this._CommonService.GetWorkSortActionList();
        this.fillparticulars();
        this.fillmodule();
        this.LoadCustomers();
        this.fillServiceEngineer();
        this.fillbranch();
        this.fillproject();
    }

    getPendingWorksHeader() {
        this._CommonService.isLoading = true;
        this._WorkSortingService.getPendingWorksHeader(this.DealerId, 'ALL').subscribe(obj => {
            this._CommonService.isLoading = false;
            this._WorkSortingService.AllWorkSorting = obj
        },
            error => {
                this.errorMsg = error;
                this._CommonService.isLoading = false;
                this.toastr.error('Something went Wrong', 'Work Sorting');
            });
    }
    ClearWorkAllotmentHoldReason() {
        this.workallotmentHoldReasonsList = [
            { Id: 1, Name: 'Meeting' },
            { Id: 4, Name: 'Clarification' }
        ];
        this.selectedHoldDetails = { ClarificationFrom: null, FormID: null, ServiceCharge: null, ID: 0, MeetingID: null, Reason: '', Reasons: null, Remark: '' }
    }

    getPendingWorksDet(obj: WorkSorting) {

        this.LoadCustomerDet(obj);
        this._WorkSortingService.AllWorkSortingDet = []
        this._CommonService.isLoading = true;
        this._WorkSortingService.getPendingWorksDet(obj.DealerId, obj.RefNo, obj.Vtype).subscribe(obj => {
            this._CommonService.isLoading = false;
            console.log(obj.AssignedHisList)
            this._WorkSortingService.AllWorkSortingDet = Object.assign([], obj.AssignedHisList)
            this._WorkSortingService.selectedWorkSortHead.Remark = obj.HdrRemark
            this._WorkSortingService.RemarkHistory = obj.RemarkHistory
            if (this._WorkSortingService.AllWorkSortingDet != null) {
                this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
                    this.FileList = obj.FileList;
                    obj.ExpDate = new Date(obj.ExpDate)
                    if (obj.StatusId == 9) {
                        obj.AssTo = 0
                        obj.ExpDate = null
                    }
                    if (obj.AssTo == 0)
                        obj.ExpDate = null
                })
            }
            else
                this.toastr.warning('Work was Already Assigned', 'Work Sorting')

        },
            error => {
                this.errorMsg = error;
                this._CommonService.isLoading = false;
                this.toastr.error('Something went Wrong', 'Work Sorting');
            });
    }


    onSubmit(form: NgForm) {
        if (!this.CurrentWorkStatus) {
            // this._WorkSortingService.AllWorkSortingDet.forEach(obj => { if (obj.AssTo == 0) obj.ExpDate = new Date() })

            console.log(this._WorkSortingService.AllWorkSortingDet.filter(obj => (obj.AssTo != 0)))


            // (obj.ActionType != 1 && obj.AssTo != 0) ||
            if (this._WorkSortingService.AllWorkSortingDet.filter(obj => (obj.ActionType != 0)).length > 0 ||
                this._WorkSortingService.AllWorkSortingDet.filter(obj => (obj.ActionType == 1 && obj.ExpDate == null) || (obj.ActionType == 2 && obj.ExpDate == null)).length == 0) {
                this.WorkHeaderAllotmentStatus = false
                this._CommonService.isLoading = true;
                this._WorkSortingService.InsWorkSorting().subscribe(obj => {
                    this.WorkHeaderAllotmentStatus = true
                    this._CommonService.isLoading = false;

                    this._WorkSortingService.AllWorkSortingDet = []
                    if (obj.Status == 0)
                        this.toastr.success(obj.Message, 'Work Sorting')
                    else
                        this.toastr.warning(obj.Message, 'Work Sorting')
                    this.resetForm();
                },
                    error => {
                        this.errorMsg = error;
                        this._CommonService.isLoading = false;
                        this.toastr.error('Something went Wrong', 'Work Sorting');
                    });
            }
            else
                this.toastr.warning('Update the fileds', 'Work Sorting')
        }
        else
            this.toastr.warning('Work Is Under Progress', 'Work Sorting')
    }

    changeActionType() {
        if (this._WorkSortingService.selectedActiontype != 3) {
            this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
                if (obj.CheckStatus && (obj.StatusId == 2 || obj.StatusId == 9 || obj.StatusId == 3 || obj.StatusId == 5 || obj.StatusId == 1))
                    obj.ActionType = this._WorkSortingService.selectedActiontype
                obj.CheckStatus = false
            })
        }
        else {
            this.toastr.warning('Multiple Hold Not Allowed', 'Work Sorting')
            this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
                obj.CheckStatus = false
            })
        }
        this._WorkSortingService.selectedActiontype = 0
        this._WorkSortingService.selectedCheckAll = false
        this.ActionTypVisible = false
    }
    checkAll() {
        this._WorkSortingService.AllWorkSortingDet.forEach(obj => { obj.CheckStatus = this._WorkSortingService.selectedCheckAll })
        if (this._WorkSortingService.selectedCheckAll)
            this.ActionTypVisible = true
        else
            this.ActionTypVisible = false
    }
    OnRemoveDet(obj: WorkSortingAssigning, index: number) {
        this._WorkSortingService.AllWorkSortingDet.forEach(field => {
            if (field.ID == obj.ID) {
                field.ActionType = 4
                field.AssTo = 0
                field.ExpDate = new Date()
            }
        })
    }



    //Ready To install
    ReadytoInstall() {
        this.resetForm()
        if (this.clickReadyToinstall) {
            this.clickReadyToinstall = false
            this.getData()
        }
        else {
            this.clickReadyToinstall = true
            this._CustomerService.allCustomers = []
            this.DscptnVisible = 0
            this._WorkSortingService.RemarkHistory = []
            this._WorkSortingService.AllWorkSortingDet = []
            this._WorkSortingService.AllWorkSorting = []
            this._CustomerService.FetchAllCustomerById('DELIVERED', this.DealerId)
                .subscribe(data => {
                    this._CustomerService.allCustomers = data
                    this._WorkSortingService.selectedWorkSortHead.CustomerId = 0
                    // this._CustomerService.selectedCustomer = this._CustomerService.allCustomers[0]
                    // this.CustomerChange()
                },
                    error => {
                        this.errorMsg = error;
                    });
        }
    }
    CustomerChange() {
        this.DscptnVisible = 0
        this._WorkSortingService.RemarkHistory = []
        this._WorkSortingService.AllWorkSortingDet = []
        this._WorkSortingService.AllWorkSorting = []
        this.FileList = []
        this._BranchService.selectedBranch = {
            Addr1: null,
            Addr2: null,
            Addr3: null,
            BranchEmail: null,
            BranchFax: null,
            BranchName: null,
            Customer:null,
            BranchPhone: null,
            ContactEmail: null,
            ContactPerson: null,
            ContactPhone: null,
            CountryId: null,
            CustomerId: null,
            DealerId: null,
            Id: null,
            RouteId: null,
            UniqueBrachId: '',
        }
        this._CommonService.isLoading = true;
        this._WorkSortingService.GetdeliveryItem(this._WorkSortingService.selectedWorkSortHead.CustomerId, 'ALL').subscribe(obj => {
            this._CommonService.isLoading = false;
            this._WorkSortingService.AllWorkSorting = obj.WorkSortingHdr
            console.log('obj:', obj)
            this._WorkSortingService.AllWorkSortingDet = Object.assign([], obj.WorkSortingDet.AssignedHisList == null ? [] : obj.WorkSortingDet.AssignedHisList)
            if (obj.ImageList != null) {
                obj.ImageList.forEach(element => {
                    this.FileList.push(element.FileName)
                });
            }
            if (this._WorkSortingService.AllWorkSorting.length > 0)
                this.LoadCustomerDet(this._WorkSortingService.AllWorkSorting[0])

            if (this._WorkSortingService.AllWorkSortingDet.length > 0) {
                console.log('    this._WorkSortingService.AllWorkSortingDet :', this._WorkSortingService.AllWorkSortingDet)
                this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
                    obj.ExpDate = new Date(obj.ExpDate)
                    if (obj.StatusId == 9) {
                        obj.AssTo = 0
                        obj.ExpDate = null
                    }
                })
            }
            else
                this.toastr.warning('Work was Already Assigned', 'Work Sorting')
        },
            error => {
                this.errorMsg = error;
                this._CommonService.isLoading = false;
                this.toastr.error('Something went Wrong', 'Work Sorting');
            });
    }


    LoadCustomerDet(obj: WorkSorting) {
        //Check whether Work Under Progress
        this.CurrentWorkStatus = false
        this.ActionTypVisible = false
        this._WorkSortingService.selectedCheckAll = false

        this.AmcORChargable = 0;
        this.DscptnVisible = 0;
        this.Description = ''

        this._WorkSortingService.selectedWorkSortHead = obj;
        this.AmcORChargable = new Date(obj.AMCDate) >= new Date() ? 1 : 2;
        this._CustomerService.FetchAllCustomerById('CUSTOMERID', obj.CustomerId).subscribe(obj => {
            this._CustomerService.selectedCustomer = obj[0]
        })

        this._BranchService.Fetch_BranchById(obj.BranchId, 'BRANCHID').subscribe(obj => {
            this._BranchService.selectedBranch = obj[0]
        })
    }


    ClearWorkSortHoldReason() {
        this.selectedHoldDetails = { ClarificationFrom: null, FormID: null, ID: 0, ServiceCharge: null, MeetingID: null, Reason: '', Reasons: null, Remark: '' }
    }

    closePopUp() {
        this.ClearWorkAllotmentHoldReason();
        this.DisplayWorkStatus = 0;
        this.preActivityiIDValue = 0;
        this.ActionIDValue = 0;
        this.DscptnVisible = 0;
    }

    selectHoldingReason(obj: HoldActivityDetails) {
        this.selectedHoldDetails = Object.assign({}, obj);
    }


    toggleFilter() {
        this._WorkSortingService.isFiltering = this._WorkSortingService.isFiltering ? false : true;
        if (!this._WorkSortingService.isFiltering) {
            this._WorkSortingService.ClearFilter();
        }
    }

    //Sorting
    sort(colName: string) {
        if (colName == this._WorkSortingService.orderByColName) {
            this._WorkSortingService.orderByDir = !this._WorkSortingService.orderByDir;
        }
        this._WorkSortingService.orderByColName = colName;
    }


    onUpdate(obj: WorkSortingAssigning) {
        this.DscptnVisible = 1;
        this.Description = obj.Description;

    }
    UpdateActionTypeCombo() {
        if (this._WorkSortingService.AllWorkSortingDet.filter(obj => obj.CheckStatus).length > 0) {
            this.ActionTypVisible = true
            if (this._WorkSortingService.AllWorkSortingDet.length == this._WorkSortingService.AllWorkSortingDet.filter(obj => obj.CheckStatus).length)
                this._WorkSortingService.selectedCheckAll = true
            else
                this._WorkSortingService.selectedCheckAll = false
        }
        else
            this.ActionTypVisible = false
    }

    UpdateActionTypeDet(field: WorkSortingAssigning) {
        if (field.ActionType == 2 || field.ActionType == 3) {
            this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
                if (obj.ID == field.ID) {
                    obj.ExpDate = new Date()
                    obj.AssTo = 0
                }
            })
            if (field.ActionType == 3) {
                this.getHoldingDetails(field);
            }
        }
    }

    getHoldingDetails(obj: WorkSortingAssigning) {
        if (obj.StatusId == 6 || obj.StatusId == 7 || obj.StatusId == 8 || obj.StatusId == 4 || obj.AssTo != 0) {
            this.toastr.warning('Work is Under Progress', 'Work Allocation');
        }
        else {
            if (obj.ActionType == 2) {
                this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
                    if (obj.ID == obj.ID) {
                        obj.ExpDate = new Date()
                        obj.AssTo = 0
                    }
                })
            }
            this.DisableHoldUpdateButton = false;
            this.DisplayWorkStatus = 1;
            this.preActivityiIDValue = obj.ID;
            this._CommonService.GetHoldActivityDetails('WORKSORTING', obj.ID).subscribe(data => {
                this._EmployeeService.Fetch_EmployeeById('JOBCARD', obj.ID).subscribe(data2 => {
                    this._JobCardService.allEmployee = data2;
                    this.ActionIDValue = obj.ID;
                    this.HoldDetailsList = data;
                },
                    error => {
                        this.errorMsg = error;
                    })
            },
                error => {
                    this.errorMsg = error;
                    this._CommonService.isLoading = false;
                    this.toastr.error('Something went Wrong', 'Work Sorting');
                });
        }
    }
    updateHoldReason() {
        if (this.selectedHoldDetails.Reasons > 0 && (this.selectedHoldDetails.ClarificationFrom > 0) && this.selectedHoldDetails.Reason.length > 0) {
            if (this.selectedHoldDetails.ID == 0) {
                this.ActionIDValue = 3;
            }
            this.ActionIDValue = 3;
            this._CommonService.HoldActivity('WORKSORTING', this.selectedHoldDetails.ID, this.preActivityiIDValue, this.ActionIDValue, this.selectedHoldDetails.Reasons, this.selectedHoldDetails.ClarificationFrom, 0, this.selectedHoldDetails.Reason, this.selectedHoldDetails.Remark, 0).subscribe(data => {
                if (data.Status == 0) {
                    this.toastr.success(data.Message, 'Work Sorting');
                    let i = this._WorkSortingService.AllWorkSortingDet.findIndex(arr => arr.ID == this.preActivityiIDValue);
                    this._WorkSortingService.AllWorkSortingDet[i].ActionType = 3;
                    this.DisplayWorkStatus = 0;
                    this.preActivityiIDValue = 0;
                    this.ActionIDValue = 0;
                    this.ClearWorkAllotmentHoldReason();

                }
                else {
                    this.toastr.warning(data.Message, 'Work Sorting');
                }
            },
                error => {
                    this.errorMsg = error;
                    this._CommonService.isLoading = false;
                    this.toastr.error('Something went Wrong', 'Work Sorting');
                });
        }
        else {
            this.toastr.warning('Please fill the required fields.', 'Work Sorting');
        }
    }

    downloadFileUrl(URL) {
        let imagePath: string
        if (this.clickReadyToinstall)
            imagePath = 'Images/WorkDelivery/'
        else
            imagePath = 'Images/SoftwareRegistration/'

        window.open(IMG_ROOT_URL + imagePath + URL, '_blank');
    }

    GetexpectedDate(field: WorkSortingAssigning) {
        this._WorkSortingService.GetexpectedDate(field).subscribe(date => {
            this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
                if (obj.ID == field.ID && date != '0001-01-01T00:00:00')
                    obj.ExpDate = new Date(date)
            })
        })
    }

    async LoadCustomers() {
        this._CustomerService.FetchAllCustomerById('DEALERID', this.DealerId)
            .subscribe(data => {
                this._CustomerService.allCustomers = data
            },
                error => {
                    this.errorMsg = error;
                });
    }
    fillServiceEngineer() {
        this._EmployeeService.Fetch_Employee('SUPPORTLEADERID')
            .subscribe(data => {
                this._CustomerrequirementService.allSE = data;
            },
                error => {
                    this.errorMsg = error;
                })
    }

    fillproject() {
        this._projectService.fetchProject('ALL', 0)
            .subscribe(data => this._projectService.allProjects = data,
                error => {
                    this.errorMsg = error;
                });
    }

    fillbranch() {
        this._BranchService.Fetch_BranchById(this.DealerId, 'DEALERID')
            .subscribe(data => {
                this._BranchService.allBranchs = data
            },
                error => {
                    this.errorMsg = error;
                });
    }

    fillparticulars() {
        this._ParticularmasterService.getParticularMaster("COMBO")
            .subscribe(data => {
                this._ParticularmasterService.allparticular = data
            },
                error => {
                    this.errorMsg = error;
                })
    }

    fillmodule() {
        this._modulService.getModule()
            .subscribe(data => {
                this._modulService.allModules = data
            },
                error => {
                    this.errorMsg = error;
                })
    }
}
