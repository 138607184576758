import { Component, OnInit } from '@angular/core';
import { customer } from 'src/app/Models/customer.model';
import { BranchsettingsService } from 'src/app/Services/branchsettings.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { NgForm } from '@angular/forms';
import { BranchService } from 'src/app/Services/branch.service';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-branch-settings-form',
  templateUrl: './branch-settings-form.component.html',
  styleUrls: ['./branch-settings-form.component.css']
})
export class BranchSettingsFormComponent implements OnInit {

  Country_Id: any;
  public errorMsg;
  FileInvalidMessage = '';
  FileisValid = false;
  fileToUpload: File = null;
  selectedCustomer: customer;
  allCustomers1: customer[];
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  constructor(
    public _BranchsettingsService: BranchsettingsService,
    private toastr: ToastrService,
    public _countryService: CountryService,
    public _dealrService: DealerService,
    public _custService: CustomerService,
    public _CommonService: CommonService,
    public _brnchService: BranchService,
  ) { }

  ngOnInit(): void {
    this._countryService.getCountry()
      .subscribe(data => this._countryService.allCountries = data,
        error => {
          this.errorMsg = error;
        });
    this.resetForm();
    this.LoadDealerByDealerId();
    this.fillbranch();
  }
  LoadDealerByDealerId() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        this._dealrService.allDealers = data
        this._BranchsettingsService.selectedBranchSettings.DealerId = data[0].Id;

      })
  }
  LoadCustomers() {

    this._custService.FetchAllCustomer('DEALERID')
      .subscribe(data => this._custService.allCustomers = data,
        error => {
          this.errorMsg = error;
        });
  }

  fillbranch() {
    let customerID = this._BranchsettingsService.selectedBranchSettings.CustomerId == null ? 0 : this._BranchsettingsService.selectedBranchSettings.CustomerId
    this._brnchService.Fetch_BranchById(customerID, 'CUSTOMERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
        console.log(this._brnchService.allBranchs)
        this._BranchsettingsService.selectedBranchSettings.BranchId = null
        let branchList = data;
        if (branchList.length == 1)
          branchList.filter(e => {
            if (e.CustomerId == e.CustomerId) {
              this._BranchsettingsService.selectedBranchSettings.BranchId = e.Id
            }
          })

      },
        error => {
          this.errorMsg = error;
        });
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._BranchsettingsService.selectedBranchSettings = {
      Id: null,
      BranchId: null,
      DealerId: null,
      CustomerId: null,
      LicenseKeyValDays: null,
      RemainderExp: null,
      YearlyReminderExp: null,
      YearlyReminderExpfreq: null,
      SystemReplacement: null,
      BlockLicenseKeyIssue: null,
      VersionChange: null,
      CustomerProfileUpdation: null,
      NextRenewalValidDays: null,
    }
    this.LoadDealerByDealerId();
  }
  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._BranchsettingsService.formHideShow = 0;

  }
  formValidation(): boolean {
    if (this._BranchsettingsService.selectedBranchSettings.CustomerId > 0 && this._BranchsettingsService.selectedBranchSettings.LicenseKeyValDays != 0 && this._BranchsettingsService.selectedBranchSettings.RemainderExp != 0
      && this._BranchsettingsService.selectedBranchSettings.YearlyReminderExp != 0 && this._BranchsettingsService.selectedBranchSettings.YearlyReminderExpfreq != 0 && this._BranchsettingsService.selectedBranchSettings.BlockLicenseKeyIssue != null
      && this._BranchsettingsService.selectedBranchSettings.VersionChange != null && this._BranchsettingsService.selectedBranchSettings.CustomerProfileUpdation != null && this._BranchsettingsService.selectedBranchSettings.SystemReplacement != 0
      && this._BranchsettingsService.selectedBranchSettings.BranchId > 0)
      return true;
    else
      return false;
  }
  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid && this.formValidation()) {
      this.isSubmit = true
      form.value.DealerId = this._BranchsettingsService.selectedBranchSettings.DealerId
      this._CommonService.isLoading = true
      this._BranchsettingsService.InsUpdDelBranchSettings(this._BranchsettingsService.selectedBranchSettings)
        .subscribe(data => {
          this._CommonService.isLoading = false
          this.isSubmit = false
          if (data.Status == 0)
            this.toastr.success(data.Message, 'Branch settings');
          else
            this.toastr.warning(data.Message, 'Branch settings');

          this.getAllBranchsettings();
          this.backList()
        },
          error => {
            this._CommonService.isLoading = false
            this.errorMsg = error;
            this.toastr.error('New Record Added Failure', 'Branch settings');
          })
      this._BranchsettingsService.formHideShow = 0;
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Branch settings');
    }
  }

  getAllBranchsettings() {
    this._BranchsettingsService.Fetch_BranchSettings('DEALERID')
      .subscribe(data => {
        this._BranchsettingsService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        })
  }
  keyPress(event: any) {
    if (event.charCode !== 0) {
      const pattern = /[0-9\+]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode != 13) {
        event.preventDefault();
      }
    }
  }
}
