<div class="container-fluid" *ngIf="_typeService.formHideShow==0">
    <h2 class="heading_all">BusinessType</h2>
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">
                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width:60%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('BTypeCode')">
                            <span *ngIf="!_typeService.isFiltering">BTypeCode</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_typeService.orderByColName!='BTypeCode' "></i>
                                <div *ngIf="_typeService.orderByColName=='BTypeCode'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_typeService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_typeService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_typeService.isFiltering">
                            <input type="text" #txtBTypeCodeFilter="ngModel" [(ngModel)]="_typeService.BTypeCodeFilter"
                                placeholder="BTypeCode">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('BType')">
                            <span *ngIf="!_typeService.isFiltering">Name</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_typeService.orderByColName!='BType' "></i>
                                <div *ngIf="_typeService.orderByColName=='BType'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_typeService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_typeService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_typeService.isFiltering">
                            <input type="text" #txtBTypeFilter="ngModel" [(ngModel)]="_typeService.BTypeFilter"
                                placeholder="BType">
                        </div>
                    </th>
                    <th><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_typeService.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="_typeService.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- ngRepeat: item in items -->
                <!-- <tr *ngFor="let type of _typeService.allBusinessTypes | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope"> -->
                <tr *ngFor="let type of _typeService.allBusinessTypes | orderBy : 'BType'| gridOperations  :_typeService.pageIndex: 
                        {BTypeCode:_typeService.BTypeCodeFilter,BType:_typeService.BTypeFilter}:
                       {Column:_typeService.orderByColName,Dir:_typeService.orderByDir} ; let i = index"
                    class="ng-scope">
                    <td>{{i+1+((NoOfItems*_typeService.pageIndex)-NoOfItems)}}</td>
                    <td>{{type.BTypeCode}}</td>
                    <td>{{type.BType}}</td>
                    <td>
                        <button type="button" class="btn-success" (click)="showForEdit(type)">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                        <button type="button" class="btn-danger" (click)="onDelete(type)"><i class="fas fa-trash"
                                aria-hidden="true"></i></button>
                    </td>
                </tr><!-- end ngRepeat: item in items -->
                <tr>
                    <td colspan="3"
                        *ngIf="(_typeService.allBusinessTypes?.length<=0 )  || _CommonService.filterdItemCount<1">
                        No Records To
                        Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="_typeService.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        <div class="margintop"></div>
        <!-- </div>
    </div> -->
    </div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'BUSINESS TYPE'" (ConfirmEvent)="dialogResult($event)">
</app-delete-confirm>