import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { WorkAllocationStatusService } from 'src/app/Services/work-allocation-status.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CommonService } from 'src/app/Services/common.service';
import { WorkStatus, WorkStatusDet, WorkStatusAttachments } from 'src/app/Models/WorkSorting.model';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { ModuleService } from 'src/app/Services/module.service';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';
import { EmployeeService } from 'src/app/Services/employee.service';
import * as $ from "jquery";
import { HoldActivityDetails } from 'src/app/Models/ActivityHold';
import { IMG_ROOT_URL } from 'src/app/Models/Config';
import { WorkDeliveryService } from 'src/app/Services/work-delivery.service';
import { PLWorkDetailsHdr } from 'src/app/Models/PLWorkDetailsHdr.model';
import { ProjectLeaderService } from 'src/app/Services/project-leader.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';


@Component({
  selector: 'app-work-allocation-status',
  templateUrl: './work-allocation-status.component.html',
  styleUrls: ['./work-allocation-status.component.css']
})
export class WorkAllocationStatusComponent implements OnInit {

  constructor(public _countryService: CountryService,
    public _CommonService: CommonService,
    public _dealrService: DealerService,
    public _CustomerService: CustomerService,
    public _ParticularmasterService: ParticularmasterService,
    public _modulService: ModuleService,
    private toastr: ToastrService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _EmployeeService: EmployeeService,
    public _workAllocationStatusService: WorkAllocationStatusService,
    public _WorkDeliveryService: WorkDeliveryService,
    public _ProjectLeaderService: ProjectLeaderService) { }

  public errorMsg;
  PriorityList: any;
  StatusFullList: any
  selectWorkStatusDet: WorkStatusDet
  consoldatedStatus: boolean
  // DeliveredBy: number
  DeliverPopUp: boolean;
  WorkSortingHdrID: number
  deliverButtonVisible: boolean = false;
  selectedHeaderID: number = 0;
  Description: string = '';
  FileList: any[] = [];
  FileInvalidMessage = '';
  FileisValid = false;
  ActionList: any;
  preActionValue: number;
  preActivityiIDValue: number;
  ActionIDValue: number;
  HoldDetailsList: HoldActivityDetails[];
  workallotmentHoldReasonsList: any[];
  selectedHoldDetails: HoldActivityDetails;
  displayHoldpopUp: number;
  HolePopUpEmpList: any[];
  DisableHoldUpdateButton: boolean;
  SubmitButtonClickCheck: boolean;
  WorkDetailsHdr: PLWorkDetailsHdr[]

  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  RejectReason: string = '';
  holdOrReject: number = 0;

  ngOnInit(): void {
    this.SubmitButtonClickCheck = false;
    this.HolePopUpEmpList = [];
    this.displayHoldpopUp = 0;
    this.workallotmentHoldReasonsList = [
      { Id: 1, Name: 'Meeting' },
      { Id: 4, Name: 'Clarification' },
      { Id: 6, Name: 'Approval for SC' } //5 Service Charge Confirmation' in Work Allotment
    ];
    this.ActionList = [
      {
        Id: 1,
        Name: 'Accepted',
      },
      {
        Id: 2,
        Name: 'Rejected',
      },
      {
        Id: 3,
        Name: 'Hold',
      },
      {
        Id: 4,
        Name: 'Removed',
      },
      {
        Id: 5,
        Name: 'Back to Dealer',
      }
    ];
    this.selectedHeaderID = 0;
    // this.DeliverPopUp = false;
    // if (localStorage.getItem("Designation") == 'Project Manager')
    //   this.DeliveredBy = CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8);
    // else
    //   this.DeliveredBy = 0
    this._workAllocationStatusService.CountryId = null
    this._workAllocationStatusService.DealerId = null
    this.consoldatedStatus = true
    this._countryService.getCountry()
      .subscribe(data => {
        this._countryService.allCountries = data
      },
        error => {
          this.errorMsg = error;
        });

    this.LoadDealer();
    this.Reset();
    this.LoadCustomers();
    this.getEmployee();
    this.fillmodule()
    this.fillparticulars()
    this.PriorityList = this._CommonService.GetPriorityList()
    this.StatusFullList = this._CommonService.GetStageFullProviderList()
    this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate = null;

  }

  toggleFilter() {
    this._workAllocationStatusService.isFiltering = this._workAllocationStatusService.isFiltering ? false : true;
    if (!this._workAllocationStatusService.isFiltering) {
      this._workAllocationStatusService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._workAllocationStatusService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._workAllocationStatusService.orderByColName) {
      this._workAllocationStatusService.orderByDir = !this._workAllocationStatusService.orderByDir;
    }
    this._workAllocationStatusService.orderByColName = colName;
  }

  NoOfItems: number = 10;

  getEmployee() {
    this._EmployeeService.Fetch_Employee('ADMIN').subscribe(data => {
      this._workAllocationStatusService.allEmployee = data;
    },
      error => {
        this.errorMsg = error;
      })
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('ALL')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  Reset(form?: NgForm) {
    this._CustomerrequirementService.DescriptionEdit = ''

    if (form != null)
      form.reset();
    this.FileList = []
    $("#customFile").val('');
    this.isSubmit = false
    this.FormValidation = false
    this._workAllocationStatusService.SelectedWorkStatusHdr = {
      ID: 0,
      DealerId: 0,
      WorkSortingHdrID: 0,
      RefNo: 0,
      VType: 0,
      Remark: '',
      Charge: 0,
      // EmpId: 0,
      PlannedDeliveryDate: new Date(),
      WorkStatusAttachmentsList: [],
      WorkStatusAttachmentRemovedList: [],

    }
    this._workAllocationStatusService.BtmVisible = false
    this._workAllocationStatusService.WorkStatusList = []
    this._workAllocationStatusService.SelectedWorkStatusDet = []
    this.deliverButtonVisible = false;
  }

  fillparticulars() {
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  onUpdate(obj: WorkStatusDet) {
    this.Description = obj.Description;
    this._CustomerrequirementService.DescriptionEdit = obj.Description;
    this._workAllocationStatusService.BtmVisible = true;
    this.selectedActivityID = obj.ID;
  }

  fillmodule() {
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  LoadDealer() {
    this._dealrService.FetchDealerById('COUNTRYID', 0)
      .subscribe(
        data => {
          this._dealrService.allDealers = data
          this._workAllocationStatusService.DealerId = null
        }
        ,
        error => {
          this.errorMsg = error;
        });
  }

  getPendingWorks() {
    this.Reset()
    this._workAllocationStatusService.SelectedWorkStatusDet = []
    this._CommonService.isLoading = true;
    this._workAllocationStatusService.getWorkStatusHeader()
      .subscribe(obj => {
        this._workAllocationStatusService.WorkStatusList = obj
        this._CommonService.isLoading = false;
      });

  }

  //amala commit
  getWorkAllocStatusDet(obj: WorkStatus) {
    this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentsList = []
    this.FileList = []
    // this._workAllocationStatusService.BtmVisible = false;
    this.selectedHeaderID = obj.WorkSortHdrId;
    this._workAllocationStatusService.SelectedWorkStatusDet = []
    this._workAllocationStatusService.SelectedWorkStatusHdr.ID = obj.ID
    this._workAllocationStatusService.SelectedWorkStatusHdr.RefNo = obj.RefNo
    this._workAllocationStatusService.SelectedWorkStatusHdr.VType = obj.VType
    this._workAllocationStatusService.SelectedWorkStatusHdr.DealerId = obj.DealerId
    this.WorkSortingHdrID = obj.WorkSortHdrId;
    this._CommonService.isLoading = true;
    this._workAllocationStatusService.getWorkStatusDet(this._workAllocationStatusService.SelectedWorkStatusHdr)
      .subscribe(obj => {
        console.log('obj', obj)
        this._workAllocationStatusService.SelectedWorkStatusDet = obj
        this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentRemovedList = []
        this.deliverButtonVisible = false;
        // this._workAllocationStatusService.SelectedWorkStatusDtlsHdr.Tasks = [];
        this._workAllocationStatusService.SelectedWorkStatusDet.forEach(field => {
          this._workAllocationStatusService.SelectedWorkStatusHdr.ID = field.WorkStatusHdrId
          this._workAllocationStatusService.SelectedWorkStatusHdr.Remark = field.Remark
          this._workAllocationStatusService.SelectedWorkStatusHdr.Charge = field.Charge
          this._CommonService.isLoading = false;
          this._workAllocationStatusService.FetchWorkStatusAttachments(this._workAllocationStatusService.SelectedWorkStatusHdr.ID)
            .subscribe(data => {
              this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentsList = data

            },
              error => {
                this.errorMsg = error;
              });
          this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate = field.PlannedDeliveryDate != null ? new Date(field.PlannedDeliveryDate) : null;
          field.ExpStDate == null ? field.ExpStDatetoBind = null : field.ExpStDatetoBind = new Date(field.ExpStDate)
          field.ExpCompDate == null ? field.ExpCompDatetoBind = null : field.ExpCompDatetoBind = new Date(field.ExpCompDate)
        })
      })

  }

  UpdateExpStDate(obj1, field: WorkStatusDet) {
    this._workAllocationStatusService.SelectedWorkStatusDet.filter(obj => {
      if (obj.ID == field.ID) {
        obj.ExpStDatetoBind = obj1
        obj.ExpStDate = this.convert(obj1)
      }
    })
  }

  UpdateExpCompDate(obj1, field: WorkStatusDet) {
    this._workAllocationStatusService.SelectedWorkStatusDet.filter(obj => {
      if (obj.ID == field.ID) {
        obj.ExpCompDatetoBind = obj1
        obj.ExpCompDate = this.convert(obj1)
      }
    })
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }


  handleFileInput(event) {

    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        this.FileList.push(files[i])

      }
    }
  }
  RemoveImage(image: File) {
    const index = this.FileList.indexOf(image);
    if (index > -1) {
      this.FileList.splice(index, 1);
    }
  }
  RemovePreviousImage(obj: WorkStatusAttachments) {

    const index = this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentsList.indexOf(obj);
    if (index > -1) {
      this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentRemovedList.push(obj)
      this._workAllocationStatusService.SelectedWorkStatusHdr.WorkStatusAttachmentsList.splice(index, 1);
    }
  }

  formValidation(): boolean {
    if (this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate != "" && this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate != null)
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {

    this.FormValidation = true
    if (this.formValidation()) {
      this.isSubmit = true
      this.consoldatedStatus = true
      this._workAllocationStatusService.SelectedWorkStatusHdr.WorkSortingHdrID = this.WorkSortingHdrID;
      // this._workAllocationStatusService.SelectedWorkStatusHdr.EmpId = this.DeliveredBy;
      this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate = this.convert(this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate)


      this._workAllocationStatusService.WorkStatusIns(this._workAllocationStatusService.SelectedWorkStatusHdr, this.FileList).subscribe(obj => {
        if (obj.Status == 0) {
          this.isSubmit = false
          this.toastr.success(obj.Message, 'Work Status')
          this.getPendingWorks();

        }
        else
          this.toastr.warning('Updation Failed', 'Work Status')
        this.Reset();

      })
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Work Status');
    }
  }

  changeActionID(preObj: WorkStatusDet, ActionID) {

    this.ClearHoldReasonPopUp();
    this.DisableHoldUpdateButton = false;
    preObj.CheckStatus = false;
    this.ActionIDValue = ActionID;
    this.preActionValue = preObj.ActionID;
    this.preActivityiIDValue = preObj.ID;
    if (ActionID == 3) {
      this.holdOrReject = 0;
      this.displayHoldpopUp = 1;
      this._EmployeeService.Fetch_EmployeeById('JOBCARD', this.preActivityiIDValue).subscribe(data => {
        this.HolePopUpEmpList = data;
        this._CommonService.GetHoldActivityDetails('WORKSTATUS', this.preActivityiIDValue).subscribe(data2 => {
          this.HoldDetailsList = data2;
        });
      });

    }
    else if (ActionID == 2) {
      this.RejectReason = '';
      this.holdOrReject = 1;
      this.displayHoldpopUp = 2;
    }
    else {
      this.RejectReason = '';
      preObj.RejectReason = '';
      this._CommonService.ChangeActionID('WORKSTATUS', this.preActivityiIDValue, ActionID, '').subscribe(data => {
        if (data.Status == 0) {
          this.toastr.success(data.Message, 'Work Status');
        }
        else {
          this.toastr.warning(data.Message, 'Work Status');
        }
      })
    }

  }
  closeHoldPopUp() {
    this.displayHoldpopUp = 0;
    this._workAllocationStatusService.SelectedWorkStatusDet.forEach(data => {
      if (data.ID == this.preActivityiIDValue) {
        data.ActionID = this.preActionValue;
      }
    });
    this.ClearHoldReasonPopUp();

    this.preActivityiIDValue = 0;
    this.preActionValue = 0;
    this.ActionIDValue = 0;
  }
  ClearHoldReasonPopUp() {
    this.selectedHoldDetails = { ClarificationFrom: null, FormID: null, ID: 0, MeetingID: null, Reason: '', Reasons: null, Remark: '', ServiceCharge: null }

  }
  selectHoldingReason(obj: HoldActivityDetails) {
    this.selectedHoldDetails = Object.assign({}, obj);
  }

  updateHoldReason() {
    if (this.selectedHoldDetails.Reasons > 0 &&
      (this.selectedHoldDetails.ClarificationFrom > 0 || this.selectedHoldDetails.ServiceCharge > 0) && this.selectedHoldDetails.Reason.length > 0) {
      if (this.selectedHoldDetails.ID == 0) {
        this.ActionIDValue = 3;
      }
      this.ActionIDValue = 3;
      this.SubmitButtonClickCheck = true;
      this._CommonService.HoldActivity('WORKSTATUS', this.selectedHoldDetails.ID, this.preActivityiIDValue, this.ActionIDValue, this.selectedHoldDetails.Reasons, this.selectedHoldDetails.ClarificationFrom, 0, this.selectedHoldDetails.Reason, this.selectedHoldDetails.Remark, this.selectedHoldDetails.ServiceCharge).subscribe(data => {

        if (data.Status == 0) {
          this.toastr.success(data.Message, 'Work Status');
          // if(this.selectedHoldDetails.ID==0){

          // let i=this._ProjectLeaderService.PLWorkDetailsHdrList.findIndex(arr=>arr.ActivityID==this.preActivityiIDValue);
          // this._ProjectLeaderService.PLWorkDetailsHdrList[i].ActionID=3;
          //}
          this.displayHoldpopUp = 0;
          this.preActivityiIDValue = 0;
          this.preActionValue = 0;
          this.ActionIDValue = 0;
          this.ClearHoldReasonPopUp();
        }
        else {
          this.toastr.warning(data.Message, 'Work Status');
        }
        this.SubmitButtonClickCheck = false;
      });
    }
    else {
      this.toastr.warning('Please fill the required fields.', 'Work Status');
    }
  }
  getHoldingDetails(obj) {
    if (obj.ActionID == 2) {
      this.holdOrReject = 1;
    }
    else {
      this.holdOrReject = 0;
    }
    this.ClearHoldReasonPopUp();
    this.DisableHoldUpdateButton = obj.Stage != 5;
    this.displayHoldpopUp = 1;
    this.RejectReason = obj.RejectReason;
    this._CommonService.GetHoldActivityDetails('WORKSTATUS', obj.ID).subscribe(data => {

      this._EmployeeService.Fetch_EmployeeById('JOBCARD', obj.ID).subscribe(data2 => {
        this.HolePopUpEmpList = data2;
        this.preActivityiIDValue = obj.ID;
        this.ActionIDValue = obj.ActionID;
        this.preActionValue = obj.ActionID;
        this.HoldDetailsList = data;

      },
        error => {
          this.errorMsg = error;
        })

    })

  }
  downloadFileUrl(URL) {
    window.open(IMG_ROOT_URL + 'Images/WorkStatus/' + URL, '_blank');
  }
  UpdateRejectReason() {
    if (this.RejectReason.length > 0) {
      this._CommonService.ChangeActionID('REJECTREASONSTATUS', this.preActivityiIDValue, 2, this.RejectReason).subscribe(data => {
        if (data.Status == 0) {
          let i = this._workAllocationStatusService.SelectedWorkStatusDet.findIndex(arr => arr.ID == this.preActivityiIDValue);
          this._workAllocationStatusService.SelectedWorkStatusDet[i].RejectReason = this.RejectReason;
          this.RejectReason = ''
          let j = this._workAllocationStatusService.SelectedWorkStatusDet.findIndex(arr => arr.ID == this.preActivityiIDValue);
          this._workAllocationStatusService.SelectedWorkStatusDet[i].ActionID = 2;
          this.toastr.success(data.Message, 'Work Status');
          this.RejectReason = '';
          this.preActivityiIDValue = 0;
          this.preActionValue = 0;
          this.ActionIDValue = 0;
          this.displayHoldpopUp = 0;
        }
        else {
          this.toastr.warning(data.Message, 'Work Status');
        }
      });


    }
    else {
      this.toastr.warning('Please enter your reason.', 'Work Status');
    }
  }

  closeDescriptionPopUp() {
    this._CustomerrequirementService.DescriptionEdit = ''
    this._workAllocationStatusService.BtmVisible = false;
    this.selectedActivityID = 0;
  }
  selectedActivityID: number;
  updateDescription() {
    this._CommonService.ChangeActionID('DESCRIPTIONUPDATE', this.selectedActivityID, 0, this._CustomerrequirementService.DescriptionEdit).subscribe(data => {
      if (data.Status == 0) {
        this.toastr.success(data.Message, 'Work Status');
        var i = this._workAllocationStatusService.SelectedWorkStatusDet.findIndex(e => e.ID == this.selectedActivityID);
        this._workAllocationStatusService.SelectedWorkStatusDet[i].Description = this._CustomerrequirementService.DescriptionEdit;
        this.closeDescriptionPopUp();
      }
      else {
        this.toastr.warning(data.Message, 'Work Status');
      }
    });
  }
}