import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/Services/project.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.css']
})
export class ProjectFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  constructor(public _prjctService: ProjectService, 
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this._prjctService.formHideShow = 0;
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._prjctService.selectedProject = {
      Id: null,
      Name: '',
      Code: '',
      MonthlyRent:0,
      YearlyRent:0,
      AddUserMonthlyRent:0,
      AddUserYearlyRent:0,
      Cloud:false
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._prjctService.formHideShow = 0;
  }

  validation(): boolean {
    if (this._prjctService.selectedProject.Name != "" && this._prjctService.selectedProject.Code != "")
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid && this.validation()) {
      this.isSubmit = true
      this._prjctService.saveProject(this._prjctService.selectedProject)
        .subscribe(data => {
          this.isSubmit = false
          if (data.Status === 0) {
            this._prjctService.fetchProject('ALL', 0)
              .subscribe(data => {
                this._prjctService.allProjects = data
              },
                error => {
                  // this.errorMsg = error;
                })
            this.toastr.success(data.Message, "Project");
            if (this._prjctService.selectedProject.Id == 0) {
              this._prjctService.ClearFilter();
            }
            this.backList();
          }
          else {
            this.toastr.warning(data.Message, "Project");
          }
        },
          error => {
            this.errorMsg = error;
          })
      // error => {this.errorMsg = error;
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Project');
    }
  }
}
