import { CustomerService } from './../../../../Services/customer.service';
import { DealerService } from './../../../../Services/dealer.service';
import { ReportsService } from 'src/app/Services/reports.service';
import { CommonService } from 'src/app/Services/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent implements OnInit {
  public errorMsg;
  constructor(public commonService:CommonService,
              public reportsService:ReportsService,
              public dealerService: DealerService,
              public customerService:CustomerService) { }

  ngOnInit(): void {
    this.commonService. GetVoucherType();
    this.reportsService.loadCustomer();
    this.reportsService.loadDealer();
    this.incomeDetails();
  }

  incomeDetails()
  {
    this.commonService.isLoading = true;
    this.reportsService.getIncomeDetails().subscribe(
      data=>{
        this.reportsService.incomedetails=data;
        this.commonService.isLoading = false;
      },
      error => {
        this.errorMsg = error;
        this.commonService.isLoading = false;
      });
  }
}
