import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HoldActivityDetails } from 'src/app/Models/ActivityHold';
import { EmpWorkStatus, PLWorkDetailsHdr } from 'src/app/Models/PLWorkDetailsHdr.model';
import { CommonService } from 'src/app/Services/common.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { JobCardService } from 'src/app/Services/job-card.service';
import { ModuleService } from 'src/app/Services/module.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { ProjectLeaderService } from 'src/app/Services/project-leader.service';
import { ProjectService } from 'src/app/Services/project.service';
import { IMG_ROOT_URL } from 'src/app/Models/Config';
import { CustomerRequirement_Attachments } from 'src/app/Models/CustomerRequirements';
import { WorkAllocationStatusService } from 'src/app/Services/work-allocation-status.service';
import { WorkDeliveryService } from 'src/app/Services/work-delivery.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-work-delivery',
  templateUrl: './work-delivery.component.html',
  styleUrls: ['./work-delivery.component.css']
})
export class WorkDeliveryComponent implements OnInit {

  constructor(
    public _ProjectLeaderService: ProjectLeaderService,
    public _CommonService: CommonService,
    public _ParticularmasterService: ParticularmasterService,
    public _modulService: ModuleService,
    public _CustomerService: CustomerService,
    public _EmployeeService: EmployeeService,
    public _dealrService: DealerService,
    public _projectService: ProjectService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _JobCardService: JobCardService,
    public _workAllocationStatusService: WorkAllocationStatusService,
    public _WorkDeliveryService: WorkDeliveryService,
    private toastr: ToastrService,
  ) { }

  chkSelectAll: boolean = false;
  customerList: any[];
  SelectedCustomerID: number;
  selectedBatchId:number=0;
  SelectedDeliveryId: number
  SelectedStatus: number;
  isFiltering: boolean = false;
  StatusFullList: any;
  StageFullList: any;
  TypeList: any;
  PriorityList: any;
  OpenDescriptionPopup: boolean
  selectedHoldDetails: HoldActivityDetails;
  qaRemark: string;
  public errorMsg;
  fileToUpload: any[] = [];

  ngOnInit(): void {
    this.TypeList = this._CommonService.GetTypeList();
    this.PriorityList = this._CommonService.GetPriorityList();
    this.StatusFullList = [
      {
        Id: 0,
        Name: 'Pending',
      },
      {
        Id: 1,
        Name: 'WIP',
      },
      {
        Id: 2,
        Name: 'Finished',
      },
      {
        Id: 3,
        Name: 'Hold',
      },
      {
        Id: 4,
        Name: 'Unable to Complete',
      },
      {
        Id: 5,
        Name: 'Testing Finished',
      },
      {
        Id: 6,
        Name: 'QA Finished',
      }
    ];
    this.qaRemark = '';
    this.customerList = [];
    this.fileToUpload = []
    this.SelectedCustomerID = 0;
    this.SelectedDeliveryId = 0;
    this.SelectedStatus = 0;
    this.Reset();
    this.LoadDealer();
    this.fillparticulars();
    this.StageFullList = this._CommonService.GetStageFullProviderList();
    this.fillmodule();
    this.LoadCompletedWorks();
  }

  Reset(form?: NgForm) {
    this.OpenDescriptionPopup = false
    this.SelectedCustomerID = 0
    this._ProjectLeaderService.JobAssignAttachmentList = []
    this._ProjectLeaderService.ClearFilter();
    if (form != null)
      form.resetForm()
    this._workAllocationStatusService.SelectedWorkStatusHdr = {
      Charge: 0,
      DealerId: 0,
      ID: 0,
      PlannedDeliveryDate: new Date(),
      RefNo: 0,
      Remark: '',
      VType: 0,
      WorkSortingHdrID: 0,
      WorkStatusAttachmentRemovedList: [],
      WorkStatusAttachmentsList: []
    }
    this.fileToUpload = []
    this.chkSelectAll = false
    this._ProjectLeaderService.PLWorkDetailsFilter = {
      CustomerID: '',
      CustomerName: '',
      DealerId: '',
      DealerName: '',
      EstHrs: '',
      JoNo: '',
      ModuleId: '',
      ModuleText: '',
      ParticularId: '',
      ParticularText: '',
      PriorityId: '',
      PriorityText: '',
      RegDate: '',
      RegNo: '',
      RepDate: '',
      Stage: '',
      StageText: '',
      Status: '',
      StatusText: '',
      TestResult: '',
      TestResultText: '',
      Type: '',
      TypeText: '',
      Vtype: '',
      VtypeText: '',
      ActivityID: null,
      EmployeeId:'',
      Batch:'',
      PDate:'',
      EstDate:''
    }
  }
  refno: number;
  DealerId: number;
  BranchId: number;
  getCusReqOrServiceEntry(obj: PLWorkDetailsHdr) {
    this.refno = obj.RegNo;
    this.DealerId = obj.DealerId;
    this.BranchId = obj.BranchId;
    if (obj.Vtype == 0) {
      this._CommonService.customerrequirementPopUp = true
      this._CommonService.servicereportentryPopUp = false
    }
    if (obj.Vtype == 1) {
      this._CommonService.servicereportentryPopUp = true
      this._CommonService.customerrequirementPopUp = false
    }
  }

  CustomerChange() {
    this.SelectedCustomerID = this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID == '' ? 0 : parseInt(this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID)
    this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(obj => obj.CheckStatus = false)
    this.chkSelectAll = false;
    this.loadBatch();
  }

  loadBatch()
  {
    this._WorkDeliveryService.allBatch=[];
    this.selectedBatchId=0;
    if(this.SelectedCustomerID > 0){
      this._CommonService.getCommonList('BATCHLIST',this.SelectedCustomerID).subscribe(
        data=>{this._WorkDeliveryService.allBatch=data
        },
        error=>{this.errorMsg=error}
      )
    }   
  }

  LoadCompletedWorks() {
    this._CommonService.isLoading=true;
    this.customerList=[];
    this._ProjectLeaderService.fetchProjectDelivery('delivery',this._JobCardService.statusType).subscribe(obj => {  
    this._ProjectLeaderService.PLWorkDetailsHdrList=obj;
    this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered = obj;
    this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered.forEach((data, i) => {
      this._ProjectLeaderService.PLWorkDetailsHdrList[i].IsDeletable = !this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered.some(ar => ar.JoNo == data.JoNo && (ar.Status == 1 || ar.Status == 3));
      if (!(this.customerList.some(arr => arr.Id == data.CustomerID))) {
        this.customerList.push({ Id: data.CustomerID, Name: data.CustomerName });
      }
     });
    });
    this._CommonService.isLoading=false;
  }

  SelectAllCheckBox() {
    if (this.chkSelectAll) {
      this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(obj => {
        if (obj.DeliveryDate =='' && ((obj.Status == 0 || obj.Status == 2 || obj.Status == 4 || obj.Status == 6) && (this.SelectedCustomerID == obj.CustomerID) || (this.SelectedCustomerID == 0))) {
          obj.CheckStatus = true;
          this.SelectedCustomerID = obj.CustomerID != 0 ? obj.CustomerID : this.SelectedCustomerID;
          this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID = this.SelectedCustomerID.toString()
        }
      })
      if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '').length > 0) {
        this.SelectedDeliveryId = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.WorkDeliveryHdrId != 0)[0].WorkDeliveryHdrId
        this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '')[0].DeliveryDate == '' ? new Date() : new Date(this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '')[0].DeliveryDate)
        this._workAllocationStatusService.SelectedWorkStatusHdr.Charge = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '')[0].Charge
      }
      else {
        this._workAllocationStatusService.SelectedWorkStatusHdr = {
          Charge: 0,
          DealerId: 0,
          ID: 0,
          PlannedDeliveryDate: new Date(),
          RefNo: 0,
          Remark: '',
          VType: 0,
          WorkSortingHdrID: 0,
          WorkStatusAttachmentRemovedList: [],
          WorkStatusAttachmentsList: []
        }
      }
    }
    else {
      this.SelectedCustomerID = 0;
      this._ProjectLeaderService.ClearFilter();
      this._ProjectLeaderService.PLWorkDetailsHdrList = this._ProjectLeaderService.PLWorkDetailsHdrListNotFiltered
      this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(obj => {
        obj.CheckStatus = false;
      })
    }
  }

  SelectCheckBox(ActivityId, value: boolean) {
    if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(ele => (ele.CheckStatus) && (ele.CustomerID == this.SelectedCustomerID)) || value) {
      if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.ActivityID == ActivityId).length > 0) {
        this.SelectedCustomerID = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.ActivityID == ActivityId)[0].CustomerID;
        this.SelectedStatus = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.ActivityID == ActivityId)[0].Status;
        this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.ActivityID == ActivityId)[0].CustomerID.toString()
        if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(ele => (ele.CheckStatus) && (ele.CustomerID == this.SelectedCustomerID)).length == 0)
          this.chkSelectAll = false
        if ((this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CustomerID == this.SelectedCustomerID).length == this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => (obj.CustomerID == this.SelectedCustomerID) && obj.CheckStatus).length) && this.SelectedCustomerID != 0) {
          this.chkSelectAll = true
        }
        else {
          this.chkSelectAll = false
          if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus).length == 0) {
            this._ProjectLeaderService.ClearFilter();
            this.SelectedCustomerID = 0
          }
        }
      }

      if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '').length > 0) {
        this.SelectedDeliveryId = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.WorkDeliveryHdrId != 0)[0].WorkDeliveryHdrId
        this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '')[0].DeliveryDate == '' ? new Date() : new Date(this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '')[0].DeliveryDate)
        this._workAllocationStatusService.SelectedWorkStatusHdr.Charge = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.DeliveryDate != '')[0].Charge
      }
      else {
        this._workAllocationStatusService.SelectedWorkStatusHdr = {
          Charge: 0,
          DealerId: 0,
          ID: 0,
          PlannedDeliveryDate: new Date(),
          RefNo: 0,
          Remark: '',
          VType: 0,
          WorkSortingHdrID: 0,
          WorkStatusAttachmentRemovedList: [],
          WorkStatusAttachmentsList: []
        }
      }
    }
    else {
      this._ProjectLeaderService.ClearFilter();
      this.chkSelectAll = false
      this.SelectedCustomerID = 0;
      this.SelectedStatus = 0;
    }

    if (value && this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus).length > 0) {
      this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.ActivityID == ActivityId)[0].CustomerID.toString();
      if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.StageText === 'Deliverd').length > 0)
        this._workAllocationStatusService.SelectedWorkStatusHdr.Charge = this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus && obj.StageText === 'Deliverd')[0].Charge
      else
        this._workAllocationStatusService.SelectedWorkStatusHdr.Charge = 0
    }
  }

  toggleFilter() {
    this.isFiltering = this.isFiltering ? false : true;
    if (!this.isFiltering) {
      this._ProjectLeaderService.ClearFilter();

    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._ProjectLeaderService.orderByColName) {
      this._ProjectLeaderService.orderByDir = !this._ProjectLeaderService.orderByDir;
    }
    this._ProjectLeaderService.orderByColName = colName;
  }

  DisplayWorkStatus: number = 0;
  workStatus: EmpWorkStatus[] = [];
  selectedStageObj: PLWorkDetailsHdr;
  getWorkStatus(obj: PLWorkDetailsHdr) {
    this.workStatus = [];
    this.selectedStageObj = obj;
    if (obj.JoNo > 0 && obj.ActivityID > 0) {
      this._ProjectLeaderService.GetWorkStatus(obj.JoNo, obj.ActivityID).subscribe(data => {
        this.workStatus = data;
        this.DisplayWorkStatus = 1;
      })
    }
  }

  fillparticulars() {
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillmodule() {
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillproject() {
    this._projectService.fetchProject('ALL', 0)
      .subscribe(data => {
        this._projectService.allProjects = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  LoadDealer() {
    this._dealrService.FetchDealer('ALL')
      .subscribe(
        data => {
          this._dealrService.allDealers = data;
        },
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('SERVICEENG')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  OpenDescPopup(Description: string) {
    this._CustomerrequirementService.DescriptionEdit = Description
    this.OpenDescriptionPopup = true
  }

  preActionValue: number;
  preActivityiIDValue: number;
  ActionIDValue: number;
  closePopUp() {
    this.qaRemark = '';
    this.selectedStageObj = null;
    if (this.DisplayWorkStatus == 3) {
      this.DisplayWorkStatus = 1;
      return
    }
    if (this.DisplayWorkStatus == 4) {
      this._ProjectLeaderService.PLWorkDetailsHdrList.forEach(data => {
        if (data.ActivityID == this.preActivityiIDValue) {
          data.ActionID = this.preActionValue;
        }
      });
      this.ClearWorkAllotmentHoldReason();
    }
    this.DisplayWorkStatus = 0;
    this.preActivityiIDValue = 0;
    this.preActionValue = 0;
    this.ActionIDValue = 0;
  }

  ClearWorkAllotmentHoldReason() {
    this.selectedHoldDetails = { ClarificationFrom: null, FormID: null, ID: 0, MeetingID: null, Reason: '', ServiceCharge: null, Reasons: null, Remark: '' }
  }

  getEmployee() {
    this._EmployeeService.Fetch_Employee('ELSE').subscribe(data => {
      this._JobCardService.allEmployee = data;
    },
      error => {
        this.errorMsg = error;
      })
  }

  getholdReason(JobDetails: EmpWorkStatus) {
    this.DisplayWorkStatus = 3;
    this.getEmployee();
    this._JobCardService.newJobDetails = {
      ID: 0,
      JONO: null,
      Date: new Date(),
      Emp_ID: null,
      Activity: null,
      W_Date: new Date(),
      W_DateFrom: new Date(),
      W_DateTo: new Date(),
      W_From: '',
      W_To: '',
      Break: null,
      NextDay: false,
      Status: null, StatusText: '',
      Batch: 0,
      HoldingReason: { HoldingReason: null, Remark: '', Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: null, ID: 0, JONO: null, MettingWith: null, OtherEmergencyWork: null, Reason: '' }
    }
    if (JobDetails.JobCardID) {
      this._JobCardService.getHoldReason(JobDetails.JobCardID).subscribe(data => {
        this._JobCardService.newJobDetails.HoldingReason.MettingWith = data.MettingWith;
        this._JobCardService.newJobDetails.HoldingReason.HoldingReason = data.HoldingReason;
        this._JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom = data.ExpectingResultFrom;
        this._JobCardService.newJobDetails.HoldingReason.OtherEmergencyWork = data.OtherEmergencyWork;
        this._JobCardService.newJobDetails.HoldingReason.Remark = data.Remark;
        this._JobCardService.newJobDetails.HoldingReason.Reason = data.Reason;

      })
    }
  }

  //Insert Attachment
  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        this.fileToUpload.push(files[i]);
      }
    }
  }

  downloadWorkAllotmentFile(URL) {
    window.open(IMG_ROOT_URL + 'Images/ProviderWorkAllocation/' + URL, '_blank');
  }

  RemoveImage(image: File) {
    const index = this.fileToUpload.indexOf(image);
    if (index > -1) {
      this.fileToUpload.splice(index, 1);
    }
  }

  RemovePreviousImage(obj: CustomerRequirement_Attachments) {
    const index = this._ProjectLeaderService.JobAssignAttachmentList.indexOf(obj);
    if (index > -1) {
      this._ProjectLeaderService.JobAssignAttachementRemovedList.push(obj)
      this._ProjectLeaderService.JobAssignAttachmentList.splice(index, 1);
    }
  }

  TaskDeliver() {
    if (this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus == true).length > 0) {
      this._CommonService.isLoading = true;
      this._WorkDeliveryService.providerWorkDelivery(this._ProjectLeaderService.PLWorkDetailsHdrList.filter(obj => obj.CheckStatus == true), 'WorkDelivery', this.fileToUpload, parseInt(this._ProjectLeaderService.PLWorkDetailsFilter.CustomerID)).subscribe(obj => {
        this._CommonService.isLoading = false;
        if (obj.Status == 0) { this.toastr.success(obj.Message, 'Work Delivery') }
        else
          this.toastr.error(obj.Message, 'Work Delivery')
        this.Reset()
        this.LoadCompletedWorks();
      },
        (err: HttpErrorResponse) => {
          this._CommonService.isLoading = false;
        })
    }
  }

  taskUnDelivered(activityId:number,workDeliveryId:number)
  {
    if(workDeliveryId >0)
    {
      this._WorkDeliveryService.providerWorkUnDelivered(activityId,workDeliveryId).subscribe(
        obj=>{
          if(obj.Status==200)
          {
            this.toastr.success(obj.Message, 'Work Delivery')
            this.Reset()
            this.LoadCompletedWorks();
          }
          else
          {
            this.toastr.error(obj.Message, 'Work Delivery')
          }
        }
      )
    }
  }
}
