<div class="container-fluid" *ngIf="_ReasonmasterService.formHideShow!=0">
    <h2 class="heading_all">REASON MASTER</h2>
    <br>
    <div id="form-wrap">
        <form #reasonmasterForm="ngForm" (ngSubmit)="onSubmit(reasonmasterForm)">
            <div class="col-md-12">
                <div class="form-group row">
                    <label>Code<span class="validation-error">*</span></label>
                    <input name="Code" #Code="ngModel" autocomplete="off"
                        [(ngModel)]="_ReasonmasterService.selectedRecord.Code" placeholder=" Code" required>
                    <div class="validation-error"
                        *ngIf="(Code.invalid && Code.touched)||(Code.invalid &&this.FormValidation)">This Field is Required.</div>
                </div>
                <div class="form-group row">
                    <label>Reason<span class="validation-error">*</span></label>
                    <input name="Reason" #Reason="ngModel" autocomplete="off"
                        [(ngModel)]="_ReasonmasterService.selectedRecord.Reason" placeholder=" Reason" required>
                    <div class="validation-error"
                        *ngIf="(Reason.invalid && Reason.touched)||(Reason.invalid &&this.FormValidation)">This Field is Required.</div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(reasonmasterForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>