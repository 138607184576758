import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { CustomerFeedBackMaster } from '../Models/CustomerFeedBackMaster';
import { GlobalErrorHandlerService } from './global-error-handler.service'

@Injectable({
  providedIn: 'root'
})
export class CustomerfeedbackmasterService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  
  selectedRecord:CustomerFeedBackMaster;
  CustomerFeedBackMasterList:CustomerFeedBackMaster[];
  formHideShow = 0;
  allCustomerFeedBackMaster:CustomerFeedBackMaster[]; 
  pageIndex: number = 0;
  isFiltering: boolean = false;
  CodeFilter: string = '';
  QuestionFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;
  clearValue(){
    this.selectedRecord={
      ID:0,
      Code:'',
      Question:''
    }
  }

  ClearFilter() {
    this.isFiltering = false;
    this.CodeFilter = '';
    this.QuestionFilter = '';

  }
  getCustomerFeedBackMaster(TransMode: string): Observable<CustomerFeedBackMaster[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<CustomerFeedBackMaster[]>(ROOT_URL + 'CustomerFeedBackMaster/GetAllData', {
      headers, params
    })
      .pipe(
        
      );
  }

  SaveItem(Values: CustomerFeedBackMaster): Observable<any> {

    var body = {
      Id: Values.ID,
      Code: Values.Code,
      Question: Values.Question
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "CustomerFeedBackMaster/InsUpdDelCustomerFeedBackMaster", body, {
      headers
    })
      .pipe(
       
      );
  }
  onDelete(Id:number){
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.delete<any>(ROOT_URL + 'CustomerFeedBackMaster/DeleteCustomerFeedBackMaster', {
      headers, params

    })
      .pipe(
       
      );
    }

}
