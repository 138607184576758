import { Component, OnInit, Input } from '@angular/core';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { Chart } from 'chart.js';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CommonService } from 'src/app/Services/common.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { WorkSortingService } from 'src/app/Services/work-sorting.service';
import { SECustomDashBoardCustReq, SEDashBoardCustReq } from 'src/app/Models/Dashboard.Model';
import { NgForm } from '@angular/forms';
import { BranchService } from 'src/app/Services/branch.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { WorkAllocationService } from 'src/app/Services/work-allocation.service';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { ProjectService } from 'src/app/Services/project.service';
import { ModuleService } from 'src/app/Services/module.service';
import { ModuleServiceReport } from 'src/app/Models/module.model';
import { ServiceReportEntryService } from 'src/app/Services/service-report-entry.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-custom-service-report-entry',
  templateUrl: './custom-service-report-entry.component.html',
  styleUrls: ['./custom-service-report-entry.component.css']
})
export class CustomServiceReportEntryComponent implements OnInit {
  private chart: AmChart;
  errorMsg: any;
  PriorityList: any;

  jobOrderForm: boolean;
  serviceEngWF: boolean;
  WarningModel: boolean;

  StatusList: any;
  AssignToList: any;
  StatusFullList: any;
  TypeList: any;
  SupportEngId: any


  AMCRenewalStatus: boolean
  CustomerRequirementForm: boolean

  StatusFullListCustom: any;
  ServiceReportEntryForm: boolean
  ModuleDateFullList: any = []

  SECustomDashBoardCustReqRefNo: any;
  SECustomDashBoardCustReqDealerId: any;
  SECustomDashBoardCustReqBranchId: any;

  @Input() refno: number
  @Input() DealerId: number
  @Input() BranchId: number


  constructor(
    public AmCharts: AmChartsService,
    private toastr: ToastrService,
    public _CustomerService: CustomerService,
    public _ServiceReportEntryService: ServiceReportEntryService,
    public _CommonService: CommonService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _serviceEngineerDBService: ServiceEngineerDBService,
    public _BranchService: BranchService,
    public _EmployeeService: EmployeeService,
    public _WorkSortingService: WorkSortingService,
    public _WorkAllocationService: WorkAllocationService,
    public _modulService: ModuleService,
    public _projectService: ProjectService,
    public _nodeRegService: NodeRegistrationService,
    public _ParticularmasterService: ParticularmasterService,
  ) { }

  ngOnInit(): void {

    this.Reset();
    this.getDealerId()
    this.LoadCustomers();
    this.fillServiceEngineer();
    this.AssignToList = this._CommonService.GetAssignToList();
    this._serviceEngineerDBService.AllWorkSortingDet = []
    this.getListCommonServicereportEntry();
    this.getCusReqOrServiceEntry();
  }

  async getDealerId() {
    if (localStorage.getItem("Designation") == 'Support Engineer')
      this.SupportEngId = parseInt(await this._CommonService.getLoginedUserId());
    else
      this.SupportEngId = '0'
    this._CommonService.FormID = 'SRE';
    this._CommonService.LoginId = this.SupportEngId

    if (localStorage.getItem("Designation") == 'Support Engineer') {
      this._CommonService.LoginUser = 'SupportEngineer';
    }
    else {
      this._CommonService.LoginId = this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId;
      this._CommonService.LoginUser = 'Provider';
    }
  }
  Reset(form?: NgForm) {
    this._BranchService.selectedBranch = {
      Addr1: null,
      Addr2: null,
      Addr3: null,
      BranchEmail: null,
      BranchFax: null,
      BranchName: null,
      BranchPhone: null,
      ContactEmail: null,
      ContactPerson: null,
      ContactPhone: null,
      Customer:null,
      CountryId: null,
      CustomerId: null,
      DealerId: null,
      Id: null,
      RouteId: null,
      UniqueBrachId: '',
      // LicenseKeyValDays: null,
      // RemainderExp: null,
      // YearlyReminderExp: null,
      // YearlyReminderExpfreq: null,
      // BlockLicenseKeyIssue: null,
      // VersionChange: null,
      // CustomerProfileUpdation: null,
      // NextRenewalValidDays: null,
    }

    this._WorkAllocationService.SelectedJobOrderHdr = {
      BranchAdr1: null,
      BranchAdr2: null,
      BranchAdr3: null,
      BranchId: null,
      CPBranch: null,
      CustAdr1: null,
      CustAdr2: null,
      CustAdr3: null,
      CustomerId: null,
      CPCutomer: null,
      IssueDate: null,
      JoNo: null,
      CPBranchPhone: null,
      CPCustomerPhone: null,
      From: null,
      To: null,
      FromTime: null,
      ServiceMode: 0,
      ToTime: null,
      WorkSortingAssigning: [],
      SEWorkDet: [],
      Notes: null,
      CurrentCharge:0,
      ServiceCharge: 0,
      EmployeeType: 0,
      AttachmentList: [],
      ProjectId:0,
      ContractDate:null,
      ContractVsCurrentDate:null,
      NumberOfVisit:0
    }

    //customerrequirement
    this._CustomerrequirementService.selectedRecord = {
      ID: 0,
      RefNo: 0,
      Time: null,
      ContractDate: null,
      SEID: null, PlannedDeliveryDate: null,
      ModeOfReg: null,
      Date: new Date(),
      BranchID: null, ExpectedDate: null, Remark: '',
      BranchAdr1: "",
      BranchAdr2: "",
      BranchAdr3: "",
      AMCUPTo: null,
      CustomerID: null,
      CustAdr1: "",
      CustAdr2: "",
      CustAdr3: "",
      ProjectID: null,
      Server: 0,
      Node: 0,
      NoOfVisits: 0,
      Address: "",
      Note: "",
      CusAttachementList: null,
      CusAttachementRemovedList: null,
      CusReqDtls: null,
      ContractVsCurrentDate: null,
      DealerId: 0
    }
    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._modulService.allModuleServiceReport = []
    this._CustomerrequirementService.allCustomerDtls = []

    this._CustomerrequirementService.RowCount = 0;
    this._CustomerrequirementService.allCustomerReqHistory = []

    // service report entry
    this._ServiceReportEntryService.FormUpdation = false

    this._ServiceReportEntryService.SelectedServiceRepHeader = {
      AMCRenewal: null,
      AMCUpto: null,
      BranchAdr1: '',
      BranchAdr2: '',
      BranchAdr3: '',
      BranchId: null,
      ContractDate: null,
      CustAdr1: '',
      CustAdr2: '',
      CustAdr3: '',
      CustomerId: null,
      Date: new Date(),
      From: null,
      Node: 0,
      NumberOfVisit: 0,
      ProjectId: null,
      RefNo: 0,
      Se: 0,
      Server: null,
      SupportMode: null,
      To: null,
      Notes: '',
      ExtraTime: false,
      ServiceCharge: 0,
      PaidAmount: 0,
      BalanceAmount: 0,
      TransNOtherCharges: 0,
      ContractVsCurrentDate: null,
      DealerId: null,
      ServiceDetails: [],
      ServiceModules: []
    }

    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._ServiceReportEntryService.selectedServiceRequirement = []
    this._ServiceReportEntryService.SelectedServiceDetails = []
    this._modulService.allModuleServiceReport = []

    this._ServiceReportEntryService.newAttribute = {
      SNo: 1,
      Particular: 0,
      RefNo: 0,
      ModuleId: 0,
      Description: '',
      Type: 0,
      Priority: 0,
      Status: 2,
      Remarks: ''
    }
    this._ServiceReportEntryService.RowCount = 0;

  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomerById('SUPPORTENG', this.SupportEngId)
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('ALL')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }
  getListCommonServicereportEntry() {

    this.PriorityList = []
    this.StatusList = []
    this.TypeList = []
    this.StatusFullList = []
    this._ServiceReportEntryService.SelectedServiceDetails = []
    this.StatusFullList = this._CommonService.GetStatusFullList();
    this.PriorityList = this._CommonService.GetPriorityList()
    this.StatusList = this._CommonService.GetStatusList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusFullListCustom = this._CommonService.GetStatusFullListCustom();


  }

  getCusReqOrServiceEntry() {
    this.SECustomDashBoardCustReqRefNo = this.refno;
    this.SECustomDashBoardCustReqDealerId = this.DealerId;
    this.SECustomDashBoardCustReqBranchId = this.BranchId;

    this.SearchserviceReport(this.SECustomDashBoardCustReqDealerId, this.SECustomDashBoardCustReqRefNo)
    $('#myModalConfirm2').css("display", "block")
    this.jobOrderForm = false
    this.CustomerRequirementForm = false
    this.WarningModel = false
    this.serviceEngWF = false
    this.ServiceReportEntryForm = true


    this._BranchService.Fetch_BranchById(this.SECustomDashBoardCustReqBranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.selectedBranch = obj[0]
    })
  }



  fillproject() {
    this._projectService.fetchProject('BYBRANCHID', this._CustomerrequirementService.selectedRecord.BranchID == null ? 0 : this._CustomerrequirementService.selectedRecord.BranchID)
      .subscribe(data => {
        this._projectService.allProjects = data
        this._CustomerrequirementService.selectedRecord.ProjectID = null
        if (this._projectService.allProjects.length == 1)
          this._CustomerrequirementService.selectedRecord.ProjectID = this._projectService.allProjects[0].Id
        this.GetAllModules();
      },
        error => {
          this.errorMsg = error;
        });
  }

  ModuleDateList: ModuleServiceReport
  SearchserviceReport(DealerId: number, RefNo: number) {
    //   this.SearchOrNewSRE = true;
    this._ServiceReportEntryService.FormUpdation = true
    this.AMCRenewalStatus = false
    this.ModuleDateFullList = []
    if (this._ServiceReportEntryService.SelectedServiceRepHeader.RefNo != null)
      this._ServiceReportEntryService.getServiceReportEntry(DealerId, RefNo).subscribe(obj => {
        console.log('**********SelectedServiceRepHeader***********', Object.assign({}, obj))
        if (obj.RefNo != 0) {
          this._ServiceReportEntryService.SelectedServiceRepHeader = Object.assign({}, obj);
          this._ServiceReportEntryService.SelectedServiceDetails = obj.ServiceDetails
          this._modulService.allModuleServiceReport = []
          this._ServiceReportEntryService.SelectedServiceRepHeader.SupportMode = obj.SupportMode;
          this._ServiceReportEntryService.SelectedServiceRepHeader.Se = obj.Se;
          this._ServiceReportEntryService.SelectedServiceRepHeader.Date = new Date(obj.Date);
          this._ServiceReportEntryService.SelectedServiceRepHeader.To = null;
          this._ServiceReportEntryService.SelectedServiceRepHeader.From = null;
          this._ServiceReportEntryService.selectedServiceRequirement = obj.CustumerRequDtls;

          this._ServiceReportEntryService.RowCount = this._ServiceReportEntryService.selectedServiceRequirement.length;
          this._ServiceReportEntryService.newAttribute = {
            SNo: this._ServiceReportEntryService.RowCount + 1,
            Particular: 0,
            RefNo: 0,
            ModuleId: 0,
            Description: '',
            Type: 0,
            Priority: 0,
            Status: 2,
            Remarks: ''
          }
          this.LoadBranchSer(Object.assign({}, obj).BranchId, Object.assign({}, obj).ProjectId);
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = Object.assign({}, obj).BranchId;
          this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = Object.assign({}, obj).ProjectId;

          this.onSelect2(1)
          this.onSelect2(2)

          this._CommonService.LoadSoftWareDtlsCommon(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId, this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId).
            subscribe(obj => {
              this._ServiceReportEntryService.SelectedServiceRepHeader.Server = obj.NoOfServer
              this._ServiceReportEntryService.SelectedServiceRepHeader.Node = obj.Nodes
              this._ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal = new Date(obj.AMCRenewal)
              this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto = new Date(obj.AMCUPTo)
              this._ServiceReportEntryService.SelectedServiceRepHeader.ContractDate = new Date(obj.ContractDate)
              this._ServiceReportEntryService.SelectedServiceRepHeader.ContractVsCurrentDate = obj.ContractVsCurrentDate
              if (this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto > new Date())
                this.AMCRenewalStatus = true
            })


          this._CustomerrequirementService.GetCustomerRequirementsHistory(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId, this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId, 0)
            .subscribe(obj => {
              this._ServiceReportEntryService.SelectedServiceRepHeader.NumberOfVisit = obj.NoOfVisits
              this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate
              this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
              this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate
              this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate
            })

          this.ModuleDateFullList = obj.ServiceModules;
          this.ModuleDateList = {
            ExeDate: null,
            ExeDateToBind: null,
            Id: 0,
            ModuleCode: '',
            ModuleName: '',
            ScriptDate: null,
            ScriptDateToBind: null
          }
          this.ModuleDateFullList.forEach(obj => {
            this.ModuleDateList.ExeDate = obj.ExeDate == null ? null : obj.ExeDate;
            this.ModuleDateList.ExeDateToBind = obj.ExeDate == null ? null : new Date(obj.ExeDate);
            this.ModuleDateList.Id = obj.Id;
            this.ModuleDateList.ModuleCode = obj.ModuleCode;
            this.ModuleDateList.ModuleName = obj.ModuleName;
            this.ModuleDateList.ScriptDate = obj.ScriptDate == null ? null : obj.ScriptDate;
            this.ModuleDateList.ScriptDateToBind = obj.ScriptDate == null ? null : new Date(obj.ScriptDate);
            this._modulService.allModuleServiceReport.push(this.ModuleDateList)
            this.ModuleDateList = {
              ExeDate: null,
              ExeDateToBind: null,
              Id: 0,
              ModuleCode: '',
              ModuleName: '',
              ScriptDate: null,
              ScriptDateToBind: null
            }
          })
        }
        else {
          this.toastr.warning('Service Report Does not Exist', 'Service Report Entry')
          this._ServiceReportEntryService.FormUpdation = false
        }

      })
  }

  LoadBranchSer(BranchId: number, ProjectId: number) {
    if (this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId != null) {
      this._BranchService.Fetch_BranchById(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, 'CUSTOMERID')
        .subscribe(data => {
          this._BranchService.allBranchs = data
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = BranchId
          if (this._BranchService.allBranchs.length == 1) {
            this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = this._BranchService.allBranchs[0].Id
            this.onSelect2(1)
            this.onSelect2(2)
          }
          this.fillprojectser(ProjectId);

        },
          error => {
            this.errorMsg = error;
          });
    }
    else {
      this._BranchService.Fetch_Branch('SERVENGID')
        .subscribe(data => {
          this._BranchService.allBranchs = data
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = null
          if (this._BranchService.allBranchs.length == 1) {
            this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = this._BranchService.allBranchs[0].Id
            this.onSelect2(1)
            this.onSelect2(2)
          }
          this.fillprojectser(null);
          this.GetAllModules();
        },
          error => {
            this.errorMsg = error;
          });
    }
  }
  fillprojectser(ProjectId: number) {
    this._projectService.fetchProject('BYBRANCHID', this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId == null ? 0 : this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId)
      .subscribe(data => {
        this._projectService.allProjects = data
        if (this._projectService.allProjects.length == 1)
          this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = this._projectService.allProjects[0].Id
        else
          this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = ProjectId

      },
        error => {
          this.errorMsg = error;
        });
  }

  onSelect2(type: number) {
    if (type == 1) {
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr1 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr2 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr3 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr1 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr2 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr3 = '';
      var custDtls = this._CustomerService.allCustomers.filter(e => e.Id == this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId)[0];
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr1 = custDtls.Addr1;
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr2 = custDtls.Addr2;
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr3 = custDtls.Addr3;
    }
    if (type == 2) {
      var brnchDtls = this._BranchService.allBranchs.filter(e => e.Id == this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId)[0];
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr1 = brnchDtls.Addr1;
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr2 = brnchDtls.Addr2;
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr3 = brnchDtls.Addr3;
    }
  }

  GetAllModules() {

    this.AMCRenewalStatus = false
    this._CustomerrequirementService.allCustomerReqHistory = []
    if (this._CustomerrequirementService.selectedRecord.CustomerID != null
      && this._CustomerrequirementService.selectedRecord.BranchID != null
      && this._CustomerrequirementService.selectedRecord.ProjectID != null) {

      this._modulService.Fetch_ModuleServiceReport(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID)
        .subscribe(data => {
          this._modulService.allModuleServiceReport = data
        },
          error => {
            this.errorMsg = error;
          })
      this._CommonService.LoadSoftWareDtlsCommon(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID).
        subscribe(obj => {
          this._CustomerrequirementService.selectedRecord.Server = obj.NoOfServer
          this._CustomerrequirementService.selectedRecord.ContractVsCurrentDate = obj.ContractVsCurrentDate
          this._CustomerrequirementService.selectedRecord.Node = obj.Nodes
          if (obj.AMCUPTo == null)
            this._CustomerrequirementService.selectedRecord.AMCUPTo = null
          else {
            this._CustomerrequirementService.selectedRecord.AMCUPTo = new Date(obj.AMCUPTo)
            if (this._CustomerrequirementService.selectedRecord.AMCUPTo > new Date())
              this.AMCRenewalStatus = true
          }
          this._CustomerrequirementService.selectedRecord.ContractDate = obj.ContractDate == null ? null : new Date(obj.ContractDate)
        })

      this._CustomerrequirementService.GetCustomerRequirementsHistory(this._CustomerrequirementService.selectedRecord.CustomerID, this._CustomerrequirementService.selectedRecord.BranchID, this._CustomerrequirementService.selectedRecord.ProjectID, this._CustomerrequirementService.selectedRecord.RefNo).subscribe(obj => {
        this._CustomerrequirementService.allCustomerReqHistory = obj.CusReqDtls
        this._CustomerrequirementService.selectedCustomerRequirement_Common.NoOfVisits = obj.NoOfVisits
        this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate == null ? null : new Date(obj.LastVisitDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
        this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate == null ? null : new Date(obj.LastUpdExeDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate == null ? null : new Date(obj.LastUpdScriptDate)
        this._CustomerrequirementService.selectedCustomerRequirement_Common.TotalRequirements = obj.TotalRequirements;
      })

    }
  }

  OnClickCancelser() {
    $('#myModalConfirm2').css("display", "none")
    $('#myModal').addClass("show");
    this.WarningModel = false
    this.serviceEngWF = false
    this.jobOrderForm = false
    this.Reset();
    this._CommonService.servicereportentryPopUp = false
    this._CommonService.customerrequirementPopUp = false
  }
}
