import { Component, OnInit } from '@angular/core';
import { WorkDetails, Worksheet } from 'src/app/Models/Backlogs';
import { CommonService } from 'src/app/Services/common.service';
import { JobCardService } from 'src/app/Services/job-card.service';
import { NgForm } from '@angular/forms';
import { EmployeeService } from 'src/app/Services/employee.service';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-work-details',
  templateUrl: './work-details.component.html',
  styleUrls: ['./work-details.component.css']
})
export class WorkDetailsComponent implements OnInit {


  constructor(public _CommonService: CommonService,
              public _EmployeeService: EmployeeService,
              public _JobCardService: JobCardService) { }

  pageIndex: number;
  orderByColName: string = '';
  orderByDir: boolean = false;
  isFiltering: boolean = false;
  filterCol: WorkDetails;
  StatusList: any;
  UserRole: string = '';
  public errorMsg;

  ngOnInit(): void {
    this._CommonService.isLoading = false;
    this.UserRole = JSON.parse(localStorage.getItem('userRoles'))[0].toString();
    if ((this.UserRole == 'Developers & Designers' || this.UserRole == 'Testers')) {
      this._CommonService.getLoginedUserId().then(value => {
        this._JobCardService.selectedJobCard.Emp_ID = Number(value);
      });
    }
    this.resetForm();
    this.fillServiceEngineer()
    this.StatusList = this._CommonService.GetStatusFullList();
    this._JobCardService.WorkDetails = []
    this.pageIndex = 1;
    this.filterCol = {
      Activity: null,
      ActualHours: '',
      Attempts: '',
      Batch: '',
      CustomerName: '',
      EstHrs: '',
      JONO: '',
      ModuleName: '',
      NoCases: '',
      Perfomance: '',
      ProjectName: '',
      JobDetails:''
    }
    this._CommonService.pageIndex = 1;   
  }

  resetForm(form?: NgForm) {
    this._JobCardService.EmployeeId = 0
    this._JobCardService.From = null
    this._JobCardService.To = null
  }

  sort(col) {
    if (col == this.orderByColName) {
      this.orderByDir = !this.orderByDir;
    }
    this.orderByColName = col;
  }

  getData() {
    if(this._JobCardService.EmployeeId > 0)
    {
      this._CommonService.isLoading = true;
      this._JobCardService.Fetch_WorkDetails(this._JobCardService.EmployeeId, this._JobCardService.From, this._JobCardService.To).subscribe(data => {
        this._CommonService.isLoading = false;
        this._JobCardService.WorkDetails = data;
      },
        error => {
          this.errorMsg = error;
          this._CommonService.isLoading = false;
        })
    }  
  }
  
  toggleFilter() {
    this.isFiltering = !this.isFiltering;
    if (!this.isFiltering) {
      this.filterCol = {
        Activity: null,
        ActualHours: '',
        Attempts: '',
        Batch: '',
        CustomerName: '',
        EstHrs: '',
        JONO: '',
        ModuleName: '',
        NoCases: '',
        Perfomance: '',
        ProjectName: '',
        JobDetails:''
      }
    }
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('TEAMMEMBERS')
      .subscribe(data => {
        this._EmployeeService.employeeList = data;
        this._JobCardService.EmployeeId=this._JobCardService.selectedJobCard.Emp_ID;
      },
        error => {
          this.errorMsg = error;
        })
  }
}
