<div class="container-fluid" *ngIf="_CustomerfeedbackmasterService.formHideShow==0">
    <h2 class="heading_all">Customer Feedback Questions</h2>
    <br>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4 ">

            <table>
                <thead>
                    <tr>
                        <td>
                            <button type="button" class="btn-sm btn-info" (click)="addNew()"><i
                                    class="fas fa-plus" aria-hidden="true"></i></button>
                        </td>
                        <td style="width: 70%;"><input type="text" class="form-control-search" name="search"
                                [(ngModel)]="term"></td>
                        <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                    </tr>
                </thead>
            </table>

        </div>
    </div>
    <br>
    <table>
        <thead>
            <tr>
                <th>#</th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Code')">
                        <span *ngIf="!_CustomerfeedbackmasterService.isFiltering">Code</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="_CustomerfeedbackmasterService.orderByColName!='Code' "></i>
                            <div *ngIf="_CustomerfeedbackmasterService.orderByColName=='Code'">
                                <i class="fas fa-sort-amount-down"
                                    *ngIf="_CustomerfeedbackmasterService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt"
                                    *ngIf="!_CustomerfeedbackmasterService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_CustomerfeedbackmasterService.isFiltering">
                        <input type="text" #txtCodeFilter="ngModel"
                            [(ngModel)]="_CustomerfeedbackmasterService.CodeFilter" placeholder="Code">
                    </div>

                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Question')">
                        <span *ngIf="!_CustomerfeedbackmasterService.isFiltering">Question</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort"
                                *ngIf="_CustomerfeedbackmasterService.orderByColName!='Question' "></i>
                            <div *ngIf="_CustomerfeedbackmasterService.orderByColName=='Question'">
                                <i class="fas fa-sort-amount-down"
                                    *ngIf="_CustomerfeedbackmasterService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt"
                                    *ngIf="!_CustomerfeedbackmasterService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_CustomerfeedbackmasterService.isFiltering">
                        <input type="text" #txtQuestionFilter="ngModel"
                            [(ngModel)]="_CustomerfeedbackmasterService.QuestionFilter" placeholder="Question">
                    </div>

                </th>
                <th><em></em> Settings
                    <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                        <i *ngIf="!_CustomerfeedbackmasterService.isFiltering" class="fas fa-filter"></i>
                        <i *ngIf="_CustomerfeedbackmasterService.isFiltering" class="fas fa-times"></i>
                    </a>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cusfedmas of _CustomerfeedbackmasterService.allCustomerFeedBackMaster | orderBy : 'Question'| gridOperations  :_CustomerfeedbackmasterService.pageIndex: 
                     {Code:_CustomerfeedbackmasterService.CodeFilter,Question:_CustomerfeedbackmasterService.QuestionFilter}:
                    {Column:_CustomerfeedbackmasterService.orderByColName,Dir:_CustomerfeedbackmasterService.orderByDir} ; let i = index"
                class="ng-scope">
                <td>{{i+1+((NoOfItems*_CustomerfeedbackmasterService.pageIndex)-NoOfItems)}}</td>
                <td>{{cusfedmas.Code}}</td>
                <td>{{cusfedmas.Question}}</td>
                <td>
                    <button type="button" class="btn-success" (click)="OnEdit(cusfedmas);">
                        <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                    <button type="button" class="btn-danger" (click)="onDelete(cusfedmas.ID)"><i class="fas fa-trash"
                            aria-hidden="true"></i></button>
                </td>
            </tr><!-- end ngRepeat: item in items -->
            <tr>
                <td colspan="3"
                    *ngIf="(_CustomerfeedbackmasterService.allCustomerFeedBackMaster?.length<=0 )  || _CommonService.filterdItemCount<1">
                    No Records To
                    Display.</td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                <app-grid-pagination (pageIndexValue)="_CustomerfeedbackmasterService.pageIndex=$event">
                </app-grid-pagination>
            </td>
        </tfoot>
    </table>
    <div class="margintop"></div>
</div>

<app-delete-confirm *ngIf="deletePopUp" [title]="'Customer Feedback Master'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>