<div class="container-fluid">
    <!--provides a wrapper for testing the scroll-->
    <h2 class="jobheading">Job Card
        <span *ngIf="this._JobCardService.selectedJobCard.Designation==11 || this._JobCardService.selectedJobCard.Designation==10" class="span">Last WorkSheet Entered on {{this.employeeService.employeeDashBoard.LastEnteredJobCard}}
            <marquee *ngIf="!this.employeeService.employeeDashBoard.IsEnteredLastJobCard" direction="right"  behavior="alternate">
                Latest WorkSheet Not Entered,Please Update Your WorkSheet on {{this.employeeService.latestJobCardPendingDate | date :'MM-dd-yyyy'}}
            </marquee>
        </span>
    </h2>
    <div class="ui-input-container">
        <div class="conatiner-fluid">
            <div class="row">
                <div class="col-2">
                    <div class="form">
                        <div class="input">
                            <ng-select name="period" id="period" bindLabel="Name" bindValue="ID" 
                                [items]="_CommonService.periodList" placeholder="Select Period"
                                #period="ngModel" [(ngModel)]="this._CommonService.period" (ngModelChange)="periodChange($event)">
                            </ng-select><label for="Period">Period</label>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="input">
                        <input class="form-control" name="fromDate" #fromDate="ngModel" 
                        autocomplete="off" [(ngModel)]="_JobCardService.fromDate" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" [ngClass]="{'Disabled':enabledates==true}" required>
                        <label for="Period">From Date</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="input">
                        <input class="form-control" name="toDate" #toDate="ngModel" 
                        autocomplete="off" [(ngModel)]="_JobCardService.toDate" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" [ngClass]="{'Disabled':enabledates==true}" required>
                        <label for="Period">To Date</label>
                    </div>
                </div> 
                <div class="col-2">
                    <button type="button" class="sim-button button1" id="search" (click)="getAllJobs()">Filter</button>
                </div>
                <div class="col-2" *ngIf="(this.UserRole=='Project Leader' || this.UserRole == 'Project Manager')">
                    <input type="radio" name="dt" [value]="1" id="dev" #dev="ngModel" [(ngModel)]="DevOrTest"
                        (change)="getEmplist(DevOrTest)"> <label for="dev">Developers</label>
                    <input type="radio" name="dt" [value]="2" id="testr" #testr="ngModel" [(ngModel)]="DevOrTest"
                        (change)="getEmplist(DevOrTest)"> <label for="testr">Testers</label>                              
                </div>
                <div class="col-md-2" *ngIf="(this.UserRole=='Project Leader' || this.UserRole == 'Project Manager')">
                    <div class="form">
                        <div class="input">
                            <ng-select name="Emp_ID" id="Emp_ID" #Emp_ID="ngModel" bindLabel="EmpName" bindValue="Id" required
                                [items]="EmployeeList" [(ngModel)]="_JobCardService.selectedJobCard.Emp_ID" placeholder="Select Employee"
                                (change)="onChangeEmployee($event)">
                            </ng-select><label for="Emplpoyee">Employee</label>
                        </div>
                    </div>                                   
                </div>
            </div>
        </div>     
    </div>  
    <div class="row">  
        <div class="col-12">
            <div class="radio">
                <input type="radio" name="pending" [value]="1" #pending="ngModel" [(ngModel)]="this._JobCardService.statusType" (change)="this.getAllJobs()">Pending/WIP
                <input type="radio" name="Finished" [value]="2" #Finished="ngModel" [(ngModel)]="this._JobCardService.statusType" (change)="this.getAllJobs()">Finished  
                <input type="radio" name="All" [value]="0" #All="ngModel" [(ngModel)]="this._JobCardService.statusType" (change)="this.getAllJobs()">All     
            </div>
            <span style="margin-left:7px;">
                <select style="width: 210px;" name="SelectedBatch" id="SelectedBatch" #SelectedBatch="ngModel" [(ngModel)]="this.selectedBatchId" (ngModelChange)="this.getAllJobs()">
                    <option [value]="0">SELECT Batch</option>
                    <option *ngFor="let batch of this._JobCardService.EmpWiseJobBatch" [value]="batch.Id">{{batch.Name}}</option>
                </select>
            </span>    
            <b style="margin-left:7px;">{{this.employeesWorkingOn}}</b>
            <select *ngIf="this._JobCardService.selectedWorkDoc !=null && this._JobCardService.selectedWorkDoc.length > 0" style="width: 210px;padding: 4px;float:right" name="SelectedDoc" id="SelectedDoc" (change)="viewDocument($event.target.value)">
                <option [value]="0">SELECT Document</option>
                <option *ngFor="let moduledoc of this._JobCardService.selectedWorkDoc" [value]="moduledoc.Id">{{moduledoc.FileName}}</option>
            </select>
        </div>      
    </div>
    <div class="tWrap">
        <!-- <table>
            <thead>
                <tr>
                    <th title="Action" style="width:90px;">Action
                        <span>
                            <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                <i *ngIf="!isFiltering" class="fas fa-filter"></i>
                                <i *ngIf="isFiltering" class="fas fa-times"></i>
                            </a>
                        </span>
                    </th>
                    <th style="width:63px;" title="SN.No" style="width:33px;">S.NO</th>
                    <th style="width:63px;" title="JO.No">
                        <div *ngIf="!isFiltering" (click)="sort('JONO')">
                            JO.NO
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='JONO' "></i>
                                <div *ngIf="orderByColName=='JONO'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="jonoFilter" name="jonoFilter" #jonoFilter="ngModel"
                                [(ngModel)]="filterFields.JONO" placeholder="JONO">
                        </div>
                    </th>
                    <th style="width:63px;" title="ID">
                        <div *ngIf="!isFiltering" (click)="sort('Activity')">
                            ID
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Activity' "></i>
                                <div *ngIf="orderByColName=='Activity'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="ActivityFilter" name="ActivityFilter" #ActivityFilter="ngModel"
                                [(ngModel)]="filterFields.Activity" placeholder="ID">
                        </div>

                    </th>
                    <th style="width:85px;" title="ISSUE DATE">
                        <div *ngIf="!isFiltering" (click)="sort('IssueDate')">
                            ISSUE DATE
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='IssueDate' "></i>
                                <div *ngIf="orderByColName=='IssueDate'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="IssueDateFilter" name="IssueDateFilter" #IssueDateFilter="ngModel"
                                [(ngModel)]="filterFields.IssueDate" placeholder=" ISSUE DATE">
                        </div>
                    </th>
                    <th style="width:75px;" title="EST.HRS">
                        <div *ngIf="!isFiltering" (click)="sort('EstHrs')">
                            EST.HRS
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='EstHrs' "></i>
                                <div *ngIf="orderByColName=='EstHrs'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="EstHrsFilter" name="EstHrsFilter" #EstHrsFilter="ngModel"
                                [(ngModel)]="filterFields.EstHrs" placeholder="EST.HRS">
                        </div>
                    </th>
                    <th style="width:85px;" title="EST.ST.DATE">
                        <div *ngIf="!isFiltering" (click)="sort('ExpStDate')">
                            EST.ST.DATE
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='ExpStDate' "></i>
                                <div *ngIf="orderByColName=='ExpStDate'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="ExpStDateFilter" name="ExpStDateFilter" #ExpStDateFilter="ngModel"
                                [(ngModel)]="filterFields.ExpStDate" placeholder="EST.ST.DATE">
                        </div>
                    </th>
                    <th style="width:85px;" title="COMP.DATE">
                        <div *ngIf="!isFiltering" (click)="sort('CompDate')">
                            COMP.DATE
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='CompDate' "></i>
                                <div *ngIf="orderByColName=='CompDate'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="CompDateFilter" name="CompDateFilter" #CompDateFilter="ngModel"
                                [(ngModel)]="filterFields.CompDate" placeholder="COMP.DATE">
                        </div>
                    </th>
                    <th style="width:82px;" title="OVER.DUE.BY">
                        <div *ngIf="!isFiltering" (click)="sort('OverdueBy')">
                            OVR.DUE.BY
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='OverdueBy' "></i>
                                <div *ngIf="orderByColName=='OverdueBy'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="OverdueByFilter" name="OverdueByFilter" #OverdueByFilter="ngModel"
                                [(ngModel)]="filterFields.OverdueBy" placeholder="COMP.DATE">
                        </div>
                    </th>
                    <th style="width:130px;" title="CUSTOMER">
                        <div *ngIf="!isFiltering" (click)="sort('CustomerName')">
                            CUSTOMER
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='CustomerName' "></i>
                                <div *ngIf="orderByColName=='CustomerName'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="CustomerNameFilter" name="CustomerNameFilter" #CustomerNameFilter="ngModel"
                                [(ngModel)]="filterFields.CustomerName" placeholder="CUSTOMER">
                        </div>
                    </th>
                    <th style="width: 80px;" title="AttSE">
                        <div *ngIf="!isFiltering" (click)="sort('AttSE')">
                            AttSE
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='AttSE' "></i>
                                <div *ngIf="orderByColName=='AttSE'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="AttSEFilter" name="AttSEFilter" #AttSEFilter="ngModel"
                                [(ngModel)]="filterFields.AttSE" placeholder="AttSE">
                        </div>
                    </th>
                    <th style="width:80px;" title="AssignedBy">
                        <div *ngIf="!isFiltering" (click)="sort('AssignedBy')">
                            Assign.By
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='AssignedBy' "></i>
                                <div *ngIf="orderByColName=='AssignedBy'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="AssignedByFilter" name="AssignedByFilter" #AssignedByFilter="ngModel"
                                [(ngModel)]="filterFields.AssignedBy" placeholder="AssignedBy">
                        </div>
                    </th>
                    <th style="width: 80px;" title="TestResult">
                        <div *ngIf="!isFiltering" (click)="sort('AssignedBy')">
                            TestResult
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='TestResult' "></i>
                                <div *ngIf="orderByColName=='TestResult'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="TestResultFilter" name="TestResultFilter" #TestResultFilter="ngModel"
                                [(ngModel)]="filterFields.TestResult" placeholder="TestResult">
                        </div>
                    </th>
                    <th style="width: 150px;" title="MODULE">
                        <div *ngIf="!isFiltering" (click)="sort('Module')">
                            MODULE
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Module' "></i>
                                <div *ngIf="orderByColName=='Module'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="ModuleFilter" name="ModuleFilter" #ModuleFilter="ngModel"
                                [(ngModel)]="filterFields.Module" placeholder="Module">
                        </div>
                    </th>
                    <th style="width: 190px;" title="PARTICULARS">
                        <div *ngIf="!isFiltering" (click)="sort('Particulars')">
                            PARTICULARS
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Particulars' "></i>
                                <div *ngIf="orderByColName=='Particulars'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="ParticularsFilter" name="ParticularsFilter" #ParticularsFilter="ngModel"
                                [(ngModel)]="filterFields.Particulars" placeholder="Module">
                        </div>
                    </th>
                    <th style="width: 100px;" title="TYPE">
                        <div *ngIf="!isFiltering" (click)="sort('Type')">
                            TYPE
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Type' "></i>
                                <div *ngIf="orderByColName=='Type'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByColName"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="orderByColName"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <select id="TypeFilter" name="TypeFilter" #TypeFilter="ngModel"
                                [(ngModel)]="filterFields.Type">
                                <option [value]="''">SELECT Type</option>
                                <option *ngFor="let field of this.TypeList" [value]="field.Id">
                                    {{field.Name}}</option>
                            </select>
                        </div>

                    </th>
                    <th style="width:85px;" title="Priority">
                        <div *ngIf="!isFiltering" (click)="sort('Type')">
                            PRIORITY
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='PriorityId' "></i>
                                <div *ngIf="orderByColName=='PriorityId'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByColName"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="orderByColName"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <select id="PriorityIdFilter" name="PriorityIdFilter" #PriorityIdFilter="ngModel"
                                [(ngModel)]="filterFields.PriorityId">
                                <option [value]="''">SELECT Priority</option>
                                <option *ngFor="let field of this.PriorityList" [value]="field.Id">
                                    {{field.Name}}</option>
                            </select>
                        </div>
                    </th>
                    <th style="width:70px;" title="STATUS">
                        <div *ngIf="!isFiltering" (click)="sort('StatusText')">
                            STATUS
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='StatusText' "></i>
                                <div *ngIf="orderByColName=='StatusText'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="StatusTextFilter" name="StatusTextFilter" #StatusTextFilter="ngModel"
                                [(ngModel)]="filterFields.StatusText" placeholder="STATUS">
                        </div>
                    </th>
                    <th style="width:63px;" title="Attempt" *ngIf="this.UserRole == 'Developers & Designers' || this.UserRole == 'Project Manager'">
                        <div *ngIf="!isFiltering" (click)="sort('Attempt')">
                            Attempt
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="orderByColName!='Attempt' "></i>
                                <div *ngIf="orderByColName=='Attempt'">
                                    <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isFiltering">
                            <input id="AttemptFilter" name="ActivityFilter" #AttemptFilter="ngModel"
                                [(ngModel)]="filterFields.Attempt" placeholder="Attempt">
                        </div>
                    </th>                  
                </tr>
            </thead>
        </table> -->
        <!-- </div> -->
        <!--/.tWrap__head-->
        <div class="tWrap__body">
            <table>
                <thead style="position:sticky;top:0;">
                    <tr>
                        <th title="Action" style="width:90px;">Action
                            <span>
                                <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                    <i *ngIf="!isFiltering" class="fas fa-filter"></i>
                                    <i *ngIf="isFiltering" class="fas fa-times"></i>
                                </a>
                            </span>
                        </th>
                        <th style="width:63px;" title="SN.No" style="width:33px;">S.NO</th>
                        <th style="width:63px;" title="JO.No">
                            <div *ngIf="!isFiltering" (click)="sort('JONO')">
                                JO.NO
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='JONO' "></i>
                                    <div *ngIf="orderByColName=='JONO'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="jonoFilter" name="jonoFilter" #jonoFilter="ngModel"
                                    [(ngModel)]="filterFields.JONO" placeholder="JONO">
                            </div>
                        </th>
                        <th style="width:63px;" title="ID">
                            <div *ngIf="!isFiltering" (click)="sort('Activity')">
                                ID
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='Activity' "></i>
                                    <div *ngIf="orderByColName=='Activity'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="ActivityFilter" name="ActivityFilter" #ActivityFilter="ngModel"
                                    [(ngModel)]="filterFields.Activity" placeholder="ID">
                            </div>
    
                        </th>
                        <th style="width:85px;" title="Priority">
                            <div *ngIf="!isFiltering" (click)="sort('Type')">
                                PRIORITY
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='PriorityId' "></i>
                                    <div *ngIf="orderByColName=='PriorityId'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByColName"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="orderByColName"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="PriorityIdFilter" name="PriorityIdFilter" #PriorityIdFilter="ngModel"
                                    [(ngModel)]="filterFields.PriorityId">
                                    <option [value]="''">SELECT Priority</option>
                                    <option *ngFor="let field of this.PriorityList" [value]="field.Id">
                                        {{field.Name}}</option>
                                </select>
                            </div>
                        </th>
                        <th style="width:70px;" title="STATUS">
                            <div *ngIf="!isFiltering" (click)="sort('StatusText')">
                                STATUS
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='StatusText' "></i>
                                    <div *ngIf="orderByColName=='StatusText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="StatusTextFilter" name="StatusTextFilter" #StatusTextFilter="ngModel"
                                    [(ngModel)]="filterFields.StatusText" placeholder="STATUS">
                            </div>
                        </th>
                        <th style="width: 80px;" title="TestResult">
                            <div *ngIf="!isFiltering" (click)="sort('AssignedBy')">
                                TestResult
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='TestResult' "></i>
                                    <div *ngIf="orderByColName=='TestResult'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="TestResultFilter" name="TestResultFilter" #TestResultFilter="ngModel"
                                    [(ngModel)]="filterFields.TestResult" placeholder="TestResult">
                            </div>
                        </th>
                        <th style="width:85px;" title="ISSUE DATE">
                            <div *ngIf="!isFiltering" (click)="sort('IssueDate')">
                                ISSUE DATE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='IssueDate' "></i>
                                    <div *ngIf="orderByColName=='IssueDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="IssueDateFilter" name="IssueDateFilter" #IssueDateFilter="ngModel"
                                    [(ngModel)]="filterFields.IssueDate" placeholder=" ISSUE DATE">
                            </div>
                        </th>
                        <th style="width:75px;" title="EST.HRS">
                            <div *ngIf="!isFiltering" (click)="sort('EstHrs')">
                                EST.HRS
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='EstHrs' "></i>
                                    <div *ngIf="orderByColName=='EstHrs'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="EstHrsFilter" name="EstHrsFilter" #EstHrsFilter="ngModel"
                                    [(ngModel)]="filterFields.EstHrs" placeholder="EST.HRS">
                            </div>
                        </th>
                        <th style="width:85px;" title="EST.ST.DATE">
                            <div *ngIf="!isFiltering" (click)="sort('ExpStDate')">
                                EST.ST.DATE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='ExpStDate' "></i>
                                    <div *ngIf="orderByColName=='ExpStDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="ExpStDateFilter" name="ExpStDateFilter" #ExpStDateFilter="ngModel"
                                    [(ngModel)]="filterFields.ExpStDate" placeholder="EST.ST.DATE">
                            </div>
                        </th>
                        <th style="width:85px;" title="COMP.DATE">
                            <div *ngIf="!isFiltering" (click)="sort('CompDate')">
                                COMP.DATE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='CompDate' "></i>
                                    <div *ngIf="orderByColName=='CompDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="CompDateFilter" name="CompDateFilter" #CompDateFilter="ngModel"
                                    [(ngModel)]="filterFields.CompDate" placeholder="COMP.DATE">
                            </div>
                        </th>
                        <th style="width:50px;" title="OVER.DUE.BY">
                            <div *ngIf="!isFiltering" (click)="sort('OverdueBy')">
                                OD
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='OverdueBy' "></i>
                                    <div *ngIf="orderByColName=='OverdueBy'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="OverdueByFilter" name="OverdueByFilter" #OverdueByFilter="ngModel"
                                    [(ngModel)]="filterFields.OverdueBy" placeholder="COMP.DATE">
                            </div>
                        </th>
                        <th style="width:130px;" title="CUSTOMER">
                            <div *ngIf="!isFiltering" (click)="sort('CustomerName')">
                                CUSTOMER
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='CustomerName' "></i>
                                    <div *ngIf="orderByColName=='CustomerName'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="CustomerNameFilter" name="CustomerNameFilter" #CustomerNameFilter="ngModel"
                                    [(ngModel)]="filterFields.CustomerName" placeholder="CUSTOMER">
                            </div>
                        </th>                                       
                        <th style="width: 150px;" title="MODULE">
                            <div *ngIf="!isFiltering" (click)="sort('Module')">
                                MODULE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='Module' "></i>
                                    <div *ngIf="orderByColName=='Module'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="ModuleFilter" name="ModuleFilter" #ModuleFilter="ngModel"
                                    [(ngModel)]="filterFields.Module" placeholder="Module">
                            </div>
                        </th>
                        <th style="width: 190px;" title="PARTICULARS">
                            <div *ngIf="!isFiltering" (click)="sort('Particulars')">
                                PARTICULARS
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='Particulars' "></i>
                                    <div *ngIf="orderByColName=='Particulars'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="ParticularsFilter" name="ParticularsFilter" #ParticularsFilter="ngModel"
                                    [(ngModel)]="filterFields.Particulars" placeholder="Module">
                            </div>
                        </th>
                        <th style="width: 80px;" title="AttSE">
                            <div *ngIf="!isFiltering" (click)="sort('AttSE')">
                                AttSE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='AttSE' "></i>
                                    <div *ngIf="orderByColName=='AttSE'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="AttSEFilter" name="AttSEFilter" #AttSEFilter="ngModel"
                                    [(ngModel)]="filterFields.AttSE" placeholder="AttSE">
                            </div>
                        </th>
                        <th style="width:80px;" title="AssignedBy">
                            <div *ngIf="!isFiltering" (click)="sort('AssignedBy')">
                                Assign.By
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='AssignedBy' "></i>
                                    <div *ngIf="orderByColName=='AssignedBy'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="AssignedByFilter" name="AssignedByFilter" #AssignedByFilter="ngModel"
                                    [(ngModel)]="filterFields.AssignedBy" placeholder="AssignedBy">
                            </div>
                        </th>  
                        <th style="width: 100px;" title="TYPE">
                            <div *ngIf="!isFiltering" (click)="sort('Type')">
                                TYPE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='Type' "></i>
                                    <div *ngIf="orderByColName=='Type'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByColName"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="orderByColName"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="TypeFilter" name="TypeFilter" #TypeFilter="ngModel"
                                    [(ngModel)]="filterFields.Type">
                                    <option [value]="''">SELECT Type</option>
                                    <option *ngFor="let field of this.TypeList" [value]="field.Id">
                                        {{field.Name}}</option>
                                </select>
                            </div>
    
                        </th>                      
                        <th style="width:75px;" title="Attempt" *ngIf="this.UserRole == 'Developers & Designers' || this.UserRole == 'Project Manager'">
                            <div *ngIf="!isFiltering" (click)="sort('Attempt')">
                                Attempt
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="orderByColName!='Attempt' "></i>
                                    <div *ngIf="orderByColName=='Attempt'">
                                        <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="AttemptFilter" name="ActivityFilter" #AttemptFilter="ngModel"
                                    [(ngModel)]="filterFields.Attempt" placeholder="Attempt">
                            </div>
                        </th>                  
                    </tr>
                </thead>
                <tbody>
                    <tr (click)="getJobDet(obj,i)" *ngFor="let obj of _JobCardService.allJobCardDtl | gridOperations  :null:
                      {JONO:filterFields.JONO,IssueDate:filterFields.IssueDate,EstHrs:filterFields.EstHrs,ExpStDate:filterFields.ExpStDate
                    ,CompDate:filterFields.CompDate,OverdueBy:filterFields.OverdueBy,CustomerName:filterFields.CustomerName,Module:filterFields.Module
                    ,Particulars:filterFields.Particulars,StatusText:filterFields.StatusText,Activity:filterFields.Activity,Type:filterFields.Type
                    ,PriorityId:filterFields.PriorityId,AttSE:filterFields.AttSE,AssignedBy:filterFields.AssignedBy }:
                    {Column:orderByColName,Dir:orderByDir}; let i=index"
                        [ngClass]="{'dateexceed':(obj.Status==1 || obj.Status==0) && obj.IsDue,'taskInWIP':obj.Status==1,'taskInFinished':obj.Status==2,'taskInHold':obj.Status==3,'taskInUnableToComp':obj.Status==4,'taskInTestFinised':obj.Status==5,'taskInQAFinised':obj.Status==6,'taskHandover':obj.Status==7,'taskInWithoutJobCard':(obj.Status==0 && obj.TResultWOutJobcard==true)}">
                        <td style="width:90px;">
                            <i style="padding-right: 10px;" (click)="onClickOptions(obj,1)"
                                class="fas fa-file-contract testResult" title="Test Result"></i>
                            <i style="padding-right: 10px;" (click)="onClickOptions(obj,2)"
                                class="far fa-file-code jobDetails" title="Job Details"></i>
                            <i (click)="onClickOptions(obj,3)" class="fas fa-file-alt notes" title="Notes"></i>
                        </td>  
                        <td style="width:33px;">
                            <span *ngIf="SelectedIndex== i"> <i class='fas fa-chevron-right' style='font-size:20px'></i>
                            </span>
                            {{i+1}}
                        </td>
                        <td style="width:63px;"  title="{{obj.JONO}}">{{obj.JONO}}</td>
                        <td style="width:63px;"  title="{{obj.Activity}}">{{obj.Activity}}</td>
                        <td style="width:85px;">
                            <span class="form-control-label" *ngFor="let field of PriorityList" disabled>
                                <div
                                    [ngClass]="obj.PriorityId==1?'urgent':obj.PriorityId==2?'vurgent':obj.PriorityId==3?'critical':'normal'">
                                    <p *ngIf="field.Id==obj.PriorityId">
                                        {{field.Name}}
                                    </p>
                                </div>
                            </span>
                        </td>             
                        <td style="width:70px;" title="{{obj.StatusText}}">{{obj.StatusText}}</td>
                        <td style="width:80px;"  title="{{obj.TestResult}}">{{obj.TestResult}}</td>
                        <td style="width:85px;"  title="{{obj.IssueDate}}">{{obj.IssueDate }}</td>
                        <td style="width:75px;"  title="{{obj.EstHrs}}">{{obj.EstHrs}}</td>
                        <td style="width:85px;"  title="{{obj.ExpStDate  }}">{{obj.ExpStDate }}</td>
                        <td style="width:85px;"  title="{{obj.CompDate  }}">{{obj.CompDate }}</td>
                        <td style="width:50px;"  title="{{obj.OverdueBy}}">{{obj.OverdueBy}}</td>
                        <td style="width:130px;" title="{{obj.CustomerName}}">{{obj.CustomerName}}</td>
                        <td style="width:150px;" title="{{obj.Module}}">{{obj.Module}}</td>
                        <td style="width:190px;" title="{{obj.Particulars}}">{{obj.Particulars}}</td>
                        <td style="width:80px;"  title="{{obj.AttSE}}">{{obj.AttSE}}</td>
                        <td style="width:80px;"  title="{{obj.AssignedBy}}">{{obj.AssignedBy}}</td>
                        <td style="width:100px;">
                            <!-- {{obj.Type}} -->
                            <span class="form-control-label" *ngFor="let field of TypeList" disabled>
                                <p *ngIf="field.Id==obj.Type">
                                    {{field.Name}}
                                </p>
                            </span>
                        </td>                     
                        <td style="width:75px;" style="cursor: pointer;" title="{{obj.Attempt > 3 ? 'Please Clear the Issue Properly':''}}" [ngClass]="{'testFailedColorChange':obj.Attempt > 3}" *ngIf="this.UserRole == 'Developers & Designers' || this.UserRole == 'Project Manager'">{{obj.Attempt}}</td>                                      
                    </tr>
                    <tr *ngIf="!this._CommonService.isLoading && _JobCardService.allJobCardDtl.length<=0">
                        <td colspan="6">
                            No Records To Display.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--/.tWrap__body-->
    </div>
    <!--/.tWrap-->
    <div class="ui-input-container">
        <div class="conatiner-fluid">
            <div class="row">
                <Div class="col-md-6">
                    <label class="ui-form-input-container" (click)="openDescriptionPopUp=true;">
                        <textarea class="ui-form-input" [value]="Description" disabled></textarea>
                        <span class="form-input-label">Description</span>
                    </label>
                    <label class="ui-form-input-container">
                        <textarea class="ui-form-input" [value]="Remark" disabled></textarea>
                        <span class="form-input-label">Activity Remark</span>
                    </label>
                    <label class="ui-form-input-container">
                        <textarea class="ui-form-input" [value]="RemarkWorkSorting" disabled></textarea>
                        <span class="form-input-label">Work Sorting Remark</span>
                    </label>
                </Div>
                <div class="col-md-6">
                    <div class="tWrap" style="height: auto; margin-bottom: 25px;">
                        <div class="tWrap__head">
                            <table>
                                <thead>
                                    <tr>
                                        <th>ATTACHMENTS</th>
                                        <!-- <th>FILE TYPES</th> -->
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <!--/.tWrap__head-->
                        <div class="tWrap__body">
                            <table>
                                <tbody>
                                    <tr *ngFor="let data of FileList">
                                        <td (click)="downloadFileUrl(data)"
                                            style="cursor: pointer; text-decoration: underline;">
                                            {{data.File}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="FileList?.length<1">
                                        <td>No File Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--/.tWrap__body-->
                    </div>
                    <label class="ui-form-input-container">
                        <textarea class="ui-form-input" [value]="RemarkWorkStatus" disabled></textarea>
                        <span class="form-input-label">Work Status Remark</span>
                    </label>
                    <label class="ui-form-input-container">
                        <textarea class="ui-form-input" [value]="WorkAllotmentRemark" disabled></textarea>
                        <span class="form-input-label">Work Allotment Remark</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!-- TEST RESULT -->
    <form #testREsult="ngForm" *ngIf="this.saveMode == 'TestResults'">
        <div class="popup-container">
            <input type="checkbox" id="test-results">
            <div class="popup" (click)="closePopUp()">
                <div class="inner" (click)="$event.stopPropagation();">
                    <div class="title">
                        <p>TESTER FEEDBACK</p> <label for="test-results"><i class="fas fa-times"
                                (click)="closePopUp()"></i></label>
                    </div>
                    <div class="tWrap">
                        <table>
                            <thead>
                                <tr>
                                    <th style="width:50px;">SLNO</th>
                                    <th class="TesterfeedbacktdWidth">Result ID</th>
                                    <th class="keywordwidth">KeyWord</th>
                                    <th style="width:140px;">Bugs Nature</th>
                                    <th>Description</th>
                                    <th style="width:50px;" *ngIf="_JobCardService.selectedJobCard.Designation==11">
                                        Edit</th>
                                    <th class="TesterfeedbacktdWidth">Cleared</th>
                                </tr>
                            </thead>
                        </table>
                        <div class="tWrap__body">
                            <table>
                                <tbody>
                                    <tr *ngIf="_JobCardService.selectedJobCard.Designation==11">
                                        <td style="width:50px;">#</td>
                                        <td class="TesterfeedbacktdWidth">0</td>
                                        <td class="keywordwidth">
                                            <input list="TestResult" type="text" name="keyWord" id="keyWord" placeholder="Test Result Keyword" 
                                                (keydown)="$event.stopPropagation();"
                                                 #keyWord="ngModel" style="width:90%;"
                                                [(ngModel)]="AddTestResultKeyWord" autocomplete="off">
                                                <datalist id="TestResult">
                                                    <option *ngFor="let obj of KeyWordList| gridOperations:null:{KeyWord:AddNewKeyword}:{Column:null,Dir:null}; let i = index"> 
                                                        {{obj.KeyWord}}                   
                                                    </option>                                                
                                                </datalist>
                                                <img title="Permanently Deleted" class="testkeyworddelete" src="../../../../../assets/Images/delete.ico" (click)="filterSelectedTestResultKeyword()">
                                        </td>
                                        <td style="width:140px;">
                                            <select style="width: 100%;" [(ngModel)]="this.BugId"
                                                    name="{{this.BugId}}" #{{this.BugId}}="ngModel" class="form-control" required>
                                                <option [ngValue]="0" disabled>Select</option>
                                                <option *ngFor="let bug of this.Bugs" [ngValue]="bug.ID">{{bug.Name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <textarea name="testResultdesc" id="testResultdesc"
                                                #testResultdesc="ngModel" style="min-height: 50px;"
                                                [(ngModel)]="AddTestResultDesc"></textarea>
                                        </td>
                                        <td style="width:50px;">
                                            <button type="button" (click)="AddTestResult()">+</button>
                                        </td>
                                        <td class="TesterfeedbacktdWidth"></td>
                                    </tr>
                                    <tr *ngFor="let obj of _JobCardService.selectedJobCard.TestResults; let i=index">
                                        <td style="width:50px;">{{i+1}}</td> 
                                        <td class="TesterfeedbacktdWidth">{{obj.TestResultId}}</td>                                      
                                        <td class="keywordwidth" [ngClass]="{'testSuccessColorChange':obj.isCleared || obj.ClearStatus==1 || obj.BugId==3,'testFailedColorChange':!obj.isCleared && obj.BugId!=3}" [title]="obj.KeyWord">{{obj.KeyWord}}</td>
                                        <td style="width:140px;">
                                            <select style="width: 100%;" [(ngModel)]="obj.BugId"  disabled
                                                name="i{{obj.BugId}}" #i{{obj.BugId}}="ngModel" class="form-control" required>
                                                <option [ngValue]="0" disabled>Select</option>
                                                <option *ngFor="let bug of this.Bugs" [ngValue]="bug.ID">{{bug.Name}}</option>
                                            </select>
                                        </td>
                                        <td *ngIf="_JobCardService.selectedJobCard.Designation==11" [title]="obj.Description" [ngClass]="{'testSuccessColorChange':obj.isCleared || obj.ClearStatus==1 || obj.BugId==3,'testFailedColorChange':!obj.isCleared && obj.BugId!=3}" style="white-space: break-spaces;">
                                            {{obj.Description}}
                                            <span style="display: block; text-align: right;">
                                                {{obj.EmpName}}({{obj.Date | dateformat:3}})
                                            </span>
                                        </td>
                                        <td class="keywordwidth" *ngIf="_JobCardService.selectedJobCard.Designation==10" [ngClass]="{'testSuccessColorChange':obj.isCleared || obj.ClearStatus==1 || obj.BugId==3,'testFailedColorChange':!obj.isCleared && obj.BugId!=3}" [title]="obj.KeyWord">{{obj.KeyWord}}</td>
                                        <td *ngIf="_JobCardService.selectedJobCard.Designation==10" [title]="obj.Description" [ngClass]="{'testSuccessColorChange':obj.isCleared || obj.ClearStatus==1 || obj.BugId==3,'testFailedColorChange':!obj.isCleared && obj.BugId!=3}" style="white-space: break-spaces;">
                                            {{obj.Description}}
                                            <span style="display: block; text-align: right;">{{obj.EmpName}}
                                                ({{obj.Date | dateformat:3}})</span>
                                        </td>
                                        <td style="width:50px;"
                                            *ngIf="_JobCardService.selectedJobCard.Designation==11">
                                            <button (click)="RemoveTestResult(i)" type="button"
                                                *ngIf="(obj.Emp_ID==_JobCardService.selectedJobCard.Emp_ID)&&obj.EditStatus">-</button>
                                            <!-- {{obj.EditStatus}} -->
                                        </td>
                                        <td class="TesterfeedbacktdWidth">
                                            <!--  -->
                                            <!-- <input type="checkbox" *ngIf="obj.Emp_ID==_JobCardService.selectedJobCard.Emp_ID || this._JobCardService.selectedJobCard.Designation==10" [ngModelOptions]="{standalone: true}" [(ngModel)]="obj.isCleared"> -->
                                            <input name="testResultCleared" type="checkbox" [ngModelOptions]="{standalone: true}" [attr.disabled]="(obj.ClearStatus && _JobCardService.selectedJobCard.Designation==10)?true:null" #isCleared="ngModel" [(ngModel)]="obj.isCleared" [checked]="obj.isCleared"> 
                                        </td>
                                    </tr>
                                    <tr *ngIf="_JobCardService.selectedJobCard.TestResults?.length<=0">
                                        <td colspan="5">
                                            No Record To Display
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" style="padding: 10px 0px;">
                        <div class="col-12" *ngIf="_JobCardService.selectedJobCard.Designation==11">
                            <span style="width: 25%;" class="btn btn-default btn-file">Browse
                                <input type="file" name="testRFile" id="testRFile" multiple
                                    (change)="TestResultFilesInput($event)" #testRFile>
                            </span>
                        </div>
                        <div class="col-12">
                            <div class="tWrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>SLNO</th>
                                            <th>File</th>
                                            <th style="width: 190px;">Remove</th>
                                        </tr>
                                    </thead>
                                </table>
                                <div class="tWrap__body">
                                    <table>
                                        <tr
                                            *ngFor="let data of _JobCardService.selectedJobCard.JobCardTestResultAttachments;let i=index">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                <span (click)="DownloadTestResultFile(data)"
                                                    class="DownloadLink">{{data.FilePath}}</span>
                                                <span style="display: block; text-align: right;">{{data.EmpName}}
                                                    ({{data.Date}})</span>
                                            </td>
                                            <td style="width: 190px;">
                                                <button type="button"
                                                    *ngIf="data.Emp_ID==_JobCardService.selectedJobCard.Emp_ID"
                                                    (click)="RemoveTestResultFiles(i)">-</button>                                               
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="_JobCardService.selectedJobCard.JobCardTestResultAttachments?.length<1">
                                            <td colspan="3">
                                                No Record To Display.
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="title"
                        *ngIf="_JobCardService.selectedJobCard.Designation==11 || _JobCardService.selectedJobCard.TesterConclusion.Status>0">
                        <p>TEST RESULTS </p>
                    </div>
                    <div class="row" [ngClass]="{'Disabled':_JobCardService.selectedJobCard.Designation!=11}"
                        *ngIf="_JobCardService.selectedJobCard.Designation==11 || _JobCardService.selectedJobCard.TesterConclusion.Status>0">
                        <div class="col-md-5">
                            <ng-select name="testResultSta" id="testResultSta" bindLabel="Name" bindValue="ID" required
                                [items]="TestResultStatus" #testResultSta="ngModel"
                                [(ngModel)]="_JobCardService.selectedJobCard.TesterConclusion.Status"
                                (ngModelChange)="ClearQaConclusion()" (click)="checkTestResult()" placeholder="Select Test Result">
                            </ng-select>
                        </div>
                        <div class="col-md-7">
                            <textarea name="TestResultdesc" id="TestResultdesc" cols="5" rows="2"
                                placeholder="Tester's Conclusion" #TestResultdesc="ngModel"
                                [(ngModel)]="_JobCardService.selectedJobCard.TesterConclusion.Conclusion"></textarea>
                        </div>
                    </div>
                    <div id="qa"
                        *ngIf="_JobCardService.selectedJobCard.TesterConclusion.Status==1 && _JobCardService.selectedJobCard.TesterSucceed"
                        [ngClass]="{'Disabled':_JobCardService.selectedJobCard.Designation!=11}">
                        <div class="title">
                            <p>QA's Conclusion and Status</p>
                        </div>
                        <!-- {{_JobCardService.selectedJobCard.QAConclusion.Emp_ID}}!={{_JobCardService.selectedJobCard.Emp_ID}} -->
                        <div class="row"
                            [ngClass]="{'Disabled':_JobCardService.selectedJobCard.QAConclusion.Emp_ID!=_JobCardService.selectedJobCard.Emp_ID && _JobCardService.selectedJobCard.QAConclusion.Emp_ID>0}">
                            <div class="col-md-5">
                                <ng-select name="qAStatus" id="qAStatus" bindLabel="Name" bindValue="ID" required
                                    [items]="TestResultStatus" #qAStatus="ngModel"
                                    [(ngModel)]="_JobCardService.selectedJobCard.QAConclusion.Status"
                                    placeholder="Select">
                                </ng-select>
                            </div>
                            <div class="col-md-7">
                                <textarea name="qAconclusion" id="qAconclusion" cols="5" rows="2"
                                    placeholder="QA Conclusion" #qAconclusion="ngModel"
                                    [(ngModel)]="_JobCardService.selectedJobCard.QAConclusion.Conclusion"></textarea>
                            </div>

                        </div>
                    </div>
                    <div class="form-group">
                    <div class="row">
                        <!-- *ngIf="_JobCardService.selectedJobCard.Designation==11 " -->
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <button type="button" (click)="onSubmitJobDetails(testREsult)" [disabled]="DisableSubmit"
                                class="sim-button button1" style="width: 100%;">Submit
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </form>
    <form #JobDetails="ngForm" *ngIf="this.saveMode == 'JobDetails'">
        <div class="popup-container">
            <input type="checkbox" id="job-details">
            <div class="popup" (click)="closePopUp()">
                <div class="inner" (click)="$event.stopPropagation();">
                    <div class="title">
                        <p>JOB DETAILS</p> <label for="job-details"> <i class="fas fa-times" (click)="closePopUp()"></i>
                        </label>
                    </div>
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">SLNO</th>
                                        <th scope="col">DATE</th>
                                        <th scope="col">FROM</th>
                                        <th scope="col">TO</th>
                                        <th style="width:120px;" scope="col">NEXT DAY</th>
                                        <!-- <th scope="col">BREAK</th> -->
                                        <th scope="col">STATUS</th>
                                        <th style="width:70px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">#</td>
                                        <td>
                                            <input class="form-control" name="newJobDetailsDate"
                                                #newJobDetailsDate="ngModel" readonly autocomplete="off"
                                                [(ngModel)]="_JobCardService.newJobDetails.Date" placeholder="Date"
                                                (ngModelChange)="checkDate()" bsDatepicker
                                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                                        </td>
                                        <td>
                                            <input type="time" class="form-control" autocomplete="off"
                                                [(ngModel)]="_JobCardService.newJobDetails.W_From"
                                                name="newJobDetailsFrom" #newJobDetailsFrom="ngModel"
                                                placeholder="From">
                                        </td>

                                        <td>
                                            <input type="time" class="form-control" autocomplete="off"
                                                [(ngModel)]="_JobCardService.newJobDetails.W_To" name="newJobDetailsTo"
                                                #newJobDetailsTo="ngModel" placeholder="To">
                                        </td>
                                        <td>
                                            <input type="checkbox" class="form-control" autocomplete="off"
                                                [(ngModel)]="_JobCardService.newJobDetails.NextDay"
                                                name="newJobDetailsNextDay" #newJobDetailsNextDay="ngModel"
                                                placeholder="NextDay">
                                        </td>
                                        <!-- <td>
                                            <input type="text" class="form-control" autocomplete="off"
                                                (keypress)="_CommonService.numberValidation($event)"
                                                [(ngModel)]="_JobCardService.newJobDetails.Break"
                                                name="newJobDetailsBreak" #newJobDetailsBreak="ngModel"
                                                placeholder="Break">
                                        </td> -->
                                        <td>
                                            <select class="form-control"
                                                (ngModelChange)="ChangeStatus(_JobCardService.newJobDetails.Status,$event)"
                                                name="JOdstatusList" [(ngModel)]="_JobCardService.newJobDetails.Status"
                                                id="JOdstatusList" #JOdstatusList="ngModel">
                                                <option [value]="null" disabled>Select</option>
                                                <option *ngFor="let obj of StatusList" [ngValue]="obj.Id"
                                                    [disabled]="obj.Id==0">
                                                    {{obj.Name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td><button type="button" (click)="addJobDetails()">+</button></td>
                                    </tr>
                                    <tr *ngFor="let obj of _JobCardService.selectedJobCard.JobDetails ; let i = index">
                                        <td>{{i +1}}</td>
                                        <td>{{obj.Date | dateformat : 1 }}</td>
                                        <td>{{obj.W_From}}</td>
                                        <td>{{obj.W_To}}</td>
                                        <!-- <td>{{obj.NextDay}}</td> -->
                                        <td>
                                            <input type="checkbox" class="form-control" autocomplete="off" disabled
                                                [ngModel]="obj.NextDay" name="next+'obj.NextDay'"
                                                [ngModelOptions]="{standalone: true}" placeholder="NextDay">
                                        </td>
                                        <!-- <td>{{obj.Break}}</td> -->
                                        <td *ngIf="obj.Status!=3">
                                            {{obj.StatusText}}
                                        </td>
                                        <td *ngIf="obj.Status==3" (click)="getholdReason(obj)"
                                            style="text-decoration: underline; cursor: pointer; color: royalblue;">
                                            {{obj.StatusText}}
                                        </td>
                                        <td><button type="button" (click)="deleteJobDetails(i)">-</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <button type="button" class="sim-button button1" [disabled]="DisableSubmit"
                                (click)="onSubmitJobDetails(JobDetails)" style="width: 100%;">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- Job Card Notes -->
    <form #JobCardNotes="ngForm" *ngIf="this.saveMode == 'JobCardNotes'">
        <div class="popup-container">
            <input type="checkbox" id="notes">
            <div class="popup" (click)="closePopUp()">
                <div class="inner" (click)="$event.stopPropagation();">
                    <div class="title">
                        <p>NOTES</p> <label for="notes"> <i class="fas fa-times" (click)="closePopUp()"></i> </label>
                    </div>
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 7%;">SLNO</th>
                                        <th scope="col" style="width: 13%;">DATE & TIME</th>
                                        <th scope="col" style="width: 75%;">NOTE</th>
                                        <th scope="col" style="width: 5%;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">#</td>
                                        <td>
                                            <input class="form-control" name="newDate" #newDate="ngModel" readonly
                                                autocomplete="off" [(ngModel)]="_JobCardService.newNotes.Date"
                                                placeholder="Date" bsDatepicker
                                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                                            <input style="margin-top:1.5rem;" type="time" class="form-control" name="NoteTime" #NoteTime="ngModel" [(ngModel)]="_JobCardService.newNotes.NoteTime" placeholder="Time"  required>
                                        </td>
                                        <td>
                                            <textarea type="text" class="form-control" autocomplete="off"
                                                [(ngModel)]="_JobCardService.newNotes.Notes" name="newNotes"
                                                #newNotes="ngModel" placeholder="Notes" rows="2"></textarea>
                                        </td>
                                        <td><button type="button" (click)="addNotes()">+</button></td>
                                    </tr>
                                    <tr
                                        *ngFor="let obj of _JobCardService.selectedJobCard.JobCardNotes ; let i = index">
                                        <td>{{i +1}}</td>
                                        <td>{{obj.Date | dateformat : 1 }}
                                            {{obj.NoteTime}}
                                        </td>
                                        <!-- <td>{{obj.Notes}}</td> -->
                                        <td style="white-space: break-spaces;">
                                            {{obj.Notes}}
                                            <span style="display: block; text-align: right;">{{obj.EmpName}} </span>
                                        </td>
                                        <td><button type="button" (click)="deleteNotes(i)"
                                                *ngIf="obj.Emp_ID==_JobCardService.selectedJobCard.Emp_ID">-</button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="_JobCardService.selectedJobCard.JobCardNotes.length<1">
                                        <td colspan="4">
                                            No records to display.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">

                        <!-- <label style="color: #008080">Choose File</label> -->
                        <span style="width: 25%;" class="btn btn-default btn-file">Browse
                            <input type="file" name="customFile" id="customFile" multiple
                                (change)="handleFileInput($event)" #customFile>
                        </span>
                    </div>
                    <br>
                    <div class="row" style="padding: 10px 0px;">
                        <div class="col-12">
                            <div class="tWrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>SLNO</th>
                                            <th>File</th>
                                            <th style="width: 190px;">Remove</th>
                                        </tr>
                                    </thead>
                                </table>
                                <div class="tWrap__body">
                                    <table>
                                        <tr *ngFor="let data of fileToUpload; let i=index">
                                            <td>
                                                *
                                            </td>
                                            <td>
                                                <span class="DownloadLink">{{data.name}}</span>

                                            </td>
                                            <td style="width: 190px;">
                                                <button type="button" (click)="RemoveNoteFilesBeforUpload(i)">-</button>
                                                <!-- <button type="button"  *ngIf="data.Emp_ID!=_JobCardService.selectedJobCard.Emp_ID" disabled style="cursor: no-drop;">-</button>  -->
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="let data of _JobCardService.selectedJobCard.JobCardNotesAttachment;let i=index">
                                            <td>
                                                *
                                            </td>
                                            <td>
                                                <span (click)="DownloadNoteFile(data)"
                                                    class="DownloadLink">{{data.Attachment}}</span>
                                                <!-- <span style="display: block; text-align: right;">{{data.EmpName}}
                                                    ({{data.Date |date}})</span> -->
                                            </td>
                                            <td style="width: 190px;">
                                                <button type="button"
                                                    *ngIf="data.Emp_ID==_JobCardService.selectedJobCard.Emp_ID"
                                                    (click)="RemoveNoteFiles(i)">-</button>
                                                <!-- <button type="button"  *ngIf="data.Emp_ID!=_JobCardService.selectedJobCard.Emp_ID" disabled style="cursor: no-drop;">-</button>  -->
                                            </td>
                                        </tr>
                                        <tr
                                            *ngIf="_JobCardService.selectedJobCard.JobCardNotesAttachment?.length<1 && fileToUpload?.length<1">
                                            <td colspan="3">
                                                No Record To Display.
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <button type="button" (click)="onSubmitJobDetails(JobCardNotes)" [disabled]="DisableSubmit"
                                class="sim-button button1" style="width: 100%;">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!-- HOLD REASON -->
    <form #JobCardHoldReason="ngForm" (ngSubmit)="onSubmitHoldReason()"
        *ngIf="this.saveMode == 'HoldingReason' || this.saveMode == 'HoldingReasonView'">
        <div class="popup-container">
            <input type="checkbox" id="test-results">
            <div class="popup HoldingReason" (click)="closePopUp()">
                <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                    <div class="title">
                        <p>Holding Reason</p> <label for="test-results"> <i class="fas fa-times"
                                (click)="closePopUp()"></i> </label>
                    </div>
                    <div [ngStyle]="{'pointer-events':this.saveMode == 'HoldingReasonView'?'none':'auto'}">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <label class="col-3 form-control-label">Reason <span class="validation-error">*</span> </label>
                            <div class="col-md-5">
                                <ng-select name="HoldingReason" id="HoldingReason" #HoldingReason="ngModel"
                                    bindLabel="Name" bindValue="Id" required [items]="holdingReasonList"
                                    [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.HoldingReason"
                                    placeholder="Select">
                                </ng-select>
                                <div class="validation-error" *ngIf="HoldingReason.invalid && HoldingReason.touched">
                                    This
                                    Field is Required.
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==1">
                            <div class="col-md-2"></div>
                            <label class="col-3 form-control-label">Meeting With 
                                <span class="validation-error">*</span></label>
                            <div class="col-md-5">
                                <ng-select name="MeetingWith" id="MeetingWith" bindLabel="EmpName" bindValue="Id"
                                    required [items]="_JobCardService.allEmployee" #MeetingWith="ngModel"
                                    [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.MettingWith"
                                    placeholder="Select">
                                </ng-select>
                                <div class="validation-error" *ngIf="MeetingWith.invalid && MeetingWith.touched">
                                    This Field is Required.
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==2">
                            <div class="col-md-2"></div>
                            <label class="col-3 form-control-label">Expecting Reasult From 
                                <span class="validation-error">*</span></label>
                            <div class="col-md-5">
                                <ng-select name="ExpectResult" id="ExpectResult" bindLabel="EmpName" bindValue="Id"
                                    required #ExpectResult="ngModel"
                                    [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom"
                                    [items]="_JobCardService.allEmployee" placeholder="Select">
                                </ng-select>
                                <div class="validation-error" *ngIf="ExpectResult.invalid && ExpectResult.touched">
                                    This Field is Required.
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==3">
                            <div class="col-md-2"></div>
                            <label class="col-3 form-control-label">Other Emergency Work 
                                <span class="validation-error">*</span></label>
                            <div class="col-md-5">
                                <ng-select name="otherwrk" id="otherwrk" bindLabel="Activity" bindValue="Activity" required
                                    #otherwrk="ngModel"
                                    [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.OtherEmergencyWork"
                                    [items]="OtherEmergencyWork" required placeholder="Select">
                                </ng-select>
                                <div class="validation-error" *ngIf="otherwrk.invalid && otherwrk.touched">
                                    This
                                    Field is Required.
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="_JobCardService.newJobDetails.HoldingReason.HoldingReason==4">
                            <div class="col-md-2"></div>
                            <label class="col-3 form-control-label">Clarification From 
                                <span class="validation-error">*</span></label>
                            <div class="col-md-5">
                                <ng-select name="ClarificationFrm" id="ClarificationFrm" bindLabel="EmpName"
                                    bindValue="Id" required #ClarificationFrm="ngModel"
                                    [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.ExpectingResultFrom"
                                    [items]="ClarificationFrom" placeholder="Select">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="ClarificationFrm.invalid && ClarificationFrm.touched">
                                    This Field is Required.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2"></div>
                            <label class="col-3 form-control-label">Reason <span class="validation-error">*</span></label>
                            <div class="col-md-5">
                                <textarea type="text" name="Reason" id="Reason" required #Reason="ngModel"
                                    [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.Reason"></textarea>
                                <div class="validation-error" *ngIf="Reason.invalid && Reason.touched">
                                    This Field is Required.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2"></div>
                            <label class="col-3 form-control-label">Remark <span class="validation-error">*</span></label>
                            <div class="col-md-5">
                                <textarea type="text" name="Remark" id="Remark" #Remark="ngModel" required
                                    [(ngModel)]="_JobCardService.newJobDetails.HoldingReason.Remark"></textarea>
                                <div class="validation-error" *ngIf="Remark.invalid && Remark.touched">
                                    This Field is Required.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4"> </div>
                            <div class="col-md-4" *ngIf="this.saveMode == 'HoldingReason'">
                                <button type="submit" class="sim-button button1" style="width: 100%;">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!--  tEST result KEYWORD POP UP -->
    <!-- <form #keyword="ngForm" *ngIf="OpenkeyWordPopup">
        <div class="popupkeyword-container">
            <div class="popupkeyword" (click)="OpenkeyWordPopup=false;">
                <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                    <div class="title">
                        <p>Key Words</p> <label for="test-results"> <i class="fas fa-times"
                                (click)="OpenkeyWordPopup=false;"></i> </label>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-md-8">
                                <input type="text" autofocus autocomplete="off" autofocus="autofocus"
                                    [(ngModel)]="AddNewKeyword" id="newKey" name="newKey" #newKey="ngModel"
                                    class="form-control">
                            </div>
                            <div class="col-md-2">
                                <button (click)="AddNewkeyWord()">Add</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style="max-height: 400px;
                            overflow: auto;">
                                <div class="tWrap">
                                    <div class="tWrap__head">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col">SLNO</th>
                                                    <th scope="col" style="  text-align: center;">Key Word</th>
                                                    <th scope="col" style="  width: 50px;text-align: center;">Action
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div class="tWrap__body">
                                        <table>
                                            <tbody>
                                                <tr *ngFor="let obj of KeyWordList| gridOperations :null: 
                                                    {KeyWord:AddNewKeyword}:{Column:null,Dir:null} ; let i = index">
                                                    <td>{{i +1}}</td>
                                                    <td style="text-decoration: underline;cursor: pointer;"
                                                        (click)="SelectKeyWord(obj)">{{obj.KeyWord }}</td>
                                                    <td style="width: 50px;"><button type="button"
                                                            (click)="deleteTesterKeyWord(i,obj.ID)">-</button></td>
                                                </tr>
                                                <tr *ngIf="KeyWordList.length<=0">
                                                    <td colspan="2">
                                                        {{KeyWordList.length}}
                                                        No Records To Display.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4"> </div>
                            <div class="col-md-4">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form> -->

    <form #description="ngForm" *ngIf="openDescriptionPopUp">
        <div class="popup-container">
            <div class="popup HoldingReason" (click)="openDescriptionPopUp=false;">
                <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                    <div class="title">
                        <p>Description</p> <label for="test-results"> <i class="fas fa-times"
                                (click)="openDescriptionPopUp=false;"></i> </label>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-12">
                                <label class="ui-form-input-container">
                                    <textarea class="ui-form-input" rows="10" [value]="Description" disabled></textarea>
                                    <!-- <span class="form-input-label">Description</span> -->
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>