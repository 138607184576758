import { Component, OnInit } from '@angular/core';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { Chart } from 'chart.js';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { CommonService } from 'src/app/Services/common.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { NgForm } from '@angular/forms';
import { SEDashBoardCustReq } from 'src/app/Models/Dashboard.Model';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { BranchService } from 'src/app/Services/branch.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { JobCardHoldingReason } from 'src/app/Models/job-card';
import { DateformatPipe } from '../../CustomPipe/dateformat.pipe';


@Component({
  selector: 'app-dealer-dashboard',
  templateUrl: './dealer-dashboard.component.html',
  styleUrls: ['./dealer-dashboard.component.css']
})
export class DealerDashboardComponent implements OnInit {

  private chartdiv:AmChart;

  public errorMsg;
  PriorityList: any;
  TypeList: any;
  StatusList: any;

  constructor(public AmCharts: AmChartsService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _CommonService: CommonService,
    public _BranchService: BranchService,
    public _serviceEngineerDBService: ServiceEngineerDBService,
    public _EmployeeService: EmployeeService,
    public _CustomerService: CustomerService,) { }

  holdReason: JobCardHoldingReason;
  holdingReasonList = [
    { Id: 1, Name: 'Meeting With' },
    { Id: 2, Name: 'Expecting Reasult From' },
    { Id: 3, Name: 'Other Emergency Work' },
    { Id: 4, Name: 'Clarification' },
    { Id: 5, Name: 'Service Charge Confirmation' },
  ];
  ngOnInit() {
    this.holdReason = { Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: 0, HoldingReason: null, ID: 0, JONO: 0, MettingWith: 0, OtherEmergencyWork: 0, Reason: '', Remark: '' }

    // Bar chart
    this.Reset();
    this.LoadCustomers();
    this.fillServiceEngineer();
    this._CommonService.displayKeyExpiryCount();
    this.PriorityList = this._CommonService.GetPriorityList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusList = this._CommonService.GetStatusFullList();
    this.LoadDealerDashboardCustReqData();
    this.LoadDealerDashboardTodaysSchdule();
    this.LoadGraph();
  }

  Reset(form?: NgForm) {
    this._BranchService.selectedBranch = {
      Addr1: null,
      Addr2: null,
      Addr3: null,
      BranchEmail: null,
      BranchFax: null,
      BranchName: null,
      Customer:null,
      BranchPhone: null,
      ContactEmail: null,
      ContactPerson: null,
      ContactPhone: null,
      CountryId: null,
      CustomerId: null,
      DealerId: null,
      Id: null,
      RouteId: null,
      UniqueBrachId: ''
    }
    this._serviceEngineerDBService.selectedDate = new Date()
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    this._CustomerService.allCustomers = []
    this._CustomerrequirementService.allSE = []
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('DEALERID')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }
  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('SERVICEENG')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  LoadDealerDashboardTodaysSchdule() {
    this._CustomerrequirementService.DealerDashBoardTodaysSchdule = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("DLTODAYS", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.DealerDashBoardTodaysSchdule = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  p: number = 1;
  LoadDealerDashboardCustReqData() {
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("DLID", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.SelectedDealerDashBoardCustReq = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  showForEdit(Backlog: SEDashBoardCustReq) {
    this._serviceEngineerDBService.AllWorkSortingDet = []
    this._serviceEngineerDBService.getPendingWorksDet(Backlog.RefNo, Backlog.Vtype, Backlog.DealerId, 'COMMON').subscribe(data => {
      this._serviceEngineerDBService.AllWorkSortingDet = data
    })

    this._BranchService.Fetch_BranchById(Backlog.BranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.selectedBranch = obj[0]
    })
  }

  LoadGraph() {
    //gauge
    var chart = this.AmCharts.makeChart("chartdiv", {
      "theme": "light",
      "type": "gauge",
      "axes": [{
        "topTextFontSize": 20,
        "topTextYOffset": 70,
        "axisColor": "#31d6ea",
        "axisThickness": 1,
        "endValue": 100,
        "gridInside": true,
        "inside": true,
        "radius": "50%",
        "valueInterval": 50,
        "tickColor": "#67b7dc",
        "startAngle": -90,
        "endAngle": 90,
        "unit": "%",
        "bandOutlineAlpha": 0,
        "bands": [{
          "color": "#0080ff",
          "endValue": 100,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0,
        },
        {
          "color": "#3cd3a3",
          "endValue": 0,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0
        }]
      }],
      "arrows": [{
        "alpha": 1,
        "innerRadius": "35%",
        "nailRadius": 0,
        "radius": "170%"
      }]
    });

    setInterval(randomValue, 2000);
    // set random value
    function randomValue() {
      var value = Math.round(Math.random() * 100);
      chart.arrows[0].setValue(value);
      chart.axes[0].setTopText(value + " %");
      // adjust darker band to new value
      chart.axes[0].bands[1].setEndValue(value);
    }
  }
  
  popUpIndex: number = 0;
  ClarificationFrom: any;
  openHoldPopUp(Backlog: SEDashBoardCustReq) {
    this._EmployeeService.Fetch_Employee('ELSE').subscribe(data => {
      this.ClarificationFrom = data;
    });
    this._serviceEngineerDBService.getHoldReason(Backlog.RefNo, Backlog.Vtype, Backlog.DealerId, 'HOLDREASON').subscribe(data => {
      this.holdReason = data;
    })
    this.popUpIndex = 1;
  }
  closePopUp() {
    this.popUpIndex = 0;
    this._CommonService.expDetailsPopup=0;
  }
}
