import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-report-for-products-list',
  templateUrl: './service-report-for-products-list.component.html',
  styleUrls: ['./service-report-for-products-list.component.css']
})
export class ServiceReportForProductsListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
