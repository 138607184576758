<div class="container-fluid">

    <!-- The Modal -->
    <div class="modal fade show" id="myModalConfirm2">
        <div class="modal-dialog">

            <div *ngIf="ServiceReportEntryForm">
                <!--class="modal-dialog" -->
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header">
                        <span class="modal-title">Service Report Entry</span>
                        <button type="button" class="close" (click)="OnClickCancelser()">×</button>
                    </div>

                    <div class="modal-body" style="overflow: scroll;height: 600px;">
                        <div class="tab-content">
                            <div id="sec1" class="container-fluid tab-pane active">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group row">
                                            <label class="col-md-2  form-control-label" for="select">Ref.No</label>
                                            <div class="col-md-5 input-group">
                                                <input class="form-control" name="RefNo" #RefNo="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.RefNo"
                                                    placeholder=" RefNo" required>
                                              
                                            </div>



                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-2 form-control-label">Date</label>
                                            <div class="col-md-4">
                                                <input class="section3" placeholder="Date" #Date="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Date"
                                                    style="color: black; width: 110px; text-align: left;"
                                                    autocomplete="off" bsDatepicker name="currentStringDate"
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required>
                                               
                                            </div>

                                            <label class="col-md-2  form-control-label" for="select">Support
                                                Mode</label>
                                            <div class="col-md-4">
                                                <select class="form-control" name="SupportMode"
                                                    #SupportMode="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.SupportMode"
                                                    required>
                                                    <option [ngValue]="null" disabled>Select</option>
                                                    <option [ngValue]="4">On Site</option>
                                                    <option [ngValue]="5">Remote Section</option>
                                                </select>
                                              
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <!-- <label class="col-md-2 form-control-label">From</label>
                                            <div class="col-md-4">
                                                <input type="time" class="form-control" placeholder="From Date"
                                                    name="From" disabled #From="ngModel"
                                                    [ngModel]="_ServiceReportEntryService.SelectedServiceRepHeader.From"
                                                    id="From"  style="color: black; width: 110px; text-align: center;"
                                                    autocomplete="off" required>
                                               
                                            </div>
                                            <label class="col-md-2 form-control-label">To</label>
                                            <div class="col-md-4">
                                                <input type="time" class="form-control" placeholder="To Date"
                                                    #To="ngModel" disabled name="To"
                                                    [ngModel]="_ServiceReportEntryService.SelectedServiceRepHeader.To"
                                                    style="color: black;  width: 110px;text-align: center;"
                                                    autocomplete="off" id="To" required>
                                              
                                            </div> -->
                                            <div class="col-md-3">
                                                <label>Date<span style="color: red;">*</span></label>
                                                <input class="form-control" style="font-size: .8rem;" placeholder="Date"
                                                    #Date="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Date"
                                                    autocomplete="off" bsDatepicker 
                                                    name="currentStringDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                                                    required>
                                               
                                            </div>
                                            <div class="col-md-3">
                                                <label>From<span style="color: red;">*</span></label>
                                                <input type="time" style="font-size: .6rem;" class="form-control" disabled
                                                    placeholder="From Date" name="From" #From="ngModel"
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.From"
                                                    autocomplete="off" required>
                                               
                                            </div>
                                            <div class="col-md-3">
                                                <label>To<span style="color: red;">*</span></label>
        
                                                <input type="time" style="font-size: .6rem;" class="form-control" disabled
                                                    placeholder="To Date" #To="ngModel" name="To"
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.To"
                                                    autocomplete="off" required>
                                               
                                            </div>
                                        </div>
                                        <div class="form-group row">

                                            <label class="col-md-2  form-control-label" for="select">SE</label>
                                            <div class="col-md-4">
                                                <select class="form-control" name="SEID" #SEID="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Se"
                                                    required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option
                                                        *ngFor="let obj of _CustomerrequirementService.allSE | orderBy : 'EmpName'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.EmpName}}
                                                    </option>
                                                </select>
                                                
                                            </div>

                                            <div class="col-md-1">
                                                <input style="width: 18px;height: 25px;" type="checkbox"
                                                    id="ExtraTime" disabled name="ExtraTime" #ExtraTime="ngModel"
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ExtraTime">
                                            </div>
                                            <label class="col-md-1 form-control-label">+1</label>
                                        </div>
                                        <div class="form-group row">
                                            <label class=" col-md-3 form-control-label">Modules</label>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-12">
                                                <div  class="tWrap" disabled>
                                                    <div class="tWrap__head">
                                                    <table >
                                                    <thead>
                                                        <th scope="col">Modules</th>
                                                        <th scope="col">Script</th>
                                                        <th scope="col">Exe</th>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div class="tWrap__body">
                                                <table>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let obj of _modulService.allModuleServiceReport | orderBy : 'ModuleName'; ;let i=index">
                                                            <th  style="background:white;color:black;">{{obj.ModuleName}}</th>
                                                            <th  style="background:white;color:black;">
                                                                <input class="section3" placeholder="Date" disabled
                                                                    style="color: black; text-align: center;width: 110px;"
                                                                    autocomplete="off" bsDatepicker
                                                                    #ScriptDate{{i}}="ngModel"
                                                                    [ngModel]="obj.ScriptDateToBind"
                                                                    name="ScriptDate{{i}}"
                                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">

                                                            </th>
                                                            <th  style="background:white;color:black;">
                                                                <input class="section3" placeholder="Date" disabled
                                                                    style="color: black; text-align: center; width: 110px;"
                                                                    autocomplete="off" bsDatepicker
                                                                    [ngModel]="obj.ExeDateToBind"
                                                                    name="ExeDate{{i}}" #ExeDate{{i}}="ngModel"
                                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">

                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group row">
                                            <label class="col-md-4  form-control-label"
                                                for="select">Customer</label>
                                            <div class="col-md-8">
                                                <select class="form-control" name="CustomerId" #CustomerId="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustomerId"
                                                    required>
                                                    <option [ngValue]="null" disabled>Select</option>
                                                    <option
                                                        *ngFor="let prf of _CustomerService.allCustomers | orderBy : 'Name'"
                                                        [ngValue]="prf.Id">
                                                        {{ prf.Name }}
                                                    </option>
                                                </select>
                                               
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label">Address</label>
                                            <div class="col-md-8">
                                                <input class="form-control" name="CustAdr1" #CustAdr1="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustAdr1"
                                                    placeholder=" Addr1" required>
                                              
                                                <input class="form-control" name="CustAdr2" #CustAdr2="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustAdr2"
                                                    placeholder=" Addr2" required>
                                                
                                                <input class="form-control" name="CustAdr3" #CustAdr3="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustAdr3"
                                                    placeholder=" Addr3" required>
                                              
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4  form-control-label" for="select">Branch</label>
                                            <div class="col-md-8">
                                                <select class="form-control" name="BranchId" #BranchId="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchId"
                                                    required>
                                                    <option [ngValue]="null" disabled>Select</option>
                                                    <option
                                                        *ngFor="let prf of _BranchService.allBranchs | orderBy : 'BranchName'"
                                                        [ngValue]="prf.Id">
                                                        {{ prf.BranchName }}
                                                    </option>
                                                </select>
                                              
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label">Address</label>
                                            <div class="col-md-8">
                                                <input class="form-control" name="BranchAdr1" #BranchAdr1="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr1"
                                                    placeholder=" Addr1" required>
                                               
                                                <input class="form-control" name="BranchAdr2" #BranchAdr2="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr2"
                                                    placeholder=" Addr2" required>
                                               
                                                <input class="form-control" name="BranchAdr3" #BranchAdr3="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr3"
                                                    placeholder=" Addr3" required>
                                              
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-4">

                                        <div class="form-group row">
                                            <label class="col-md-4  form-control-label" for="select">Project</label>
                                            <div class="col-md-8">
                                                <select class="form-control" name="ProjectId" #ProjectId="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ProjectId"
                                                    required>
                                                    <option [ngValue]="null" disabled>Select</option>
                                                    <option
                                                        *ngFor="let prf of _projectService.allProjects | orderBy : 'Name'"
                                                        [ngValue]="prf.Id">
                                                        {{ prf.Name }}
                                                    </option>
                                                </select>
                                             
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-2  form-control-label" for="select">Server</label>
                                            <div class="col-md-4">
                                                <input class="form-control" name="Server" #Server="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Server"
                                                    placeholder=" Server" required>
                                            </div>
                                            <label class="col-md-2  form-control-label" for="select">Node</label>
                                            <div class="col-md-4">
                                                <input class="form-control" name="Node" #Node="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Node"
                                                    placeholder=" Node" required>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-4 form-control-label">Contract Date</label>
                                            <div class="col-md-4">
                                                <input class="section3" placeholder="Contract Date"
                                                    #ContractDate="ngModel" disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ContractDate"
                                                    style="color: black; width: 110px; text-align: left;"
                                                    autocomplete="off" bsDatepicker name="ContractDate"
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                            </div>
                                            <div class="col-md-4 "
                                                *ngIf="_ServiceReportEntryService.SelectedServiceRepHeader.ContractDate!=null">
                                                <label style="color:green" class="form-control-label" disabled
                                                    *ngIf="this.AMCRenewalStatus">ON
                                                    AMC</label>
                                                <label style="color:red" class="form-control-label" disabled
                                                    *ngIf="!this.AMCRenewalStatus">AMC
                                                    EXIPIRED</label>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label disabled
                                                class="col-md-12 form-control-label">{{this._ServiceReportEntryService.SelectedServiceRepHeader.ContractVsCurrentDate}}</label>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-2  form-control-label" for="select">Amc
                                                Renewal</label>
                                            <div class="col-md-4">
                                                <input class="section3" placeholder="Amc Renewal" disabled
                                                    style="color: black; text-align: center;width: 110px;"
                                                    autocomplete="off" bsDatepicker name="AMCRenewal"
                                                    #AMCRenewal="ngModel"
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal"
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                            </div>
                                            <label class="col-md-2  form-control-label" for="select">Amc
                                                Upto</label>
                                            <div class="col-md-4">
                                                <input class="section3" placeholder="AMC Upto" disabled
                                                    style="color: black; text-align: center;width: 110px;"
                                                    autocomplete="off" bsDatepicker name="AMCUpto"
                                                    #AMCUpto="ngModel"
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto"
                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-md-4  form-control-label" for="select">No of
                                                Visits</label>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" #NumberOfVisit="ngModel"
                                                    disabled
                                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.NumberOfVisit"
                                                    name="NumberOfVisit" placeholder="No of Visits" required>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-5 form-control-label" disabled
                                                *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit !=null">
                                                Last
                                                Visit:{{this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit|date}}
                                            </label>
                                            <label class="col-md-1 form-control-label"
                                                *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE !=0">
                                                SE:
                                            </label>
                                            <label class="col-md-5 form-control-label" disabled
                                                *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE !=0">
                                                <select class="form-control" name="SE" #SE="ngModel" disabled
                                                    [(ngModel)]="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE"
                                                    required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option
                                                        *ngFor="let obj of this._CustomerrequirementService.allSE | orderBy : 'EmpName'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.EmpName}}
                                                    </option>
                                                </select>
                                            </label>

                                        </div>
                                    </div>

                                </div>

                                <hr>
                                <div style="text-align: center;">
                                    <h6>
                                        <ul>Pending Works/Work Done</ul>
                                    </h6>
                                </div>
                                <br>
                                <div class="row" disabled>
                                    <div class="tWrap">
                                        <div class="tWrap__head">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th scope="col">SlNo</th>
                                                    <th scope="col">Ref No</th>
                                                    <th scope="col">Module</th>
                                                    <th scope="col">Particular</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Priority</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Remarks</th>

                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div class="tWrap__body">
                                        <table>
                                            <tbody>
                                                <tr
                                                    *ngFor="let field of this._ServiceReportEntryService.SelectedServiceDetails ; let i = index">
                                                    <th  style="background:white;color:black;"  scope="row">{{i+1}}</th>
                                                    <td>{{field.RefNo}}</td>

                                                    <td>
                                                        <span class="form-control-label" disabled
                                                            *ngFor="let obj of _modulService.allModuleServiceReport">
                                                            <p *ngIf="field.ModuleId==obj.Id">
                                                                {{obj.ModuleName}}
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="form-control-label" disabled
                                                            *ngFor="let obj of this._ParticularmasterService.allparticular | orderBy : 'Particulars'">
                                                            <p *ngIf="field.Particular==obj.ID">
                                                                {{obj.Particulars}}
                                                            </p>
                                                        </span>
                                                    </td>

                                                    <td disabled>
                                                        {{field.Description}}
                                                    </td>
                                                    <td>
                                                        <span class="form-control-label" disabled
                                                            *ngFor="let obj of TypeList">
                                                            <p *ngIf="field.Type==obj.Id">
                                                                {{obj.Name}}
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="form-control-label"
                                                            *ngFor="let obj of PriorityList" disabled>
                                                            <p *ngIf="field.Priority==obj.Id">
                                                                {{obj.Name}}
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="form-control-label" disabled
                                                            *ngFor="let obj of StatusFullList">
                                                            <p *ngIf="field.Status==obj.Id">
                                                                {{obj.Name}}
                                                            </p>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {{field.Remarks}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                                <hr>
                                <div style="text-align: center;">
                                    <h6>
                                        <ul>Customer Requirement</ul>
                                    </h6>
                                </div>
                                <br>
                                <div class="row">
                                    <div  class="tWrap" disabled>

                                        <div class="tWrap__head">
                                        <table >
                                      
                                            <thead>
                                                <tr>
                                                    <th scope="col">SlNo</th>
                                                    <th scope="col">Module</th>
                                                    <th scope="col">Particular</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Priority</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Remarks</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div class="tWrap__body">
                                        <table>
                                            <tbody>
                                                <tr
                                                    *ngFor="let field of this._ServiceReportEntryService.selectedServiceRequirement; let i = index">
                                                    <th style="background:white;color:black;" scope="row">{{i+1}}</th>
                                                    <td>
                                                        <label class="form-control-label" disabled
                                                            *ngFor="let obj of _modulService.allModuleServiceReport">
                                                            <p *ngIf="field.ModuleId==obj.Id">
                                                                {{obj.ModuleName}}
                                                            </p>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label class="form-control-label" disabled
                                                            *ngFor="let obj of this._ParticularmasterService.allparticular | orderBy : 'Particulars'">
                                                            <p *ngIf="field.Particular==obj.ID">
                                                                {{obj.Particulars}}
                                                            </p>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {{field.Description}}
                                                    </td>
                                                    <td>
                                                        <label class="form-control-label" disabled
                                                            *ngFor="let obj of TypeList">
                                                            <p *ngIf="field.Type==obj.Id">
                                                                {{obj.Name}}
                                                            </p>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label class="form-control-label" disabled
                                                            *ngFor="let obj of PriorityList">
                                                            <p *ngIf="field.Priority==obj.Id">
                                                                {{obj.Name}}
                                                            </p>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="field.Status==3||field.Status==4||field.Status==5||field.Status==6||field.Status==7||field.Status==8||field.Status==9">
                                                            <span class="form-control-label" *ngFor="let obj of StatusFullList" disabled>
                                                                <p *ngIf="obj.Id==field.Status">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span>
                                                        </div>
                                                        <div *ngIf="field.Status==1||field.Status==2||field.Status==10">
                                                            <span class="form-control-label" *ngFor="let obj of StatusList" disabled>
                                                                <p *ngIf="obj.Id==field.Status">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span>
                                                           
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{field.Remarks}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <label class="col-md-1  form-control-label" for="select">Note:</label>
                                    <div class="col-md-11">
                                        <textarea class="form-control" name="Notes" #Notes="ngModel" disabled
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Notes"
                                            placeholder=" Notes" required></textarea>
                                       
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <label class="col-md-2  form-control-label" for="select">Service
                                        Charge:</label>
                                    <div class="col-md-3">
                                        <input type="text" placeholder="Service Charge" class="form-control"
                                            disabled name="ServiceCharge" #ServiceCharge="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge"
                                            required>
                                        
                                    </div>
                                    <label class="col-md-2  form-control-label" for="select">Paid:</label>
                                    <div class="col-md-3">
                                        <input type="text" placeholder="Paid" class="form-control" name="PaidAmount"
                                            disabled #PaidAmount="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.PaidAmount"
                                            required>
                                     
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-md-2  form-control-label" for="select">Balance:</label>
                                    <div class="col-md-3">
                                        <input type="text" placeholder="Balance" class="form-control"
                                            name="BalanceAmount" disabled #BalanceAmount="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BalanceAmount"
                                            required>
                                       
                                    </div>
                                    <label class="col-md-2  form-control-label" for="select">Transportation and
                                        Other
                                        Charges:</label>
                                    <div class="col-md-3">
                                        <input type="text" placeholder="Transportation and Other Charges" disabled
                                            class="form-control" name="TransNOtherCharges"
                                            #TransNOtherCharges="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.TransNOtherCharges"
                                            required>
                                     
                                    </div>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="WarningModel">
                <!--class="modal-dialog" -->
                <div class="modal-content" style="width: 62%;">
                    <div class="modal-header">
                        <button type="button" class="close" (click)="OnClickCancelser()">×</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>