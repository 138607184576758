import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LeavedetailsService } from 'src/app/Services/leavedetails.service';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { HolidayDetails, LeaveDetails, OffdayDetails } from 'src/app/Models/LeaveDetails';
@Component({
  selector: 'app-holiday-settings',
  templateUrl: './holiday-settings.component.html',
  styleUrls: ['./holiday-settings.component.css']
})
export class HolidaySettingsComponent implements OnInit {
  public errorMsg;
  public deletePopUp1: boolean = false;
  public deletePopUp2: boolean = false;
  deleteID: number = 0;
  public term: any = '';
  isSubmitoff: boolean  //API call multiple 
  FormValidationoff: boolean  //Used form form validation
  isSubmitho: boolean  //API call multiple 
  FormValidationho: boolean  //Used form form validation
  isSubmitle: boolean  //API call multiple 
  FormValidationle: boolean  //Used form form validation
  constructor(
    public _LeavedetailsService: LeavedetailsService,
    private toastr: ToastrService,
    public _CommonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.deleteID = 0;
    this.deletePopUp1 = false;
    this.deletePopUp2 = false;
    this._LeavedetailsService.pageIndexL = 1;
    this._CommonService.pageIndex = 1;
    this.getLeaveDetails();
    this.getHolidayDetails();
    this.getOffdayDetails();
    this.resetForm();
    this.resetHolidayForm();
    this.resetOffdayForm();
    this._LeavedetailsService.giveEditPermissionOFF = true;
    this._LeavedetailsService.giveEditPermissioHoliday = true;
    this._LeavedetailsService.giveEditPermissioLeave = true;
  }

  getLeaveDetails() {
    this._LeavedetailsService.getLeaveDetails("ALL")
      .subscribe(data => {
        this._LeavedetailsService.allleavedetails = data

      },
        error => {
          this.errorMsg = error;
        })
  }

  getHolidayDetails() {
    this._LeavedetailsService.getHolidayDetails("ALL")
      .subscribe(data => {
        this._LeavedetailsService.allholidaydetails = data

      },
        error => {
          this.errorMsg = error;
        })
  }

  getOffdayDetails() {
    this._LeavedetailsService.getOffdayDetails("ALL")
      .subscribe(data => {
        this._LeavedetailsService.alloffdaydetails = data
        this._LeavedetailsService.alloffdaydetails.forEach(obj => {
          this._LeavedetailsService.selectedRecordOffday.Id = obj.Id
          this._LeavedetailsService.selectedRecordOffday.Sunday = obj.Sunday
          this._LeavedetailsService.selectedRecordOffday.Monday = obj.Monday
          this._LeavedetailsService.selectedRecordOffday.Tuesday = obj.Tuesday
          this._LeavedetailsService.selectedRecordOffday.Wednesday = obj.Wednesday
          this._LeavedetailsService.selectedRecordOffday.Thursday = obj.Thursday
          this._LeavedetailsService.selectedRecordOffday.Friday = obj.Friday
          this._LeavedetailsService.selectedRecordOffday.Saturday = obj.Saturday
          this._LeavedetailsService.selectedRecordOffday.ManHours = obj.ManHours
        })
      },
        error => {
          this.errorMsg = error;
        })
  }

  toggleFilter() {
    this._LeavedetailsService.isFiltering = this._LeavedetailsService.isFiltering ? false : true;
    if (!this._LeavedetailsService.isFiltering) {
      this._LeavedetailsService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._LeavedetailsService.pageIndexL = 1;
    }
  }
  toggleHolidayFilter() {
    this._LeavedetailsService.isFiltering = this._LeavedetailsService.isFiltering ? false : true;
    if (!this._LeavedetailsService.isFiltering) {
      this._LeavedetailsService.ClearHolidayFilter();
      this._CommonService.pageIndex = 1;
      this._LeavedetailsService.pageIndexH = 1;
    }
  }
  sort(colName: string) {
    if (colName == this._LeavedetailsService.orderByColName) {
      this._LeavedetailsService.orderByDir = !this._LeavedetailsService.orderByDir;
    }
    this._LeavedetailsService.orderByColName = colName;
  }
  NoOfItemsL: number = 10;
  NoOfItemsH: number = 10;
  OnEdit(selectedRow: LeaveDetails) {
    this._LeavedetailsService.selectedRecord = Object.assign({}, selectedRow);

  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  OnEditHoliday(selectedRow: HolidayDetails) {
    this._LeavedetailsService.selectedRecordHoliday.Reason = selectedRow.Reason;
    this._LeavedetailsService.selectedRecordHoliday.Note = selectedRow.Note;
    this._LeavedetailsService.selectedRecordHoliday.From = new Date(selectedRow.From);
    this._LeavedetailsService.selectedRecordHoliday.To = new Date(selectedRow.To);

  }
  onDelete(deleteID: number) {
    console.log('deleteID', deleteID)
    this.deletePopUp1 = true;
    this.deleteID = deleteID;
  }
  onDeleteHoliday(deleteID: number) {
    console.log('deleteID', deleteID)
    this.deletePopUp2 = true;
    this.deleteID = deleteID;
  }
  dialogResult(event) {
    this.deletePopUp1 = false;
    if (event) {
      this._LeavedetailsService.onDelete(this.deleteID)
        .subscribe(x => {
          this._LeavedetailsService.getLeaveDetails("ALL").subscribe(data => this._LeavedetailsService.allleavedetails = data,
            error => {
              this.errorMsg = error;
              console.error(error.name, error.message, error.stack);
            });
          this.toastr.success("Deleted Successfully", "Leave details");
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.warning("Deletion Failure", "Leave details");
        })
    }
  }
  dialogResultHoliday(event) {
    this.deletePopUp2 = false;
    if (event) {
      this._LeavedetailsService.onDeleteHoliday(this.deleteID)
        .subscribe(x => {
          this._LeavedetailsService.getHolidayDetails("ALL").subscribe(data => this._LeavedetailsService.allholidaydetails = data,
            error => {
              this.errorMsg = error;
              console.error(error.name, error.message, error.stack);
            });
          this.toastr.success("Deleted Successfully", "Holiday details");
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.warning("Deletion Failure", "Holiday details");
        })
    }
  }
  formValidation(): boolean {
    if (this._LeavedetailsService.selectedRecord.Name != "" && this._LeavedetailsService.selectedRecord.NoOfDays > 0)
      return true;
    else
      return false;
  }
  onSubmit(frm: NgForm) {
    this.FormValidationle = true
    if (frm.valid && this.formValidation()) {
      this.isSubmitle = true
      this._LeavedetailsService.SaveItem(this._LeavedetailsService.selectedRecord).subscribe(
        async data => {
          this.isSubmitle = false
          if (data.Status == 0) {

            this.toastr.success(data.Message, "Leave details Master")
            this.resetForm();
            this._LeavedetailsService.getLeaveDetails("ALL").subscribe(data => this._LeavedetailsService.allleavedetails = data,
              error => {
                this.errorMsg = error;
              });
          }
          else {
            this.toastr.warning(data.Message, "Leave details Master")

          }
        }
      );


    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Leave details Master');
    }
  }
  formValidationHoliday(): boolean {
    if (this._LeavedetailsService.selectedRecordHoliday.Reason != "" && this._LeavedetailsService.selectedRecordHoliday.Note != ""
      && this._LeavedetailsService.selectedRecordHoliday.From != "" && this._LeavedetailsService.selectedRecordHoliday.To != "")
      return true;
    else
      return false;
  }

  onHolidaySubmit(frm: NgForm) {
    this.FormValidationho = true
    if (frm.valid && this.formValidationHoliday()) {
      this.isSubmitho = true
      this._LeavedetailsService.selectedRecordHoliday.From = this.convert(this._LeavedetailsService.selectedRecordHoliday.From);
      this._LeavedetailsService.selectedRecordHoliday.To = this.convert(this._LeavedetailsService.selectedRecordHoliday.To);
      this._LeavedetailsService.SaveHolidayItem(this._LeavedetailsService.selectedRecordHoliday).subscribe(
        async data => {
          this.isSubmitho = false
          if (data.Status == 0) {

            this.toastr.success(data.Message, "Holiday details Master")
            this.resetHolidayForm();
            this._LeavedetailsService.getHolidayDetails("ALL").subscribe(data => this._LeavedetailsService.allholidaydetails = data,
              error => {
                this.errorMsg = error;
              });
          }
          else {
            this.toastr.warning(data.Message, "Holiday details Master")

          }
        }
      );


    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Holiday details Master');
    }
  }
  formValidationOffday(): boolean {
    if (this._LeavedetailsService.selectedRecordOffday.ManHours > 0)
      return true;
    else
      return false;
  }
  onOffdaySubmit(frm: NgForm) {
    this.FormValidationoff = true
    if (frm.valid && this.formValidationOffday()) {
      this.isSubmitoff = true
      this._LeavedetailsService.SaveOffdayItem(this._LeavedetailsService.selectedRecordOffday).subscribe(
        async data => {
          this.isSubmitoff = false
          if (data.Status == 0) {

            this.toastr.success(data.Message, "Offday details Master")
            //this.resetOffdayForm();
            this._LeavedetailsService.getOffdayDetails("ALL").subscribe(data => this._LeavedetailsService.alloffdaydetails = data,
              error => {
                this.errorMsg = error;
              });
          }
          else {
            this.toastr.warning(data.Message, "Offday details Master")

          }
        }
      );


    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Offday details Master');
    }
  }

  resetForm(form?: NgForm) {
    this.isSubmitle = false
    this.FormValidationle = false
    if (form != null)
      form.reset();
    this._LeavedetailsService.selectedRecord = {
      Id: null,
      Name: '',
      NoOfDays: 0
    }
  }
  resetHolidayForm(form?: NgForm) {
    this.isSubmitho = false
    this.FormValidationho = false
    if (form != null)
      form.reset();
    this._LeavedetailsService.selectedRecordHoliday = {
      Id: null,
      Reason: '',
      From: new Date(),
      To: new Date(),
      Note: '',
    }
  }
  resetOffdayForm(form?: NgForm) {
    this.isSubmitoff = false
    this.FormValidationoff = false
    if (form != null)
      form.reset();
    this._LeavedetailsService.selectedRecordOffday = {
      Id: null,
      Sunday: false
      , Monday: false
      , Tuesday: false
      , Wednesday: false
      , Thursday: false
      , Friday: false
      , Saturday: false
      , ManHours: 0

    }
  }
  resetText(form?: NgForm) {
    form.reset();

  }
  resetHolidayText(form?: NgForm) {
    form.reset();

  }
}
