<div class="container-fluid">
    <nav>
        <div class="row d-flex d-md-block flex-nowrap wrapper">
            <div class="col-md-2 float-left col-1 pl-0 pr-0 collapse width show" id="sidebar">
                <div class="list-group border-0 card text-center text-md-left">

                    <!--------------------------------DASH BOAD LIST-------------------------------------------------------------------------->

                    <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/dashboard"
                        class="list-group-item d-inline-block collapsed active" data-parent="#sidebar"><i
                            class="fa fa-dashboard"></i> <span class="d-none d-md-inline">Dashboard</span> </a>
                    <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/dealerdashboard" class="list-group-item"
                        data-parent="#sidebar"><i class="fa fa-dashboard"></i> <span
                            class="d-none d-md-inline">Dealer</span> </a>
                    <a *ngIf="userService.roleMatch(['Support Engineer'])" routerLink="/supportEngDashboard"
                        class="list-group-item" data-parent="#Reports"><i class="fas fa-star"></i>Support Engineer Home Screen</a>
                    <a *ngIf="userService.roleMatch(['Support Leader'])" routerLink="/supportleaderhome"
                        class="list-group-item" data-parent="#Reports"><i class="fas fa-star"></i>Support Leader Home Screen</a>
                    <a *ngIf="userService.roleMatch(['Project Manager'])" routerLink="/projectmanagerhome"
                        class="list-group-item" data-parent="#Reports"><i class="fas fa-star"></i>Project Manager Home Screen</a>

                    <!---------------------------------MASTERS--------------------------------------------------------------------->

                    <a href="#admin" class="list-group-item d-inline-block collapsed" data-toggle="collapse"
                        data-parent="#sidebar" aria-expanded="false"><i class="fas fa-list"></i><span
                            class="d-none d-md-inline">Master</span>
                        <i class="fas fa-caret-right"></i>
                    </a>
                    <div class="collapse" id="admin">

                        <!-- Deaer -->

                        <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/routemaster" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-map-marker"></i>Route Master</a>
                        <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/customer" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-users"></i>Customer</a>
                        <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/branch" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-star"></i>Branch</a>
                        <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/branchsettings" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-star"></i>Branch Settings</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/designation" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-list"></i>Designation</a>
                        <a *ngIf="userService.roleMatch(['Admin','Dealer'])" routerLink="/employee"
                            class="list-group-item" data-parent="#admin"><i class="fas fa-users"></i>Employee</a>

                        <!-- Admin -->

                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/country" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-globe"></i>Country</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/dealer" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-user-circle"></i>Dealer</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/project" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-file"></i>Project</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/module" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-list"></i> Module</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/bussinesstype" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-building"></i>Business Type</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/settings" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-file"></i>Settings</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/newlead" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-star"></i>New Lead</a>
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/reasonmaster" class="list-group-item"
                            data-parent="#admin"><i class="fas fa-globe"></i>Reason Master</a>
                        <!-- Support Engineer -->
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/particularmaster"
                            class="list-group-item" data-parent="#admin"><i class="fas fa-file"></i>Particular Master</a>
                        <a *ngIf="userService.roleMatch(['Support Engineer'])" routerLink="/customerfeedbackmaster"
                            class="list-group-item" data-parent="#admin"><i class="fas fa-file"></i>Customer Feedback Master</a>
                    </div>

                    <!------------------------------ Activities ------------------------------------------------------->

                    <a href="#dealer" class="list-group-item d-inline-block collapsed" data-toggle="collapse"
                        data-parent="#sidebar" aria-expanded="false"><i class="fas fa-star"></i> 
                        <span class="d-none d-md-inline">Activity</span>
                        <i class="fas fa-caret-right"></i>
                    </a>
                    <div class="collapse" id="dealer">
                        <!-- admin -->
                        <!-- Deaer -->
                        <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/softwareregistration"
                            class="list-group-item" data-parent="#dealer"><i class="fas fa-star"></i>Software
                            Registration</a>
                        <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/noderegistration"
                            class="list-group-item" data-parent="#dealer"><i class="fas fa-star"></i>Node
                            Registration</a>
                        <a *ngIf="userService.roleMatch(['Dealer'])" routerLink="/amcrenewal" class="list-group-item"
                            data-parent="#dealer"><i class="fas fa-star"></i>AMC Renewal</a>

                        <!-- Support Engineer -->
                        <a *ngIf="userService.roleMatch(['Support Engineer','Project Manager'])"
                            routerLink="/customerrequirement" class="list-group-item" data-parent="#SupportEngineer"><i
                                class="fas fa-star"></i>Customer Requirements</a>
                        <a *ngIf="userService.roleMatch(['Support Engineer'])" routerLink="/customerfeedback"
                            class="list-group-item" data-parent="#SupportEngineer"><i class="fas fa-star"></i>Customer
                            Feedback</a>
                        <a *ngIf="userService.roleMatch(['Support Engineer','Project Manager'])"
                            routerLink="/servicereportentry" class="list-group-item" data-parent="#SupportEngineer"><i
                                class="fas fa-star"></i>Service Report Entry Form</a>

                        <!-- Support Leader -->

                        <a *ngIf="userService.roleMatch(['Support Leader'])" routerLink="/worksorting"
                            class="list-group-item" data-parent="#Reports"><i class="fas fa-star"></i>Work Sorting</a>
                        <a *ngIf="userService.roleMatch(['Support Leader'])" routerLink="/workallocation"
                            class="list-group-item" data-parent="#Reports"><i class="fas fa-star"></i>Work
                            Allocation</a>

                        <!-- Project Manager (Inhouse)-->
                        <a *ngIf="userService.roleMatch(['Project Manager'])" routerLink="/workstatus"
                            class="list-group-item" data-parent="#SupportEngineer"><i class="fas fa-star"></i>Work
                            Status</a>
                        <a *ngIf="userService.roleMatch(['Project Manager'])" routerLink="/projectallotment"
                            class="list-group-item" data-parent="#SupportEngineer"><i class="fas fa-star"></i>Project
                            Allotment</a>
                        <a *ngIf="userService.roleMatch(['Project Manager'])" routerLink="/prowork"
                            class="list-group-item" data-parent="#SupportEngineer"><i class="fas fa-star"></i>Project
                            Work
                            Allotment</a>
                           
                    </div>

                    <!------------------------------ Reports ------------------------------------------------------->
                    <a href="#Reports" class="list-group-item d-inline-block collapsed" data-toggle="collapse"
                        data-parent="#sidebar" aria-expanded="false"><i class="fas fa-star"></i> <span
                            class="d-none d-md-inline">Reports</span>
                        <i class="fas fa-caret-right"></i>
                    </a>
                    <div class="collapse" id="Reports">
                        <a *ngIf="userService.roleMatch(['Admin'])" routerLink="/workForecasting"
                            class="list-group-item" data-parent="#SupportEngineer"><i class="fas fa-star"></i>Project
                            Forecasting</a>
                        <a *ngIf="userService.roleMatch(['Admin','Support Leader','Dealer'])"
                            routerLink="/servicehistory" class="list-group-item" data-parent="#SupportEngineer"><i
                                class="fas fa-star"></i>Service History</a>
                    </div>
                    <!------------------------------ MIS ------------------------------------------------------->
                    <a href="#mis" class="list-group-item d-inline-block collapsed" data-toggle="collapse"
                        data-parent="#sidebar" aria-expanded="false"><i class="fas fa-star"></i> <span
                            class="d-none d-md-inline">MIS</span>
                        <i class="fas fa-caret-right"></i>
                    </a>
                    <div class="collapse" id="mis">
                    </div>

                    <!------------------------------ Utility ------------------------------------------------------->

                    <a href="#utility" class="list-group-item d-inline-block collapsed" data-toggle="collapse"
                        data-parent="#sidebar" aria-expanded="false"><i class="fas fa-star"></i> <span
                            class="d-none d-md-inline">Utility</span>
                        <i class="fas fa-caret-right"></i>
                    </a>
                    <div class="collapse" id="utility">
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>