import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DatePeriod } from 'src/app/Models/DatePeriod';
import { CommonService } from 'src/app/Services/common.service';
import { ProjectCostAnalysisService } from 'src/app/Services/project-cost-analysis.service';

@Component({
  selector: 'app-project-cost-analysis',
  templateUrl: './project-cost-analysis.component.html',
  styleUrls: ['./project-cost-analysis.component.css']
})
export class ProjectCostAnalysisComponent implements OnInit {

  errorMsg:string=''
  dDates:DatePeriod={FromDate:'',ToDate:''}
  enabledates:boolean=true;
  viewProejctCostDetails:boolean=false;
  
  constructor(public _CommonService:CommonService,
              public _ProjectCostAnalysisService:ProjectCostAnalysisService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.setDate(this._ProjectCostAnalysisService.selectedPeriod??1);
    this.loadDealer();
    this.loadCustomer();
    this.loadBatch();
    this.loadModule();
  }

  setDate(id: Number) {
    this._CommonService.GetDateByPeriod(id).subscribe(
      data=>{
        this.dDates=data;
        this._ProjectCostAnalysisService.fromDate = new Date(this.dDates.FromDate);
        this._ProjectCostAnalysisService.toDate = new Date(this.dDates.ToDate);
      }
    );
  }

  periodChange(e: Event) {
    if (this._ProjectCostAnalysisService.selectedPeriod == 11)
      this.enabledates = false;
    else {
      this.enabledates = true;
      this.setDate(this._ProjectCostAnalysisService.selectedPeriod??1);
    }
  }

  loadDealer()
  {
    this._CommonService.getCommonList('ALLDEALER',this._ProjectCostAnalysisService.selectedDealer??0).subscribe(
      data=>{this._ProjectCostAnalysisService.allDealer=data
         this._ProjectCostAnalysisService.selectedDealer=data[0].Id},
      error=>{this.errorMsg=error}
    )
  }

  loadCustomer()
  {
    this._CommonService.getCommonList('DEALERWISECUSTOMER',this._ProjectCostAnalysisService.selectedDealer??0).subscribe(
      data=>{this._ProjectCostAnalysisService.allCustomer=data
        this._ProjectCostAnalysisService.selectedCustomer=data[0].Id},
      error=>{this.errorMsg=error}
    )
  }

  loadBatch()
  {
    this._CommonService.getCommonList('BATCHWITHALLOPTION',this._ProjectCostAnalysisService.selectedCustomer??0).subscribe(
      data=>{this._ProjectCostAnalysisService.allBatch=data
        this._ProjectCostAnalysisService.selectedBatch=data[0].Id
        this.searchClear();
      },
      error=>{this.errorMsg=error}
    )
  }

  loadModule()
  {
    this._CommonService.getCommonList('ALLMODULE').subscribe(
      data=>{this._ProjectCostAnalysisService.allModule=data
        this._ProjectCostAnalysisService.selectedModule=data[0].Id
        this.searchClear();
      },
      error=>{this.errorMsg=error}
    )
  }

  moduleChange()
  {
    if(this._ProjectCostAnalysisService.selectedModule > 0)
      this._ProjectCostAnalysisService.selectedBatch=0;
  }

  searchClear()
  {
    this._ProjectCostAnalysisService.projectCostAnalysis=[];
  }

  viewProjectCostAnalysis()
  {
    if(this._ProjectCostAnalysisService.selectedDealer ==undefined || this._ProjectCostAnalysisService.selectedCustomer ==undefined || this._ProjectCostAnalysisService.selectedBatch ==undefined || this._ProjectCostAnalysisService.selectedModule ==undefined)
    {
      this.toastr.info('Please fill the mandatory fields', 'Project Cost Analysis') 
      return;
    }
    this._CommonService.isLoading=true;
    this._ProjectCostAnalysisService.proejctCostAnalysis('PROJECTCOST',this._ProjectCostAnalysisService.selectedDealer,this._ProjectCostAnalysisService.selectedCustomer,this._ProjectCostAnalysisService.selectedBatch??0,this._ProjectCostAnalysisService.selectedModule??0).subscribe(
      data=>{this._ProjectCostAnalysisService.projectCostAnalysis=data
        this._CommonService.isLoading=false;
      },
      error=>{this.errorMsg=error
        this._CommonService.isLoading=false;}
    )
  }

  projectCostAnalysisDetails(dealerId:number=0,customerId:number=0,batchId:number=0)
  {
    if(dealerId >0 && customerId > 0)
    {
      this._ProjectCostAnalysisService.proejctCostAnalysis('PROJECTCOSTDETAILS',dealerId,customerId,batchId,this._ProjectCostAnalysisService.selectedModule??0).subscribe(
        data=>{this._ProjectCostAnalysisService.projectCostAnalysisDtls=data
          this.viewProejctCostDetails=true;
        },
        error=>{this.errorMsg=error}
      )} 
  }

  closePopUp()
  {
    this.viewProejctCostDetails=false;
  }

}
