import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { customer } from 'src/app/Models/customer.model';
import { BranchService } from 'src/app/Services/branch.service';
import { ToastrService } from 'ngx-toastr';
import { CloudChargeService } from 'src/app/Services/cloud-charge.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { CloudCharge,CloudDueList } from 'src/app/Models/CloudCharge';
import { DealerService } from 'src/app/Services/dealer.service';

@Component({
  selector: 'app-cloudcharge',
  templateUrl: './cloudcharge.component.html',
  styleUrls: ['./cloudcharge.component.css']
})
export class CloudchargeComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean  //API call multiple
  FormValidation: boolean  //Used form form validation
  popUpIndex: number = 0;

  constructor(public _brnchService: BranchService,
              private toastr: ToastrService,
              public _custService: CustomerService,
              public _CloudChargeService: CloudChargeService,
              public _dealrService: DealerService,
              public _CommonService: CommonService) { }

  ngOnInit(): void {
    this.resetForm();
  }

  async GetVNo() {
    this._CloudChargeService.selectedCloudCharge.VNo = parseInt(await this._CommonService.getVNo('CLOUDCHARGE'));
  }

  closePopUp() {
    this.popUpIndex = 0;
    this._CommonService.remainderDays=0;
    this._CommonService.chkSelectAll=false;
  }

  resetForm(form?: NgForm) {
    if (form != null)
    this.isSubmit = false
    this.FormValidation = false
    this._CloudChargeService.selectedCloudCharge = {
      Id: 0,
      SoftwareRegId: 0,
      VNo: null,
      DealerId:null,
      CustomerId: null,
      Date: new Date(),
      FreeFor:0,
      MonthlyRent: 0,
      TotalRent:0,
      AffectedFrom: null,
      Duration: 0,
      Expiry: null,
      Remark: '',
    }
    this.LoadDealer();
    this.loadDealerWiseCustomers();
    this.GetVNo();
    this._CommonService.dueEnabled=true;
    this._CommonService.remainderDays=0;
  }

  LoadDealer() {
    this._dealrService.FetchDealer('ALL')
      .subscribe(data => {
        this._dealrService.allDealers = data
      })
  }

  loadDealerWiseCustomers() {
  this._CloudChargeService.selectedCloudCharge.CustomerId=null;
  this._custService.FetchAllCustomer('ALL')
    .subscribe(data => { 
    if(this._CloudChargeService.selectedCloudCharge.DealerId > 0)
    {
      this._custService.allCustomers  = data.filter(e=>
        e.DealerId==this._CloudChargeService.selectedCloudCharge.DealerId
      )
    } 
    else
      this._custService.allCustomers=data   
    },
      error => {
        this.errorMsg = error;
      });
  }

  displayPendingCloudCharge() {
    this._CloudChargeService.allDues=[];
    this._CommonService.isLoading = true;
    this._CloudChargeService.getPendingCloudCharge(this._CommonService.remainderDays,this._CloudChargeService.selectedCloudCharge.DealerId > 0?this._CloudChargeService.selectedCloudCharge.DealerId:0,this._CloudChargeService.selectedCloudCharge.CustomerId > 0?this._CloudChargeService.selectedCloudCharge.CustomerId:0).subscribe(obj => {
    if (obj.length != 0) {
      this._CloudChargeService.allDues = obj;
      this._CommonService.isLoading = false;
      this.popUpIndex=1;
    }
    else {
      this._CommonService.isLoading = false;
      this.toastr.warning('There is No Pending Cloud Amount', 'Cloud Charge')
    }
    });
    this._CommonService.isLoading = false;
  }

  SelectedIndex: number = null;
  oldexpiry:any= null;
  getSelectedDue(dues: CloudDueList, i) {
    this.SelectedIndex = i,
    this._CloudChargeService.selectedCloudCharge.SoftwareRegId = dues.SoftwareRegId;
    this._CloudChargeService.selectedCloudCharge.DealerId = dues.DealerId;
    this._CloudChargeService.selectedCloudCharge.CustomerId = dues.CustomerId;
    this._CloudChargeService.selectedCloudCharge.TotalRent = dues.CloudRent;
    this._CloudChargeService.selectedCloudCharge.Expiry =new Date(dues.Expiry);
    this._CloudChargeService.selectedCloudCharge.Duration =dues.PendingMonth;
    var date = new Date(dues.Expiry);
    // add a day
    date.setDate(date.getDate() + 1);
    this._CloudChargeService.selectedCloudCharge.AffectedFrom =date;
    this.popUpIndex=0;
    this._CloudChargeService.selectedCloudCharge.MonthlyRent=dues.PendingMonth > 0?(dues.CloudRent / dues.PendingMonth):dues.CloudRent
    this.expiryDateCalculate();
  }

  searchCloudCharge() {
    if (this._CloudChargeService.selectedCloudCharge.VNo != null)
      this._CloudChargeService.getCloudCharge(this._CloudChargeService.selectedCloudCharge.VNo).subscribe(data => {
        this._CloudChargeService.selectedCloudCharge=data;
        this._CommonService.dueEnabled=false;
        if(this._CloudChargeService.selectedCloudCharge.Id == 0)
        {
          this.resetForm();
          this.toastr.warning('Vno Does Not Exist', 'Cloud Charge')
        }
      })
  }

  formValidation(): boolean {
    if (this._CloudChargeService.selectedCloudCharge.VNo != 0 && this._CloudChargeService.selectedCloudCharge.Date != null && this._CloudChargeService.selectedCloudCharge.DealerId != 0
      && this._CloudChargeService.selectedCloudCharge.CustomerId != 0  && this._CloudChargeService.selectedCloudCharge.AffectedFrom != null
      && this._CloudChargeService.selectedCloudCharge.Expiry != null && (this._CloudChargeService.selectedCloudCharge.Duration !=null || this._CloudChargeService.selectedCloudCharge.FreeFor !=null))
      return true;
    else
      return false;
  }

  onSubmit(frm: NgForm) {
    this.FormValidation = true
    if (this.formValidation()) {
      this.isSubmit = true
      this._CloudChargeService.SaveItem(this._CloudChargeService.selectedCloudCharge).subscribe(
       data => {
         this.isSubmit = false
        if (data.Status == 0) {
          this.toastr.success(data.Message, "Cloud Charge")
          this.resetForm();
        }
        else 
          this.toastr.warning(data.Message, "Cloud Charge")
      });
  }
    else 
      this.toastr.warning('Please Fill All Mandatory Fields.', 'Cloud Charge');
  }

  deleteCloudCharge()
  {
    this._CloudChargeService.deleteCloudCharge().subscribe(data=>
      {
          if(data.Status==200)
            {
              this.toastr.success(data.Message, "Cloud Charge")
              this.resetForm();
            }
          else if(data.Status==400)
            this.toastr.warning(data.Message, "Cloud Charge")
          else
            this.toastr.error(data.Message, "Cloud Charge")
      })
  }

  expiryDateCalculate() {
    if (this._CloudChargeService.selectedCloudCharge.AffectedFrom != null && (this._CloudChargeService.selectedCloudCharge.Duration !=null || this._CloudChargeService.selectedCloudCharge.FreeFor !=null)) {
      let duration:number=0;
      duration=this._CloudChargeService.selectedCloudCharge.Duration > 0 ?this._CloudChargeService.selectedCloudCharge.Duration:this._CloudChargeService.selectedCloudCharge.FreeFor;
      let AffectedFrom: Date = this._CloudChargeService.selectedCloudCharge.AffectedFrom
      this._CloudChargeService.selectedCloudCharge.Expiry = this.addMonths(new Date(AffectedFrom),duration);
    }
    this.totalRent();
  }

  totalRent()
  {
      this._CloudChargeService.selectedCloudCharge.TotalRent=(this._CloudChargeService.selectedCloudCharge.MonthlyRent * this._CloudChargeService.selectedCloudCharge.Duration)
  }

  addMonths(date: Date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + parseInt(months));
    if (date.getDate() != d) 
      date.setDate(0);
    return date;
  }

  toggleFilter() {
    this._CloudChargeService.isFiltering = this._CloudChargeService.isFiltering ? false : true;
    if (!this._CloudChargeService.isFiltering) {
      this._CloudChargeService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._CloudChargeService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._CloudChargeService.orderByColName) 
      this._CloudChargeService.orderByDir = !this._CloudChargeService.orderByDir;
    this._CloudChargeService.orderByColName = colName;
  }
  NoOfItems: number = 10;

}
