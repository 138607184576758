<div class="container-fluid">
    <h2 class="heading_all">PROJECT COST ANALYSIS</h2>
    <br>
    <form #ProjectCostAnalysis="ngForm">
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-2">
                <label><span class="validation-error">*</span>Period</label>
                <ng-select name="Period" id="Period" bindLabel="Name" bindValue="ID" placeholder="Select"  
                    [items]="_CommonService.periodList" #Period="ngModel" [(ngModel)]="this._ProjectCostAnalysisService.selectedPeriod" (ngModelChange)="periodChange($event)">
                </ng-select>             
            </div>
            <div class="col-md-2">
                <label>From<span class="validation-error">*</span></label>
                <input class="form-control" name="From"  #From="ngModel" [(ngModel)]="this._ProjectCostAnalysisService.fromDate"
                    autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
            </div>
            <div class="col-md-2">
                <label>To<span class="validation-error">*</span></label>
                <input class="form-control" name="To" #To="ngModel" [(ngModel)]="this._ProjectCostAnalysisService.toDate"
                    autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
            </div>         
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label><span class="validation-error">*</span>Dealer</label>
                    <ng-select name="Dealer" id="Dealer" bindLabel="Name" bindValue="Id" placeholder="Select" [items]="_ProjectCostAnalysisService.allDealer"  
                        #Dealer="ngModel" [(ngModel)]="this._ProjectCostAnalysisService.selectedDealer" (ngModelChange)="this.loadCustomer()">
                    </ng-select>
                </div>      
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label><span class="validation-error">*</span>Customer</label>
                    <ng-select name="Customer" id="Customer" bindLabel="Name" bindValue="Id" placeholder="Select" [items]="_ProjectCostAnalysisService.allCustomer" 
                        #Customer="ngModel" [(ngModel)]="this._ProjectCostAnalysisService.selectedCustomer" (ngModelChange)="this.loadBatch()">
                    </ng-select>   
                </div>      
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label><span class="validation-error">*</span>Batch</label>
                    <ng-select name="Batch" id="Batch" bindLabel="Name" bindValue="Id" placeholder="Select" [items]="_ProjectCostAnalysisService.allBatch"
                         #Batch="ngModel" [(ngModel)]="this._ProjectCostAnalysisService.selectedBatch" >
                    </ng-select>   
                </div>        
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label><span class="validation-error">*</span>Module</label>
                    <ng-select name="Module" id="Module" bindLabel="Name" bindValue="Id" placeholder="Select" [items]="_ProjectCostAnalysisService.allModule"
                        #Module="ngModel" [(ngModel)]="this._ProjectCostAnalysisService.selectedModule" (ngModelChange)="moduleChange()">
                    </ng-select>  
                </div>
            </div>
            <div class="col-md-2">
                <button type="button" name="Display" class="sim-button button1" style="width:75%; margin-top: 25px;" (click)="viewProjectCostAnalysis()">View</button> 
            </div>
        </div>
        <div *ngIf="this.viewProejctCostDetails" class="popup-container">
            <input type="checkbox" id="test-results" checked>
            <div style="z-index:99;" class="popup CostDetails" (click)="closePopUp()">
                <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                    <div class="title">
                        <p>Project Cost Details</p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i></label>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="label"><b>{{this._ProjectCostAnalysisService.projectCostAnalysisDtls[0].Filed1}}</b></label>
                            <label class="label"><b>{{this._ProjectCostAnalysisService.projectCostAnalysisDtls[0].Filed2}}</b></label>
                            <div class="tWrap table-responsive">
                                <table id="ProjectCostDetails">
                                    <thead>
                                        <tr>
                                            <th>Employee</th>
                                            <th>Hrs</th>
                                            <th>Work Type</th>
                                            <th>Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let obj of this._ProjectCostAnalysisService.projectCostAnalysisDtls;let last=last;let first=first"
                                                [ngClass]="{'last-row-without-border':last}">
                                            <td *ngIf="!first" [ngClass]="{'last-row-without-border':last}">{{obj.Filed1}}</td>
                                            <td *ngIf="!first" [ngClass]="{'last-row-without-border':last}">{{obj.Filed2}}</td>
                                            <td *ngIf="!first" >{{obj.Filed3}}</td>
                                            <td *ngIf="!first" style="text-align:right;">{{obj.Filed4}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
            <div class="tWrap">
                <table id="ProjectCost">
                    <thead>
                        <tr>
                            <th>Dealer</th>
                            <th>Customer</th>
                            <th>Batch</th>
                            <th>Status</th>
                            <th>Cost</th>
                            <th>Income</th>
                            <th>Variation</th>
                            <th>OD</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let obj of this._ProjectCostAnalysisService.projectCostAnalysis;let last=last" 
                            [ngClass]="{'last-row-without-border':last}" (dblclick)="this.projectCostAnalysisDetails(obj.DealerId,obj.CustomerId,obj.BatchId)">
                            <td [ngClass]="{'last-row-without-border':last}" title="{{obj.Dealer}}">{{obj.Dealer}}</td>
                            <td [ngClass]="{'last-row-without-border':last}" title="{{obj.Customer}}">{{obj.Customer}}</td>
                            <td [ngClass]="{'last-row-without-border':last}">{{obj.BatchName}}</td>
                            <td>{{obj.Status}}</td>
                            <td style="text-align:right;">{{obj.Cost}}</td>
                            <td style="text-align:right;">{{obj.Income}}</td>
                            <td style="text-align:right;" [ngClass]="{'last-row-without-border':last}">{{obj.Variation}}</td>
                            <td [ngClass]="{'last-row-without-border':last,'overdue':obj.OverDueBy > 0}">{{obj.OverDueBy}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>
