<div class="container-fluid">
    <h2 class="heading_all">System Details</h2><br>   
        <div class="row">
            <div class="form-group">
                <div class="col-md-4">
                    <label>Dealer<span class="validation-error">*</span></label>
                    <select name="Type" #Type="ngModel" class="drp" [(ngModel)]="this.reportsService.dealerFilter" (ngModelChange)="loadDealerWiseCustomer()">
                        <option [ngValue]="''">Select Dealer</option>
                        <option *ngFor="let dealer of this.dealerService.allDealers" value="{{dealer.Id}}">{{dealer.DealerName}}</option>
                    </select>                                     
                </div>  
            </div>
            <div class="form-group">
                <div class="col-md-4">
                    <label>Customer<span class="validation-error">*</span></label>
                    <ng-select name="CustomerId" id="CustomerId" class="drp" placeholder="Select Customer" bindLabel="Name" #CustomerId="ngModel" 
                      [(ngModel)]="this.reportsService.customerFilter" bindValue="Id" (ngModelChange)="sytemDetails('GETBRANCHWISEMODULELIST','0',0)"
                      [items]="this.customerService.allCustomers  | orderBy : 'Name'" required>
                    </ng-select> 
                </div>    
            </div>   
            <div class="col-md-2" style="margin-top:30px;">
                <input type="radio" name="All" [value]="1"  [(ngModel)]="this.reportsService.type" (change)="sytemDetails('GETBRANCHWISEMODULELIST','0',0)"><label>All</label>
                <input type="radio" name="Live" [value]="2" [(ngModel)]="this.reportsService.type" (change)="sytemDetails('GETBRANCHWISEMODULELIST','0',0)"><label>Live</label>
                <button type="button" class="btn btn-sm btn-success" (click)="sytemDetails('GETBRANCHWISEMODULELIST','0',0)">Refresh</button>
            </div>                
        </div>  
            <div class="row">
                <div class="col-md-12">
                    <table class="table tablepl" id="systemdetails">
                    <tbody>
                        <tr *ngFor="let brnch of this.reportsService.allBranchWiseModuleDtls.AllBranchName">
                           <td style="width:340px;">{{this.brnch.Branch}}</td>
                            <ng-container *ngFor="let sys of brnch.AllBranchWiseSystem">
                                <tr>
                                    <td></td>
                                    <td title="SystemName({{sys.SystemName}})" style="width:170px;">{{sys.SystemName}}</td>
                                    <td *ngIf="sys.Type==0">
                                        <button type="button" style="width:110px;" [ngClass]="{'btn btn btn-danger':sys.SystemStatus=='Block','btn btn-sm btn-success':sys.SystemStatus=='Live'}" (click)="sytemDetails('SYSTEMDELETE','0',sys.NodeRegId)">Delete</button>
                                    </td>
                                    <td *ngIf="sys.Type==1">
                                        <button type="button" style="width:110px;" [ngClass]="{'btn btn btn-danger':sys.SystemStatus=='Block','btn btn-sm btn-success':sys.SystemStatus=='Live'}" (click)="sytemDetails('TABDELETE',sys.SystemName,sys.NodeRegId)">Tab Delete</button>
                                    </td>
                                    <td *ngIf="sys.Type==0">
                                        <button type="button" style="width:110px;" [ngClass]="{'btn btn btn-danger':sys.SystemStatus=='Block','btn btn-sm btn-success':sys.SystemStatus=='Live'}" (click)="sytemDetails(sys.SystemStatus,'0',sys.NodeRegId)">{{sys.SystemStatus}}</button>
                                    </td>
                                    <td *ngIf="sys.Type==0">
                                        <span>{{sys.CurrentStatus}}</span>
                                    </td>
                                        <ng-container *ngFor="let data of sys.AllSystemWiseModule">
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td style="width:150px;" title="{{data.ModuleName}}"><span [ngClass]="{'badge badge-danger':data.IsHold,'badge badge-success':!data.IsHold}">{{data.ModuleName}}</span></td>
                                                <td title="Register Date({{data.RegDate}})" class="datetdwidth">{{data.RegDate}}</td>
                                                <td title="Last Renewal Date({{data.LastRenewDate}})" class="datetdwidth">{{data.LastRenewDate}}</td>
                                                <td title="Expiry Date({{data.ExpDate}})" class="datetdwidth">{{data.ExpDate}}</td>
                                                <td>
                                                    <button type="button" style="width:110px;" [ngClass]="{'btn btn btn-danger':data.IsHold,'btn btn-sm btn-success':!data.IsHold}" class="btn btn btn-danger" (click)="sytemDetails(data.ModuleStatus,data.ModuleCode,data.NodeRegId)">{{data.ModuleStatus}}</button>
                                                </td>
                                            </tr>                       
                                        </ng-container>
                                </tr> 
                            </ng-container>
                        </tr>                   
                        <tr *ngIf="this.reportsService.allBranchWiseModuleDtls.Status == 1">
                            <td colspan="3">
                             No Records To Display.</td>
                        </tr>           
                    </tbody>
                    </table>
                </div>            
            </div>
     </div>
