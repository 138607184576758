import { Injectable } from '@angular/core';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { NewLeadHdr, NewLeadDet } from '../Models/NewLead';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';

@Injectable({
  providedIn: 'root'
})
export class NewleadService {
  selectedNewLeads: NewLeadHdr;
  moduleList: number[] = [];
  allNewLeadDet: NewLeadDet[];
  FormUpdation: boolean = false
  RowCount: any;
  referedbycusVisible = 0;
  referedbyempVisible = 0
  reasonVisible = 0;
  giveEditPermission: boolean = false;


  public newAttribute: any = {};
  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }

  SaveItem(NewLead: NewLeadHdr, allNewLeadDet: NewLeadDet[]): Observable<any> {
    console.log('NewLead', NewLead)
    var body = {
      Id: NewLead.Id
      , DealerId: NewLead.DealerId
      , RefNo: NewLead.RefNo
      , Date: NewLead.Date
      , BpeId: NewLead.BpeId
      , CustomerId: NewLead.CustomerId
      , CustAddr1: NewLead.CustAddr1
      , CustAddr2: NewLead.CustAddr2
      , CustAddr3: NewLead.CustAddr3
      , ContactPerson: NewLead.ContactPerson
      , ContactNo: NewLead.ContactNo
      , Email: NewLead.Email
      , ProjectId: NewLead.ProjectId
      , ReferedBy: NewLead.ReferedBy
      , ReferedEmployee: NewLead.ReferedEmployee
      , NextAction: NewLead.NextAction
      , NextActionDate: NewLead.NextActionDate
      , Note: NewLead.Note
      , Status: NewLead.Status
      , Reason: NewLead.Reason
      , Remark: NewLead.Remark
      , NewLeadDet: allNewLeadDet
      , selectedModules: this.moduleList

    }

    let params = new HttpParams();
    params = params.append('TransMode', this.FormUpdation == false ? 'SAVE' : 'UPDATE')

    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + 'NewLead/PostNewLead', body, {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );

    // const headers = new HttpHeaders().set('content-type', 'application/json');
    // return this.http.post<any>(ROOT_URL + "NewLeadController/PostNewLead", body, {
    //   headers
    // })
    //   .pipe(

    //   );
  }
  getNewLead(): Observable<NewLeadHdr> {
    let params = new HttpParams();
    params = params.append('RefNo', this.selectedNewLeads.RefNo.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<NewLeadHdr[]>(ROOT_URL + 'NewLead/NewLead_Fetch', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getAllModuleByLeadId(Id: number) {
    return this.http.get<any>(ROOT_URL + 'NewLead/getAllModuleByLeadId?Id=' + Id + '')
  }

}
