import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'src/app/DashBoard/Admin/dashboard.component';
import { EmployeeComponent } from './Masters/employee/employee.component';
import { DesignationComponent } from './Masters/designation/designation.component';
import { ServiceHistoryComponent } from './Dealers/service-history/service-history.component';
import { CountryComponent } from './Masters/country/country.component';
import { DealerComponent } from './Masters/dealer/dealer.component';
import { ProjectComponent } from './Masters/project/project.component';
import { SparePartsComponent } from './Masters/spare-parts/spare-parts.component';
import { ServicesComponent } from './Masters/services/services.component';
import { ModuleComponent } from './Masters/module/module.component';
import { BussinessTypeComponent } from './Masters/bussiness-type/bussiness-type.component';
import { BusinessExecutiveComponent } from './Dealers/business-executive/business-executive.component';
import { ProjectAllocationComponent } from './Support-Engineer/project-allocation/project-allocation.component';
import { ProjectForecastingComponent } from './Support-Engineer/project-forecasting/project-forecasting.component';
import { TimeSettingsComponent } from './Masters/time-settings/time-settings.component';
import { NewLeadComponent } from './Masters/new-lead/new-lead.component';
import { ParticularMasterComponent } from './Masters/particular-master/particular-master.component';
import { ReasonMasterComponent } from './Masters/reason-master/reason-master.component';
import { HolidaySettingsComponent } from './Masters/holiday-settings/holiday-settings.component';
import { BacklogsComponent } from '../Provider/Reports/backlogs/backlogs.component';
import { OutstandingComponent } from './../Provider/Reports/Customer/outstanding/outstanding.component';
import { TodaysDeliveryComponent } from '../Provider/Reports/todays-delivery/todays-delivery.component';
import { CompanyRegistrationComponent } from './Masters/company-registration/company-registration.component';
import { AttandenceregisterComponent } from './attandenceregister/attandenceregister.component';
import { TimeSheetComponent } from './time-sheet/time-sheet.component';
import { ChargeTypeComponent } from './Masters/charge-type/charge-type.component';

const routes: Routes = [
  { path: '', component: DashboardComponent }, 
  { path: 'companyRegistration', component: CompanyRegistrationComponent }, 
  { path: 'employee', component: EmployeeComponent, },
  { path: 'designation', component: DesignationComponent },
  { path: 'servicehistory', component: ServiceHistoryComponent },
  { path: 'country', component: CountryComponent },
  { path: 'dealer', component: DealerComponent },
  { path: 'project', component: ProjectComponent },
  { path: 'spareparts', component: SparePartsComponent },
  { path: 'ChargeType', component: ChargeTypeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'module', component: ModuleComponent },
  { path: 'bussinesstype', component: BussinessTypeComponent },
  { path: 'businessexecutive', component: BusinessExecutiveComponent },
  { path: 'projectallocation', component: ProjectAllocationComponent },
  { path: 'workForecasting', component: ProjectForecastingComponent },
  { path: 'settings', component: TimeSettingsComponent },
  { path: 'newlead', component: NewLeadComponent },
  { path: 'particularmaster', component: ParticularMasterComponent },
  { path: 'reasonmaster', component: ReasonMasterComponent },
  { path: 'holydaysetting', component: HolidaySettingsComponent },
  { path: 'Backlogs', component: BacklogsComponent },
  { path: 'outstanding', component: OutstandingComponent },
  { path: 'TodaysDelivery', component: TodaysDeliveryComponent },
  { path: 'attandence', component: AttandenceregisterComponent },
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes),
  ]
})
export class AdminModule { }
