import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Meeting } from 'src/app/Models/Metting';
import { CommonService } from 'src/app/Services/common.service';
import { MeetingService } from 'src/app/Services/meeting.service';

@Component({
  selector: 'app-meeting-list',
  templateUrl: './meeting-list.component.html',
  styleUrls: ['./meeting-list.component.css']
})
export class MeetingListComponent implements OnInit {

  deleteID:number=0;deletePopUp:boolean=false;
  errorMsg:string='';
  NoOfItems: number = 10;
  term:string='';

  constructor(public _CommonService:CommonService,
              public _Meeting:MeetingService,
              public toastr:ToastrService) { }

  ngOnInit(): void {
    this.deleteID = 0;
    this.deletePopUp = false;
    this._Meeting.pageIndex = 1;
    this._Meeting.getallmeeting("ALL").subscribe(data => this._Meeting.allMeeting = data,
      error => {
        this.errorMsg = error;
      });
    this._Meeting.formHideShow=0;
  }

  toggleFilter() {
    this._Meeting.isFiltering = this._Meeting.isFiltering ? false : true;
    if (!this._Meeting.isFiltering) {
      this._Meeting.ClearFilter();
      this._Meeting.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._Meeting.orderByColName) {
      this._Meeting.orderByDir = !this._Meeting.orderByDir;
    }
    this._Meeting.orderByColName = colName;
  }
  getRegardingList()
  {
    let transType='';
    this._Meeting.regardingList=[];
    this._Meeting.selectedMeeting.RegardingId=undefined;
    transType=this._Meeting.selectedMeeting.RegardingTypeId==1?'BATCHLIST':this._Meeting.selectedMeeting.RegardingTypeId==2?'PROJECT':'OTHERWORKS';
    this._CommonService.getCommonList(transType).subscribe(data=>{
      this._Meeting.regardingList=data;
    },
    error => {
      this.errorMsg = error;
    })
  }
  addNew()
  {
    this._Meeting.formHideShow = 1;
  }
  OnEdit(selectedRow:Meeting)
  {
    this._Meeting.selectedMeeting = Object.assign({}, selectedRow);
    this._Meeting.getMeetingDetails('MEETINGDETAILS',selectedRow.ID).subscribe(
      data=>{
        this._Meeting.selectedMeeting.Employees=data;
      },error => {
        this.errorMsg = error;
        this.toastr.error("Something went wrong", "Meeting");
      })
    this.getRegardingList();
    this._Meeting.selectedMeeting.RegardingId=selectedRow.RegardingId;
    this._Meeting.formHideShow = 1;
  }
  onDelete(deleteID: number) {
    this.deleteID = deleteID;
    this.deletePopUp = true;
  }
  dialogResult(event)
  {
    this.deletePopUp = false;
    if (event) {
      this._Meeting.onDelete(this.deleteID)
        .subscribe(x => {
          if(x.Status)
          {
            this._Meeting.getallmeeting("ALL").subscribe(data => this._Meeting.allMeeting = data,
              error => {
                this.errorMsg = error;
              });
            this.toastr.success(x.Message, "Meeting");
          }
        }, error => {
          this.errorMsg = error;
          this.toastr.warning("Deletion Failure", "Meeting");
        })
    }
  }

}
