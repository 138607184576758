import { error } from 'protractor';
import { Component, OnInit } from '@angular/core';
import { BranchService } from 'src/app/Services/branch.service';
import { ToastrService } from 'ngx-toastr';
import { ServicechargeService } from 'src/app/Services/servicecharge.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { ServiceCharge, ServiceChargeList } from 'src/app/Models/ServiceCharge';
import { DealerService } from 'src/app/Services/dealer.service';
import $ from 'jquery';

@Component({
  selector: 'app-servicecharge',
  templateUrl: './servicecharge.component.html',
  styleUrls: ['./servicecharge.component.css']
})
export class ServicechargeComponent implements OnInit {
  public errorMsg;
  serviceChargeForm: boolean;
  WarningModel: boolean;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  constructor(public _brnchService: BranchService,
              private toastr: ToastrService,
              public _custService: CustomerService,
              public _dealrService: DealerService,
              public _ServicechargeService: ServicechargeService,
              public _CommonService: CommonService) { }

  ngOnInit(): void {
    this.resetForm();
  }

  async GetVNo() {
    this._ServicechargeService.selectedServiceCharge.VNo = parseInt(await this._CommonService.getVNo('SERVICECHARGE'));
  }

  closePopUp() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");
    this.WarningModel = false
    this.serviceChargeForm = false
    this._CommonService.chkSelectAll=false;
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    this._CommonService.chkSelectAll=false;
    if (form != null)
      form.reset();
    this._ServicechargeService.selectedServiceCharge = {
      Id: 0,
      VNo: 0,
      Date: new Date(),
      DealerId:null,
      CustomerId:null,
      BranchId: null,
      DemandedServiceCharge: null,
      AgainstRefno:'',
      VType: 0,
      ApprovedServiceCharge: null,
      Remark: '',
    }
    this.loadDealer();
    this.loadDealerWiseCustomers();
    this.loadCustomerWiseBranch()
    this._ServicechargeService.listVisible = 0
    this.GetVNo();
    this._ServicechargeService.selectedWorkStatus=[];
  }

  loadDealer() {
    this._dealrService.FetchDealer('ALL')
      .subscribe(data => {
        this._dealrService.allDealers = data
      })
  }

  loadDealerWiseCustomers() {
    this._ServicechargeService.selectedServiceCharge.BranchId=null;
    this._ServicechargeService.selectedServiceCharge.CustomerId=null;
    this._custService.FetchAllCustomer('ALL')
      .subscribe(data => { 
      if(this._ServicechargeService.selectedServiceCharge.DealerId > 0)
      {
        this._custService.allCustomers  = data.filter(e=>
          e.DealerId==this._ServicechargeService.selectedServiceCharge.DealerId
        )
      } 
      else
        this._custService.allCustomers=data   
      },
        error => {
          this.errorMsg = error;
        });
    }

  loadCustomerWiseBranch() {
    this._ServicechargeService.selectedServiceCharge.BranchId=null;
    let customerID = this._ServicechargeService.selectedServiceCharge.CustomerId == null ? 0 : this._ServicechargeService.selectedServiceCharge.CustomerId
    this._brnchService.Fetch_BranchById(customerID, 'CUSTOMERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
        if(data.length==1)
          this._ServicechargeService.selectedServiceCharge.BranchId=data[0].Id;
      },
        error => {
          this.errorMsg = error;
        });
  }

  getServiceChargeData() {
    this._ServicechargeService.listVisible = 1
    this._CommonService.isLoading = true;
    this._ServicechargeService.getServiceChargeData(this._ServicechargeService.selectedServiceCharge.DealerId > 0?this._ServicechargeService.selectedServiceCharge.DealerId:0,this._ServicechargeService.selectedServiceCharge.CustomerId > 0?this._ServicechargeService.selectedServiceCharge.CustomerId:0).subscribe(obj => {
      if (obj.length != 0) {
        this._ServicechargeService.allServiceCharge = obj;     
        $('#myModalConfirm').css("display", "block")
        this.serviceChargeForm = true
        this.WarningModel = false
        this._CommonService.isLoading = false;
        this._ServicechargeService.selectedServiceCharge.DemandedServiceCharge=0;
        this._ServicechargeService.selectedServiceCharge.AgainstRefno='';
        this._ServicechargeService.selectedWorkStatus=[];
      }
      else {
        this._CommonService.isLoading = false;
        this.toastr.warning('There is No Data Found', 'Service Charge')
      }
    });
    this._CommonService.isLoading = false;
  }

  selectAllServiceCharge()
  {
    let sum:number=0;
    this._ServicechargeService.selectedWorkStatus=[];
    this._ServicechargeService.allServiceCharge.forEach(obj=>{
      obj.CheckStatus=this._CommonService.chkSelectAll
    })
    if(this._CommonService.chkSelectAll)
    {
      sum = this._ServicechargeService.allServiceCharge.map(a => a.Amount).reduce(function(a, b)
      { return a+b;});
    }
    else
      this._ServicechargeService.selectedServiceCharge.DemandedServiceCharge=0;
    this._ServicechargeService.selectedServiceCharge.DemandedServiceCharge=sum;
  }

  selectedSerivceCharge(obj:ServiceChargeList)
  {
    if(obj.CheckStatus)
      this._ServicechargeService.selectedServiceCharge.DemandedServiceCharge+=Number(obj.Amount);
    else
      this._ServicechargeService.selectedServiceCharge.DemandedServiceCharge=this._ServicechargeService.selectedServiceCharge.DemandedServiceCharge-obj.Amount;
    
    let Count:number=0;
    Count=this._ServicechargeService.allServiceCharge.filter(count=>count.CheckStatus==true).length;
    if(Count > 0)
    {
      this._ServicechargeService.allServiceCharge.forEach(data=>
        {
          if(obj.WorkSortingHdrID !=data.WorkSortingHdrID)
          {
            if(data.CustomerId!=obj.CustomerId || data.DealerId!=obj.DealerId || data.BranchId!=obj.BranchId)
              data.CheckboxPrivilege=true;
          }   
        })
    }
    else
      this.getServiceChargeData();
  }

  loadSelectedServiceCharge()
  {
    this._ServicechargeService.selectedServiceCharge.AgainstRefno='';
    this._ServicechargeService.selectedWorkStatus=[];
    this._ServicechargeService.allServiceCharge.forEach(obj=>
      {
        if(obj.CheckStatus)
        {
          this._ServicechargeService.selectedServiceCharge.DealerId=obj.DealerId;
          this._ServicechargeService.selectedServiceCharge.CustomerId = obj.CustomerId;
          this._ServicechargeService.selectedServiceCharge.BranchId = obj.BranchId;
          this._ServicechargeService.selectedServiceCharge.VType = obj.VType;
          this._ServicechargeService.selectedServiceCharge.AgainstRefno=this._ServicechargeService.selectedServiceCharge.AgainstRefno + (obj.RefNo.toString()) +','
          let selectedWorkStatus=obj.WorkSortingHdrID.toString();
          this._ServicechargeService.selectedWorkStatus.push(selectedWorkStatus);
        }
      })
    this._ServicechargeService.selectedServiceCharge.AgainstRefno=this._ServicechargeService.selectedServiceCharge.AgainstRefno.slice(0,-1)
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");
    this.WarningModel = false
    this.serviceChargeForm = false
    this._CommonService.chkSelectAll=false;
  }

  searchServiceCharge() {
    if (this._ServicechargeService.selectedServiceCharge.VNo != null)
      this._ServicechargeService.getServiceCharge(this._ServicechargeService.selectedServiceCharge.VNo).subscribe(obj => {
        this._ServicechargeService.selectedServiceCharge = obj
        if(this._ServicechargeService.selectedServiceCharge.Id == 0) {
          this.resetForm();
          this.toastr.warning('Vno Does Not Exist', 'Service Charge')
        }      
      })
  }

  formValidation(): boolean {
    if (this._ServicechargeService.selectedServiceCharge.VNo != 0 && this._ServicechargeService.selectedServiceCharge.Date != null && this._ServicechargeService.selectedServiceCharge.DealerId != 0
      && this._ServicechargeService.selectedServiceCharge.CustomerId != 0 && this._ServicechargeService.selectedServiceCharge.BranchId != 0
      && this._ServicechargeService.selectedServiceCharge.ApprovedServiceCharge != null 
      && this._ServicechargeService.selectedServiceCharge.DemandedServiceCharge > 0 && this._ServicechargeService.selectedServiceCharge.Remark != "")
      return true;
    else
      return false;
  }

  onSubmit(frm: NgForm) {
    this.FormValidation = true
    if (frm.valid && this.formValidation()) {
      this.isSubmit = true
      if (frm.valid) {
        this._ServicechargeService.SaveItem(this._ServicechargeService.selectedServiceCharge).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 200) {
              this.toastr.success(data.Message, "Service Charge")
              this.resetForm();
            }
            else 
              this.toastr.warning(data.Message, "Service Charge")
          }
        );
      }
    }
    else 
      this.toastr.warning('Please Fill All Mandatory Fields.', 'Service Charge');
  }

  deleteServiceCharge()
  {
    this._ServicechargeService.deleteServiceCharge().subscribe(
      data=>
      {
        if (data.Status == 200) {
          this.toastr.success(data.Message, "Service Charge")
          this.resetForm();
        }
        else
          this.toastr.warning(data.Message, "Service Charge")
      },
      error=>{
        this.errorMsg=error;
        this.toastr.error(this.errorMsg, "Service Charge")
      })
  }

  toggleFilter() {
    this._ServicechargeService.isFiltering = this._ServicechargeService.isFiltering ? false : true;
    if (!this._ServicechargeService.isFiltering) {
      this._ServicechargeService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._ServicechargeService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._ServicechargeService.orderByColName) {
      this._ServicechargeService.orderByDir = !this._ServicechargeService.orderByDir;
    }
    this._ServicechargeService.orderByColName = colName;
  }
  NoOfItems: number = 10;

}
