<div *ngIf="_BranchsettingsService.formHideShow!=0" class="container-fluid">
    <h2 class="heading_all">BRANCH SETTINGS</h2>
    <div id="form-wrap">
        <form action="#" #BranchSettingsForm="ngForm" (ngSubmit)="onSubmit(BranchSettingsForm)">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label style="padding-top: 13px;">Dealer</label>
                        <select disabled class="form-control" name="DealerId" #DealerId="ngModel"
                            (change)="LoadCustomers()"
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.DealerId" required>
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let prf of _dealrService.allDealers" [ngValue]="prf.Id">
                                {{ prf.DealerName }}
                            </option>
                        </select>
                        <div class="validation-error"
                            *ngIf="(DealerId.invalid && DealerId.touched)||(DealerId.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 11px;">Customer<span class="validation-error">*</span></label>
                        <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel" bindLabel="Name"
                            bindValue="Id" [items]="_custService.allCustomers  | orderBy : 'Name'" required
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.CustomerId"
                            (change)="fillbranch()">
                        </ng-select>
                        <div class="validation-error"
                            *ngIf="(CustomerId.invalid && CustomerId.touched)||(CustomerId.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label for="select">Branch<span class="validation-error">*</span></label>
                        <ng-select name="BranchId" id="BranchId" #BranchId="ngModel" bindLabel="BranchName"
                            bindValue="Id" [items]="_brnchService.allBranchs | orderBy : 'BranchName'" required
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.BranchId">
                        </ng-select>
                        <div class="validation-error"
                            *ngIf="(BranchId.invalid && BranchId.touched)||(BranchId.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label>License Key Validation Days<span class="validation-error">*</span></label>
                        <input class="form-control" name="LicenseKeyValDays" #LicenseKeyValDays="ngModel"
                            autocomplete="off"
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.LicenseKeyValDays"
                            ng-pattern="/^[0-9]*$/" (keypress)="keyPress($event)"
                            placeholder=" License Key Validation Days" required>
                        <div class="validation-error"
                            *ngIf="(LicenseKeyValDays.invalid && LicenseKeyValDays.touched)||(LicenseKeyValDays.invalid && this.FormValidation)">
                            This Field is  Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 13px;">The remainder of Expiry<span class="validation-error">*</span></label>
                        <input class="form-control" name="RemainderExp" #RemainderExp="ngModel" autocomplete="off"
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.RemainderExp"
                            ng-pattern="/^[0-9]*$/" (keypress)="keyPress($event)" placeholder=" The remainder of Expiry"
                            required>
                        <div class="validation-error"
                            *ngIf="(RemainderExp.invalid && RemainderExp.touched)||(RemainderExp.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 11px;">The yearly reminder of Expiry<span class="validation-error">*</span></label>
                        <input class="form-control" name="YearlyReminderExp" #YearlyReminderExp="ngModel"
                            autocomplete="off"
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.YearlyReminderExp"
                            ng-pattern="/^[0-9]*$/" (keypress)="keyPress($event)"
                            placeholder=" The yearly reminder of Expiry" required>
                        <div class="validation-error"
                            *ngIf="(YearlyReminderExp.invalid && YearlyReminderExp.touched)||(YearlyReminderExp.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label style="padding-top: 15px;">The yearly reminder of Expiry frequency<span class="validation-error">*</span></label>
                        <input class="form-control" name="YearlyReminderExpfreq" #YearlyReminderExpfreq="ngModel"
                            ng-pattern="/^[0-9]*$/" (keypress)="keyPress($event)" autocomplete="off"
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.YearlyReminderExpfreq"
                            placeholder=" The yearly reminder of Expiry frequency" required>
                        <div class="validation-error"
                            *ngIf="(YearlyReminderExpfreq.invalid && YearlyReminderExpfreq.touched)||(YearlyReminderExpfreq.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <label style="padding-top: 15px;">System Replacement<span class="validation-error">*</span></label>
                        <input class="form-control" name="SystemReplacement" #SystemReplacement="ngModel"
                            autocomplete="off"
                            [(ngModel)]="_BranchsettingsService.selectedBranchSettings.SystemReplacement"
                            placeholder=" System Replacement" required>
                        <div class="validation-error"
                            *ngIf="(SystemReplacement.invalid && SystemReplacement.touched)||(SystemReplacement.invalid && this.FormValidation)">
                            This Field is Required.</div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Block License Key Issue<span class="validation-error">*</span></label>
                            </div>
                            <div class="col-md-4">
                                <input type="radio" [value]="true" name="BlockLicenseKeyIssue1"
                                    id="BlockLicenseKeyIssue1" #BlockLicenseKeyIssue1="ngModel"
                                    [(ngModel)]="_BranchsettingsService.selectedBranchSettings.BlockLicenseKeyIssue">
                                <label>Yes &nbsp;&nbsp;
                                    &nbsp;&nbsp; &nbsp;&nbsp;</label>
                                <input type="radio" [value]="false" name="BlockLicenseKeyIssue2"
                                    id="BlockLicenseKeyIssue2" #BlockLicenseKeyIssue2="ngModel"
                                    [(ngModel)]="_BranchsettingsService.selectedBranchSettings.BlockLicenseKeyIssue">
                                <label>No &nbsp;&nbsp;
                                    &nbsp;&nbsp; &nbsp;&nbsp;</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Version Change<span class="validation-error">*</span></label>
                            </div>
                            <div class="col-md-4">
                                <input type="radio" [value]="true" name="VersionChange1" id="VersionChange1"
                                    #VersionChange1="ngModel"
                                    [(ngModel)]="_BranchsettingsService.selectedBranchSettings.VersionChange">
                                <label>Yes &nbsp;&nbsp;
                                    &nbsp;&nbsp; &nbsp;&nbsp;</label>
                                <input type="radio" [value]="false" name="VersionChange2" id="VersionChange2"
                                    #VersionChange2="ngModel"
                                    [(ngModel)]="_BranchsettingsService.selectedBranchSettings.VersionChange">
                                <label>No &nbsp;&nbsp;
                                    &nbsp;&nbsp; &nbsp;&nbsp;</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Customer Profile Updation<span class="validation-error">*</span></label>
                            </div>
                            <div class="col-md-4">
                                <input type="radio" [value]="true" name="CustomerProfileUpdation1"
                                    id="CustomerProfileUpdation1" #CustomerProfileUpdation1="ngModel"
                                    [(ngModel)]="_BranchsettingsService.selectedBranchSettings.CustomerProfileUpdation">
                                <label>Yes &nbsp;&nbsp;
                                    &nbsp;&nbsp; &nbsp;&nbsp;</label>
                                <input type="radio" [value]="false" name="CustomerProfileUpdation2"
                                    id="CustomerProfileUpdation2" #CustomerProfileUpdation2="ngModel"
                                    [(ngModel)]="_BranchsettingsService.selectedBranchSettings.CustomerProfileUpdation">
                                <label>No &nbsp;&nbsp;
                                    &nbsp;&nbsp; &nbsp;&nbsp;</label>
                            </div>
                        </div>
                    </div>               
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                        class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                        (click)="resetForm(BranchSettingsForm)">Reset</button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                        (click)="backList()">Exit</button>
                </div>
            </div>
        </form>
    </div>
</div>