<header class="header">
    <nav class="navbar">
        <span class="open-menu">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16">
                <g fill="#252a32" fill-rule="evenodd">
                    <path d="M0 0h24v2H0zM0 7h24v2H0zM0 14h24v2H0z" />
                </g>
            </svg>
        </span>
        <h1 style="padding-left: 30%;">
            <!-- <a (click)="closeSideBar()"href="./index.html" class="brand">PROJECT MANAGEMENT SYSTEM</a> -->
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Admin'])" routerLink="/admin"
                class="brand">PROJECT MANAGEMENT SYSTEM</a>
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Support Engineer'])" routerLink="/supportEng"
                class="brand">PROJECT MANAGEMENT SYSTEM</a>
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Project Leader'])" routerLink="/projectleader"
                class="brand">PROJECT MANAGEMENT SYSTEM</a>
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Dealer'])" routerLink="/dealerdashboard"
                class="brand">PROJECT MANAGEMENT SYSTEM</a>
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Support Leader'])" routerLink="/supportleader"
                class="brand">PROJECT MANAGEMENT SYSTEM</a>
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Project Manager'])" routerLink="/projectmanager"
                class="brand">PROJECT MANAGEMENT SYSTEM</a>
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Developers & Designers'])"
                routerLink="/developer" class="brand">PROJECT MANAGEMENT SYSTEM</a>
            <a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Testers'])" routerLink="/tester"
                class="brand">PROJECT MANAGEMENT SYSTEM</a>
        </h1>

        <div class="User-area">
            <div class="User-avtar">
                <img src="../../../assets/Images/Employee.png" />
            </div>
            <ul class="User-Dropdown">
                <li><a (click)="closeSideBar()">My Profile</a></li>
                <li *ngIf="this._CommonService.nodificationcount > 0" ><a routerLink="/leave">Notifications</a><span>{{this._CommonService.nodificationcount}}</span></li>
                <li><a (click)="closeSideBar()">Settings</a></li>
                <li><a (click)="closeSideBar()" (click)="Logout()">Logout</a></li>
            </ul>
        </div>
        
        <div class="menu-wrapper">
            <ul class="menu">
                <li class="menu-block">
                    <span class="close-menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                            <path fill="#ffffff" fill-rule="evenodd"
                                d="M17.778.808l1.414 1.414L11.414 10l7.778 7.778-1.414 1.414L10 11.414l-7.778 7.778-1.414-1.414L8.586 10 .808 2.222 2.222.808 10 8.586 17.778.808z" />
                        </svg>
                    </span>
                </li>
                <!--------------------------------DASH BOAD LIST-------------------------------------------------------------------------->
                <!-- <li class="menu-item"><a (click)="closeSideBar()"routerLink="/projectallotment" class="menu-link">Datepicker</a></li> -->

                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Admin'])"
                        routerLink="/admin" class="menu-link">Dashboard</a></li>
                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Support Engineer'])"
                        routerLink="/supportEng" class="menu-link">Dashboard</a></li>
                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Project Leader'])"
                        routerLink="/projectleader" class="menu-link">Dashboard</a></li>
                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Dealer'])"
                        routerLink="/dealerdashboard" class="menu-link">Dashboard</a></li>
                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Support Leader'])"
                        routerLink="/supportleader" class="menu-link">Dashboard</a></li>
                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Project Manager'])"
                        routerLink="/projectmanager" class="menu-link">Dashboard</a></li>

                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Developers & Designers'])" routerLink="/developer"
                        class="menu-link">Dashboard</a></li>
                <li class="menu-item"><a (click)="closeSideBar()" *ngIf="userService.roleMatch(['Testers'])"
                        routerLink="/tester" class="menu-link">Dashboard</a></li>
                <!--------------------------------DASH BOAD LIST -------------------------------------------------------------------------->
                <!--------------------------------Lazy loading begin-------------------------------------------------------------------------->
                <!-- <h3>Lazy loading Begin</h3> -->
                <li class="menu-item has-collapsible">
                    <a><span></span>Master</a>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Project Manager','Project Leader'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/bussinesstype">Business Type</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/particularmaster">Particular Master</a>
                        </li>  
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/chargeType/3">Charge Type</a>
                        </li>  
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/reasonmaster">Reason Master</a>
                        </li>                     
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Admin'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/companyRegistration">Company Registration</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/designation">Designation</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/employee">Employee</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/country">Country</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/dealer">Dealer</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/project">Project</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/module">Module</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/bussinesstype">Business Type</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/settings">Settings</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/newlead">New Lead</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/reasonmaster">Reason Master</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/particularmaster">Particular Master</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/serverprovider">Server Provider Master</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/server">Server Master</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/domain">Domain Master</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/holydaysetting">Holyday Settings</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Dealer'])">
                        <!-- Deaer -->
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/routemaster"> Route Master </a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/customer">Customer</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/branch">Branch</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/branchsettings">Branch Settings</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/employee">Employee</a>
                        </li>
                    </ul>
                </li>
                <li class="menu-item has-collapsible">
                    <a><span></span>Activity</a>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Project Manager'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/newlead">New Lead</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/customerrequirement">Customer Requirements</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/workstatus">Work Status</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/prowork">Project Work Allotment</a>
                        </li>                    
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/servicereportentry">Service Report Entry</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/jobcard">Job Card</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/jobscheduling">Job Scheduling</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/leave">Leave Application</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/cscancellation">CS Cancellation</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/meeting">Meeting</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top"
                        *ngIf="userService.roleMatch(['Developers & Designers'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/developer/customerrequirement">Customer Requirements</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/developer/servicereportentry">Service Report Entry</a>
                        </li>
                        <li class="menu-child-item ">
                            <a (click)="closeSideBar()" class="test" routerLink="/developer/jobcard"> Job Card</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Testers'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/tester/customerrequirement">Customer Requirements</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/tester/servicereportentry">Service Report Entry</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/tester/jobcard"> Job Card</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/tester/workdelivery">Work Delivery</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Dealer'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/amcrenewal">AMC Renewal</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/newlead">New Lead</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/softwareregistration">Software Registration</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/dealer/noderegistration"> Node Details</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Support Engineer'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/supportEng/customerrequirement">Customer Requirements</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/supportEng/servicereportentry">Service Report Entry</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Support Leader'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/customerrequirement">Customer Requirements</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/servicereportentry">Service Report Entry</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/newlead">New Lead</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/worksorting">Work Sorting</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/workallocation">Work Allocation</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Project Leader'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/projectleader/customerrequirement">Customer Requirements</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/projectleader/servicereportentry">Service Report Entry</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/projectleader/prowork">Project Work Allotment</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/projectleader/jobcard"> Job Card</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/jobscheduling">Job Scheduling</a>
                        </li>
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top"
                        *ngIf="userService.roleMatch(['Project Leader','Developers & Designers','Testers'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/leave">Leave Application</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/meeting">Meeting</a>
                        </li>
                    </ul>
                </li>
                <li class="menu-item has-collapsible">
                    <a><span></span>VOUCHERS</a>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Project Manager'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/servicecharge">SERVICE CHARGE</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/cloudcharge">CLOUD CHARGE</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/otherCharges">OTHER CHARGE</a>
                        </li>
                        <!-- <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/paymentpostphoned">PAYMENT POSTPONED</a>
                        </li> -->
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/receipts">Receipt</a>
                        </li>
                    </ul>
                </li>
                <li class="menu-item has-collapsible">
                    <a><span></span>Job Management</a>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Admin','Project Manager', 'Project Leader','Developers & Designers', 'Testers'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/asjobsmangement">Assigned Jobs Forecasting</a>
                        </li>
                    </ul>
                </li>
                <li class="menu-item has-collapsible">
                    <a><span></span>Settings</a>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Admin','Project Manager', 'Project Leader'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/documentsettings">Document Settings</a>
                        </li>
                    </ul>
                </li>
                <li class="menu-item has-collapsible">
                    <a><span></span>Reports</a>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Admin'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/admin/workForecasting">Work Forecasting</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/admin/servicehistory">Service History</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/admin/Backlogs">Backlogs</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/admin/TodaysDelivery">TodaysDelivery</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/admin/outstanding">Outstanding</a>
                        </li>                    
                    </ul>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Dealer'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/dealer/servicehistory">Service History</a>
                        </li>
                    </ul>  
                      <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Project Manager'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/outstanding">Outstanding</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/income">Income</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/sytemdetails">SystemDetails</a>
                        </li>
                      </ul>  
                      <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Support Leader'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/outstanding">Outstanding</a>
                        </li>
                      </ul>              
                </li>
               
                <li class="menu-item has-collapsible">
                    <a><span></span>MIS</a>
                    <ul class="menu-child ATJ-animation-slide-top" *ngIf="userService.roleMatch(['Admin','Project Manager', 'Project Leader'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/jobdetails">Job Details</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/projectcostanalysis">Project Cost Analysis</a>
                        </li>
                    </ul>
                </li>

                <li class="menu-item has-collapsible">
                    <a><span></span>Employee Details</a>
                    <ul class="menu-child ATJ-animation-slide-top"
                        *ngIf="userService.roleMatch(['Project Manager','Project Leader','Developers & Designers', 'Testers'])">
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/attandence">Attendence Register</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/timesheet">Time Sheet</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/workdetails">Work Details</a>
                        </li>
                        <li class="menu-child-item">
                            <a (click)="closeSideBar()" routerLink="/worksheet">Work Sheet</a>
                        </li>
                    </ul>  
                </li> 
                <div class="clock-container">
                    <marquee><b>{{this.time}}</b></marquee>
                    {{this.weekdayName}}
                </div>          
            </ul>
        </div>
    </nav>
</header>
<br>

<app-loading *ngIf="_CommonService.isLoading"></app-loading>