import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { Domain } from '../Models/Domain';
import { Observable } from 'rxjs';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Common } from '../Models/Response';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  
  selectedRecord:Domain;
  formHideShow = 0;
  allDomain:Domain[]; 
  pageIndex: number = 0;
  isFiltering: boolean = false;
  DomainNameFilter: string = '';
  ServerFilter: string = '';
  CustomerFilter:string='';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;
  allServer:Common[]=[];
  allCustomer:Common[]=[];

  clearValue(){
    this.selectedRecord={
      ID:0,
      DomainName:'',
      ServerID:undefined,
      ServerProvider:'',
      DomainCost:0,
      DomainRegDate:null,
      DomainExpDate:null,
      SSLCost:0,
      SSLRegDate:null,
      SSLExpDate:null,
      CustomerID:undefined,
      Customer:'',
      Notes:''
    }
  }

  clearFilter() {
    this.isFiltering = false;
    this.DomainNameFilter = '';
    this.ServerFilter = '';
    this.CustomerFilter='';
  }
  dateToStr(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  get(TransMode: string): Observable<Domain[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<Domain[]>(ROOT_URL + 'Domain/Get', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getByID(TransMode: string,id:number): Observable<Domain> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    params = params.append('ID', id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<Domain>(ROOT_URL + 'Domain/GetByID', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  trans(values: Domain): Observable<any> {
    var body = {
      ID:values.ID,
      DomainName:values.DomainName,
      ServerID:values.ServerID,
      DomainCost:values.DomainCost,
      DomainRegDate:this.dateToStr(values.DomainRegDate),
      DomainExpDate:this.dateToStr(values.DomainExpDate),
      SSLCost:values.SSLCost,
      SSLRegDate:values.SSLRegDate?.toString()?this.dateToStr(values.SSLRegDate):null,
      SSLExpDate:values.SSLExpDate?.toString()?this.dateToStr(values.SSLExpDate):null,
      CustomerID:values.CustomerID,
      Notes:values.Notes
    }
    let params = new HttpParams();
    params = params.append('TransMode',"SAVE");
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "Domain/Trans", body, {
      headers,params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  onDelete(Id:number){
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete<any>(ROOT_URL + 'Domain/Delete', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
    }
}
