import { Component, OnInit } from '@angular/core';
import { Backloghs } from 'src/app/Models/Backlogs';
import { BacklogsService } from 'src/app/Services/backlogs.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-todays-delivery',
  templateUrl: './todays-delivery.component.html',
  styleUrls: ['./todays-delivery.component.css']
})
export class TodaysDeliveryComponent implements OnInit { 

    constructor(public _BacklogsService:BacklogsService,public _CommonService:CommonService) { }
    pageIndex:number;
    orderByColName:string=''; 
    orderByDir:boolean=false;
    isFiltering:boolean=false;
    DelDate:Date=new Date();
    filterCol:Backloghs;
    ngOnInit(): void {
      this.pageIndex=1;
      this.filterCol={Customer:'',Dealer:'',DelDate:'',OverDueBy:'',RefNo:'',RegDate:'',RepDate:'',Stage:'',Status:'',TestResult:'',VType:''}
      this._CommonService.pageIndex=1;
      this._BacklogsService.getTodayDelivery(this.DelDate).subscribe(data=>{
        console.log(data);
        this._BacklogsService.DataList=data;
      })
    }
    sort(col){
      if (col == this.orderByColName) {
        this.orderByDir = !this.orderByDir;
      }
      this.orderByColName=col;
  
    }
    getData(){
      this._BacklogsService.getTodayDelivery(this.DelDate).subscribe(data=>{
        console.log(data);
        this._BacklogsService.DataList=data;
      })
    }
    toggleFilter(){
      this.isFiltering=!this.isFiltering;
      if(!this.isFiltering){
      this.filterCol={Customer:'',Dealer:'',DelDate:'',OverDueBy:'',RefNo:'',RegDate:'',RepDate:'',Stage:'',Status:'',TestResult:'',VType:''}
      }
    }
  }
  