<div class="container-fluid" *ngIf="_CustomerfeedbackmasterService.formHideShow!=0">
    <h2 class="heading_all">Customer Feedback Questions</h2>
    <!-- <div class="row FormHeader sim-button_head">
        <h3>Customer FeedBack</h3>
    </div> <br> -->
    <br>
    <div id="form-wrap">
        <form action="#" #cusfedmasForm="ngForm" (ngSubmit)="onSubmit(cusfedmasForm)">
            <!-- <h1>Customer feedback Questions</h1> -->

            <div class="form-group">
                <label for="first-name">Code<span style="color: red;">*</span></label>
                <input type="text" name="Code" id="first-name" name="Code" #Code="ngModel" autocomplete="off"
                    [(ngModel)]="_CustomerfeedbackmasterService.selectedRecord.Code" required>
                <div class="validation-error"
                    *ngIf="(Code.invalid && Code.touched)||(Code.invalid && this.FormValidation)">This Field is
                    Required.</div>
            </div>
            <div class="form-group">
                <label for="last-name">Question<span style="color: red;">*</span></label>
                <input type="text" name="Question" id="last-name" name="Question" #Question="ngModel" autocomplete="off"
                    [(ngModel)]="_CustomerfeedbackmasterService.selectedRecord.Question" placeholder=" Question"
                    required>
                <div class="validation-error"
                    *ngIf="(Question.invalid && Question.touched)||(Question.invalid && this.FormValidation)">This Field
                    is
                    Required.</div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                        class="sim-button button1">Submit</button>
                </div>
                <div class="col-md-4">
                    <button type="button" class="sim-button button2" (click)="resetForm(cusfedmasForm)"
                        style="width: 99%; margin-top: 25px;">Reset</button>
                </div>
                <div class="col-md-4">
                    <button type="button" class="sim-button button3" (click)="backList()"
                        style="width: 99%; margin-top: 25px;">Exit</button>
                </div>
            </div>

        </form>
    </div>

</div><br>