import { Component, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/Services/employee.service';
import { ToastrService } from 'ngx-toastr';
import { DesignationService } from 'src/app/Services/designation.service';
import { CommonService } from 'src/app/Services/common.service';
import { NgForm } from '@angular/forms';
import $ from 'jquery';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent implements OnInit {
  public errorMsg;
  public userRole: string;
  public userType: number = 0;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  constructor(public _empService: EmployeeService, 
              private toastr: ToastrService, 
              public _desigService: DesignationService,
              public _CommonService:CommonService) { }
  SelectedApproverIndex: number = null;

  // CurrentPassword: any = ''
  changePassword: boolean
  WarningModel: boolean;

  ngOnInit(): void {
    this.changePassword = false
    this.WarningModel = false
    this._empService.formHideShow = 0;
    this._empService.formHidePassword = false;
    this.userRole = localStorage.getItem('userRoles')
    if (this.userRole == '["Admin"]')
      this.userType = 0;
    else
      this.userType = 1
    this._empService.getDesignation(this.userType)
      .subscribe(data => {
        this._empService.allDesignations = data
      },
        error => {
          this.errorMsg = error;
        });
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._empService.CurrentPassword = ''
    this._empService.selectedEmployee = {
      Id: null, 
      LastUpdatedDate: '',
      EmpId: '',
      EmpName: '',
      Designation: null,
      Addr1: '',
      Addr2: '',
      Addr3: '',
      Email: '',
      Phone: '',
      HourlyRate:0,
      Username: '',
      Password: '',
      ConfirmPassword: '',
      DesignationName: '',
      ParentId: null,
      LoginId: null,
      LoginDate: '', 
      ModuleWorkingOn: '',
      TotalEstimatedHours:'',
      IsPeningWork:false,
      EmployeeApprover: [],
      InActive: false,
      OverDueCount:0
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._empService.formHideShow = 0;
    this.SelectedApproverIndex = null;
  }

  onSubmit(form: NgForm) {
    var result="";
    this.FormValidation = true  
    // form.valid
    if (form.valid) {
      this.isSubmit = true
      this._empService.allDesignations.forEach(obj => {
        if (obj.Id == this._empService.selectedEmployee.Designation) {
          this._empService.selectedEmployee.DesignationName = obj.Name
          if (form.value.Id == null) {
            this._empService.postEmployee(this._empService.selectedEmployee)
              .subscribe(data => {
                result=data;
                this.getAllEmployee()
                this.backList();
                this.toastr.success('New Record Added Succcessfully', 'Employee');
                this.SelectedApproverIndex = null;
              },
                error => {
                  this.errorMsg = error;
                  this.isSubmit = false;
                  this.toastr.error('New Record Added Failure', 'Employee');
                })
          }
          else {
            this._empService.putEmployee(this._empService.selectedEmployee)
              .subscribe(data => {
                if (data.Status == 0)
                  this.toastr.success('Record Updated Successfully!', 'Employee');
                else
                  this.toastr.success(data.Message, 'Employee');
                this.getAllEmployee()
                this.backList();
              },
                error => {
                  this.errorMsg = error;
                  this.toastr.error('Record not Updated', 'Employee');
                });
          }
        }
      });
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Employee');
    }
  }

  getAllEmployee() {
    let role: string
    if (localStorage.getItem('userRoles') == "[" + '"' + "Dealer" + '"' + "]")
      role = 'DEALERALL'
    else
      role = 'ADMINALL'

    this._empService.Fetch_EmployeeById(role, 0)
      .subscribe(data => {
        this._empService.allEmployees = data
      },
        error => {
          this.errorMsg = error;
        })
  }
  AddtoApproverList() {
    if (this.SelectedApproverIndex >= 0) {
      let emp = this._empService.allEmployees[this.SelectedApproverIndex];
      if (emp) {
        if (!(this._empService.selectedEmployee.EmployeeApprover.some(data => { return data.ApproverId == emp.Id }))) {
          this._empService.selectedEmployee.EmployeeApprover.push({ Designation: emp.DesignationName, ApproverId: emp.Id, ApproverName: emp.EmpName });
        }
      }
      this.SelectedApproverIndex = null;
    }
  }
  removeFormApproverList(i) {
    this._empService.selectedEmployee.EmployeeApprover.splice(i, 1)
  }

  OnClickCancel() {
    $('#myModalConfirm').css("display", "none")
    this.changePassword = false

  }
  changepwd() {
    this.changePassword = true
    $('#myModalConfirm').css("display", "block")
  }
}
