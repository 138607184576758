import { Component, OnInit } from '@angular/core';
import { DealerService } from 'src/app/Services/dealer.service';
import { ToastrService } from 'ngx-toastr';
import { dealer } from 'src/app/Models/dealer.Model';
import { AppConstants } from 'src/app/Models/constants';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html',
  styleUrls: ['./dealer-list.component.css']
})
export class DealerListComponent implements OnInit {

  public term: any = '';
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  public errorMsg;
  constructor(public _dealrService: DealerService,
    public _CommonService: CommonService,
    private toastr: ToastrService) { }


  ngOnInit(): void {
    this.deletePopUp = false;
    this.deleteID = 0;
    this._dealrService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.getdealer()
    this._dealrService.formHideShow = 0;
    this._CommonService.FormID = 'Dealer';
  }

  getdealer() {
    this._dealrService.FetchDealer('COUNTRYID')
      .subscribe(data => {
        this._dealrService.allDealers = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  toggleFilter() {
    this._dealrService.isFiltering = this._dealrService.isFiltering ? false : true;
    if (!this._dealrService.isFiltering) {
      this._dealrService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._dealrService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._dealrService.orderByColName) {
      this._dealrService.orderByDir = !this._dealrService.orderByDir;
    }
    this._dealrService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  async addList() {
    // this._dealrService.selectedDealer.DealerCode = await this._CommonService.getCode();
    this._dealrService.imageUrl = null;
    this._dealrService.formHideShow = 1;
    this._dealrService.formHidePassword = false;
  }

  showForEdit(dealr: dealer) {
    this._dealrService.selectedDealer = Object.assign({}, dealr);;
    this._dealrService.formHideShow = 1;
    this._dealrService.formHidePassword = true;
    this._dealrService.imageUrl = AppConstants.tockenURL + "Images/Logo/" + this._dealrService.selectedDealer.LogoURL;
  }

  onDelete(dealr: dealer) {
    this.deleteID = dealr.Id;
    this.deletePopUp = true;
    // if (confirm('Are you sure to delete this record ?') == true) {
    //   this._dealrService.deleteDealer(dealr)
    //     .subscribe(x => {
    //       if (x == '1') {
    //         this._dealrService.FetchDealer(0, 'COUNTRYID').subscribe(data => this._dealrService.allDealers = data,
    //           error => {
    //             this.errorMsg = error;
    //           });
    //         this.toastr.success("Deleted Successfully", "Dealer");
    //       }
    //       else
    //         this.toastr.error("Deletion Failed", "Dealer");
    //     })
    // }
  }
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._dealrService.deleteDealer(this.deleteID)
        .subscribe(x => {
          if (x == '1') {
            this.getdealer()
            this.toastr.success("Deleted Successfully", "Dealer");
          }
          else
            this.toastr.error("Deletion Failed", "Dealer");

          this.deleteID = 0;
        })
    }
  }
}
