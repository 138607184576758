<div class="container-fluid">
    <h2 class="heading_all">JOB DETAILS</h2>
    <br>
    <div id="form-wrap">
        <form #BathwiseJobDetails="ngForm">
            <div class="row">
                <div class="col-md-2">
                    <label><span class="validation-error">*</span>Period</label>
                    <ng-select name="Period" id="Period" bindLabel="Name" bindValue="ID" placeholder="Select"  
                        [items]="_CommonService.periodList" #Period="ngModel" [(ngModel)]="this._JobschedulingService.selectedPeriod" (ngModelChange)="periodChange($event)">
                    </ng-select>              
                </div>
                <div class="col-md-2">
                    <label>From<span class="validation-error">*</span></label>
                    <input class="form-control" name="From" #From="ngModel" [(ngModel)]="this._JobschedulingService.fromDate"
                        autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                </div>
                <div class="col-md-2">
                    <label>To<span class="validation-error">*</span></label>
                    <input class="form-control" name="To" #To="ngModel" [(ngModel)]="this._JobschedulingService.toDate" 
                        autocomplete="off" bsDatepicker [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label><span class="validation-error">*</span>Status</label>
                        <ng-select name="Status" id="Status" bindLabel="Name" bindValue="Id" placeholder="Select"
                            [items]="_JobschedulingService.batchStatus" #Status="ngModel" [(ngModel)]="this._JobschedulingService.selectedStatus">
                        </ng-select>
                    </div>      
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Customer</label>
                        <ng-select name="Customer" id="Customer" bindLabel="Name" bindValue="Id" placeholder="Select" [items]="_JobschedulingService.allCustomer"  
                             #Customer="ngModel" [(ngModel)]="this._JobschedulingService.selectedCustomer"  (ngModelChange)="this.loadBatch()" >
                        </ng-select>
                    </div>             
                </div>
                <div class="col-md-3">
                    <label><span class="validation-error">*</span>Batch</label>
                    <ng-select name="Batch" id="Batch" bindLabel="Name" bindValue="Id" placeholder="Select" 
                        [items]="_JobschedulingService.allBatch" #Batch="ngModel" [(ngModel)]="this._JobschedulingService.selectedBatch">
                    </ng-select>           
                </div>
                <div class="col-md-2">
                    <button type="button" name="Display" class="sim-button button1" style="width:75%; margin-top: 25px;" (click)="searchJobDetails()">Display</button> 
                </div>
            </div>
            <div class="tWrap">
                <table>
                    <thead>
                        <tr *ngFor="let data of this._JobschedulingService.batchWisejob.BatchWiseJobHdr;let i=index">
                            <th class="width">{{data.Name}}</th>
                            <th class="width">{{data.DesignDate}}</th>
                            <th class="width">{{data.DevelopmentDate}}</th>
                            <th class="width">{{data.TestingDate}}</th>
                            <th class="width">{{data.DeliveryDate}}</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="tWrap">
                <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>ID</th>
                            <th>Particulars</th>
                            <th>Description</th>
                            <th>Programmers</th>
                            <th>Testers</th>
                            <th>Stage</th>
                            <th>Est.C.Date</th>
                            <th>Act.C.Date</th>
                            <th>Est.Hrs</th>
                            <th>Act.hrs</th>
                            <th>Attempt</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of this._JobschedulingService.batchWisejob.BatchWiseJobDetails;let i=index">
                            <td>{{i+1}}</td>
                            <td>{{data.ActivityId}}</td>
                            <td title="{{data.Particulars}}">{{data.Particulars}}</td>
                            <td title="{{data.Description}}">{{data.Description}}</td>
                            <td title="{{data.Programmers}}">{{data.Programmers}}</td>
                            <td title="{{data.Tester}}">{{data.Tester}}</td>
                            <td>{{data.Stage}}</td>
                            <td>{{data.ExpCDate}}</td>
                            <td>{{data.ActCDate}}</td>
                            <td>{{data.EstHrs}}</td>
                            <td>{{data.ActHrs}}</td>
                            <td>{{data.Attempt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>   
        </form>
    </div>
</div>
