<!-- <ul class="pagination">
    <li [ngClass]="{'disabled': _commonService.pageIndex == 1}"><a (click)="first()"> &laquo;</a></li>
    <li [ngClass]="{'disabled': _commonService.pageIndex == 1}"><a (click)="pre()">❮</a></li>
    <ng-container *ngFor="let value of ' '.repeat(_commonService.totalPageCount).split(''), let i = index">
        <li (click)="PageIndexClick(i+1);" [ngClass]="{'current': i+1==_commonService.pageIndex}"
            *ngIf="pageIndexVisible(i+1) ">
            <a>{{i+1}} </a></li>
    </ng-container>
    <li [ngClass]="{'disabled': _commonService.pageIndex == _commonService.totalPageCount}"><a (click)="next()"> ❯</a>
    </li>
    <li [ngClass]="{'disabled': _commonService.pageIndex == _commonService.totalPageCount}"><a (click)="last()">
            &raquo;</a></li>
</ul> -->
<div style="text-align: center;">
    <div id="pagination">
        <ul class="pagination">
            <li><a [ngClass]="{'disabled': _commonService.pageIndex == 1}" (click)="first()"> &laquo;</a></li>
            <li><a [ngClass]="{'disabled': _commonService.pageIndex == 1}" (click)="pre()">❮</a></li>
            <ng-container *ngFor="let value of ' '.repeat(_commonService.totalPageCount).split(''), let i = index">
                <li *ngIf="pageIndexVisible(i+1) ">
                    <a (click)="PageIndexClick(i+1);" [ngClass]="{'current': i+1==_commonService.pageIndex}">{{i+1}}
                    </a></li>
            </ng-container>
            <li><a [ngClass]="{'disabled': _commonService.pageIndex == _commonService.totalPageCount}" (click)="next()">
                    ❯</a>
            </li>
            <li><a [ngClass]="{'disabled': _commonService.pageIndex == _commonService.totalPageCount}" (click)="last()">
                    &raquo;</a></li>
        </ul>
    </div>
</div>