import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';
import { JobCardService } from 'src/app/Services/job-card.service';
import { ProjectLeaderService } from 'src/app/Services/project-leader.service';

@Component({
  selector: 'app-assigned-jobs-forecasting',
  templateUrl: './assigned-jobs-forecasting.component.html',
  styleUrls: ['./assigned-jobs-forecasting.component.css']
})
export class AssignedJobsForecastingComponent implements OnInit {

  devTestToggle: number = 0;
  filterDate: Date;
  loadingPercentage:number=0

  constructor(public _ProjectLeaderService: ProjectLeaderService,
              private toastr: ToastrService, 
              public _CommonService: CommonService,
              public router:Router) { }

  ngOnInit(): void {
    this.filterDate = new Date();
    this.getEmpForcastDetails();
  }

  getEmpForcastDetails() {
    this._CommonService.isLoading = true;
    this._ProjectLeaderService.GetEmployeeWorkForcastDetails('ASSIGNEDJOBFORECASTING', this.filterDate, 0).subscribe(
      data => {
        //Designation last coulmn return the total avaliable hours of selected month
        this._CommonService.isLoading = false;
        this._ProjectLeaderService.EmployeeWorkForcastDetails = data;
        const assignedHourParts = this._ProjectLeaderService.EmployeeWorkForcastDetails[this._ProjectLeaderService.EmployeeWorkForcastDetails.length- 1].EmployeeWorkForcastDetails[0].ManHour.toString().split(':');
        const assignedHour  = parseInt(assignedHourParts[0], 10);
        const assignedMinute= parseInt(assignedHourParts[1], 10);
        const avalibeHourParts = this._ProjectLeaderService.EmployeeWorkForcastDetails[this._ProjectLeaderService.EmployeeWorkForcastDetails.length- 1].EmployeeWorkForcastDetails[0].Designation.toString().split(':');
        const avaliableHour = parseInt(avalibeHourParts[0], 10);
        const avaliableMinute = parseInt(avalibeHourParts[1], 10);
        this.loadingPercentage=Math.round((((assignedHour *60) + assignedMinute) *100) / ((avaliableHour *60) + avaliableMinute));
      }
    )
  }

  preMonth() {
    this.filterDate = new Date(this.filterDate.setMonth(this.filterDate.getMonth() - 1));
    this.getEmpForcastDetails();
  }
  nextMonth() {
    this.filterDate = new Date(this.filterDate.setMonth(this.filterDate.getMonth() + 1));
    this.getEmpForcastDetails();
  }

  jobOrder(employeeId:number,designation:string,date:string)
  {
    const queryParams = {
      param1:employeeId,
      param2:designation,
      param3:this._CommonService.DateFormat(date,1),
      param4:true
    };
    if(employeeId > 0)
    {
      this.router.navigate(['/jobcard'],{ queryParams: queryParams});
    }
  }
}
