import { CommonService } from 'src/app/Services/common.service';
import { async } from '@angular/core/testing';
import { Component, OnInit } from '@angular/core';
import { Subscription,interval } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  subscription: Subscription;
  // intervalId: number;

  constructor(public commonService:CommonService) { }

  ngOnInit(): void { 
    //  const source = interval(100000);
    //  this.subscription = source.subscribe(val => this.displayLeaveReqCount());
     this.displayLeaveReqCount();
  }

  displayLeaveReqCount()
  {
    this.subscription=this.commonService.loginedUserId().subscribe(
      data=>{
        this.commonService.getnodificationCount(data.loginedUserId > 0?data.loginedUserId:0,'GETALLREQCOUNT');
      }
    )
    console.log("sucess")
  }

  ngOnDestroy()
  {
    this.subscription.unsubscribe();
    // this.subscription && this.subscription.unsubscribe();
    // clearInterval(this.intervalId);
  }

}
