import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { ServiceReportEntryService } from 'src/app/Services/service-report-entry.service';
import { NgForm } from '@angular/forms';
import { ModuleService } from 'src/app/Services/module.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { CommonService } from 'src/app/Services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ModuleServiceReport } from 'src/app/Models/module.model';
import { EmployeeService } from 'src/app/Services/employee.service';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { ServiceRequirement } from 'src/app/Models/serviceReport.model';

@Component({
  selector: 'app-service-report-entry',
  templateUrl: './service-report-entry.component.html',
  styleUrls: ['./service-report-entry.component.css']
})
export class ServiceReportEntryComponent implements OnInit {

  constructor(public _custService: CustomerService,
    public _serviceEngineerDBService: ServiceEngineerDBService,
    public _ParticularmasterService: ParticularmasterService,
    public _brnchService: BranchService,
    public _projectService: ProjectService,
    public _ServiceReportEntryService: ServiceReportEntryService,
    public _nodeRegService: NodeRegistrationService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _CommonService: CommonService,
    private toastr: ToastrService,
    public _EmployeeService: EmployeeService,
    public _dealrService: DealerService,
    public _ModuleService: ModuleService) { }

  public errorMsg;
  PriorityList: any;
  StatusList: any;
  StatusFullList: any;
  StatusFullListCustom: any;
  TypeList: any;
  datechanges: boolean
  AMCRenewalStatus: boolean
  SupportEngId: number
  SearchOrNewSRE: boolean
  ModuleDateFullList: any = []
  SubmitButton: boolean = false
  FormValidation: boolean  //Used form form validation

  //For description edit
  OpenDescriptionPopup: boolean
  customerReqIndex: number
  newAttributeorNot: boolean

  ngOnInit(): void {
    this.getLoginUser()
    this.Reset();
    this.GetDataLoads();
  }

  GetDataLoads() {
    this.datechanges = false
    this.SearchOrNewSRE = false
    this.fillAllEmployees();
    this.fillparticulars();
    this.getListCommon();
    this.AMCRenewalStatus = false

    this.GetAllDealers();
    this.GetRefNo();
    this.LoadCustomers();
  }

  async getLoginUser() {
    if (localStorage.getItem("Designation") == 'Support Engineer')
      this._CommonService.LoginUser = 'SupportEngineer';
    else
      this._CommonService.LoginUser = 'Provider';

    this.SupportEngId = parseInt(await this._CommonService.getLoginedUserId())
  }

  async GetRefNo() {
    this._ServiceReportEntryService.SelectedServiceRepHeader.Se = parseInt(await this._CommonService.getLoginedUserId())
    this._CommonService.FormID = 'SRE';
    this._CommonService.LoginId = this._ServiceReportEntryService.SelectedServiceRepHeader.Se

    if (localStorage.getItem("Designation") == 'Support Engineer') {
      this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId = parseInt(await this._CommonService.getDealerId(this._CommonService.FormID, this._CommonService.LoginId, this._CommonService.LoginUser));
      this.LoadCustomers()
    }
    else {
      this._CommonService.LoginId = this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId;
    }
    //Get RefNo
    this._ServiceReportEntryService.SelectedServiceRepHeader.RefNo = parseInt(await this._CommonService.getRefNoById(this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId, this._CommonService.FormID, this._CommonService.LoginUser));
  }

  Reset() {
    this.OpenDescriptionPopup = false
    this._CustomerrequirementService.DescriptionEdit = ''
    this.customerReqIndex = 0
    this.newAttributeorNot = true

    this._ServiceReportEntryService.FormUpdation = false
    this.SearchOrNewSRE = false;
    this.ModuleDateFullList = []
    this.SubmitButton = false
    this.FormValidation = false
    this._ServiceReportEntryService.SelectedServiceRepHeader = {
      AMCRenewal: null,
      AMCUpto: null,
      BranchAdr1: '',
      BranchAdr2: '',
      BranchAdr3: '',
      BranchId: 0,
      ContractDate: null,
      CustAdr1: '',
      CustAdr2: '',
      CustAdr3: '',
      CustomerId: 0,
      Date: new Date(),
      From: null,
      Node: 0,
      NumberOfVisit: 0,
      ProjectId: 0,
      RefNo: 0,
      Se: 0,
      Server: null,
      SupportMode: 0,
      To: null,
      Notes: '',
      ExtraTime: false,
      ServiceCharge: 0,
      PaidAmount: 0,
      BalanceAmount: 0,
      TransNOtherCharges: 0,
      ContractVsCurrentDate: null,
      DealerId: 0,
      ServiceDetails: [],
      ServiceModules: [],

    }

    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._ServiceReportEntryService.selectedServiceRequirement = []
    this._ServiceReportEntryService.SelectedServiceDetails = []
    this._ModuleService.allModuleServiceReport = []

    this._ServiceReportEntryService.newAttribute = {
      ID: 0,
      SNo: 1,
      Particular: 0,
      RefNo: 0,
      ModuleId: 0,
      Description: '',
      Type: 0,
      Priority: 0,
      Status: 2,
      Remarks: ''
    }
    this._ServiceReportEntryService.RowCount = 0;


  }

  GetAllDealers() {
    //Only SR alloted ,Not Loading ALL
    this._dealrService.FetchDealer('SREDEALER')
      .subscribe(data => {
        // console.log(data)
        this._dealrService.allDealers = data
      })
  }

  getListCommon() {
    this.PriorityList = []
    this.StatusList = []
    this.TypeList = []
    this.StatusFullList = []
    this._ServiceReportEntryService.SelectedServiceDetails = []
    this.StatusFullList = this._CommonService.GetStatusFullList();
    this.PriorityList = this._CommonService.GetPriorityList()
    this.StatusList = this._CommonService.GetStatusList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusFullListCustom = this._CommonService.GetStatusFullListCustom();
    // this.LoadBranch();
  }

  LoadCustomers() {
    // console.log((111111))
    this._custService.FetchAllCustomerById('SRECUSTMR', this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId, this._ServiceReportEntryService.SelectedServiceRepHeader.Se)
      .subscribe(data => {
        this._custService.allCustomers = data
        this.onSelect(1);
        this.onSelect(2);
        // this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId = 0
        if (localStorage.getItem("sehome") == 'selecteddata') {
          this.fillhomescreenData();

        }
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillhomescreenData() {
    if (localStorage.getItem("sehome") == 'selecteddata') {
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId = this._serviceEngineerDBService.allSEDashBoardCustReq.CustomerId;
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = this._serviceEngineerDBService.allSEDashBoardCustReq.BranchId;
      this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = this._serviceEngineerDBService.allSEDashBoardCustReq.ProjectId;
      this._ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge = this._serviceEngineerDBService.allSEDashBoardCustReq.ServiceCharge;
    }
    this.getAllServiceReqPending();
    this.GetAllModules()
    this.onSelect(1);
    this.onSelect(2);
    localStorage.removeItem('sehome');
  }

  fillAllEmployees() {
    this._EmployeeService.Fetch_Employee('ALL')
      .subscribe(data => {
        this._CustomerrequirementService.allEmployees = data;
        // this._CustomerrequirementService.allSE = data;
        this._ServiceReportEntryService.SelectedServiceRepHeader.Se = this.SupportEngId
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillproject() {
    this._projectService.fetchProject('BYBRANCHID', this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId == null ? 0 : this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId)
      .subscribe(data => {
        this._projectService.allProjects = Object.assign([], data)
        this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = null
        if (this._projectService.allProjects.length == 1)
          this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = this._projectService.allProjects[0].Id
        this.GetAllModules();
        this.getAllServiceReqPending()
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillparticulars() {
    this._ParticularmasterService.particularList = []
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = Object.assign([], data)
        this._ParticularmasterService.particularList = this._ParticularmasterService.allparticular.filter(obj => obj.ModuleID == this._ServiceReportEntryService.newAttribute.ModuleId)
        this._ServiceReportEntryService.newAttribute.Particular = 0
      },
        error => {
          this.errorMsg = error;
        })
  }

  async LoadBranch() {
    // console.log('this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId:', this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId)
    if (this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId != 0) {
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = 0

      await this._brnchService.Fetch_BranchById(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, 'CUSTOMERID')
        .subscribe(data => {
          this._brnchService.allBranchs = Object.assign([], data)
          if (this._brnchService.allBranchs.length == 1) {
            this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = this._brnchService.allBranchs[0].Id
            this.fillproject();
            this.onSelect(1)
            this.onSelect(2)
          }
          this.getAllServiceReqPending()
        },
          error => {
            this.errorMsg = error;
          });
    }
    else {
      await this._brnchService.Fetch_BranchById(this.SupportEngId, 'SERVENGID')
        .subscribe(data => {
          this._brnchService.allBranchs = Object.assign([], data)
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = null
          if (this._brnchService.allBranchs.length == 1) {
            this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = this._brnchService.allBranchs[0].Id
            this.fillproject();
            this.onSelect(1)
            this.onSelect(2)
          }
          this.GetAllModules();
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  GetAllModules() {

    this.AMCRenewalStatus = false
    this.datechanges = false
    if (this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId != 0
      && this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId != null
      && this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId != null) {

      if (this.ModuleDateFullList.length < 1) {

        this._ModuleService.Fetch_ModuleServiceReport(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId, this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId)
          .subscribe(data => {
            this._ModuleService.allModuleServiceReport = data
            this._ModuleService.allModuleServiceReport.forEach(obj => {
              obj.ExeDate = null
              obj.ExeDateToBind = null
              obj.ScriptDate = null
              obj.ScriptDateToBind = null
            })
          },
            error => {
              this.errorMsg = error;
            })
      }

      this._CommonService.LoadSoftWareDtlsCommon(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId, this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId).
        subscribe(obj => {
          this._ServiceReportEntryService.SelectedServiceRepHeader.Server = obj.NoOfServer
          this._ServiceReportEntryService.SelectedServiceRepHeader.Node = obj.Nodes
          this._ServiceReportEntryService.SelectedServiceRepHeader.ContractVsCurrentDate = obj.ContractVsCurrentDate
          this._ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal = new Date(obj.AMCRenewal)
          this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto = new Date(obj.AMCUPTo)
          this._ServiceReportEntryService.SelectedServiceRepHeader.ContractDate = new Date(obj.ContractDate)
          if (this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto > new Date())
            this.AMCRenewalStatus = true
        })

      this._CustomerrequirementService.GetCustomerRequirementsHistory(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId, this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId, 0)
        .subscribe(obj => {
          this._ServiceReportEntryService.SelectedServiceRepHeader.NumberOfVisit = obj.NoOfVisits
          this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate
          this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
          this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate
          this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate
        })
    }
    this.datechanges = true
  }

  getAllServiceReqPending() {
    // console.log('this.SearchOrNewSRE:', this.SearchOrNewSRE)
    if (!this.SearchOrNewSRE) {
      this._ModuleService.allModuleServiceReport = []
      this._ServiceReportEntryService.SelectedServiceDetails = [];
      this._ServiceReportEntryService.SelectedServiceRepHeader.Se = this.SupportEngId
      if (this._ServiceReportEntryService.SelectedServiceRepHeader.Se != null
        && this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId != null
        && this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId != 0
        && this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId != null)
        this._ServiceReportEntryService.GetServRptPendingWorks(this._ServiceReportEntryService.SelectedServiceRepHeader.Se,
          this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId, this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId,
          this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId).subscribe(obj => {
            this._ServiceReportEntryService.SelectedServiceDetails = obj
            this._ServiceReportEntryService.SelectedServiceDetails.forEach(field => {
              this._ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge = field.ServiceCharge
            })
          })
      this._ServiceReportEntryService.selectedServiceRequirement = []
      this._ServiceReportEntryService.newAttribute = {
        SNo: 1,
        Particular: 0,
        RefNo: 0,
        ModuleId: 0,
        Description: '',
        Type: 0,
        Priority: 0,
        Status: 2,
        Remarks: ''
      }
    }
  }

  Updatedates(item, moduleName: any, selector: number) {
    if (this.datechanges) {
      this._ModuleService.allModuleServiceReport.filter(obj => {
        if (obj.ModuleName == moduleName && selector == 1) {
          obj.ScriptDateToBind = item
          obj.ScriptDate = this.convert(item)
        }
        if (obj.ModuleName == moduleName && selector == 2) {
          obj.ExeDateToBind = item
          obj.ExeDate = this.convert(item)
        }
      })
    }
  }

  Updatedate(newDate) {
    this._ServiceReportEntryService.SelectedServiceRepHeader.Date = newDate
    this._ServiceReportEntryService.currentStringDate = this.convert(newDate)
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }

  onSelect(type: number) {
    if (type == 1) {
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr1 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr2 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr3 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr1 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr2 = '';
      this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr3 = '';
      // if (localStorage.getItem("sehome") == 'selecteddata') {
      //   this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId = this._serviceEngineerDBService.allSEDashBoardCustReq.CustomerId;
      //   this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = this._serviceEngineerDBService.allSEDashBoardCustReq.BranchId;
      //   this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = this._serviceEngineerDBService.allSEDashBoardCustReq.ProjectId;
      //   this._ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge = this._serviceEngineerDBService.allSEDashBoardCustReq.ServiceCharge;
      // }

      // console.log('this._custService.allCustomers:', this._custService.allCustomers)
      // console.log('this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId:', this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId)
      var custDtls = this._custService.allCustomers.filter(e => e.Id == this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId);
      // console.log('custDtls:', custDtls)
      if (custDtls != undefined)
        if (custDtls.length > 0) {
          this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr1 = custDtls[0].Addr1;
          this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr2 = custDtls[0].Addr2;
          this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr3 = custDtls[0].Addr3;
        }
    }
    if (type == 2) {
      // console.log('this._brnchService.allBranchs:', this._brnchService.allBranchs)
      if (this._brnchService.allBranchs != undefined)
        var brnchDtls = this._brnchService.allBranchs.filter(e => e.Id == this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId);
      // console.log('brnchDtls:', brnchDtls)
      if (brnchDtls != undefined)
        if (brnchDtls.length > 0) {
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr1 = brnchDtls[0].Addr1;
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr2 = brnchDtls[0].Addr2;
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr3 = brnchDtls[0].Addr3;
          this._brnchService.allBranchs.length > 1 ? this.fillproject() : null
        }
    }

  }

  addFieldValue() {
    if (this._ServiceReportEntryService.newAttribute.Particular != 0 && this._ServiceReportEntryService.newAttribute.ModuleId != 0 &&
      this._ServiceReportEntryService.newAttribute.Description != '' && this._ServiceReportEntryService.newAttribute.Type != 0
      && this._ServiceReportEntryService.newAttribute.Priority != 0 && this._ServiceReportEntryService.newAttribute.Status != 0) {
      this._ServiceReportEntryService.selectedServiceRequirement.push(this._ServiceReportEntryService.newAttribute)
      this._ServiceReportEntryService.RowCount = this._ServiceReportEntryService.selectedServiceRequirement.length;
      this._ServiceReportEntryService.newAttribute = {
        SNo: this._ServiceReportEntryService.RowCount + 1,
        ID: 0,
        Particular: 0,
        RefNo: 0,
        ModuleId: 0,
        Description: '',
        Type: 0,
        Priority: 0,
        Status: 2,
        Remarks: ''
      }
    }
    else
      this.toastr.warning('Please Enter Fields', 'Service Report Entry')
  }
  deleteFieldValue(index) {
    this._ServiceReportEntryService.selectedServiceRequirement.splice(index, 1);
    this._ServiceReportEntryService.RowCount = this._ServiceReportEntryService.selectedServiceRequirement.length;
  }


  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid) {
      if (this._ServiceReportEntryService.SelectedServiceDetails.filter(obj => obj.CheckStatus == true).length > 0 || this._ServiceReportEntryService.FormUpdation) {
        this.SubmitButton = true
        this._ServiceReportEntryService.SaveItem(this._ServiceReportEntryService.SelectedServiceRepHeader).subscribe(data => {
          this.SubmitButton = false
          if (data.Status == 0)
            this.toastr.success(data.Message, 'Service Data Entry');
          else
            this.toastr.warning(data.Message, 'Service Data Entry');
          this.Reset();
          this.GetRefNo()
        })
      }
      else
        this.toastr.warning('Pending Works need to Update', 'Service Data Entry');
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Service Data Entry');
    }
  }

  ModuleDateList: ModuleServiceReport
  onChangeCalc(PaidAmount: number) {
    let a = this._ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge - PaidAmount
    this._ServiceReportEntryService.SelectedServiceRepHeader.BalanceAmount = a;
  }

  SearchSERByRef() {
    this.SearchOrNewSRE = true;
    this._ServiceReportEntryService.FormUpdation = true
    this.AMCRenewalStatus = false
    this.ModuleDateFullList = []
    if (this._ServiceReportEntryService.SelectedServiceRepHeader.RefNo != null)
      this._ServiceReportEntryService.getServiceReportEntry(this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId, this._ServiceReportEntryService.SelectedServiceRepHeader.RefNo).subscribe(async obj => {
        // console.log('getServiceReportEntry', obj)
        if (obj.RefNo != 0) {
          this._ServiceReportEntryService.SelectedServiceRepHeader = Object.assign({}, obj);
          // console.log(' this._ServiceReportEntryService.SelectedServiceRepHeader:', this._ServiceReportEntryService.SelectedServiceRepHeader)

          this._ServiceReportEntryService.SelectedServiceDetails = obj.ServiceDetails
          this._ModuleService.allModuleServiceReport = []
          this._ServiceReportEntryService.SelectedServiceRepHeader.SupportMode = obj.SupportMode;
          this._ServiceReportEntryService.SelectedServiceRepHeader.Se = obj.Se;

          this._custService.FetchAllCustomerById('SRECUSTMRSELECT', this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId, this._ServiceReportEntryService.SelectedServiceRepHeader.Se)
            .subscribe(data => {
              this._custService.allCustomers = data;
              this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId = obj.CustomerId;
            });

          this._ServiceReportEntryService.SelectedServiceRepHeader.Date = new Date(obj.Date);
          this.LoadBranch();
          this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = obj.BranchId;
          this.fillproject();

          this._ServiceReportEntryService.selectedServiceRequirement = obj.CustumerRequDtls;
          this._ServiceReportEntryService.RowCount = this._ServiceReportEntryService.selectedServiceRequirement.length;
          this._ServiceReportEntryService.newAttribute = {
            SNo: this._ServiceReportEntryService.RowCount + 1,
            ID: 0,
            Particular: 0,
            RefNo: 0,
            ModuleId: 0,
            Description: '',
            Type: 0,
            Priority: 0,
            Status: 2,
            Remarks: ''
          }

          this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = obj.ProjectId;
          this.onSelect(1)
          this.onSelect(2)

          this._CommonService.LoadSoftWareDtlsCommon(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId, this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId).
            subscribe(obj => {
              this._ServiceReportEntryService.SelectedServiceRepHeader.Server = obj.NoOfServer
              this._ServiceReportEntryService.SelectedServiceRepHeader.Node = obj.Nodes
              this._ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal = new Date(obj.AMCRenewal)
              this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto = new Date(obj.AMCUPTo)
              this._ServiceReportEntryService.SelectedServiceRepHeader.ContractDate = new Date(obj.ContractDate)
              this._ServiceReportEntryService.SelectedServiceRepHeader.ContractVsCurrentDate = obj.ContractVsCurrentDate
              if (this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto > new Date())
                this.AMCRenewalStatus = true
            })


          this._CustomerrequirementService.GetCustomerRequirementsHistory(this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId, this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId, this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId, 0)
            .subscribe(obj => {
              this._ServiceReportEntryService.SelectedServiceRepHeader.NumberOfVisit = obj.NoOfVisits
              this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate
              this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
              this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate
              this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate
            })

          this.ModuleDateFullList = obj.ServiceModules;
          this.ModuleDateList = {
            ExeDate: null,
            ExeDateToBind: null,
            Id: 0,
            ModuleCode: '',
            ModuleName: '',
            ScriptDate: null,
            ScriptDateToBind: null
          }
          this.ModuleDateFullList.forEach(obj => {
            this.ModuleDateList.ExeDate = obj.ExeDate == null ? null : obj.ExeDate;
            this.ModuleDateList.ExeDateToBind = obj.ExeDate == null ? null : new Date(obj.ExeDate);
            this.ModuleDateList.Id = obj.Id;
            this.ModuleDateList.ModuleCode = obj.ModuleCode;
            this.ModuleDateList.ModuleName = obj.ModuleName;
            this.ModuleDateList.ScriptDate = obj.ScriptDate == null ? null : obj.ScriptDate;
            this.ModuleDateList.ScriptDateToBind = obj.ScriptDate == null ? null : new Date(obj.ScriptDate);
            this._ModuleService.allModuleServiceReport.push(this.ModuleDateList)
            this.ModuleDateList = {
              ExeDate: null,
              ExeDateToBind: null,
              Id: 0,
              ModuleCode: '',
              ModuleName: '',
              ScriptDate: null,
              ScriptDateToBind: null
            }
          })
          this.datechanges = true
        }
        else {
          this.Reset();
          this.toastr.warning('Service Report Does not Exist', 'Service Report Entry')
          this._ServiceReportEntryService.FormUpdation = false
        }

      })
  }

  DelaerChange() {
    this._ServiceReportEntryService.FormUpdation = false
    this.SearchOrNewSRE = false;
    this.ModuleDateFullList = []
    this.LoadCustomers();
    this._ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr1 = ''
    this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr2 = ''
    this._ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr3 = ''
    this._ServiceReportEntryService.SelectedServiceRepHeader.BranchId = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.ContractDate = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr1 = ''
    this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr2 = ''
    this._ServiceReportEntryService.SelectedServiceRepHeader.CustAdr3 = ''
    this._ServiceReportEntryService.SelectedServiceRepHeader.CustomerId = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.Date = new Date()
    this._ServiceReportEntryService.SelectedServiceRepHeader.From = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.Node = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.NumberOfVisit = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.ProjectId = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.Server = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.SupportMode = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.To = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.Notes = ''
    this._ServiceReportEntryService.SelectedServiceRepHeader.ExtraTime = false
    this._ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.PaidAmount = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.BalanceAmount = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.TransNOtherCharges = 0
    this._ServiceReportEntryService.SelectedServiceRepHeader.ContractVsCurrentDate = null
    this._ServiceReportEntryService.SelectedServiceRepHeader.ServiceDetails = []
    this._ServiceReportEntryService.SelectedServiceRepHeader.ServiceModules = []
  }

  OpenDescPopup(field: ServiceRequirement, index: number) {
    if (index == 0)
      this.newAttributeorNot = false
    else
      this.newAttributeorNot = true
    this._CustomerrequirementService.DescriptionEdit = field.Description
    this.customerReqIndex = field.SNo
    this.OpenDescriptionPopup = true
    setTimeout(() => {
      var cntrl = document.getElementById('DescriptionEdit');
      if (cntrl) {
        cntrl.focus();
      }
    }, 300);
  }

  SetDescription() {
    if (this.newAttributeorNot)
      this._ServiceReportEntryService.newAttribute.Description = this._CustomerrequirementService.DescriptionEdit
    else {
      this._ServiceReportEntryService.selectedServiceRequirement.forEach(obj => {
        if (obj.SNo.toString() == this.customerReqIndex.toString())
          obj.Description = this._CustomerrequirementService.DescriptionEdit
      })
    }
    this._CustomerrequirementService.DescriptionEdit = ''
    this.OpenDescriptionPopup = false
  }

}
