import { error } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { ChargeTypeService } from './../../../../../Services/charge-type.service';
import { CommonService } from '../../../../../Services/common.service';
import { ToastrService } from 'ngx-toastr';
import { CommonCodeNameMaster } from 'src/app/Models/CommonCodeNameMaster';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-charge-type-list',
  templateUrl: './charge-type-list.component.html',
  styleUrls: ['./charge-type-list.component.css']
})
export class ChargeTypeListComponent implements OnInit {

  NoOfItems: number = 10;
  public deletePopUp: boolean = false;
  deleteID:number=0;

  constructor(public chargeTypeService:ChargeTypeService,
              public commonService:CommonService,
              public toastrService:ToastrService,
              public activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
   this.commonService.formId=this.activatedRoute.snapshot.paramMap.get("formId");
   if(this.commonService.formId=='3')
    this.commonService.commonNameText='Charge Type';

   this.deletePopUp = false;
   this.displayAllChargeType();
   this.commonService.pageIndex = 1;
   this.chargeTypeService.pageIndex = 1;
  }

  toggleFilter() {
    this.chargeTypeService.isFiltering = this.chargeTypeService.isFiltering ? false : true;
    if (!this.chargeTypeService.isFiltering) {
      this.chargeTypeService.ClearFilter();
      this.commonService.pageIndex = 1;
      this.chargeTypeService.pageIndex = 1;
    }
  }

  sort(colName: string) {
    if (colName == this.chargeTypeService.orderByColName) {
      this.chargeTypeService.orderByDir = !this.chargeTypeService.orderByDir;
    }
    this.chargeTypeService.orderByColName = colName;
  }

  addList()
  {
    this.chargeTypeService.formHideShow=1;
  }

  showForEdit(obj:CommonCodeNameMaster)
  {
    this.chargeTypeService.selectedChargeType=Object.assign({},obj);
    this.chargeTypeService.formHideShow = 1;
  }

  displayAllChargeType()
  {
    this.chargeTypeService.getAllCommonCodeNameMaster(this.commonService.formId).subscribe(
      list=>{this.chargeTypeService.allChargeType=list},error=>
      {this.toastrService.error('Failed to Load Charge Type',this.commonService.commonNameText)}
    )
  }

  onDelete(type: CommonCodeNameMaster) {
    if(type.Id > 4)
    {
      this.deleteID = type.Id;
      this.deletePopUp = true;
    }
    else
    {
      this.toastrService.info('Reserverd Charges',this.commonService.commonNameText)
      return;
    }
  }

  dialogResult(event)
  {
    this.deletePopUp = false;
    if (event) {
      this.chargeTypeService.deleteCommonCodeNameMaster(this.deleteID,this.commonService.formId)
        .subscribe(data => {
          if(data.Status==200)
          {
            this.toastrService.success(data.Message, this.commonService.commonNameText);
            this.chargeTypeService.getAllCommonCodeNameMaster(this.commonService.formId).subscribe(data => this.chargeTypeService.allChargeType = data,
              error => {
                this.toastrService.error(data.Message,this.commonService.commonNameText);
              });
          }      
          else if(data.Status==500)
            this.toastrService.error(data.Message,this.commonService.commonNameText);
          else
            this.toastrService.info(data.Message,this.commonService.commonNameText);
        }, error => {
          this.toastrService.error("Deletion Failure",this.commonService.commonNameText);
        })
    }
  }

}
