import { strict } from 'assert';
import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
import { TimeSettingsService } from '../../../Services/time-settings.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx'; 
import { error } from 'protractor';

@Component({
  selector: 'app-keyexpirydetails',
  templateUrl: './keyexpirydetails.component.html',
  styleUrls: ['./keyexpirydetails.component.css']
})
export class KeyexpirydetailsComponent implements OnInit {
  public errorMsg;
  fileName= 'Default.xlsx'; 
  rewdaysPermission:number=0;
  designation:string
  selectedSystemName:string[]=[];

  @Input() expDetailsPopup :number=0;

  constructor(public _CommonService:CommonService,
              public _TimeSettings:TimeSettingsService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.designation=localStorage.getItem('Designation');
    if(this.designation =="Project Manager")
      this.rewdaysPermission=1;
    else if(this.designation =="Admin")
      this.rewdaysPermission=1;
      
    this._CommonService.getremainderdays();
    this.displayExpiryCustomerList();
    this.displayExpiryDealerList();
  }
  
  closePopUp() {
    this._CommonService.expDetailsPopup=0;
    this._CommonService.validDays=0;
    this._CommonService.selectedCustomer=0;
    this._CommonService.selectedDealer=0;
  }

  selectAllExpiredkeys()
  {
    if(this._CommonService.chkSelectAll)
      this._CommonService.KeyExpiryDetailsList.forEach(obj=>obj.IsChecked=true)
    else
      this._CommonService.KeyExpiryDetailsList.forEach(obj=>obj.IsChecked=false)
  }

  displayExpiryCustomerList()
  {
    this._CommonService.getExpiryCustomerList().subscribe(
      data=>{this._CommonService.keyExpiredCustomer=data
      },
      error=>{this.errorMsg=error}
    );
  }
  
  displayExpiryDealerList()
  {
    this._CommonService.getExpiryDealerList().subscribe(
      data=>{this._CommonService.keyExpiredDealer=data
      },
      error=>{this.errorMsg=error}
    )
  }

  filterRemainderdaywiseKeyExpiry(isHoldModuleFilter:number)
  {   
    this.displayExpiryDealerList();
    this.displayExpiryCustomerList();
    this._CommonService.displayKeyExpiryDetails(this._CommonService.expDetailsPopup,isHoldModuleFilter);
    let x=this._CommonService.selectedCustomer;
    this._CommonService.selectedCustomer=0;
    let y=this._CommonService.selectedDealer;
    this._CommonService.selectedDealer=0;
    setTimeout(() => {  
      this._CommonService.selectedCustomer=x;
      this._CommonService.selectedDealer=y;
    }, 1000);
  }

  updateValidDays()
  {
    this._CommonService.KeyExpiryDetailsList.forEach(obj=>{
      if(obj.IsChecked)
        this.selectedSystemName.push(obj.SystemName)
    })

    if(this.selectedSystemName.toString().length==0)
    {
      this.toastr.warning("Please Select the Key Details", 'Key Details');
      return;
    }
    this._CommonService.updateValidDays(this.selectedSystemName).subscribe(
      data=>{
        this._CommonService.validDays=0;
        this._CommonService.selectedCustomer=0;
        this._CommonService.selectedDealer=0;
        this.selectedSystemName=[];
        if(this.rewdaysPermission==1)
            this.displayExpiryCustomerList();
        this._CommonService.displayKeyExpiryDetails(this._CommonService.expDetailsPopup,0);
        if(data.Status==200)
          this.toastr.success(data.Message, 'Key Details');
        else
          this.toastr.error(data.Message, 'Key Details');
      }
    )
  }

  dealerWiseCustomer()
  {
    this._CommonService.selectedCustomer=0;
    this.displayExpiryCustomerList();
  }

  exportexcel(): void 
  {
      this.fileName="";
      if(this._CommonService.expDetailsPopup==1)
      {
        this.fileName="KeyExpiryDetails("+this._CommonService.DateFormat(new Date(),0)+').xlsx';
        let keyelement = document.getElementById('keyexpirydetails');/* table id is passed over here */   
        const wskey: XLSX.WorkSheet =XLSX.utils.table_to_sheet(keyelement);/* generate workbook and add the worksheet */
        const wbkey: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wbkey, wskey, 'Sheet1');
        XLSX.writeFile(wbkey, this.fileName); /* save to file */
      }
      if(this._CommonService.expDetailsPopup==2)
      {
        this.fileName="AMCDetails("+this._CommonService.DateFormat(new Date(),0)+').xlsx';
        let amcelement = document.getElementById('AMCdetails'); 
        const wsamc: XLSX.WorkSheet =XLSX.utils.table_to_sheet(amcelement);
        const wbamc: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wbamc,wsamc, 'Sheet2');
        XLSX.writeFile(wbamc, this.fileName); 
      }
  }
}


