import { Component, OnInit } from '@angular/core';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import * as $ from "jquery";
import { CommonService } from 'src/app/Services/common.service';
import { TesterdashboardService } from 'src/app/Services/testerdashboard.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TesterDashboard, TesterDashboardDet } from 'src/app/Models/TesterDashboard';
import * as CryptoJS from 'crypto-js';
import { IMG_ROOT_URL, ROOT_URL } from 'src/app/Models/Config';
import { EmployeeService } from 'src/app/Services/employee.service';

@Component({
  selector: 'app-testers-home-screen',
  templateUrl: './testers-home-screen.component.html',
  styleUrls: ['./testers-home-screen.component.css']
})
export class TestersHomeScreenComponent implements OnInit {

  constructor(
            private AmCharts: AmChartsService,
            private toastr: ToastrService,
            public _TesterdashboardService: TesterdashboardService,
            public commonService:CommonService
  ) { }
  private chartdiv: AmChart;
  private chartdiv2: AmChart;
  private chartdiv3: AmChart;
  Remark: string = '';
  Description: string = '';
  FileList: string[] = [];
  jobOrderForm: boolean;
  CustomerRequirementForm: boolean
  WarningModel: boolean;
  // TesterId: string

  ngOnInit(): void {
    this.FileList = []
    this._TesterdashboardService.AllPendingWorkDet =
    {
      CompDate: null,
      FileList: [],
      Module: null,
      ModuleId: null,
      Particular: null,
      ParticularId: null,
      StDate: null
    }
    // this.employeeService.employeeDashBoard={
    //   IsEnteredLastJobCard:true,
    //   LastEnteredJobCard:null,
    // }
    this.LoadGraph();
    // this.employeeService.displayEmployeeDashBoard('EMPDASHBOARD');
    this.getPendingWorksHeader();
    this.commonService.storeloginUserId();
  }

  getPendingWorksHeader() {
    this._TesterdashboardService.getPendingWorks().subscribe(obj => {
      this._TesterdashboardService.AllPendingWork = obj
    })
  }
  getPendingWorksDet(obj: TesterDashboard) {
    this.Description = obj.Description;
    this.Remark = obj.Remark;
    this._TesterdashboardService.fetchProjectDet(obj).subscribe(obj1 => {
      this._TesterdashboardService.AllPendingWorkDet = obj1[0];
      this._TesterdashboardService.AllPendingWorkDet.StDate = obj.StDate
      this.FileList = obj1[0].FileList;
    });
  }
  downloadFileUrl(URL) {
    window.open(IMG_ROOT_URL + 'Images/CustomerLogo/' + URL, '_blank');
  }

  LoadGraph() {
    // chartdiv2
    var chart = this.AmCharts.makeChart("chartdiv2", {
      "type": "serial",
      "theme": "none",
      "dataProvider": [{
        "month": "Jan",
        "visits": 9
      }, {
        "month": "Feb",
        "visits": 10
      }, {
        "month": "Mar",
        "visits": 11
      }, {
        "month": "April",
        "visits": 12
      }, {
        "month": "May",
        "visits": 13
      }, {
        "month": "June",
        "visits": 14
      }, {
        "month": "July",
        "visits": 15
      }, {
        "month": "Aug",
        "visits": 16
      }, {
        "month": "Sept",
        "visits": 17
      }, {
        "month": "Oct",
        "visits": 18
      }, {
        "month": "Nov",
        "visits": 19
      }, {
        "month": "Dec",
        "visits": 20
      }],
      "valueAxes": [{
        "gridColor": "#FFFFFF",
        "gridAlpha": 0.2,
        "dashLength": 0
      }],
      "gridAboveGraphs": true,
      "startDuration": 1,
      "graphs": [{
        "balloonText": "[[category]]: <b>[[value]]</b>",
        "fillAlphas": 0.8,
        "lineAlpha": 0.2,
        "type": "column",
        "valueField": "visits"
      }],
      "chartCursor": {
        "categoryBalloonEnabled": false,
        "cursorAlpha": 0,
        "zoomable": false
      },
      "categoryField": "month",
      "categoryAxis": {
        "gridPosition": "start",
        "gridAlpha": 0,
        "tickPosition": "start",
        "tickLength": 20
      },
      "export": {
        "enabled": true
      }

    });
    // chartdiv3
    var chart = this.AmCharts.makeChart("chartdiv3", {
      "theme": "none",
      "type": "gauge",
      "axes": [{
        "topTextFontSize": 20,
        "topTextYOffset": 70,
        "axisColor": "#31d6ea",
        "axisThickness": 1,
        "endValue": 100,
        "gridInside": true,
        "inside": true,
        "radius": "50%",
        "valueInterval": 10,
        "tickColor": "#67b7dc",
        "startAngle": -90,
        "endAngle": 90,
        "unit": "%",
        "bandOutlineAlpha": 0,
        "bands": [{
          "color": "#0080ff",
          "endValue": 100,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0
        }, {
          "color": "#3cd3a3",
          "endValue": 0,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0
        }]
      }],
      "arrows": [{
        "alpha": 1,
        "innerRadius": "35%",
        "nailRadius": 0,
        "radius": "170%"
      }]
    });

    setInterval(randomValue, 2000);

    // set random value
    function randomValue() {
      var value = Math.round(Math.random() * 100);
      chart.arrows[0].setValue(value);
      chart.axes[0].setTopText(value + " %");
      // adjust darker band to new value
      chart.axes[0].bands[1].setEndValue(value);
    }

    // file upload

    function readURL(input) {
      if (input.files && input.files[0]) {

        var reader = new FileReader();

        reader.onload = function (e) {
          $('.image-upload-wrap').hide();

          $('.file-upload-image').attr('src', e.target.result);
          $('.file-upload-content').show();

          $('.image-title').html(input.files[0].name);
        };

        reader.readAsDataURL(input.files[0]);

      } else {
        removeUpload();
      }
    }

    function removeUpload() {
      $('.file-upload-input').replaceWith($('.file-upload-input').clone());
      $('.file-upload-content').hide();
      $('.image-upload-wrap').show();
    }
    $('.image-upload-wrap').bind('dragover', function () {
      $('.image-upload-wrap').addClass('image-dropping');
    });
    $('.image-upload-wrap').bind('dragleave', function () {
      $('.image-upload-wrap').removeClass('image-dropping');
    });
    // file upload end
  }
}
