import { branch } from 'src/app/Models/branch.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { BranchService } from 'src/app/Services/branch.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-payment-postphoned',
  templateUrl: './payment-postphoned.component.html',
  styleUrls: ['./payment-postphoned.component.css']
})
export class PaymentPostphonedComponent implements OnInit {

  paymentposphonedform: FormGroup
  Vno:number=0;
  submitted
  public errorMsg;

  validationMessages = {
    VNo: [
      { type: 'required', message: 'VNo Required' },
      { type: 'minlength', message: 'Min Length Required' },
      { type: 'pattern', message: 'Enter Valid VNo' }
    ],
    Customer: [
      { type: 'required', message: 'Customer Required' }
    ],
    Branch: [
      { type: 'required', message: 'Branch Required' }
    ],
    Date: [
      { type: 'required', message: 'Date Required' }
    ],
    PostPonedTo: [
      { type: 'required', message: 'PostPoned Date is Required' }
    ]
  }

  constructor(public fb: FormBuilder, 
              public _custService: CustomerService, 
              public _brnchService: BranchService,
              public commonService: CommonService) { }

  ngOnInit(): void {

    this.paymentposphonedform = this.fb.group({
      VNo: new FormControl(null, Validators.compose([
        Validators.pattern("^[0-9]+$"),
        Validators.required,
        Validators.minLength(1)
      ])),
      Date: new FormControl(null, Validators.compose([
        Validators.required
      ])),
      Customer: new FormControl(null, Validators.compose([
        Validators.required
      ])),
      Branch: new FormControl(null, Validators.compose([
        Validators.required
      ])),
      TotalDue: new FormControl(0),
      PostPonedTo: new FormControl(null, Validators.compose([
        Validators.required
      ])),
      Remark: new FormControl(null)
    })
    this.GetVNo();
    setTimeout(() => {
      this.Restform();
    }, 2000);
    this.LoadCustomers();
  }

  Restform() {
    this.GetVNo();
    this.paymentposphonedform.setValue({
      VNo:this.Vno,
      Date:null,
      Customer:null,
      Branch:null,
      TotalDue:null,
      PostPonedTo:null,
      Remark:null
    })
  }
 
  async GetVNo() {
    this.Vno= parseInt(await this.commonService.getVNo('PAYMENTPOSTPOND'));
  }

  LoadCustomers() {
  this._custService.FetchAllCustomer('ALL')
    .subscribe(data => this._custService.allCustomers = data,
      error => {
        this.errorMsg = error;
      });
  }

  loadCustomerWiseBranch() {
  console.log(this.paymentposphonedform.value.Customer)
  this.paymentposphonedform.value.Branch=null;
  this._brnchService.Fetch_BranchById(this.paymentposphonedform.value.Customer, 'CUSTOMERID')
    .subscribe(data => {
      this._brnchService.allBranchs = data
    },
      error => {
        this.errorMsg = error;
      });
  }

  onSubmit() {
    alert("submitt")
    console.log(this.paymentposphonedform.value)
  }

}
