import { Component, OnInit } from '@angular/core';
import { ModuleService } from 'src/app/Services/module.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from 'src/app/Services/project.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-module-form',
  templateUrl: './module-form.component.html',
  styleUrls: ['./module-form.component.css']
})
export class ModuleFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  constructor(public _modulService: ModuleService, 
              private toastr: ToastrService, 
              public _prjctService: ProjectService) { }

  ngOnInit(): void {
    this._modulService.formHideShow = 0;
    this._prjctService.fetchProject('ALL', 0)
      .subscribe(data => {
        this._prjctService.allProjects = data
      },
        error => {
          this.errorMsg = error;
        })
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._modulService.selectedModule = {
      Id: null,
      ModuleName: '',
      ModuleCode: '',
      Type:false
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._modulService.formHideShow = 0;
  }

  formValidation(): boolean {
    if (this._modulService.selectedModule.ModuleName != "" && this._modulService.selectedModule.ModuleCode != "")
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (form.valid && this.formValidation()) {
      this.isSubmit = true
      if (form.valid) {
        this._modulService.postModule(form.value)
          .subscribe(data => {
            this.isSubmit = false
            if (data.Status == 0) {
              this.toastr.success(data.Message, 'Module');
              this.resetForm(form);
              this.getAllModule();
              this._modulService.formHideShow = 0;
            }
            else
              this.toastr.warning(data.Message, 'Module');
          },
            error => {
              this.errorMsg = error;
              this.toastr.error('New Record Added Failure', 'Module');
            })
      }
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Module');
    }
  }

  getAllModule() {
    this._modulService.getModule().subscribe(data => this._modulService.allModules = data,
      error => {
        this.errorMsg = error;
      });
  }
}
