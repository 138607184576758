<div class="container-fluid" *ngIf="_Service.formHideShow!=0">
    <h2 class="heading_all">SERVER MASTER</h2>
    <br>
    <div id="form-wrap">
        <form #domainForm="ngForm" (ngSubmit)="onSubmit(domainForm)">
            <div class="col-md-12">
                <div class="form-group row">
                    <label>Reg.Date<span class="validation-error">*</span></label>
                    <input class="form-control" name="RegDate" #RegDate="ngModel" 
                        autocomplete="off" [(ngModel)]="_Service.selectedRecord.RegDate" bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">      
                </div> 
                <div class="form-group row">
                    <label>IP<span class="validation-error">*</span></label>
                    <input name="Server" #Server="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Server" placeholder="Server Name" required>
                    <div class="validation-error"
                        *ngIf="(Server.invalid && Server.touched)||(Server.invalid &&this.formValidation)">This Field is Required.
                    </div>
                </div>   
                <div class="form-group row">
                    <label>Specification<span class="validation-error">*</span></label>
                    <textarea style="width: 100%;" rows="1" name="Spec" #Spec="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Specification" placeholder="Specification" required></textarea>
                    <div class="validation-error"
                        *ngIf="(Spec.invalid && Spec.touched)||(Spec.invalid &&this.formValidation)">This Field is Required.
                    </div>
                </div>  
                <div class="form-group row">
                    <label>Location</label>
                    <input name="Location" #Location="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Location" placeholder="Location">
                </div>              
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label>Server Cost</label>
                            <input name="ServerCost" type="number" #ServerCost="ngModel" autocomplete="off"
                                [(ngModel)]="_Service.selectedRecord.ServerCost" placeholder="Server Cost">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="TermID">Terms<span class="validation-error">*</span></label>                              
                            <ng-select name="TermID" id="TermID" #TermID="ngModel" bindLabel="Name"
                                bindValue="Id" [items]="_CommonService.allTerms" [(ngModel)]="_Service.selectedRecord.TermID">
                            </ng-select>
                            <div class="validation-error" *ngIf="(TermID.invalid && TermID.touched)||(TermID.invalid && this.formValidation)">
                                This Field is Required.
                            </div>
                        </div>  
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label for="ServerExpiry">Server Expiry<span class="validation-error">*</span></label>
                            <input class="form-control" name="ServerExpiry" #ServerExpiry="ngModel" 
                            autocomplete="off" [(ngModel)]="_Service.selectedRecord.ServerExpiry" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
                        </div>
                    </div>
                </div>                    
                <div class="form-group">
                    <label for="ServerProviderID">Server Provider<span class="validation-error">*</span></label>                              
                    <ng-select name="ServerProviderID" id="ServerProviderID" #ServerProviderID="ngModel" bindLabel="Name"
                        bindValue="Id" [items]="_Service.allServerProvider" placeholder="Select Server Provider" required [(ngModel)]="_Service.selectedRecord.ServerProviderID">
                    </ng-select>
                    <div class="validation-error" *ngIf="(ServerProviderID.invalid && ServerProviderID.touched)||(ServerProviderID.invalid && this.formValidation)">
                        This Field is Required.
                    </div>
                </div>  
                <fieldset class="span6 reset">
                    <div class="form_row">
                        <div class="input" id="Project">
                            <label for="Project" style="color:#008080;">Project<span class="validation-error">*</span></label>
                            <ng-multiselect-dropdown id="ProjectID" name="ProjectID" [settings]="dropdownSettings" [data]="_Service.allProject" #ProjectID="ngModel" class="custom-multipledrpdown" placeholder="Select Project"
                                    [(ngModel)]="_Service.selectedRecord.ProjectList" required>
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </fieldset>  
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label>Capacity</label>
                            <input name="Capacity" #Capacity="ngModel" autocomplete="off"
                                [(ngModel)]="_Service.selectedRecord.Capacity" placeholder="Capacity">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label for="Date">Date</label>
                            <input class="form-control" name="Date" #Date="ngModel" 
                            autocomplete="off" [(ngModel)]="_Service.selectedRecord.Date" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
                        </div>
                    </div>
                </div>  
                <div class="form-group row">
                    <label>Reason</label>
                    <textarea style="width: 100%;" rows="1"  name="Reason" #Reason="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Reason" placeholder="Reason"></textarea>
                </div>
                <div class="form-group row">
                    <input name="Cancel" type="checkbox" #Cancel="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.IsCanceled" >
                    <label for="Date">Cancel</label>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(domainForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>