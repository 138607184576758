<form *ngIf="!this.employeeService.employeeDashBoard.IsEnteredLastJobCard">
    <div class="Atj_overlay " (click)="$event.stopPropagation(); closePopUp()">
        <div id="Atj_popup" class="Atj_popup ATJ-slide-bottom" (click)="$event.stopPropagation();">
            <a class="close" (click)="closePopUp()"></a>
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                <div>
                    <div class="row">
                        <div class="col-md-12">
                                <span class="popspan">Last WorkSheet Entered on {{this.employeeService.employeeDashBoard.LastEnteredJobCard}}
                                    <marquee class="popspan" *ngIf="!this.employeeService.employeeDashBoard.IsEnteredLastJobCard" direction="right"  behavior="alternate">
                                        Latest WorkSheet Not Entered,Please Update Your WorkSheet on {{this.employeeService.latestJobCardPendingDate | date :'MM-dd-yyyy'}}
                                    </marquee>
                                </span>
                            <button class="sim-button button1" style="float:right;cursor: pointer;width:45px;color:white;" (click)="redirectJobCard()">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
