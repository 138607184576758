import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-feed-back-master',
  templateUrl: './customer-feed-back-master.component.html',
  styleUrls: ['./customer-feed-back-master.component.css']
})
export class CustomerFeedBackMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
