<div class="container-fluid" *ngIf="_Service.formHideShow!=0">
    <h2 class="heading_all">DOMAIN MASTER</h2>
    <br>
    <div id="form-wrap">
        <form #domainForm="ngForm" (ngSubmit)="onSubmit(domainForm)">
            <div class="col-md-12">
                <div class="form-group row">
                    <label>Domain Name<span class="validation-error">*</span></label>
                    <input name="DomainName" #DomainName="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.DomainName" placeholder="Domain Name" required>
                    <div class="validation-error"
                        *ngIf="(DomainName.invalid && DomainName.touched)||(DomainName.invalid &&this.formValidation)">This Field is Required.</div>
                </div>             
                <div class="form-group">
                    <label for="ServerID">Server<span class="validation-error">*</span></label>                              
                    <ng-select name="ServerID" id="ServerID" #ServerID="ngModel" bindLabel="Name"
                        bindValue="Id"  [items]="_Service.allServer" placeholder="Select Server" required [(ngModel)]="_Service.selectedRecord.ServerID">
                    </ng-select>
                    <div class="validation-error" *ngIf="(ServerID.invalid && ServerID.touched)||(ServerID.invalid && this.formValidation)">
                        This Field is Required.
                    </div>
                </div>             
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label>Domain Cost</label>
                            <input name="DomainCost" type="number" #DomainCost="ngModel" autocomplete="off"
                                [(ngModel)]="_Service.selectedRecord.DomainCost" placeholder="DomainCost">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label for="DomainRegDate">Reg.Date<span class="validation-error">*</span></label>
                            <input class="form-control" name="DomainRegDate" #DomainRegDate="ngModel" 
                            autocomplete="off" [(ngModel)]="_Service.selectedRecord.DomainRegDate" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        </div> 
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label for="DomainExpDate">Exp.Date<span class="validation-error">*</span></label>
                            <input class="form-control" name="DomainExpDate" #DomainExpDate="ngModel" 
                                autocomplete="off" [(ngModel)]="_Service.selectedRecord.DomainExpDate" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" required>
                        </div>   
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label>SSL Cost</label>
                            <input name="SSLCost" type="number" #SSLCost="ngModel" autocomplete="off"
                                [(ngModel)]="_Service.selectedRecord.SSLCost" placeholder="SSL Cost">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label for="SSLRegDate">Reg.Date</label>
                            <input class="form-control" name="SSLRegDate" #SSLRegDate="ngModel" 
                            autocomplete="off" [(ngModel)]="_Service.selectedRecord.SSLRegDate" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group row">
                            <label for="SSLExpDate">Exp.Date</label>
                            <input class="form-control" name="SSLExpDate" #SSLExpDate="ngModel" 
                                autocomplete="off" [(ngModel)]="_Service.selectedRecord.SSLExpDate" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }">
                        </div>
                    </div>
                </div>  
                <div class="form-group">
                    <label for="ServerID">Customer<span class="validation-error">*</span></label>                              
                    <ng-select name="CustomerID" id="CustomerID" #CustomerID="ngModel" bindLabel="Name"
                        bindValue="Id"  [items]="_Service.allCustomer" placeholder="Select Customer" required [(ngModel)]="_Service.selectedRecord.CustomerID">
                    </ng-select>
                    <div class="validation-error" *ngIf="(CustomerID.invalid && CustomerID.touched)||(CustomerID.invalid && this.formValidation)">
                        This Field is Required.
                    </div>
                </div>              
                <div class="form-group row">
                    <label>Notes</label>
                    <textarea style="width: 100%;" rows="1"  name="Notes" #Notes="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Notes" placeholder="Notes"></textarea>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(domainForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>