import { from } from 'rxjs';
import { error } from '@angular/compiler/src/util';
import { ReasonmasterService } from 'src/app/Services/reasonmaster.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { NgForm } from '@angular/forms';
import { CloudChargeService } from 'src/app/Services/cloud-charge.service';
import { CommonService } from 'src/app/Services/common.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cscancellation-form',
  templateUrl: './cscancellation-form.component.html',
  styleUrls: ['./cscancellation-form.component.css']
})
export class CscancellationFormComponent implements OnInit {

  public errorMsg:string

  constructor(public cloudChargeService:CloudChargeService,
              public commonService:CommonService,
              public dealerService:DealerService,
              public customerService:CustomerService,
              public reasonmasterService:ReasonmasterService,
              private toastrService:ToastrService) { }

  ngOnInit(): void {
    this.cloudChargeService.formHideShow = 0;
    this.cloudChargeService.pageIndex = 1;
    this.commonService.pageIndex = 1;
    this.resetForm();
  }

  async GetVNo() {
    this.cloudChargeService.selectedCSCancellation.VNo = parseInt(await this.commonService.getVNo('CSCANCELLATION'));
  }

  resetForm(form?:NgForm)
  {
    if (form != null)
      form.reset();
    this.cloudChargeService.selectedCSCancellation={
      Id:0,
      VNo:null,
      Date:new Date(),
      DealerId:null,
      CustomerId:null,
      Customer:'',
      AffectedFrom:null,
      ReasonId:null,
      Remark:''
    }
    this.GetVNo();
    this.LoadDealer();
    this.loadDealerWiseCustomers();
    this.loadReason();
  }

  LoadDealer() {
    this.dealerService.FetchDealer('ALL')
      .subscribe(data => {
        this.dealerService.allDealers = data
      })
  }

  loadDealerWiseCustomers() {
  this.cloudChargeService.selectedCSCancellation.CustomerId=null;
  this.customerService.FetchAllCustomer('ALL')
    .subscribe(data => { 
    if(this.cloudChargeService.selectedCSCancellation.DealerId > 0)
    {
      this.customerService.allCustomers  = data.filter(e=>
        e.DealerId==this.cloudChargeService.selectedCSCancellation.DealerId
      )
    } 
    else
      this.customerService.allCustomers=data   
    },
      error => {
        this.errorMsg = error;
      });
  }

  loadReason()
  {
    this.reasonmasterService.getReasonMaster('All').subscribe(
      data=>{
        this.reasonmasterService.allReasonMaster=data
      },
      error=>{this.errorMsg=error}
    );
  }

  formValidation(): boolean {
    if (this.cloudChargeService.selectedCSCancellation.DealerId != null && this.cloudChargeService.selectedCSCancellation.CustomerId != null && this.cloudChargeService.selectedCSCancellation.AffectedFrom !=null && this.cloudChargeService.selectedCSCancellation.ReasonId !=null)
      return true;
    else
      return false;
  }

  onSubmit(form:NgForm)
  {
    if(this.formValidation && form.valid)
    {
      this.cloudChargeService.CsCancellationTrans(form.value,this.cloudChargeService.selectedCSCancellation.VNo).subscribe(
        data=>{
            if(data.Status==200)
             {
               this.cloudChargeService.formHideShow=0;
               this.resetForm(form);
               this.toastrService.success(data.Message,'CsCancellation')
               this.cloudChargeService.getCsCancellation().subscribe(data => this.cloudChargeService.allCSCancellation = data,
                error => {
                  this.errorMsg = error;
                });
             }
             else
              this.toastrService.error(data.Message,'CsCancellation')
        },error=>{this.errorMsg=error
          this.toastrService.error(this.errorMsg,'CsCancellation')
        });
    }
    else
    {
      this.toastrService.warning('Please fill all mandatory fields.', 'CsCancellation');
      return;
    }
  }

  backList()
  {
    this.cloudChargeService.formHideShow=0;
    this.resetForm();
  }

}

