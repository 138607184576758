import { Injectable } from '@angular/core';
import { customer } from '../Models/customer.model';
import { ROOT_URL } from '../Models/Config';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/observable';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { AppConstants } from '../Models/constants';
import { softwareRegistration } from '../Models/softwareRegistration.model';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  selectedCustomer: customer;
  customerList: customer[];
  formHideShow = 0;
  formHidePassword = false;
  allCustomers: customer[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  NameFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  selectedAMCRenewal: softwareRegistration

  imageUrl: string = AppConstants.tockenURL + "Images/Logo/default.png";
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  UploadImage(fileToUpload: File) {

    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload, fileToUpload.name);
    return this.http.post<customer>(ROOT_URL + 'Customer/UploadImage', formData)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  ClearFilter() {
    this.isFiltering = false;
    this.NameFilter = '';
  }

  postCustomer(cust: customer, fileToUpload: File) {
    // const headers = new HttpHeaders().set('content-type', 'application/json');.
    console.log('Customer:', cust)
    let headers = new HttpHeaders().set('contentType', 'false');
    var body = {
      Id: cust.Id,
      CountryId: cust.CountryId,
      DealerId: cust.DealerId,
      Name: cust.Name,
      Addr1: cust.Addr1,
      Addr2: cust.Addr2,
      Addr3: cust.Addr3,
      CustEmail: cust.CustEmail,
      CustPhone: cust.CustPhone,
      CustFax: cust.CustFax,
      ContactPerson: cust.ContactPerson,
      ContactPhone: cust.ContactPhone,
      ContactEmail: cust.ContactEmail,
      URL: cust.URL,
      LogoURL: cust.LogoURL,
      NoOfBranches: cust.NoOfBranches,
      Code: cust.Code,
      BranchName: cust.BranchName,
      RouteId: cust.RouteId
    }
    const formData: FormData = new FormData();
    // if (fileToUpload) {
    //   for (var i = 0; i < fileToUpload.length; i++) {
    //     formData.append('Document', fileToUpload[i]);
    //   }
    // } 
    formData.append('obj', JSON.stringify(body));
    formData.append('Image', fileToUpload);
    // return this.http.post<customer>(ROOT_URL + 'Customer/PostCustomer', body, {
    //   headers
    // })
    return this.http.post<any>(ROOT_URL + 'Customer/PostCustomer', formData, {
      headers,
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  FetchAllCustomer(transMode: string): Observable<customer[]> {
    let params = new HttpParams();
    params = params.append('TransMode', transMode)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<customer[]>(ROOT_URL + 'Customer/FetchAllCustomer', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  FetchAllCustomerById(transMode: string, Id: number, SEID?: number): Observable<customer[]> {
    let params = new HttpParams();
    params = params.append('TransMode', transMode)
    params = params.append('Id', Id.toString())
    params = params.append('SEID', SEID ? SEID.toString() : '0')

    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<customer[]>(ROOT_URL + 'Customer/FetchAllCustomerById', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  deleteCustomer(DeleteID, imageUrl) {
    let params = new HttpParams();
    params = params.append('ID', DeleteID)
    params = params.append('ImageUrl', imageUrl)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + 'Customer/DeleteCustomer', {}, {
      headers, params
    })
    // return this.http.post<any>(ROOT_URL + 'Customer/DeleteCustomer,?ID=' + DeleteID+'&ImageUrl'+imageUrl)
  }
}
