import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-grid-pagination',
  templateUrl: './grid-pagination.component.html',
  styleUrls: ['./grid-pagination.component.css']
})
export class GridPaginationComponent implements OnInit {
  constructor(public _commonService: CommonService) { }
  @Output() pageIndexValue: EventEmitter<number> = new EventEmitter();
  ngOnInit() {
  }
  PageIndexClick(i) {
    this._commonService.pageIndex = i;
    this.pageIndexValue.emit(this._commonService.pageIndex);
  }
  pageIndexVisible(n: number): boolean {

    if (this._commonService.pageIndex <= 3) {
      if (n <= 5) {
        return true;
      }
      return false;
    }
    else if ((this._commonService.totalPageCount - 3) < this._commonService.pageIndex) {

      if (n > this._commonService.totalPageCount - 5) {
        return true;
      }
      return false;
    }
    else if (this._commonService.pageIndex > 3) {

      if ((this._commonService.pageIndex - 3 < n) && (this._commonService.pageIndex + 3 > n))
        return true;
    }
  }
  pre() {
    if (this._commonService.pageIndex > 1) {
      this._commonService.pageIndex = this._commonService.pageIndex - 1;
      this.pageIndexValue.emit(this._commonService.pageIndex);
    }
  }
  first() {
    if (this._commonService.pageIndex > 1) {
      this._commonService.pageIndex = 1;
      this.pageIndexValue.emit(this._commonService.pageIndex);
    }
  }
  next() {
    if (this._commonService.totalPageCount > this._commonService.pageIndex) {
      this._commonService.pageIndex = this._commonService.pageIndex + 1;
      this.pageIndexValue.emit(this._commonService.pageIndex);
    }
  }
  last() {
    if (this._commonService.totalPageCount > this._commonService.pageIndex) {
      this._commonService.pageIndex = this._commonService.totalPageCount;
      this.pageIndexValue.emit(this._commonService.pageIndex);
    }
  }
}
