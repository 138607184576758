import { Chart } from 'chart.js';
import { Component, OnInit } from '@angular/core';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { CommonService } from 'src/app/Services/common.service';
import { error } from 'protractor';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit {
  public errorMsg;

  constructor(public PieAmCharts: AmChartsService,
              public _CommonService: CommonService) { }

  ngOnInit(): void {
    this.getBacklogs();
  }

  getBacklogs()
  {
    this._CommonService.getBacklogs().subscribe(
      data=>{
        this._CommonService.backlogs=data
        if(this._CommonService.backlogs.length > 0)
         this.loadBacklog();
      },
      error => {
        this.errorMsg = error;
      });    
  }

  loadBacklog() {
    var chart = this.PieAmCharts.makeChart("chartdiv", {      
      "type": "pie",
      "theme": "none",
      "fontSize": 10,
      "radius": 100,
      "autoMargins": false,
      "labelRadius": -13,
      "hideCredits":true,
      "dataProvider":this._CommonService.backlogs,
      "valueField": "WorkCount",
      "titleField": "Work",     
      "balloon": {
        "fixedPosition": true
      },
      "labelText": "[[Work]]([[WorkCount]])",
      "export": {
        "enabled": true
      }
    }); 
  }
  ngDestrory()
  {
    this.PieAmCharts.destroyChart(this.PieAmCharts);
  }
}
