import { Backloghs } from './../../../../Models/Backlogs';
import { Component, OnInit } from '@angular/core';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { CommonService } from 'src/app/Services/common.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { SEDashBoardCustReq } from 'src/app/Models/Dashboard.Model';
import { NgForm } from '@angular/forms';
import { EmployeeService } from 'src/app/Services/employee.service';
import { JobCardHoldingReason } from 'src/app/Models/job-card';
import { WorkSortingService } from 'src/app/Services/work-sorting.service';
import { DevdashboardService } from '../../../../Services/devdashboard.service';
import { WorkSorting, WorkSortingAssigning } from 'src/app/Models/WorkSorting.model';
import { IMG_ROOT_URL } from 'src/app/Models/Config';
import { error } from 'protractor';

@Component({
  selector: 'app-support-leader-home',
  templateUrl: './support-leader-home.component.html',
  styleUrls: ['./support-leader-home.component.css']
})
export class SupportLeaderHomeComponent implements OnInit {

  public errorMsg;
  PriorityList: any;
  TypeList: any;
  StatusList: any;
  FileList: string[] = [];
  DealerId: number;
  AmcORChargable = 0;
  backlogs:any

  constructor(public _CustomerrequirementService: CustomerrequirementService,
    public _CommonService: CommonService,
    public _WorkSortingService: WorkSortingService,
    public _BranchService: BranchService,
    public _serviceEngineerDBService: ServiceEngineerDBService,
    public _EmployeeService: EmployeeService,
    public _CustomerService: CustomerService,
    public _DevdashboardService:DevdashboardService) { }

  holdReason: JobCardHoldingReason;
  holdingReasonList = [
    { Id: 1, Name: 'Meeting With' },
    { Id: 2, Name: 'Expecting Reasult From' },
    { Id: 3, Name: 'Other Emergency Work' },
    { Id: 4, Name: 'Clarification' },
    { Id: 5, Name: 'Service Charge Confirmation' },
  ];

  async ngOnInit(): Promise<void> {
    this.AmcORChargable = 0;
    this.holdReason = { Activity: 0, Date: null, Emp_ID: 0, ExpectingResultFrom: 0, HoldingReason: null, ID: 0, JONO: 0, MettingWith: 0, OtherEmergencyWork: 0, Reason: '', Remark: '' }
    this.Reset();
    this.LoadCustomers();
    this.fillServiceEngineer();
    this._CommonService.displayKeyExpiryCount();
    this.PriorityList = this._CommonService.GetPriorityList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusList = this._CommonService.GetStatusFullList();
    this.LoadDealerDashboardTodaysSchdule();

    this._CommonService.LoginId = parseInt(await this._CommonService.getLoginedUserId());
    this.DealerId = parseInt(await this._CommonService.getDealerId('WorkSorting', this._CommonService.LoginId, 'SupportLeader'));
    this.getPendingWorksHeader();
    this.displayFrequentlyVisitCustomer();
  }

  Reset(form?: NgForm) {
    this._BranchService.selectedBranch = {
      Addr1: null,
      Addr2: null,
      Addr3: null,
      BranchEmail: null,
      BranchFax: null,
      BranchName: null,
      Customer:null,
      BranchPhone: null,
      ContactEmail: null,
      ContactPerson: null,
      ContactPhone: null,
      CountryId: null,
      CustomerId: null,
      DealerId: null,
      Id: null,
      RouteId: null,
      UniqueBrachId: '',
    }
    this._serviceEngineerDBService.selectedDate = new Date()
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    this._CustomerService.allCustomers = []
    this._CustomerrequirementService.allSE = [];
    this._WorkSortingService.selectedWorkSortHead =
    {
      ID: 0,
      SEID: 0,
      RDate: null,
      RefNo: 0,
      BranchId: 0,
      LastServiceMode: '',
      LastServiceSE: '',
      OnSiteCount: '',
      RemoteCount: '',
      ProjectId: 0,
      ModeOfReg: 0,
      CustomerId: 0,
      PriorityId: 0,
      TypeId: 0,
      Vtype: 0,
      Remark: null,
      InstallationDate: null,
      Modules: '',
      DealerId: null,
      CustomerName: '',
      BranchName: '',
      EmpName: '',
      ProjectName: '',
      AMCDate: null,
      ExpDate: null,
      OD_EDate: 0,
      OD_PDDate: 0,
      PD_Date: null,
      CurrentStatus: ''
    }
    this._CommonService.KeyExpiryCount={
      LicenseKey:0,AMC:0,CloudServer:0,CustomerOutstanding:0
    }
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('ALL')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_EmployeeById('SERVICEENG', 0)
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  LoadDealerDashboardTodaysSchdule() {
    this._CustomerrequirementService.DealerDashBoardTodaysSchdule = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("SLTODAYS", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.DealerDashBoardTodaysSchdule = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  p: number = 1;
  LoadDealerDashboardCustReqData() {
    this._CustomerrequirementService.SelectedDealerDashBoardCustReq = []
    if (this._serviceEngineerDBService.selectedDate != null) {
      this._CustomerrequirementService.GetDealerDashboardCustReqData("SLID", this.convert(this._serviceEngineerDBService.selectedDate))
        .subscribe(data => {
          this._CustomerrequirementService.SelectedDealerDashBoardCustReq = data
        },
          error => {
            this.errorMsg = error;
          });
    }
  }

  SelectedIndexRefNo: number = null;
  SelectedIndexVtype: number = null;
  showForEdit(Backlog: SEDashBoardCustReq) {
    this.SelectedIndexRefNo = Backlog.RefNo;
    this.SelectedIndexVtype = Backlog.Vtype;
    this._serviceEngineerDBService.AllWorkSortingDet = []
    this._serviceEngineerDBService.getPendingWorksDet(Backlog.RefNo, Backlog.Vtype, Backlog.DealerId, 'COMMON').subscribe(data => {
      this._serviceEngineerDBService.AllWorkSortingDet = data
    })
    this._BranchService.Fetch_BranchById(Backlog.BranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.selectedBranch = obj[0]
    })
  }

  popUpIndex: number = 0;
  ClarificationFrom: any;
  openHoldPopUp(Backlog: SEDashBoardCustReq) {
    this._EmployeeService.Fetch_EmployeeById('ELSE', 0).subscribe(data => {
      this.ClarificationFrom = data;
    });
    this._serviceEngineerDBService.getHoldReason(Backlog.RefNo, Backlog.Vtype, Backlog.DealerId, 'HOLDREASON').subscribe(data => {
      this.holdReason = data;
    })
    this.popUpIndex = 1;
  }

  closePopUp() {
    this.popUpIndex = 0;
    this._CommonService.expDetailsPopup=0;
  }

  getPendingWorksDet(obj: WorkSorting) {
    this._WorkSortingService.selectedCheckAll = false
    this.AmcORChargable = 0;
    this._WorkSortingService.AllWorkSortingDet = []
    this._WorkSortingService.selectedWorkSortHead = obj;
    this.AmcORChargable = new Date(obj.AMCDate) >= new Date() ? 1 : 2;

    this._CustomerService.FetchAllCustomerById('CUSTOMERID', obj.CustomerId).subscribe(obj => {
      this._CustomerService.selectedCustomer = obj[0]
    })

    this._BranchService.Fetch_BranchById(obj.BranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.selectedBranch = obj[0]
    })

    this._WorkSortingService.getPendingWorksDet(obj.DealerId, obj.RefNo, obj.Vtype).subscribe(obj => {
    this._WorkSortingService.AllWorkSortingDet = Object.assign([], obj.AssignedHisList)

      this._WorkSortingService.selectedWorkSortHead.Remark = obj.HdrRemark
      this._WorkSortingService.RemarkHistory = obj.RemarkHistory
      if (this._WorkSortingService.AllWorkSortingDet != null) {
        this._WorkSortingService.AllWorkSortingDet.forEach(obj => {
          this.FileList = obj.FileList;
          obj.ExpDate = new Date(obj.ExpDate)
          if (obj.StatusId == 9) {
            obj.AssTo = 0
            obj.ExpDate = null
          }
          if (obj.AssTo == 0)
            obj.ExpDate = null
        })
      }
    })
  }

  sort(colName: string) {
    if (colName == this._WorkSortingService.orderByColName) {
      this._WorkSortingService.orderByDir = !this._WorkSortingService.orderByDir;
    }
    this._WorkSortingService.orderByColName = colName;
  }

  getPendingWorksHeader() {
    this._WorkSortingService.getPendingWorksHeader(this.DealerId, 'ALL').subscribe(obj => {
      this._WorkSortingService.AllWorkSorting = obj
    })
  }

  displayFrequentlyVisitCustomer(){
    this._DevdashboardService.GetFrequentlyVisitCustomer().subscribe(
      data=>{
        this._DevdashboardService.AllFrequentlyVisitCustomer=data;
      },
      error => {
        this.errorMsg = error;
      });
  }

  downloadFileUrl(URL) {
    window.open(IMG_ROOT_URL + 'Images/SoftwareRegistration/' + URL, '_blank');
  }
}
