import { Component, OnInit } from '@angular/core';
import { AttandanceregisterService } from 'src/app/Services/attandanceregister.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.css']
})

export class TimeSheetComponent implements OnInit {

  constructor(public _AttandanceregisterService: AttandanceregisterService, 
              public _common: CommonService) { }
  lastIndex: number = 0;
  public errorMsg;

  ngOnInit(): void {
    let month=(new Date().getMonth()) + 1; 
    this._AttandanceregisterService.selectedDate = (new Date().getFullYear().toString()+'-'+ (month.toString().length==1 ?'0'+month:month));
    this.getdata();
  }
 
  getdata() {
    this._common.isLoading = true;
    this._AttandanceregisterService.getTimeSheetData().subscribe(data => {
      this._common.isLoading = false;
      this.lastIndex = data.tableDetails.length > 0 ? data.tableDetails.length : 0;
      this._AttandanceregisterService.attandanceDetails = data;
    },
      error => {
        this.errorMsg = error;
        this._common.isLoading = false;
      });
  }
}
