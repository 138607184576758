import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { Observable } from 'rxjs';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { GeneralSettings, TimeSettings } from '../Models/TimeSettings.model';

@Injectable({
  providedIn: 'root'
})
export class TimeSettingsService {

  selectedTimeSettings: TimeSettings;
  selectedGeneralSettings:GeneralSettings;

  constructor(private http: HttpClient, 
              public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  postTimeSettings(): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      DDU: this.selectedTimeSettings.DDU,
      DPU: this.selectedTimeSettings.DPU,
      DDV: this.selectedTimeSettings.DDV,
      DPV: this.selectedTimeSettings.DPV,
      DDC: this.selectedTimeSettings.DDC,
      DPC: this.selectedTimeSettings.DPC,

      EDU: this.selectedTimeSettings.EDU,
      EPU: this.selectedTimeSettings.EPU,
      EDV: this.selectedTimeSettings.EDV,
      EPV: this.selectedTimeSettings.EPV,
      EDC: this.selectedTimeSettings.EDC,
      EPC: this.selectedTimeSettings.EPC,

      MDU: this.selectedTimeSettings.MDU,
      MPU: this.selectedTimeSettings.MPU,
      MDV: this.selectedTimeSettings.MDV,
      MPV: this.selectedTimeSettings.MPV,
      MDC: this.selectedTimeSettings.MDC,
      MPC: this.selectedTimeSettings.MPC,

      TDU: this.selectedTimeSettings.TDU,
      TPU: this.selectedTimeSettings.TPU,
      TDV: this.selectedTimeSettings.TDV,
      TPV: this.selectedTimeSettings.TPV,
      TDC: this.selectedTimeSettings.TDC,
      TPC: this.selectedTimeSettings.TPC,
    }
    const finalbody={
      TimeSettings:body,
      DefaultDate: this.selectedGeneralSettings.DefaultDate,
      KeyExpiryRemainderDays: this.selectedGeneralSettings.KeyExpiryRemainderDays,
      DecimalPlaces: this.selectedGeneralSettings.DecimalPlaces,
      PeriodStartDate: this.selectedGeneralSettings.PeriodStartDate,    
      AdministrationPercentage: this.selectedGeneralSettings.AdministrationPercentage,
    }
    return this.http.post<any>(ROOT_URL + 'Common/TimeSettings_Trans', finalbody, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getTimeSettings(): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<any>(ROOT_URL + 'Common/TimeSettings_Fetch', {
      headers,
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
