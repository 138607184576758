import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ROOT_URL } from '../Models/Config';
import { customer } from '../Models/customer.model';
import { employee } from '../Models/employee.model';
import { ProjectAllotment } from '../Models/ProjectAllotment';
import { GlobalErrorHandlerService } from './global-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectAllotmentService {

  constructor(
    private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }

  allLeaders: employee[]
  allCustomers: customer[]
  allTeamMembers: employee[]
  selectedProject: ProjectAllotment

  clearValues() {
    this.selectedProject = {
      Id: null,
      CustomerId: null,
      Date: null,
      EndDate: null,
      HasTeamMembers: false,
      HasProject: false,
      LeaderId: null,
      ProjectId: null,
      RefNo: '',
      StartDate: null,
      TeamMembers: []
    }
  }

  saveItem(Values: ProjectAllotment): Observable<any> {
    var body = {
      Id: Values.Id,
      RefNo: Values.RefNo,
      Date: Values.Date,
      LeaderId: Values.LeaderId,
      ProjectId: Values.ProjectId,
      CustomerId: Values.CustomerId,
      HasTeamMembers: Values.HasTeamMembers,
      TeamMembers: Values.TeamMembers,
      HasProject: Values.HasProject,
      StartDate: Values.StartDate,
      EndDate: Values.EndDate
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + 'ProjectAllotment/InsUpdate', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

}
