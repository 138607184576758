<div class="container-fluid" *ngIf="_typeService.formHideShow!=0">
    <h2 class="heading_all">BusinessType</h2>
    <br>
    <div id="form-wrap">
        <form #businessTypeForm="ngForm" (ngSubmit)="onSubmit(businessTypeForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_typeService.selectedBusinessType.Id">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Code<span class="validation-error">*</span></label>
                    <input name="BTypeCode" #BTypeCode="ngModel" autocomplete="off"
                        [(ngModel)]="_typeService.selectedBusinessType.BTypeCode" placeholder="" required>
                    <div class="validation-error"
                        *ngIf="(BTypeCode.invalid && BTypeCode.touched)||(BTypeCode.invalid &&this.FormValidation)">This
                        Field is
                        Required.</div>

                </div>
                <div class="form-group row">
                    <label>BusinessType<span class="validation-error">*</span></label>

                    <input name="BType" #BType="ngModel" autocomplete="off"
                        [(ngModel)]="_typeService.selectedBusinessType.BType" placeholder="" required>
                    <div class="validation-error"
                        *ngIf="(BType.invalid && BType.touched)||(BType.invalid &&this.FormValidation)">This Field is
                        Required.</div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" [disabled]="this.isSubmit" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button2"
                            (click)="resetForm(businessTypeForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button style="width: 99%; margin-top: 25px;" type="button" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- </div> -->
</div>