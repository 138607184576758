import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/Services/project.service';
import { ToastrService } from 'ngx-toastr';
import { project } from 'src/app/Models/project.model';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  public errorMsg;
  public term: any = '';
  popVisible: boolean = false;
  deleteId: number = 0;

  constructor(public _prjctService: ProjectService,
    public _CommonService: CommonService,
    public _toastrService: ToastrService) { }

  ngOnInit(): void {
    this._prjctService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._prjctService.fetchProject('ALL', 0)
      .subscribe(data => this._prjctService.allProjects = data,
        error => {
          this.errorMsg = error;
        })
    this._prjctService.formHideShow = 0;
  }

  toggleFilter() {
    this._prjctService.isFiltering = this._prjctService.isFiltering ? false : true;
    if (!this._prjctService.isFiltering) {
      this._prjctService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._prjctService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._prjctService.orderByColName) {
      this._prjctService.orderByDir = !this._prjctService.orderByDir;
    }
    this._prjctService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  addList() {
    this._prjctService.formHideShow = 1;
  }
  showForEdit(prjct: project) {
    this._prjctService.selectedProject = Object.assign({}, prjct);
    this._prjctService.formHideShow = 1;
  }

  onDelete(Id: number) {
    this.popVisible = true;
    this.deleteId = Id;
  }

  dialogResult($event) {
    this.popVisible = false;
    if ($event == true && this.deleteId != 0) {
      this.exeDelete(this.deleteId)

    }
    this.deleteId = 0;
  }

  exeDelete(DeleteId: number) {
    this._prjctService.removeProject(DeleteId)
      .subscribe(data => {
        if (data.Status === 0) {
          this._toastrService.success(data.Message, "Project");
          this._prjctService.fetchProject('ALL', 0)
            .subscribe(data => {
              this._prjctService.allProjects = data;
            })
        }
        else
          this._toastrService.warning(data.Message);
      });
  }
}
