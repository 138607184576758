import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { WorkAllocation, ServiceEngWorks, ServiceEngWorkForecastingHdr, ServiceEngWorksForecast, JobOrderHdr } from '../Models/WorkAllocation.model';
import { Observable } from 'rxjs';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { RemarkHistory, WorkSortingAssigning } from '../Models/WorkSorting.model';
import { dealer } from '../Models/dealer.Model';
import { ActivityRemarks } from '../Models/ActivityHold';
import * as CryptoJS from 'crypto-js';
import { Common } from '../Models/Response';
@Injectable({
  providedIn: 'root'
})
export class WorkAllocationService {

  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  AllWorkAllcation: WorkAllocation[]
  AllServiceEngWorks: ServiceEngWorks[]
  //Detpart
  AllWorkAllocationDet: WorkSortingAssigning[];
  CheckedWorkAllocationDet: WorkSortingAssigning[];
  AllServiceEngWorksForecast: ServiceEngWorksForecast[];
  SelectedJobOrderHdr: JobOrderHdr;
  SelectedServiceEngWorkForecastingHdr: ServiceEngWorkForecastingHdr;
  selectedWorkAllocationHdr: WorkAllocation;
  WorkAllocationDet: string
  RemarkHistory: RemarkHistory[]
  DealerId: number

  //Filteration 
  pageIndex: number = 0;
  isFiltering: boolean = false;

  RDateFilter: string = '';
  RefNoFilter: string = '';
  CustomerFilter: string = '';
  MORegFilter: string = '';
  PriorityFilter: string = '';
  AttSEFilter: string = '';
  RemarksFilter: string = '';
  ExpDateFilter: string = '';
  VTypeFilter: String = "";
  orderByDir: boolean = false;
  orderByColName: string = '';
  jobBatch:Common[]=[];

  ClearFilter() {
    this.isFiltering = false;
    this.RDateFilter = '';
    this.RefNoFilter = '';
    this.CustomerFilter = '';
    this.MORegFilter = '';
    this.PriorityFilter = '';
    this.AttSEFilter = '';
    this.RemarksFilter = '';
    this.ExpDateFilter = '';
    this.VTypeFilter = "";
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    let result = [date.getFullYear(), mnth, day].join("-");
    return result + ' ' + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  getPendingWorksHeader(SupportLeaderId: number, TransMode: string): Observable<WorkAllocation[]> {
    return this.http.get<any>(ROOT_URL + 'WorkAllocation/getWorkAllocationList?Id=' + SupportLeaderId + '&TransMode=' + TransMode)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  LoadSupportEngWorksByDate(newDate: any): Observable<any> {
    return this.http.get<any>(ROOT_URL + 'WorkAllocation/LoadSupportEngWorksByDate?SelectedDate=' + newDate)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getWorkAllocationDet(obj: WorkAllocation): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      DealerId: obj.DealerId,
      VType: obj.VType,
      RefNo: obj.RefNo,
      CustomerId: obj.CustomerId
    }
    return this.http.post<any>(ROOT_URL + 'WorkAllocation/getWorkAllocationDet', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  InsWorkAllocation(): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.AllWorkAllcation.forEach(obj => { obj.ExpDate = obj.ExpDateToBind })

    return this.http.post<any>(ROOT_URL + 'WorkAllocation/InsWorkAllocation?workAlloc=' + JSON.stringify(this.AllWorkAllcation.filter(obj => obj.AssTo != 0)), '', {
      headers,
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getServiceEngWorks(DealerId: string, EmployeeType: number): Observable<ServiceEngWorksForecast[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    // var body = {
    //   Date: this.SelectedServiceEngWorkForecastingHdr.Date,
    //   From: this.SelectedServiceEngWorkForecastingHdr.From,
    //   FromTime: this.SelectedServiceEngWorkForecastingHdr.FromTime,
    //   JobAssignType: this.SelectedServiceEngWorkForecastingHdr.JobAssignType,
    //   ServiceMode: this.SelectedServiceEngWorkForecastingHdr.ServiceMode,
    //   To: this.SelectedServiceEngWorkForecastingHdr.To,
    //   ToTime: this.SelectedServiceEngWorkForecastingHdr.ToTime,
    //   RefNo: this.selectedWorkAllocationHdr.RefNo,
    //   Vtype: this.selectedWorkAllocationHdr.VType,
    //   DealerId: DealerId
    // }
    // let params = new HttpParams();
    // params.append('DealerId', DealerId)
    return this.http.get<ServiceEngWorksForecast[]>(ROOT_URL + `WorkAllocation/getServiceEngWorks?DealerId=${DealerId}&EmployeeType=${EmployeeType}`, {
      headers,
      // params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  SaveJobOrder(JoNo: number, TransMode: string): Observable<WorkSortingAssigning[]> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    if (TransMode == 'DELETE')
      this.WorkAllocationDet = JSON.stringify(this.SelectedJobOrderHdr.WorkSortingAssigning)
    else
      this.WorkAllocationDet = JSON.stringify(this.CheckedWorkAllocationDet)

    let obj: WorkSortingAssigning[]
    var body = {
      TransMode: TransMode,
      JoNo: JoNo,
      IssueDate: Date.now,
      SEs: JSON.stringify(this.AllServiceEngWorksForecast.filter(obj => obj.checkStatus == true)),
      From: this.convert(this.SelectedServiceEngWorkForecastingHdr.From),
      ServiceCharge: this.SelectedJobOrderHdr.ServiceCharge,
      To: this.convert(this.SelectedServiceEngWorkForecastingHdr.To),
      FromTime: this.SelectedServiceEngWorkForecastingHdr.FromTime,
      ToTime: this.SelectedServiceEngWorkForecastingHdr.ToTime,
      CustomerId: this.selectedWorkAllocationHdr.CustomerId,
      BranchId: this.selectedWorkAllocationHdr.BranchId,
      ServiceMode: this.SelectedServiceEngWorkForecastingHdr.ServiceMode,
      Notes: this.SelectedServiceEngWorkForecastingHdr.Notes,
      CheckedWorkAllocationDet: this.WorkAllocationDet,
      RefNo: this.selectedWorkAllocationHdr.RefNo,
      Vtype: this.selectedWorkAllocationHdr.VType,
      WorkDetails: null,
      SEWorkDet: null,
      DealerId: this.selectedWorkAllocationHdr.DealerId,
      EmployeeType: this.SelectedServiceEngWorkForecastingHdr.EmployeeType
    }
    console.log(body)
    return this.http.post<WorkSortingAssigning[]>(ROOT_URL + 'WorkAllocation/SaveJobOrder', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getJobOrderByJobId(JoNo: number): Observable<JobOrderHdr> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<JobOrderHdr>(ROOT_URL + 'WorkAllocation/getJobOrder?JoNo=' + JoNo, {
      headers,
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  GetActivityRemarkList(FormName, ActivityID) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<JobOrderHdr>(ROOT_URL + 'WorkAllocation/GetActivityRemarkList?FormName=' + FormName + '&ActivityID=' + ActivityID, {
      headers,
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  SaveActivtyRemark(obj: ActivityRemarks): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = {
      ID: obj.ID,
      ActivityID: obj.ActivityID,
      AddedBy: 0,//CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8),
      AddedByName: obj.AddedByName,
      AddedDate: obj.AddedDate,
      EditedBy: 0,//CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8),
      EditedByName: obj.EditedByName,
      EditedDate: obj.EditedDate,
      FormName: obj.FormName,
      Remark: obj.Remark
    }
    console.log(body)
    return this.http.post<any>(ROOT_URL + 'WorkAllocation/SaveActivityRemark', body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
