<div class="container-fluid">
    <h2 class="heading_all">Work Delivery</h2>
    <div class="row">
        <div class="col-md-4">
            <input type="radio" name="Delivered" [value]="0" [(ngModel)]="_JobCardService.statusType" (change)="LoadCompletedWorks()"><label>Delivered</label>
            <input type="radio" name="ReadyToDelivered" [value]="1" [(ngModel)]="_JobCardService.statusType" (change)="LoadCompletedWorks()"><label>Ready To Delivered</label>
        </div>
        <div class="col-md-3">
            <select id="CustomerFilter" name="CustomerFilter" #CustomerFilter="ngModel"
                style="padding: 8px;border-radius: .5rem;" (change)="CustomerChange()"
                [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.CustomerID">
                <option [value]="''">SELECT CUSTOMER</option>
                <option *ngFor="let field of customerList | orderBy : 'Name'" [value]="field.Id"> {{field.Name}}</option>
            </select>
        </div>
        <div class="col-md-3">
            <select id="BatchFilter" name="BatchFilter" #BatchFilter="ngModel"
                style="padding:8px;border-radius:.5rem;" [(ngModel)]="this.selectedBatchId">
                <option [value]="0">SELECT BATCH</option>
                <option *ngFor="let batch of this._WorkDeliveryService.allBatch" [value]="batch.Id"> {{batch.Name}}</option>
            </select>
        </div>
        <div class="col-md-2"></div>
    </div>
    <br>
    <div class="tWrap">
        <div class="tWrap__head">
            <table>
                <thead>
                    <tr>
                        <th style="width: 50px;">
                            <span>
                                <input type="checkbox" title="Select ALL" name="chkall" id="chkall" #chkall="ngModel"
                                    [(ngModel)]="chkSelectAll" (change)="SelectAllCheckBox()">
                            </span>
                            <span>
                                <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                                    <i *ngIf="!isFiltering" class="fas fa-filter"></i>
                                    <i *ngIf="isFiltering" class="fas fa-times"></i>
                                </a>
                            </span>
                        </th>
                        <th style="width: 50px;">SN.NO </th>
                        <th title="ActivityID" style="width:70px;">
                            <div *ngIf="!isFiltering" (click)="sort('ActivityID')">
                                ID
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='ActivityID' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='ActivityID'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="ActivityFilter" name="ActivityFilter" #ActivityFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.ActivityID" placeholder="ID">
                            </div>                           
                        </th>
                        <th style="width: 165px;">
                            <div *ngIf="!isFiltering" (click)="sort('DealerName')">
                                DEALER
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='DealerName' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='DealerName'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="dealerFilter" name="dealerFilter" #dealerFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.DealerId">
                                    <option [value]="''">SELECT DEALER</option>
                                    <option *ngFor="let field of this._dealrService.allDealers" [value]="field.Id">
                                        {{field.DealerName}} </option>
                                </select>
                            </div>

                        </th>
                        <th style="width:175px;">
                            <div *ngIf="!isFiltering" (click)="sort('CustomerName')">
                                Customer
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='CustomerName' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='CustomerName'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="CustomerNameFilter" name="CustomerNameFilter" #CustomerNameFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.CustomerName"
                                    placeholder="CustomerName">
                            </div>
                        </th>

                        <th style="width: 52px;">
                            <div *ngIf="!isFiltering" (click)="sort('RegNo')">
                                REG.NO
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='RegNo' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='RegNo'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="regNoFilter" name="regNoFilter" #regNoFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.RegNo" placeholder="REG.NO">
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('VtypeText')">
                                VTYPE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='VtypeText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='VtypeText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="vtypefilter" name="vtypefilter" #vtypefilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Vtype">
                                    <option [value]="''">SELECT TYPE</option>
                                    <option [value]="0">Customer Requirement</option>
                                    <option [value]="1">Service Report</option>
                                </select>

                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('RepDate')">
                                REP.DATE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='RepDate' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='RepDate'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="repDateFilter" name="repDateFilter" #repDateFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.RepDate"
                                    placeholder="REP.DATE">
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('StageText')">
                                STAGE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='StageText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='StageText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="stageFilter" name="stageFilter" #stageFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Stage">
                                    <option [value]="''">SELECT STAGE</option>
                                    <option *ngFor="let field of this.StageFullList" [value]="field.Id">
                                        {{field.Name}}</option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('StatusText')">
                                STATUS
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='StatusText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='StatusText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="statusFilter" name="statusFilter" #statusFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Status">
                                    <option [value]="''">SELECT STATUS</option>
                                    <option *ngFor="let field of this.StatusFullList " [value]="field.Id">
                                        {{field.Name}}</option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('TestResultText')">
                                TEST RESULT
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='TestResultText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='TestResultText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="testresultFilter" name="testresultFilter" #testresultFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.TestResultText"
                                    placeholder="TEST RESULT">
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('TypeText')">
                                TYPE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='TypeText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='TypeText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="typeFilter" name="typeFilter" #typeFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.Type">
                                    <option [value]="''">SELECT TYPE</option>
                                    <option *ngFor="let field of TypeList" [value]="field.Id"> {{field.Name}}</option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('PriorityText')">
                                PRIORITY
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='PriorityText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='PriorityText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="priortyFilter" name="priortyFilter" #priortyFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.PriorityId">
                                    <option [value]="''">SELECT PRIORITY</option>
                                    <option *ngFor="let field of PriorityList" [value]="field.Id"> {{field.Name}}
                                    </option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('ModuleText')">
                                MODULE
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='ModuleText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='ModuleText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="moduleFilter" name="moduleFilter" #moduleFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.ModuleId">
                                    <option [value]="''">SELECT MODULE</option>
                                    <option *ngFor="let field of this._modulService.allModules" [value]="field.Id">
                                        {{field.ModuleName}}
                                    </option>
                                </select>
                            </div>
                        </th>
                        <th>
                            <div *ngIf="!isFiltering" (click)="sort('ParticularText')">
                                PARTICULARS
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort"
                                        *ngIf="_ProjectLeaderService.orderByColName!='ParticularText' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='ParticularText'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <select id="particularFilter" name="particularFilter" #particularFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.ParticularId">
                                    <option [value]="''">SELECT PARTICULAR</option>
                                    <option *ngFor="let field of this._ParticularmasterService.allparticular"
                                        [value]="field.ID">
                                        {{field.Particulars}}</option>
                                </select>
                            </div>
                        </th>
                        <th title="Delivery Date">
                            Delivered
                        </th>
                        <!-- <th>
                            <div *ngIf="!isFiltering" (click)="sort('JoNo')">
                                JOBNO
                                <div class="AtjfloatRight">
                                    <i class="fas fa-sort" *ngIf="_ProjectLeaderService.orderByColName!='JoNo' "></i>
                                    <div *ngIf="_ProjectLeaderService.orderByColName=='JoNo'">
                                        <i class="fas fa-sort-amount-down" *ngIf="_ProjectLeaderService.orderByDir"></i>
                                        <i class="fas fa-sort-amount-up-alt"
                                            *ngIf="!_ProjectLeaderService.orderByDir"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isFiltering">
                                <input id="jobnoFilter" name="jobnoFilter" #jobnoFilter="ngModel"
                                    [(ngModel)]="_ProjectLeaderService.PLWorkDetailsFilter.JoNo" placeholder="JOBNO">
                            </div>
                        </th> -->
                        <th style="width:150px;">
                            Description
                        </th>
                        <th title="UnDelivered"><i class="fas fa-trash-alt"></i></th>
                    </tr>
                </thead>
            </table>
        </div>
        <div class="tWrap__body">
            <table>
                <tbody>
                    <ng-container
                        *ngFor="let obj of  this._ProjectLeaderService.PLWorkDetailsHdrList| gridOperations  :null: 
                            {ActivityID:_ProjectLeaderService.PLWorkDetailsFilter.ActivityID,DealerId:_ProjectLeaderService.PLWorkDetailsFilter.DealerId,RegNo:_ProjectLeaderService.PLWorkDetailsFilter.RegNo,
                            Vtype:_ProjectLeaderService.PLWorkDetailsFilter.Vtype,RepDate:_ProjectLeaderService.PLWorkDetailsFilter.RepDate,
                            Stage:_ProjectLeaderService.PLWorkDetailsFilter.Stage,Status:_ProjectLeaderService.PLWorkDetailsFilter.Status,
                            TestResultText:_ProjectLeaderService.PLWorkDetailsFilter.TestResultText,Type:_ProjectLeaderService.PLWorkDetailsFilter.Type,
                            PriorityId:_ProjectLeaderService.PLWorkDetailsFilter.PriorityId,ModuleId:_ProjectLeaderService.PLWorkDetailsFilter.ModuleId,
                            ParticularId:_ProjectLeaderService.PLWorkDetailsFilter.ParticularId, JoNo:_ProjectLeaderService.PLWorkDetailsFilter.JoNo,
                            CustomerID:_ProjectLeaderService.PLWorkDetailsFilter.CustomerID,BatchId:this.selectedBatchId}:
                            {Column:_ProjectLeaderService.orderByColName,Dir:_ProjectLeaderService.orderByDir};let i =index">

                        <tr [ngClass]="{'finishedWork':obj.Status==2,'QaFinished':obj.Status==6 && obj.TestResultText=='Succeed','succedBG':obj.Stage==8}">
                            <td style="width: 50px;">
                                <span
                                    *ngIf="(this.SelectedCustomerID==obj.CustomerID) || (this.SelectedCustomerID==0  )">
                                    <input type="checkbox" [(ngModel)]="obj.CheckStatus" name="chk"
                                        [disabled]="(obj.ActionID !=1 || obj.DeliveryDate !='')"
                                        (change)="SelectCheckBox(obj.ActivityID,obj.CheckStatus)">
                                </span>
                            </td>
                            <td style="width: 50px;"> {{i+1}}</td>
                            <td style="width:70px;">{{obj.ActivityID}}</td>
                            <td style="width: 165px;" [title]="obj.DealerName"> {{obj.DealerName}} </td>
                            <td style="width: 175px;" [title]="obj.CustomerName"> {{obj.CustomerName}} </td>
                            <td [title]="obj.RegNo" style="cursor: pointer;text-decoration: underline;width: 52px;"
                                (click)="getCusReqOrServiceEntry(obj)">
                                {{obj.RegNo}}</td>
                            <td [title]="obj.VtypeText"> {{obj.VtypeText}} </td>
                            <td [title]="obj.RepDate">{{obj.RepDate }}</td>
                            <td [title]="obj.StageText" (click)="getWorkStatus(obj)"
                                [ngClass]="{'deliveredstage':obj.Stage==8,'undeliveredstage':obj.Stage==9}">
                                {{obj.StageText}} </td>
                            <td [title]="obj.StatusText"> {{obj.StatusText}} </td>
                            <td [title]="obj.TestResultText">{{obj.TestResultText}}</td>
                            <td [title]="obj.TypeText"> {{obj.TypeText}} </td>
                            <td [title]="obj.PriorityText"> {{obj.PriorityText}} </td>
                            <td [title]="obj.ModuleText"> {{obj.ModuleText}} </td>
                            <td [title]="obj.ParticularText"> {{obj.ParticularText}} </td>
                            <td [title]=" obj.DeliveryDate">{{obj.DeliveryDate}} </td>
                            <!-- <td [title]="obj.JoNo">{{obj.JoNo}}</td> -->
                            <td [title]="obj.Description"
                                style="cursor: pointer;text-decoration: underline;width:150px;"
                                (click)="OpenDescPopup(obj.Description)">
                                {{obj.Description}}</td>
                            <td [ngClass]="{'undelivered':(obj.DeliveryDate!='' && obj.WorkDeliveryHdrId > 0),'delivered':(obj.WorkDeliveryHdrId==0)}"> 
                                <i class="fas fa-trash-alt" title="UnDelivered" (click)="taskUnDelivered(obj.ActivityID,obj.WorkDeliveryHdrId)"></i>
                            </td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td colspan="4"
                            *ngIf="(_ProjectLeaderService.PLWorkDetailsHdrList?.length<=0 )  || _CommonService.filterdItemCount<1">
                            No Records To
                            Display.</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
    <app-custom-service-report-entry *ngIf="this._CommonService.servicereportentryPopUp" [refno]="refno"
        [DealerId]="DealerId" [BranchId]="BranchId" [title]="'Service Report Entry'"></app-custom-service-report-entry>
    <app-custom-customer-requirement *ngIf="this._CommonService.customerrequirementPopUp" [refno]="refno"
        [DealerId]="DealerId" [BranchId]="BranchId" [title]="'Customer Requirement'"></app-custom-customer-requirement>

    <br>
    <div class="row" style="margin-top: 5px;padding-left: 15px;">
        <label for="select">Delivery date:</label>
        <div class="col-md-2">
            <input bsDatepicker  #PlannedDeliveryDate="ngModel"
                [(ngModel)]="this._workAllocationStatusService.SelectedWorkStatusHdr.PlannedDeliveryDate"
                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" autocomplete="off" name="PlannedDeliveryDate">
        </div>
        <label>
            Service Charge <span class="validation-error">*</span>
        </label>
        <div class="col-md-2">
            <input type="text" style="width: 100%;" name="Charge" placeholder="Service Charge" #Charge="ngModel"
                [(ngModel)]="this._workAllocationStatusService.SelectedWorkStatusHdr.Charge" autocomplete="off">
        </div>
    </div>
    <br>
    <div class="row" style="margin-left: 0px;">
        <div class="col-md-4">
            <div style="width: 50%;text-align: center;" class="btnBrowse btn-default btn-file">Browse
                <input type="file" name="customFile" #customFile id="customFile" multiple
                    (change)="handleFileInput($event)">
            </div>
        </div>
        <div class="col-md-4">
            <button type="button" style="width: 100%; margin-top: 20px;" (click)="TaskDeliver()"
                class=" sim-button button1">Deliver</button>
        </div>
    </div>

    <div class="row" *ngIf="this.fileToUpload.length>0 ||this._ProjectLeaderService.JobAssignAttachmentList.length>0">
        <div class="col-md-6">
            <div class="tWrap">
                <div class="tWrap__head">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">*</th>
                                <th scope="col">FileName</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let obj of  this.fileToUpload; let i=index">
                                <th style="background:white;color:black;font-weight: bold;" scope="row">
                                    <i class="fas fa-chevron-circle-right"></i>
                                </th>
                                <td> {{obj.name}} </td>
                                <td><button type="button" (click)="RemoveImage(obj)">-</button>
                                </td>
                            </tr>
                            <tr *ngFor="let obj of this._ProjectLeaderService.JobAssignAttachmentList; let i=index">
                                <th style="background:white;color:black;" scope="row"><i
                                        class="fas fa-chevron-circle-right"></i></th>
                                <td (click)="downloadWorkAllotmentFile(obj.FileName)"
                                    style="text-decoration: underline; cursor: pointer;">
                                    {{obj.FileName}} </td>
                                <td><button type="button" (click)="RemovePreviousImage(obj)">-</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>
<form #Description="ngForm" *ngIf="OpenDescriptionPopup">
    <div class="popup-container">
        <div class="popup HoldingReason" (click)="OpenDescriptionPopup=false;">

            <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                <div class="title">
                    <p>Description</p> <label for="test-results"> <i class="fas fa-times"
                            (click)="OpenDescriptionPopup=false;"></i> </label>
                </div>
                <div style="margin-top: 25px;">
                    <div class="row">
                        <div class="col-md-12">
                            <textarea class="form-control" autofocus name="DescriptionEdit" #DescriptionEdit="ngModel"
                                style="height: 350px;" [(ngModel)]="_CustomerrequirementService.DescriptionEdit"
                                autocomplete="off" placeholder=" Description" required>
                                                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<form *ngIf="DisplayWorkStatus==1">
    <div class="popup-container">

        <input type="checkbox" id="test-results" checked>
        <div class="popup HoldingReason" (click)="closePopUp()">
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                <div class="title">
                    <p>Work Status</p> <label for="test-results"> <i class="fas fa-times" (click)="closePopUp()"></i>
                    </label>
                </div>
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">SNO</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Expected Start Date</th>
                                            <th scope="col">Expected Completion Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let obj of this.workStatus;let i=index">
                                            <tr>
                                                <td> {{i+1}} </td>
                                                <td> {{obj.EmpName}} </td>
                                                <td *ngIf="obj.Status!=3">
                                                    {{obj.StatusText}}
                                                    <span style="display: block;" *ngIf="obj.Status==6">
                                                        {{obj.QaStatus==1?'Succeed':obj.QaStatus==2?'Failure':obj.QaStatus==3?'Retest':'Pending'}}
                                                        - {{obj.LastJobCardEntryDate |date:'MM/dd/yyyy'}}
                                                        <br>
                                                        <span
                                                            style="text-decoration: underline; color: rgb(11, 85, 223);cursor: pointer;"
                                                            (click)="qaRemark=obj.QaRemark"> Remark </span>
                                                    </span>
                                                </td>
                                                <td *ngIf="obj.Status==3" (click)="getholdReason(obj)"
                                                    style="text-decoration: underline; cursor: pointer; color: royalblue;">
                                                    {{obj.StatusText}}
                                                </td>
                                                <td>
                                                    {{obj.ExpStartDate |date:'MM/dd/yyyy'}}
                                                </td>
                                                <td>
                                                    {{obj.ExpCompDate |date:'MM/dd/yyyy'}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td *ngIf="!this._CommonService.isLoading && this.workStatus?.length<0">
                                                No Records To Display.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="col-md-12" *ngIf="qaRemark.length>0">
                            <textarea name="qaRe" disabled id="qaRe" cols="6" rows="3" #qaRe="ngModel"
                                [ngModel]="qaRemark"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>