import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/Services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { NgForm } from '@angular/forms';
import { RouteService } from 'src/app/Services/route.service';
import * as CryptoJS from 'crypto-js';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent implements OnInit {
  constructor(public _custService: CustomerService,
    private toastr: ToastrService,
    public _CommonService: CommonService,
    public _countryService: CountryService,
    public _dealrService: DealerService, 
    public _routeService: RouteService) { }

  public errorMsg;
  saveBranch: boolean;
  // filepreview = '';
  FileInvalidMessage = '';
  FileisValid = false;
  //imageUrl: string = "";
  fileToUpload: File = null;
  BranchNameVisibility: boolean
  // DealerId: string = '0'
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  ngOnInit(): void {
    this.saveBranch = false;
    this._custService.formHideShow = 0;
    this._custService.formHidePassword = false;
    this._countryService.getCountry()
      .subscribe(data => this._countryService.allCountries = data,
        error => {
          this.errorMsg = error;
        });
    this.BranchNameVisibility = false;
    this._custService.imageUrl = "";
    this.resetForm();
    this.BranchNameVisibility = false;
  }

  getAllRoute() {
    this._routeService.getRoute('BYCOUNTRYID', this._custService.selectedCustomer.CountryId)
      .subscribe(data => this._routeService.allRoutes = data,
        error => {
          this.errorMsg = error;
        });
  }

  UpdateBranchName() {
    if (this._custService.selectedCustomer.NoOfBranches == 1 && this._custService.selectedCustomer.Id == 0) {
      console.log('1', this._custService.selectedCustomer.NoOfBranches)
      this.BranchNameVisibility = true;
      console.log('this.BranchNameVisibility', this.BranchNameVisibility)
    }
    else {
      console.log('2', this._custService.selectedCustomer.NoOfBranches)
      this.BranchNameVisibility = false;
    }
  }

  LoadDealer() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        this._dealrService.allDealers = data
        this._custService.selectedCustomer.CountryId = data[0].CountryId;
        this._custService.selectedCustomer.DealerId = data[0].Id;
        this.getAllRoute();
      })
  }
  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._custService.selectedCustomer = {
      Id: 0,
      CountryId: 0,
      DealerId: 0,
      Name: '',
      Addr1: '',
      Addr2: '',
      Addr3: '',
      CustEmail: '',
      CustPhone: '',
      CustFax: '',
      ContactPerson: '',
      ContactPhone: '',
      ContactEmail: '',
      URL: '',
      LogoURL: '',
      NoOfBranches: 0,
      Code: '',
      BranchName: '',
      RouteId: 0
    }
    this._custService.imageUrl = null
    this.LoadDealer();
    this.fileToUpload = null;
    this.BranchNameVisibility = false;
  }
  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._custService.formHideShow = 0;
    this.BranchNameVisibility = false;
  }

  handleFileInput(file: FileList) {
    if (file.length > 0) {
      this.fileToUpload = file.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this._custService.imageUrl = event.target.result;
      }
      reader.readAsDataURL(this.fileToUpload);
    }
  }

  //File Validation
  ChechFileValid(file): boolean {
    let result = true;
    if (this.fileToUpload != null) {
      if ((file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/gif') && file.size <= (1600 * 1200)) {
        this.FileInvalidMessage = "";
        this.FileisValid = true;
        result = true;
      }
      else {
        this.FileInvalidMessage = "Only JPEG/PNG/Gif Image can be upload )";
        this.toastr.warning(this.FileInvalidMessage, 'Customer');
        this.FileisValid = false;
        result = false;
      }
    }
    else {
      result = false;
    }

    return result;
  }
  formValidation(): boolean {
    let result = true;
    if (this._custService.selectedCustomer.Name != '' && this._custService.selectedCustomer.Code != "" && this._custService.selectedCustomer.Addr1 != ""
      && this._custService.selectedCustomer.Addr2 != "" && this._custService.selectedCustomer.Addr3 != "" && this._custService.selectedCustomer.CustEmail != ""
      && this._custService.selectedCustomer.CustPhone != "" && this._custService.selectedCustomer.CustFax != "" && this._custService.selectedCustomer.URL != ""
      && this._custService.selectedCustomer.ContactPerson != "" && this._custService.selectedCustomer.ContactPhone != "" && this._custService.selectedCustomer.ContactEmail != ""
      && this._custService.selectedCustomer.NoOfBranches > 0
    ) {

      if (this._custService.selectedCustomer.NoOfBranches == 1 && this._custService.selectedCustomer.Id == 0) {
        if ((this._custService.selectedCustomer.BranchName.length > 0 && this._custService.selectedCustomer.RouteId > 0) || this._custService.selectedCustomer.Id > 0) {
          result = true;
        }
        else {
          result = false;
        }
      }
    }
    else {
      result = false;
    }

    return result;
  }
  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (this.formValidation() == true && form.valid) {
      form.value.CountryId = this._custService.selectedCustomer.CountryId
      form.value.DealerId = this._custService.selectedCustomer.DealerId
      form.value.LogoURL = this._custService.selectedCustomer.LogoURL
      form.value.Code = this._custService.selectedCustomer.Code
      if (this.ChechFileValid(this.fileToUpload) || this._custService.selectedCustomer.LogoURL.length > 0) {
        if (this._custService.selectedCustomer.NoOfBranches == 1 && this._custService.selectedCustomer.Id == 0) {
          this.saveBranch = true;
        }
        else {
          this.isSubmit = true
          if (parseInt(form.value.NoOfBranches) > 1) {
            form.value.BranchName = ''
            form.value.RouteId = 0
          }
          else if (this._custService.selectedCustomer.Id != 0) {
            form.value.BranchName = ''
            form.value.RouteId = 0
          }
          this._CommonService.isLoading = true
          console.log(form.value)
          this._custService.postCustomer(form.value, this.fileToUpload).subscribe(data => {
            this.isSubmit = false
            this._CommonService.isLoading = false
            if (data.Status == 0) {
              this.toastr.success(data.Message, "Customer")
              this._custService.formHideShow = 0;
              this.resetForm();
              this.getallCustomers();
              this.fileToUpload = null;
            }
            else
              this.toastr.error(data.Message, "Customer")
          },
            error => {
              this._CommonService.isLoading = false
              this.errorMsg = error;
              this.toastr.error('New Record Added Failure', 'Customer');
            })
        }
      }
      else {
        this.toastr.warning('Please fill all mandatory fields.', 'Customer');
      }
    } else {
      this.toastr.warning('Please fill all mandatory fields.', 'Customer');
    }
  }
  saveBranchDetailsTo(e, form: NgForm) {
    this.FormValidation = true
    this.saveBranch = false;
    form.value.CountryId = this._custService.selectedCustomer.CountryId
    form.value.DealerId = this._custService.selectedCustomer.DealerId
    form.value.Code = this._custService.selectedCustomer.Code
    form.value.LogoURL = this._custService.selectedCustomer.LogoURL

    if (e == true) {
      this.isSubmit = true
      this._CommonService.isLoading = true

      this._custService.postCustomer(form.value, this.fileToUpload).subscribe(data => {
        this._CommonService.isLoading = false
        if (data.Status == 0) {
          this.toastr.success(data.Message, "Customer")
          this._custService.formHideShow = 0;
          this.resetForm();
          this.getallCustomers();
          this.fileToUpload = null;
        }
        else
          this.toastr.warning(data.Message, "Customer")
        this.isSubmit = false
      },
        error => {
          this._CommonService.isLoading = false
          this.errorMsg = error;
          this.toastr.error('New Record Added Failure', 'Customer');
        })
    }
    else if (e == false) {
      form.value.BranchName = '';
      form.value.RouteId = 0;
      form.value.Code = this._custService.selectedCustomer.Code
      this.isSubmit = true
      this._CommonService.isLoading = false

      this._custService.postCustomer(form.value, this.fileToUpload).subscribe(data => {
        this._CommonService.isLoading = false
        if (data.Status == 0) {
          this.toastr.success(data.Message, "Customer")
          this._custService.formHideShow = 0;
          this.resetForm();
          this.getallCustomers();
          this.fileToUpload = null;
        }
        else
          this.toastr.warning(data.Message, "Customer")
        this.isSubmit = false
      },
        error => {
          this._CommonService.isLoading = false
          this.errorMsg = error;
          this.toastr.error('New Record Added Failure', 'Customer');
        })
    }
  }

  getallCustomers() {
    this._custService.FetchAllCustomer('DEALERID')
      .subscribe(data => this._custService.allCustomers = data,
        error => {
          this.errorMsg = error;
        })
  }
  isNumber(event) {
    if (event.charCode !== 0) {
      const pattern = /^[0-9]\d*$/;// /^\d{0,4}(\.\d{0,4})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode != 13) {
        event.preventDefault();
      }
    }
  }
}
