import { Component, OnInit } from '@angular/core';
import { ReasonmasterService } from 'src/app/Services/reasonmaster.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerFeedBackMaster } from 'src/app/Models/CustomerFeedBackMaster';
import { CommonService } from 'src/app/Services/common.service';
import { ReasonMaster } from 'src/app/Models/ReasonMaster';

@Component({
  selector: 'app-reason-master-list',
  templateUrl: './reason-master-list.component.html',
  styleUrls: ['./reason-master-list.component.css']
})
export class ReasonMasterListComponent implements OnInit {


  constructor(
    public _ReasonmasterService: ReasonmasterService,
    public _CommonService: CommonService,
    private toastr: ToastrService
  ) { }

  public errorMsg;
  public term: any = '';
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  ngOnInit(): void {
    this.deleteID = 0;
    this.deletePopUp = false;
    this._ReasonmasterService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this._ReasonmasterService.getReasonMaster("ALL")
      .subscribe(data => {
        this._ReasonmasterService.allReasonMaster = data

      },
        error => {
          this.errorMsg = error;
        })
    this._ReasonmasterService.formHideShow = 0;
  }

  toggleFilter() {
    this._ReasonmasterService.isFiltering = this._ReasonmasterService.isFiltering ? false : true;
    if (!this._ReasonmasterService.isFiltering) {
      this._ReasonmasterService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._ReasonmasterService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._ReasonmasterService.orderByColName) {
      this._ReasonmasterService.orderByDir = !this._ReasonmasterService.orderByDir;
    }
    this._ReasonmasterService.orderByColName = colName;
  }


  NoOfItems: number = 10;
  addNew() {
    this._ReasonmasterService.formHideShow = 1;
  }

  OnEdit(selectedRow: ReasonMaster) {
    this._ReasonmasterService.selectedRecord = Object.assign({}, selectedRow);
    this._ReasonmasterService.formHideShow = 1;
  }

  onDelete(deleteID: number) {
    this.deleteID = deleteID;
    this.deletePopUp = true;
  }
  
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._ReasonmasterService.onDelete(this.deleteID)
        .subscribe(x => {
          this._ReasonmasterService.getReasonMaster("ALL").subscribe(data => this._ReasonmasterService.allReasonMaster = data,
            error => {
              this.errorMsg = error;
              console.error(error.name, error.message, error.stack);
            });
          this.toastr.success("Deleted Successfully", "Reason master");
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.warning("Deletion Failure", "Reason master");
        })
    }
  }

}
