import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { LeaveDetails, HolidayDetails, OffdayDetails } from '../Models/LeaveDetails';
import { GlobalErrorHandlerService } from './global-error-handler.service'

@Injectable({
  providedIn: 'root'
})
export class LeavedetailsService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  selectedRecord: LeaveDetails;
  leavedetailList: LeaveDetails[];
  allleavedetails: LeaveDetails[];
  pageIndexL: number = 0;
  pageIndexH: number = 0;
  isFiltering: boolean = false;
  NameFilter: string = '';
  NoOfDaysFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';

  //holiday form
  selectedRecordHoliday: HolidayDetails;
  holidaydetailList: HolidayDetails[];
  allholidaydetails: HolidayDetails[];
  ReasonFilter: string = '';
  FromFilter: string = '';
  ToFilter: string = '';
  NoteFilter: string = '';


  //offday form
  selectedRecordOffday: OffdayDetails;
  offdaydetailList: OffdayDetails[];
  alloffdaydetails: OffdayDetails[];

  giveEditPermissionOFF:boolean=false;
  giveEditPermissioHoliday:boolean=false;
  giveEditPermissioLeave:boolean=false;

  clearValue() {
    this.selectedRecord = {
      Id: 0,
      Name: '',
      NoOfDays: 0
    }
  }
  clearHolidayValue() {
    this.selectedRecordHoliday = {
      Id: 0,
      Reason: '',
      From: null,
      To: null,
      Note: ''
    }
  }
  ClearFilter() {
    this.isFiltering = false;
    this.NameFilter = '';
    this.NoOfDaysFilter = '';

  }

  ClearHolidayFilter() {
    this.isFiltering = false;
    this.ReasonFilter = '';
    this.FromFilter = '';
    this.ToFilter = '';
    this.NoteFilter = '';

  }

  getLeaveDetails(TransMode: string): Observable<LeaveDetails[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<LeaveDetails[]>(ROOT_URL + 'LeaveDetails/GetAllData', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  getHolidayDetails(TransMode: string): Observable<HolidayDetails[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<HolidayDetails[]>(ROOT_URL + 'LeaveDetails/GetAllHolidayData', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getOffdayDetails(TransMode: string): Observable<OffdayDetails[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<OffdayDetails[]>(ROOT_URL + 'LeaveDetails/GetAllOffdayData', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  SaveItem(Values: LeaveDetails): Observable<any> {

    var body = {
      Id: Values.Id,
      Name: Values.Name,
      NoOfDays: Values.NoOfDays
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "LeaveDetails/InsUpdDelLeaveDetails", body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  SaveHolidayItem(Values: HolidayDetails): Observable<any> {

    var body = {
      Id: Values.Id,
      Reason: Values.Reason,
      From: Values.From,
      To: Values.To,
      Note: Values.Note
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "LeaveDetails/InsUpdDelHolidayDetails", body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  SaveOffdayItem(Values: OffdayDetails): Observable<any> {

    var body = {
        Id: Values.Id
      , Sunday: Values.Sunday
      , Monday: Values.Monday
      , Tuesday: Values.Tuesday
      , Wednesday: Values.Wednesday
      , Thursday: Values.Thursday
      , Friday: Values.Friday
      , Saturday: Values.Saturday
      , ManHours: Values.ManHours

    }
    console.log('body',body)
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "LeaveDetails/InsUpdDelOffdayDetails", body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  onDelete(Id: number) {
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.delete<any>(ROOT_URL + 'LeaveDetails/DeleteLeaveDetails', {
      headers, params

    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }

  onDeleteHoliday(Id: number) {
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.delete<any>(ROOT_URL + 'LeaveDetails/DeleteHolidayDetails', {
      headers, params

    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }
}
