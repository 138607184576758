<div class="container-fluid" *ngIf="_swRegService.formHideShow!=0">
    <h2 class="heading_all">Software Registartion</h2>
    <br>
    <div id="form-wrap">
        <form #SoftwareRegistrationForm="ngForm" (ngSubmit)="onSubmit(SoftwareRegistrationForm)">
            <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_swRegService.selectedSoftwareRegistration.Id">
            <div class="tab-content">
                <div id="sec1" class="container tab-pane active">
                    <div class="row">
                        <div class="col-md-6">

                            <div class="form-group">
                                <label for="RefNo">Ref.No<span class="validation-error">*</span></label>
                                <input class="form-control" name="RefNo" #RefNo="ngModel" autocomplete="off"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.RefNo" placeholder=" RefNo"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(RefNo.invalid && RefNo.touched)||(RefNo.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="ContractDate">Contract Date<span class="validation-error">*</span></label>
                                <input type="text" bsDatepicker [bsConfig]="{  dateInputFormat: 'DD-MM-YYYY' }"
                                    placeholder="Choose Date" style="color: black; text-align: center;"
                                    (ngModelChange)="this.datecal()" autocomplete="off" id="ContractDate"
                                    name="ContractDate" #ContractDate="ngModel"
                                    [(ngModel)]="this._swRegService.selectedSoftwareRegistration.ContractDate">
                                <div class="validation-error"
                                    *ngIf="(ContractDate.invalid && ContractDate.touched)||(ContractDate.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="CountryId">Country<span class="validation-error">*</span></label>
                                <select disabled class="form-control" name="CountryId" #CountryId="ngModel"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.CountryId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let prf of _countryService.allCountries | orderBy : 'Name'"
                                        [ngValue]="prf.Id">
                                        {{ prf.Name }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(CountryId.invalid && CountryId.touched)||(CountryId.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="DealerId">Dealer<span class="validation-error">*</span></label>
                                <select disabled class="form-control" name="DealerId" #DealerId="ngModel"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.DealerId"
                                    (change)="LoadCustomers()" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.DealerName }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(DealerId.invalid && DealerId.touched)||(DealerId.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="CustomerId">Customer<span class="validation-error">*</span></label>                              
                                <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel" bindLabel="Name"
                                    bindValue="Id" (ngModelChange)="onSelect($event);LoadBranch();"
                                    [items]="_custService.allCustomers | orderBy : 'Name'" required
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.CustomerId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(CustomerId.invalid && CustomerId.touched)||(CustomerId.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="Addr1">Address<span class="validation-error">*</span></label>
                                <input class="form-control" name="Addr1" #Addr1="ngModel" autocomplete="off"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.Addr1" placeholder=" Addr1"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr1.invalid && Addr1.touched)||(Addr1.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <label><span class="validation-error">*</span></label>
                                <input class="form-control" name="Addr2" #Addr2="ngModel" autocomplete="off"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.Addr2" placeholder=" Addr2"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr2.invalid && Addr2.touched)||(Addr2.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <label><span class="validation-error">*</span></label>
                                <input class="form-control" name="Addr3" #Addr3="ngModel" autocomplete="off"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.Addr3" placeholder=" Addr3"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(Addr3.invalid && Addr3.touched)||(Addr3.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group">
                                <label for="BPEId">BPE<span class="validation-error">*</span></label>

                                <select class="form-control" name="BPEId" #BPEId="ngModel"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.BPEId" required>
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option
                                        *ngFor="let prf of _bpeService.allBusinessPromotionExecutives | orderBy : 'BPEName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.BPEName }}
                                    </option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(BPEId.invalid && BPEId.touched)||(BPEId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                            <div class="row">
                                <span style="width: 25%;" class="btn btn-default btn-file">Browse
                                    <input type="file" name="customFile" #customFile id="customFile" multiple
                                        (change)="handleFileInput($event)">
                                </span>
                            </div>
                            <br>
                            <div class="row" style="width: 1445px;"
                                *ngIf="this.FileList.length>0 || this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachments.length>0">
                                <div class="col-md-4">
                                    <div class="tWrap">
                                        <div class="tWrap__head">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 35px;">*</th>
                                                        <th scope="col">FileName</th>
                                                        <th scope="col" style="width: 100px;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let obj of  this.FileList; let i=index">
                                                        <th style="background:white;color:black;" scope="row">></th>
                                                        <td (click)="downloadFileUrl(obj.name)"
                                                            style="cursor: pointer; text-decoration: underline;">
                                                            {{obj.name}} </td>
                                                        <td><button type="button" (click)="RemoveImage(obj)">-</button>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        *ngFor="let obj of this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachments; let i=index">
                                                        <th style="background:white;color:black;" scope="row">></th>
                                                        <td (click)="downloadFileUrl(obj.FileName)"
                                                            style="cursor: pointer; text-decoration: underline;">
                                                            {{obj.FileName}} </td>
                                                        <td><button type="button"
                                                                (click)="RemovePreviousImage(obj)">-</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">

                        </div> -->
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="ProjectId">Project<span class="validation-error">*</span></label>                             
                                <ng-select name="ProjectId" id="ProjectId" #ProjectId="ngModel" bindLabel="Name"
                                    bindValue="Id" [items]="_projectService.allProjects" required
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.ProjectId">
                                </ng-select>

                                <div class="validation-error"
                                    *ngIf="(ProjectId.invalid && ProjectId.touched)||(ProjectId.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                            </div>
                            <div class="form-group">
                                <label for="ModuleName">Modules</label>
                                <div class="col-md-8 moduleBox">
                                    <div
                                        *ngFor="let obj of _ModuleService.allModulesCheckBox | orderBy : 'Selected': true">
                                        <input type="checkbox" (change)="onChangeModules($event, obj)"
                                            [checked]="obj.Selected" name="obj.ModuleName"
                                            value="{{obj.Id}}">&nbsp;{{obj.ModuleName}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="checkbox" style="width: auto;"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.CentralizedServer"
                                    (change)="toggle()"
                                    [checked]="_swRegService.selectedSoftwareRegistration.CentralizedServer"
                                    name="CentralizedServer"
                                    value="_swRegService.selectedSoftwareRegistration.CentralizedServer">
                                <label>Centralized Server</label>
                            </div>
                            <div class="form-group">
                                <div *ngIf="_swRegService.checkboxVisible==1">
                                    <input type="radio"
                                        [checked]="_swRegService.selectedSoftwareRegistration.LocalServer" name="Server"
                                        value="_swRegService.selectedSoftwareRegistration.LocalServer"
                                        (change)="radioChanged('L')">

                                    <label>Local Server</label>
                                    <input type="radio" style="width: 35px;height: 15px;"
                                        [checked]="_swRegService.selectedSoftwareRegistration.NetServer" name="Server"
                                        value="_swRegService.selectedSoftwareRegistration.NetServer"
                                        (change)="radioChanged('N')">

                                    <label>Net Server</label>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="ServerDetails">Server Details<span class="validation-error">*</span></label>
                                <textarea class="form-control" name="ServerDetails" #ServerDetails="ngModel"
                                    autocomplete="off"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.ServerDetails"
                                    placeholder=" ServerDetails" required></textarea>
                                <div class="validation-error"
                                    *ngIf="(ServerDetails.invalid && ServerDetails.touched)||(ServerDetails.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                                <input type="checkbox" style="width: auto;" (change)="ServerNametoggle()"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.ReportServer"
                                    [checked]="_swRegService.selectedSoftwareRegistration.ReportServer"
                                    name="ReportServer"
                                    value="_swRegService.selectedSoftwareRegistration.ReportServer">
                                <label>Cloud Server</label>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-md-6" *ngIf="_swRegService.ServerNameVisible==1">
                                    <div class="form-group">
                                        <label for="ServerID">Server Name<span class="validation-error">*</span></label>                             
                                        <ng-select name="ServerID" id="ServerID" #ServerID="ngModel" bindLabel="Name"
                                            bindValue="Id" [items]="_swRegService.allServer" placeholder="Select Server"
                                            [(ngModel)]="_swRegService.selectedSoftwareRegistration.ServerID">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="ServerId">Frequency</label>                             
                                        <ng-select name="TermID" id="ServerId" #TermID="ngModel" bindLabel="Name"
                                            bindValue="Id" [items]="_CommonService.allTerms" placeholder="Select Frequency"
                                            [(ngModel)]="_swRegService.selectedSoftwareRegistration.TermID">
                                        </ng-select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-8" *ngIf="_swRegService.ServerNameVisible==1">
                                    <input class="form-control" name="ServerName" autocomplete="off"
                                        #ServerName="ngModel"
                                        [(ngModel)]="_swRegService.selectedSoftwareRegistration.ServerName"
                                        placeholder="Server Name">
                                </div> -->                          
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Rent</label>
                                        <!-- <input class="form-control" name="Rent" autocomplete="off" #Rent="ngModel"
                                            ng-pattern="[789][0-9]{9}"
                                            [(ngModel)]="_swRegService.selectedSoftwareRegistration.Rent"
                                            placeholder="Rent"> -->
                                            <input class="form-control" name="Rent" autocomplete="off" #Rent="ngModel" (keypress)="this._CommonService.decimalPointValidation($event)" 
                                            [(ngModel)]="_swRegService.selectedSoftwareRegistration.Rent" placeholder="0.00">
                                    </div>
                                </div>
                                <!-- <div class="col-md-4" >
                                    <div class="form-group">
                                        <label>FreeFor:</label>
                                        <input class="form-control" name="FreeFor" autocomplete="off" #FreeFor="ngModel"
                                            (keypress)="this._CommonService.numberValidation($event)"
                                            [(ngModel)]="_swRegService.selectedSoftwareRegistration.FreeFor" (ngModelChange)="this.datecal()" placeholder=" FreeFor">
                                    </div>
                                </div>
                                  <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Expiry</label>
                                        <input class="form-control" name="Expiry" autocomplete="off" #Expiry="ngModel"
                                            disabled [(ngModel)]="_swRegService.selectedSoftwareRegistration.Expiry"
                                            bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="Expiry">
                                    </div>
                                </div> -->                             
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Starting Date</label>
                                        <input class="form-control" name="StartDate" autocomplete="off" #StartDate="ngModel"
                                             [(ngModel)]="_swRegService.selectedSoftwareRegistration.StartDate"
                                            bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="Starting Date">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Ending Date</label>
                                        <input class="form-control" name="EndingDate" autocomplete="off" #EndingDate="ngModel"
                                             [(ngModel)]="_swRegService.selectedSoftwareRegistration.EndDate"
                                            bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placeholder="Ending Date">
                                    </div>
                                </div>                          
                            </div>
                            <!-- <div class="row"> -->
                            <div class="form-group">
                                <label for="ServerDetails">Notes</label>
                                <textarea class="form-control" name="Notes" #Notes="ngModel" autocomplete="off"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.Notes" placeholder="Notes"
                                    required></textarea>
                            </div>
                            <!-- </div> -->
                            <!-- <div class="row"> -->
                            <div class="form-group">
                                <label>Software Cost:</label>
                                <input class="form-control" name="SoftwareCost" autocomplete="off"
                                    #SoftwareCost="ngModel" (keypress)="_CommonService.decimalPointValidation($event)"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.SoftwareCost" placeholder="0.00">
                            </div>
                            <!-- </div> -->
                            <div class="row">
                                <label style="color:  #008080;text-decoration: underline;" for="select">Payment Terms
                                </label>
                                <div class="tWrap" style="max-height: 200px;overflow: auto;">
                                    <div class="tWrap__head">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width: 30px;">#</th>
                                                    <th scope="col" style="width: 65px;"> Term</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col" style="width: 101px;"> Amount</th>
                                                    <th scope="col" style="width: 100px;">Remark</th>
                                                    <th scope="col" style="width: 42px;"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let pay of  _swRegService.allSoftwareRegPaymentTerms ; let i = index">
                                                    <th style="background:white;color:black;width: 30px;" scope="row">{{i+1}}</th>
                                                    <td style="width: 76px;">
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="pay.Term" name="{{pay.Term}}"
                                                            (keypress)="numberOnly($event)" #{{pay.Term}}="ngModel"
                                                            [ngModelOptions]="{standalone: true}" >
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="pay.Date" name="{{pay.Date}}"
                                                            #{{pay.Date}}="ngModel" bsDatepicker
                                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                                                            [ngModelOptions]="{standalone: true}" placeholder="Term">

                                                    </td>
                                                    <td style="width: 76px;">
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="pay.Amount" name="{{pay.Amount}}" (keypress)="_CommonService.decimalPointValidation($event)"
                                                             #{{pay.Amount}}="ngModel"
                                                            [ngModelOptions]="{standalone: true}" placeholder="0.00" >
                                                    </td>
                                                    <td style="width: 122px;">
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="pay.Remark" name="{{pay.Remark}}"
                                                            #{{pay.Remark}}="ngModel"
                                                            [ngModelOptions]="{standalone: true}" placeholder="Term">
                                                    </td>

                                                    <td><button type="button" (click)="deleteFieldValue(i)">-</button>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th style="background:white;color:black;width: 30px;" scope="row">
                                                        {{this._swRegService.RowCount + 1}}</th>

                                                    <td style="width: 76px;">
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="this._swRegService.newAttribute.Term"
                                                            (keypress)="numberOnly($event)" name="newAttributeTerm"
                                                            #newAttributeTerm="ngModel" >
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="this._swRegService.newAttribute.Date"
                                                            bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                                                            name="newAttributeDate" #newAttributeDate="ngModel"
                                                            placeholder="Date">
                                                    </td>
                                                    <td style="width: 76px;">
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="this._swRegService.newAttribute.Amount" TwoDigitfloatValue
                                                            name="newAttributeAmount"
                                                            #newAttributeAmount="ngModel" placeholder="0.00" >
                                                    </td>
                                                    <td style="width: 122px;">
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="this._swRegService.newAttribute.Remark"
                                                            name="newAttributeRemark" #newAttributeRemark="ngModel"
                                                            placeholder="Remark">
                                                    </td>
                                                    <td><button type="button" (click)="addFieldValue()">+</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <label style="color:  #008080;text-decoration: underline;" for="select">Terms and
                                    Conditions
                                </label>
                                <textarea class="form-control" name="TermsConditions" #TermsConditions="ngModel"
                                    autocomplete="off"
                                    [(ngModel)]="_swRegService.selectedSoftwareRegistration.TermsConditions"
                                    placeholder=" Terms and Conditions" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_swRegService.allSystemDetails.length>0">
                        <label class="col-md-4  form-control-label"
                            style="color: black;margin-bottom: 12px;font-size: larger;" for="select">System Details</label>
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 190px;"> Branch</th>
                                            <th scope="col"> Business Type</th>
                                            <th scope="col"> Cont.Date</th>
                                            <th scope="col"> Free Support</th>
                                            <th scope="col"> Exp.Date </th>
                                            <th scope="col" style="width:72px;">Server</th>
                                            <th scope="col" style="width:72px;">Nodes</th>
                                            <th scope="col" style="width:72px;">Users</th>
                                            <th scope="col" style="width:72px;">Van Sales</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let swReg of _swRegService.allSystemDetails;let i = index "
                                            class="ng-scope">
                                            <td title="{{swReg.BranchName}}">
                                                {{swReg.BranchName}}
                                            </td>
                                            <td>
                                                <select class="form-control" name="{{i}}_t" #{{i}}_t="ngModel"
                                                    [(ngModel)]="swReg.TypeOfBusinessId">
                                                    <option [ngValue]="null" disabled>Select</option>
                                                    <option
                                                        *ngFor="let prf of _businessTypeService.allBusinessTypes | orderBy : 'BType'"
                                                        [ngValue]="prf.Id">
                                                        {{ prf.BType }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input class="form-control" name="{{i}}_d" #{{i}}_d="ngModel"
                                                    type="text" bsDatepicker
                                                    [bsConfig]="{dateInputFormat:'DD-MM-YYYY'}"
                                                    placeholder="Choose Date" style="color: black; text-align: center;"
                                                    [(ngModel)]="swReg.Date" autocomplete="off">
                                            </td>
                                            <td>
                                                <input class="form-control" name="{{i}}_f" type="text"
                                                    #{{i}}_f="ngModel" (change)="calculateRowTotal(i)" (keypress)="_CommonService.decimalPointValidation($event)"
                                                    autocomplete="off" [(ngModel)]="swReg.FreeSupport" placeholder=" Free Support">
                                            </td>
                                            <td>
                                                <input class="form-control" name="{{i}}_e" type="text" bsDatepicker
                                                    #{{i}}_e="ngModel" [bsConfig]="{dateInputFormat:'DD-MM-YYYY'}"
                                                    autocomplete="off" placeholder="Choose Date"
                                                    style="color: black; text-align: center;"
                                                    [(ngModel)]="swReg.ExpiredOn" placeholder=" Expired On">
                                            </td>
                                            <td>
                                                <input class="form-control" name="{{i}}_s" type="text" (keypress)="_CommonService.decimalPointValidation($event)"
                                                    #{{i}}_s="ngModel" autocomplete="off" [(ngModel)]="swReg.Server"
                                                    placeholder=" Server">
                                            </td>
                                            <td>
                                                <input class="form-control" name="{{i}}_n" type="text" (keypress)="_CommonService.decimalPointValidation($event)"
                                                    #{{i}}_n="ngModel" autocomplete="off" [(ngModel)]="swReg.Nodes"
                                                    placeholder=" Nodes">
                                            </td>
                                            <td>
                                                <input class="form-control" name="{{i}}_u" type="text"
                                                    #{{i}}_u="ngModel" autocomplete="off" [(ngModel)]="swReg.Users"
                                                    placeholder=" Users">
                                            </td>
                                            <td>
                                                <input type="text" name="{{i}}_vansales" style="width:50px;" 
                                                    #{{i}}_vansales="ngModel" autocomplete="off"  [(ngModel)]="swReg.VanSales" placeholder="VanSales">
                                            </td>
                                        </tr><!-- end ngRepeat: item in items -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button style="width: 99%" [disabled]="this.isSubmit" type="submit" class="sim-button button1">
                        Submit
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%" type="button" class="sim-button button2"
                        (click)="resetForm(SoftwareRegistrationForm)">
                        Reset
                    </button>
                </div>
                <div class="col-md-4">
                    <button style="width: 99%" type="button" class="sim-button button3"
                        (click)="backList()">Exit</button>
                </div>
            </div>
        </form>
    </div>
</div>