<div class="container-fluid">
    <h2 class="heading_all">Service Report Entry</h2>
    <br>
    <div id="form-wrap">
        <form #projectForm="ngForm" (ngSubmit)="onSubmit(projectForm)">
            <div class="tab-content">
                <div id="sec1" class="container-fluid tab-pane active">
                    <div class="row">
                        <div class="col-md-4">
                            <div *ngIf=" this._CommonService.LoginUser == 'Provider'" class="form-group">
                                <label for="DealerId">Dealer</label>
                                <select class="form-control"
                                    [(ngModel)]="this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId"
                                    #DealerId="ngModel" name="DealerId" (change)="GetRefNo();DelaerChange()" required>
                                    <option [ngValue]="0" disabled>Select Dealer</option>
                                    <option *ngFor="let prf of _dealrService.allDealers | orderBy : 'DealerName'"
                                        [ngValue]="prf.Id">
                                        {{ prf.DealerName }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <div class="row" style="margin-top: 15px;">
                                    <label for="RefNo">Ref.No<span class="validation-error">*</span></label>
                                    <input class="form-control" name="RefNo" #RefNo="ngModel" autocomplete="off"
                                        [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.RefNo"
                                        placeholder=" RefNo" required style="width: 30%;margin-left: 10px">
                                    <div class="validation-error"
                                        *ngIf="(RefNo.invalid && RefNo.touched)||(RefNo.invalid && this.FormValidation)">
                                        This Field is
                                        Required.</div>
                                    <i class="fas fa-search" style="font-size: 1.5rem;margin-top: 5px;margin-left: 5px;"
                                        (click)="SearchSERByRef()" aria-hidden="true"></i>
                                </div>
                            </div>

                            <div class="form-group ">
                                <label for="select">SE</label>
                                <ng-select name="SEID" id="SEID" #SEID="ngModel" bindLabel="EmpName" bindValue="Id"
                                    [disabled]="true" (change)="onSelect(1);LoadBranch();"
                                    [items]="this._CustomerrequirementService.allEmployees"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Se">
                                </ng-select>
                            </div>
                            <div class="form-group">
                                <div class="row" style="margin-top: 15px;">
                                    <div class="col-md-3">
                                        <label>Date<span class="validation-error">*</span></label>
                                        <input class="form-control" style="font-size: .8rem;" placeholder="Date"
                                            #Date="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Date"
                                            autocomplete="off" bsDatepicker (bsValueChange)="Updatedate($event)"
                                            name="currentStringDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                                            required>
                                        <div class="validation-error"
                                            *ngIf="(Date.invalid && Date.touched)||(Date.invalid && this.FormValidation)">
                                            This Field isRequired.</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>From<span class="validation-error">*</span></label>
                                        <input type="time" style="font-size: .6rem;" class="form-control"
                                            placeholder="From Date" name="From" #From="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.From"
                                            autocomplete="off" required>
                                        <div class="validation-error"
                                            *ngIf="(From.invalid && From.touched)||(From.invalid && this.FormValidation)">
                                            This
                                            Field is
                                            Required.</div>
                                    </div>
                                    <div class="col-md-3">
                                        <label>To<span class="validation-error">*</span></label>
                                        <input type="time" style="font-size: .6rem;" class="form-control"
                                            placeholder="To Date" #To="ngModel" name="To"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.To"
                                            autocomplete="off" required>
                                        <div class="validation-error"
                                            *ngIf="(To.invalid && To.touched)||(To.invalid && this.FormValidation)">This Field is Required.</div>
                                    </div>
                                    <div class="col-md-3" style="margin-top:43px;">
                                        <input style="width: 18px;height: 25px;" type="checkbox" id="ExtraTime"
                                            name="ExtraTime" #ExtraTime="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ExtraTime">
                                        <label>+1</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="select">Support Mode<span class="validation-error">*</span></label>
                                <select class="form-control" name="SupportMode" #SupportMode="ngModel"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.SupportMode"
                                    required>
                                    <option [ngValue]="0" disabled>Select Support Mode</option>
                                    <option [ngValue]="4">On Site</option>
                                    <option [ngValue]="5">Remote Section</option>
                                </select>
                                <div class="validation-error"
                                    *ngIf="(SupportMode.invalid && SupportMode.touched)||(SupportMode.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="form-group ">
                                <label for="select">Customer<span class="validation-error">*</span></label>
                                <ng-select name="CustomerId" id="CustomerId" #CustomerId="ngModel" bindLabel="Name" placeholder="Select Customer"
                                    bindValue="Id" (change)="onSelect(1); GetAllModules();LoadBranch()"
                                    [items]="_custService.allCustomers | orderBy : 'Name'" required
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustomerId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(CustomerId.invalid && CustomerId.touched)||(CustomerId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group ">
                                <label>Address</label>
                                <input class="form-control" name="CustAdr1" #CustAdr1="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustAdr1"
                                    placeholder=" Addr1" required><br>
                                <div class="validation-error"
                                    *ngIf="(CustAdr1.invalid && CustAdr1.touched)||(CustAdr1.invalid && this.FormValidation)">
                                    This Field is Required.</div>
                                <input class="form-control" name="CustAdr2" #CustAdr2="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustAdr2"
                                    placeholder=" Addr2" required><br>
                                <div class="validation-error"
                                    *ngIf="(CustAdr2.invalid && CustAdr2.touched)||(CustAdr2.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <input class="form-control" name="CustAdr3" #CustAdr3="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.CustAdr3"
                                    placeholder=" Addr3" required><br>
                                <div class="validation-error"
                                    *ngIf="(CustAdr3.invalid && CustAdr3.touched)||(CustAdr3.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                            <div class="form-group ">
                                <label for="select">Branch<span class="validation-error">*</span></label>
                                <ng-select name="BranchId" id="BranchId" #BranchId="ngModel" placeholder="Select Branch"
                                    [items]="_brnchService.allBranchs" bindValue="Id" bindLabel="BranchName"
                                    (change)="onSelect(2); GetAllModules();"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(BranchId.invalid && BranchId.touched)||(BranchId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>
                            <div class="form-group ">
                                <label>Address</label>
                                <input class="form-control" name="BranchAdr1" #BranchAdr1="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr1"
                                    placeholder=" Addr1" required><br>
                                <div class="validation-error"
                                    *ngIf="(BranchAdr1.invalid && BranchAdr1.touched)||(BranchAdr1.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <input class="form-control" name="BranchAdr2" #BranchAdr2="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr2"
                                    placeholder=" Addr2" required><br>
                                <div class="validation-error"
                                    *ngIf="(BranchAdr2.invalid && BranchAdr2.touched)||(BranchAdr2.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                                <input class="form-control" name="BranchAdr3" #BranchAdr3="ngModel" disabled
                                    autocomplete="off"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BranchAdr3"
                                    placeholder=" Addr3" required><br>
                                <div class="validation-error"
                                    *ngIf="(BranchAdr3.invalid && BranchAdr3.touched)||(BranchAdr3.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                        </div>
                        <div class="col-md-4">

                            <div class="form-group">
                                <label for="select">Project<span class="validation-error">*</span></label>
                                <ng-select name="ProjectId" id="ProjectId" #ProjectId="ngModel" bindLabel="Name" placeholder="Select Project"
                                    bindValue="Id" (change)="GetAllModules();getAllServiceReqPending()"
                                    [items]="_projectService.allProjects" required
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ProjectId">
                                </ng-select>
                                <div class="validation-error"
                                    *ngIf="(ProjectId.invalid && ProjectId.touched)||(ProjectId.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>

                            </div>

                            <div class="form-group ">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="select">Server</label>

                                        <input class="form-control" name="Server" #Server="ngModel" disabled
                                            autocomplete="off"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Server"
                                            placeholder=" Server" required>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="select">Node</label>

                                        <input class="form-control" name="Node" #Node="ngModel" disabled
                                            autocomplete="off"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Node"
                                            placeholder=" Node" required>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Contract Date</label>

                                        <input class="form-control" placeholder="Contract Date" #ContractDate="ngModel"
                                            disabled
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ContractDate"
                                            autocomplete="off" bsDatepicker name="ContractDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-4 "
                                    *ngIf="_ServiceReportEntryService.SelectedServiceRepHeader.ContractDate!=null">
                                    <label style="color:green" class="form-control-label" *ngIf="this.AMCRenewalStatus">
                                        ON
                                        AMC
                                    </label>
                                    <label style="color:red" class="form-control-label" *ngIf="!this.AMCRenewalStatus">
                                        AMC
                                        EXIPIRED
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{this._ServiceReportEntryService.SelectedServiceRepHeader.ContractVsCurrentDate}}</label>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="select">Amc Renewal</label>

                                        <input class="form-control" placeholder="Amc Renewal" disabled
                                            autocomplete="off" bsDatepicker name="AMCRenewal" #AMCRenewal="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                    </div>
                                    <div class="col-md-4">
                                        <label for="select">Amc Upto</label>

                                        <input class="form-control" placeholder="AMC Upto" disabled autocomplete="off"
                                            bsDatepicker name="AMCUpto" #AMCUpto="ngModel"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">

                                    </div>
                                    <div class="col-md-4">
                                        <label for="select">No of Visits</label>

                                        <input type="text" class="form-control" #NumberOfVisit="ngModel" disabled
                                            autocomplete="off"
                                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.NumberOfVisit"
                                            name="NumberOfVisit" placeholder="No of Visits" required>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group ">
                                <label
                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit !=null">
                                    Last
                                    Visit:{{this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit|date}}
                                </label>
                                <label
                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE !=0">
                                    SE:
                                </label>
                                <label
                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE !=0">
                                    <select class="form-control" name="SE" #SE="ngModel" disabled
                                        [(ngModel)]="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE"
                                        required>
                                        <option [ngValue]="0" disabled>Select</option>
                                        <option
                                            *ngFor="let obj of this._CustomerrequirementService.allEmployees | orderBy : 'EmpName'"
                                            [ngValue]="obj.Id">
                                            {{obj.EmpName}}
                                        </option>
                                    </select>
                                </label>
                            </div>
                            <div class="form-group ">
                                <div *ngIf=" _ModuleService.allModuleServiceReport.length>0" class="form-group ">
                                    <label>Modules</label>
                                </div>
                                <div *ngIf=" _ModuleService.allModuleServiceReport.length>0" class="form-group ">
                                    <div class="col-md-12">
                                        <div class="tWrap">
                                            <div class="tWrap__head">
                                                <table>
                                                    <thead>
                                                        <th scope="col">Modules</th>
                                                        <th scope="col">Script</th>
                                                        <th scope="col">Exe</th>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div class="tWrap__body">
                                                <table>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let obj of _ModuleService.allModuleServiceReport | orderBy : 'ModuleName'; ;let i=index">
                                                            <th style="background:white;color:black;">{{obj.ModuleName}}
                                                            </th>
                                                            <th style="background:white;color:black;">
                                                                <input class="section3" placeholder="Date"
                                                                    style="color: black; text-align: center;width: 110px;"
                                                                    autocomplete="off" bsDatepicker
                                                                    #ScriptDate{{i}}="ngModel"
                                                                    (bsValueChange)="Updatedates($event,obj.ModuleName,1)"
                                                                    [ngModel]="obj.ScriptDateToBind"
                                                                    name="ScriptDate{{i}}"
                                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                            </th>
                                                            <th style="background:white;color:black;">
                                                                <input class="section3" placeholder="Date"
                                                                    style="color: black; text-align: center; width: 110px;"
                                                                    autocomplete="off" bsDatepicker
                                                                    [ngModel]="obj.ExeDateToBind" name="ExeDate{{i}}"
                                                                    #ExeDate{{i}}="ngModel"
                                                                    (bsValueChange)="Updatedates($event,obj.ModuleName,2)"
                                                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <br>
                    <div style="text-align: center;">
                        <h6>
                            <ul>Pending Works/Work Done</ul>
                        </h6>
                    </div>
                    <br>
                    <div class="row">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style="width: 5%;">*</th>
                                            <th style="width: 5%;" scope="col">SlNo</th>
                                            <th style="width: 5%;" scope="col">Ref No</th>
                                            <th scope="col">Module</th>
                                            <th scope="col">Particular</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Remarks</th>

                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        <tr
                                            *ngFor="let field of this._ServiceReportEntryService.SelectedServiceDetails ; let i = index">
                                            <td style="width: 5%;">
                                                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="field.CheckStatus" [name]="field.CheckStatus"
                                                    #newAttributeCheckStatus="ngModel">
                                            </td>
                                            <th scope="row" style="width: 5%;background:white;color:black !important">
                                                {{i+1}}</th>
                                            <td style="width: 5%;">{{field.RefNo}}</td>

                                            <td [title]="field.ModuleName">
                                                {{field.ModuleName}}
                                            </td>
                                            <td [title]="field.ParticularName">
                                                {{field.ParticularName}}
                                            </td>

                                            <td [title]="field.Description">
                                                {{field.Description}}
                                            </td>
                                            <td>
                                                <span class="form-control-label"
                                                    *ngFor="let obj of this._ParticularmasterService.allparticular | orderBy : 'Particulars'">
                                                    <p [title]="obj.Particulars" *ngIf="field.Particular==obj.ID">
                                                        {{obj.Particulars}}
                                                    </p>
                                                </span>
                                            </td>
                                            <td>
                                                <span class="form-control-label" *ngFor="let obj of PriorityList">
                                                    <p *ngIf="field.Priority==obj.Id">
                                                        {{obj.Name}}
                                                    </p>
                                                </span>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [disabled]="!field.CheckStatus"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="field.Status"
                                                    [name]="field.Status" #newAttributeStatus="ngModel" required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option [hidden]="field.CheckStatus"
                                                        *ngFor="let obj of StatusFullList | orderBy : 'Name'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                    <option [hidden]="!field.CheckStatus"
                                                        *ngFor="let obj of StatusFullListCustom" [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="field.Remarks"
                                                    autocomplete="off" [disabled]="!field.CheckStatus"
                                                    [ngModelOptions]="{standalone: true}" [name]="field.Remarks"
                                                    #newAttributeRemarks="ngModel" placeholder="Remarks" required>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div style="text-align: center;">
                        <h6>
                            <ul>Customer Requirement</ul>
                        </h6>
                    </div>
                    <br>
                    <div class="row">
                        <div class="tWrap">
                            <div class="tWrap__head">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col">SlNo</th>
                                            <th scope="col">Module</th>
                                            <th scope="col">Particular</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Priority</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="tWrap__body">
                                <table>
                                    <tbody>
                                        <tr
                                            *ngFor="let field of this._ServiceReportEntryService.selectedServiceRequirement; let i = index">
                                            <th style="background:white;color:black">{{i+1}}</th>
                                            <td>
                                                <select style="width: 60%;" [(ngModel)]="field.ModuleId"
                                                    [ngModelOptions]="{standalone: true}" name="{{field.ModuleId}}"
                                                    class="form-control" required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option
                                                        *ngFor="let obj of _ModuleService.allModuleServiceReport | orderBy : 'ModuleName'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.ModuleName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control" name="Particulars"
                                                    [(ngModel)]="field.Particular" name="{{field.Particular}}" required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <ng-container
                                                        *ngFor="let obj of this._ParticularmasterService.allparticular | orderBy : 'Particulars'">
                                                        <option *ngIf="field.ModuleId==obj.ModuleID" [ngValue]="obj.ID">
                                                            {{obj.Particulars}}</option>
                                                    </ng-container>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="field.Description" name="{{field.Description}}"
                                                    (click)="OpenDescPopup(field,0)"
                                                    (keydown)="OpenDescPopup(field,0);$event.stopPropagation();"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Description"
                                                    required>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="field.Type" name="{{field.Type}}"
                                                    [ngModelOptions]="{standalone: true}" required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of TypeList | orderBy : 'Name'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="field.Priority" name="{{field.Priority}}"
                                                    [ngModelOptions]="{standalone: true}" required>
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of PriorityList | orderBy : 'Name'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <div
                                                    *ngIf="field.Status==3||field.Status==4||field.Status==5||field.Status==6||field.Status==7||field.Status==8||field.Status==9">
                                                    <span class="form-control-label" *ngFor="let obj of StatusFullList">
                                                        <p *ngIf="obj.Id==field.Status">
                                                            {{obj.Name}}
                                                        </p>
                                                    </span>
                                                </div>
                                                <div *ngIf="field.Status==1||field.Status==2||field.Status==10">
                                                    <select style="width: 60%;" class="form-control"
                                                        [(ngModel)]="field.Status" name="{{field.Status}}"
                                                        #{{field.Status}}="ngModel"
                                                        [ngModelOptions]="{standalone: true}"
                                                        [disabled]="field.Status==1||field.Status==2||field.Status==10"
                                                        required>
                                                        <option [ngValue]="0" disabled>Select</option>
                                                        <option *ngFor="let obj of StatusList | orderBy : 'Name'"
                                                            [ngValue]="obj.Id">
                                                            {{obj.Name}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" [(ngModel)]="field.Remarks"
                                                    autocomplete="off" name="{{field.Remarks}}"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Remarks"
                                                    required>
                                            </td>
                                            <td>
                                                <button type="button" (click)="deleteFieldValue(i)">-</button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th scope="row" style="background:white;color:black">
                                                {{this._ServiceReportEntryService.RowCount + 1}}</th>
                                            <td>
                                                <select style="width: 60%;"
                                                    [(ngModel)]="this._ServiceReportEntryService.newAttribute.ModuleId"
                                                    name="newAttributeModuleId" #newAttributeModuleId="ngModel"
                                                    (change)="fillparticulars()" class="form-control">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option
                                                        *ngFor="let obj of _ModuleService.allModuleServiceReport | orderBy : 'ModuleName'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.ModuleName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._ServiceReportEntryService.newAttribute.Particular"
                                                    name="newAttributeParticular" #newAttributeParticular="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option
                                                        [hidden]="obj.ModuleID!=this._ServiceReportEntryService.newAttribute.ModuleId"
                                                        *ngFor="let obj of  this._ParticularmasterService.particularList | orderBy : 'Particulars'"
                                                        [ngValue]="obj.ID">
                                                        {{obj.Particulars}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="this._ServiceReportEntryService.newAttribute.Description"
                                                    (click)="OpenDescPopup(this._ServiceReportEntryService.newAttribute,1)"
                                                    (keydown)="OpenDescPopup(this._ServiceReportEntryService.newAttribute,1);$event.stopPropagation();"
                                                    name="newAttributeDescription" #newAttributeDescription="ngModel"
                                                    placeholder="Description">
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._ServiceReportEntryService.newAttribute.Type"
                                                    name="newAttributeType" #newAttributeType="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of TypeList | orderBy : 'Name'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._ServiceReportEntryService.newAttribute.Priority"
                                                    name="newAttributePriority" #newAttributePriority="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of PriorityList | orderBy : 'Name'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <select style="width: 60%;" class="form-control"
                                                    [(ngModel)]="this._ServiceReportEntryService.newAttribute.Status"
                                                    name="newAttributeStatus" #newAttributeStatus="ngModel">
                                                    <option [ngValue]="0" disabled>Select</option>
                                                    <option *ngFor="let obj of StatusList | orderBy : 'Name'"
                                                        [ngValue]="obj.Id">
                                                        {{obj.Name}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" autocomplete="off"
                                                    [(ngModel)]="this._ServiceReportEntryService.newAttribute.Remarks"
                                                    name="newAttributeRemarks" #newAttributeRemarks="ngModel"
                                                    placeholder="Remarks">
                                            </td>
                                            <td>
                                                <button type="button" (click)="addFieldValue()">+</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <label style="color: #008080" for="select">Note:<span style="color: red;">*</span></label>
                        <textarea class="form-control" name="Notes" #Notes="ngModel"
                            [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.Notes" autocomplete="off"
                            placeholder=" Notes" required></textarea>
                        <div class="validation-error"
                            *ngIf="(Notes.invalid && Notes.touched)||(Notes.invalid && this.FormValidation)">This Field
                            is
                            Required.</div>

                    </div>

                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label style="color: #008080" for="ServiceCharge">Old SC:
                                    <!-- <span
                                        style="color: red;">*</span> -->
                                </label>

                                <input type="text" placeholder="Old SC" class="form-control" autocomplete="off"
                                    name="ServiceCharge" #ServiceCharge="ngModel" disabled
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge">
                                <div class="validation-error"
                                    *ngIf="(ServiceCharge.invalid && ServiceCharge.touched)||(ServiceCharge.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label style="color: #008080" for="ServiceCharge">Current SC:
                                    <!-- <span
                                        style="color: red;">*</span> -->
                                </label>

                                <input type="text" placeholder="Current SC" class="form-control" autocomplete="off"
                                    name="ServiceCharge" #ServiceCharge="ngModel"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge">
                                <div class="validation-error"
                                    *ngIf="(ServiceCharge.invalid && ServiceCharge.touched)||(ServiceCharge.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div>
                        <!-- <div class="col-md-2">
                            <div class="form-group ">
                                <label style="color: #008080" for="ServiceCharge">Service Charge:<span
                                        style="color: red;">*</span></label>

                                <input type="text" placeholder="Service Charge" class="form-control" autocomplete="off"
                                    name="ServiceCharge" #ServiceCharge="ngModel"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.ServiceCharge"
                                    required>
                                <div class="validation-error"
                                    *ngIf="(ServiceCharge.invalid && ServiceCharge.touched)||(ServiceCharge.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div> -->
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label style="color: #008080" for="PaidAmount">Paid:
                                    <!-- <span
                                        style="color: red;">*</span> -->
                                </label>

                                <input type="text" placeholder="Paid" class="form-control" name="PaidAmount"
                                    autocomplete="off" #PaidAmount="ngModel"
                                    (change)="onChangeCalc(_ServiceReportEntryService.SelectedServiceRepHeader.PaidAmount)"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.PaidAmount">
                                <div class="validation-error"
                                    *ngIf="(PaidAmount.invalid && PaidAmount.touched)||(PaidAmount.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label style="color: #008080" for="BalanceAmount">Balance:
                                    <!-- <span
                                        style="color: red;">*</span> -->
                                </label>

                                <input type="text" placeholder="Balance" class="form-control" type="number" disabled
                                    name="BalanceAmount" autocomplete="off" #BalanceAmount="ngModel"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.BalanceAmount">
                                <div class="validation-error"
                                    *ngIf="(BalanceAmount.invalid && BalanceAmount.touched)||(BalanceAmount.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label style="color: #008080" for="TransNOtherCharges">
                                    Transportation and Other
                                    Charges:
                                    <!-- <span style="color: red;">*</span> -->
                                </label>

                                <input type="text" placeholder="Transportation and Other Charges" autocomplete="off"
                                    class="form-control" name="TransNOtherCharges" #TransNOtherCharges="ngModel"
                                    [(ngModel)]="_ServiceReportEntryService.SelectedServiceRepHeader.TransNOtherCharges">
                                <div class="validation-error"
                                    *ngIf="(BalanceAmount.invalid && BalanceAmount.touched)||(BalanceAmount.invalid && this.FormValidation)">
                                    This Field is
                                    Required.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <button type="submit" [disabled]="this.SubmitButton" class=" sim-button button1"
                        style="width: 99%; margin-top: 0px;"
                        *ngIf="_ServiceReportEntryService.SelectedServiceRepHeader.Se ==SupportEngId">
                        <p *ngIf="!_ServiceReportEntryService.FormUpdation">Submit</p>
                        <p *ngIf="_ServiceReportEntryService.FormUpdation">Update</p>
                    </button>
                </div>
                <div class="col-md-4">
                    <button type="button" style="width: 99%; margin-top: 0px;" (click)="Reset()"
                        class=" sim-button button2">Reset</button>
                </div>
            </div>

            <!--   Description POP UP -->
            <form #Description="ngForm" *ngIf="OpenDescriptionPopup">
                <div class="popup-container">
                    <div class="popup HoldingReason" (click)="OpenDescriptionPopup=false;">

                        <div class="inner ui-input-container" (click)="$event.stopPropagation();">

                            <div class="title">
                                <p>Description</p> <label for="test-results"> <i class="fas fa-times"
                                        (click)="OpenDescriptionPopup=false;"></i> </label>
                            </div>
                            <div style="margin-top: 25px;">
                                <div class="row">
                                    <div class="col-md-12">
                                        <textarea class="form-control" autofocus name="DescriptionEdit"
                                            id="DescriptionEdit" #DescriptionEdit="ngModel" style="height: 350px;"
                                            [(ngModel)]="_CustomerrequirementService.DescriptionEdit" autocomplete="off"
                                            placeholder=" Description" required>
                                                        </textarea>
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-4">
                                    </div>
                                    <div class="col-md-4">
                                        <button type="button" class="sim-button button1" (click)="SetDescription()"
                                            style="width: 100%;">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </form>
    </div>
</div>