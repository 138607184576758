import { DeleteConfirmComponent } from './../../../Layout/delete-confirm/delete-confirm.component';
import { CommonService } from 'src/app/Services/common.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { JobschedulingService } from 'src/app/Services/jobscheduling.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-jobscheduling',
  templateUrl: './jobscheduling.component.html',
  styleUrls: ['./jobscheduling.component.css']
})
export class JobschedulingComponent implements OnInit {

  errorMsg:string='';
  finished:boolean=false;
  unFinished:boolean=false;
  selectAll:boolean=false;

  constructor(public _JobschedulingService:JobschedulingService,
              public _CommonService:CommonService,
              private toastr: ToastrService, ) { }

  ngOnInit(): void {
    this.reset();
    this.loadCustomer();
    this.loadBatch();
  }

  loadCustomer()
  {
    this._CommonService.getCommonList('ALLCUSTOMER').subscribe(
      data=>{this._JobschedulingService.allCustomer=data},
      error=>{this.errorMsg=error}
    )
  }

  loadBatch()
  {
    this._JobschedulingService.selectedJobScheduling.Id=undefined;
    this._CommonService.getCommonList('BATCHLIST',this._JobschedulingService.selectedJobScheduling.CustomerId??0).subscribe(
      data=>{this._JobschedulingService.allBatch=data
        this.searchClear();
      },
      error=>{this.errorMsg=error}
    )
  }

  loadCustomerWiseUnScheduledJobs()
  {
    this.loadBatch();
    this._CommonService.isLoading=true;
    this._JobschedulingService.getUnSheduledJobs('UNSCHEDULEDJOBS').subscribe(
      data=>{this._JobschedulingService.allUnScheduledJobs=data;
        this._CommonService.isLoading=false;
      },
      error=>{this.errorMsg=error
        this._CommonService.isLoading=false;}
    )
  }

  selectedJobs(type:number,obj:any,index:number)
  {
    if(type==1)
    {
      this._JobschedulingService.selectedJobScheduling.SelectedJobList=[];
      this._JobschedulingService.allUnScheduledJobs.forEach(e=>{
          e.Chk=this.selectAll?true:false;
      if(this.selectAll)
        this._JobschedulingService.selectedJobScheduling.SelectedJobList.push(e.ActivityId);
      })   
    }
    else
    {
      if(obj.Chk)
        this._JobschedulingService.selectedJobScheduling.SelectedJobList.push(obj.ActivityId);
      else
      {  
        const indexToRemove = this._JobschedulingService.selectedJobScheduling.SelectedJobList.findIndex(item => item == obj.ActivityId);
        this._JobschedulingService.selectedJobScheduling.SelectedJobList.splice(indexToRemove,1);
      }
    }
  }

  validate():boolean{
    let result=true;
    if(this._JobschedulingService.selectedJobScheduling.BatchName=='' || this._JobschedulingService.selectedJobScheduling.CustomerId==undefined
      || this._JobschedulingService.selectedJobScheduling.SelectedJobList.length==0 || this._JobschedulingService.selectedJobScheduling.DeliveryDate==''
      || this._JobschedulingService.selectedJobScheduling.DevelopmentDate=='' || this._JobschedulingService.selectedJobScheduling.TestingDate=='')
      {
        this.toastr.info('Please fill the fileds','Job Scheduling');
        result=false;
      }
    return result;
  }

  onSubmit(frm:NgForm)
  {
    if(frm.valid && this.validate())
    {
      this._JobschedulingService.scheduledJobTrans(this._JobschedulingService.selectedJobScheduling,'SAVE').subscribe(
        data=>{
          if(data.Status==1)
          {
            this.toastr.success(data.Message,'Job Scheduling');
            this.reset();
          }
          else
            this.toastr.info(data.Message,'Job Scheduling');
        },error=>{
          this.errorMsg=error
          this.toastr.error('Something went wrong','Job Scheduling')
        }
      )
    }
  }

  search()
  {
    if(this._JobschedulingService.selectedJobScheduling.Id==undefined)
    {
      this.toastr.info('Please select one batch','Job Scheduling');
      return;
    }
    let transmode:string='';
    this._CommonService.isLoading=true;
    this._JobschedulingService.allUnScheduledJobs=[];
    transmode=((this.finished && this.unFinished) || (!this.finished && !this.unFinished)) ?'BOTHSCHEDULEDJOBS':this.finished?'FINISHEDSCHEDULEDJOBS':'UNFINISHEDSCHEDULEDJOBS';
    this._JobschedulingService.getSheduledJobs(transmode,this._JobschedulingService.selectedJobScheduling.Id??0).subscribe(
      data=>{
        this._JobschedulingService.allUnScheduledJobs=data.JobsList;
        this._JobschedulingService.selectedJobScheduling.VDate=data.VDate;
        this._JobschedulingService.selectedJobScheduling.CustomerId=data.CustomerId;
        this._JobschedulingService.selectedJobScheduling.BatchName=data.BatchName;
        this._JobschedulingService.selectedJobScheduling.DesignDate=data.DesignDate;
        this._JobschedulingService.selectedJobScheduling.DevelopmentDate=data.DevelopmentDate;
        this._JobschedulingService.selectedJobScheduling.TestingDate=data.TestingDate;
        this._JobschedulingService.selectedJobScheduling.DeliveryDate=data.DeliveryDate;
        this._JobschedulingService.selectedJobScheduling.SelectedJobList=data.RetriveJobList;
        this._CommonService.isLoading=false;
      },
      error=>{this.errorMsg=error
        this._CommonService.isLoading=false;
      }
    )
  }

  searchClear()
  {
    this._JobschedulingService.selectedJobScheduling={
      VDate:new Date(),CustomerId:this._JobschedulingService.selectedJobScheduling.CustomerId,BatchName:'',DesignDate:'',DevelopmentDate:'',TestingDate:'',DeliveryDate:'',SelectedJobList:[]
    }
    this._JobschedulingService.allUnScheduledJobs=[];
    this._JobschedulingService.selectedJobScheduling.SelectedJobList=[];
  }

  reset(form?: NgForm)
  {
    if (form != null)
      form.reset();
    this._JobschedulingService.ClearValue();
    this.finished=false;
    this.unFinished=false;
    this.selectAll=false;
    this.loadBatch();
  }

  delete()
  {
    if(this._JobschedulingService.selectedJobScheduling.Id > 0)
    {
      this._JobschedulingService.scheduledJobTrans(this._JobschedulingService.selectedJobScheduling,'DELETE').subscribe(
        data=>{
          if(data.Status==1)
          {
            this.toastr.success(data.Message,'Job Scheduling');
            this.reset();
            this.loadBatch();
          }
          else
            this.toastr.info(data.Message,'Job Scheduling');
        },error=>{
          this.errorMsg=error
          this.toastr.error('Something went wrong','Job Scheduling')
        }
      )
    }
  }

  toggleFilter() {
    this._JobschedulingService.isFiltering = this._JobschedulingService.isFiltering ? false : true;
    if (!this._JobschedulingService.isFiltering) {
      this._JobschedulingService.ClearFilter();
      this._CommonService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._JobschedulingService.orderByColName) {
      this._JobschedulingService.orderByDir = !this._JobschedulingService.orderByDir;
    }
    this._JobschedulingService.orderByColName = colName;
  }

}
