import { error } from '@angular/compiler/src/util';
import { Component, OnInit, AfterViewInit, VERSION, ViewChild } from '@angular/core';
import { customer } from 'src/app/Models/customer.model';
import { BranchService } from 'src/app/Services/branch.service';
import { ToastrService } from 'ngx-toastr';
import { RecieptService } from 'src/app/Services/reciept.service';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { ReceiptPaymentDataList, Receipts } from 'src/app/Models/Receipts';
import { CustomerService } from 'src/app/Services/customer.service';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.css']
})
export class ReceiptsComponent implements OnInit {

  public errorMsg;
  popUpIndex:boolean=false;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation
  currentDealerId:number=null;
  currentCustomerId:number=null;
  selectedVoucher:any=null;

  constructor(public _brnchService: BranchService,
    private toastr: ToastrService,
    public _RecieptService: RecieptService,
    public _CommonService: CommonService,
    public _dealrService: DealerService,
    public _custService: CustomerService
  ) { }

  ngOnInit(): void {
    this.resetForm();
  }

  async GetVNo() {
    this._RecieptService.selectedReciept.VNo = parseInt(await this._CommonService.getVNo('RECEIPT'));
  }

  closePopUp() {
    this.popUpIndex = false;
    this._RecieptService.totalSelectedDue=null;
    this._RecieptService.totalSelectedPaid=null;
    this._RecieptService.totalSelectedBalance=null;
    this._CommonService.chkSelectAll=false;
  }
  
  resetSelectedReceipt(){
    this._RecieptService.selectedReciept = {
      Id: null,
      VNo: null,
      DealerId: this.currentDealerId,
      CustomerId: this.currentCustomerId,
      Date:new Date(),
      TotalDue: 0,
      Received: 0,
      Discount: 0,
      Balance: 0,
      Remark: '',
      ReceiptDtls: [],
    }
    this._RecieptService.totalSelectedDue=null;
    this._RecieptService.totalSelectedPaid=null;
    this._RecieptService.totalSelectedBalance=null;
  }

  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.resetSelectedReceipt();
    this.LoadDealer();
    this.loadDealerWiseCustomers();
    this.GetVNo();
    this._RecieptService.allReceiptDtls = [];
    this._RecieptService.allDues = [];
    this.FormValidation=false;
    this._CommonService.dueEnabled=true;
    this._CommonService.chkSelectAll=false;
  }

  LoadDealer() {
    this._dealrService.FetchDealer('ALL')
      .subscribe(data => {
        this._dealrService.allDealers = data
      })
  }

  loadFullCustomers() {
    this._custService.FetchAllCustomer('ALL')
      .subscribe(data => { 
      if(this._RecieptService.selectedReciept.DealerId > 0)
      {
        this._custService.allCustomers  = data.filter(e=>
          e.DealerId==this._RecieptService.selectedReciept.DealerId
        )
      } 
      else
        this._custService.allCustomers=data  
      },
        error => {
          this.errorMsg = error;
        });
    }

  loadDealerWiseCustomers() {
    this._RecieptService.selectedReciept.CustomerId=null;
    this._CommonService.dueCustomerList(this._RecieptService.selectedReciept.DealerId==null?0:this._RecieptService.selectedReciept.DealerId).subscribe(res=>{
      this._CommonService.dueCustomers=res;
    }),
      error => {
        this.errorMsg = error;
      };
  }

  getDueData() {
    this._RecieptService.totalSelectedDue=null;
    this._RecieptService.totalSelectedPaid=null;
    this._RecieptService.totalSelectedBalance=null;
    if(this._RecieptService.selectedReciept.CustomerId !=null && this._RecieptService.selectedReciept.DealerId !=null)
    {
      this._CommonService.isLoading = true;
      this._RecieptService.getReceiptPaymentData(this._RecieptService.selectedReciept.CustomerId,this._RecieptService.selectedReciept.DealerId).subscribe(obj => {
        if (obj.length != 0) {
          this._RecieptService.allDues = obj;
          this.popUpIndex=true;  
          this._CommonService.isLoading = false; 
        }
        else {
          this._CommonService.isLoading = false;
          this.toastr.warning('There is no Receipt Found', 'Receipt')
        }
      });
    }
    else
    {
      if(this._RecieptService.selectedReciept.DealerId ==null)
      {
        this.toastr.warning('Please Select the Dealer', 'Receipt')
        return
      }
      else (this._RecieptService.selectedReciept.CustomerId ==null)
        this.toastr.warning('Please Select the Customer', 'Receipt')
    }  
  }

  selectAllReceipts()
  {
    this._RecieptService.allDues.forEach(obj=>{
      obj.CheckStatus=this._CommonService.chkSelectAll
      if(obj.CheckStatus)
      {
        this._RecieptService.totalSelectedDue += Number(obj.Due);
        this._RecieptService.totalSelectedPaid += Number(obj.Paid);
        this._RecieptService.totalSelectedBalance = Number(obj.Balance);
      }
      else
      {
        this._RecieptService.totalSelectedDue=null;
        this._RecieptService.totalSelectedPaid=null;
        this._RecieptService.totalSelectedBalance=null;
      }
    }) 
    this._RecieptService.totalSelectedDue=parseFloat(this._RecieptService.totalSelectedDue.toFixed(2))
    this._RecieptService.totalSelectedPaid=parseFloat(this._RecieptService.totalSelectedPaid.toFixed(2))
    this._RecieptService.totalSelectedBalance=parseFloat(this._RecieptService.totalSelectedBalance.toFixed(2))
  }

  calculateDue(obj:ReceiptPaymentDataList) {
    if(obj.CheckStatus)
    {     
      // this._RecieptService.totalSelectedDue += Number(obj.Due);
      // this._RecieptService.totalSelectedPaid += Number(obj.Paid);
      // this._RecieptService.totalSelectedBalance = +Number(obj.Balance);
      this._RecieptService.allDues.forEach((item) => {
        if (item.VNo !== obj.VNo) {
          item.CheckStatus = false;
        }
      });
      this._RecieptService.totalSelectedDue = Number(obj.Due);
      this._RecieptService.totalSelectedPaid = Number(obj.Paid);
      this._RecieptService.totalSelectedBalance = Number(obj.Balance);
    }
    else
    {
      // this._RecieptService.totalSelectedDue =this._RecieptService.totalSelectedDue - obj.Due;
      // this._RecieptService.totalSelectedPaid =this._RecieptService.totalSelectedPaid - obj.Paid;
      // this._RecieptService.totalSelectedBalance =this._RecieptService.totalSelectedBalance - obj.Balance;
      this._RecieptService.totalSelectedDue = 0;
      this._RecieptService.totalSelectedPaid = 0;
      this._RecieptService.totalSelectedBalance = 0;
    }
    this._RecieptService.totalSelectedDue=parseFloat(this._RecieptService.totalSelectedDue.toFixed(2))
    this._RecieptService.totalSelectedPaid=parseFloat(this._RecieptService.totalSelectedPaid.toFixed(2))
    this._RecieptService.totalSelectedBalance=parseFloat(this._RecieptService.totalSelectedBalance.toFixed(2))
  }

  loadBalance() {
    this._RecieptService.selectedReciept.TotalDue=this._RecieptService.totalSelectedDue;
    if (this._RecieptService.selectedReciept.Received != null) {
      if(this._RecieptService.selectedReciept.TotalDue > this._RecieptService.selectedReciept.Received)
        this._RecieptService.selectedReciept.Balance = this._RecieptService.selectedReciept.TotalDue - this._RecieptService.selectedReciept.Received - this._RecieptService.selectedReciept.Discount;
    }
    else
    {
      this._RecieptService.selectedReciept.Balance=0;
      this._RecieptService.selectedReciept.Discount=0;
    }
  }

  loadSelectedReceipt() {
    this._RecieptService.allDues.forEach(obj => {
      if(obj.CheckStatus)
      {
        this._RecieptService.allReceiptDtls.push({
          VNo: obj.VNo,
          VType: obj.VType,
          EntryTypeId:obj.EntryTypeId,
          DueDate: obj.DueDate,
          AgreedDate: new Date(obj.AgreedDate),
          Due: obj.Due,
          Paid: obj.Paid,
          Balance: obj.Balance
        });
      }  
    })
    this._RecieptService.selectedReciept.Received=this._RecieptService.totalSelectedDue;
    this.loadBalance();
    this.popUpIndex=false;
  }

  validation(): boolean {
    if(this._RecieptService.selectedReciept.DealerId == null)
    {
      this.toastr.warning("Please Select the Dealer", "Receipt")
      return false;
    }
    if(this._RecieptService.selectedReciept.Balance < 0)
    {
      this.toastr.warning("Amount Must be Equal to OR Less Than Total Due", "Receipt")
      return false;
    }
    if(this._RecieptService.selectedReciept.Received==0)
    {
      this.toastr.warning("Invalid Received Amount", "Receipt")
      return false;
    }
    if(this._RecieptService.allReceiptDtls.length==0)
    {
      this.toastr.warning("Please Select One Receipt", "Receipt")
      return false;
    }
    if (this._RecieptService.selectedReciept.VNo != 0 && this._RecieptService.selectedReciept.Date != null
      && this._RecieptService.selectedReciept.CustomerId != null && (this._RecieptService.selectedReciept.Received !=null))
      return true;
    else
    {
      this.toastr.warning('Please Fill All Mandatory Fields.', 'Receipt');
      return false;
    }
  }

  onSubmit(frm: NgForm) {
    if (this.validation()) {
      this.isSubmit = true
      this._RecieptService.SaveItem(this._RecieptService.selectedReciept, this._RecieptService.allReceiptDtls).subscribe(
         data => {
          this.isSubmit = false
          if (data.Status == 200) {
            this.currentDealerId=this._RecieptService.selectedReciept.DealerId;
            this.currentCustomerId=this._RecieptService.selectedReciept.CustomerId;
            this.toastr.success(data.Message, "Receipt")
            this.resetForm();
            this._RecieptService.selectedReciept.CustomerId=this.currentCustomerId;
          }
          else 
            this.toastr.error(data.Message, "Receipt")
        }
      );
    }
  }

  searchReceipt()
  {
    this.loadFullCustomers();
    this._RecieptService.getReceipt(this._RecieptService.selectedReciept.VNo).subscribe(
      data=>
      {
        this._RecieptService.selectedReciept=data;
        this._RecieptService.allReceiptDtls=data.ReceiptDtls;
        if(this._RecieptService.selectedReciept.Id > 0) {
          this._CommonService.dueEnabled=false;
        }
        else
        {
          this.resetForm();
          this.toastr.warning('Vno Does Not Exist', 'Receipt')
        }
      }
    )
  }

  deleteReceipt()
  {
    if(this._RecieptService.selectedReciept.Id==null)
    {
      this.toastr.info("Please select the voucher", "Receipt")
      return;
    }
    this._RecieptService.deleteReceipt().subscribe(data=>
      {
        if(data.Status==200)
          {
            this.currentDealerId=null;
            this.currentCustomerId=null;
            this.toastr.success(data.Message, "Receipt")
            this.resetForm();
          }
        else if(data.Status==400)
          this.toastr.warning(data.Message, "Receipt")
        else
          this.toastr.error(data.Message, "Receipt")
      })
  }
}
