import { Injectable } from '@angular/core';
import { ServiceRepHeader, ServiceDetails, ServiceRequirement } from '../Models/serviceReport.model';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ModuleService } from './module.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceReportEntryService {

  SelectedServiceRepHeader: ServiceRepHeader
  // SelectedServiceModules: ServiceModules[]
  SelectedServiceDetails: ServiceDetails[]
  selectedServiceRequirement: ServiceRequirement[]
  public currentStringDate: any;

  public newAttribute: any = {};
  RowCount: any;
  FormUpdation: boolean = false

  constructor(private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService, public _ModuleService: ModuleService) { }

  SaveItem(Values: ServiceRepHeader): Observable<any> {

    var body = {
      RefNo: Values.RefNo,
      Date: this.currentStringDate,
      From: Values.From,
      To: Values.To,
      Se: Values.Se,
      BranchId: Values.BranchId,
      CustomerId: Values.CustomerId,
      ProjectId: Values.ProjectId,
      ExtraTime: Values.ExtraTime,
      ServiceCharge: Values.ServiceCharge,
      PaidAmount: Values.PaidAmount,
      BalanceAmount: Values.BalanceAmount,
      TransNOtherCharges: Values.TransNOtherCharges,
      SupportMode: Values.SupportMode,
      Notes: this.SelectedServiceRepHeader.Notes,
      ServiceDtls: JSON.stringify(this.SelectedServiceDetails.filter(obj => obj.CheckStatus == true)),
      CusReqDtls: JSON.stringify(this.selectedServiceRequirement),
      ModuleUpdationDate: JSON.stringify(this._ModuleService.allModuleServiceReport.filter(obj => obj.ScriptDateToBind != null && obj.ExeDateToBind != null)),
      CusAttachementList: null,
      DealerId: this.SelectedServiceRepHeader.DealerId
    }
    let params = new HttpParams();
    params = params.append('Type', this.FormUpdation == false ? 'SAVE' : 'UPDATE')

    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "ServiceReportEntry/InsUpdServiceReportEntry", body, {
      headers,
      params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  GetServRptPendingWorks(SeId: number, ProjectId: number, DealerId: number, CustomerID: number, BranchId: number): Observable<ServiceDetails[]> {
    return this.http.get<any>(ROOT_URL + 'ServiceReportEntry/GetServRptPendingWorks?SeId=' + SeId + "&ProjectId=" + ProjectId + "&DealerId=" + DealerId + "&CustomerID=" + CustomerID + "&BranchId=" + BranchId)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }
  getServiceReportEntry(DealerId: number, RefNo: number): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<any>(ROOT_URL + 'ServiceReportEntry/getServiceReportEntryByRefNo?RefNo=' + RefNo + '&DealerId=' + DealerId, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
}
