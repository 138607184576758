import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/Services/customer.service';
import { customer } from 'src/app/Models/customer.model';
import { AppConstants } from 'src/app/Models/constants';
import { CommonService } from 'src/app/Services/common.service';


@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  public errorMsg;
  public term: string = ''
  public deletePopUp: boolean = false;
  deleteID: number = 0;

  constructor(public _custService: CustomerService,
    public _CommonService: CommonService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this._custService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.getallCustomers();
    this._custService.formHideShow = 0;
    this._CommonService.FormID = 'Customer';
  }

  getallCustomers() {
    this._CommonService.isLoading = true
    this._custService.FetchAllCustomer('DEALERID')
      .subscribe(data => {
        this._custService.allCustomers = data
        this._CommonService.isLoading = false
      },
        error => {
          this._CommonService.isLoading = false
          this.errorMsg = error;
        })
  }

  toggleFilter() {
    this._custService.isFiltering = this._custService.isFiltering ? false : true;
    if (!this._custService.isFiltering) {
      this._custService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._custService.pageIndex = 1;
    }
  }

  //Sorting
  sort(colName: string) {
    if (colName == this._custService.orderByColName) {
      this._custService.orderByDir = !this._custService.orderByDir;
    }
    this._custService.orderByColName = colName;
  }

  NoOfItems: number = 10;
  async addList() {
     this._custService.selectedCustomer.Code = await this._CommonService.getCode('CUSTOMER');
   // alert(this._custService.selectedCustomer.Code)
    this._custService.imageUrl = AppConstants.tockenURL + "Images/Logo/default.jpg";
    this._custService.formHideShow = 1;
    this._custService.formHidePassword = false;
  }

  showForEdit(cust: customer) {
    this._custService.selectedCustomer = Object.assign({}, cust);
    this._custService.formHideShow = 1;
    this._custService.formHidePassword = true;
    this._custService.imageUrl = AppConstants.tockenURL + "Images/CustomerLogo/" + this._custService.selectedCustomer.LogoURL;

  }

  onDelete(cust: customer) {
    this.deletePopUp = true;
    this.deleteID = cust.Id;
  }

  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._custService.deleteCustomer(this.deleteID, (this._custService.allCustomers.filter(e => e.Id == this.deleteID)[0].LogoURL))
        .subscribe(x => {
          this.getallCustomers();
          this.toastr.success("Deleted Successfully", "Customer");
          this.deleteID = 0;
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.error("Deletion Failure", "Customer");
        }
        )
    }

  }
}
