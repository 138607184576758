import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { ParticularMaster } from '../Models/ParticularMaster';
import { GlobalErrorHandlerService } from './global-error-handler.service'

@Injectable({
  providedIn: 'root'
})
export class ParticularmasterService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }

  selectedRecord: ParticularMaster;
  particularList: ParticularMaster[];
  formHideShow = 0;
  allparticular: ParticularMaster[];
  pageIndex: number = 0;
  isFiltering: boolean = false;
  ModuleNameFilter: string = '';
  ParticularsFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;
  // totalPageCount: number = 0;
  // itemCountPerPage: number = 10;
  // filterdItemCount: number = 0;
  clearValue() {
    this.selectedRecord = {
      ID: 0,
      ModuleID: 0,
      ModuleName: '',
      Particulars: ''
    }
  }
  ClearFilter() {
    this.isFiltering = false;
    this.ModuleNameFilter = '';
    this.ParticularsFilter = '';

  }
  getParticularMaster(TransMode: string): Observable<ParticularMaster[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ParticularMaster[]>(ROOT_URL + 'ParticularMaster/GetAllData', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  SaveItem(Values: ParticularMaster): Observable<any> {

    var body = {
      Id: Values.ID,
      ModuleID: Values.ModuleID,
      Particulars: Values.Particulars
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "ParticularMaster/InsUpdDelParticularMaster", body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }
  onDelete(Id: number) {
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.delete<any>(ROOT_URL + 'ParticularMaster/DeleteParticularMaster', {
      headers, params

    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))

      );
  }
}
