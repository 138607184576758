import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProjectManagerHomeComponent } from './project-manager-home/project-manager-home.component';
import { CustomerRequirementsComponent } from '../../Admin/Support-Engineer/customer-requirements/customer-requirements.component';
import { ServiceReportEntryComponent } from '../../Admin/Support-Engineer/service-report-entry/service-report-entry.component';
import { ProWorkAllotmentComponent } from '../Project Leader/pro-work-allotment/pro-work-allotment.component';
import { WorkAllocationStatusComponent } from './work-allocation-status/work-allocation-status.component';
import { JobCardComponent } from '../Developer/job-card/job-card.component';
import { BacklogsComponent } from '../Reports/backlogs/backlogs.component';
import { TodaysDeliveryComponent } from '../Reports/todays-delivery/todays-delivery.component';
import { ServicechargeComponent } from './servicecharge/servicecharge.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { CloudchargeComponent } from './cloudcharge/cloudcharge.component';
import { PaymentPostphonedComponent } from './payment-postphoned/payment-postphoned.component';
import { WorkDetailsComponent } from './work-details/work-details.component';
import { OtherChargesComponent } from './other-charges/other-charges.component';
import { CscancellationComponent } from './cloudcharge/cscancellation/cscancellation.component';
import { CscancellationFormComponent } from './cloudcharge/cscancellation/cscancellation-form/cscancellation-form.component';
import { CscancellationListComponent } from './cloudcharge/cscancellation/cscancellation-list/cscancellation-list.component';
import { ProjectCostAnalysisComponent } from '../MIS/project-cost-analysis/project-cost-analysis.component';


const routes: Routes = [
  { path: '', component: ProjectManagerHomeComponent },
  { path: 'customerrequirement', component: CustomerRequirementsComponent, },
  { path: 'servicereportentry', component: ServiceReportEntryComponent, },
  { path: 'prowork', component: ProWorkAllotmentComponent, },
  { path: 'jobcard', component: JobCardComponent },
  { path: 'Backlogs', component: BacklogsComponent },
  { path: 'TodaysDelivery', component: TodaysDeliveryComponent },
  { path: 'workstatus', component: WorkAllocationStatusComponent, },
  { path: 'servicecharge', component: ServicechargeComponent, },
  { path: 'receipts', component: ReceiptsComponent, },
  { path: 'cloudcharge', component: CloudchargeComponent, },
  { path: 'cscancellation', component: CscancellationComponent, },
  { path: 'paymentpostphoned', component: PaymentPostphonedComponent, },
  { path: 'workdetails', component: WorkDetailsComponent, },
  { path: 'otherCharges',component:OtherChargesComponent}
]
@NgModule({
  declarations: [],
  imports: [
    CommonModule, RouterModule.forChild(routes),
  ]
})
export class ProjectmanagerModule { }
