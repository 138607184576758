<div class="container-fluid">
    <div class="row flex-body">
        <div class="col-md-9 offset-md-2 FormSec">
            <form #projectForm="ngForm" (ngSubmit)="onSubmit(projectForm)">
                <!-- <input type="hidden" name="Id" #Id="ngModel" [(ngModel)]="_prjctService.selectedProject.Id"> -->
                <div class="row FormHeader sim-button_head">
                    <h3>Spare Parts </h3>
                </div>
                <div class="col-md-12 FormBox">
                    <div class="form-group row">
                        <label class="col-md-4 form-control-label">Code:</label>

                        <div class="form-group col-md-6">
                            <input class="form-control" placeholder=" Code" required autocomplete="off">
                            <!-- <div class="validation-error" *ngIf="Code.invalid && Code.touched">This Field is Required.</div> -->
                        </div>

                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 form-control-label">Name:</label>
                        <div class="form-group col-md-6">
                            <input class="form-control" name="Name" placeholder=" Name" required autocomplete="off">
                            <!-- <div class="validation-error" *ngIf="Name.invalid && Name.touched">This Field is Required.</div> -->
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-4 form-control-label">Rate:</label>

                        <div class="form-group col-md-6">
                            <input class="form-control" placeholder=" Rate" required autocomplete="off">
                            <!-- <div class="validation-error" *ngIf="Code.invalid && Code.touched">This Field is Required.</div> -->
                        </div>

                    </div>
                    <div class="btnactns">
                        <button [disabled]="!projectForm.valid" type="submit" class="sim-button button1">Submit</button>
                        <button type="button" class="sim-button button2" (click)="resetForm(projectForm)">Reset</button>
                        <button type="button" class="sim-button button3" (click)="backList()">Back</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>