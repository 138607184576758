import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { strict } from 'assert';
import { SEDashBoardCustReq, SECustomDashBoardCustReq } from '../Models/Dashboard.Model';
import { WorkSortingAssigning } from '../Models/WorkSorting.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceEngineerDBService {

  SelectedSEDashBoardCustReq: SECustomDashBoardCustReq[]
  AllWorkSortingDet: WorkSortingAssigning[]
  selectedDate: Date
  allSEDashBoardCustReq: SECustomDashBoardCustReq
  constructor(private http: HttpClient, public _GlobalErrorHandlerService: GlobalErrorHandlerService) { }

  getPendingWorks(): Observable<SECustomDashBoardCustReq[]> {
    return this.http.get<any>(ROOT_URL + 'DashBoard/getPendingWorks')
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  //For Common
  getPendingWorksDet(RefNo: number, Vtype: number, DealerId: number, TransMode: string): Observable<WorkSortingAssigning[]> {
    return this.http.get<any>(ROOT_URL + 'DashBoard/getPendingWorksDet?RefNo=' + RefNo + '&Vtype=' + Vtype + '&TransMode=' + TransMode + '&DealerId=' + DealerId)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  getHoldReason(RefNo: number, Vtype: number, DealerId: number, TransMode: string): Observable<any> {
    return this.http.get<any>(ROOT_URL + 'DashBoard/getHoldReason?RefNo=' + RefNo + '&Vtype=' + Vtype + '&TransMode=' + TransMode + '&DealerId=' + DealerId)
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }

  



}
