import { Component, Input, OnInit } from '@angular/core';
import { EmployeeService } from 'src/app/Services/employee.service';

@Component({
  selector: 'app-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.css']
})
export class ContextmenuComponent implements OnInit {

  @Input() x=0;
  @Input() y=0;
  
  menuList=[
    {id:1, menu:'Employee Work Shedule'}
    // {id:2, menu:'Employee Work History'}
  ]

  constructor(public employeeService:EmployeeService) { }

  ngOnInit(): void {

  }

  employeeActivity(obj)
  {
    this.employeeService.employeepopup= obj.id;
  }

}
