import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/Services/common.service';
import { DomainService } from 'src/app/Services/domain.service';

@Component({
  selector: 'app-domain-form',
  templateUrl: './domain-form.component.html',
  styleUrls: ['./domain-form.component.css']
})
export class DomainFormComponent implements OnInit {

  public errorMsg;
  isSubmit: boolean 
  formValidation: boolean

    constructor(
      public _Service: DomainService,
      public _CommonService:CommonService,
      private toastr: ToastrService,
    ) { }
  ngOnInit(): void {
    this._Service.formHideShow = 0;
    this.resetForm();
    this.fillServer();
    this.fillCustomer();
  }

  fillServer()
  {
    this._CommonService.getCommonList("ALLSERVER").subscribe(
      data=>{
        this._Service.allServer=data;
      }
    )
  }
  fillCustomer()
  {
    this._CommonService.getCommonList("ALLCUSTOMER").subscribe(
      data=>{
        this._Service.allCustomer=data;
      }
    )
  }

  validation(): boolean {
    if (this._Service.selectedRecord.DomainName != "" && this._Service.selectedRecord.CustomerID > 0 && this._Service.selectedRecord.ServerID > 0)
      return true;
    else
      return false;
  }

  onSubmit(frm: NgForm) {
    this.formValidation = true;
    if (frm.valid && this.validation()) {
      this.isSubmit = true;
        this._Service.trans(this._Service.selectedRecord).subscribe(
          async data => {
            this.isSubmit = false;
            if (data.Status == 1) {
              this._Service.formHideShow = 0;
              this.toastr.success(data.Message, "Domain Master")
              this.resetForm();
              this._Service.get("ALL").subscribe(data => this._Service.allDomain = data,
                error => {
                  this.errorMsg = error;
                });
            }
            else if (data.Status == 2) {
              this.toastr.warning(data.Message, "Domain Master")
              return;
            }
            else {
              this.toastr.warning(data.Message, "Domain Master")
            }
          }
        );
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Domain Master');
      this.isSubmit = false;
    }
  }

  backList() {
    this.resetForm();
    this.isSubmit = false;
    this.formValidation = false
    this._Service.formHideShow = 0;
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false;
    this.formValidation = false;
    if (form != null)
      form.reset();
     this._Service.clearValue();
  }
}
