<div class="container-fluid" *ngIf="_Service.formHideShow!=0">
    <h2 class="heading_all">SERVER PROVIDER MASTER</h2>
    <br>
    <div id="form-wrap">
        <form #serverProviderForm="ngForm" (ngSubmit)="onSubmit(serverProviderForm)">
            <div class="col-md-12">
                <div class="form-group row">
                    <label>Code<span class="validation-error">*</span></label>
                    <input name="Code" #Code="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Code" placeholder=" Code" required>
                    <div class="validation-error"
                        *ngIf="(Code.invalid && Code.touched)||(Code.invalid &&this.formValidation)">This Field is Required.</div>
                </div>
                <div class="form-group row">
                    <label>Name<span class="validation-error">*</span></label>
                    <input name="Name" #Name="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Name" placeholder="Name" required>
                    <div class="validation-error"
                        *ngIf="(Name.invalid && Name.touched)||(Name.invalid &&this.formValidation)">This Field is Required.</div>
                </div>
                <div class="form-group row">
                    <label>Address<span class="validation-error">*</span></label>
                    <textarea  style="width: 100%;"  rows="1"  name="Address" #Address="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Address" placeholder="Address" required></textarea>
                    <div class="validation-error"
                        *ngIf="(Address.invalid && Address.touched)||(Address.invalid &&this.formValidation)">This Field is Required.</div>
                </div>
                <div class="form-group row">
                    <label>Contact Person</label>
                    <input name="ContactPerson" #ContactPerson="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.ContactPerson" placeholder="Contact Person">
                </div>
                <div class="form-group row">
                    <label>Contact Number</label>
                    <input name="ContactNumber" #ContactNumber="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.ContactNumber" placeholder="Contact Number">
                </div>
                <div class="form-group row">
                    <label>Email</label>
                    <input type="email" name="Email" #Email="ngModel" autocomplete="off"
                        [(ngModel)]="_Service.selectedRecord.Email" placeholder="Email" >
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <button [disabled]="this.isSubmit" style="width: 99%; margin-top: 25px;" type="submit"
                            class="sim-button button1">Submit</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button2"
                            (click)="resetForm(serverProviderForm)">Reset</button>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="width: 99%; margin-top: 25px;" class="sim-button button3"
                            (click)="backList()">Exit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>