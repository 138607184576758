import { Component, OnInit } from '@angular/core';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { ToastrService } from 'ngx-toastr';
import { nodeHDR } from 'src/app/Models/node.model';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import * as CryptoJS from 'crypto-js';
import { SoftwareRegistrationService } from 'src/app/Services/software-registration.service';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-node-reg-list',
  templateUrl: './node-reg-list.component.html',
  styleUrls: ['./node-reg-list.component.css']
})
export class NodeRegListComponent implements OnInit {

  constructor(public _custService: CustomerService,
    public _CommonService: CommonService,
    public _swRegService: SoftwareRegistrationService,
    public _brnchService: BranchService,
    public _nodeRegService: NodeRegistrationService,
    private toastr: ToastrService) { }

  public errorMsg;
  public term: string = ''
  public deletePopUp: boolean = false;
  deleteID: number = 0;
  ngOnInit(): void {
    this._nodeRegService.pageIndex = 1;
    this._CommonService.pageIndex = 1;
    this.getAllNodeReg();
    this.getAllBranch();
    this._nodeRegService.formHideShow = 0;
  }

  getAllBranch() {
    this._brnchService.Fetch_Branch('DEALERID')
      .subscribe(data => {
        this._brnchService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        });
  }


  getAllNodeReg() {
    // if (localStorage.getItem("Designation") == 'Dealer')
    //   this._brnchService.DealerId = CryptoJS.AES.decrypt(localStorage.getItem('Id'), 'secret key 123').toString(CryptoJS.enc.Utf8);
    // else
    //   this._brnchService.DealerId = '0'
    this._nodeRegService.getNodeRegistration()
      .subscribe(data => {
        console.log('data', data)
        this._nodeRegService.allNodeRegistrations = data
        console.log(this._nodeRegService.allNodeRegistrations)
      },
        error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
        })

  }

  toggleFilter() {
    this._nodeRegService.isFiltering = this._nodeRegService.isFiltering ? false : true;
    if (!this._nodeRegService.isFiltering) {
      this._nodeRegService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._nodeRegService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._nodeRegService.orderByColName) {
      this._nodeRegService.orderByDir = !this._nodeRegService.orderByDir;
    }
    this._nodeRegService.orderByColName = colName;
  }

  NoOfItems: number = 10;

  addList() {
    this._nodeRegService.allNodeRegistrationDTLs = [];
    this._nodeRegService.newAttribute = {};
    this._nodeRegService.formHideShow = 1;
    this._nodeRegService.tblVisible = 0
  }

  showForEdit(swReg: nodeHDR) {

    this._nodeRegService.selectedNodeRegistration = Object.assign({}, swReg);
    if (this._nodeRegService.selectedNodeRegistration.BranchId != null)
      this._nodeRegService.currentStringDateToBind = new Date(this._nodeRegService.selectedNodeRegistration.CurDate);
    this._nodeRegService.currentStringDate = this.convert(this._nodeRegService.selectedNodeRegistration.CurDate);
    this._nodeRegService.allNodeRegistrationDTLs = this._nodeRegService.selectedNodeRegistration.NodeRegistrationDTLs;
    this.LoadSoftRegDtls()
    var custDtls = this._custService.allCustomers.filter(e => e.Id == this._nodeRegService.selectedNodeRegistration.CustomerId)[0];
    if (custDtls != null) {
      this._nodeRegService.selectedNodeRegistration.Addr1 = custDtls.Addr1;
      this._nodeRegService.selectedNodeRegistration.Addr2 = custDtls.Addr2;
      this._nodeRegService.selectedNodeRegistration.Addr3 = custDtls.Addr3;
    }
    console.log(this._nodeRegService.selectedNodeRegistration)

    this._swRegService.getSoftwareregRefnoByCustomer(this._nodeRegService.selectedNodeRegistration.CustomerId == null ? 0 : this._nodeRegService.selectedNodeRegistration.CustomerId)
      .subscribe(data => {
        this._swRegService.allSRRefno = data
        // this._nodeRegService.selectedNodeRegistration.SRNo == null
      },
        error => {
          this.errorMsg = error;
        });
    this._nodeRegService.tblVisible = 1
    this._nodeRegService.formHideShow = 1;

  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }

  onDelete(swReg: nodeHDR) {
    this.deletePopUp = true;
    this.deleteID = swReg.Id;
    // if (confirm('Are you sure to delete this record ?') == true) {
    //   this._nodeRegService.deleteNodeRegistration(swReg)
    //     .subscribe(x => {
    //       this.getAllNodeReg();
    //       this.toastr.success("Deleted Successfully", "NodeRegistration");
    //     }, error => {
    //       this.errorMsg = error;
    //       console.error(error.name, error.message, error.stack);
    //       this.toastr.warning("Deletion Failure", "NodeRegistration");
    //     })
    // }
  }
  dialogResult(event) {
    this.deletePopUp = false;
    if (event) {
      this._nodeRegService.deleteNodeRegistration(this.deleteID)
        .subscribe(x => {
          this.getAllNodeReg();
          this.toastr.success("Deleted Successfully", "NodeRegistration");
          this.deleteID = 0;
        }, error => {
          this.errorMsg = error;
          console.error(error.name, error.message, error.stack);
          this.toastr.error("Deletion Failure", "NodeRegistration");
        }
        )
    }

  }
  i: number = 0
  LoadSoftRegDtls() {
    //  this._nodeRegService.allNodeRegistrationDTLs = []
    if (this._nodeRegService.selectedNodeRegistration.CustomerId != null && this._nodeRegService.selectedNodeRegistration.BranchId != null && this._nodeRegService.selectedNodeRegistration.ProjectId != null)
      this._nodeRegService.LoadSoftRegDtls(this._nodeRegService.selectedNodeRegistration.CustomerId, this._nodeRegService.selectedNodeRegistration.BranchId, this._nodeRegService.selectedNodeRegistration.ProjectId).
        subscribe(obj => {
          // this._nodeRegService.selectedNodeRegistration.SRNo = obj.RefNo;
          if (obj.Nodes > 0) {
            this._nodeRegService.tblVisible = 1;
            while (this.i < obj.Nodes) {
              this._nodeRegService.newAttribute = {}
              this._nodeRegService.allNodeRegistrationDTLs.push(this._nodeRegService.newAttribute)
              this.i = this.i + 1
            }
            this.i = 0
          }
          else {
            this._nodeRegService.tblVisible = 0;

          }
        })

  }
}
