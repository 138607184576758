import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { Customerfeedback,CustomerfeedbackDtls } from '../Models/Customerfeedback';
import { GlobalErrorHandlerService } from './global-error-handler.service'
import { CustomerFeedBackMaster } from '../Models/CustomerFeedBackMaster';

@Injectable({
  providedIn: 'root'
})
export class CustomerfeedbackService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }

  selectedRecord:Customerfeedback;
  CustomerFeedBackList:Customerfeedback[];
  formHideShow = 0;
  allCustomerFeedBack:Customerfeedback[]; 
  QuestionairList: CustomerFeedBackMaster[];
 
  selectedQues: CustomerfeedbackDtls;
   allQuesDtls: CustomerfeedbackDtls[];
 
   pageIndex: number = 0;
   isFiltering: boolean = false;
   LikeFilter: string = '';
   DisLikeFilter: string = '';
   orderByDir: boolean = false;
   orderByColName: string = '';

   ClearFilter() {
    this.isFiltering = false;
    this.LikeFilter = '';
    this.DisLikeFilter = '';

  }
  clearValue(){

    this.selectedQues = {
      ID: null,
      HdrID: 0,
      QuestionID: 0,
      Question:'',
      BadOption: false,
      GoodOption: false,
      VeryGoodOption: false,
      ExcellentOption: false,
    }
    this.allQuesDtls = [];
    
    this.selectedRecord={
      ID:0,
      Likestaff:'',
      DislikeStaff:'',
      QuestionList:[]
    }
  }
  getCustomerFeedBack(TransMode: string): Observable<Customerfeedback[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<Customerfeedback[]>(ROOT_URL + 'Customerfeedback/GetAllData', {
      headers, params
    })
      .pipe(
        
      );
  }

  
  getCustomerFeedBackById(Id: number): Observable<Customerfeedback> {
    let params = new HttpParams();
    params = params.append('ID', Id.toString())
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<Customerfeedback>(ROOT_URL + 'Customerfeedback/GetAllDataById', {
      headers, params
    });
  }


  getCustomerfeedbackDtls(TransMode: string): Observable<CustomerfeedbackDtls[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<CustomerfeedbackDtls[]>(ROOT_URL + 'Customerfeedback/GetCustomerfeedbackDtls', {
      headers, params
    })
      .pipe(

      );
  }

  SaveItem(Values: Customerfeedback, allQuesDtls: CustomerfeedbackDtls[]): Observable<any> {
console.log('allQuesDtls',allQuesDtls)
    var body = {
      ID: Values.ID,
      Likestaff: Values.Likestaff,
      DislikeStaff: Values.DislikeStaff,
      QuestionList:allQuesDtls
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "Customerfeedback/InsUpdDelCustomerfeedback", body, {
      headers
    })
      .pipe(
       
      );
  }
  onDelete(Id:number){
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.http.delete<any>(ROOT_URL + 'Customerfeedback/DeleteCustomerfeedback', {
      headers, params

    })
      .pipe(
       
      );
    }

}
