<span title="Scheduled Meeting Count" style="float:right;" (click)="loadScheduledMeetingDetails()" *ngIf="this.scheduledCount > 0">
    <img src="../../../../assets/Images/chat.ico">
    <span class="messagepopup">{{this.scheduledCount}}</span>
</span>
<form *ngIf="this.meetingpopup">
    <div class="Atj_overlay " (click)="$event.stopPropagation(); closePopUp()">
        <div id="Atj_popup" class="Atj_popup ATJ-slide-bottom" (click)="$event.stopPropagation();">
            <a class="close" (click)="closePopUp()"></a>
            <div class="inner ui-input-container" (click)="$event.stopPropagation();">
                <div>
                    <div class="row">
                        <div class="col-md-12">   
                            <div class="timercontainer">
                                <span style="color:teal;">{{hr}}:</span>  
                                <span style="color:teal;">{{min}}:</span> 
                                <span style="color:teal;">{{sec}}:</span> 
                                <span style="color:teal;">{{ms}}</span>  
                            </div>                   
                            <div class="table-responsive">
                                <table class="table">
                                    <tr *ngFor="let meeting of this._Meeting.allscheduledMeeting;let i=index;">
                                        <td style="width:52px;">{{meeting.MeetingNo}}</td>
                                        <td title="{{meeting.Topic}}">{{meeting.Topic}}</td>
                                        <td style="width:202px;">{{meeting.ScheduledDate}}</td>
                                        <td style="width:102px;">{{meeting.ScheduledTime}}</td>
                                        <td style="width:111px;">
                                            <button type="button" class="button" (click)="saveMeetingTime(1,meeting)">START</button>
                                        </td>
                                        <td style="width:102px;">
                                            <button type="button" class="button" (click)="saveMeetingTime(2,meeting)">STOP</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

