<div class="container-fluid">
    <h2 class="heading_all">Today's Delivery</h2>
    <br>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label for="select">Delivery Date</label>
                <input class="section3" placeholder="Choose Date" style="color: black; text-align: left;"
                    autocomplete="off" #Date="bsDatepicker" bsDatepicker [(ngModel)]="DelDate" name="Date"
                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }" (ngModelChange)="getData()">

            </div>
        </div>
        <div class="col-md-4"></div>     
    </div>
    <br>
    <table class="tablepl">
        <thead>
            <tr>
                <th>#
                    <div style="display: inline-block;">
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="isFiltering" class="fas fa-times"></i>
                        </a>
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Dealer')" *ngIf="!isFiltering">
                        <span>Dealer</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='Dealer' "></i>
                            <div *ngIf="orderByColName=='Dealer'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="Dealer" id="Dealer" #Dealer="ngModel"
                            [(ngModel)]="filterCol.Dealer" placeholder="Dealer">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Customer')" *ngIf="!isFiltering">
                        <span>Customer</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='Customer' "></i>
                            <div *ngIf="orderByColName=='Customer'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="Customer" id="Customer" #Customer="ngModel"
                            [(ngModel)]="filterCol.Customer" placeholder="Customer">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('VType')" *ngIf="!isFiltering">
                        <span>VType</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='VType' "></i>
                            <div *ngIf="orderByColName=='VType'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="VType" id="VType" #VType="ngModel"
                            [(ngModel)]="filterCol.VType" placeholder="VType">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('RefNo')" *ngIf="!isFiltering">
                        <span>RefNo</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='RefNo' "></i>
                            <div *ngIf="orderByColName=='RefNo'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="RefNo" id="RefNo" #RefNo="ngModel"
                            [(ngModel)]="filterCol.RefNo" placeholder="RefNo">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('RegDate')" *ngIf="!isFiltering">
                        <span>RegDate</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='RegDate' "></i>
                            <div *ngIf="orderByColName=='RegDate'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="RegDate" id="RegDate" #RegDate="ngModel"
                            [(ngModel)]="filterCol.RegDate" placeholder="RegDate">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('RepDate')" *ngIf="!isFiltering">
                        <span>RepDate</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='RepDate' "></i>
                            <div *ngIf="orderByColName=='RepDate'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="RepDate" id="RepDate" #RepDate="ngModel"
                            [(ngModel)]="filterCol.RepDate" placeholder="RepDate">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('DelDate')" *ngIf="!isFiltering">
                        <span>DelDate</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='DelDate' "></i>
                            <div *ngIf="orderByColName=='DelDate'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="DelDate" id="DelDate" #DelDate="ngModel"
                            [(ngModel)]="filterCol.DelDate" placeholder="DelDate">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('OverDueBy')" *ngIf="!isFiltering">
                        <span>Over Due By</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='OverDueBy' "></i>
                            <div *ngIf="orderByColName=='OverDueBy'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="OverDueBy" id="OverDueBy"
                            #OverDueBy="ngModel" [(ngModel)]="filterCol.OverDueBy" placeholder="OverDueBy">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Stage')" *ngIf="!isFiltering">
                        <span>Stage</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='Stage' "></i>
                            <div *ngIf="orderByColName=='Stage'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="Stage" id="Stage" #Stage="ngModel"
                            [(ngModel)]="filterCol.Stage" placeholder="Stage">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('Status')" *ngIf="!isFiltering">
                        <span>Status</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='Stage' "></i>
                            <div *ngIf="orderByColName=='Stage'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="Status" id="Status" #Status="ngModel"
                            [(ngModel)]="filterCol.Status" placeholder="Status">
                    </div>
                </th>
                <th class="hidden-xs">
                    <div class="AtjIsPoint" (click)="sort('TestResult')" *ngIf="!isFiltering">
                        <span>Test Result</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort" *ngIf="orderByColName!='TestResult' "></i>
                            <div *ngIf="orderByColName=='TestResult'">
                                <i class="fas fa-sort-amount-down" *ngIf="orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFiltering">
                        <input type="text" class="form-control-search" name="TestResult" id="TestResult"
                            #TestResult="ngModel" [(ngModel)]="filterCol.TestResult" placeholder="Test Result">
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <!-- <tr *ngFor="let AmcRenewal of _AmcRenewalService.allAMCRenewals | gridOperations  :_AmcRenewalService.pageIndex:
                {AMCRenewalDate:_AmcRenewalService.DateFilter}:
               {Column:_AmcRenewalService.orderByColName,Dir:_AmcRenewalService.orderByDir} ; let i = index"
                class="ng-scope"> -->
            <tr *ngFor="let data of _BacklogsService.DataList | gridOperations  :pageIndex:
                {Dealer:filterCol.Dealer,Customer:filterCol.Customer,VType:filterCol.VType,RefNo:filterCol.RefNo
                ,RegDate:filterCol.RegDate,RepDate:filterCol.RepDate,DelDate:filterCol.DelDate,OverDueBy:filterCol.OverDueBy,Stage:filterCol.Stage
                ,Status:filterCol.Status,TestResult:filterCol.TestResult}:
               {Column:orderByColName,Dir:orderByDir}; let i=index">
                <td>{{i+1}}</td>
                <td title="{{data.Dealer}}">{{data.Dealer}}</td>
                <td title="{{data.Customer}}">{{data.Customer}}</td>
                <td>{{data.VType}}</td>
                <td>{{data.RefNo}}</td>
                <td>{{data.RegDate}}</td>
                <td>{{data.RepDate}}</td>
                <td>{{data.DelDate}}</td>
                <td>{{data.OverDueBy}}</td>
                <td>{{data.Stage}}</td>
                <td>{{data.Status}}</td>
                <td>{{data.TestResult}}</td>

            </tr><!-- end ngRepeat: item in items -->
            <tr *ngIf="_BacklogsService.DataList?.length<=0">
                <td colspan="12">
                    No Records To Display.
                </td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                <app-grid-pagination (pageIndexValue)="pageIndex=$event">
                </app-grid-pagination>
            </td>
        </tfoot>
    </table>
    <div class="margintop"></div>
</div>