import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateformat'
})
export class DateformatPipe implements PipeTransform {

  transform(value:string,type:number): any {

    var formatedDate;
    var date = new Date(value),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);

    /* time section */
    var timeDate;
    if(date.getHours().toString()=="0" && date.getMinutes().toString()=="0")
      timeDate=new Date();
    else
      timeDate=date;

      console.log(date)
      console.log('month',mnth,'day',day,'year',date.getFullYear())

    let hour=timeDate.getHours().toString().length > 1 ? timeDate.getHours() : '0'+ timeDate.getHours();
    let minutes=timeDate.getMinutes().toString().length > 1 ? timeDate.getMinutes() : '0'+ timeDate.getMinutes();
    let ampm = hour >= 12 ? 'pm' : 'am';
    let time=hour + ':' + minutes + ' ' + ampm;

    if(type==0)
      formatedDate= [date.getFullYear(), mnth, day].join("-");
    if(type==1)
      formatedDate= [mnth, day,date.getFullYear()].join("-");
    if(type==2)
      formatedDate= [day,mnth,date.getFullYear()].join("-");
    if(type==3)
      formatedDate= ([mnth, day,date.getFullYear()].join("-") +' '+ time);

    return formatedDate;
  }

}

/*  type-0 : yyyy-MM-dd
    type-1 : MM-dd-yyyy
    type-2 : dd-MM-yyyy
    type-3 : MM-dd-yyyy HH:mm
    type-4 : MMM-yyyy
*/
