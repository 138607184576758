<div class="container-fluid">
    <h2 class="heading_all">Developer Home Screen  
        <app-scheduledmeeting></app-scheduledmeeting>
    </h2>
    <div class="row">
        <div class="col-md-4">
            <div id="chartdiv" class="bggg">
                <app-pie></app-pie>
            </div>
        </div>
        <div class="col-md-4">
            <div id="chartdiv2" class="bggg"></div>
        </div>
        <div class="col-md-4">
            <div id="chartdiv3" class="bggg"></div>
        </div>
    </div>
    <br>
    <h3>To Do List</h3>
    <div class="tWrap">
        <div class="tWrap__head">
            <table>
                <thead>
                    <tr>
                        <th style="width:50px;">S.No</th>
                        <th>Activity ID</th>
                        <th> Date Issue</th>
                        <th>Customer</th>
                        <th> Issued By</th>
                        <th> Exp.St.Date</th>
                        <th> Exp.Comp.Date</th>
                        <th> St Date</th>
                        <th> Comp Date</th>
                        <th title="Overdue by" style="width:60px;">OD</th>
                        <th style="width:70px;">View</th>
                    </tr>
                </thead>
            </table>
        </div>
        <div class="tWrap__body">
            <table>
                <tbody class="tWrap__body">
                    <tr *ngFor="let field of this._DevdashboardService.AllPendingWork ; let i = index" [ngClass]="{'overdue':field.OverdueBy > 0}">
                        <td style="width:50px;">{{i+1}}</td>
                        <td>{{field.Activity}}</td>
                        <td title="{{field.IssueDate}}">{{field.IssueDate}}</td>
                        <td title="{{field.Customer}}">{{field.Customer}}</td>
                        <td title="{{field.DealerName}}">{{field.DealerName}}</td>
                        <td title="{{field.ExpStDate}}">{{field.ExpStDate}}</td>
                        <td title="{{field.ExpCompDate}}">{{field.ExpCompDate}}</td>                     
                        <td>{{field.StDate}}</td>
                        <td>{{field.CompDate}}</td>
                        <td style="width:60px;" title="{{field.OverdueBy}}">{{field.OverdueBy}}</td>
                        <td style="width:70px;">
                            <i class="fas fa-eye" (click)="getPendingWorksDet(field)" style="font-size:24px"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br>
    <div class="tWrap" *ngIf="this._DevdashboardService.AllPendingWorkDet.Module!=null">
        <table>
            <thead>
                <tr>
                    <th>*</th>
                    <th>Module</th>
                    <th>Particulars</th>
                    <th>St.Date</th>
                    <th>Comp.Date</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody class="tWrap__body">
                <tr>
                    <td></td>
                    <td>{{this._DevdashboardService.AllPendingWorkDet.Module}}</td>
                    <td>{{this._DevdashboardService.AllPendingWorkDet.Particular}}</td>
                    <td>
                        <input name="StDate" #StDate="ngModel" autocomplete="off"
                            [(ngModel)]="this._DevdashboardService.AllPendingWorkDet.StDate" placeholder=" St.Date">
                    </td>
                    <td>
                        <input name="CompDate" #CompDate="ngModel" autocomplete="off"
                            [(ngModel)]="this._DevdashboardService.AllPendingWorkDet.CompDate" placeholder=" Comp.Date">
                    </td>
                    <td>
                        <i class="fas fa-play" style="color:green ;margin-right: 18px;font-size: 21px;"
                            aria-hidden="true"></i>
                        <i class="fas fa-pause"
                            style="color:rebeccapurple;margin-right: 18px;font-size: 21px;"></i>
                        <i class="fas fa-fast-forward" style="color:blue; margin-right: 18px;font-size: 21px;"></i>
                        <i class="fas fa-stop" style="color:orange ;margin-right: 18px;font-size: 21px;"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Description</label>
                    <textarea class="ui-form-input" [value]="Description" style="width: 100%;" disabled
                        autocomplete="off"></textarea>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Remark</label>
                    <textarea class="ui-form-input" [value]="Remark" disabled autocomplete="off"
                        style="width: 100%;"></textarea>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>ATTACHMENTS</label>
                    <div class="tWrap__body">
                        <table>
                            <tbody>
                                <tr *ngFor="let data of this.FileList">
                                    <td (click)="downloadFileUrl(data)"
                                        style="cursor: pointer; text-decoration: underline;">
                                        {{data}}
                                    </td>
                                </tr>
                                <tr *ngIf="this.FileList.length<1">
                                    <td style="color: black;">No File Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popupmessage></app-popupmessage>