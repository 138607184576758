<div class="container-fluid" *ngIf="_ReasonmasterService.formHideShow==0">
    <div id="form-wrap">
        <h2 class="heading_all">REASON MASTER</h2>
        <br>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">

                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addNew()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width:60%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th class="hidden-xs" style="width:205px;">
                        <div class="AtjIsPoint" (click)="sort('Code')">
                            <span *ngIf="!_ReasonmasterService.isFiltering">Code</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_ReasonmasterService.orderByColName!='Code' "></i>
                                <div *ngIf="_ReasonmasterService.orderByColName=='Code'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_ReasonmasterService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_ReasonmasterService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_ReasonmasterService.isFiltering">
                            <input type="text" #txtCodeFilter="ngModel" [(ngModel)]="_ReasonmasterService.CodeFilter"
                                placeholder="Code">
                        </div>
                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Reason')">
                            <span *ngIf="!_ReasonmasterService.isFiltering">Reason</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_ReasonmasterService.orderByColName!='Reason' "></i>
                                <div *ngIf="_ReasonmasterService.orderByColName=='Reason'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_ReasonmasterService.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_ReasonmasterService.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_ReasonmasterService.isFiltering">
                            <input type="text" #txtReasonFilter="ngModel"
                                [(ngModel)]="_ReasonmasterService.ReasonFilter" placeholder="Reason">
                        </div>
                    </th>
                    <th style="width: 135px;"><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_ReasonmasterService.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="_ReasonmasterService.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cusfedmas of _ReasonmasterService.allReasonMaster | orderBy : 'Reason' | gridOperations  :_ReasonmasterService.pageIndex: 
                     {Code:_ReasonmasterService.CodeFilter,Reason:_ReasonmasterService.ReasonFilter}:
                    {Column:_ReasonmasterService.orderByColName,Dir:_ReasonmasterService.orderByDir} ; let i = index"
                    class="ng-scope">
                    <td>{{i+1+((NoOfItems*_ReasonmasterService.pageIndex)-NoOfItems)}}</td>
                    <td style="width:205px;">{{cusfedmas.Code}}</td>
                    <td>{{cusfedmas.Reason}}</td>
                    <td style="width: 135px;">
                        <button type="button" class="btn-success" (click)="OnEdit(cusfedmas);">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                        <button type="button" class="btn-danger" (click)="onDelete(cusfedmas.ID)"><i
                                class="fas fa-trash" aria-hidden="true"></i></button>
                    </td>
                </tr><!-- end ngRepeat: item in items -->
                <tr>
                    <td colspan="4"
                        *ngIf="(_ReasonmasterService.allReasonMaster?.length<=0 )  || _CommonService.filterdItemCount<1">
                        No Records To Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="_ReasonmasterService.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        <div class="margintop"></div>
        <!-- </div>
    </div> -->
    </div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'REASON MASTER'" (ConfirmEvent)="dialogResult($event)">
</app-delete-confirm>