import { Component, OnInit } from '@angular/core';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { Backloghs } from 'src/app/Models/Backlogs';
import { BacklogsService } from 'src/app/Services/backlogs.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-backlogs',
  templateUrl: './backlogs.component.html',
  styleUrls: ['./backlogs.component.css']
})
export class BacklogsComponent implements OnInit {

  constructor(public _BacklogsService:BacklogsService,
              public _CommonService:CommonService) { }
  pageIndex:number;
  orderByColName:string=''; 
  orderByDir:boolean=false;
  isFiltering:boolean=false;
  filterCol:Backloghs;
  now:Date;
  ngOnInit(): void {
    this.now=new Date();
    this.pageIndex=1;
    this.filterCol={Customer:'',Dealer:'',DelDate:'',OverDueBy:'',RefNo:'',RegDate:'',RepDate:'',Stage:'',Status:'',TestResult:'',VType:''}
    this._CommonService.pageIndex=1;
    this._BacklogsService.GetData(this.now).subscribe(data=>{
      this._BacklogsService.DataList=data;
    })
  }
  sort(col){
    if (col == this.orderByColName) {
      this.orderByDir = !this.orderByDir;
    }
    this.orderByColName=col;
  }

  toggleFilter(){
    this.isFiltering=!this.isFiltering;
    if(!this.isFiltering){
    this.filterCol={Customer:'',Dealer:'',DelDate:'',OverDueBy:'',RefNo:'',RegDate:'',RepDate:'',Stage:'',Status:'',TestResult:'',VType:''}
    }
  }

  getData(){
    this._CommonService.isLoading=true;
    this._BacklogsService.GetData(this.now).subscribe(data=>{
      this._CommonService.isLoading=false;
      this._BacklogsService.DataList=data;
    })
  }
}
