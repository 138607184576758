<div class="container-fluid" *ngIf="_desigServive.formHideShow==0">
    <!-- <div class="row flex-body">
        <div class="col-md-11 col-md-offset-1">
            <div class="box">
                <h4 class="box-title">Designation</h4>
            </div>

            <div class="col-md-4 offset-md-8 searchBox">
                <div class="form-group row no-gutters">
                    <div class="col-12 col-sm-6 col-md-2"><button type="button" class="btn-sm btn-info"
                            (click)="addList()"><i class="fas fa-plus" aria-hidden="true"></i></button></div>
                    <div class="col-12 col-sm-6 col-md-8"><input type="text" class="form-control-search" name="search"
                            [(ngModel)]="term"></div>
                    <div class="col-6 col-md-2"><button class="btn-info"><i class="fas fa-search"
                                aria-hidden="true"></i></button></div>
                </div>
            </div> -->
    <h2 class="heading_all">DESIGNATION</h2>
    <br>
    <div id="form-wrap">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4"></div>
            <div class="col-md-4 ">

                <table>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus"
                                        aria-hidden="true"></i></button>
                            </td>
                            <td style="width: 70%;"><input type="text" class="form-control-search" name="search"
                                    [(ngModel)]="term"></td>
                            <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                        </tr>
                    </thead>
                </table>

            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>#</th>

                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Code')">
                            <span *ngIf="!_desigServive.isFiltering">Code</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_desigServive.orderByColName!='Code' "></i>
                                <div *ngIf="_desigServive.orderByColName=='Code'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_desigServive.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_desigServive.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_desigServive.isFiltering">
                            <input type="text" #txtCodeFilter="ngModel" [(ngModel)]="_desigServive.CodeFilter"
                                placeholder="Code">
                        </div>

                    </th>
                    <th class="hidden-xs">
                        <div class="AtjIsPoint" (click)="sort('Name')">
                            <span *ngIf="!_desigServive.isFiltering">Name</span>
                            <div class="AtjfloatRight">
                                <i class="fas fa-sort" *ngIf="_desigServive.orderByColName!='Name' "></i>
                                <div *ngIf="_desigServive.orderByColName=='Name'">
                                    <i class="fas fa-sort-amount-down" *ngIf="_desigServive.orderByDir"></i>
                                    <i class="fas fa-sort-amount-up-alt" *ngIf="!_desigServive.orderByDir"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="_desigServive.isFiltering">
                            <input type="text" #txtNameFilter="ngModel" [(ngModel)]="_desigServive.NameFilter"
                                placeholder="Name">
                        </div>

                    </th>
                    <th><em></em> Settings
                        <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                            <i *ngIf="!_desigServive.isFiltering" class="fas fa-filter"></i>
                            <i *ngIf="_desigServive.isFiltering" class="fas fa-times"></i>
                        </a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr *ngFor="let desig of _desigServive.allDesignations | orderBy: key : reverse | filter:term | paginate: { itemsPerPage: NoOfItems, currentPage: p }; let i = index"
                        class="ng-scope"> -->
                <tr *ngFor="let desig of _desigServive.allDesignations  | gridOperations  :_desigServive.pageIndex:
                        {Code:_desigServive.CodeFilter,Name:_desigServive.NameFilter}:
                       {Column:_desigServive.orderByColName,Dir:_desigServive.orderByDir} ; let i = index"
                    class="ng-scope">
                    <td>{{i+1+((NoOfItems*_desigServive.pageIndex)-NoOfItems)}}</td>
                    <td>{{desig.Code}}</td>
                    <td>{{desig.Name}}</td>
                    <td>
                        <button type="button" class="btn-success" (click)="showForEdit(desig)">
                            <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                        <!-- <button type="button" class="btn-danger" (click)="onDelete(desig)"><i class="fas fa-trash"
                                    aria-hidden="true"></i></button> -->
                    </td>
                </tr>
                <tr>
                    <td colspan="3"
                        *ngIf="(_desigServive.allDesignations?.length<=0 )  || _CommonService.filterdItemCount<1">
                        No Records To
                        Display.</td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="4" *ngIf="_CommonService.totalPageCount>1">
                    <app-grid-pagination (pageIndexValue)="_desigServive.pageIndex=$event">
                    </app-grid-pagination>
                </td>
            </tfoot>
        </table>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
    </div>
    <div class="margintop"></div>
    <!-- </div>
    </div> -->
</div>