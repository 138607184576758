import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { ModuleService } from 'src/app/Services/module.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { ToastrService } from 'ngx-toastr';
import { WorkAllocationService } from 'src/app/Services/work-allocation.service';
import { WorkAllocation, ServiceEngWorksForecast } from 'src/app/Models/WorkAllocation.model';
import { NgForm } from '@angular/forms';
import $ from 'jquery';
import { WorkSortingAssigning } from 'src/app/Models/WorkSorting.model';
import { EmployeeService } from 'src/app/Services/employee.service';
import * as CryptoJS from 'crypto-js';
import { ProjectLeaderService } from 'src/app/Services/project-leader.service';


@Component({
  selector: 'app-work-allocation',
  templateUrl: './work-allocation.component.html',
  styleUrls: ['./work-allocation.component.css']
})
export class WorkAllocationComponent implements OnInit {

  constructor(
    public _CommonService: CommonService,
    public _WorkAllocationService: WorkAllocationService,
    public _ParticularmasterService: ParticularmasterService,
    public _modulService: ModuleService,
    public _CustomerService: CustomerService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _BranchService: BranchService,
    public _projectService: ProjectService,
    private toastr: ToastrService,
    public _EmployeeService: EmployeeService,
    public _ProjectLeaderService: ProjectLeaderService
  ) { }

  PriorityList: any;
  StatusList: any;
  AssignToList: any;
  StatusFullList: any;
  TypeList: any;
  public errorMsg;
  jobOrderForm: boolean;
  serviceEngWF: boolean;
  WarningModel: boolean;
  jobOrderExistsStatus: boolean;
  SupportLeaderId: number
  clickReadyToinstall: boolean
  TempServiceEngWorksForecast: ServiceEngWorksForecast[]

  filterDate: Date;
  DevTestToggle: number = 0;
  JobOrderOrManHour: number = 0;
  EmployeeType: number = 0;



  ModuleDateFullList: any = [];

  Description: string = '';
  DscptnVisible: number = 0;

  ngOnInit(): void {
    this.getLoginedUser();
    this.getListCommon();
    this.Reset();
    this.serviceEngWF = false
    this.jobOrderForm = false
    this._ProjectLeaderService.EmployeeWorkForcastDetails = [];
    this.filterDate = new Date();
  }

  async getLoginedUser() {
    this.SupportLeaderId = parseInt(await this._CommonService.getLoginedUserId())
    this._CommonService.LoginId = this.SupportLeaderId
    this._CommonService.LoginUser = 'SupportLeader';
    this._CommonService.FormID = 'WORKALLOC';
  }

  Reset(form?: NgForm) {
    this.clickReadyToinstall = false
    this._WorkAllocationService.RemarkHistory = []
    this._WorkAllocationService.AllServiceEngWorks = []
    this._WorkAllocationService.AllWorkAllocationDet = []
    this.DscptnVisible = 0

    this._BranchService.selectedBranch = {
      Addr1: null,
      Addr2: null,
      Addr3: null,
      BranchEmail: null,
      BranchFax: null,
      BranchName: null,
      Customer:null,
      BranchPhone: null,
      ContactEmail: null,
      ContactPerson: null,
      ContactPhone: null,
      CountryId: null,
      CustomerId: null,
      DealerId: null,
      Id: null,
      RouteId: null,
      UniqueBrachId: ''
    }

    this._WorkAllocationService.SelectedJobOrderHdr = {
      BranchAdr1: null,
      BranchAdr2: null,
      BranchAdr3: null,
      BranchId: null,
      CPBranch: null,
      CustAdr1: null,
      CustAdr2: null,
      CustAdr3: null,
      CustomerId: null,
      CPCutomer: null,
      IssueDate: null,
      JoNo: null,
      CPBranchPhone: null,
      CPCustomerPhone: null,
      From: null,
      To: null,
      FromTime: null,
      ServiceMode: 0,
      ToTime: null,
      WorkSortingAssigning: [],
      SEWorkDet: [],
      Notes: null,
      CurrentCharge: 0,
      ServiceCharge: 0,
      EmployeeType: 0,
      AttachmentList: [],

      ProjectId: 0,
      ContractDate: null,
      ContractVsCurrentDate: null,
      NumberOfVisit: 0,
    }

    this._WorkAllocationService.selectedWorkAllocationHdr = {
      AssTo: 0,
      AttSEId: 0,
      BranchId: 0,
      CustomerId: 0,
      CustomerName: '',
      Date: null,
      DealerId: 0,
      EmpName: '',
      ExpDate: null,
      ExpDateToBind: null,
      ModeOfReg: 0,
      PriorityId: 0,
      RDate: null,
      RefNo: 0,
      Remark: '',
      SNo: 0,
      VType: 0,
      AssignedBy: ''
    }

    this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr = {
      From: null,
      FromTime: null,
      JobAssignType: null,
      ServiceMode: 0,
      To: null,
      ToTime: null,
      Date: null,
      Notes: null,
      JoNo: 0,
      ServiceCharge: 0,
      EmployeeType: 0
    }
    this._WorkAllocationService.AllServiceEngWorksForecast = []
    this.getDealer()

    this.jobOrderExistsStatus = false
    this.WarningModel = false
    this.serviceEngWF = false
    this.ModuleDateFullList = []

    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._modulService.allModuleServiceReport = []
  }
  async getDealer() {
    this._WorkAllocationService.DealerId = parseInt(await this._CommonService.getDealerId(this._CommonService.FormID, this._CommonService.LoginId, this._CommonService.LoginUser));
    this.getWorkAllocationList();
  }
  onChangeCalc(CurrentCharge: number) {
    let a = this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ServiceCharge + CurrentCharge
    this._WorkAllocationService.SelectedJobOrderHdr.ServiceCharge = a;
  }
  getWorkAllocationList() {
    this._WorkAllocationService.getPendingWorksHeader(this._WorkAllocationService.DealerId, 'ALL')
      .subscribe(data => {
        this._WorkAllocationService.AllWorkAllcation = data
        // this._WorkAllocationService.AllWorkAllcation.forEach(obj => {
        //   obj.ExpDateToBind = obj.ExpDate == null ? null : new Date(obj.ExpDate)
        // })
      },
        error => {
          this.errorMsg = error;
        })
  }

  valuechange(newValue, type: string) {
    if (type == 'From')
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.From = new Date(newValue)
    else if (type == 'To')
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.To = new Date(newValue)
    // else if (type == 'FromTime')
    //   this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.FromTime =newValue
    // else if (type == 'ToTime')
    //   this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ToTime = newValue
  }

  LoadSupportEngWorks(newDate: Date) {
    this._WorkAllocationService.LoadSupportEngWorksByDate(this.convert(newDate))
      .subscribe(data => {
        this._WorkAllocationService.AllServiceEngWorks = data
      },
        error => {
          this.errorMsg = error;
        })
  }


  getListCommon() {
    this.PriorityList = this._CommonService.GetPriorityList()
    this.StatusList = this._CommonService.GetStatusList();
    this.AssignToList = this._CommonService.GetAssignToList();
    this.StatusFullList = this._CommonService.GetStatusFullList();
    this.LoadCustomers();
    this.fillServiceEngineer();
    this.fillbranch();
    this.fillproject();
    this.fillparticulars();
    this.fillmodule();
  }


  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }

  getWorkAllocationDet(objects: WorkAllocation) {
    this._WorkAllocationService.selectedWorkAllocationHdr = objects;
    this._WorkAllocationService.AllWorkAllocationDet = []
    this._CommonService.isLoading = true;
    this._WorkAllocationService.getWorkAllocationDet(objects).subscribe(obj => {
      this._CommonService.isLoading = false;
      console.log(obj)
      this._WorkAllocationService.AllWorkAllocationDet = obj.AssignedHisList
      this._WorkAllocationService.RemarkHistory = obj.RemarkHistory
      this._WorkAllocationService.AllWorkAllocationDet.forEach(field => {
        this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ServiceCharge = field.ServiceCharge
      })
    },
      error => {
        this.errorMsg = error;
        this._CommonService.isLoading = false;
        this.toastr.error('Something went Wrong', 'Work Allocation');
      });
    this._BranchService.Fetch_BranchById(objects.BranchId, 'BRANCHID').subscribe(obj => {
      this._BranchService.selectedBranch = obj[0]
    })
  }

  OnClickCancel() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");
    this.jobOrderExistsStatus = false
    this.WarningModel = false
    this.serviceEngWF = false
    this.jobOrderForm = false
    this.LoadCustomers();
    this.Reset();
  }

  JobForeCastingClick() {
    var date = new Date();
    this.getServiceEngWorks()
    // add a day
    date.setDate(date.getDate() + 1);
    this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.From = date;
    this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.To = date;
    this._WorkAllocationService.AllWorkAllocationDet.forEach(obj => {
      if (obj.JoNo > 0) {
        this.jobOrderExistsStatus = true
        this._WorkAllocationService.SelectedJobOrderHdr.JoNo = obj.JoNo
      }
    })

    if (this.jobOrderExistsStatus) {
      $('#myModalConfirm').css("display", "block")
      this.WarningModel = true;
    }
    else {
      this._WorkAllocationService.CheckedWorkAllocationDet = this._WorkAllocationService.AllWorkAllocationDet.filter(obj => {
        return obj.CheckStatus == true
      })
      if (this._WorkAllocationService.CheckedWorkAllocationDet.length > 0) {
        $('#myModalConfirm').css("display", "block")
        this.jobOrderForm = false
        this.WarningModel = false
        this.serviceEngWF = true
        this.getEmpForcastDetails();
      }
    }
  }

  confirm() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");

    this._WorkAllocationService.CheckedWorkAllocationDet = this._WorkAllocationService.AllWorkAllocationDet.filter(obj => {
      return obj.CheckStatus == true
    })
    this._WorkAllocationService.getJobOrderByJobId(this._WorkAllocationService.SelectedJobOrderHdr.JoNo).subscribe(obj => {
      this._WorkAllocationService.SelectedJobOrderHdr = obj
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.JoNo = obj.JoNo;
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.From = new Date(this._WorkAllocationService.SelectedJobOrderHdr.From);
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.To = new Date(this._WorkAllocationService.SelectedJobOrderHdr.To);
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ServiceMode = this._WorkAllocationService.SelectedJobOrderHdr.ServiceMode;
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.FromTime = this._WorkAllocationService.SelectedJobOrderHdr.FromTime.toString();
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.ToTime = this._WorkAllocationService.SelectedJobOrderHdr.ToTime.toString();
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.Notes = this._WorkAllocationService.SelectedJobOrderHdr.Notes;
      this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.EmployeeType = this._WorkAllocationService.SelectedJobOrderHdr.EmployeeType;
      if (this._WorkAllocationService.CheckedWorkAllocationDet.length > 0) {
        $('#myModalConfirm').css("display", "block")
        this.WarningModel = false
        this.jobOrderForm = false
        this.serviceEngWF = true
      }
    })
    this.getEmpForcastDetails();
  }

  decline() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");

    this._WorkAllocationService.CheckedWorkAllocationDet = this._WorkAllocationService.AllWorkAllocationDet.filter(obj => {
      return obj.CheckStatus == true && obj.JoNo == 0
    })
    if (this._WorkAllocationService.CheckedWorkAllocationDet.length > 0) {
      $('#myModalConfirm').css("display", "block")
      this.WarningModel = false
      this.jobOrderForm = false
      this.serviceEngWF = true
    }
  }

  onUpdate(obj: WorkSortingAssigning) {
    this.DscptnVisible = 1;
    this.Description = obj.Description;

  }


  closePopUp() {
    this.DscptnVisible = 0;
  }
  //---------------------------------View Job order details-------------------------------------------
  //-----------------------------------------------------------------------------------------------

  ViewJobOrderDet(JoNo: number) {
    if (JoNo > 0) {
      //Get Job Order Details
      this.GetJobDetailsByJoNo(JoNo)
      this.getServiceEngWorks()
      $('#myModalConfirm').css("display", "block")
      this.jobOrderForm = true
      this.WarningModel = false
      this.serviceEngWF = false
    }
    else
      this.toastr.warning('Job is not Alloted', 'Work Allocation')
  }

  GetJobDetailsByJoNo(JoNo: number) {
    this._WorkAllocationService.getJobOrderByJobId(JoNo).subscribe(obj => {

      this._WorkAllocationService.SelectedJobOrderHdr = Object.assign({}, obj)
      console.log('this._WorkAllocationService.SelectedJobOrderHdr', this._WorkAllocationService.SelectedJobOrderHdr)

      console.log('_CustomerService.allCustomers:', this._CustomerService.allCustomers)
      this._WorkAllocationService.SelectedJobOrderHdr.IssueDate = new Date(obj.IssueDate)
      this._WorkAllocationService.SelectedJobOrderHdr.ServiceCharge = obj.ServiceCharge
      this._WorkAllocationService.SelectedJobOrderHdr.From = new Date(obj.From)
      this._WorkAllocationService.SelectedJobOrderHdr.To = new Date(obj.To)
      this._WorkAllocationService.SelectedJobOrderHdr.FromTime = obj.FromTime
      this._WorkAllocationService.SelectedJobOrderHdr.ToTime = obj.ToTime
      this._WorkAllocationService.SelectedJobOrderHdr.WorkSortingAssigning.forEach(field => {
        this._WorkAllocationService.SelectedJobOrderHdr.ProjectId = field.ProjectId


      })
      this._CustomerService.allCustomers.forEach(obj => {
        if (obj.Id == this._WorkAllocationService.SelectedJobOrderHdr.CustomerId) {
          this._WorkAllocationService.SelectedJobOrderHdr.CustAdr1 = obj.Addr1
          this._WorkAllocationService.SelectedJobOrderHdr.CustAdr2 = obj.Addr2
          this._WorkAllocationService.SelectedJobOrderHdr.CustAdr3 = obj.Addr3
          this._WorkAllocationService.SelectedJobOrderHdr.CPCutomer = obj.ContactPerson
          this._WorkAllocationService.SelectedJobOrderHdr.CPCustomerPhone = obj.ContactPhone
        }
      })
      this._BranchService.allBranchs.forEach(obj => {
        if (obj.Id == this._WorkAllocationService.SelectedJobOrderHdr.BranchId) {
          this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr1 = obj.Addr1
          this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr2 = obj.Addr2
          this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr3 = obj.Addr3
          this._WorkAllocationService.SelectedJobOrderHdr.CPBranch = obj.ContactPerson
          this._WorkAllocationService.SelectedJobOrderHdr.CPBranchPhone = obj.ContactPhone
        }
      })

      if (this.ModuleDateFullList.length < 1) {
        this._modulService.Fetch_ModuleServiceReport(this._WorkAllocationService.SelectedJobOrderHdr.CustomerId, this._WorkAllocationService.SelectedJobOrderHdr.BranchId, this._WorkAllocationService.SelectedJobOrderHdr.ProjectId)
          .subscribe(data => {
            this._modulService.allModuleServiceReport = data
            this._modulService.allModuleServiceReport.forEach(obj => {
              obj.ExeDate = null
              obj.ExeDateToBind = null
              obj.ScriptDate = null
              obj.ScriptDateToBind = null
            })
          },
            error => {
              this.errorMsg = error;
            })
      }



      this._CommonService.LoadSoftWareDtlsCommon(this._WorkAllocationService.SelectedJobOrderHdr.CustomerId, this._WorkAllocationService.SelectedJobOrderHdr.BranchId, this._WorkAllocationService.SelectedJobOrderHdr.ProjectId).
        subscribe(obj => {
          // this._ServiceReportEntryService.SelectedServiceRepHeader.Server = obj.NoOfServer
          // this._ServiceReportEntryService.SelectedServiceRepHeader.Node = obj.Nodes
          this._WorkAllocationService.SelectedJobOrderHdr.ContractVsCurrentDate = obj.ContractVsCurrentDate
          // this._ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal = new Date(obj.AMCRenewal)
          // this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto = new Date(obj.AMCUPTo)
          this._WorkAllocationService.SelectedJobOrderHdr.ContractDate = this.convert(obj.ContractDate)
          // if (this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto > new Date())
          //   this.AMCRenewalStatus = true
        })


      this._CustomerrequirementService.GetCustomerRequirementsHistory(this._WorkAllocationService.SelectedJobOrderHdr.CustomerId, this._WorkAllocationService.SelectedJobOrderHdr.BranchId, this._WorkAllocationService.SelectedJobOrderHdr.ProjectId, 0)
        .subscribe(obj => {
          this._WorkAllocationService.SelectedJobOrderHdr.NumberOfVisit = obj.NoOfVisits
          this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate
          this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
          this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate
          this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate
        })


    })
  }

  //Load Service Eng Work Loading in the popup
  getServiceEngWorks() {
    this._WorkAllocationService.getServiceEngWorks(this._WorkAllocationService.DealerId.toString(), this._WorkAllocationService.SelectedJobOrderHdr.EmployeeType)
      .subscribe(obj => {
        this._WorkAllocationService.AllServiceEngWorksForecast = []
        if (this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.JoNo == 0) {
          this._WorkAllocationService.AllServiceEngWorksForecast = obj
        }
        else {
          this._WorkAllocationService.AllServiceEngWorksForecast = []
          if (this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.EmployeeType == this._WorkAllocationService.SelectedJobOrderHdr.EmployeeType) {
            this._WorkAllocationService.AllServiceEngWorksForecast = this._WorkAllocationService.SelectedJobOrderHdr.SEWorkDet
            obj.forEach(test => {
              if (!this._WorkAllocationService.SelectedJobOrderHdr.SEWorkDet.some(data => data.SEId == test.SEId)) {
                this._WorkAllocationService.AllServiceEngWorksForecast.push(test)
              }
            })
          }
          else {
            this._WorkAllocationService.AllServiceEngWorksForecast = obj
          }
        }
      })
  }

  WorkForeCastingSubmit() {
    this._WorkAllocationService.AllWorkAllocationDet = []
    this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.EmployeeType = this._WorkAllocationService.SelectedJobOrderHdr.EmployeeType
    if (this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.JoNo == 0) {
      this._CommonService.isLoading = true;
      this._WorkAllocationService.SaveJobOrder(0, 'SAVE').subscribe(obj => {
        this._CommonService.isLoading = false;
        this._WorkAllocationService.AllWorkAllocationDet = obj;
        this.toastr.success('Saved Successfully', 'Work Allocation')
        this.OnClickCancel();
      },
        error => {
          this.errorMsg = error;
          this._CommonService.isLoading = false;
          this.toastr.error('Something went Wrong', 'Work Allocation');
        });
    }
    else {
      this._CommonService.isLoading = true;
      this._WorkAllocationService.SaveJobOrder(this._WorkAllocationService.SelectedServiceEngWorkForecastingHdr.JoNo, 'MODIFY')
        .subscribe(obj => {
          this._CommonService.isLoading = false;
          this._WorkAllocationService.AllWorkAllocationDet = obj;
          this.toastr.success('Modified Successfully', 'Work Allocation')
          this.OnClickCancel();
        },
          error => {
            this.errorMsg = error;
            this._CommonService.isLoading = false;
            this.toastr.error('Something went Wrong', 'Work Allocation');
          });
    }
  }

  DeleteRow(filed: WorkSortingAssigning) {
    const index = this._WorkAllocationService.SelectedJobOrderHdr.WorkSortingAssigning.indexOf(filed);
    this._WorkAllocationService.SelectedJobOrderHdr.WorkSortingAssigning.splice(index, 1);
  }

  JobOrderDetSubmit() {
    this._WorkAllocationService.SaveJobOrder(this._WorkAllocationService.SelectedJobOrderHdr.JoNo, 'DELETE')
      .subscribe(obj => {
        this._WorkAllocationService.AllWorkAllocationDet = obj;
        this.toastr.success('Removed Successfully', 'Work Allocation')
        this.OnClickCancel();
      },
        error => {
          this.errorMsg = error;
          this.toastr.error('Something went Wrong', 'Work Allocation');
        });

  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('SUPPORTLEADER')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_Employee('ALL')
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillproject() {
    this._projectService.fetchProject('ALL', 0)
      .subscribe(data => {
        this._projectService.allProjects = data
        console.log(data)
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillbranch() {
    this._BranchService.Fetch_Branch('SUPPORTLEADER')
      .subscribe(data => {
        this._BranchService.allBranchs = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillparticulars() {
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  fillmodule() {
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data

      },
        error => {
          this.errorMsg = error;
        })
  }
  ToggleDevTest() {
    this._ProjectLeaderService.PLEmployeeWorksForecastList.forEach(data => {
      data.checkStatus = false;
    });
  }
  nextMonth() {
    this.filterDate = new Date(this.filterDate.setMonth(this.filterDate.getMonth() + 1));
    this.getEmpForcastDetails();
  }
  preMonth() {
    this.filterDate = new Date(this.filterDate.setMonth(this.filterDate.getMonth() - 1));
    this.getEmpForcastDetails();
  }
  getEmpForcastDetails() {
    this._ProjectLeaderService.GetEmployeeWorkForcastDetails('SE', this.filterDate, this.SupportLeaderId).subscribe(
      data => {
        this._ProjectLeaderService.EmployeeWorkForcastDetails = data;
        console.log('SE',data)
      }
    )
  }

  toggleFilter() {
    this._WorkAllocationService.isFiltering = this._WorkAllocationService.isFiltering ? false : true;
    if (!this._WorkAllocationService.isFiltering) {
      this._WorkAllocationService.ClearFilter();
      this._CommonService.pageIndex = 1;
      this._WorkAllocationService.pageIndex = 1;
    }
  }
  //Sorting
  sort(colName: string) {
    if (colName == this._WorkAllocationService.orderByColName) {
      this._WorkAllocationService.orderByDir = !this._WorkAllocationService.orderByDir;
    }
    this._WorkAllocationService.orderByColName = colName;
  }
  NoOfItems: number = 10;
}


