import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomerfeedbackmasterService } from 'src/app/Services/customerfeedbackmaster.service';
import { NgForm } from '@angular/forms';
import * as $ from "jquery";

@Component({
  selector: 'app-customer-feed-back-master-form',
  templateUrl: './customer-feed-back-master-form.component.html',
  styleUrls: ['./customer-feed-back-master-form.component.css']
})
export class CustomerFeedBackMasterFormComponent implements OnInit {
  public errorMsg;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  constructor(
    public _CustomerfeedbackmasterService: CustomerfeedbackmasterService,
    private toastr: ToastrService,
  ) { }


  ngOnInit(): void {
    this._CustomerfeedbackmasterService.formHideShow = 0;
    this.resetForm();
  }
  formValidation(): boolean {
    if (this._CustomerfeedbackmasterService.selectedRecord.Question != "" && this._CustomerfeedbackmasterService.selectedRecord.Code != "")
      return true;
    else
      return false;
  }
  onSubmit(frm: NgForm) {
    this.FormValidation = true
    if (frm.valid && this.formValidation()) {
      if (frm.valid) {
        this._CustomerfeedbackmasterService.SaveItem(this._CustomerfeedbackmasterService.selectedRecord).subscribe(
          async data => {
            if (data.Status == 0) {

              this.toastr.success(data.Message, "Customerfeedback Master")
              this.resetForm();
              this._CustomerfeedbackmasterService.getCustomerFeedBackMaster("ALL").subscribe(data => this._CustomerfeedbackmasterService.allCustomerFeedBackMaster = data,
                error => {
                  this.errorMsg = error;
                });
              this._CustomerfeedbackmasterService.formHideShow = 0;
            }
            else {
              this.toastr.warning(data.Message, "Customerfeedback Master")

            }
          }
        );

      }
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'Customerfeedback Master');
    }
  }


  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._CustomerfeedbackmasterService.formHideShow = 0;

  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this._CustomerfeedbackmasterService.selectedRecord = {
      ID: null,
      Code: '',
      Question: ''
    }




  }


}
