<div class="container-fluid" *ngIf="_BranchsettingsService.formHideShow==0">
    <h2 class="heading_all">BRANCH SETTINGS</h2>
    <br>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4 ">
            <table>
                <thead>
                    <tr>
                        <td>
                            <button type="button" class="btn-sm btn-info" (click)="addList()"><i class="fas fa-plus" aria-hidden="true"></i></button>
                        </td>
                        <td style="width:60%;"><input type="text" class="form-control-search" name="search"
                                [(ngModel)]="term"></td>
                        <td><button class="btn-info"><i class="fas fa-search" aria-hidden="true"></i></button></td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
    <br>
    <table>
        <thead>
            <tr>
                <th style="width:50px;">#</th>
                <th class="hidden-xs" style="width:130px;">
                    <div class="AtjIsPoint" (click)="sort('LicenseKeyValDays')">
                        <span *ngIf="!_BranchsettingsService.isFiltering">License Key Val Days</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort"
                                *ngIf="_BranchsettingsService.orderByColName!='LicenseKeyValDays' "></i>
                            <div *ngIf="_BranchsettingsService.orderByColName=='LicenseKeyValDays'">
                                <i class="fas fa-sort-amount-down" *ngIf="_BranchsettingsService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_BranchsettingsService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_BranchsettingsService.isFiltering">
                        <input type="text" #txtLicenseKeyValDaysFilter="ngModel"
                            [(ngModel)]="_BranchsettingsService.LicenseKeyValDaysFilter"
                            placeholder="LicenseKeyValDays">
                    </div>
                </th>
                <th style="width:210px;">
                    <div class="AtjIsPoint" (click)="sort('CustomerId')">
                        <span *ngIf="!_BranchsettingsService.isFiltering">Customer</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort"
                                *ngIf="_BranchsettingsService.orderByColName!='CustomerId' "></i>
                            <div *ngIf="_BranchsettingsService.orderByColName=='CustomerId'">
                                <i class="fas fa-sort-amount-down" *ngIf="_BranchsettingsService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_BranchsettingsService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_BranchsettingsService.isFiltering">
                        <select id="CustomerFilterId" name="CustomerFilterId" #CustomerFilterId="ngModel"
                        [(ngModel)]="_BranchsettingsService.CustomerFilterId">
                        <option [value]="''">SELECT Customer</option>
                        <option *ngFor="let obj of this._CustomerService.allCustomers" [value]="obj.Id">
                            {{obj.Name}}</option>
                        </select>
                    </div>
                </th>
                <th style="width:180px;">
                    <div class="AtjIsPoint" (click)="sort('BranchId')">
                        <span *ngIf="!_BranchsettingsService.isFiltering">Branch</span>
                        <div class="AtjfloatRight">
                            <i class="fas fa-sort"
                                *ngIf="_BranchsettingsService.orderByColName!='BranchId' "></i>
                            <div *ngIf="_BranchsettingsService.orderByColName=='BranchId'">
                                <i class="fas fa-sort-amount-down" *ngIf="_BranchsettingsService.orderByDir"></i>
                                <i class="fas fa-sort-amount-up-alt" *ngIf="!_BranchsettingsService.orderByDir"></i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="_BranchsettingsService.isFiltering">
                        <select id="BranchFilterId" name="BranchFilterId" #BranchFilterId="ngModel"
                        [(ngModel)]="_BranchsettingsService.BranchFilterId">
                        <option [value]="''">SELECT Branch</option>
                        <option *ngFor="let obj of this._BranchService.branchList" [value]="obj.Id">
                            {{obj.BranchName}}</option>
                        </select>
                    </div>
                </th>
                <th style="width:60px;"> Settings
                    <a class="AtjIsPoint AtjfloatRight" (click)="toggleFilter()">
                        <i *ngIf="!_BranchsettingsService.isFiltering" class="fas fa-filter"></i>
                        <i *ngIf="_BranchsettingsService.isFiltering" class="fas fa-times"></i>
                    </a>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let brnch of _BranchsettingsService.allBranchs | gridOperations  :_BranchsettingsService.pageIndex: 
                       {LicenseKeyValDays:_BranchsettingsService.LicenseKeyValDaysFilter,CustomerId:_BranchsettingsService.CustomerFilterId,BranchId:_BranchsettingsService.BranchFilterId}:
                       {Column:_BranchsettingsService.orderByColName,Dir:_BranchsettingsService.orderByDir} ; let i = index"
                class="ng-scope">
                <td style="width:50px;">{{i+1+((NoOfItems*_BranchsettingsService.pageIndex)-NoOfItems)}}</td>
                <td style="width:130px;">{{brnch.LicenseKeyValDays}}</td>
                <td style="width:210px;">
                    <label class="form-control-label" *ngFor="let obj of this._CustomerService.allCustomers" disabled>
                        <p *ngIf="brnch.CustomerId==obj.Id">
                            {{obj.Name}}
                        </p>
                    </label>
                </td>
                <td style="width:280px;">
                    <label class="form-control-label" *ngFor="let obj of this._BranchService.branchList">
                        <p *ngIf="brnch.BranchId==obj.Id">
                            {{obj.BranchName}}
                        </p>
                    </label>
                </td>
                <td style="width:60px;">
                    <button type="button" class="btn-success" (click)="showForEdit(brnch)">
                        <i class="fas fa-pen-square" aria-hidden="true"></i></button>
                    <button type="button" class="btn-danger" (click)="onDelete(brnch)"><i class="fas fa-trash"
                            aria-hidden="true"></i></button>
                </td>
            </tr><!-- end ngRepeat: item in items -->
            <tr>
                <td colspan="2" *ngIf="(_BranchsettingsService.allBranchs?.length<=0 )  || _CommonService.filterdItemCount<1">
                    No Records To Display.</td>
            </tr>
        </tbody>
        <tfoot>
            <td colspan="3" *ngIf="_CommonService.totalPageCount>1">
                <app-grid-pagination (pageIndexValue)="_BranchsettingsService.pageIndex=$event">
                </app-grid-pagination>
            </td>
        </tfoot>
    </table>
    <div class="margintop"></div>
</div>
<app-delete-confirm *ngIf="deletePopUp" [title]="'Branch'" (ConfirmEvent)="dialogResult($event)"></app-delete-confirm>