import { Component, OnInit } from '@angular/core';
import { customer } from 'src/app/Models/customer.model';
import { SoftwareRegistrationService } from 'src/app/Services/software-registration.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/Services/country.service';
import { DealerService } from 'src/app/Services/dealer.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { BranchService } from 'src/app/Services/branch.service';
import { ProjectService } from 'src/app/Services/project.service';
import { ModuleService } from 'src/app/Services/module.service';
import { NgForm } from '@angular/forms';
import { BussinessTypeService } from 'src/app/Services/bussiness-type.service';
import { BPEService } from 'src/app/Services/bpe.service';
import { CommonService } from 'src/app/Services/common.service';
import { SoftwareRegSystemDtls, SoftwareRegistrationAttachments } from 'src/app/Models/softwareRegistration.model';
import { IMG_ROOT_URL } from 'src/app/Models/Config';

@Component({
  selector: 'app-software-registration-form',
  templateUrl: './software-registration-form.component.html',
  styleUrls: ['./software-registration-form.component.css']
})
export class SoftwareRegistrationFormComponent implements OnInit {
  public show: boolean = false;
  constructor(public _swRegService: SoftwareRegistrationService,
    private toastr: ToastrService,
    public _countryService: CountryService,
    public _dealrService: DealerService,
    public _custService: CustomerService,
    public _brnchService: BranchService,
    public _projectService: ProjectService,
    public _businessTypeService: BussinessTypeService,
    public _bpeService: BPEService,
    public _ModuleService: ModuleService,
    public _CommonService: CommonService,

  ) { }

  Country_Id: any;
  public errorMsg;
  FileInvalidMessage = '';
  FileisValid = false;
  fileToUpload: File = null;
  selectedCustomer: customer;
  allCustomers1: customer[];
  public d: Date = new Date();

  SystemDetailsList: SoftwareRegSystemDtls
  filesToUpload: Array<File>;
  FileList: any[] = [];
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation

  ngOnInit(): void {
    this._swRegService.checkboxVisible = 0
    this._swRegService.ServerNameVisible = 0
    this._swRegService.moduleList = []
    this._swRegService.allSystemDetails = [];
    this._brnchService.allBranchs = []
    this._swRegService.formHideShow = 0;
    this._swRegService.formHidePassword = false;
    this.resetForm();
    this.LoadCustomers();
    this.LoadDataSets();
    this.GetAllModules();
    this.GetRefNo();
    this._CommonService.loadTerms(2);
  }

  resetForm(form?: NgForm) {
    this.isSubmit = false
    this.FormValidation = false
    if (form != null)
      form.reset();
    this.FileList = []

    this._swRegService.selectedSoftwareRegistration = {
      Id: 0,
      CountryId: null,
      DealerId: null,
      RefNo: '',
      CustomerId: null,
      CustomerName: '',
      Addr1: '',
      Addr2: '',
      Addr3: '',
      Nodes: null,
      ProjectId: null,
      BPEId: null,
      ContractDate: null,
      FileName: 'default.jpg',
      CentralizedServer: false,
      LocalServer: false,
      NetServer: false,
      ServerDetails: '',
      SoftwareRegModulesList: [],
      SoftwareRegSystemDtlsList: [],
      SoftwareRegistrationAttachments: [],
      SoftwareRegistrationAttachmentRemovedList: [],

      ReportServer: false,
      ServerName: '',
      Rent:null,
      FreeFor:0,
      Expiry: new Date(),
      Notes: '',
      SoftwareCost:null,
      SoftwareRegPaymentTermsList: [],
      TermsConditions: '',
      ServerID:undefined,
      StartDate:new Date(),
      EndDate:new Date(),
      TermID:undefined
    }
    this._swRegService.imageUrl = null
    this._swRegService.allSystemDetails = []
    this.LoadDealer();
    this._swRegService.moduleList = []
    this._swRegService.allModuleDetails = []
    this.GetAllModules();
    this.GetRefNo();
    this.FileList = [];
    this._swRegService.allSoftwareRegPaymentTerms = []
    this._swRegService.newAttribute = {
      Id: 0,
      Term: '',
      Date: null,
      Amount: 0,
      Remarks: ''
    }
    this._swRegService.tblVisible = 0;
    this._swRegService.checkboxVisible = 0;
    this._swRegService.ServerNameVisible = 0;
    this._swRegService.RowCount = 0;
  }

  LoadDataSets() {
    this._countryService.getCountry()
      .subscribe(data => {
        this._countryService.allCountries = data
      },
        error => {
          this.errorMsg = error;
        });

    this.LoadDealer();
    this.LoadCustomers();
    this._projectService.fetchProject('ALL', 0)
      .subscribe(data => {
        this._projectService.allProjects = data
      },
        error => {
          this.errorMsg = error;
        });
    this._businessTypeService.getBusinessType()
      .subscribe(data => this._businessTypeService.allBusinessTypes = data,
        error => {
          this.errorMsg = error;
        });
    this._bpeService.getBusinessPromotionExecutive('DEALERID')
      .subscribe(data => this._bpeService.allBusinessPromotionExecutives = data,
        error => {
          this.errorMsg = error;
        });
  }

  async GetRefNo() {
    this._swRegService.selectedSoftwareRegistration.RefNo = await this._CommonService.getRefNo('SoftReg', 'Dealer');
  }

  toggle() {
    if (this._swRegService.selectedSoftwareRegistration.CentralizedServer == true)
      this._swRegService.checkboxVisible = 1;
    else
      this._swRegService.checkboxVisible = 0;
  }

  ServerNametoggle() {
    if (this._swRegService.selectedSoftwareRegistration.ReportServer == true)
    {
      this._swRegService.ServerNameVisible = 1;
      this.fillServer();
    }
    else
    {
      this._swRegService.ServerNameVisible = 0;
      this._swRegService.selectedSoftwareRegistration.ServerID=0;
    }

  }

  datecal() {
    if (this._swRegService.selectedSoftwareRegistration.ContractDate != null && this._swRegService.selectedSoftwareRegistration.FreeFor != null) {
      let tempdate: Date = this._swRegService.selectedSoftwareRegistration.ContractDate
      this._swRegService.selectedSoftwareRegistration.Expiry = this.addMonths(new Date(tempdate), this._swRegService.selectedSoftwareRegistration.FreeFor);
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  radioChanged(e) {
    if (e == 'L') {
      this._swRegService.selectedSoftwareRegistration.CentralizedServer = true
      this._swRegService.selectedSoftwareRegistration.LocalServer = true
      this._swRegService.selectedSoftwareRegistration.NetServer = false

    }
    if (e == 'N') {
      this._swRegService.selectedSoftwareRegistration.CentralizedServer = true
      this._swRegService.selectedSoftwareRegistration.NetServer = true
      this._swRegService.selectedSoftwareRegistration.LocalServer = false
    }
  }

  LoadCustomers() {
    this._custService.FetchAllCustomer('DEALERID')
      .subscribe(data => {
        this._custService.allCustomers = data
        this._swRegService.selectedSoftwareRegistration.CustomerId = null
      },
        error => {
          this.errorMsg = error;
        });
  }

  calculateRowTotal(i: number) {
    if (this._swRegService.allSystemDetails[i].Date != null &&
      this._swRegService.allSystemDetails[i].FreeSupport != null) {
      var tempdate = this._swRegService.allSystemDetails[i].Date;
      this._swRegService.allSystemDetails[i].ExpiredOn = this.addMonths(new Date(tempdate), this._swRegService.allSystemDetails[i].FreeSupport);
    }
  }

  addMonths(date: Date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + parseInt(months));
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onChangeModules(event, cat: any) {
    if (event.target.checked)
      this._swRegService.moduleList.push(cat.Id)
    else {
      const index = this._swRegService.moduleList.indexOf(cat.Id);
      if (index > -1) {
        this._swRegService.moduleList.splice(index, 1);
      }
    }
  }

  GetAllModules() {
    this._ModuleService.getModuleCheckBox()
      .subscribe(data => {
        this._ModuleService.allModulesCheckBox = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  LoadDealer() {
    this._dealrService.FetchDealer('ID')
      .subscribe(data => {
        this._dealrService.allDealers = data
        this._swRegService.selectedSoftwareRegistration.CountryId = data[0].CountryId;
        this._swRegService.selectedSoftwareRegistration.DealerId = data[0].Id;
      })
  }
  
  LoadBranch() {
    this._swRegService.FetchSoftRegDtls(this._swRegService.selectedSoftwareRegistration.CustomerId, 'CUSTOMERID', this._swRegService.selectedSoftwareRegistration.CustomerId)
      .subscribe(data => {
        if (data.length > 0) {
          this._swRegService.allSystemDetails = data
        }
        else {
          this._swRegService.allSystemDetails = []
          this.toastr.error('Number of Branches Exceeds', "Software Registration")
        }
      },
        error => {
          this.errorMsg = error;
        });
  }

  onSelect(Cust_ID) {
    this._swRegService.selectedSoftwareRegistration.CustomerId = Cust_ID;
    var custDtls = this._custService.allCustomers.filter(e => e.Id == this._swRegService.selectedSoftwareRegistration.CustomerId)[0];
    this._swRegService.selectedSoftwareRegistration.Addr1 = custDtls.Addr1;
    this._swRegService.selectedSoftwareRegistration.Addr2 = custDtls.Addr2;
    this._swRegService.selectedSoftwareRegistration.Addr3 = custDtls.Addr3;
  }

  backList() {
    this.resetForm();
    this.isSubmit = false
    this.FormValidation = false
    this._swRegService.formHideShow = 0;
  }

  formValidation(): boolean {
    if (this._swRegService.selectedSoftwareRegistration.ContractDate != null && this._swRegService.selectedSoftwareRegistration.CustomerId != 0
      && this._swRegService.selectedSoftwareRegistration.Addr1 != "" && this._swRegService.selectedSoftwareRegistration.Addr2 != ""
      && this._swRegService.selectedSoftwareRegistration.Addr3 != "" && this._swRegService.selectedSoftwareRegistration.BPEId != 0
      && this._swRegService.selectedSoftwareRegistration.ProjectId != 0 && this._swRegService.selectedSoftwareRegistration.ServerDetails != "")
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    this.FormValidation = true
    if (this.formValidation()) {
      this.isSubmit = true
      if (this._swRegService.allSystemDetails.length > 0) {
        let b = this._swRegService.allSystemDetails.filter(e => e.TypeOfBusinessId === 0);
        b.forEach(f => this._swRegService.allSystemDetails.splice(this._swRegService.allSystemDetails.findIndex(e => e.TypeOfBusinessId === f.TypeOfBusinessId), 1));
        this.CheckFileValid(this.fileToUpload)
        this.saveFunction(form)
      }
      else
        this.toastr.warning('Branch is Not Registered', "Software Registration")
    }
    else 
      this.toastr.warning('Please fill all mandatory fields.', 'Software Registration');
  }

  saveFunction(form: NgForm) {
    form.value.CountryId = this.Country_Id;
    this._swRegService.SaveItem(this._swRegService.selectedSoftwareRegistration, this._swRegService.allSystemDetails, this.FileList, this._swRegService.allSoftwareRegPaymentTerms)
      .subscribe(
        async data => {
          this.isSubmit = false
          if (data.Status == 0) {
            this.toastr.success(data.Message, "Software Registration")
            this.resetForm();
            this.getSoftwareRegistration();
            this._swRegService.formHideShow = 0;
          }
          else {
            this.toastr.error(data.Message, "Software Registration")
          }
        })
  }

  getSoftwareRegistration() {
    this._swRegService.getSoftwareRegistration()
      .subscribe(data => {
        this._swRegService.allSoftwareRegistrations = data
      },
        error => {
          this.errorMsg = error;
        })
  }

  //File Validation
  CheckFileValid(file) {
    if (this.fileToUpload != null) {
      if ((file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/gif') && file.size <= (1600 * 1200)) {
        this.FileInvalidMessage = "";
        this.FileisValid = true;
      }
      else {
        this.FileisValid = false;
        this.FileInvalidMessage = "Only JPEG/PNG/Gif Image can be upload )";
      }
    }
  }

  handleFileInput(event) {

    if (event.target.files && event.target.files[0]) {
      let files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        this.FileList.push(files[i])
      }
    }
  }

  RemoveImage(image: File) {
    const index = this.FileList.indexOf(image);
    if (index > -1) {
      this.FileList.splice(index, 1);
    }
  }

  RemovePreviousImage(obj: SoftwareRegistrationAttachments) {
    const index = this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachments.indexOf(obj);
    if (index > -1) {
      this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachmentRemovedList.push(obj)
      this._swRegService.selectedSoftwareRegistration.SoftwareRegistrationAttachments.splice(index, 1);
    }
  }

  downloadFileUrl(URL) {
    window.open(IMG_ROOT_URL + 'Images/SoftwareRegistration/' + URL, '_blank');
  }


  deleteFieldValue(index) {
    this._swRegService.allSoftwareRegPaymentTerms.splice(index, 1);
    this._swRegService.RowCount = this._swRegService.allSoftwareRegPaymentTerms.length;
  }
  addFieldValue() {
    if (this._swRegService.newAttribute.Term != '' && this._swRegService.newAttribute.Date != null &&
      this._swRegService.newAttribute.Amount != '' && this._swRegService.newAttribute.Remark != '') {
      this._swRegService.allSoftwareRegPaymentTerms.push(this._swRegService.newAttribute)
      this._swRegService.RowCount = this._swRegService.allSoftwareRegPaymentTerms.length;
      this._swRegService.newAttribute = {
        Id: 0,
        Term: '',
        Date: null,
        Amount: '',
        Remarks: ''
      }
    }
    else
      this.toastr.warning('Please Enter Fields', 'Software Registration')
  }
  fillServer()
  {
    this._CommonService.getCommonList("ALLSERVER").subscribe(
      data=>{
        this._swRegService.allServer=data;
      }
    )
  }
}
