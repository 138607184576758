 <div class="container-fluid">

        <!-- The Modal -->
        <div class="modal fade show" id="myModalConfirm1">
            <div class="modal-dialog">

                <div *ngIf="CustomerRequirementForm">
                    <!--class="modal-dialog" -->
                    <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header">
                            <span class="modal-title">Customer Requirements</span>
                            <button type="button" class="close" (click)="OnClickCancelcus()">×</button>
                        </div>
                        <div class="modal-body" style="overflow: scroll;height: 600px;">

                            <div class="tab-content">
                                <div id="sec1" class="container-fluid tab-pane active">

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">Ref.No</label>
                                                <div class="form-group col-md-5">
                                                    <input class="form-control" name="RefNo" #RefNo="ngModel"
                                                        type="number" autocomplete="off" disabled
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.RefNo"
                                                        placeholder=" Ref No" required>

                                                </div>


                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">SE</label>

                                                <div class="form-group col-md-6">
                                                    <select class="form-control" name="SEID" #SEID="ngModel" disabled
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.SEID"
                                                        required>
                                                        <option [ngValue]="null" disabled>Select</option>
                                                        <option
                                                            *ngFor="let obj of _CustomerrequirementService.allSE | orderBy : 'EmpName'"
                                                            [ngValue]="obj.Id">
                                                            {{obj.EmpName}}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">Date</label>
                                                <div class="col-md-8">
                                                    <input class="section3" placeholder="Date" name="Date"
                                                        #Date="ngModel"
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.Date"
                                                        disabled style="color: black; width: 110px; text-align: left;"
                                                        autocomplete="off" bsDatepicker
                                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required>

                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-md-4  form-control-label"
                                                    for="select">Customer</label>
                                                <div class="col-md-8">
                                                    <select class="form-control" name="CustomerID" #CustomerID="ngModel"
                                                        disabled
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.CustomerID"
                                                        required>
                                                        <option [ngValue]="null" disabled>Select</option>
                                                        <option
                                                            *ngFor="let customer of _CustomerService.allCustomers | orderBy : 'Name'"
                                                            [ngValue]="customer.Id">
                                                            {{customer.Name }}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">Address</label>
                                                <div class="col-md-8">
                                                    <input class="form-control" name="CustAdr1" #CustAdr1="ngModel"
                                                        disabled
                                                        [(ngModel)]=" this._CustomerrequirementService.selectedRecord.CustAdr1"
                                                        placeholder=" Addr1" required>

                                                    <input class="form-control" name="CustAdr2" #CustAdr2="ngModel"
                                                        disabled
                                                        [(ngModel)]=" this._CustomerrequirementService.selectedRecord.CustAdr2"
                                                        placeholder=" Addr2" required>

                                                    <input class="form-control" name="CustAdr3" #CustAdr3="ngModel"
                                                        disabled
                                                        [(ngModel)]=" this._CustomerrequirementService.selectedRecord.CustAdr3"
                                                        placeholder=" Addr3" required>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">Time</label>
                                                <div class="col-md-8">

                                                    <!-- <input class="form-control" name="Time" #Time="ngModel" disabled
                                                        [ngModel]="_CustomerrequirementService.selectedRecord.Time| date: 'HH:mm' "
                                                        (ngModelChange)="_CustomerrequirementService.selectedRecord.Time = $event"
                                                        id="exampleInputName2" required type="time"> -->
                                                        <input class="form-control" name="Time" #Time="ngModel" autocomplete="off"
                                                        [(ngModel)]="this._CustomerrequirementService.selectedRecord.Time" required
                                                        type="time">
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-md-4  form-control-label" for="select">Mode of
                                                    Reg</label>
                                                <div class="col-md-8">
                                                    <select class="form-control" name="ModeOfReg" #ModeOfReg="ngModel"
                                                        disabled
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.ModeOfReg"
                                                        required>
                                                        <option [ngValue]="null" disabled>Select</option>
                                                        <option [ngValue]="1">By Phone</option>
                                                        <option [ngValue]="2">By Mail</option>
                                                        <option [ngValue]="3">By Chat</option>
                                                        <option [ngValue]="4" hidden>On Site</option>
                                                        <option [ngValue]="5" hidden>Remote Section</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-4  form-control-label" for="select">Branch</label>
                                                <div class="col-md-8">
                                                    <select class="form-control" name="BranchID" #BranchID="ngModel"
                                                        disabled
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.BranchID"
                                                        required>
                                                        <option [ngValue]="null" disabled>Select</option>
                                                        <option
                                                            *ngFor="let branch of _BranchService.allBranchs | orderBy : 'BranchName'"
                                                            [ngValue]="branch.Id">
                                                            {{ branch.BranchName }}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-md-4  form-control-label" for="select">Project</label>
                                                <div class="col-md-8">
                                                    <select class="form-control" name="ProjectID" #ProjectID="ngModel"
                                                        disabled
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.ProjectID"
                                                        required>
                                                        <option [ngValue]="null" disabled>Select</option>
                                                        <option
                                                            *ngFor="let project of _projectService.allProjects | orderBy : 'Name'"
                                                            [ngValue]="project.Id">
                                                            {{ project.Name }}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">Modules</label>
                                                <div class="col-md-8 moduleBox">
                                                    <div
                                                        *ngFor="let obj of _modulService.allModuleServiceReport | orderBy : 'ModuleName'">
                                                        <input disabled type="checkbox" [checked]="true"
                                                            name="obj.ModuleName"
                                                            value="{{obj.Id}}">&nbsp;{{obj.ModuleName}}
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-md-4">

                                            <div class="form-group row">

                                                <label class="col-md-2 form-control-label">Server</label>
                                                <div class="form-group col-md-4">
                                                    <input class="form-control" name="Server" #Server="ngModel"
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.Server"
                                                        disabled placeholder=" Server" required>
                                                </div>
                                                <label class="col-md-2 form-control-label">Node</label>
                                                <div class="form-group col-md-4">
                                                    <input class="form-control" name="Node" #Node="ngModel"
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.Node"
                                                        disabled placeholder="Node" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">Contract Date</label>
                                                <div class="col-md-4">
                                                    <input class="section3" placeholder="ContractDate"
                                                        name="ContractDate" #ContractDate="ngModel" disabled
                                                        [(ngModel)]="_CustomerrequirementService.selectedRecord.ContractDate"
                                                        style="color: black; width: 110px; text-align: left;"
                                                        autocomplete="off" bsDatepicker
                                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
                                                </div>
                                                <div class="col-md-4 "
                                                    *ngIf="_CustomerrequirementService.selectedRecord.ContractDate!=null">
                                                    <label style="color:green" class="form-control-label" disabled
                                                        *ngIf="this.AMCRenewalStatus">ON
                                                        AMC</label>
                                                    <label style="color:red" class="form-control-label" disabled
                                                        *ngIf="!this.AMCRenewalStatus">AMC
                                                        EXIPIRED</label>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label disabled
                                                    class="col-md-12 form-control-label">{{this._CustomerrequirementService.selectedRecord.ContractVsCurrentDate}}</label>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">AMC UpTo</label>
                                                <div class="form-group col-md-6">
                                                    <input class="form-control" name="AMCUPTo" #AMCUPTo="ngModel"
                                                        disabled
                                                        [(ngModel)]="this._CustomerrequirementService.selectedRecord.AMCUPTo"
                                                        placeholder="AMCUPTo" bsDatepicker
                                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-md-4 form-control-label">No Of Visits</label>
                                                <div class="form-group col-md-6">
                                                    <input class="form-control" name="NoOfVisits" #NoOfVisits="ngModel"
                                                        disabled
                                                        [(ngModel)]="this._CustomerrequirementService.selectedCustomerRequirement_Common.NoOfVisits"
                                                        placeholder="No Of Visits" required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-md-5 form-control-label" disabled
                                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit!=null">
                                                    Last
                                                    Visit:{{this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit|date}}
                                                </label>

                                                <label
                                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE!=0"
                                                    disabled class="col-md-2 form-control-label">
                                                    SE:
                                                </label>
                                                <label
                                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE!=0"
                                                    class="col-md-5 form-control-label">
                                                    <select class="form-control" name="SE" #SE="ngModel" disabled
                                                        [(ngModel)]="this._CustomerrequirementService.selectedCustomerRequirement_Common.SE"
                                                        required>
                                                        <option [ngValue]="0" disabled>Select</option>
                                                        <option
                                                            *ngFor="let obj of this._CustomerrequirementService.allSE | orderBy : 'EmpName'"
                                                            [ngValue]="obj.Id">
                                                            {{obj.EmpName}}
                                                        </option>
                                                    </select>
                                                </label>

                                            </div>
                                            <div class="form-group row">
                                                <label
                                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate!=null"
                                                    disabled class="col-md-6 form-control-label">
                                                    Script:{{this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate|date}}
                                                </label>
                                                <label class="col-md-6 form-control-label" disabled
                                                    *ngIf="this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate!=null">
                                                    EXC:
                                                    {{this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate|date}}
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    <hr>
                                    <div style="text-align: center;">
                                        <h6>
                                            <ul>Work History</ul>
                                        </h6>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div class="tWrap">
                                            <div class="tWrap__head">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">SlNo</th>
                                                        <th scope="col">Ref No</th>
                                                        <th scope="col">Module</th>
                                                        <th scope="col">Particular</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Priority</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Remarks</th>

                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div class="tWrap__body">
                                            <table>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let field of this._CustomerrequirementService.allCustomerReqHistory ; let i = index">
                                                        <th style="background:white;color:black;"  scope="row">{{i+1}}</th>
                                                        <td>{{field.RefNo}}</td>
                                                        <td>
                                                            {{field.ModuleName}}
                                                        </td>
                                                        <td>
                                                            {{field.ParticularName}}
                                                        </td>
                                                        <td>
                                                            {{field.Description}}
                                                        </td>
                                                        <td>
                                                            <span class="form-control-label"
                                                                *ngFor="let obj of TypeList" disabled>
                                                                <p *ngIf="obj.Id==field.Type">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="form-control-label"
                                                                *ngFor="let obj of PriorityList" disabled>
                                                                <p *ngIf="obj.Id==field.Priority">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="form-control-label" disabled
                                                                *ngFor="let obj of StatusFullList">
                                                                <p *ngIf="obj.Id==field.Status">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {{field.Remarks}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                          
                                            </table>
                                        </div>
                                        </div>
                                    </div>

                                    <hr>
                                    <div style="text-align: center;">
                                        <h6>
                                            <ul>Customer Requirement</ul>
                                        </h6>
                                    </div>
                                    <br>
                                    <div class="row">
                                        <div  class="tWrap" disabled>

                                            <div class="tWrap__head">
                                            <table >

                                                <thead>
                                                    <tr>
                                                        <th scope="col">SlNo</th>
                                                        <th scope="col">Module</th>
                                                        <th scope="col">Particular</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Priority</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Remarks</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div class="tWrap__body">
                                            <table>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let field of this._CustomerrequirementService.allCustomerDtls ; let i = index">
                                                        <th  style="background:white;color:black;" scope="row">{{i+1}}</th>
                                                        <td>
                                                            {{field.ModuleName}}
                                                        </td>
                                                        <td>
                                                            {{field.ParticularName}}
                                                        </td>
                                                        <td>
                                                            {{field.Description}}
                                                        </td>
                                                        <td>
                                                            <span class="form-control-label"
                                                                *ngFor="let obj of TypeList">
                                                                <p *ngIf="obj.Id==field.Type">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="form-control-label"
                                                                *ngFor="let obj of PriorityList">
                                                                <p *ngIf="obj.Id==field.Priority">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <div *ngIf="field.Status==3||field.Status==4||field.Status==5||field.Status==6||field.Status==7||field.Status==8||field.Status==9">
                                                                <span class="form-control-label" *ngFor="let obj of StatusFullList" >
                                                                    <p *ngIf="obj.Id==field.Status">
                                                                        {{obj.Name}}
                                                                    </p>
                                                                </span>
                                                            </div>
                                                            <div *ngIf="field.Status==1||field.Status==2||field.Status==10">
                                                                <span class="form-control-label" *ngFor="let obj of StatusList" >
                                                                    <p *ngIf="obj.Id==field.Status">
                                                                        {{obj.Name}}
                                                                    </p>
                                                                </span>
                                                               
                                                            </div>
                                                            <!-- <span class="form-control-label"
                                                                *ngFor="let obj of StatusList">
                                                                <p *ngIf="obj.Id==field.Status">
                                                                    {{obj.Name}}
                                                                </p>
                                                            </span> -->
                                                        </td>
                                                        <td>
                                                            {{field.Remarks}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>


                                        </div>
                                        </div>


                                          


                                    </div>
                                    <br>
                                    <div class="row">
                                        <label class="col-md-2  form-control-label" for="select">Note</label>
                                        <div class="col-md-10">
                                            <textarea class="form-control" name="Note" #Note="ngModel" disabled
                                                [(ngModel)]="this._CustomerrequirementService.selectedRecord.Note"
                                                placeholder=" Notes" required></textarea>

                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>

                    </div>

                </div>

                <div *ngIf="WarningModel">

                    <!--class="modal-dialog" -->
                    <div class="modal-content" style="width: 62%;">
                        <!-- Modal Header -->
                        <div class="modal-header">
                            <!-- <span class="modal-title">Job Order</span> -->
                            <button type="button" class="close" (click)="OnClickCancelcus()">×</button>
                        </div>

                        <!-- <div class="modal-body text-center">
                                    <p>Do you want to Modify existing JobOrder?</p>
                                    <button type="button" class=" btn-success" (click)="confirm()">Yes</button>
                                    <button type="button" class=" btn-info" (click)="decline()">No</button>
                                </div> -->
                    </div>
                </div>
            </div>

        </div>
    </div>
