import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-allocation-list',
  templateUrl: './project-allocation-list.component.html',
  styleUrls: ['./project-allocation-list.component.css']
})
export class ProjectAllocationListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
