import { Component, OnInit } from '@angular/core';
import { DealerService } from 'src/app/Services/dealer.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { ProjectService } from 'src/app/Services/project.service';
import { BPEService } from 'src/app/Services/bpe.service';
import { ModuleService } from 'src/app/Services/module.service';
import { ReasonmasterService } from 'src/app/Services/reasonmaster.service';
import { NewleadService } from 'src/app/Services/newlead.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
@Component({
  selector: 'app-new-lead',
  templateUrl: './new-lead.component.html',
  styleUrls: ['./new-lead.component.css']
})
export class NewLeadComponent implements OnInit {

  constructor(

    private toastr: ToastrService,
    public _dealrService: DealerService,
    public _newleadService: NewleadService,
    public _custService: CustomerService,
    public _projectService: ProjectService,
    public _bpeService: BPEService,
    public _ModuleService: ModuleService,
    public _ReasonmasterService: ReasonmasterService,
    public _EmployeeService: EmployeeService,
    public _CommonService: CommonService

  ) { }


  public errorMsg;
  DealerId: number = 0
  ReferedList: any;
  ActionList: any;
  StatusList: any;
  isSubmit: boolean  //API call multiple 
  FormValidation: boolean  //Used form form validation


  ngOnInit(): void {
    this.resetForm();
    this.LoadDataSets();
    this._newleadService.allNewLeadDet = [];
  }


  resetForm(form?: NgForm) {

    this.isSubmit = false
    this.FormValidation = false
    console.log('hi')
    if (form != null)
      form.reset();
    this._newleadService.FormUpdation = false
    this._newleadService.referedbycusVisible = 0
    this._newleadService.referedbyempVisible = 0
    this._newleadService.reasonVisible = 0
    this._newleadService.selectedNewLeads = {
      Id: 0,
      DealerId: 0,
      RefNo: 0,
      Date: null,
      BpeId: 0,
      CustomerId: '',
      CustAddr1: null,
      CustAddr2: null,
      CustAddr3: null,
      ContactPerson: null,
      ContactNo: null,
      Email: null,
      ProjectId: 0,
      ReferedBy: 0,
      ReferedEmployee: 0,
      NextAction: 0,
      NextActionDate: null,
      Note: null,
      Status: 0,
      Reason: 0,
      Remark: null,
      NewLeadDet: [],
      NewLeadProjectModules: [],
    }
    this._newleadService.newAttribute = {
      SNo: 1,
      Requirement: null,
    }

    this._newleadService.RowCount = 0;
    this._newleadService.allNewLeadDet = [];
    this._newleadService.moduleList = []
    this._ModuleService.allModulesCheckBox = []

    this.LoadDataSets();


  }


  GetAllModules() {
    this._ModuleService.getModuleCheckBox()
      .subscribe(data => {
        this._ModuleService.allModulesCheckBox = data
      },
        error => {
          this.errorMsg = error;
        })
  }
  async GetRefNo() {
    this._CommonService.FormID = 'NewLead';
    this._CommonService.LoginUser = localStorage.getItem('Designation');
    this._newleadService.selectedNewLeads.RefNo = parseInt(await this._CommonService.getRefNoById(this._newleadService.selectedNewLeads.DealerId, this._CommonService.FormID, this._CommonService.LoginUser));
  }
  LoadDataSets() {
    this.ReferedList = []
    this.ReferedList = this._CommonService.GetReferedByList()
    this.ActionList = []
    this.ActionList = this._CommonService.GetActionList()
    this.StatusList = []
    this.StatusList = this._CommonService.GetNewLeadStatusList()
    this.GetAllModules()
    this.LoadDealer();
    this.LoadBPE();

    this._projectService.fetchProject('ALL', 0)
      .subscribe(data => this._projectService.allProjects = data,
        error => {
          this.errorMsg = error;
        });
  }
  onSelect(ReferedBy: number) {
    console.log('ReferedBy', ReferedBy)
    if (ReferedBy == 1) {
      this._newleadService.referedbyempVisible = 0
      this._newleadService.referedbycusVisible = 1
      this.LoadCustomers();
    }
    else if (ReferedBy == 4) {
      this._newleadService.referedbycusVisible = 0
      this._newleadService.referedbyempVisible = 1
      this.LoadEmployees()
    }
    else if (ReferedBy != 4 && ReferedBy != 1) {
      this._newleadService.referedbycusVisible = 0
      this._newleadService.referedbyempVisible = 0
    }
    console.log('this._newleadService.selectedNewLeads.ReferedEmployeeewwerwe', this._newleadService.selectedNewLeads.ReferedEmployee)
  }

  onStatusSelect(Status: number) {
    console.log('Status', Status)
    if (Status == 4) {
      this._newleadService.reasonVisible = 1
      this.LoadReason();
    }
    else {
      this._newleadService.reasonVisible = 0
    }

  }
  LoadReason() {
    this._ReasonmasterService.getReasonMaster('ALL')
      .subscribe(data => this._ReasonmasterService.allReasonMaster = data,
        error => {
          this.errorMsg = error;
        });
    console.log('this._ReasonmasterService.allReasonMaster', this._ReasonmasterService.allReasonMaster)
  }
  LoadBPE() {
    this._bpeService.getBusinessPromotionExecutiveById('DEALERID', this._newleadService.selectedNewLeads.DealerId.toString())
      .subscribe(data => this._bpeService.allBusinessPromotionExecutives = data,
        error => {
          this.errorMsg = error;
        });
  }

  onChangeModules(event, cat: any) {
    if (event.target.checked)
      this._newleadService.moduleList.push(cat.Id)
    else {
      const index = this._newleadService.moduleList.indexOf(cat.Id);
      if (index > -1) {
        this._newleadService.moduleList.splice(index, 1);
      }
    }
  }

  LoadCustomers() {
    this._custService.FetchAllCustomerById('DEALERID', this._newleadService.selectedNewLeads.DealerId)
      .subscribe(data => {
        this._custService.allCustomers = data
        this._newleadService.selectedNewLeads.ReferedEmployee = null
      },
        error => {
          this.errorMsg = error;
        });
  }
  LoadEmployees() {
    this._EmployeeService.Fetch_EmployeeById('DEALER', this._newleadService.selectedNewLeads.DealerId)
      .subscribe(data => {
        this._EmployeeService.allEmployees = data
        this._newleadService.selectedNewLeads.ReferedEmployee = null
      },
        error => {
          this.errorMsg = error;
        });
  }
  deleteFieldValue(index) {
    this._newleadService.allNewLeadDet.splice(index, 1);
    this._newleadService.RowCount = this._newleadService.allNewLeadDet.length;
  }

  addFieldValue() {
    this._newleadService.allNewLeadDet.push(this._newleadService.newAttribute)
    this._newleadService.RowCount = this._newleadService.allNewLeadDet.length;
    this._newleadService.newAttribute = {
      SNo: this._newleadService.RowCount + 1,
      Requirement: null,
    }
  }

  SearchCustReq() {
    if (this._newleadService.selectedNewLeads.RefNo != null)
      this._newleadService.getNewLead().subscribe(obj => {
        if (obj.Id != 0) {
          this._newleadService.FormUpdation = true
          this._newleadService.selectedNewLeads = obj
          this._newleadService.selectedNewLeads.Date = new Date(obj.Date)
          this._newleadService.selectedNewLeads.DealerId = obj.DealerId
          this.LoadBPE()
          this._newleadService.selectedNewLeads.BpeId = obj.BpeId
          this._newleadService.selectedNewLeads.NextActionDate = new Date(obj.NextActionDate)

          this._newleadService.getAllModuleByLeadId(this._newleadService.selectedNewLeads.Id).subscribe(obj => {
            this._ModuleService.allModulesCheckBox = []
            this._ModuleService.allModulesCheckBox = obj;
            this._ModuleService.allModulesCheckBox.forEach(obj => {
              if (obj.Selected)
                this._newleadService.moduleList.push(obj.Id)
            })
          })
          this._newleadService.allNewLeadDet = obj.NewLeadDet
          this._newleadService.RowCount = this._newleadService.allNewLeadDet.length;
          this._newleadService.newAttribute = {
            SNo: this._newleadService.RowCount + 1,
            Requirement: ''
          }
          this._newleadService.selectedNewLeads.ReferedEmployee = obj.ReferedEmployee
          if (this._newleadService.selectedNewLeads.ReferedEmployee != 0)
            this._newleadService.referedbycusVisible = 1
          else
            this._newleadService.referedbycusVisible = 0
          this._newleadService.selectedNewLeads.Reason = obj.Reason
        }
        else {
          this.resetForm()
          this.toastr.warning('RefNo Does Not exist', 'Customer Requirements')
        }
      })
  }

  LoadDealer() {


    this._dealrService.FetchDealer('ALL')
      .subscribe(data => {

        this._dealrService.allDealers = data
        //this._newleadService.selectedNewLeads.DealerId = data[0].Id;
      })



  }
  formValidation(): boolean {
    //  && this._custService.selectedCustomer.CustFax != "" && this._custService.selectedCustomer.URL != ""
    //   && this._custService.selectedCustomer.ContactPerson != ""
    if (this._newleadService.selectedNewLeads.DealerId > 0 && this._newleadService.selectedNewLeads.RefNo > 0 && this._newleadService.selectedNewLeads.Date != null
      && this._newleadService.selectedNewLeads.BpeId > 0 && this._newleadService.selectedNewLeads.ContactPerson != "" && this._newleadService.selectedNewLeads.NextAction > 0
      && this._newleadService.selectedNewLeads.ContactNo != "" && this._newleadService.selectedNewLeads.CustomerId != "" && this._newleadService.selectedNewLeads.CustAddr1 != ""
      && this._newleadService.selectedNewLeads.CustAddr2 != "" && this._newleadService.selectedNewLeads.CustAddr3 != "" && this._newleadService.selectedNewLeads.Email != ""
      && this._newleadService.selectedNewLeads.NextActionDate != null && this._newleadService.selectedNewLeads.ReferedBy > 0 && this._newleadService.selectedNewLeads.ProjectId > 0
      && this._newleadService.selectedNewLeads.Note != "" && this._newleadService.selectedNewLeads.Status > 0)
      return true;
    else
      return false;
  }
  onSubmit(frm: NgForm) {
    this.FormValidation = true
    if (frm.valid && this.formValidation()) {
      this.isSubmit = true
      if (this._newleadService.allNewLeadDet.length > 0) {
        this._newleadService.SaveItem(this._newleadService.selectedNewLeads, this._newleadService.allNewLeadDet).subscribe(
          async data => {
            this.isSubmit = false
            if (data.Status == 0) {

              this.toastr.success(data.Message, "New Lead")

              this.resetForm();

            }
            else {
              this.toastr.warning(data.Message, "New Lead")

            }
          }
        );
      }
    }
    else {
      this.toastr.warning('Please fill all mandatory fields.', 'New Lead');
    }
  }
  isNumber(event) {
    if (event.charCode !== 0) {
      const pattern = /^[0-9]\d*$/;// /^\d{0,4}(\.\d{0,4})?$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode != 13) {
        event.preventDefault();
      }
    }
  }
}
