import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ROOT_URL } from '../Models/Config';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/observable';
import { ReasonMaster } from '../Models/ReasonMaster';
import { GlobalErrorHandlerService } from './global-error-handler.service'


@Injectable({
  providedIn: 'root'
})
export class ReasonmasterService {

  constructor(
    private http: HttpClient,
    private _GlobalErrorHandlerService: GlobalErrorHandlerService
  ) { }
  
  selectedRecord:ReasonMaster;
  ReasonMasterList:ReasonMaster[];
  formHideShow = 0;
  allReasonMaster:ReasonMaster[]; 
  pageIndex: number = 0;
  isFiltering: boolean = false;
  CodeFilter: string = '';
  ReasonFilter: string = '';
  orderByDir: boolean = false;
  orderByColName: string = '';
  giveEditPermission:boolean=false;

  clearValue(){
    this.selectedRecord={
      ID:0,
      Code:'',
      Reason:''
    }
  }

  ClearFilter() {
    this.isFiltering = false;
    this.CodeFilter = '';
    this.ReasonFilter = '';

  }
  getReasonMaster(TransMode: string): Observable<ReasonMaster[]> {
    let params = new HttpParams();
    params = params.append('TransMode', TransMode);
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get<ReasonMaster[]>(ROOT_URL + 'ReasonMaster/GetAllData', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  SaveItem(Values: ReasonMaster): Observable<any> {
    var body = {
      Id: Values.ID,
      Code: Values.Code,
      Reason: Values.Reason
    }
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<any>(ROOT_URL + "ReasonMaster/InsUpdDelReasonMaster", body, {
      headers
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
  }
  onDelete(Id:number){
    let params = new HttpParams();
    params = params.append('DeleteID', Id.toString());
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete<any>(ROOT_URL + 'ReasonMaster/DeleteReasonMaster', {
      headers, params
    })
      .pipe(
        catchError((error) => this._GlobalErrorHandlerService.handleError(error))
      );
    }

}
