import { Component, OnInit } from '@angular/core';
import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { Chart } from 'chart.js';
import { ServiceEngineerDBService } from 'src/app/Services/service-engineer-db.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { CommonService } from 'src/app/Services/common.service';
import { CustomerrequirementService } from 'src/app/Services/customerrequirement.service';
import { SECustomDashBoardCustReq } from 'src/app/Models/Dashboard.Model';
import { NgForm } from '@angular/forms';
import { BranchService } from 'src/app/Services/branch.service';
import { EmployeeService } from 'src/app/Services/employee.service';
import { WorkAllocationService } from 'src/app/Services/work-allocation.service';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { NodeRegistrationService } from 'src/app/Services/node-registration.service';
import { ProjectService } from 'src/app/Services/project.service';
import { ModuleService } from 'src/app/Services/module.service';
import { ServiceReportEntryService } from 'src/app/Services/service-report-entry.service';
import { ParticularmasterService } from 'src/app/Services/particularmaster.service';
import { ImageModel } from 'src/app/Models/Image.model';
import { IMG_ROOT_URL, ROOT_URL } from 'src/app/Models/Config';

@Component({
  selector: 'app-support-engineer-home-screen',
  templateUrl: './support-engineer-home-screen.component.html',
  styleUrls: ['./support-engineer-home-screen.component.css']
})
export class SupportEngineerHomeScreenComponent implements OnInit {

  private chart: AmChart;
  errorMsg: any;
  PriorityList: any;

  jobOrderForm: boolean;
  serviceEngWF: boolean;
  WarningModel: boolean;

  StatusList: any;
  StatusFullList: any;
  TypeList: any;
  // SupportEngId: any

  AMCRenewalStatus: boolean
  StatusFullListCustom: any;
  ModuleDateFullList: any = []


  constructor(public AmCharts: AmChartsService,
    private toastr: ToastrService,
    public _CustomerService: CustomerService,
    public _ServiceReportEntryService: ServiceReportEntryService,
    public _CommonService: CommonService,
    public _CustomerrequirementService: CustomerrequirementService,
    public _serviceEngineerDBService: ServiceEngineerDBService,
    public _BranchService: BranchService,
    public _EmployeeService: EmployeeService,
    public _WorkAllocationService: WorkAllocationService,
    public _modulService: ModuleService,
    public _projectService: ProjectService,
    public _nodeRegService: NodeRegistrationService,
    public _ParticularmasterService: ParticularmasterService,

  ) { }

  ngOnInit() {


    this.Reset();
    // this.getDealerId()
    this.getListCommon();
    this.fillproject();

    this.getHomeScreenData();
    this.LoadCharts();
  }

  // async getDealerId() {
  // if (localStorage.getItem("Designation") == 'Support Engineer')
  //   this.SupportEngId = this._CommonService.getLoginedUserId()
  // else
  //   this.SupportEngId = 0

  // this._CommonService.FormID = 'SRE';
  // this._CommonService.LoginId = this.SupportEngId

  // if (localStorage.getItem("Designation") == 'Support Engineer') {
  //   this._CommonService.LoginUser = 'SupportEngineer';
  // }
  // else {
  //   this._CommonService.LoginId = this._ServiceReportEntryService.SelectedServiceRepHeader.DealerId;
  //   this._CommonService.LoginUser = 'Provider';
  // }
  // }

  getListCommon() {
    this.LoadCustomers();
    this.fillServiceEngineer();
    this.fillbranch();
    this.fillmodule();
    this.fillparticulars();

    this.PriorityList = []
    this.StatusList = []
    this.TypeList = []
    this.StatusFullList = []
    this.StatusFullList = this._CommonService.GetStatusFullList();
    this.PriorityList = this._CommonService.GetPriorityList()
    this.StatusList = this._CommonService.GetStatusFullList();
    this.TypeList = this._CommonService.GetTypeList();
    this.StatusFullListCustom = this._CommonService.GetStatusFullListCustom();


  }

  fillbranch() {
    this._BranchService.Fetch_Branch('SERVENGID')
      .subscribe(data => {
        this._BranchService.allBranchs = data
        // this._CustomerrequirementService.selectedRecord.BranchID = null
        // if (this._BranchService.allBranchs.length == 1) {
        //   this._CustomerrequirementService.selectedRecord.BranchID = this._BranchService.allBranchs[0].Id
        // }
      },
        error => {
          this.errorMsg = error;
        });
  }
  fillproject() {
    this._projectService.fetchProject('ALL', 0)
      .subscribe(data => this._projectService.allProjects = data,
        error => {
          this.errorMsg = error;
        });
  }
  Reset() {

    this._WorkAllocationService.SelectedJobOrderHdr = {
      BranchAdr1: null,
      BranchAdr2: null,
      BranchAdr3: null,
      BranchId: null,
      CPBranch: null,
      CustAdr1: null,
      CustAdr2: null,
      CustAdr3: null,
      CustomerId: null,
      CPCutomer: null,
      IssueDate: null,
      JoNo: null,
      CPBranchPhone: null,
      CPCustomerPhone: null,
      From: null,
      To: null,
      FromTime: null,
      ServiceMode: 0,
      ToTime: null,
      WorkSortingAssigning: [],
      SEWorkDet: [],
      Notes: null,
      CurrentCharge: 0,
      ServiceCharge: 0,
      EmployeeType: 0,
      AttachmentList: [],

      ProjectId: 0,
      ContractDate: null,
      ContractVsCurrentDate: null,
      NumberOfVisit: 0
    }
    this._CustomerrequirementService.selectedCustomerRequirement_Common = {
      ExeUpdatedDate: null,
      LastVisit: null,
      NoOfVisits: 0,
      SE: 0,
      ScriptUpdatedDate: null,
      TotalRequirements:0
    }
    this._modulService.allModuleServiceReport = []
  }

  LoadCustomers() {
    this._CustomerService.FetchAllCustomer('SUPPORTENG')
      .subscribe(data => {
        this._CustomerService.allCustomers = data
      },
        error => {
          this.errorMsg = error;
        });
  }

  fillServiceEngineer() {
    this._EmployeeService.Fetch_EmployeeById('ALL', 0)
      .subscribe(data => {
        this._CustomerrequirementService.allSE = data;
      },
        error => {
          this.errorMsg = error;
        })
  }

  getHomeScreenData() {
    // alert(1)
    this._serviceEngineerDBService.getPendingWorks().subscribe(obj => {
      this._serviceEngineerDBService.SelectedSEDashBoardCustReq = obj
      console.log('this._serviceEngineerDBService.SelectedSEDashBoardCustReq :', this._serviceEngineerDBService.SelectedSEDashBoardCustReq)
    })
  }

  getCusReqDet(obj: SECustomDashBoardCustReq) {
    localStorage.setItem('sehome', 'selecteddata');
    this._serviceEngineerDBService.allSEDashBoardCustReq = obj;
  }


  //-------------------------------------------Job Order Form Review-----------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------

  OnClickAllot(obj: SECustomDashBoardCustReq) {

    this._WorkAllocationService.getJobOrderByJobId(obj.JoId).subscribe(data => {
      console.log('getJobOrderByJobId', data)
      if (data.JoNo > 0) {
        this._WorkAllocationService.SelectedJobOrderHdr = Object.assign({}, data);
        this._WorkAllocationService.SelectedJobOrderHdr.IssueDate = new Date(data.IssueDate)
        this._WorkAllocationService.SelectedJobOrderHdr.ServiceCharge = data.ServiceCharge
        this._WorkAllocationService.SelectedJobOrderHdr.From = new Date(data.From)
        this._WorkAllocationService.SelectedJobOrderHdr.To = new Date(data.To)
        this._WorkAllocationService.SelectedJobOrderHdr.FromTime = data.FromTime
        this._WorkAllocationService.SelectedJobOrderHdr.ToTime = data.ToTime
        this._WorkAllocationService.SelectedJobOrderHdr.WorkSortingAssigning.forEach(field => {
          this._WorkAllocationService.SelectedJobOrderHdr.ProjectId = field.ProjectId

        })
        this._CustomerService.allCustomers.forEach(obj => {
          if (obj.Id = this._WorkAllocationService.SelectedJobOrderHdr.CustomerId) {
            this._WorkAllocationService.SelectedJobOrderHdr.CustAdr1 = obj.Addr1
            this._WorkAllocationService.SelectedJobOrderHdr.CustAdr2 = obj.Addr2
            this._WorkAllocationService.SelectedJobOrderHdr.CustAdr3 = obj.Addr3
            this._WorkAllocationService.SelectedJobOrderHdr.CPCutomer = obj.ContactPerson
            this._WorkAllocationService.SelectedJobOrderHdr.CPCustomerPhone = obj.ContactPhone
          }
        })
        this._BranchService.allBranchs.forEach(obj => {
          if (obj.Id = this._WorkAllocationService.SelectedJobOrderHdr.BranchId) {
            this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr1 = obj.Addr1
            this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr2 = obj.Addr2
            this._WorkAllocationService.SelectedJobOrderHdr.BranchAdr3 = obj.Addr3
            this._WorkAllocationService.SelectedJobOrderHdr.CPBranch = obj.ContactPerson
            this._WorkAllocationService.SelectedJobOrderHdr.CPBranchPhone = obj.ContactPhone
          }
        })

        if (this.ModuleDateFullList.length < 1) {
          this._modulService.Fetch_ModuleServiceReport(this._WorkAllocationService.SelectedJobOrderHdr.CustomerId, this._WorkAllocationService.SelectedJobOrderHdr.BranchId, this._WorkAllocationService.SelectedJobOrderHdr.ProjectId)
            .subscribe(data => {
              this._modulService.allModuleServiceReport = data
              this._modulService.allModuleServiceReport.forEach(obj => {
                obj.ExeDate = null
                obj.ExeDateToBind = null
                obj.ScriptDate = null
                obj.ScriptDateToBind = null
              })
            },
              error => {
                this.errorMsg = error;
              })
        }



        this._CommonService.LoadSoftWareDtlsCommon(this._WorkAllocationService.SelectedJobOrderHdr.CustomerId, this._WorkAllocationService.SelectedJobOrderHdr.BranchId, this._WorkAllocationService.SelectedJobOrderHdr.ProjectId).
          subscribe(obj => {
            // this._ServiceReportEntryService.SelectedServiceRepHeader.Server = obj.NoOfServer
            // this._ServiceReportEntryService.SelectedServiceRepHeader.Node = obj.Nodes
            this._WorkAllocationService.SelectedJobOrderHdr.ContractVsCurrentDate = obj.ContractVsCurrentDate
            // this._ServiceReportEntryService.SelectedServiceRepHeader.AMCRenewal = new Date(obj.AMCRenewal)
            // this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto = new Date(obj.AMCUPTo)
            this._WorkAllocationService.SelectedJobOrderHdr.ContractDate = this.convert(obj.ContractDate)
            // if (this._ServiceReportEntryService.SelectedServiceRepHeader.AMCUpto > new Date())
            //   this.AMCRenewalStatus = true
          })


        this._CustomerrequirementService.GetCustomerRequirementsHistory(this._WorkAllocationService.SelectedJobOrderHdr.CustomerId, this._WorkAllocationService.SelectedJobOrderHdr.BranchId, this._WorkAllocationService.SelectedJobOrderHdr.ProjectId, 0)
          .subscribe(obj => {
            this._WorkAllocationService.SelectedJobOrderHdr.NumberOfVisit = obj.NoOfVisits
            this._CustomerrequirementService.selectedCustomerRequirement_Common.LastVisit = obj.LastVisitDate
            this._CustomerrequirementService.selectedCustomerRequirement_Common.SE = obj.LastVisitSE
            this._CustomerrequirementService.selectedCustomerRequirement_Common.ExeUpdatedDate = obj.LastUpdExeDate
            this._CustomerrequirementService.selectedCustomerRequirement_Common.ScriptUpdatedDate = obj.LastUpdScriptDate
          })




        console.log('this._WorkAllocationService.SelectedJobOrderHdr:', this._WorkAllocationService.SelectedJobOrderHdr)
        $('#myModalConfirm').css("display", "block")
        this.jobOrderForm = true
        this.WarningModel = false
        this.serviceEngWF = false
      }
      else
        this.toastr.warning('Job is not Alloted', 'Work Allocation')
    })

  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join("-");
  }
  OnClickCancel() {
    $('#myModalConfirm').css("display", "none")
    $('#myModal').addClass("show");
    this.WarningModel = false
    this.serviceEngWF = false
    this.jobOrderForm = false
    this.LoadCustomers();
    this.Reset();
  }

  fillmodule() {
    this._modulService.getModule()
      .subscribe(data => {
        this._modulService.allModules = data

      },
        error => {
          this.errorMsg = error;
        })
  }
  fillparticulars() {
    this._ParticularmasterService.getParticularMaster("COMBO")
      .subscribe(data => {
        this._ParticularmasterService.allparticular = data
      },
        error => {
          this.errorMsg = error;
        })
  }



  //-------------------------------------------Customer Requirement Form Review-----------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  refno: number;
  DealerId: number;
  BranchId: number;
  getCusReqOrServiceEntry(obj: SECustomDashBoardCustReq) {
    this.refno = obj.RefNo;
    this.DealerId = obj.DealerId;
    this.BranchId = obj.BranchId;

    if (obj.Vtype == 0) {
      this._CommonService.customerrequirementPopUp = true
      this._CommonService.servicereportentryPopUp = false
    }
    if (obj.Vtype == 1) {
      this._CommonService.servicereportentryPopUp = true
      this._CommonService.customerrequirementPopUp = false

    }

  }

  downloadFileUrl(URL: ImageModel) {
    console.log('ImageModel', URL)
    this._CommonService.ImageDownloadTrack(URL.RefId, URL.FileName).subscribe(obj => {
      console.log('downloadFileUrl Response:', obj)
      if (URL.FormName == 'WorkDelivery') {
        window.open(IMG_ROOT_URL + 'Images/WorkDelivery/' + URL.FileName, '_blank');
      }
    })

  }

  LoadCharts() {
    // Bar chart
    // new Chart(document.getElementById("bar-chart-grouped") as HTMLCanvasElement, {
    //   type: 'bar',
    //   data: {
    //     labels: ["1900", "1950", "1999", "2050"],
    //     datasets: [
    //       {
    //         label: "Modifications",
    //         backgroundColor: "#209e91",
    //         data: [133, 221, 783, 2478]
    //       }, {
    //         label: "Complaints",
    //         backgroundColor: "#0e5049",
    //         data: [408, 547, 675, 734]
    //       }
    //     ]
    //   },
    //   options: {
    // title: {
    //   display: true,
    //   text: 'Modifications/complaints Registration Analysis'
    // }
    //   }
    // });
    //pie chart
    // new Chart(document.getElementById("doughnut-chart") as HTMLCanvasElement, {
    //   type: 'doughnut',
    //   data: {

    //     datasets: [
    //       {
    //         label: "Population (millions)",
    //         backgroundColor: ["#209e91", "#3cba9f", "#398439", "#209e91", "#0e5049", "rgb(51, 102, 0)", "209e91", "#3cba9f", "#0e5049"],
    //         data: [2478, 5267, 734, 784, 4133, 1200, 1500, 1500, 1500, 1500]
    //       }
    //     ],
    //     labels: ["", ""]
    //   },
    //   options: {
    // title: {
    //   display: true,
    //   text: 'Last 12 months sales performance'
    // }
    //   }
    // });
    //gauge
    var chart = this.AmCharts.makeChart("chartdiv", {
      "theme": "light",
      "type": "gauge",
      "axes": [{
        "topTextFontSize": 20,
        "topTextYOffset": 70,
        "axisColor": "#31d6ea",
        "axisThickness": 1,
        "endValue": 100,
        "gridInside": true,
        "inside": true,
        "radius": "50%",
        "valueInterval": 50,
        "tickColor": "#67b7dc",
        "startAngle": -90,
        "endAngle": 90,
        "unit": "%",
        "bandOutlineAlpha": 0,
        "bands": [{
          "color": "#0080ff",
          "endValue": 100,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0,
        },
        {
          "color": "#3cd3a3",
          "endValue": 0,
          "innerRadius": "105%",
          "radius": "170%",
          "gradientRatio": [0.5, 0, -0.5],
          "startValue": 0
        }]
      }],
      "arrows": [{
        "alpha": 1,
        "innerRadius": "35%",
        "nailRadius": 0,
        "radius": "170%"
      }]
    });

    setInterval(randomValue, 2000);

    // set random value
    function randomValue() {
      var value = Math.round(Math.random() * 100);
      chart.arrows[0].setValue(value);
      chart.axes[0].setTopText(value + " %");
      // adjust darker band to new value
      chart.axes[0].bands[1].setEndValue(value);
    }
  }
}
